import React, { useState } from 'react';
import './Popup.css';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
  },
};

const Popup = (props: any) => {
  return (
    <div>
      <Modal isOpen={props.isVisible} style={customStyles} ariaHideApp={false}>
        <div className="popup-box">
          <div className="box">
            <span className="close-icon" onClick={props.handleClose}>
              x
            </span>
            {props.content}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Popup;
