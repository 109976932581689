import React, { useEffect, useMemo, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Dropdown } from 'primereact/dropdown';
import {
  dropdownUnitsInTime,
  dropdownWeightUnits,
  dropdownFixedMax,
  dropdownSolidUnits,
} from '../../_global/constants/Protocol_constants';
import { Col, Row } from 'react-grid-system';
import { InputNumber } from 'primereact/inputnumber';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import { globals } from '../../_global/common/Utils';
import { drop } from 'lodash';

export const getBasisOutput = (data: any, type: string) => {
  let calculationUnit = data.calculationUnit.code
    ? data.calculationUnit.name
    : data.calculationUnit;
  let medicationUnit = data.medicationUnit.code
    ? data.medicationUnit.name
    : data.medicationUnit;
  let electricalUnit = data.electricalUnit.code
    ? data.electricalUnit.name
    : data.electricalUnit;
  let timeUnit = data.timeUnit.code ? data.timeUnit.name : data.timeUnit;

  if (type === 'Electrical') {
    if (data.amount1 && electricalUnit) {
      if (data.amount2 && calculationUnit && calculationUnit !== 'NA') {
        return String(
          data.amount1 +
            '-' +
            data.amount2 +
            ' ' +
            electricalUnit +
            '/' +
            calculationUnit
        );
      } else if (data.amount2 && calculationUnit && calculationUnit === 'NA') {
        return String(data.amount1 + '-' + data.amount2 + ' ' + electricalUnit);
      } else if (!data.amount2 && calculationUnit && calculationUnit !== 'NA') {
        return String(
          data.amount1 + ' ' + electricalUnit + '/' + calculationUnit
        );
      } else {
        return String(data.amount1 + ' ' + electricalUnit);
      }
    } else {
      return 'N/A';
    }
  } else if (type === 'Infusion') {
    if (data.amount1 && medicationUnit) {
      if (data.amount2 && calculationUnit !== 'NA') {
        return String(
          data.amount1 +
            '-' +
            data.amount2 +
            ' ' +
            medicationUnit +
            '/' +
            calculationUnit +
            '/' +
            timeUnit
        );
      } else if (data.amount2 && calculationUnit === 'NA') {
        return String(
          data.amount1 +
            '-' +
            data.amount2 +
            ' ' +
            medicationUnit +
            '/' +
            timeUnit
        );
      } else if (!data.amount2 && calculationUnit !== 'NA') {
        return String(
          data.amount1 +
            ' ' +
            medicationUnit +
            '/' +
            calculationUnit +
            '/' +
            timeUnit
        );
      } else {
        return String(data.amount1 + ' ' + medicationUnit + '/' + timeUnit);
      }
    } else {
      return 'N/A';
    }
  } else {
    if (data.amount1 && medicationUnit) {
      if (data.amount2 && calculationUnit !== 'NA') {
        return String(
          data.amount1 +
            '-' +
            data.amount2 +
            ' ' +
            medicationUnit +
            '/' +
            calculationUnit
        );
      } else if (data.amount2 && calculationUnit === 'NA') {
        return String(data.amount1 + '-' + data.amount2 + ' ' + medicationUnit);
      } else if (!data.amount2 && calculationUnit !== 'NA') {
        return String(
          data.amount1 + ' ' + medicationUnit + '/' + calculationUnit
        );
      } else {
        return String(data.amount1 + ' ' + medicationUnit);
      }
    } else {
      return 'N/A';
    }
  }
};

type CalculationBasisDialogProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  basis?: string;
  handleSet?: any;
  type?: any;
  unit?: any;
};

/* 10-23-23 Arul: Created the common componnet for Calculation Basis popup */
const CalculationBasisDialog: React.FC<CalculationBasisDialogProps> = ({
  dialogVisible,
  setDialogVisible,
  headerText,
  basis,
  handleSet,
  type,
  unit,
}) => {
  const [fullBasis, setFullBasis] = useState<string>(
    basis && basis !== '+Add' ? basis : ''
  );

  useEffect(() => {
    if (fullBasis && fullBasis !== '-1') {
      let d = {
        basisHigh: '',
        basisLow: '',
        basisUnit: '',
        calcUnit: '',
        timeUnit: '',
      };

      /* ---------------- Parse the data for the basis variables ---------------- */
      let basisArr: string[] = fullBasis.split(' ', 2);
      if (basisArr.length < 2) return;

      /* Parse basis amount to a double for comparison */
      let basisNums: string[] = basisArr[0].split('-');
      d.basisLow = '' + parseFloat(basisNums[0]);
      if (basisNums.length > 1) d.basisHigh = '' + parseFloat(basisNums[1]);
      else d.basisHigh = '';

      /* Parse the units for the basis to validate calculations */
      let fullBasisUnit = basisArr[1];
      let basisUnitsArr: string[] = fullBasisUnit.split('/');
      d.basisUnit = basisUnitsArr[0];
      if (basisUnitsArr.length > 1) {
        let unit = basisUnitsArr[1];
        if (unit !== 'kg') {
          if (unit === 'hr' || unit === 'min') {
            d.timeUnit = unit;
            d.calcUnit = '';
          }
        } else d.calcUnit = unit;
      }
      if (basisUnitsArr.length > 2) {
        let unit = basisUnitsArr[2];
        if (unit === 'hr' || unit === 'min') d.timeUnit = unit;
      }

      formik.setValues({
        ...formik.values,
        amount1: d.basisLow ? d.basisLow : '',
        amount2: d.basisHigh ? d.basisHigh : '',
        medicationUnit: d.basisUnit
          ? { name: d.basisUnit, code: d.basisUnit }
          : dropdownSolidUnits[0],
        electricalUnit: d.basisUnit
          ? { name: d.basisUnit, code: d.basisUnit }
          : dropdownFixedMax[0],
        calculationUnit: d.calcUnit
          ? { name: d.calcUnit, code: d.calcUnit }
          : dropDownCalculationOption[0],
        timeUnit: d.timeUnit
          ? { name: d.timeUnit, code: d.timeUnit }
          : dropdownUnitsInTime[0],
      });
    }
  }, [fullBasis]);

  //
  const dropDownElectricalMedicationOption = useMemo(() => {
    if (type === 'Electrical') {
      return dropdownFixedMax;
    } else {
      return dropdownSolidUnits;
    }
  }, [type]);

  const initalUnit = useMemo(() => {
    for (let i = 0; i < dropDownElectricalMedicationOption.length; i++) {
      if (dropDownElectricalMedicationOption[i].code === unit) {
        return dropDownElectricalMedicationOption[i];
      }
    }
    return dropDownElectricalMedicationOption[0];
  }, [unit, dropDownElectricalMedicationOption]);

  const dropDownCalculationOption = useMemo(() => {
    const value = { name: 'NA', code: 'NA' };
    if (type === 'Electrical') {
      let result = [value, ...dropdownWeightUnits];
      if (type === 'Infusion') result = [...result, { name: 'mL', code: 'mL' }];
      return result;
    } else {
      const result = [value, ...dropdownWeightUnits];
      return result;
    }
  }, [type]);

  const getUnit = (data: any, type: string) => {
    // if(data){
    if (type === 'Electrical') {
      return data?.basisUnit
        ? { name: data?.basisUnit, code: data?.basisUnit }
        : dropdownFixedMax[0];
    } else if (type === 'Time') {
      let d = data as MedicationSubItem;
      return d?.timeUnit
        ? { name: d?.timeUnit, code: d?.timeUnit }
        : dropdownUnitsInTime[0];
    } else if (type === 'Calculation')
      return data?.calcUnit
        ? { name: data?.calcUnit, code: data?.calcUnit }
        : dropDownCalculationOption[0];
    else {
      return data?.basisUnit
        ? { name: data?.basisUnit, code: data?.basisUnit }
        : dropdownSolidUnits[1];
    }
    // // }
    // return { name: '', code: ''}
  };

  /* 10-23-23 Arul: initialize the field initial values  */
  const formik = useFormik({
    initialValues: {
      amount1: '',
      amount2: '',
      medicationUnit: initalUnit,
      electricalUnit: initalUnit,
      calculationUnit: dropDownCalculationOption[0],
      timeUnit: dropdownUnitsInTime[0],
      output: '',
    },
    validationSchema: Yup.object({
      amount1: Yup.number().required('Atleast one value is required'),
      amount2: Yup.number().nullable(),
      medicationUnit: Yup.string(),
      electricalUnit: Yup.string(),
      calculationUnit: Yup.string().required(),
      timeUnit: Yup.string(),
      output: Yup.string().required(),
    }),
    onSubmit: (values) => {},
  });

  // /* 10-23-23 Arul: set field values to repopulate  */
  // useEffect(() => {
  //     if (data) {
  //         let lastElementUnit: any;
  //         if (data?.fullBasisUnit) {
  //             const units = data?.fullBasisUnit?.split('/'); // Split the string by '/'
  //             lastElementUnit = units[units?.length - 1];
  //         }

  //         if (type === 'Electrical' && (data?.basisHigh || data?.basisLow)) {
  //             const basisHigh = data?.basisHigh?.split(' ');
  //             const basisLow = data?.basisLow?.split(' ');
  //             const basisUnits = basisHigh[1]?.split('/');

  //             formik.setValues(
  //                 {
  //                     ...formik.values,
  //                     "amount1": basisHigh[0] ? basisHigh[0] : 0,
  //                     "amount2": basisLow[0] ? basisLow[0] : 0,
  //                     "electricalUnit": basisUnits[0] ? { name: basisUnits[0], code: basisUnits[0] } : dropdownFixedMax[0],
  //                     "calculationUnit": basisUnits[1] ? { name: basisUnits[1], code: basisUnits[1] } : dropDownCalculationOption[0],
  //                 });
  //         } else {
  //             formik.setValues(
  //                 {
  //                     ...formik.values,
  //                     "amount1": data?.basis ? data.basis : 1.0,
  //                     "amount2": data?.basisHigh ? data?.basisHigh : 0,
  //                     "medicationUnit": data.basisUnit ? { name: data?.basisUnit, code: data?.basisUnit } : dropdownSolidUnits[0],
  //                     "calculationUnit": data.calcUnit ? { name: data?.calcUnit, code: data?.calcUnit } : dropDownCalculationOption[0],
  //                     "timeUnit": data.fullBasisUnit ? { name: lastElementUnit, code: lastElementUnit } : dropdownUnitsInTime[0],
  //                 });
  //         }

  //     }
  // }, [data])

  const handleSubmit = () => {
    // const data:any ={...formik.values}
    // if(formik.values.calculationUnit.code ==='NA'){
    //     data.calculationUnit = ''
    // }else{
    //     data.calculationUnit = formik.values.calculationUnit.code;
    // }
    handleSet(getBasisOutput(formik.values, type));
  };

  /* 10-24-23 Arul: handling function for output */
  const outputValue = useMemo(() => {
    let vals = formik.values;
    // vals.calculationUnit = vals.calculationUnit.code;
    // vals.medicationUnit = vals.medicationUnit.code;
    // vals.electricalUnit = vals.electricalUnit.code;
    // vals.timeUnit = vals.timeUnit.code;

    return getBasisOutput(vals, type);
  }, [formik.values, type]);

  return (
    <Dialog
      header={headerText}
      visible={dialogVisible}
      className="minDoseGap"
      onHide={() => setDialogVisible(false)}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <h6 className="headerText">
                Enter the calculation basis to administer to the patient
              </h6>
              <span className="contentText">
                <AiOutlineQuestionCircle className="icon-normal" />
              </span>
            </div>
            <span className="contentText">
              Enter the calculation basis for the medication dose.
            </span>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Row>
              <Col sm={6}>
                <div className="p-inputgroup flex-1 calcualtionDiv">
                  <div className="p-inputgroup-addon-left dialog_Item_label">
                    <span className="colAlignCenter">
                      Amount 1:<span className="required-field">*</span>
                    </span>
                  </div>
                  <div className="input-container" style={{ width: '250px' }}>
                    {/* <InputText placeholder="" className='dialogInput calculationInput' /> */}
                    <InputNumber
                      id="amount1"
                      name="amount1"
                      value={Number(formik.values.amount1)}
                      mode="decimal"
                      maxFractionDigits={4}
                      autoFocus={true}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') handleSubmit();
                      }}
                      onChange={(e: any) => {
                        let n = Number(e.value);
                        if (isNaN(n))
                          formik.setFieldError(
                            'amount1',
                            'Please enter a valid number'
                          );
                        else formik.setFieldValue('amount1', n);
                      }}
                      className="dialogInput calculationInput"
                    />
                    <div className="dialogInput-border"></div>
                  </div>
                  {/* {formik.touched.amount1 && formik.errors.amount1 ? (
                                        <span className='errorText'>{formik.errors.amount1}</span>
                                ) : null} */}
                </div>
              </Col>
              <Col sm={6}>
                <div className="p-inputgroup flex-1">
                  <div className="p-inputgroup-addon-left dialog_Item_label">
                    <span>Amount 2:</span>
                  </div>
                  <div className="input-container" style={{ width: '250px' }}>
                    <InputNumber
                      id="amount2"
                      name="amount2"
                      data-testid="amount2"
                      value={Number(formik.values.amount2)}
                      mode="decimal"
                      maxFractionDigits={4}
                      onChange={(e: any) => {
                        let n = Number(e.value);
                        if (isNaN(n))
                          formik.setFieldError(
                            'amount2',
                            'Please enter a valid number'
                          );
                        else formik.setFieldValue('amount2', n);
                      }}
                      placeholder="Optional..."
                      className="dialogInput calculationInput"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') handleSubmit();
                      }}
                    />
                    <div className="dialogInput-border"></div>
                  </div>
                  {/* {formik.touched.amount2 && formik.errors.amount2 ? (
                                        <span className='errorText'>{formik.errors.amount2}</span>
                                    ) : null} */}
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Row>
              <Col sm={6}>
                <div>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon-left calculationMinWidth">
                      <span className="colAlignCenter">
                        {type === 'Electrical'
                          ? ' Electrical Unit:'
                          : ' Medication Unit:'}
                        <span className="required-field">*</span>
                      </span>
                    </span>
                    <div
                      className="doseDropdwnWidth p-inputgroup-addon"
                      style={{ width: '210px' }}
                    >
                      <Dropdown
                        value={
                          type === 'Electrical'
                            ? formik.values.electricalUnit
                            : formik.values.medicationUnit
                        }
                        onChange={(e: any) => {
                          if (type === 'Electrical')
                            formik.setFieldValue('electricalUnit', e.value);
                          else formik.setFieldValue('medicationUnit', e.value);
                        }}
                        options={dropDownElectricalMedicationOption}
                        optionLabel="name"
                        className="selectDropdown"
                        data-testid="unit1"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon-left calculationMinWidth">
                      <span>Second Unit:</span>
                    </span>
                    <div
                      className="doseDropdwnWidth p-inputgroup-addon"
                      style={{ width: '210px' }}
                    >
                      <Dropdown
                        value={formik.values.calculationUnit}
                        onChange={(e: any) => {
                          formik.setFieldValue('calculationUnit', e.value);
                        }}
                        options={dropDownCalculationOption}
                        optionLabel="name"
                        data-testid="unit2"
                        className={`selectDropdown ${
                          formik.values.calculationUnit?.code === 'NA'
                            ? 'dropdownGreyText'
                            : ''
                        }`}
                        //className={`selectDropdown ${viewOnly ? !formik.values.rangeLow ? 'greyText' : '' : 'inputHover'}``}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {type === 'Infusion' && (
            <div>
              <Row>
                <Col sm={6}>
                  <div>
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon-left calculationMinWidth">
                        <span className="colAlignCenter">
                          Time Unit:<span className="required-field">*</span>
                        </span>
                      </span>
                      <div
                        className="doseDropdwnWidth p-inputgroup-addon"
                        style={{ width: '210px' }}
                      >
                        <Dropdown
                          value={formik.values.timeUnit}
                          onChange={(e: any) => {
                            formik.setFieldValue('timeUnit', e.value);
                          }}
                          options={dropdownUnitsInTime}
                          optionLabel="name"
                          className="selectDropdown"
                          data-testid="time"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <hr />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <div style={{ width: '100%' }}>
              <label htmlFor="Output" className="ketamine-general-label">
                Output:
              </label>
              <div className="doseDropdwnWidth p-inputgroup-addon">
                <span
                  className=""
                  style={{
                    fontSize: '16px',
                    color: outputValue === 'N/A' ? '#999999' : '#000000',
                  }}
                >
                  {outputValue}
                </span>
              </div>
            </div>
            <div className="" style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                data-testid="cancelBtnn"
                className="secondary-button-dialog btn-rightMargin"
                onClick={() => setDialogVisible(false)}
              >
                {' '}
                Cancel
              </Button>
              <Button
                data-testid="saveBtnn"
                className="primary-button"
                onClick={handleSubmit}
                disabled={!outputValue ? true : false}
              >
                Set
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CalculationBasisDialog;
