import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import * as Yup from 'yup';
import {
  dropdownLiquidUnits,
  dropdownSolidUnits,
} from '../../_global/constants/Protocol_constants';
import { MedicationConcentration } from '../../../models';
import { set } from 'lodash';

type ConcentrationDialogProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  headerTitle?: string;
  InputLabel?: string;
  value?: MedicationConcentration;
  concentrations?: MedicationConcentration[];
  handleSet?: any;
};

/* 10-19-23 Arul: Created the common componnet for Concentration popup */
const ConcentrationDialog: React.FC<ConcentrationDialogProps> = ({
  dialogVisible,
  setDialogVisible,
  headerText,
  value,
  handleSet,
  concentrations,
}) => {
  const [error, setError] = React.useState<string>('');
  const [isSolidOnly, setIsSolidOnly] = React.useState<boolean>(false);

  const formik = useFormik<any>({
    initialValues: {
      amntSolid: value && value.firstAmnt !== undefined ? value.firstAmnt : 1.0,
      amntLiquid: value && value.secAmnt !== undefined ? value.secAmnt : 1.0,
      unitSolid:
        value && value.firstUnit
          ? { name: value.firstUnit, code: value.firstUnit }
          : dropdownSolidUnits[1],
      unitLiquid:
        value && value.secUnit
          ? { name: value.secUnit, code: value.secUnit }
          : dropdownLiquidUnits[1],
    },
    validationSchema: Yup.object({
      amntSolid: Yup.number().required(),
      amntLiquid: Yup.number().nullable(),
      unitSolid: Yup.string().required(),
      unitLiquid: Yup.string().nullable(),
    }),
    onSubmit: (values) => {},
  });

  // // Form value mapping
  // useEffect(() => {
  //     if(value && value.firstAmnt != null){
  //         formik.setValues({
  //             ...formik.values,
  //             "amntSolid": value && value.firstAmnt !== undefined ? value.firstAmnt : 1.0,
  //             "amntLiquid": value && value.secAmnt !== undefined ? value.secAmnt : 1.0,
  //             "unitSolid": value && value.firstUnit ? { name: value.firstUnit, code: value.firstUnit } : dropdownSolidUnits[1],
  //             "unitLiquid": value && value.secUnit ? { name: value.secUnit, code: value.secUnit } : null,
  //         });
  //     }
  // }, [value]);

  // Caluclate the output field on Input change
  const outputValue = useMemo(() => {
    if (formik.values.amntSolid == null || formik.values.amntSolid < 0)
      return '';
    if (
      !formik.values.amntLiquid ||
      formik.values.amntLiquid < 0 ||
      !formik.values.unitLiquid
    )
      return formik.values.amntSolid + ' ' + formik.values.unitSolid.code;
    if (formik.values.amntLiquid === 1)
      return (
        formik.values.amntSolid +
        ' ' +
        formik.values.unitSolid.code +
        '/' +
        formik.values.unitLiquid.code
      );
    else
      return (
        formik.values.amntSolid +
        ' ' +
        formik.values.unitSolid.code +
        '/' +
        formik.values.amntLiquid +
        ' ' +
        formik.values.unitLiquid.code
      );
  }, [formik.values]);

  const handleSubmit = () => {
    if (formik.values.amntSolid < -1 || formik.values.unitSolid.code === '') {
      setError('Please enter valid solid amount.');
      return;
    }
    /* Make sure that concentration is not duplicated */
    let isDuplicate = false;
    concentrations?.forEach((concentration: MedicationConcentration) => {
      if (
        concentration.firstAmnt === formik.values.amntSolid &&
        concentration.secAmnt === formik.values.amntLiquid &&
        concentration.firstUnit === formik.values.unitSolid.code &&
        concentration.secUnit === formik.values.unitLiquid?.code
      ) {
        isDuplicate = true;
      }
    });
    if (isDuplicate) {
      setError('Concentration is already added.');
      return;
    }
    handleSet(
      formik.values.amntSolid,
      formik.values.amntLiquid,
      formik.values.unitSolid.code,
      formik.values.unitLiquid?.code
    );
    formik.resetForm();
  };

  const handleCancel = () => {
    if (value) {
      handleSet(value.firstAmnt, value.secAmnt, value.firstUnit, value.secUnit);
      formik.resetForm();
    } else setDialogVisible(false);
  };

  return (
    <Dialog
      header={headerText}
      visible={dialogVisible}
      className="minDoseGap"
      onHide={handleCancel}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <h6 className="headerText">Enter Concentration</h6>
              <span className="contentText">
                <AiOutlineQuestionCircle className="icon-normal" />
              </span>
            </div>
            <span className="contentText">
              Enter the concentration for the medication
            </span>
            {error !== '' && <span className="errorText">{error}</span>}
          </div>
          <div
            className="calculationDialogContent"
            style={{ marginBottom: '0px' }}
          >
            <label
              htmlFor="isSolidOnly"
              className="departmentItemText"
              style={{ flexDirection: 'row' }}
            >
              Solid Only:
              <InputSwitch
                name="isSolidOnly"
                style={{ marginLeft: '10px' }}
                checked={isSolidOnly}
                onChange={(e) => {
                  setIsSolidOnly(e.value);
                  formik.setValues({
                    ...formik.values,
                    amntLiquid: null,
                    unitLiquid: null,
                  });
                }}
              />
            </label>
          </div>
          <div
            className="calculationDialogContent"
            style={{ marginBottom: '10px' }}
          >
            <Row>
              <Col sm={6}>
                <div className="p-inputgroup flex-1 calcualtionDiv">
                  <div className="p-inputgroup-addon-left dialog_Item_label">
                    <span style={{ display: 'flex' }}>
                      Solid Amount:{' '}
                      <span
                        className="required-field"
                        style={{ marginBottom: '5px' }}
                      >
                        *
                      </span>
                    </span>
                  </div>
                  <div className="input-container">
                    <InputNumber
                      id="amntSolid"
                      name="amntSolid"
                      data-testid="amntSolid"
                      value={formik.values.amntSolid}
                      mode="decimal"
                      autoFocus={true}
                      // minFractionDigits={1}
                      maxFractionDigits={2}
                      required={true}
                      onChange={(e) => {
                        if (error !== '') setError('');
                        formik.setValues({
                          ...formik.values,
                          amntSolid: e.value,
                        });
                        // handleChangeEvent('amntSolid', e)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSubmit();
                        }
                      }}
                      placeholder=""
                      className="dialogInput calculationInput"
                    />
                    <div className="dialogInput-border"></div>
                  </div>
                </div>
              </Col>
              <Col sm={6} style={{ display: 'flex' }}>
                <div className="p-inputgroup flex-1">
                  <span className="p-inputgroup-addon-left calculationMinWidth">
                    <span style={{ display: 'flex' }}>
                      Solid Unit:{' '}
                      <span
                        className="required-field"
                        style={{ marginBottom: '5px' }}
                      >
                        *
                      </span>
                    </span>
                  </span>
                  <span className="doseDropdwnWidth p-inputgroup-addon">
                    <Dropdown
                      value={formik.values.unitSolid}
                      onChange={(e) => {
                        if (error !== '') setError('');
                        formik.setValues({
                          ...formik.values,
                          unitSolid: e.value,
                        });
                      }}
                      options={dropdownSolidUnits}
                      required={true}
                      optionLabel="name"
                      className="selectDropdown"
                      data-testid="unitSolid"
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          {!isSolidOnly && (
            <div className="calculationDialogContent">
              <Row>
                <Col sm={6}>
                  <div className="p-inputgroup flex-1 calcualtionDiv">
                    <div className="p-inputgroup-addon-left dialog_Item_label">
                      <span style={{ display: 'flex' }}>Liquid Amount:</span>
                    </div>
                    <div className="input-container">
                      <InputNumber
                        id="amntLiquid"
                        name="amntLiquid"
                        data-testid="amntLiquid"
                        value={formik.values.amntLiquid}
                        mode="decimal"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') handleSubmit();
                        }}
                        // minFractionDigits={1}
                        maxFractionDigits={2}
                        onChange={(e) => {
                          if (error !== '') setError('');
                          formik.setValues({
                            ...formik.values,
                            amntLiquid: e.value,
                          });
                        }}
                        placeholder="Optional..."
                        className="dialogInput calculationInput"
                      />
                      <div className="dialogInput-border"></div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} style={{ display: 'flex' }}>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon-left calculationMinWidth">
                      <span>Liquid Unit:</span>
                    </span>
                    <span className="doseDropdwnWidth p-inputgroup-addon">
                      <Dropdown
                        value={formik.values.unitLiquid}
                        onChange={(e) => {
                          if (error !== '') setError('');
                          if (e.value.code === '')
                            formik.setValues({
                              ...formik.values,
                              unitLiquid: null,
                            });
                          else
                            formik.setValues({
                              ...formik.values,
                              unitLiquid: e.value,
                            });
                        }}
                        placeholder="Optional..."
                        options={dropdownLiquidUnits}
                        optionLabel="name"
                        className="selectDropdown"
                        data-testid="unitLiquid"
                      />
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <hr />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
              padding: '10px',
            }}
          >
            <div style={{ width: '50%' }}>
              <label htmlFor="Output" className="ketamine-general-label">
                Output:
              </label>
              <div className="doseDropdwnWidth p-inputgroup-addon">
                <span className="">{outputValue}</span>
              </div>
            </div>
            <div className="" style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                data-testid="dismissBttn"
                className="secondary-button-dialog btn-rightMargin"
                onClick={handleCancel}
              >
                {' '}
                Cancel
              </Button>
              <Button
                data-testid="saveBttn"
                className="primary-button"
                onClick={handleSubmit}
                disabled={!formik.values.amntSolid || !formik.values.unitSolid}
              >
                Set
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConcentrationDialog;
