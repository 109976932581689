import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';
import MedicationItem from './MedicationItem';
import { roundToDec, globals } from '../../ui/_global/common/Utils';
import {
  MedicationConcentration,
  MedicationRange,
  MedicationRoute,
  ProgressStatus,
} from '../../models';

export const cloneMedicationSubItems = (
  parentMedication: MedicationItem,
  meds: MedicationSubItem[]
): MedicationSubItem[] => {
  let clone: MedicationSubItem[] = [];
  for (let i = 0; i < meds.length; i++)
    clone.push(
      new MedicationSubItem(
        parentMedication,
        meds[i].parentProtocol,
        meds[i].range
      )
    );
  return clone;
};

export const cloneMedicationSubItem = (
  parentMedication: MedicationItem,
  med: MedicationSubItem
): MedicationSubItem => {
  return new MedicationSubItem(parentMedication, med.parentProtocol, med.range);
};

export const getBasisString = (med: MedicationSubItem): string => {
  if (med.basis === -1) return 'EMRGNCY';
  if (med.basisHigh == globals.MAX_VALUE) {
    if (!med.calcUnit || med.calcUnit == '')
      return med.basis + ' ' + med.basisUnit;
    return med.basis + ' ' + med.basisUnit + '/' + med.calcUnit;
  } else {
    if (!med.calcUnit || med.calcUnit == '')
      return med.basis + '-' + med.basisHigh + ' ' + med.basisUnit;
    return (
      med.basis + '-' + med.basisHigh + ' ' + med.basisUnit + '/' + med.calcUnit
    );
  }
};

export const getRoutesString = (med: MedicationSubItem): string => {
  if (med.routes == null || med.routes.length == 0) return '';
  let str = '';
  for (let i = 0; i < med.routes.length; i++) {
    if (i == med.routes.length - 1) str += med.routes[i];
    else str += med.routes[i] + ' / ';
  }
  return str;
};

export const getAllConcentrations = (med: MedicationSubItem): string => {
  let str = '';
  for (let i = 0; i < med.concentrations.length; i++)
    str += getConcentration(med, i) + ', ';
  return str;
};

export const getConcentration = (med: MedicationSubItem, i: number): string => {
  if (
    med.concentrations == null ||
    med.concentrations.length == 0 ||
    i >= med.concentrations.length
  )
    return '';

  if (med.concentrations.length > 1 && i == -1) return 'Input Required';
  else if (med.concentrations.length == 1) i = 0;

  let str =
    med.concentrations[i].firstAmnt + ' ' + med.concentrations[i].firstUnit;
  if (med.concentrations[i].secAmnt ?? 0 > 0) {
    str += '/';
    if (med.concentrations[i].secAmnt != 1.0)
      str +=
        med.concentrations[i].secAmnt + ' ' + med.concentrations[i].secUnit;
    else str += med.concentrations[i].secUnit;
  }
  return str;
};

class MedicationSubItem implements ModelItem {
  TAG = 'MedicationSubItem';
  uid: string;
  name: string;
  parentProtocol: ProtocolItem;
  parentMedication: MedicationItem;
  concentrations: MedicationConcentration[];
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';

  // Medication Option

  // Medication Range
  fullBasisUnit: string;
  basisUnit: string;
  calcUnit: string;
  maxDoseUnit: string;
  timeUnit?: string;

  title: string;
  instruction: string;
  description: string;
  note: string;
  warning: string;
  routes: string[];
  nemsisRoutes: MedicationRoute[];
  basis: number;
  basisHigh: number;
  rangeLow: number;
  rangeHigh: number;
  maxDose: number;
  minDose: number;
  index: number;

  range: MedicationRange;

  constructor(
    medication: MedicationItem,
    parent: ProtocolItem,
    range: MedicationRange
  ) {
    // console.log('NEMSIS ROUTES', range.route, range.nemsisRoutes);
    this.uid = medication.uid;
    this.name = medication.name;
    this.parentMedication = medication;
    this.parentProtocol = parent;
    this.concentrations = medication.dbMedication?.concentration ?? [];
    this.range = range;
    this.index = range.index;
    this.nemsisRoutes = range.nemsisRoutes ? range.nemsisRoutes : [];

    this.title = range.title ?? '';
    this.note = range.note ?? '';
    this.instruction = range.instruction ?? '';
    this.warning = range.warning ?? '';
    this.routes = range.route;
    this.rangeLow = range.rangeLow;
    this.rangeHigh = range.rangeHigh ?? globals.MAX_VALUE;

    this.status = medication.status ?? 'ACTIVE';
    this.description = '';
    this.fullBasisUnit = '';
    this.basisUnit = '';
    this.calcUnit = '';
    this.maxDoseUnit = '';
    this.basis = -1;
    this.basisHigh = globals.MAX_VALUE;
    this.maxDose = globals.MAX_VALUE;
    this.minDose = 0;

    try {
      if (range.basis === '-1') {
        this.basis = -1;
        this.basisHigh = globals.MAX_VALUE;
      } else {
        /* ---------------- Parse the data for the basis variables ---------------- */
        let basisArr: string[] = range.basis.split(' ', 2);

        /* Parse basis amount to a double for comparison */
        let basisNums: string[] = basisArr[0].split('-');
        this.basis = parseFloat(basisNums[0]);
        if (basisNums.length > 1) this.basisHigh = parseFloat(basisNums[1]);
        else this.basisHigh = globals.MAX_VALUE;

        /* Parse the units for the basis to validate calculations */
        this.fullBasisUnit = basisArr[1];
        let basisUnitsArr: string[] = basisArr[1].split('/');
        this.basisUnit = basisUnitsArr[0];
        if (basisUnitsArr.length > 1) {
          this.calcUnit = basisUnitsArr[1];
          if (this.calcUnit !== 'kg')
            if (globals.debug)
              if (globals.debug)
                console.log(
                  this.TAG,
                  'ERROR: Basis unit calculation is not kg -> ' +
                    this.calcUnit +
                    ' basis: ' +
                    range.basis
                );
        }

        /* ---------------- Parse the data for the max dose variables ---------------- */
        if (range.maxDose && range.maxDose !== '') {
          let maxDoseArr: string[] = range.maxDose.split(' ', 2);

          if (maxDoseArr.length != 2) {
            if (globals.debug)
              if (globals.debug)
                console.log(
                  this.TAG,
                  "ERROR: Max dose is not in the format of 'number units' -> " +
                    range.maxDose +
                    ' length: ' +
                    maxDoseArr.length
                );
            this.maxDose = globals.MAX_VALUE;
            return;
          }

          /* Parse basis amount to a double for comparison */
          this.maxDose = parseFloat(maxDoseArr[0]);

          /* Parse the units for the basis to validate calculations */
          this.maxDoseUnit = maxDoseArr[1];
          if (this.maxDoseUnit !== this.basisUnit)
            if (globals.debug)
              if (globals.debug)
                console.log(
                  this.TAG,
                  'ERROR: Max dose unit does not match basis unit -> ' +
                    range.maxDose +
                    ' basis: ' +
                    this.basisUnit +
                    ' maxDose: ' +
                    this.maxDoseUnit
                );
        }
      }
    } catch (e) {
      if (globals.debug)
        if (globals.debug)
          console.log(
            this.TAG,
            'ERROR: Failed to parse basis -> ' + range.basis
          );
    }
  }

  // constructor(parentProtocol: ProtocolItem, parentMedication: MedicationItem, name: string, isRange: boolean){
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.name               = name;
  //     this.parentProtocol     = parentProtocol;
  //     this.parentMedication   = parentMedication;
  //     this.concentrations     = parentMedication.getConcentrations();

  //     this.title              = "Sedation";
  //     this.note               = "";
  //     this.instruction        = "Repeat as needed to acheive appropriate sedation score.";
  //     this.description        = "Contraindication : Known heart disease (Rare) use Etomidate"
  //     this.warning            = "";
  //     this.routes             = ["IV", "IO"];
  //     this.rangeLow           = 0;
  //     this.rangeHigh          = globals.MAX_VALUE;

  //     this.fullBasisUnit      = "mg/kg/hr";
  //     this.basisUnit          = "mg";
  //     this.calcUnit           = "kg";
  //     this.maxDoseUnit        = "mg";
  //     this.administeredAmount = 0.0;
  //     this.basis              = 1.0;
  //     this.basisHigh          = (isRange ? 2.0 : globals.MAX_VALUE);
  //     this.maxDose            = 150.0;
  // }

  /**
   * function get the amount to administer in liquid and solid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in liquid and solid form Ex. "1.5 mL (10.2 mg)"
   */
  getAmount(weight: PatientWeight): string {
    if (!this.inRange(weight)) return '';

    if (this.basis <= 0 || this.basis == globals.MAX_VALUE) return '';

    if (this.basisHigh == globals.MAX_VALUE)
      return `${roundToDec(this.basis, 1).toFixed(1)}`;
    return `${roundToDec(this.basis, 1).toFixed(1)}-${roundToDec(this.basisHigh, 1).toFixed(1)}`;
  }

  /**
   * Get the amount to administer in solid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in solid form Ex. "10.2 mg"
   */
  getDoseSolid(weight: PatientWeight, selectedConcentration: number): string {
    /* Base case is no concentration */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      this.basis == 0.0
    )
      return '';

    /* If there is multiple concentrations and it has not been selected return nothing */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return '--' + this.concentrations[0].firstUnit;
    else if (this.concentrations.length == 1) selectedConcentration = 0;

    let concentration: MedicationConcentration =
      this.concentrations[selectedConcentration];

    if (concentration == null) return '';

    /* if there is no calculation needed just return required amount - Ex. Glucagon (0.5 mg) */
    if (concentration.secAmnt == null || concentration.secAmnt <= 0) {
      //TODO need to validate it will be null in a single concentration
      /* No dose range, return the dose in primary units */
      let dose: number =
        this.calcUnit === null ? this.basis : this.basis * weight.getWeightKg();
      dose = Math.min(dose, this.maxDose);
      let dec1 = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;

      if (this.basisHigh == globals.MAX_VALUE)
        return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;
      /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
        let doseHigh: number =
          this.calcUnit === null
            ? this.basisHigh
            : this.basisHigh * weight.getWeightKg();
        doseHigh = Math.min(dose, this.maxDose);
        let dec2 = doseHigh > 99.9 ? 0 : doseHigh > 9.9 ? 1 : 2;

        /* If the doses are the same return the dose in primary units */
        if (dose == doseHigh)
          return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;

        /* If the doses are different return a range in primary units */
        return `${roundToDec(dose, dec1).toFixed(dec1)}-${roundToDec(doseHigh, dec2).toFixed(dec2)} ${concentration.firstUnit}`;
      }
    } else {
      /* No dose range, return the dose in primary units */
      let dose: number = Math.min(
        weight.getWeightKg() * this.basis,
        this.maxDose
      );
      let dec1 = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;
      if (this.basisHigh == globals.MAX_VALUE)
        return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;
      /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
        let doseHigh: number = roundToDec(
          Math.min(weight.getWeightKg() * this.basisHigh, this.maxDose),
          1
        );
        let dec2 = doseHigh > 99.9 ? 0 : doseHigh > 9.9 ? 1 : 2;
        /* If the doses are the same return the dose in primary units */
        if (dose == doseHigh)
          return `${roundToDec(dose, dec2).toFixed(dec2)} ${concentration.firstUnit}`;

        /* If the doses are different return a range in primary units */
        return `${roundToDec(dose, dec1).toFixed(dec1)}-${roundToDec(doseHigh, dec2).toFixed(dec2)} ${concentration.firstUnit}`;
      }
    }
  }

  /**
   * Get the unit of the solid form
   * @returns The unit of the solid form Ex. "mg"
   */
  getSolidUnit(): string {
    if (this.concentrations == null || this.concentrations.length == 0)
      return '';
    return this.concentrations[0].firstUnit;
  }

  /**
   * Get the amount to administer in liquid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in liquid form Ex. "1.5 mL"
   */
  getDoseLiquid(weight: PatientWeight, selectedConcentration: number): string {
    /* Base case is no concentration */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      this.basis == 0.0
    )
      return '';

    /* If there is multiple concentrations and it has not been selected return nothing */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return '--' + this.concentrations[0].secUnit;
    else if (this.concentrations.length == 1) selectedConcentration = 0;

    let concentration: MedicationConcentration =
      this.concentrations[selectedConcentration];

    if (
      concentration == null ||
      !concentration.secAmnt ||
      concentration.secAmnt <= 0
    )
      return '';

    /* No dose range, return the dose in primary units */
    let concentrationAmount: number =
      concentration.firstAmnt / concentration.secAmnt;
    let dose: number =
      Math.min(weight.getWeightKg() * this.basis, this.maxDose) /
      concentrationAmount;

    if (this.basisHigh == globals.MAX_VALUE)
      return `${roundToDec(dose, 1).toFixed(1)} ${concentration.secUnit}`;
    /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
      let doseHigh: number =
        roundToDec(
          Math.min(weight.getWeightKg() * this.basisHigh, this.maxDose),
          1
        ) / concentrationAmount;

      /* If the doses are the same return the dose in primary units */
      if (dose == doseHigh)
        return `${roundToDec(dose, 1).toFixed(1)} ${concentration.secUnit}`;

      /* If the doses are different return a range in primary units */
      return `${roundToDec(dose, 1).toFixed(1)}-${roundToDec(doseHigh, 1).toFixed(1)} ${concentration.secUnit}`;
    }
  }

  /**
   * Get the unit of the liquid form
   * @returns The unit of the liquid form Ex. "mL"
   */
  getLiquidUnit(): string {
    if (
      this.concentrations[0] == null ||
      this.concentrations[0].secAmnt == null
    )
      return '';
    return this.concentrations[0].secUnit ?? '';
  }

  /**
   * Get the string representation of the routes
   * @returns The string representation of the routes Ex. "IV / IO"
   */
  getRoutesString(): string {
    if (this.routes == null || this.routes.length == 0) return '';
    let str = '';
    for (let i = 0; i < this.routes.length; i++) {
      if (i == this.routes.length - 1) str += this.routes[i];
      else str += this.routes[i] + ' / ';
    }
    return str;
  }

  /**
   * Get the string representation of the concentration
   * @returns The string representation of the concentration Ex. "1 mg/mL"
   */
  getConcentration(i: number): string {
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      i >= this.concentrations.length
    )
      return '';

    if (this.concentrations.length > 1 && i == -1) return 'Input Required';
    else if (this.concentrations.length == 1) i = 0;

    let str =
      this.concentrations[i].firstAmnt + ' ' + this.concentrations[i].firstUnit;
    if (this.concentrations[i].secAmnt ?? 0 > 0) {
      str += '/';
      if (this.concentrations[i].secAmnt != 1.0)
        str +=
          this.concentrations[i].secAmnt + ' ' + this.concentrations[i].secUnit;
      else str += this.concentrations[i].secUnit;
    }
    return str;
  }

  /**
   * Compare this MedicationItem to another MedicationItem first by name then by concentration
   * @param other A MedicationItem to compare to
   * @returns 0 if the two items are equal, 1 if this item is greater than the other, -1 if this item is less than the other
   */
  compareTo(other: ModelItem): number {
    if (other instanceof MedicationSubItem) {
      let med = other as MedicationSubItem;
      if (this.name === med.name) {
        return this.uid === med.uid ? 0 : this.uid > med.uid ? 1 : -1;
      } else if (this.name > med.name) return 1;
      return -1;
    }
    return -1;
  }

  /**
   * Parse the basis string into a number and units
   * @returns The string representation of the basis Ex. "0.5 mg/kg"
   */
  public getBasisString(): string {
    if (this.basisHigh == globals.MAX_VALUE) {
      if (this.calcUnit == null) return this.basis + ' ' + this.basisUnit;
      return this.basis + ' ' + this.basisUnit + '/' + this.calcUnit;
    } else {
      if (this.calcUnit == null)
        return this.basis + '-' + this.basisHigh + ' ' + this.basisUnit;
      return (
        this.basis +
        '-' +
        this.basisHigh +
        ' ' +
        this.basisUnit +
        '/' +
        this.calcUnit
      );
    }
  }

  /**
   * Get the lowest liquid amount to administer to the patient based on their weight. This is used for the slider
   * @param weight The patient's weight
   * @returns The number amount to administer in lowest liquid form to the patient based on their weight
   */
  getAmountLow(weight: PatientWeight, selectedConcentration: number): number {
    /* Base case check if the concentration is null or empty */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      selectedConcentration >= this.concentrations.length
    )
      return 0.0;

    /* Check if there are multiple concentrations and if one has been selected */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      this.concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: MedicationConcentration =
      this.concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number =
      this.calcUnit === null ? this.basis : this.basis * weight.getWeightKg();
    return roundToDec(Math.min(amnt, this.maxDose) / concentrationAmount, 1);
  }

  /**
   * Get the highest liquid amount to administer to the patient based on their weight. This is used for the slider
   * @param weight The patient's weight
   * @returns The number amount to administer in highest liquid form to the patient based on their weight
   */
  getAmountHigh(weight: PatientWeight, selectedConcentration: number): number {
    /* Base case check if the concentration is null or empty */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      selectedConcentration >= this.concentrations.length
    )
      return 0.0;

    /* Check if there are multiple concentrations and if one has been selected */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      this.concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: MedicationConcentration =
      this.concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number =
      this.calcUnit === null
        ? this.basisHigh
        : this.basisHigh * weight.getWeightKg();
    return roundToDec(Math.min(amnt, this.maxDose) / concentrationAmount, 1);
  }

  /**
   * Get the interval to use for the slider
   * @param weight The patient's weight
   * @param selectedConcentration The selected concentration index
   * @returns The interval to use for the slider
   */
  getInterval(weight: PatientWeight, selectedConcentration: number): number {
    let low: number = this.getAmountLow(weight, selectedConcentration);
    let high: number = this.getAmountHigh(weight, selectedConcentration);
    if (high - low > 10) return 1;
    else if (high - low > 5) return 0.1;
    else return 0.05;
  }

  /**
   * Convert the liquid dose to solid form
   * @param doseLiquid The liquid dose to convert to solid
   * @param selectedConcentration The selected concentration index
   * @returns The converted dose in solid form
   */
  convertLiquidToSolid(
    doseLiquid: number,
    selectedConcentration: number
  ): string {
    /* Base case check if the concentration is null or empty */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      selectedConcentration >= this.concentrations.length
    )
      return '';

    /* Check if there are multiple concentrations and if one has been selected */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return '';
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      this.concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: MedicationConcentration =
      this.concentrations[selectedConcentration];

    if (concen === null || concen.secAmnt === null) return '';
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let dose: number = doseLiquid * concentrationAmount;

    let decs = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;
    return roundToDec(dose, decs).toFixed(decs) + ' ' + concen.firstUnit;
  }

  /**
   * Check if the current medication is a range medication
   * @returns True if the medication is a range medication, false otherwise
   */
  isRangeMedication(): boolean {
    return this.basisHigh !== globals.MAX_VALUE;
  }

  /**
   * Get the full calculation basis units
   * @returns The string representation of the basis units Ex. "mg/kg/hr"
   */
  getFullBasisUnit(): string {
    return this.fullBasisUnit;
  }

  inRange(weight: PatientWeight): boolean {
    return true;
  }

  getRangeHigh(): number {
    return this.rangeHigh;
  }

  getRangeLow(): number {
    return this.rangeLow;
  }

  getBasisHigh(): number {
    return this.basisHigh;
  }

  getBasisLow(): number {
    return this.basis;
  }

  getBasisUnit(): string {
    return this.basisUnit;
  }

  getParentMedication(): MedicationItem {
    return this.parentMedication;
  }

  getParentProtocol(): ProtocolItem {
    return this.parentProtocol;
  }

  getUid(): string {
    return this.uid;
  }

  getName(): string {
    return this.name;
  }

  getTitle(): string {
    return this.title;
  }

  getInstruction(): string {
    return this.instruction;
  }
  getDescription(): string {
    return this.description;
  }

  getWarning(): string {
    return this.warning;
  }

  getNote(): string {
    return this.note;
  }

  getRoutes(): string[] {
    return this.routes;
  }

  getConcentrations(): MedicationConcentration[] {
    return this.concentrations;
  }

  getRange(): MedicationRange {
    return this.range;
  }

  //Create a toString function to output the data in a readable format
  toString(): string {
    let str = '';
    for (let i = 0; i < this.parentMedication.getConcentrations().length; i++)
      str += this.getConcentration(i) + ', ';
    return `MedicationSubItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
            concentration=${str}, 
            option=${this.title}, 
            rangeLow=${this.rangeLow}, 
            rangeHigh=${this.rangeHigh}, 
            basis=${this.basis}, 
            basisHigh=${this.basisHigh}, 
            maxDose=${this.maxDose}, 
            basisUnit=${this.basisUnit}, 
            calcUnit=${this.calcUnit}, 
            maxDoseUnit=${this.maxDoseUnit}, 
            instruction=${this.instruction},
            description=${this.description}, 
            warning=${this.warning}, 
            note=${this.note}, 
            routes=${this.routes}, 
        }`;
  }
}

export default MedicationSubItem;
