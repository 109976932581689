import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  dropdownCalculatedMax,
  dropdownFixedMax,
  dropdownSolidUnits,
} from '../../_global/constants/Protocol_constants';

type DoseDialogProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  headerTitle: string;
  InputLabel: string;
  value: string;
  unit?: string;
  handleSet: (e: any) => void;
};

/* 10-19-23 Praveen: Created the popup globally for Max and Min Dose related changes */
const DoseFixedCalcDialogModal: React.FC<DoseDialogProps> = ({
  value,
  dialogVisible,
  setDialogVisible,
  headerText,
  headerTitle,
  InputLabel,
  unit,
  handleSet,
}) => {
  const [data, setData] = useState<string>(value ? value : '');

  const dropDownOptions = useMemo(() => {
    if (headerText === 'Fixed Max') {
      return dropdownFixedMax;
    } else if (headerText === 'Calculated Max') {
      return dropdownCalculatedMax;
    } else {
      return dropdownSolidUnits;
    }
  }, [headerText]);

  const initialItem = useMemo(() => {
    for (let i = 0; i < dropDownOptions.length; i++) {
      if (dropDownOptions[i].code === unit) {
        return dropDownOptions[i];
      }
    }
    return dropDownOptions[0];
  }, [unit, dropDownOptions]);

  const formik = useFormik({
    initialValues: {
      value: '',
      unit: initialItem,
    },
    validationSchema: Yup.object({
      value: Yup.string().required(),
      unit: Yup.string().required(),
    }),
    onSubmit: (values) => {},
  });

  // On change mapping the Form value
  useEffect(() => {
    if (data) {
      let num = Number(data.split(' ')[0]);
      let unit = data.split(' ')[1];

      formik.setValues({
        ...formik.values,
        value: num === 0 ? '' : num + '',
        unit: { name: unit, code: unit },
      });
    }
  }, [data]);

  const handleSubmit = () => {
    const data = {
      value: formik.values.value,
      unit: formik.values.unit.code,
    };
    handleSet(data);
  };

  return (
    <Dialog
      header={headerText}
      visible={dialogVisible}
      className="minDoseGap"
      onHide={() => setDialogVisible(false)}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            <h6 style={{ marginBottom: '5px' }} className="headerText">
              Enter {headerText}{' '}
            </h6>
            <span className="contentText">
              Enter the {headerTitle === 'Min' ? 'Min' : 'Max'} dose allowed to
              give.
            </span>
          </div>
          <div className="dialogContent">
            <div>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon-left">
                  <span style={{ display: 'flex' }}>
                    {InputLabel}:
                    <span
                      className="required-field"
                      style={{ marginBottom: '5px' }}
                    >
                      *
                    </span>
                  </span>
                </span>
                <div className=" input-container">
                  <InputNumber
                    mode="decimal"
                    value={Number(formik.values.value)}
                    maxFractionDigits={2}
                    onChange={(e) => {
                      formik.setFieldValue('value', e.value);
                    }}
                    placeholder={headerTitle}
                    required={true}
                    className="dialogInput calculationInput"
                    autoFocus={true}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="dialogInput-border"></div>
                </div>
              </div>
            </div>

            <div>
              <div className="p-inputgroup flex-1">
                <div className="p-inputgroup-addon-left">
                  <span>Units:</span>
                </div>
                {/* <InputText placeholder="" readOnly={true} className='spanInput ' /> */}
                <div className="doseDropdwnWidth p-inputgroup-addon">
                  <Dropdown
                    id="unit"
                    name="unit"
                    value={formik.values.unit}
                    options={dropDownOptions}
                    optionLabel="name"
                    className="cursorPointer selectDropdown"
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="dialogButtons">
            <Button
              data-testid="cancelBtnn"
              className="secondary-button-dialog btn-rightMargin"
              onClick={() => setDialogVisible(false)}
            >
              {' '}
              Cancel
            </Button>
            <Button
              data-testid="cancelBtnn"
              className="red-background-button  btn-rightMargin"
              onClick={() => {
                handleSet({ value: '', unit: '' });

                setDialogVisible(false);
              }}
            >
              {' '}
              Remove
            </Button>
            <Button
              data-testid="saveBtnn"
              className="primary-button"
              disabled={!formik.values.value}
              onClick={handleSubmit}
            >
              Set
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DoseFixedCalcDialogModal;
