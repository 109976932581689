import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { IoArrowBack, IoDocuments, IoHeart, IoMedkit } from 'react-icons/io5';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { FaListCheck, FaSyringe } from 'react-icons/fa6';
const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const HinckleyDatabasePage = (props: any) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  /* Uncomment when the are finished */
  const actionList: any = [
    // 'Department',
    // 'User',
    // {name: 'Category-Protocols', icon: <IoDocuments />},
    // // 'Protocol',
    { name: 'Medications', icon: <FaSyringe /> },
    // // 'Infusion',
    // // 'Electrical',
    // {name: 'Equipment', icon: <IoMedkit />},
    // {name: 'Vitals', icon: <IoHeart />},
    // // 'Log',
    // {name: 'Checklist', icon: <FaListCheck />},
    // 'Ambulance',
    // 'Notification',
  ];

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (item: any) => {
    let route = item.name.toLowerCase().replaceAll(' ', '-');
    navigate(`/actions/hinckley-database/${route}`);
  };

  const handleBack = () => {
    navigate(`/actions`);
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Hinckley Database'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        type={'protocol'}
        handleCancel={handleBack}
        descriptionTitle={'Department ID: '}
        description={HM_DB_ID}
        isCopyDescription={true}
      />
      <div className="grid-container">
        {actionList.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleItemClick(item)}
            >
              {item.icon}

              <div className="item-name">{item.name}</div>
              {/* <div className="item-count">{item.description}</div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HinckleyDatabasePage;
