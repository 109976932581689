import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { BiCopy, BiExit, BiRename, BiSolidCopy } from 'react-icons/bi';
import { InputSwitch } from 'primereact/inputswitch';
import {
  USAStatesAbrev,
  getFormattedDate,
  toTitleCase,
} from '../../../_global/common/Utils';
import { getHashedPin, getSalt } from '../../../_global/common/Encrypt';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User } from '../../../../models';
import { PiPencil } from 'react-icons/pi';
import ResetPinModal from './ResetPinModal';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { RadioButton } from 'primereact/radiobutton';
interface DepartmentProps {
  department: DepartmentItem;
  formik: any;
  isEditMode: boolean;
  logo: File | null;
  setLogo: any;
  setDepartment: any;
  parentDepartment: DepartmentItem;
}

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const EditDepartmentPage = (props: DepartmentProps) => {
  const {
    parentDepartment,
    department,
    formik,
    isEditMode,
    logo,
    setLogo,
    setDepartment,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(isEditMode);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const user: User = useSelector((state: any) => state?.user);
  const dropRef = useRef(null);
  const [isSubDepChoiceOpen, setIsSubDepChoiceOpen] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);
  const [activeDepartment, setActiveDepartment] =
    useState<DepartmentItem>(department);

  const [isResetPinModal, setIsResetPinModal] = useState(false);
  // const [options, setOptions] = useState<any[] | null>(
  //   activeDepartment.subDeps ? [] : null
  // );

  // useEffect(() => {
  //   setActiveDepartment(activeDepartment);
  // }, [activeDepartment]);

  const options: any[] | null = useMemo(() => {
    if (parentDepartment.subDeps && parentDepartment.subDeps.length > 0) {
      let options = [
        {
          label: parentDepartment.name,
          value: parentDepartment,
          img: parentDepartment.logoVerifiedUrl,
        },
      ];
      for (let i = 0; i < parentDepartment.subDeps.length; i++) {
        const subDep = parentDepartment.subDeps[i];
        options.push({
          label: subDep.name,
          value: subDep,
          img: subDep.logoVerifiedUrl,
        });
      }
      return options;
    }
    return null;
  }, [parentDepartment, parentDepartment.subDeps]);

  const objURL = useMemo(() => {
    if (logo) return URL.createObjectURL(logo);
    return activeDepartment?.logoVerifiedUrl;
  }, [activeDepartment, logo]);

  useEffect(() => {
    setIsEdit(isEditMode === true ? true : false);
  }, [isEditMode]);

  const handleBack = () => {
    navigate('/actions');
  };

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setLogo(file);

    // if (e.target.files.length) {
    //   const data = e.target.files[0];
    //   props.pdfUploaded(data);
    // }
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const SoftwareBadge = ({ softwarePlan }: { softwarePlan: string }) => {
    let text_color = '#037F02';
    let bg_color = '#C3DBB0';
    if (softwarePlan === 'Plus') {
      text_color = '#0d4d8e';
      bg_color = '#ADD8E6';
    } else if (softwarePlan === 'View Only') {
      text_color = '#3d3d3d';
      bg_color = '#D3D3D3';
    } else if (softwarePlan === 'Essentials') {
      text_color = '#BB5C34';
      bg_color = '#FEDED1';
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3px 6px',
          borderRadius: '4px',
          backgroundColor: bg_color,
          color: text_color,
          fontSize: '0.75rem',
        }}
      >
        {softwarePlan}
      </div>
    );
  };

  /* Use this effect to close the dropdown when clicked outside */
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        isSubDepChoiceOpen &&
        divRef.current &&
        !divRef.current.contains(e.target as Node)
      ) {
        setTimeout(() => {
          if (isSubDepChoiceOpen) setIsSubDepChoiceOpen(false);
        }, 150);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [divRef, isSubDepChoiceOpen]);

  console.log('Parent Department:', parentDepartment);

  return (
    <div className="">
      <ResetPinModal
        isVisible={isResetPinModal}
        department={parentDepartment}
        handleClose={() => {
          setIsResetPinModal(false);
        }}
        handleSubmit={(pin: string, salt: string, hash: string) => {
          formik.setFieldValue('pin', pin);
          formik.setFieldValue('saltedPin', salt);
          formik.setFieldValue('hashedPin', hash);
          setIsResetPinModal(false);
        }}
        value={formik.values.pin === '' ? undefined : formik.values.pin}
      />
      {/* <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              // padding: '10px 0px',
              flex: 1,
              overflowX: 'auto',
            }}
          >
            <div className={`titleStyling`}>
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6 className={`backTextLight`} onClick={handleBack}></h6>
              </div>

              <div className="titleItemContainer">
                <div className="titleTextPH">Edit Department</div>
              </div>

              {/* <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                Description here
              </h6> 
            </div>
          </div>
        </div>
        <hr />
      </div> */}
      <Row>
        <Col
          sm={8}
          style={{
            padding: '0 5rem',
            justifyContent: 'center',
            alignItems: 'center',
            justifyItems: 'center',

            // overflowX: 'hidden',
          }}
        >
          <label
            htmlFor="s"
            className="settings-general-label"
            style={{
              marginLeft: '0rem',
              fontSize: '1.25rem',
            }}
          >
            Department Information
          </label>
          <div
            className="input-container roundBorder"
            style={{
              margin: '0px 0rem',
            }}
          >
            <Row>
              <Col sm={6}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                  }}
                  className="settings-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Software Plan:</div>
                  {/* <div style={{ fontWeight: '500' }}>
                {formik.values.softwarePlan}
              </div> */}
                  <SoftwareBadge softwarePlan={formik.values.softwarePlan} />
                </div>
                <div
                  style={{ display: 'flex', marginTop: '5px' }}
                  className="settings-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Department Code:</div>
                  <div style={{ fontWeight: '500' }}>
                    {formik.values.departmentCode}
                    <span>
                      {isCopied && isCopied === formik.values.departmentCode ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(formik.values.departmentCode, e)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
                {activeDepartment.isNemsisConfig &&
                  activeDepartment.model?.createdAt && (
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Created:</div>
                      <div style={{ fontWeight: '500' }}>
                        {getFormattedDate(
                          activeDepartment.model.createdAt,
                          true
                        )}
                      </div>
                    </div>
                  )}
                {activeDepartment.isNemsisConfig &&
                  activeDepartment?.location && (
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Location:</div>
                      <div style={{ fontWeight: '500' }}>
                        {activeDepartment.location}
                      </div>
                    </div>
                  )}
              </Col>
              <Col sm={6}>
                {activeDepartment.isNemsisConfig ? (
                  <div>
                    <div
                      style={{ display: 'flex', marginTop: '5px' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>GNIS Code:</div>
                      <div style={{ fontWeight: '500' }}>
                        {formik.values.gnisCode}
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>State ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {formik.values.stateIDEMS}
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Agency Number:</div>
                      <div style={{ fontWeight: '500' }}>
                        {formik.values.agencyNumEMS}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {activeDepartment.model?.createdAt && (
                      <div
                        style={{ display: 'flex' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Created:</div>
                        <div style={{ fontWeight: '500' }}>
                          {getFormattedDate(
                            activeDepartment.model.createdAt,
                            true
                          )}
                        </div>
                      </div>
                    )}
                    {activeDepartment?.location && (
                      <div
                        style={{ display: 'flex' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Location:</div>
                        <div style={{ fontWeight: '500' }}>
                          {activeDepartment.location}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <label
            htmlFor="neonateCutoff"
            className="settings-general-label"
            style={{
              fontSize: '1.25rem',
              marginTop: '3rem',
              marginLeft: '0rem',
            }}
          >
            General
          </label>
          <label
            htmlFor="Name"
            className="settings-general-label"
            style={{
              marginTop: '1rem',
              marginLeft: '0px',
            }}
          >
            Name
            <span className="required-field ">
              *
              <BiRename
                className="refresh-icon"
                onClick={() => {
                  formik.setFieldValue('name', toTitleCase(formik.values.name));
                }}
                size="1.25rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '0px' }}>
            This is the name of the department that is reflected on the OneDose
            app.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={false}
            />
            <div className="input-border"></div>
          </div>

          {user.type === 'ADMIN' && (
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="neonateCutoff"
                  className="settings-general-label"
                >
                  Neonate Cutoff (Kg)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="neonateCutoff"
                    name="neonateCutoff"
                    data-testid="neonateCutoff"
                    required={true}
                    value={formik.values.neonateCutoff + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('neonateCutoff', 0);
                        formik.setFieldError(
                          'neonateCutoff',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('neonateCutoff', num);
                    }}
                    disabled={false}
                  />
                  <div className="input-border"></div>
                </div>
              </Col>
              <Col sm={6}>
                <label
                  htmlFor="pediatricCutoff"
                  className="settings-general-label"
                >
                  Pediatric Cutoff (Kg)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="pediatricCutoff"
                    name="pediatricCutoff"
                    data-testid="pediatricCutoff"
                    required={true}
                    value={formik.values.pediatricCutoff + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('pediatricCutoff', 0);
                        formik.setFieldError(
                          'pediatricCutoff',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('pediatricCutoff', num);
                    }}
                    disabled={false}
                  />
                  <div className="input-border"></div>
                </div>
                {formik.errors.pediatricCutoff !== '' && (
                  <div
                    className="errorText"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '16px',
                    }}
                  >
                    {formik.errors.pediatricCutoff}
                  </div>
                )}
              </Col>
              <p className="sidebarText" style={{ marginLeft: '30px' }}>
                This is the universal cutoff for neonate and pediatrics in the
                OneDose app.
              </p>
            </Row>
          )}
          {activeDepartment.parentDepID == null && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                // justifyContent: 'center',
                // alignItems: 'center',
                marginTop: '3rem',
                flexDirection: 'column',
              }}
            >
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                }}
              >
                Sign-Up Pin
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This is the pin that is used to verify which department that the
                user is signing up for.
              </p>
              <Button
                data-testid="set-Bttn"
                className="primary-button btn-rightMargin"
                disabled={false}
                style={{
                  marginTop: '0.5rem',
                  width: '50%',
                  marginLeft: '0.75rem',
                }}
                onClick={() => {
                  setIsResetPinModal(true);
                }}
              >
                Reset Sign-Up Pin
              </Button>
            </div>
          )}

          {(!parentDepartment.isMultiDep ||
            (parentDepartment.isMultiDep &&
              activeDepartment.parentDepID !== null)) && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                // justifyContent: 'center',
                // alignItems: 'center',
                marginTop: '3rem',
                flexDirection: 'column',
              }}
            >
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                }}
              >
                Active Status
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This is toggles whether users on OneDose can see the department
                or if it is hidden from them.
              </p>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="active_status_true"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.activeStatus !== false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="active_status_true"
                    name="active_status_true"
                    data-testid="active_status_true"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.activeStatus !== false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('activeStatus', true);
                    }}
                  />
                  Activate this sub-department and users in the OneDose app will
                  be able to choose this sub-department.
                </label>
              </div>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="active_status_false"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.activeStatus === false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="active_status_false"
                    name="active_status_false"
                    data-testid="active_status_false"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.activeStatus === false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('activeStatus', false);
                    }}
                  />
                  Hide this sub-department from users in the OneDose app.
                </label>
              </div>
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                  marginTop: '3rem',
                }}
              >
                Infusion Calculation
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This toggles whether the infusion values should be calculated by
                weight or to just show the calculation basis. Normally
                departments with infusion pumps choose to just display the
                calculation basis.
              </p>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="infusion_status_true"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.infusionCalculation !== false
                        ? ''
                        : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="infusion_status_true"
                    name="infusion_status_true"
                    data-testid="infusion_status_true"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.infusionCalculation !== false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('infusionCalculation', true);
                    }}
                  />
                  Enable infusion calculation based of of weight for{' '}
                  {activeDepartment?.name}. Ex. 80 mL/hr.
                </label>
              </div>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="infusion_status_false"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.infusionCalculation === false
                        ? ''
                        : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="infusion_status_false"
                    name="infusion_status_false"
                    data-testid="infusion_status_false"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.infusionCalculation === false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('infusionCalculation', false);
                    }}
                  />
                  Only show the calculation basis for {activeDepartment?.name}.
                  Ex. 1 mg/kg/hr.
                </label>
              </div>
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                  marginTop: '3rem',
                }}
              >
                OneWeight Enabled
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This toggles whether the ActualWeight tab and bluetooth
                capabilities are enabled on the OneDose app.
              </p>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="oneweight_status_true"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.oneweightEnabled !== false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="oneweight_status_true"
                    name="oneweight_status_true"
                    data-testid="oneweight_status_true"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.oneweightEnabled !== false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('oneweightEnabled', true);
                    }}
                  />
                  Enable the Actual Weight and Bluetooth capabilities for{' '}
                  {activeDepartment?.name}. This allows you to connect to the
                  OneWeight scale.
                </label>
              </div>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="oneweight_status_false"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.oneweightEnabled === false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="oneweight_status_false"
                    name="oneweight_status_false"
                    data-testid="oneweight_status_false"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.oneweightEnabled === false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('oneweightEnabled', false);
                    }}
                  />
                  Disable the Actual Weight and Bluetooth capabilities for{' '}
                  {activeDepartment?.name}. This will remove the Actual Weight
                  tab.
                </label>
              </div>
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                  marginTop: '3rem',
                }}
              >
                Age-Based Logic
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This toggles whether age based logic is enabled on the OneDose
                app. This will allow medics to input a age of the patient and
                the app will filter out any protocols or treatment options that
                are not applicable to that age group.
              </p>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="ageFilterEnabled_status_true"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.ageFilterEnabled !== false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="ageFilterEnabled_status_true"
                    name="ageFilterEnabled_status_true"
                    data-testid="ageFilterEnabled_status_true"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.ageFilterEnabled !== false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('ageFilterEnabled', true);
                    }}
                  />
                  Enable the age-based logic for {activeDepartment?.name}. This
                  allows the users to use age as an input for the patient.
                </label>
              </div>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="ageFilterEnabled_status_false"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.ageFilterEnabled === false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="ageFilterEnabled_status_false"
                    name="ageFilterEnabled_status_false"
                    data-testid="ageFilterEnabled_status_false"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.ageFilterEnabled === false ? true : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('ageFilterEnabled', false);
                    }}
                  />
                  Disable the age-based logic for {activeDepartment?.name}. This
                  will remove the age input for the patient.
                </label>
              </div>
            </div>
          )}

          {user.type === 'ADMIN' && (
            <div
              style={{
                marginTop: '5rem',
              }}
            >
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '0.5rem',
                  fontSize: '1.25rem',
                }}
              >
                ePCR Integration Settings
              </label>
              <div
                className="input-container "
                style={{
                  // margin: '0px 1rem',
                  padding: '1rem',
                }}
              >
                <Row>
                  <Col sm={4}>
                    <label
                      htmlFor="gnisCode"
                      className="settings-general-label"
                    >
                      GNIS Code:
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="gnisCode"
                        name="gnisCode"
                        data-testid="gnisCode"
                        required={true}
                        value={formik.values.gnisCode + ''}
                        onChange={(e: any) => {
                          formik.setFieldValue('gnisCode', e.target.value);
                        }}
                      />
                      <div className="input-border"></div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <label htmlFor="Name" className="settings-general-label">
                      State ID:
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="stateIDEMS"
                        name="stateIDEMS"
                        data-testid="stateIDEMS"
                        required={true}
                        value={formik.values.stateIDEMS + ''}
                        onChange={(e: any) => {
                          formik.setFieldValue('stateIDEMS', e.target.value);
                        }}
                        disabled={false}
                      />
                      <div className="input-border"></div>
                    </div>
                  </Col>

                  <Col sm={4}>
                    <label
                      htmlFor="agencyNumEMS"
                      className="settings-general-label"
                    >
                      Agency Number:
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="agencyNumEMS"
                        name="agencyNumEMS"
                        data-testid="agencyNumEMS"
                        required={true}
                        value={formik.values.agencyNumEMS + ''}
                        onChange={(e: any) => {
                          formik.setFieldValue('agencyNumEMS', e.target.value);
                        }}
                        disabled={false}
                      />
                      <div className="input-border"></div>
                    </div>
                  </Col>
                </Row>
                <p className="sidebarText" style={{ marginLeft: '10px' }}>
                  These are used for the ePCR Integration
                </p>
              </div>
            </div>
          )}
        </Col>
        <Col sm={4}>
          {options && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                // justifyContent: 'center',
                // alignItems: 'center',
                marginTop: '10px',
                flexDirection: 'column',
              }}
            >
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '0.25rem',
                }}
              >
                Current Department
              </label>
              <div
                className="border hover-raise-elevation"
                onClick={(e) => {
                  if (!isSubDepChoiceOpen)
                    setIsSubDepChoiceOpen(!isSubDepChoiceOpen);
                }}
              >
                {activeDepartment && activeDepartment.logoVerifiedUrl && (
                  <img
                    className="department-logo"
                    src={activeDepartment.logoVerifiedUrl}
                    alt="Agency Logo"
                  />
                )}
                {activeDepartment && (
                  <div
                    className="departmentName no-select"
                    style={{
                      marginLeft: '1rem',
                      marginRight: '6px',
                      flex: 1,
                    }}
                  >
                    {activeDepartment.name}
                  </div>
                )}
                <FaChevronDown
                  size={12}
                  style={{
                    marginRight: '1rem',
                  }}
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              flexDirection: 'row',
              padding: '0.25rem 1rem',
              gap: '0.5rem',
              borderRadius: '0.5rem',
              flex: 1,
              marginTop: options ? '1rem' : '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                flexDirection: 'column',
              }}
              ref={dropRef}
              onClick={() => {
                const fileInput = document.getElementById('fileInput');
                fileInput?.click();
              }}
            >
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                className="pdfInputBox"
                id="fileInput"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '1.5rem',
                }}
              >
                {activeDepartment?.name} Logo
              </label>
              <img
                className="department-logo-settings"
                src={objURL ? objURL : ''}
                alt="Agency Logo"
                style={{
                  marginLeft: '3rem',
                }}
              />
              {/* Create a floating Edti Badge */}
              <div
                className="edit-image-container"
                style={{
                  top: options ? '17.5rem' : '11.5rem',
                }}
              >
                <PiPencil size="1.0rem" />
                <span
                  className="settings-general-label no-select"
                  style={{
                    fontSize: '1rem',
                    color: '#FFF',
                  }}
                >
                  Edit
                </span>
              </div>
            </div>
          </div>
          {/*  <div>
            <label
              htmlFor="s"
              className="settings-general-label"
              style={{
                marginLeft: '0.5rem',
              }}
            >
              Enable Settings
            </label>
            <div
              className="input-container roundBorder"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0.25rem',
                gap: '0.75rem',
              }}
            >
              <div
                className="input-container"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                  // marginTop: '10px',
                }}
              >
                <label
                  htmlFor="isSolidOnly"
                  className="settings-general-label"
                  style={{ flexDirection: 'row' }}
                >
                  Active Status:
                </label>
                <InputSwitch
                  name="isSolidOnly"
                  style={{ marginLeft: '10px', height: '1.5rem' }}
                  // checked={true}
                  checked={formik.values.activeStatus === false ? false : true}
                  onChange={(e) => {
                    formik.setFieldValue('activeStatus', e.value);
                  }}
                />
              </div>
              <div
                className="input-container"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                  // marginTop: '10px',
                }}
              >
                <label
                  htmlFor="isSolidOnly"
                  className="settings-general-label"
                  style={{ flexDirection: 'row' }}
                >
                  Infusion Calculation:
                </label>
                <InputSwitch
                  name="isSolidOnly"
                  style={{
                    marginLeft: '10px',
                    height: '1.5rem',
                    // width: '5rem',
                  }}
                  // checked={true}
                  checked={
                    formik.values.infusionCalculation === false ? false : true
                  }
                  onChange={(e) => {
                    formik.setFieldValue('infusionCalculation', e.value);
                  }}
                />
              </div>
            </div>
          </div> */}
          {user.type === 'ADMIN' && (
            <div>
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '0.5rem',
                  marginTop: '1rem',
                }}
              >
                Super Admin Settings
              </label>
              <div
                className="input-container roundBorder"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0.25rem',
                  gap: '0.75rem',
                }}
              >
                {activeDepartment && (
                  <div
                    style={{ display: 'flex', marginTop: '5px' }}
                    className="settings-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>UID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {activeDepartment?.id}
                      <span>
                        {isCopied && isCopied === activeDepartment?.id ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(
                                activeDepartment.id ? activeDepartment.id : '',
                                e
                              )
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label
                    htmlFor="isSolidOnly"
                    className="settings-general-label"
                    style={{ flexDirection: 'row' }}
                  >
                    Realtime Updating:
                  </label>
                  <InputSwitch
                    name="isSolidOnly"
                    style={{
                      marginLeft: '10px',
                      height: '1.5rem',
                      // width: '5rem',
                    }}
                    // checked={true}
                    checked={
                      formik.values.realTimeEnabled === false ? false : true
                    }
                    onChange={(e) => {
                      formik.setFieldValue('realTimeEnabled', e.value);
                    }}
                  />
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label htmlFor="Location" className="settings-general-label">
                    Software Plan:
                  </label>
                  <Dropdown
                    value={formik.values.softwarePlan}
                    options={[
                      { label: 'Plus', value: 'Plus' },
                      { label: 'Essentials', value: 'Essentials' },
                      { label: 'View Only', value: 'View Only' },
                      { label: 'Premium', value: 'Premium' },
                    ]}
                    onChange={(value: any) => {
                      formik.setFieldValue('softwarePlan', value);
                    }}
                    style={{
                      marginTop: '-13px',
                      marginLeft: '16px',
                      padding: 0,
                    }}
                    buttonColor={'#E0EADD'}
                    textColor={'#00534C'}
                  />
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label htmlFor="Location" className="settings-general-label">
                    Location:
                  </label>
                  <Dropdown
                    value={formik.values.location}
                    options={USAStatesAbrev}
                    onChange={(value: any) => {
                      formik.setFieldValue('location', value);
                    }}
                    style={{
                      marginTop: '-13px',
                      marginLeft: '16px',
                      padding: 0,
                    }}
                    buttonColor={'#E0EADD'}
                    textColor={'#00534C'}
                  />
                </div>

                {formik.values.pin !== '' && (
                  <div>
                    <label
                      className="settings-general-label"
                      style={{ marginTop: '20px' }}
                    >
                      Hashed Pin:
                    </label>
                    <p className="sidebarText" style={{ marginLeft: '10px' }}>
                      {formik.values.hashedPin}
                      {isCopied !== formik.values.hashedPin && (
                        <BiCopy
                          size="1rem"
                          className="refresh-icon"
                          onClick={(e) =>
                            handleCopy(formik.values.hashedPin, e)
                          }
                        />
                      )}
                      {isCopied === formik.values.hashedPin && (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="refresh-icon"
                        />
                      )}
                    </p>
                    <label
                      className="settings-general-label"
                      style={{ marginTop: '20px' }}
                    >
                      Salt Pin:
                    </label>
                    <p className="sidebarText" style={{ marginLeft: '10px' }}>
                      {formik.values.saltedPin}
                      {isCopied !== formik.values.saltedPin && (
                        <BiCopy
                          size="1rem"
                          className="refresh-icon"
                          onClick={(e) =>
                            handleCopy(formik.values.saltedPin, e)
                          }
                        />
                      )}
                      {isCopied === formik.values.saltedPin && (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="refresh-icon"
                        />
                      )}
                    </p>
                    <label
                      className="settings-general-label"
                      style={{ marginTop: '20px' }}
                    >
                      Pin:
                    </label>
                    <p className="sidebarText" style={{ marginLeft: '10px' }}>
                      {formik.values.pin}
                      {isCopied !== formik.values.pin && (
                        <BiCopy
                          size="1rem"
                          className="refresh-icon"
                          onClick={(e) => handleCopy(formik.values.pin, e)}
                        />
                      )}
                      {isCopied === formik.values.pin && (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="refresh-icon"
                        />
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {options && isSubDepChoiceOpen && (
            <ul className="c-dropdown_list-settings" ref={divRef}>
              {options.map((option: any) => {
                return (
                  <li
                    className="c-multi-select-dropdown__option"
                    key={option.value.id}
                    onClick={(e) => {
                      // if (globals.debug) console.log('Option clicked');
                      e.stopPropagation();
                      setDepartment(option.value);
                      setActiveDepartment(option.value);
                      setIsSubDepChoiceOpen(false);
                      // handleUserInfo();
                    }}
                  >
                    <div className={`c_dropdown_option`}>
                      {option && option.value.logoVerifiedUrl && (
                        <img
                          className="department-logo"
                          src={option.value.logoVerifiedUrl}
                          alt="Agency Logo"
                        />
                      )}
                      {option && (
                        <div
                          className="departmentName no-select"
                          style={{
                            marginLeft: '1rem',
                            marginRight: '6px',
                            flex: 1,
                          }}
                        >
                          {option.label}
                        </div>
                      )}
                      <FaChevronRight
                        size={12}
                        style={{
                          marginRight: '1rem',
                        }}
                      />
                      {/* <span>{labelField(option)}</span> */}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditDepartmentPage;
