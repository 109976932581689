import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MedicationItem, {
  getConcentrationString,
} from '../../../../data/model/MedicationItem';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../../components/Search/SearchBar';
import { Accordion } from 'react-bootstrap';
import { ViewportList } from 'react-viewport-list';
import { FaChevronRight } from 'react-icons/fa6';
import { ProgressStatus } from '../../../../API';
import { getFormattedDate } from '../../../_global/common/Utils';
import { Medication, User } from '../../../../models';
import { filter } from 'lodash';

const ListArchiveMedications = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: medications, department, database } = state;
  const [user, setUser] = useState<User | undefined>();
  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filteredMedications, setFilteredMedications] =
    useState<Medication[]>(medications);

  useEffect(() => {
    let filtered = (medications as Medication[]).filter(
      (medication: any) =>
        medication.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (medication.modifiedBy &&
          medication.modifiedBy
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
    );
    filtered = filtered.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setFilteredMedications(filtered);
  }, [searchQuery, medications]);

  const handleItemClick = (medications: MedicationItem) => {};

  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '1fr 3fr 2fr 2fr 2fr' +
      // (department.subDeps && department.subDeps.length > 0 ? ' 2fr ' : '') +
      ' 1fr',
  };

  const handleBack = () => {
    navigate(`/archive`, { state: department });
  };

  const calculateDoses = (medication: Medication) => {
    let doses = 0;
    medication.protocolOptions.forEach((option) => {
      doses += option.options.length;
    });
    return doses;
  };

  const getModifiedBy = async (medication: Medication) => {
    let modifiedBy = await database.users.get(medication.modifiedBy);
    return modifiedBy;
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={`Medications: ${filteredMedications.length} items`}
        status={ProgressStatus.ARCHIVE}
        page={department.name}
        rightSideBtn={'edit'}
        type={'protocol'}
      />
      <Row>
        <Col sm={12}>
          <SearchBar
            value={searchQuery}
            containerStyle={{ width: '60%' }}
            onChange={(searchTerm: string) => setSearchQuery(searchTerm)}
            placeholder={'Search Protocols...'}
          />
        </Col>
      </Row>

      <div className="accordion-container">
        <div style={{ borderBottom: '1px solid #ccc' }}>
          <Accordion>
            <div style={rowStyle} className="departmentItemNoHover">
              <h6 className="departmentItemText">Version</h6>
              <h6 className="departmentItemText">Name</h6>
              <h6 className="departmentItemText">Concentration</h6>
              <h6 className="departmentItemText">Archive Date</h6>
              <h6 className="departmentItemText">Archived By</h6>
              <h6 className="departmentItemText">Doses</h6>
            </div>
          </Accordion>
        </div>
        <div className="list-container">
          <ViewportList items={filteredMedications}>
            {(item: Medication, index) => {
              // let username = item.modifiedBy
              //   ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
              //   : 'Hinckley Medical';
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  //   onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.version}</h6>
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">
                      {getConcentrationString(item.concentration[0])}
                    </h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.updatedAt ? item?.updatedAt : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {user
                        ? user.firstName + ' ' + user.lastName
                        : 'Hinckley Medical'}
                    </h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {calculateDoses(item)}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListArchiveMedications;
