import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiArrowBack, BiSolidChevronRight } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import SearchBar from '../../../../components/Search/SearchBar';

interface AddProtocolSideoutProps {
  handleClose: () => void;
  handleAdd: (items: ProtocolItem[]) => void;
  isVisible: boolean;
  selectedItems?: ProtocolItem[];
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const EquipmentAddProtocolSideout: React.FC<AddProtocolSideoutProps> = (
  props
) => {
  const { isVisible, selectedItems, handleClose, handleAdd } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const [value, setValue] = useState<any>('');
  const [protocolList, setProtocolList] = useState<ProtocolItem[]>([]);

  const [selectedProtocols, setSelectedProtocols] = useState<ProtocolItem[]>(
    []
  );

  /* 11-07-23 Arul: handle function for radio buttton onChange */
  const handleCheckboxChange = (item: ProtocolItem) => {
    if (selectedProtocols.includes(item)) {
      setSelectedProtocols(selectedProtocols.filter((i) => i !== item));
    } else {
      setSelectedProtocols([...selectedProtocols, item]);
    }
  };

  /* 10-13-23 Praveen: function for handling back to parent side out*/
  const handleBack = () => {
    setSelectedProtocols([]);
    setValue('');
  };

  useEffect(() => {
    if (database.protocols) {
      setProtocolList(database.protocols);
    }
  }, [database.protocols]);

  useEffect(() => {
    if (selectedItems) {
      setSelectedProtocols(selectedItems);
    }
  }, [selectedItems]);

  /* 10-13-23 Praveen: For filtering the protocol sub item list */
  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        const filterData = database.protocols?.filter((item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        setProtocolList(filterData);
      } else {
        setProtocolList(database.protocols);
      }
    },
    [value, protocolList, database, database.protocols]
  );

  /* 10-13-23 Praveen: to handle the search input*/
  const handleSearch = (text: string) => {
    setValue(text);
    handleFilter(text);
  };
  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add
      </span>
    </div>
  );

  return (
    <div>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleClose}
        style={{
          width: '25%',
          minWidth: '400px',
        }}
        className={'protocolSidebar sidebarWidth'}
      >
        <div style={{ height: '100%', padding: '10px' }}>
          <div
            className="buttonContainer contentTitleLarge hoverText"
            onClick={handleBack}
          >
            <span className="headerTilte">
              <BiArrowBack
                className="header-icon cursorPointer"
                style={{ paddingLeft: '4px' }}
              />
              <HiPlus className="header-icon " style={{ marginLeft: '8px' }} />{' '}
              Protocols
            </span>
          </div>
          <div className="contentText">
            Add this equipment item to multiple protocols by selecting them
            below.
          </div>
          <div className="contentLabelBold">Select Protocols</div>
          {selectedProtocols.length > 0 && (
            <div className="contentText">
              {selectedProtocols.length} Protocols Selected
            </div>
          )}
          {/* <div className="search-custom-input">
            <span className="icon_search_pad">
              <FiSearch className="icon-normal" />
            </span>
            <InputText
              value={value}
              onChange={handleSearch}
              className="search-inputs"
            />
          </div> */}
          <SearchBar
            value={value}
            onChange={handleSearch}
            placeholder="Search"
            containerStyle={{ width: '100%' }}
          />
          <div
            style={{ maxHeight: '60%' }}
            className="contentBorder protocolCalculationPad secondaryListScroll"
          >
            <ViewportList items={protocolList}>
              {(item: ProtocolItem, index: any) => (
                <div
                  key={item?.name + index}
                  onClick={() => {
                    handleCheckboxChange(item);
                  }}
                  className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                    // selectedData.type === 'Checklist' &&
                    // options?.length !== index + 1
                    //   ? 'contentUnderline '
                    //   : ''
                    ''
                  } ${
                    protocolList.length !== index + 1 ? 'contentUnderline ' : ''
                  }`}
                >
                  <RadioButton
                    inputId="pairedProtocol"
                    name="pairedProtocol"
                    className="radioBtnColor"
                    style={{ marginRight: '10px' }}
                    value={item.name}
                    checked={
                      selectedProtocols.find((i) => i.uid === item.uid)
                        ? true
                        : false
                    }
                    onChange={() => {
                      handleCheckboxChange(item);
                    }}
                  />
                  <div>{item.name}</div>
                </div>
              )}
            </ViewportList>
          </div>
          <div className="btn_Bottom">
            <Button
              data-testid="cancel"
              className="secondary-button btn-rightMargin"
              onClick={handleClose}
            >
              X Cancel
            </Button>
            <Button
              data-testid="save"
              className="primary-button"
              disabled={selectedProtocols.length === 0}
              onClick={() => {
                handleAdd(selectedProtocols);
              }}
            >
              + Add
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default EquipmentAddProtocolSideout;
