import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import {
  AiOutlineClose,
  AiOutlineCloseCircle,
  AiOutlinePlus,
} from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';
import { IoMenu } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleSetProtocolData,
  handleSetSelectedDoseValue,
} from '../../../../store/actions';
import { newChecklist } from '../../../_global/constants/model';
import ConfirmModal from '../../Modal/ConfirmModal';
import FormItem from '../../../../data/model/FormItem';
import { useFormik } from 'formik';
import { BiArrowBack } from 'react-icons/bi';

interface ChecklistSideoutProps {
  type?: any;
  data?: any;
  modalName?: any;
  onRemove?: (dose: any) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  editDose?: boolean;
  doseIndex?: any;
  onSubmit?: (dose: any, prevDose?: FormItem | any) => void;
}

/*11-17-23 Arul:  Created common component for checklist sideout view*/
const ChecklistSideout: React.FC<ChecklistSideoutProps> = ({
  doseIndex,
  data,
  visible,
  setVisible,
  editDose,
  onRemove,
  onSubmit,
}) => {
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  const [checklistOrder, setChecklistOrder] = useState([1]);
  const [editedNames, setEditedNames] = useState<string[]>(['']);
  const selectedDose = useSelector(
    (state: any) => state.protocol.dose.selectedDose
  );
  const selectedProtocol = useSelector(
    (state: any) => state.protocol.selectedProtocol
  );
  const [initialData, setIsinitialData] = useState(selectedDose);
  const dispatch = useDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [autoFocuaIndex, setAutoFocusIndex] = useState();

  /*11-17-23 Arul: function for textinput change*/
  const handleNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedNames = [...editedNames];
    updatedNames[index] = e.target.value;
    setEditedNames(updatedNames);
  };

  /**
   * Author: Guruprasad V (01-24-2024)
   * If editDose and data are truthy, it dispatches the handleSetSelectedDoseValue action with the data,
   * sets the isInitialData state to the data.
   * Otherwise, it dispatches the handleSetSelectedDoseValue action with a newChecklist object,
   * sets the isInitialData state to the newChecklist,
   * resets the editedNames state to an empty array,
   * and resets the checklistOrder state to the initial state [1].
   *
   * @param editDose - indicating whether the dose is being edited.
   * @param data - The data for the checklist.
   */

  useEffect(() => {
    if (editDose && data) {
      dispatch<any>(handleSetSelectedDoseValue(data));
      setIsinitialData(data);
    } else {
      dispatch<any>(handleSetSelectedDoseValue(newChecklist));
      setIsinitialData(newChecklist);
      setEditedNames(['']); // Reset edited names to an empty array
      setChecklistOrder([1]); // Reset checklist order to initial state
    }
  }, [editDose, data]);

  /*11-17-23 Arul: function for dragging*/
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedChecklistOrder = Array.from(checklistOrder);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    reorderedChecklistOrder.splice(
      destinationIndex,
      0,
      reorderedChecklistOrder.splice(sourceIndex, 1)[0]
    );
    setChecklistOrder(reorderedChecklistOrder);
    setEditedNames((prevNames) => {
      const updatedNames = [...prevNames];
      const movedName = updatedNames.splice(sourceIndex, 1)[0];
      updatedNames.splice(destinationIndex, 0, movedName);
      return updatedNames;
    });
  };

  /*11-17-23 Arul: update the state the single double toggle*/
  const handleDispatchSingleDouple = useCallback(
    (value: any) => {
      if (value === 'single') {
        const data = { ...selectedDose, isDouble: false };
        dispatch<any>(handleSetSelectedDoseValue(data));
      } else {
        const data = { ...selectedDose, isDouble: true };
        dispatch<any>(handleSetSelectedDoseValue(data));

        if (checklistOrder.length % 2 !== 0) {
          const value = [...checklistOrder];
          const name = [...editedNames];
          let nextNumber = value[value.length - 1] + 1; // Starting from 5
          value.push(nextNumber);
          name.push('');
          setEditedNames(name);
          setChecklistOrder(value);
        }
      }
    },
    [
      selectedDose,
      checklistOrder,
      editedNames,
      setEditedNames,
      setChecklistOrder,
    ]
  );

  /*11-17-23 Arul: update the state the normal urgent toggle*/
  const handleDispatchNormalUrgent = useCallback(
    (value: any) => {
      if (value === 'normal') {
        const data = { ...selectedDose, isUrgent: false };
        dispatch<any>(handleSetSelectedDoseValue(data));
      } else {
        const data = { ...selectedDose, isUrgent: true };
        dispatch<any>(handleSetSelectedDoseValue(data));
      }
    },
    [selectedDose]
  );

  /*11-20-23 Arul: update state for title text input*/
  const handleDispatchtitle = useCallback(
    (e: any) => {
      const data = { ...selectedDose, title: e.target.value };
      dispatch<any>(handleSetSelectedDoseValue(data));
    },
    [selectedDose]
  );

  /*11-20-23 Arul:  function to add new rows*/
  const handleClick = () => {
    const data = [...checklistOrder];
    const name = [...editedNames];
    let nextNumber: any = data[data.length - 1] + 1; // Starting from 5
    setAutoFocusIndex(nextNumber);
    if (selectedDose.isDouble) {
      if (checklistOrder.length % 2 === 0) {
        data.push(nextNumber, nextNumber + 1);
        name.push('', '');
        setEditedNames(name);
        setChecklistOrder(data);
      } else {
        data.push(nextNumber);
        name.push('');
        setEditedNames(name);
        setChecklistOrder(data);
      }
    } else {
      data.push(nextNumber);
      name.push('');
      setEditedNames(name);
      setChecklistOrder(data);
    }
  };

  /*11-20-23 Arul:  function to delete item*/
  const handleDeleteProtocol = useCallback(
    (index?: any) => {
      if (index) {
        const dataIndex: any = [...checklistOrder];
        const dataName: any = [...editedNames];
        dataIndex.splice(index, 1);
        dataName.splice(index, 1);
        setEditedNames(dataName);
        setChecklistOrder(dataIndex);
      }
    },
    [
      checklistOrder,
      selectedProtocol,
      editedNames,
      selectedIndex,
      setChecklistOrder,
      setEditedNames,
    ]
  );

  /*11-20-23 Arul: convert double stucture to array*/
  const doubleStuctureData = useCallback(
    (data: any) => {
      const transformedArray: any = [];
      data.forEach((item: any) => {
        transformedArray.push(item.firstItem, item.secItem);
      });
      return transformedArray;
    },
    [selectedDose.items]
  );

  /*11-20-23 Arul: convert single stucture to array*/
  const singleStuctureData = useCallback(
    (data: any) => {
      return data.map((item: any) => item.firstItem || item.secItem);
    },
    [selectedDose.options]
  );

  /*11-20-23 Arul: get the items length*/
  const itemsLength = useMemo(() => {
    if (selectedDose.isDouble) {
      const length: any = selectedDose?.options?.length * 2;
      return Array.from({ length }, (_, index) => index + 1);
    } else {
      const length: any = selectedDose?.options?.length;
      return Array.from({ length }, (_, index) => index + 1);
    }
  }, [selectedDose]);

  /*11-20-23 Arul:mapping the array item*/
  useEffect(() => {
    if (selectedDose?.options?.length > 0) {
      setChecklistOrder(itemsLength);
      if (selectedDose.isDouble) {
        const data = doubleStuctureData(selectedDose.options);
        setEditedNames(data);
      } else {
        const data = singleStuctureData(selectedDose.options);
        setEditedNames(data);
      }
    }
  }, [selectedDose.options, setEditedNames, setChecklistOrder]);

  /*11-20-23 Arul: convert array to single stucture*/
  const isSingleData = useMemo(() => {
    return editedNames
      .map((item: any) => {
        return {
          firstItem: item,
          secItem: '',
        };
      })
      .filter(Boolean);
  }, [editedNames]);

  /*11-20-23 Arul: convert array to double stucture*/
  const isDoubleData = useMemo(() => {
    const pairedItems = [];
    for (let i = 0; i < editedNames.length; i += 2) {
      const firstItem = editedNames[i] || '';
      const secItem = editedNames[i + 1] || '';
      pairedItems.push({
        firstItem,
        secItem,
      });
    }
    return pairedItems.filter(
      (item) => item.firstItem !== '' || item.secItem !== ''
    );
  }, [editedNames]);

  const handleSubmit = () => {
    const newItems = selectedDose.isDouble ? isDoubleData : isSingleData;
    const updatedSelectedDose = {
      ...selectedDose,
      items: newItems,
    };

    // Passing the initialData only when editing an existing group
    if (onSubmit) {
      onSubmit(updatedSelectedDose, editDose ? initialData : null);
    }
    setVisible(false);
  };

  /*11-20-23 Arul: check any changes made in sideout*/
  const isDataChange: any = useMemo(() => {
    const items =
      editedNames[0] !== ''
        ? selectedDose.isDouble
          ? isDoubleData
          : isSingleData
        : [];
    const doseData = { ...selectedDose };
    const initialDataValue = {
      isDouble: initialData?.isDouble,
      isUrgent: initialData?.isUrgent,
      title: initialData?.title,
      items: initialData?.options,
    };
    const modifiedData = {
      isDouble: doseData?.isDouble,
      isUrgent: doseData?.isUrgent,
      title: doseData?.title,
      items: items,
    };
    const isCheck = _.isEqual(modifiedData, initialDataValue);
    return isCheck;
  }, [selectedDose, initialData, editedNames, isDoubleData, isSingleData]);

  /*11-20-23 Arul: handle close sideout*/
  const handleCloseSideout = () => {
    if (!isDataChange) {
      setIsWarningModal(true);
    } else {
      setVisible(false);
      setIsinitialData(null);
      dispatch<any>(handleSetSelectedDoseValue(''));
    }
  };
  /*11-20-23 Arul: handle close changes warning modal*/
  const handleCloseWarningModal = () => {
    setVisible(false);
    setIsinitialData(null);
    dispatch<any>(handleSetSelectedDoseValue(''));
  };

  const handleDeleteCheckListGroup = () => {
    if (onRemove) onRemove(data);
    setIsDelete(false);
    handleCloseSideout();
  };

  /*11-20-23 Arul: custom header*/
  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleCloseSideout}
    >
      <span className="">
        <BiArrowBack className="header-icon" data-testid="backBttn" />
        {data ? 'Edit' : 'Create'} Checklist Group
      </span>
    </div>
  );

  /*11-20-23 Arul:Press enter key to add new row*/
  const handleKeyPress = useCallback(
    (event: any, index: any) => {
      if (editedNames.length === index + 1) {
        if (event.key === 'Enter') {
          handleClick();
        }
      }
    },
    [editedNames]
  );

  //check mandatory fields
  const isValid = useMemo(() => {
    if (selectedDose.title) {
      if (editedNames[0] !== '') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [selectedDose, editedNames]);

  // delete group modal
  const handleDeleteItem = (item: any, index: any) => {
    if (index === 0) {
      return;
    }
    if (!item) {
      const updatedChecklistOrder = [...checklistOrder];
      const updatedNames = [...editedNames];

      updatedChecklistOrder.splice(index, 1);
      updatedNames.splice(index, 1);

      setChecklistOrder(updatedChecklistOrder);
      setEditedNames(updatedNames);
    }
    if (item) {
      handleDeleteProtocol(index);
    }
  };

  return (
    <div>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Confirm Delete?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeleteCheckListGroup}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want to delete this item from the Checklist?"
        />
      )}

      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleCloseWarningModal}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Checklist Group.  Click cancel to return to Checklist Group details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      <Sidebar
        header={customHeader}
        visible={visible}
        position="right"
        onHide={() => handleCloseSideout()}
        className="sidebarWidth"
      >
        <div style={{ height: '100%' }}>
          <div style={{ padding: '0px 15px', height: '7%' }}>
            <p className="sidebarText">
              Edit the information for the Checklist group
            </p>
            <p className="sidebarText">
              Need more information: <a className="sidebarLink">Learn More</a>
            </p>
          </div>
          <div className="siderbarContent" style={{ height: '80%' }}>
            <div style={{ marginBottom: '5px', marginLeft: '-13px' }}>
              <div className="infolist">
                <div className="display_flex">
                  <div>
                    <span className="ketamine-general-label">Stucture:</span>
                  </div>
                  <div className="pricing-toggle">
                    <input
                      type="radio"
                      id="pricing-toggle-single"
                      name="pricing"
                      value="single"
                      data-testid="single"
                      checked={!selectedDose.isDouble}
                      onChange={() => handleDispatchSingleDouple('single')}
                    />
                    <label
                      className="radio-button"
                      htmlFor="pricing-toggle-single"
                    >
                      Single
                    </label>
                    <input
                      type="radio"
                      id="pricing-toggle-double"
                      name="pricing"
                      value="double"
                      data-testid="double"
                      checked={selectedDose.isDouble}
                      onChange={() => handleDispatchSingleDouple('double')}
                    />
                    <label
                      className="radio-button"
                      htmlFor="pricing-toggle-double"
                    >
                      Double
                    </label>
                  </div>
                </div>
              </div>
              <div className="infolist">
                <div className="display_flex">
                  <div>
                    <span className="ketamine-general-label">
                      Checklist Type:
                    </span>
                  </div>
                  <div
                    className={`pricing-toggle-formtype ${
                      selectedDose.isUrgent ? 'urgent' : 'normal'
                    }`}
                  >
                    <input
                      type="radio"
                      id="pricing-toggle-normal"
                      name="pricingForm"
                      data-testid="normal"
                      value="normal"
                      checked={!selectedDose.isUrgent}
                      onChange={() => handleDispatchNormalUrgent('normal')}
                    />
                    <label
                      className="radio-button"
                      htmlFor="pricing-toggle-normal"
                    >
                      Normal
                    </label>
                    <input
                      type="radio"
                      id="pricing-toggle-urgent"
                      name="pricingForm"
                      data-testid="urgent"
                      value="urgent"
                      checked={selectedDose.isUrgent}
                      onChange={() => handleDispatchNormalUrgent('urgent')}
                    />
                    <label
                      className="radio-button"
                      htmlFor="pricing-toggle-urgent"
                    >
                      Urgent
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: '100%' }}>
              <div
                style={{ maxHeight: '77%', overflow: 'auto' }}
                className={`sidebarChecklistInputContainer ${
                  selectedDose.isUrgent ? 'urgent' : 'normal'
                }`}
              >
                <div>
                  <label
                    htmlFor="calculationBasis"
                    className={`checkListTitle`}
                  >
                    Title<span className="required-field">*</span>
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="title"
                      name="title"
                      data-testid="title"
                      required={true}
                      autoFocus={true}
                      value={selectedDose.title}
                      onChange={(e: any) => {
                        handleDispatchtitle(e);
                      }}
                    />
                    <div className="input-border"></div>
                  </div>
                </div>
                <div className="checklist-twocontent">
                  {selectedDose.isDouble ? (
                    <>
                      <div className="form-group">
                        <label
                          htmlFor="calculationBasis"
                          className={`checkListTitle`}
                        >
                          First Column Items
                        </label>
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="calculationBasis"
                          className={`checkListTitle`}
                        >
                          Second Column Items
                        </label>
                      </div>
                    </>
                  ) : (
                    <div className="form-group">
                      <label
                        htmlFor="calculationBasis"
                        className={`checkListTitle`}
                      >
                        Items<span className="required-field">*</span>
                      </label>
                      <p className="sidebarText" style={{ fontSize: '12px' }}>
                        Press Enter to add another Item.
                      </p>
                    </div>
                  )}
                </div>
                <div
                  style={{ maxHeight: '85%', overflow: 'auto' }}
                  className={
                    !selectedDose.isDouble
                      ? 'checkboxgrid-single single-column'
                      : 'checkboxgrid double-column'
                  }
                >
                  <DragDropContext onDragEnd={handleDragEnd}>
                    {checklistOrder.map((itemId, itemIndex) => {
                      return (
                        <Droppable
                          key={itemIndex}
                          droppableId={`droppable-${itemIndex}`}
                        >
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="checkbox-row"
                            >
                              <Draggable
                                key={itemId} // Use optional chaining for 'item.id'
                                draggableId={`draggable-${itemId}`} // Use optional chaining for 'item.id'
                                index={itemIndex}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className={`draggable-item input-container ${
                                      draggedItemIndex === itemIndex
                                        ? 'dragging'
                                        : ''
                                    }`}
                                  >
                                    {/* Use optional chaining for 'item.name' */}
                                    <div className="form-group">
                                      <div className="checklistInput">
                                        <div
                                          tabIndex={-1}
                                          style={{
                                            marginLeft: '5px',
                                            paddingTop: '2px',
                                          }}
                                        >
                                          <div {...provided.dragHandleProps}>
                                            <IoMenu
                                              tabIndex={-1}
                                              size="1.5rem"
                                              style={{ marginRight: '8px' }}
                                            />
                                          </div>{' '}
                                        </div>
                                        <InputText
                                          type="text"
                                          tabIndex={itemIndex + 1}
                                          autoFocus={
                                            autoFocuaIndex === itemIndex + 1
                                          }
                                          className={`p-inputtextborderNone p-inputtext p-inputtext-border form-control pointer greyText `}
                                          id="name"
                                          name="name"
                                          data-testid={`name-${itemIndex}`}
                                          placeholder=""
                                          value={editedNames[itemIndex]}
                                          onChange={(e) =>
                                            handleNameChange(e, itemIndex)
                                          }
                                          onKeyDown={(e) =>
                                            handleKeyPress(e, itemIndex)
                                          }
                                          style={{
                                            cursor: 'auto',
                                            textAlign: 'left',
                                          }}
                                        />
                                        <span
                                          tabIndex={-1}
                                          className={`close_icon_color cursorPointer}`}
                                          style={{
                                            marginRight: '10px',
                                            paddingTop: '2px',
                                          }}
                                        >
                                          <AiOutlineCloseCircle
                                            data-testid={`delete-${itemIndex}`}
                                            onClick={() => {
                                              handleDeleteItem(
                                                editedNames[itemIndex],
                                                itemIndex
                                              );
                                            }}
                                            className="cursorPointer icon-normal"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      tabIndex={-1}
                                      className="input-border"
                                    ></div>
                                  </div>
                                )}
                              </Draggable>
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      );
                    })}
                  </DragDropContext>
                </div>
                <div
                  data-testid={`addItem`}
                  tabIndex={-1}
                  onClick={() => {
                    handleClick();
                  }}
                  className="cursorPointer contentHeading checklist newProtocolBorder "
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <span
                    style={{
                      textDecoration: 'underLine',
                      textUnderlinePosition: 'under',
                      fontSize: '15px',
                    }}
                  >
                    <HiPlus className="text-icon " /> Add
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebarButtons" style={{ marginTop: '40px' }}>
            <Button
              tabIndex={-1}
              data-testid="dismissBtn"
              className="secondary-button btn-rightMargin"
              onClick={handleCloseSideout}
            >
              <span>
                <AiOutlineClose className="icon-normal" />
              </span>{' '}
              Cancel
            </Button>
            {data && (
              <Button
                data-testid="deleteBtn"
                className="red-background-button btn-rightMargin "
                onClick={() => {
                  setIsDelete(true);
                }}
              >
                Delete
              </Button>
            )}
            <Button
              tabIndex={-1}
              data-testid="setBtn"
              onClick={handleSubmit}
              disabled={!isValid}
              className="primary-button"
            >
              <span>
                <AiOutlinePlus className="icon-normal" />
              </span>
              {data ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ChecklistSideout;
