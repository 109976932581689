import React from 'react';
import './SplashScreen.scss'; // Import CSS file for styling
import logo from '../../assets/images/hinckleymedical_logo_symbol.png';

const SplashScreen = () => {
  return (
    <div className="loading-container">
      <div className="pulse-background"></div>
      <div className="logo-container">
        {/* Your logo image */}
        <img src={logo} alt="Logo" className="logo" />
      </div>
      {/* <div className="loading-bar"></div> */}
    </div>
  );
};

export default SplashScreen;
