import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaFilePdf } from 'react-icons/fa6';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';

interface CustomPdfUploadProps {
  pdfUploaded?: any;
  pdfvalue?: File;
  onClear?: () => void;
  containerStyle?: React.CSSProperties;
}

/* 10-11-23 Arul: Created the CustomPdfUpload component for Protocol Modal */
const CustomPdfUpload: React.FC<CustomPdfUploadProps> = (props) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(
    props.pdfvalue
  );
  const objURL = useMemo(() => {
    if (selectedFile) {
      return URL.createObjectURL(selectedFile);
    }
  }, [selectedFile]);
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const dropRef = useRef(null);

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (e.target.files.length) {
      const data = e.target.files[0];
      props.pdfUploaded(data);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    /* Check if the file is a pdf */

    const file: File = e.dataTransfer?.files[0];
    if (file.type === 'application/pdf') {
      setSelectedFile(file);
      props.pdfUploaded(file);
      setErrorText('');
    } else {
      setErrorText('Only PDF files are allowed');
    }
    setIsDragActive(false);
  };

  useEffect(() => {
    setSelectedFile(props.pdfvalue);
  }, [props.pdfvalue]);

  const viewPDFinWindow = () => {
    if (selectedFile && objURL) {
      localStorage.setItem('pdfURL', objURL);
      localStorage.setItem('pdfName', selectedFile.name);
      window.open(
        `${window.location.origin}/fullscreen-pdf/${selectedFile.name}`,
        '_blank'
      );
    }
  };

  return (
    <div style={{ flex: 1, ...props.containerStyle }}>
      {selectedFile ? (
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          onClick={viewPDFinWindow}
        >
          <AiOutlineCloseCircle
            onClick={(e) => {
              e.stopPropagation();
              setSelectedFile(undefined);
              props.onClear && props.onClear();
            }}
            className="red_text cursorPointer icon-normal"
          />
          <h6 className="pdf-name">
            <span>
              <FaFilePdf size={40} />
            </span>
            {selectedFile.name}
          </h6>
        </div>
      ) : (
        <div
          className="cursorPointer dragDropDotGridModalWorkbook"
          style={{
            display: 'flex',
            background: isDragActive ? '#E0EADD' : 'transparent',
            padding: '20px 0px',
            border: isDragActive
              ? '1px dashed #00534C'
              : errorText
                ? '1px dashed #880808'
                : '1px dashed #cdc8c8',
          }}
          onClick={() => {
            const fileInput = document.getElementById('fileInput');
            fileInput?.click();
          }}
          ref={dropRef}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragActive(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setIsDragActive(false);
          }}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept=".pdf"
            className="pdfInputBox"
            id="fileInput"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          {errorText !== '' && (
            <div
              className="errorText"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
                fontSize: '16px',
              }}
            >
              {errorText}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5px',
            }}
          >
            {isDragActive ? (
              <RiUpload2Line className="drag-drop-icon" />
            ) : (
              <RiDownload2Line className="drag-drop-icon" />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5px',
            }}
          >
            Drag and Drop
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5px',
            }}
          >
            or{' '}
            <span style={{ marginLeft: '5px' }} className="green_accent_text">
              {' '}
              Browse
            </span>
          </div>
          <div
            style={{ fontSize: '12px' }}
            className="grey_text ketamine-general-label"
          >
            Support: PDF ONLY
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomPdfUpload;
