import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { globals } from '../../../../_global/common/Utils';
import { formatForMatchingUnits } from '../../../../../data/model/EquipmentItem';
import { PiSyringe } from 'react-icons/pi';
import { EquipmentOption } from '../../../../../models';

interface EquipmentDoseProps {
  dose?: EquipmentOption | any;
  doseIndex: number;
}

const EquipmentDose: React.FC<EquipmentDoseProps> = (props) => {
  const { dose, doseIndex } = props;

  return (
    <div>
      <div className="addDoseHeader">
        <span className="ketamine-general-label">Dose {doseIndex + 1}</span>
      </div>
      <div
        className={`model-input-container  ${'cursorPointerDiv hoverableBackground'}`}
      >
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label className={` ketamine-label`}>Range Low</label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      !dose.rangeLow ? 'greyText' : ''
                    }`}
                    placeholder="Min"
                    value={dose?.rangeLow ? dose?.rangeLow + ' kg' : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className={` ketamine-label cursorPointerDiv`}>
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control highRange pointer ${
                      Number(dose.rangeLow) === globals.MAX_VALUE
                        ? 'greyText'
                        : ''
                    }`}
                    placeholder="Max"
                    value={
                      dose?.rangeHigh
                        ? dose.rangeHigh === globals.MAX_VALUE
                          ? ''
                          : dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ketamine-input-content-equipment">
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              <PiSyringe className="protocol-icons " />
            </div>
            <div className="form-group">
              <label htmlFor="value" className="ketamine-label-equipment">
                Value:
              </label>
              <div className="p-input-icon-right">
                <InputText
                  type="text"
                  className={`form-control btn-rightMargin  pointer inputHover`}
                  value={formatForMatchingUnits(dose?.amnt, dose?.amntHigh)}
                  placeholder=""
                  readOnly={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentDose;
