import { EmbedHTMLAttributes, useEffect, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaPlay } from 'react-icons/fa6';
import { Document, Page } from 'react-pdf';
import { globals } from './Utils';

// Set the workerSrc property of the pdfjs object
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface GenerateThumbnailProps {
  file: File;
  isDelete?: boolean;
  onDelete?: (e: any) => void;
  onClick: (e: any) => void;
}
const GenerateThumbnail = ({
  file,
  isDelete,
  onClick,
  onDelete,
}: GenerateThumbnailProps) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const pdfRef = useRef<HTMLEmbedElement>(null);

  useEffect(() => {
    if (file) {
      if (file.type.startsWith('video') && videoRef.current) {
        const videoElement = videoRef.current;
        videoElement.src = URL.createObjectURL(file);
        videoElement.load();
        videoElement.onloadeddata = () => {
          videoElement.currentTime = 1;
          // captureThumbnail(videoElement);
        };
        videoElement.onseeked = () => {
          setTimeout(() => captureThumbnail(videoElement), 200); // Adding a delay
        };
      } else if (file.type === 'application/pdf' && pdfRef.current) {
        const pdfElement = pdfRef.current;
        pdfElement.src = URL.createObjectURL(file);
        pdfElement.type = 'application/pdf';

        pdfElement.onload = () => {
          if (globals.debug) console.log('PDF Loaded');
        };
      }
    }
  }, [file]);

  const capturePdfThumbnail = (pdfElement: HTMLEmbedElement) => {};

  const captureThumbnail = (videoElement: HTMLVideoElement) => {
    const canvas = document.createElement('canvas');
    /* Scale the canvas down to have a width of 150 and then set the height according to the aspect ratio of the video */
    canvas.width = 150;
    canvas.height =
      videoElement.videoHeight / (videoElement.videoWidth / canvas.width);
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            setThumbnail(URL.createObjectURL(blob));
          }
        },
        'image/jpeg',
        1
      );
    }
  };

  const onRenderSuccess = (page: any) => {
    if (globals.debug) console.log('Page rendered', page);
    // Access internal instance of PDFPageProxy
    // Find the canvas rendered by react-pdf

    // // Access internal instance of PDFPageProxy
    // const pdfPageProxy = page._pdfPageProxy;

    // if (!pdfPageProxy) {
    //     console.error('PDFPageProxy is not available.');
    //     return;
    // }

    // // Create a canvas to render the page
    // const canvas = document.createElement('canvas');
    // if(globals.debug) console.log('Canvas', canvas)
    // const viewport = pdfPageProxy.getViewport({ scale: 1 });
    // if(globals.debug) console.log('Viewport', viewport)
    // canvas.height = viewport.height;
    // canvas.width = viewport.width;

    // // Render the page into the canvas
    // pdfPageProxy.render({
    //   canvasContext: canvas.getContext('2d'),
    //   viewport,
    // }).then(() => {
    //   setThumbnail(canvas.toDataURL());
    //   if(globals.debug) console.log('Page rendered on canvas', canvas)
    // }).catch((error: any) => {
    //     console.error('Error occurred while rendering the page', error);
    // });
  };

  return (
    <div className="cursorPointer thumbnail-container" onClick={onClick}>
      {file && file.type.startsWith('video') ? (
        <>
          <video ref={videoRef} style={{ display: 'none' }} />
          <div className="thumbnail-wrapper">
            {thumbnail && <img src={thumbnail} alt="Video Thumbnail" />}
            <div className="overlay">
              <div className="play-button">
                <FaPlay size={50} className="icon" />
              </div>
            </div>
            {isDelete && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'end',
                  marginBottom: '2px',
                }}
              >
                <AiOutlineCloseCircle
                  onClick={(e) => {
                    onDelete && onDelete(e);
                  }}
                  className="red_text cursorPointer icon-normal"
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="thumbnail-wrapper">
          <embed ref={pdfRef} width="150" height="150" />
          {isDelete && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'end',
                marginBottom: '2px',
              }}
            >
              <AiOutlineCloseCircle
                onClick={(e) => {
                  onDelete && onDelete(e);
                }}
                className="red_text cursorPointer icon-normal"
              />
            </div>
          )}
          {thumbnail && <img src={thumbnail} alt="PDF Thumbnail" />}
        </div>
      )}
    </div>
  );
};
export default GenerateThumbnail;
