import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globals } from '../../_global/common/Utils';
import { Navbar } from 'react-bootstrap';
import NavbarComponent from '../../components/Navbar/Navbar';
import PublicNavbar from './Navbar/PublicNavbar';
import PublicSidebar from './PublicSidebar';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { handleShowSideBar } from '../../../store/actions';
import { useDispatch } from 'react-redux';

interface DashboardProps {
  children?: ReactNode;
  pageTitle: string;
  component?: ReactNode;
}

/* 09-28-23 Arul: Created the Dashboard layout component to wrapper the navbar, sidebar and main component */
const PublicDashboard: React.FC<DashboardProps> = ({
  children,
  pageTitle,
  component,
}) => {
  const dispatch = useDispatch();
  const isSidebar = useSelector((state: any) => state.sidebar);
  const [isProtocolPage, setIsProtocolPage] = useState(false);
  const dbState = useSelector((state: any) => state.protocol);
  const width = window.innerWidth;
  const [department, setDepartment] = useState<DepartmentItem | undefined>(
    dbState && dbState.departmentItem && dbState.departmentItem.department
      ? dbState.departmentItem.department
      : undefined
  );

  useEffect(() => {
    const dep: DepartmentItem = dbState.departmentItem.department;
    if (dep && dep.subDeps && dep.subDeps.length > 0) {
      dispatch<any>(handleShowSideBar({ sidebar: true }));
      setIsProtocolPage(true);
    } else if (isProtocolPage) setIsProtocolPage(false);
    setDepartment(dep);
  }, [dbState]);
  return (
    <div style={{ flex: 1, overflowY: 'auto' }}>
      <PublicNavbar isProtocolPage={isProtocolPage} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          marginTop: '63px',
          marginLeft: '0px',
          // marginRight: '10px',
          minHeight: 'calc(100vh - 63px)',
        }}
      >
        <PublicSidebar show={isSidebar && isProtocolPage} />
        <div
          className={`public-main ${isSidebar && isProtocolPage ? 'with-sidebar' : ''}`}
          style={{
            marginLeft:
              isSidebar && isProtocolPage
                ? width * 0.2 < 300
                  ? '350px'
                  : `${width * 0.2 + 50}px`
                : '0px',
          }}
        >
          {pageTitle}
          {children}
          {component}
        </div>
      </div>
    </div>
  );
};

export default PublicDashboard;
