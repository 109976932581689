import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../Modal/ConfirmModal';
import { BiArrowBack } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';

interface AddProtocolSideoutProps {
  handleClose?: any;
  handleAdd?: any;
  isVisible?: boolean;
  data?: any;
  isView?: any;
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const NotificationProtocolSideout: React.FC<AddProtocolSideoutProps> = (
  props
) => {
  const { isVisible, handleClose, handleAdd, data, isView } = props;
  const [isSecondPage, setIsSecondPage] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const departmentItem = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [selectedProtocolList, setSelectedProtocolList] = useState<any>([]);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [maxHeight, setMaxHeight] = useState<any>('60%');

  /* 11-07-23 Arul: handle function for radio buttton onChange */
  const handleCheckboxChange = (option: any, protocol: any) => {
    if (!isView) {
      if (selectedOptions.includes(option)) {
        // If the option is already selected, remove it
        setSelectedList(
          selectedList?.filter((item: any) => item.name !== option)
        );
        return setSelectedOptions(
          selectedOptions.filter((item: any) => item !== option)
        );
      } else {
        // If the option is not selected, add it
        setSelectedList([...selectedList, protocol]);
        return setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  useEffect(() => {
    if (data) {
      setSelectedProtocolList(data);
    }
  }, [data]);

  const protocolOption = useMemo(() => {
    const sortedArray = departmentItem.protocols
      .slice()
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
    return sortedArray;
  }, [departmentItem.protocols]);

  useEffect(() => {
    setOptions(protocolOption);
  }, [protocolOption]);

  /* 10-13-23 Praveen: For filtering the protocol sub item list */
  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        const filterData = protocolOption.filter((item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filterData);
      } else {
        setOptions(protocolOption);
      }
    },
    [value, protocolOption, setOptions]
  );

  /* 10-13-23 Praveen: to handle the search input*/
  const handleSearch = (e: any) => {
    setValue(e?.target?.value);
    const timeoutId = setTimeout(() => {
      handleFilter(e?.target?.value);
    }, 250);
    return () => clearTimeout(timeoutId);
  };

  /* 11-07-23 Arul: matching paired protocol list */
  const pariredProtocolMatch = useMemo(() => {
    if (selectedProtocolList.length <= 2) {
      setMaxHeight('45%');
    } else if (selectedProtocolList.length === 3) {
      setMaxHeight('40%');
    } else if (selectedProtocolList.length >= 4) {
      setMaxHeight('30%');
    }

    const result = options?.filter(
      (item: any) =>
        !selectedProtocolList.some((e: any) => e.name === item.name)
    );
    return result;
  }, [options, selectedProtocolList]);

  /* 11-07-23 Arul: select the paired protocol */
  const handleDeletePairedProtocol = () => {
    setIsDelete(false);
    const data = selectedProtocolList.filter(
      (item: any) => item.name !== deleteItem.name
    );
    setSelectedProtocolList(data);
  };

  const handleAddPairedProtocol = () => {
    const data = [...new Set([...selectedProtocolList, ...selectedList])];
    const sortedArray = data
      .slice()
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
    setSelectedProtocolList(sortedArray);
    setSelectedOptions([]);
    setSelectedList([]);
    handleAdd(sortedArray);
  };

  const customHeader = (
    <div>
      {isView ? (
        <div className="buttonContainer contentTitle" onClick={handleClose}>
          <span className="headerTilte">
            <BiArrowBack
              className="header-icon cursorPointer"
              style={{ marginRight: '5px' }}
            />{' '}
            Paired Protocol
          </span>
        </div>
      ) : (
        <div className="buttonContainer contentTitle">
          <span className="headerTilte">
            <HiPlus className="header-icon " style={{ marginRight: '5px' }} />{' '}
            Add Paired Protocol
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove Paired Protocol?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeletePairedProtocol}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want remove this paired protocol?"
        />
      )}
      <Sidebar
        header={customHeader}
        visible={isVisible}
        position="right"
        onHide={() => {}}
        style={{ width: '25%' }}
        className={
          isSecondPage ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
      >
        <div style={{ height: '100%', padding: '0px 10px' }}>
          <div>
            <div className="contentText">
              Manage paired protocols to this protocol and add new by searching
              for existing protocols
            </div>
            <div className="contentLabel">Paired Protocols</div>
          </div>
          <div
            style={{ maxHeight: '30%', border: '0px' }}
            className="contentBorder protocolCalculationPad secondaryListScroll"
          >
            {' '}
            <div>
              {selectedProtocolList.length > 0 ? (
                <ViewportList items={selectedProtocolList}>
                  {(item: any, index: any) => (
                    <div
                      data-div-id="grey"
                      key={index}
                      className="selectedGreybox cursorPointerArea"
                      style={{
                        marginBottom: '5px',
                        fontSize: '15px',
                        width: 'auto',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>{item.name}</div>
                      {!isView && (
                        <span className="close_icon_color">
                          <AiOutlineCloseCircle
                            onClick={() => {
                              setDeleteItem(item);
                              setIsDelete(true);
                            }}
                            className="cursorPointer pairedProtocol-cancel-icon"
                          />
                        </span>
                      )}
                    </div>
                  )}
                </ViewportList>
              ) : (
                <div
                  data-div-id="grey"
                  className="selectedGreybox cursorPointerArea"
                  style={{
                    fontSize: '15px',
                    width: 'auto',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>None</div>
                </div>
              )}
            </div>
          </div>
          {!isView && (
            <div>
              <div className="contentLabel">Select Paired Protocol</div>
              <div className="search-custom-input">
                <span className="icon_search_pad">
                  <FiSearch className="icon-normal" />
                </span>
                <InputText
                  value={value}
                  onChange={handleSearch}
                  className="search-inputs"
                />
              </div>
              <div
                style={{ maxHeight: maxHeight }}
                className="contentBorder protocolCalculationPad secondaryListScroll"
              >
                <ViewportList items={pariredProtocolMatch}>
                  {(item: any, index: any) => (
                    <div
                      key={item?.name + index}
                      onClick={() => {
                        handleCheckboxChange(item.name, item);
                      }}
                      className={`radioBtnSelectedColor listhover cursorPointer item contentHeading ${selectedOptions.includes(item.name) ? 'list_column_selected_Checklist' : 'column_unselected'}  ${pariredProtocolMatch.length !== index + 1 ? 'contentUnderline' : ''}`}
                    >
                      {!isView && (
                        <div className="radioBtnSelectedColor">
                          <RadioButton
                            inputId="pairedProtocol"
                            name="pairedProtocol"
                            data-testid={`protocol-check-${index}`}
                            className="radioBtnColor"
                            style={{ marginRight: '10px' }}
                            value={item.name}
                            checked={selectedOptions.includes(item.name)}
                            onChange={() => {
                              handleCheckboxChange(item.name, item);
                            }}
                          />
                        </div>
                      )}
                      <div>{item.name}</div>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
          <div className="btn_Bottom">
            <Button
              className="secondary-button btn-rightMargin"
              onClick={handleClose}
              data-testid="close-icon"
            >
              {' '}
              {isView ? 'Dismisss' : 'X Cancel'}
            </Button>
            {!isView && (
              <Button
                data-testid="saveBttn"
                className="primary-button"
                onClick={handleAddPairedProtocol}
              >
                + Add
              </Button>
            )}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default NotificationProtocolSideout;
