import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { BiFullscreen } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  handleGetDepartment,
  handleUpdateSelectedAccordion,
} from '../../../../store/actions';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import AddProtocolSideout from '../../../components/SideOut/AddProtocolSideout';
import ProtocolHeader from '../ProtocolHeader';
import CalculationScreen from './CalculationsScreen';
import PDFScreen from './PdfScreen';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
  formatTimestamp,
  getAllWorkbooks,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolItem, {
  cloneProtocol,
} from '../../../../data/model/ProtocolItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { reorderMedicationDose } from '../../../../data/functions/MedicationDB';
import {
  Equipment,
  Form,
  ProgressStatus,
  User,
  Workbook,
} from '../../../../models';
import Dropzone from 'react-dropzone';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import {
  getFormattedDateTime,
  globals,
  isObjectEqual,
  upgradeVersion,
} from '../../../_global/common/Utils';
import {
  addIDtoProtocol,
  createProtocol,
  deleteProtocol,
  uploadNewPDFToS3,
  validatePointerID,
} from '../../../../data/functions/ProtocolDB';
import Loading from '../../../components/Loading/Loading';
import MedicationItem from '../../../../data/model/MedicationItem';
import { reorderInfusionDose } from '../../../../data/functions/InfusionDB';
import { reorderElectricalShock } from '../../../../data/functions/ElectricalDB';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import ReviewSideOut from '../../../components/SideOut/reviewSideOut/ReviewSideOut';
import FormItem from '../../../../data/model/FormItem';
import _, { clone } from 'lodash';
import { API, DataStore, Storage } from 'aws-amplify';
import { Protocol } from '../../../../models';
import { UserType } from '../../../../models';
import { ViewportList } from 'react-viewport-list';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../../components/Search/SearchBar';
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UploadProtocolPDF from './UploadPDF';
import WorkbookItem from '../../../../data/model/WorkbookItem';
import { electricalDose } from '../../../_global/constants/model';
import Checklist from './Checklist';
import { getHashedPin } from '../../../_global/common/Encrypt';

type ProtocolDetailsItemsProps = {
  onBackClick?: any;
};

/* 10-02-23 Arul: Created the component for Protocol details page*/
const ProtocolDetails: React.FC<ProtocolDetailsItemsProps> = (props) => {
  const rootUrl = window.location.origin;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const { selectedProtocol, editMode, backPage } = state;
  const [protocol, setProtocol] = useState<ProtocolItem>(selectedProtocol);
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);

  const [selectedTab, setSelectedTab] = useState<string | null>(
    isLoggedIn && protocol.sum !== 0 ? 'Details' : 'Pdf'
  );
  const [itemSelected, setItemSelected] = useState('');
  const [newPairedPrtocols, setNewPairedPrtocols] = useState<ProtocolItem[]>();
  const [newEquipment, setNewEquipment] = useState<EquipmentItem[]>();
  const [newChecklist, setNewChecklist] = useState<FormItem[]>([]);
  const [isEditMode, setIsEditMode] = useState(editMode);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const [temporaryStatus, setTemporaryStatus] = useState<
    ProgressStatus | undefined
  >();
  const [isOpenSideOut, setIsOpenSideOut] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedWorkbookID, setSelectedWorkbookID] = useState('');
  const [workbook, setWorkbook] = useState<any>([]);
  const [filteredWorkbooks, setFilteredWorkbooks] =
    useState<Workbook[]>(workbook);

  const [modalType, setModalType] = useState('');
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [newExtractedWorkbookKey, setNewExtractedWorkbookKey] = useState('');
  const [isParsedSuccessfull, setIsParsedSuccessfull] = useState(false);
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [routeToProtocolWarning, setRouteToProtocolWarning] = useState(false);

  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [file, setFile] = useState<File | undefined>();
  const [selectedWorkbookURL, setSelectedWorkbookURL] = useState<
    string | null
  >();

  const [dropState, setDropState] = useState({
    isDragActive: false,
  });

  const objURL = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else if (selectedWorkbookURL) return selectedWorkbookURL;
    return '';
  }, [file, selectedWorkbookURL]);

  const [originalProtocolData, setOriginalProtocolData] = useState<any>({
    equipment: protocol.equipment,
    forms: protocol.forms,
    pairedProtocols: protocol.pairedProtocols,
    sum: protocol.sum,
  });

  const [reorderedLists, setReorderedLists] = useState<any>({
    medications: [],
    infusions: [],
    equipment: [],
    electrical: [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: protocol.name,
      nickname: protocol.nickname,
      pairedDeps: protocol.pairedDeps,
      rangeLow: protocol.rangeLow === 0 ? '' : protocol.rangeLow + '',
      rangeHigh:
        protocol.rangeHigh === globals.MAX_VALUE ? '' : protocol.rangeHigh + '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Protocol ame is required'),
      nickname: Yup.string().required('Protocol nickname is required'),
      pairedDeps: Yup.array(),
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
    }),
    onSubmit: (values) => {
      if (globals.debug) console.log('values', values);
    },
  });

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'b') {
        event.preventDefault();
        handleBack();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setOriginalProtocolData({
      equipment: protocol.equipment,
      forms: protocol.forms,
      pairedProtocols: protocol.pairedProtocols,
      sum: protocol.sum,
    });
  }, [protocol]);

  useEffect(() => {
    const getDetails = async () => {
      const result = await getAllWorkbooks(department.id);
      setWorkbook(result.data);
      setFilteredWorkbooks(result.data);
    };
    getDetails();
  }, []);

  useEffect(() => {
    setNewChecklist([]);
    setNewEquipment(undefined);
    setNewPairedPrtocols(undefined);
    setReorderedLists({
      medications: [],
      infusions: [],
      equipment: [],
      electrical: [],
    });
    setProtocol(selectedProtocol);
    if (selectedProtocol.sum === 0 && selectedTab === 'Details')
      setSelectedTab('Pdf');
  }, [selectedProtocol]);

  const handleItemClick = (
    item:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => {
    if ('Paired Protocol' === type) {
      if (isSaveActive) {
        setSelectedData(item);
        setRouteToProtocolWarning(true);
      } else {
        setPdfLoaded(false);
        let p = item as ProtocolItem;
        navigate(`/${p.nickname}/protocol-detail`, {
          state: { selectedProtocol: p, editMode: false },
        });
      }
    }
  };

  const isSaveActive = useMemo(() => {
    if (!isEditMode) return false;
    if (isPdfModal) return file != null || isParsedSuccessfull;
    else {
      if (
        (newPairedPrtocols && newPairedPrtocols?.length > 0) ||
        (newEquipment && newEquipment?.length > 0) ||
        (newChecklist && newChecklist?.length > 0)
      )
        return true;
      if (reorderedLists.medications.length > 0) return true;
      if (reorderedLists.infusions.length > 0) return true;
      if (reorderedLists.equipment.length > 0) return true;
      if (reorderedLists.electrical.length > 0) return true;
      if (
        formik.values.pairedDeps.map((item: any) => item.id).join(',') !==
        protocol.pairedDeps.map((item: any) => item.id).join(',')
      )
        return true;
    }
    return formik.dirty;
  }, [
    isParsedSuccessfull,
    isEditMode,
    newPairedPrtocols,
    newEquipment,
    newChecklist,
    reorderedLists,
    file,
    isPdfModal,
    formik,
  ]);

  // set the edit mode
  useEffect(() => {
    if (state?.editMode) {
      setIsEditMode(true);
    }
  }, [state]);

  useEffect(() => {
    const loadPDF = async () => {
      let result: Response = await fetchPDF(protocol.pdfUrl);
      if (result.type === ResponseType.Success) setPdfUrl(result.data);
      else {
        console.error(
          'ERROR: Could not load pdf for protocol:' + protocol.name,
          result.data
        );
        setPdfUrl('');
      }
    };
    if (protocol.pdfUrl && !pdfLoaded) {
      loadPDF();
      setPdfLoaded(true);
    }
  }, [protocol, pdfLoaded]);

  useEffect(() => {
    setPdfLoaded(false);
    setProtocol(selectedProtocol);
  }, [selectedProtocol]);

  /* 10-02-23 Arul: handle function for switching the tabs*/
  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  /* 10-02-23 Arul: handle go back to protocol page*/
  const handleBack = () => {
    if (isPdfModal) {
      setFile(undefined);
      setIsPdfModal(false);
      return;
    }
    if (backPage === 'list-protocols') {
      navigate(`/database/list-protocols`, {
        state: {
          department: department,
          data: database.protocols,
          database: database,
        },
      });
    } else {
      if (isEditMode) {
        navigate(`/protocol/edit`);
      } else {
        navigate('/protocol');
      }
      dispatch<any>(handleUpdateSelectedAccordion([]));
    }
  };

  /* 1-23-24 Hazlett:  Function to remove the previous draft and make a new one */
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
        if (modalType === 'Add') {
          setIsOpenSideOut(true);
        }
        setModalType('');
        reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
          if (newDB) updateCurrentProtocol(newDB);
        });
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department]);

  /* 11-14-23 Praveen: function for handling to open sideout*/
  const handleAdd = useCallback(() => {
    if (isEditMode) {
      setIsOpenSideOut(true);
    } else {
      handleCheckIsDraft().then((isDraft: boolean) => {
        if (isDraft) {
          setIsOpen(true);
          setModalType('Add');
        } else {
          setIsOpenSideOut(true);
        }
      });
      // setModalType('Add')
    }
  }, [isEditMode, handleCheckIsDraft]);

  /* 11-14-23 Praveen: function for draft continue button*/
  const handleContinue = useCallback(() => {
    setIsEditMode(true);
    setIsOpen(false);
    if (modalType === 'Add') {
      setIsOpenSideOut(true);
    }
    setModalType('');
  }, [modalType]);

  const handleUploadpdf = (data: any) => {
    let url;
    if (data?.pdf.name) {
      url = URL?.createObjectURL(data?.pdf);
    }
    setPdfUrl(data?.pdf?.name ? url : data.pdf);
    setIsPdfModal(false);
  };

  const onReorder = async (
    list: (
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
    )[],
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => {
    /* Check if the list is different from the original list */
    let isDifferent = false;
    for (let i = 0; i < list.length; i++) {
      let m1 = list[i];
      let m2;
      if (type === 'Medication') m2 = protocol.medications[i];
      else if (type === 'Infusion') m2 = protocol.infusions[i];
      else if (type === 'Equipment') m2 = protocol.equipment[i];
      else if (type === 'Electrical') m2 = protocol.electrical[i];

      if (!isObjectEqual(m1, m2)) {
        isDifferent = true;
        break;
      }
    }

    if (isDifferent) {
      if (type === 'Medication')
        setReorderedLists({ ...reorderedLists, medications: list });
      else if (type === 'Infusion')
        setReorderedLists({ ...reorderedLists, infusions: list });
      else if (type === 'Equipment')
        setReorderedLists({ ...reorderedLists, equipment: list });
      else if (type === 'Electrical')
        setReorderedLists({ ...reorderedLists, electrical: list });
    }
  };

  async function getProtocolsByIds(ids: string[] | any, type: string) {
    const addedProtocols: any = [];
    const idsArray = Array.isArray(ids) ? ids : [ids];

    for (const id of idsArray) {
      let protocol;
      if (type === 'Paired Protocol') {
        protocol = await DataStore.query(Protocol, id);
      } else if (type === 'Equipment') {
        protocol = await DataStore.query(Equipment, id);
      } else {
        protocol = await DataStore.query(Form, id);
      }
      if (protocol) {
        addedProtocols.push(protocol);
      }
    }
    return addedProtocols;
  }

  const handleAddPairedProtocols = async (
    protocolIDtoPair: string[],
    type: string
  ) => {
    const fetchedProtocols = await getProtocolsByIds(protocolIDtoPair, type);
    if (type === 'Paired Protocol') {
      if (globals.debug) console.log('fetchedProtocols', fetchedProtocols);
      const updatedPairedProtocols = [
        ...protocol.pairedProtocols,
        ...fetchedProtocols,
      ];

      setProtocol(
        (prevProtocol) =>
          ({
            ...prevProtocol,
            pairedProtocols: updatedPairedProtocols,
            sum: 1,
          }) as ProtocolItem
      );
      setTemporaryStatus(ProgressStatus.DRAFT);
    } else if (type === 'Equipment') {
      const updatedEquipment = [...protocol.equipment, ...fetchedProtocols];
      setProtocol(
        (prevProtocol) =>
          ({
            ...prevProtocol,
            equipment: updatedEquipment,
            sum: 1,
          }) as ProtocolItem
      );
      setTemporaryStatus(ProgressStatus.DRAFT);
    } else {
      const updatedForms = [...protocol.forms, ...fetchedProtocols];
      setProtocol(
        (prevProtocol) =>
          ({
            ...prevProtocol,
            forms: updatedForms,
            sum: 1,
          }) as ProtocolItem
      );
      setTemporaryStatus(ProgressStatus.DRAFT);
    }
  };

  const handleSetFromSideout = (
    items: ProtocolItem[] | EquipmentItem[] | FormItem[],
    type: string
  ) => {
    if (globals.debug) console.log('handleSetFromSideout', items, type);
    if (!isEditMode) setIsEditMode(true);
    // setSelectedData(selectedList);
    if (type === 'Paired Protocol') {
      setNewPairedPrtocols(items as ProtocolItem[]);
      protocol.sum += items.length;
    } else if (type === 'Equipment') {
      setNewEquipment(items as EquipmentItem[]);
      protocol.sum += items.length;
    } else {
      const item: any = items as FormItem[];
      setNewChecklist([item]);
      protocol.sum += items.length;
    }
  };

  const reloadDatabase = async () => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    if (globals.debug) console.log('loading database');
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    }
  };

  /**
   * Handle the save button being pressed there are two different save buttons
   *  - The save button for the PDF modal
   *  - The save button for the protocol details page for Re-ordering the ModelItems
   */
  const handleSave = async () => {
    if (isPdfModal) {
      if (file != null) {
        setLoading(true);
        let newProtocol = cloneProtocol(protocol);
        let prevVersion = protocol.version;
        let prevPdfVersion = protocol.pdfVersion;
        newProtocol.version = upgradeVersion(protocol.version);
        newProtocol.pdfVersion = upgradeVersion(protocol.pdfVersion);
        let result: Response = await uploadNewPDFToS3(
          department,
          newProtocol,
          file
        );
        if (result.type === ResponseType.Success) {
          setFile(undefined);
          setIsPdfModal(false);

          newProtocol.pdfUrl = result.data;
          newProtocol.activeID =
            protocol.status === ProgressStatus.ACTIVE
              ? protocol.uid
              : protocol.activeID;
          newProtocol.status = ProgressStatus.DRAFT;
          newProtocol.modifiedBy = user;
          result = await createProtocol(newProtocol, protocol);

          if (result.type === ResponseType.Success) {
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
          } else {
            console.error('Failed to create new protocol', result.data);
            protocol.version = prevVersion;
            protocol.pdfVersion = prevPdfVersion;
            alert(
              'Failed to create new protocol: ' +
                protocol.name +
                ' with new PDF:\n' +
                result.data
            );
          }
          setPdfLoaded(false);
        } else {
          alert('Failed to upload new PDF to S3');
          if (globals.debug)
            console.log('Failed to upload new PDF to S3', result.data);
          protocol.version = prevVersion;
          protocol.pdfVersion = prevPdfVersion;
        }
        setLoading(false);
        setIsEditMode(false);
        setIsParsedSuccessfull(false);
      } else if (isParsedSuccessfull) {
        setLoading(true);
        const pdfKey = newExtractedWorkbookKey;
        try {
          const signedUrlResponse = await Storage.get(pdfKey, {
            level: 'public',
            download: true,
          });
          if (!signedUrlResponse.Body)
            throw new Error('Failed to download PDF.');

          const blob = signedUrlResponse.Body;
          const fileName = 'workbookParsed.pdf';
          const lastModified = Date.now();
          const convertedToFile = new File([blob], fileName, {
            type: 'application/pdf',
            lastModified,
          });
          let prevVersion = protocol.version;
          let prevPdfVersion = protocol.pdfVersion;
          protocol.version = upgradeVersion(protocol.version);
          protocol.pdfVersion = upgradeVersion(protocol.pdfVersion);
          let result: Response = await uploadNewPDFToS3(
            department,
            protocol,
            convertedToFile
          );
          if (result.type === ResponseType.Success) {
            setFile(undefined);
            setIsPdfModal(false);
            let newProtocol = cloneProtocol(protocol);

            newProtocol.pdfUrl = result.data;
            newProtocol.activeID =
              protocol.status === ProgressStatus.ACTIVE
                ? protocol.uid
                : protocol.activeID;
            newProtocol.status = ProgressStatus.DRAFT;
            newProtocol.modifiedBy = user;
            result = await createProtocol(newProtocol, protocol);

            if (result.type === ResponseType.Success) {
              reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
                if (newDB) updateCurrentProtocol(newDB);
              });
              removeParsedPDF();
            } else {
              console.error('Failed to create new protocol', result.data);
              protocol.version = prevVersion;
              protocol.pdfVersion = prevPdfVersion;
              alert(
                'Failed to create new protocol: ' +
                  protocol.name +
                  ' with new PDF:\n' +
                  result.data
              );
            }
            setPdfLoaded(false);
            setIsParsedSuccessfull(false);
          } else {
            alert('Failed to upload new PDF to S3');
            if (globals.debug)
              console.log('Failed to upload new PDF to S3', result.data);
            protocol.version = prevVersion;
            protocol.pdfVersion = prevPdfVersion;
          }
          setLoading(false);
          setIsEditMode(false);
        } catch (error) {
          console.error('Error downloading PDF:', error);
        }
      }
    } else {
      let p = cloneProtocol(protocol);

      if (formik.dirty) {
        p.name = formik.values.name;
        p.nickname = formik.values.nickname;
        p.pairedDeps = formik.values.pairedDeps;
        p.rangeLow = Number(formik.values.rangeLow);
        p.rangeHigh = Number(
          formik.values.rangeHigh === ''
            ? globals.MAX_VALUE
            : formik.values.rangeHigh
        );
        p.activeID = p.status === ProgressStatus.ACTIVE ? p.uid : p.activeID;
        p.status = ProgressStatus.DRAFT;
        p.version = upgradeVersion(p.version);
        p.modifiedBy = user;
        let result = await createProtocol(p, protocol);
        if (result.type === ResponseType.Success) {
          let newProtocol = result.data as ProtocolItem;
          reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
            if (newDB) updateCurrentProtocol(newDB, newProtocol);
          });
        } else {
          console.error('Failed to create new protocol', result.data);
        }
      }

      if (
        (newPairedPrtocols && newPairedPrtocols?.length > 0) ||
        (newEquipment && newEquipment?.length > 0) ||
        (newChecklist && newChecklist?.length > 0)
      ) {
        let result = await addIDtoProtocol(
          p,
          newEquipment
            ? newEquipment?.map((item: EquipmentItem) => item.uid)
            : [],
          newChecklist && newChecklist.length > 0 ? newChecklist[0].uid : '',
          newPairedPrtocols
            ? newPairedPrtocols?.map((item: ProtocolItem) => item.uid)
            : [],
          user.id
        );
        if (result.type === ResponseType.Success) {
          let newProtocol = result.data as ProtocolItem;
          setProtocol(newProtocol);
          setNewChecklist([]);
          setNewEquipment(undefined);
          setNewPairedPrtocols(undefined);
          reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
            if (newDB) updateCurrentProtocol(newDB, newProtocol);
          });
        } else {
          console.error('Failed to add ID to protocol', result.data);
        }
      }
      if (reorderedLists.medications.length > 0) {
        /* First Group the medications to there parent medication */
        let groupedMedications: MedicationSubItem[][] = [];
        for (let i = 0; i < reorderedLists.medications.length; i++) {
          let medication = reorderedLists.medications[i] as MedicationSubItem;
          medication.index = i;
          if (i === 0) groupedMedications.push([medication]);
          else {
            /* Search to see if the parent medication is already in the group, otherwise add it */
            let found = false;
            for (let j = 0; j < groupedMedications.length; j++) {
              let group = groupedMedications[j];
              if (
                group[0].parentMedication.uid ===
                medication.parentMedication.uid
              ) {
                group.push(medication);
                found = true;
                break;
              }
            }
            if (!found) groupedMedications.push([medication]);
          }
        }

        /* Now reorder the medications and update by group */
        for (let i = 0; i < groupedMedications.length; i++) {
          let group = groupedMedications[i];
          let result: Response = await reorderMedicationDose(
            group[0].parentMedication,
            group[0].parentProtocol,
            group,
            user
          );
          if (result.type === ResponseType.Success) {
            let newMedication = result.data as MedicationItem;
            for (let j = 0; j < group.length; j++) {
              group[j].status = ProgressStatus.DRAFT;
              group[j].parentMedication.status = newMedication.status;
              group[j].parentMedication.activeID = newMedication.activeID;
              group[j].parentMedication.version = newMedication.version;
              group[j].parentMedication.uid = newMedication.uid;
            }
          } else {
            console.error('Failed to reorder medications', result.data);
          }
        }
        p.medications = reorderedLists.medications.sort(
          (m1: MedicationSubItem, m2: MedicationSubItem) => {
            if (m1.index === m2.index) return m1.name.localeCompare(m2.name);
            return m1.index - m2.index;
          }
        );
      }

      if (reorderedLists.infusions.length > 0) {
        /* First Group the medications to there parent medication */
        let groupedInfusions: MedicationSubItem[][] = [];
        for (let i = 0; i < reorderedLists.infusions.length; i++) {
          let infusion = reorderedLists.infusions[i] as MedicationSubItem;
          infusion.index = i;
          if (i === 0) groupedInfusions.push([infusion]);
          else {
            let found = false;
            for (let j = 0; j < groupedInfusions.length; j++) {
              let group = groupedInfusions[j];
              if (
                group[0].parentMedication.uid === infusion.parentMedication.uid
              ) {
                group.push(infusion);
                found = true;
                break;
              }
            }
            if (!found) groupedInfusions.push([infusion]);
          }
        }

        /* Now reorder the medications and update by group */
        for (let i = 0; i < groupedInfusions.length; i++) {
          let group = groupedInfusions[i];
          let result: Response = await reorderInfusionDose(
            group[0].parentMedication,
            group[0].parentProtocol,
            group,
            user
          );
          if (result.type === ResponseType.Success) {
            let newInfusion = result.data as MedicationItem;
            for (let j = 0; j < group.length; j++) {
              group[j].status = ProgressStatus.DRAFT;
              group[j].parentMedication.status = newInfusion.status;
              group[j].parentMedication.activeID = newInfusion.activeID;
              group[j].parentMedication.version = newInfusion.version;
              group[j].parentMedication.uid = newInfusion.uid;
            }
          } else {
            console.error('Failed to reorder infusions', result.data);
          }
        }
        p.infusions = reorderedLists.infusions;
      }

      if (reorderedLists.electrical.length > 0) {
        /* First Group the medications to there parent medication */
        let groupedElectricals: ElectricalSubItem[][] = [];
        for (let i = 0; i < reorderedLists.electrical.length; i++) {
          let electrical = reorderedLists.electrical[i] as ElectricalSubItem;
          electrical.index = i;
          if (i === 0) groupedElectricals.push([electrical]);
          else {
            let found = false;
            for (let j = 0; j < groupedElectricals.length; j++) {
              let group = groupedElectricals[j];
              if (
                group[0].parentElectrical.uid ===
                electrical.parentElectrical.uid
              ) {
                group.push(electrical);
                found = true;
                break;
              }
            }
            if (!found) groupedElectricals.push([electrical]);
          }
        }

        if (globals.debug)
          console.log('Grouped Electricals', groupedElectricals);

        /* Now reorder the electricals and update by group */
        for (let i = 0; i < groupedElectricals.length; i++) {
          let group = groupedElectricals[i];
          let result: Response = await reorderElectricalShock(
            group[0].parentElectrical,
            group[0].parentProtocol,
            group,
            user
          );
          if (result.type === ResponseType.Success) {
            let newElectrical = result.data as ElectricalItem;
            if (globals.debug) console.log('New Electrical', newElectrical);
            for (let j = 0; j < group.length; j++) {
              group[j].status = ProgressStatus.DRAFT;
              group[j].parentElectrical.status = newElectrical.status;
              group[j].parentElectrical.activeID = newElectrical.activeID;
              group[j].parentElectrical.version = newElectrical.version;
              group[j].parentElectrical.uid = newElectrical.uid;
            }
          } else {
            console.error('Failed to reorder infusions', result.data);
          }
        }
        p.electrical = reorderedLists.electrical;
      }

      setProtocol(p);
      setReorderedLists({
        medications: [],
        infusions: [],
        equipment: [],
        electrical: [],
      });
    }
  };

  const handleCancel = () => {
    if (isSaveActive && !isCancelWarningModal) {
      setIsCancelWarningModal(true);
    } else {
      setIsEditMode(false);
      setProtocol(originalProtocolData);
      setNewChecklist([]);
      setNewEquipment(undefined);
      setNewPairedPrtocols(undefined);
      setReorderedLists({
        medications: [],
        infusions: [],
        equipment: [],
        electrical: [],
      });
    }
  };

  const handleDeleteProtocol = async (isPermanent: boolean) => {
    let result: Response = await deleteProtocol(protocol, user, !isPermanent);
    if (result.type === ResponseType.Success) {
      if (globals.debug)
        console.log('Successfully deleted protocol', result.data);
      reloadDatabase();
      setIsDeleteModal(false);
      navigate('/protocol');
    } else {
      console.error('Failed to delete protocol', result.data);
    }
  };

  const updateCurrentProtocol = (
    newDB: DatabaseResponse,
    parmProtocol?: ProtocolItem
  ) => {
    let p = parmProtocol ? parmProtocol : protocol;
    let id = p.status === ProgressStatus.DRAFT ? p.activeID : p.uid;
    for (let i = 0; i < newDB.protocols.length; i++) {
      let p = newDB.protocols[i];
      let pID = p.status === ProgressStatus.DRAFT ? p.activeID : p.uid;
      if (pID === id) setProtocol(p);
    }
  };

  const handleParsingPDF = async (id: string) => {
    if (!id) return;
    setIsPdfLoading(true);
    setStartPage('');
    setEndPage('');

    const url =
      process.env.REACT_APP_API_GATEWAY_URL ??
      (function () {
        throw new Error('API URL is not defined');
      })();
    const bucketName =
      process.env.REACT_APP_BUCKET_NAME ??
      (function () {
        throw new Error('BUCKET NAME is not defined');
      })();
    const apiUrl = url;
    const requestBody = {
      bucket: bucketName,
      key: `public/${id}`,
      startPage,
      endPage: endPage ? endPage : startPage,
      customName: `workBook-${startPage}-${endPage}`,
      depID: department.id,
    };
    if (globals.debug) console.log(requestBody, 'URL');

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const parsedBody = JSON.parse(result.body);
      const processedPdfUrl = parsedBody.processedPdfUrl;
      if (globals.debug) console.log(processedPdfUrl, 'DATA');
      const key = processedPdfUrl.split('.com/')[1];
      const keywWithoutPublic = key.split('public/')[1];
      const extractedWorkbook = await Storage.get(keywWithoutPublic, {
        level: 'public',
        contentType: 'application/pdf',
      });
      setNewExtractedWorkbookKey(keywWithoutPublic);
      setIsParsedSuccessfull(true);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsPdfLoading(false);
    }
  };

  const removeParsedPDF = async () => {
    if (isParsedSuccessfull && newExtractedWorkbookKey) {
      try {
        await Storage.remove(newExtractedWorkbookKey, { level: 'public' });
        if (globals.debug) console.log('Parsed PDF deleted successfully');
      } catch (error) {
        console.error('Error deleting parsed PDF from S3:', error);
      }
    }
  };

  const handleViewFullScreen = () => {
    if (pdfUrl !== '' || objURL !== '') {
      if (globals.debug) console.log('pdfUrl', objURL !== '' ? objURL : pdfUrl);
      localStorage.setItem('pdfURL', objURL !== '' ? objURL : pdfUrl);
      localStorage.setItem('pdfName', protocol.name);
      let filterName = protocol.name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        pdfUrl + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${rootUrl}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );
    }
  };

  return (
    <div className="screen-container">
      {isOpen && (
        <DraftSaveModal
          department={database.department}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={handleContinue}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      <AddProtocolSideout
        isVisible={isOpenSideOut}
        isEditMode={isEditMode}
        page={'protocolDetailPage'}
        protocol={protocol}
        handleAdd={handleSetFromSideout}
        title={'modalName'}
        handleClose={() => {
          setIsOpenSideOut(false);
        }}
        data-testid="add-edit-protocol-modal"
      />

      <ConfirmModal
        isVisible={isCancelWarningModal}
        title="Abandon Changes?"
        handleClose={() => {
          setIsCancelWarningModal(false);
        }}
        handleSubmit={handleCancel}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Abandon"
        primaryDescription={`Changes were made to this Protocol.  Click cancel to return to Protocol.  To cancel without saving changes, select Abandon.`}
      />

      <ConfirmModal
        isVisible={routeToProtocolWarning}
        title="Abandon Changes?"
        handleClose={() => {
          setRouteToProtocolWarning(false);
        }}
        handleSubmit={() => {
          setRouteToProtocolWarning(false);
          let p = selectedData as ProtocolItem;
          navigate(`/${p.nickname}/protocol-detail`, {
            state: { selectedProtocol: p, editMode: false },
          });
          setSelectedData(null);
        }}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Route"
        primaryDescription={`Changes were made to this Protocol.  Click cancel to return to Protocol.  To cancel without saving changes, select Abandon and continue to route to the protocol.`}
      />

      <ConfirmModal
        isVisible={isDeleteModal}
        title={
          'Delete ' +
          protocol.name +
          (protocol.status === ProgressStatus.DRAFT ? ' (Draft)' : '') +
          '?'
        }
        handleClose={() => {
          setIsDeleteModal(false);
        }}
        handleSubmit={handleDeleteProtocol}
        // isPermanentlyDeleteBtn={true}
        isDeleteBtn={true}
        primaryBtnName="Cancel"
        secondaryBtnName="Delete"
        primaryDescription={`Are you sure that you want to delete ${protocol.name}? The protocol has ${protocol.sum} sub items. The protocol will be able to be recovered from the trash bin.`}
      />

      {reviewSideOutVisible && (
        <ReviewSideOut
          department={database.department}
          isVisible={reviewSideOutVisible} // Change 'visible' to 'isVisible'
          handleClose={() => {
            setReviewSideOutVisible(false);
          }}
          setVisible={setReviewSideOutVisible}
          handleAdd={() => {
            setReviewSideOutVisible(false);
          }}
          onPublish={() => {
            setReviewSideOutVisible(false);
            reloadDatabase().then((newDB: DatabaseResponse | undefined) => {
              if (newDB) updateCurrentProtocol(newDB);
            });
            // handleCancel();
          }}
        />
      )}

      {/* <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header"> */}
      <ProtocolHeader
        name={
          isPdfModal ? 'Upload PDF' : protocol.name
          // (protocol.status === ProgressStatus.DRAFT ? ' (Draft)' : '') +
          // (temporaryStatus === ProgressStatus.DRAFT ? ' (Draft)' : '')
        }
        isDotButton={false}
        status={temporaryStatus != null ? temporaryStatus : protocol.status}
        description={'Folder: ' + protocol.parent.name}
        isBackButton={true}
        isfullScreenButton={!isLoggedIn}
        isDeleteButton={isLoggedIn && !isPdfModal && isEditMode}
        isFullScreenButton={
          (selectedTab === 'Pdf' && !isPdfModal) ||
          (isPdfModal && file != null) ||
          (isPdfModal && selectedWorkbookURL !== '')
        }
        handleFullScreen={() => {
          handleViewFullScreen();
        }}
        isReviewButton={
          isLoggedIn &&
          !isPdfModal &&
          (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
        }
        isReviewActive={true}
        handleDelete={() => setIsDeleteModal(true)}
        handleReview={() => setReviewSideOutVisible(true)}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft) setIsEditMode(true);
          });
        }}
        handleSave={() => {
          handleSave();
        }}
        handleCancelEdit={() => {
          removeParsedPDF();
          protocol.equipment = originalProtocolData.equipment;
          protocol.forms = originalProtocolData.forms;
          protocol.pairedProtocols = originalProtocolData.pairedProtocols;
          protocol.sum = originalProtocolData.sum;
          if (protocol.sum === 0 && selectedTab === 'Details')
            setSelectedTab('Pdf');
          setNewPairedPrtocols([]);
          setNewEquipment([]);
          setNewChecklist([]);
          setIsParsedSuccessfull(false);
          setSelectedData(null);
          if (isPdfModal) {
            setFile(undefined);
            setIsPdfModal(false);
          } else setIsEditMode(false);
          formik.resetForm();
          setFile(undefined);
          setSelectedWorkbookURL('');
        }}
        // isVersionButton={isLoggedIn && !isPdfModal}
        type={'protocol'}
        isEditIcon={
          isLoggedIn &&
          !isEditMode &&
          user &&
          (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
        }
        handleCancel={handleBack}
        isSaveButton={isLoggedIn && isEditMode}
        isSaveActive={isSaveActive}
        isAddButton={
          isLoggedIn &&
          !isPdfModal &&
          (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
        }
        rightSideBtn={isPdfModal ? 'save' : 'add'}
        handleAdd={handleAdd}
        isUploadButton={
          isLoggedIn &&
          isEditMode &&
          selectedTab === 'Pdf' &&
          (!isPdfModal || file != null)
        }
        handleUpload={() => {
          if (isPdfModal) {
            if (file != null) setFile(undefined);
            else setIsPdfModal(false);
          } else setIsPdfModal(true);
        }}
        page={isPdfModal ? protocol.name : 'Folders'}
        isArrowButtons={!isPdfModal}
        isLeftArrowActive={protocol.index !== 0}
        isRightArrowActive={
          protocol.index !== protocol.parent.protocols.length - 1
        }
        handleArrow={(isRight: boolean) => {
          if (isRight) {
            let nextProtocol = protocol.parent.protocols[protocol.index + 1];
            navigate(`/${protocol.parent.name}/protocol-detail`, {
              state: { selectedProtocol: nextProtocol },
            });
            setSelectedTab(
              isLoggedIn && nextProtocol.sum !== 0 ? 'Details' : 'Pdf'
            );
          } else {
            let prevProtocol = protocol.parent.protocols[protocol.index - 1];
            navigate(`/${protocol.parent.name}/protocol-detail`, {
              state: { selectedProtocol: prevProtocol },
            });
            setSelectedTab(
              isLoggedIn && prevProtocol.sum !== 0 ? 'Details' : 'Pdf'
            );
          }
        }}
      />
      {/* </div> */}

      {itemSelected === '' && (
        <div className="backgroundHidden">
          {isPdfModal ? (
            <UploadProtocolPDF
              protocol={protocol}
              fileValue={file}
              department={department}
              onWorkbookSelected={(workbookURL: string) => {
                setSelectedWorkbookURL(workbookURL);
              }}
              onUpload={(file: File) => {
                setFile(file);
              }}
            />
          ) : (
            <>
              <div>
                {isLoggedIn && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: ' space-between',
                    }}
                  >
                    <div className="buttonContainer">
                      <Button
                        className={`secondary-button-gray btn-rightMargin ${
                          selectedTab === 'Details'
                            ? 'calculation-active btn-active'
                            : 'calculation-inActive'
                        }`}
                        onClick={() => handleTabClick('Details')}
                        disabled={!isLoggedIn}
                      >
                        Details
                      </Button>

                      <Button
                        className={`secondary-button-gray btn-rightMargin ${
                          selectedTab === 'Pdf'
                            ? 'btn-active pdf-active'
                            : 'pdf-inActive'
                        }`}
                        onClick={() => handleTabClick('Pdf')}
                      >
                        Pdf
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {selectedTab === 'Details' && isLoggedIn && (
                <CalculationScreen
                  formik={formik}
                  isEditMode={isEditMode}
                  protocolDetail={protocol}
                  onReorder={onReorder}
                  newPairedProtocols={newPairedPrtocols}
                  newEquipment={newEquipment}
                  newChecklists={newChecklist}
                  onItemClick={handleItemClick}
                />
              )}
              {selectedTab === 'Pdf' || !isLoggedIn ? (
                <PDFScreen pdf={pdfUrl} />
              ) : null}
              {/* {selectedTab === "Checklists" && isLoggedIn && (
                <Checklist isEdit={isEditMode} protocol={protocol} />
              )} */}
            </>
          )}
        </div>
      )}
      {loading && <Loading type="bubbles" />}
    </div>
  );
};

export default ProtocolDetails;
