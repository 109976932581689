import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import { HiPlus } from 'react-icons/hi';
import ConfirmModal from '../../Modal/ConfirmModal';
import { RadioButton } from 'primereact/radiobutton';
import { Question } from '../../../../models';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Checkbox } from 'primereact/checkbox';
import { set } from 'lodash';

type QuestionSideoutProps = {
  dialogVisible: boolean;
  onClose: () => void;
  headerText: string;
  handleSet: (question: Question, prevQuestion?: Question) => void;
  question?: any;
  handleDeleteQuestion?: (question: Question) => void;
  isView?: any;
};

/* 12-04-23 Praveen: Created the sideout for question and answers */
const QuestionSideout: React.FC<QuestionSideoutProps> = ({
  dialogVisible,
  onClose,
  headerText,
  handleSet,
  question,
  handleDeleteQuestion,
  isView,
}) => {
  const [parmQuestion, setParmQuestion] = useState<Question | undefined>(
    question
  );

  const [isDelete, setIsDelete] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [inputError, setInputError] = useState<any>({
    index: -1,
    message: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: parmQuestion?.question || '',
      answers: parmQuestion?.options ? parmQuestion?.options : ['', ''],
      correctAnswer: parmQuestion?.answer || '',
    },
    validationSchema: Yup.object({
      question: Yup.string().required('Question is required'),
      answers: Yup.array()
        .required('Answers are required')
        .of(Yup.string().required('Answer is required'))
        .min(2, 'Minimum 2 answers are required')
        .max(5, 'Maximum 5 answers are allowed')
        .test(
          'unique',
          'Answers must be unique and not empty',
          (answers: string[]) => {
            // Create a set from the answers array
            const set = new Set(answers);
            // Check if the size of the set is the same as the array length (no duplicates)
            return set.size === answers.length;
          }
        ),
      correctAnswer: Yup.string().required('Correct answer is required'),
    }),
    onSubmit: (values) => {
      const newQuestion: Question = {
        question: values.question,
        options: values.answers,
        answer: values.correctAnswer,
      };
      handleSet(newQuestion, parmQuestion);
      formik.resetForm();
    },
  });

  const checkForDuplicateAnswers = (value: string) => {
    const answers = formik.values.answers;
    const index = answers.findIndex((item: string) => item === value);
    return index !== -1;
  };

  useEffect(() => {
    setParmQuestion(question);
  }, [question]);

  const handleAddAnswer = () => {
    if (formik.values.answers.length >= 5) return;
    const updatedAnswers = [...formik.values.answers];
    updatedAnswers.push('');
    formik.setFieldValue('answers', updatedAnswers);
  };

  // Delete options
  const handleDeleteItem = useCallback(() => {
    if (selectedData) {
      const updatedAnswers = [...formik.values.answers];
      updatedAnswers.filter((item: string) => item !== selectedData);
      setIsDelete(false);
      formik.setFieldValue('answers', updatedAnswers);
      if (formik.values.correctAnswer === selectedData)
        formik.setFieldValue('correctAnswer', '');
    }
  }, [selectedData, formik.values]);

  /* Handle the cancel changes */
  const handleBack = () => {
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    else {
      setIsWarningModal(false);
      formik.resetForm();
      onClose();
    }
  };

  // function for delete the option
  const handleDeleteOption = useCallback(
    (answer: string, index: number) => {
      if (answer) {
        setSelectedData(answer);
        setIsDelete(true);
      } else {
        const updatedAnswers = [...formik.values.answers];
        updatedAnswers.splice(index, 1);
        setIsDelete(false);
        formik.setFieldValue('answers', updatedAnswers);
        if (formik.values.correctAnswer === answer)
          formik.setFieldValue('correctAnswer', '');
      }
    },
    [formik.values, setSelectedData, setIsDelete]
  );

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleBack}
    >
      <span className="">
        <BiArrowBack className="header-icon" style={{}} /> View Question
      </span>
    </div>
  );

  return (
    <div>
      <Sidebar
        visible={dialogVisible}
        header={customHeader}
        position="right"
        onHide={() => {
          handleBack();
        }}
        style={{ width: '35%' }}
        className={'sidebarWidth'}
      >
        <ConfirmModal
          isVisible={isDelete}
          title="Remove question?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want remove this question from the notification?'
          }
        />

        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Notification New page. Click cancel to return Notification New page.  To continue without saving changes, select Abandon Changes.`}
        />

        <div>
          <div style={{ padding: '0px 15px' }}>
            <div>
              <div>
                <div style={{ marginBottom: '5px' }}>
                  <span className="headerTextMargin">
                    Enter in the question and answers
                  </span>
                  <span className="contentText">
                    {' '}
                    Enter in the question and answers. Make sure to select the
                    correct answer
                  </span>
                </div>
                <div>
                  <label
                    htmlFor="question"
                    className={`notification-css-title`}
                  >
                    <span
                      className="headerTextMargin"
                      style={{ fontSize: '16px' }}
                    >
                      Question
                    </span>
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className=" notification-model"
                      id="question"
                      question-testid="question-sideout"
                      name="question"
                      autoFocus={question == null}
                      required={true}
                      value={formik.values.question}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      style={{
                        fontSize: '16px',
                        cursor: 'auto',
                        padding: '20px 20px',
                      }}
                      disabled={isView}
                    />
                    <div className="input-border"></div>
                  </div>
                  {formik.touched.question && formik.errors.question && (
                    <span className="errorText">{formik.errors.question}</span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Row>
                <Col md={2}></Col>
                <Col md={10}>
                  <span
                    className="headerTextMargin"
                    style={{
                      fontSize: '14px',
                      marginBottom: '0px',
                      marginTop: '5px',
                    }}
                  >
                    Answer
                  </span>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <span
                    className="headerTextMargin"
                    style={{ fontSize: '13px' }}
                  >
                    Correct
                  </span>
                </Col>
                <Col md={10}>
                  <span className="contentText">
                    {' '}
                    Enter in the question and answers. Make sure to select the
                    correct answer
                  </span>
                </Col>
              </Row>
              <div style={{ marginBottom: '5px' }}>
                {formik.values.answers?.map((answer: string, index: number) => {
                  const isChecked =
                    answer === formik.values.correctAnswer &&
                    formik.values.correctAnswer !== '';
                  return (
                    <Row md={12} style={{ width: '100%' }} key={index}>
                      <Col md={2}>
                        <div
                          key={index}
                          question-testid={`answer-check-${index}`}
                          onClick={() => {
                            formik.setFieldValue(
                              'correctAnswer',
                              !isChecked ? answer : ''
                            );
                          }}
                          className={`radioBtnSelectedColor cursorPointer item contentHeading `}
                        >
                          <div className="radioBtnSelectedColor">
                            <RadioButton
                              inputId="pairedProtocol"
                              name="pairedProtocol"
                              data-testid="paired-Protocol"
                              className="radioBtnColor"
                              style={{ marginRight: '10px' }}
                              checked={isChecked}
                              onChange={(e) => {
                                if (!isChecked)
                                  formik.setFieldValue('correctAnswer', answer);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={10}
                        style={{ display: 'grid', alignItems: 'end' }}
                      >
                        <div
                          className=" search-custom-input form-group"
                          style={{ padding: '2px' }}
                        >
                          <div style={{ width: '100%' }}>
                            <div aria-labelledby={`answer-label-${index}`}>
                              <InputText
                                type="text"
                                className={`p-inputtextborderNone p-inputtext form-control pointer `}
                                id={`answer-${index}`}
                                name={`answer-${index}`}
                                placeholder=""
                                value={answer}
                                onChange={(e) => {
                                  if (checkForDuplicateAnswers(e.target.value))
                                    setInputError({
                                      index: index,
                                      message: 'Answers must be unique',
                                    });
                                  else if (inputError.index !== -1)
                                    setInputError({ index: -1, message: '' });
                                  const updatedAnswers = [
                                    ...formik.values.answers,
                                  ];
                                  updatedAnswers[index] = e.target.value;
                                  formik.setFieldValue(
                                    'answers',
                                    updatedAnswers
                                  );
                                  if (isChecked)
                                    formik.setFieldValue(
                                      'correctAnswer',
                                      e.target.value
                                    );
                                }}
                                onBlur={formik.handleBlur}
                                onKeyDown={(e) => {
                                  if (
                                    e.key === 'Enter' &&
                                    index === formik.values.answers.length - 1
                                  ) {
                                    handleAddAnswer();
                                  }
                                }}
                                //question-testid="answer"
                                question-testid={`answer-text-${index}`}
                                style={{
                                  cursor: 'auto',
                                  textAlign: 'left',
                                  fontWeight: 400,
                                }}
                                disabled={isView}
                              />
                            </div>
                            <div
                              className="input-border"
                              style={{ top: 0, right: '110px', left: '110px' }}
                            ></div>
                          </div>

                          {!isView && (
                            <div>
                              <span
                                tabIndex={-1}
                                className={`close_icon_color cursorPointer}`}
                                style={{
                                  marginRight: '10px',
                                  paddingTop: '2px',
                                }}
                              >
                                <AiOutlineCloseCircle
                                  onClick={() => {
                                    handleDeleteOption(answer, index);
                                  }}
                                  className="cursorPointer icon-normal"
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        {formik.touched.answers && formik.errors.answers && (
                          <span className="errorText">
                            {formik.errors.answers[index]}
                          </span>
                        )}
                        {inputError.index === index && (
                          <span className="errorText">
                            {inputError.message}
                          </span>
                        )}
                      </Col>
                    </Row>
                  );
                })}

                {formik.values.answers.length < 5 && !isView && (
                  <div
                    tabIndex={-1}
                    onClick={() => {
                      handleAddAnswer();
                    }}
                    className="cursorPointer contentHeading checklist newProtocolBorder "
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      style={{
                        textDecoration: 'underLine',
                        textUnderlinePosition: 'under',
                        fontSize: '15px',
                      }}
                    >
                      <HiPlus className="text-icon " /> Add
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dialogButtons btn_Bottom">
            <Button
              className="secondary-button-dialog btn-rightMargin"
              onClick={handleBack}
            >
              {isView ? 'Dismisss' : 'Cancel'}{' '}
            </Button>
            {handleDeleteQuestion && parmQuestion && !isView && (
              <Button
                data-testid="delete-question"
                className="red-background-button btn-rightMargin "
                onClick={(e) => handleDeleteQuestion(parmQuestion)}
              >
                Delete
              </Button>
            )}
            {!isView && (
              <Button
                className="primary-button"
                disabled={!formik.isValid}
                onClick={() => formik.submitForm()}
              >
                Set
              </Button>
            )}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default QuestionSideout;
