import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import * as XLSX from 'xlsx';
import CategoryItem from '../../../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import Fuse from 'fuse.js';
import MedicationItem, {
  cloneMedication,
} from '../../../../../../data/model/MedicationItem';
import { globals } from '../../../../../_global/common/Utils';
import { Medication } from '../../../../../../models';
import { DataStore } from 'aws-amplify';
import { fetchMedicationsFromAPI } from '../../../../../../data/GraphQL_API';

const ADULT_CUTOFF = 40;
const PED_CUTOFF = 5;

const FILE_NAME = 'File Name';
const FILE_PAGE_INDEX = 'Page Number';
const ITEM_INDEX = 'Index';
const ITEM_SOURCE = 'Source';
const ITEM_TYPE = 'Type';
const ITEM_NAME = 'Name';
const ITEM_DOSE = 'Dose';
const ITEM_ROUTE = 'Administration Routes';
const ITEM_MIN_SINGLE = 'Min Single Dose';
const ITEM_MAX_SINGLE = 'Max Single Dose';
const ITEM_MAX_TOTAL = 'Max Total Dose';
const ITEM_REPEAT_TIME = 'Repeat Time';
const ITEM_REPEAT_ALLOWED = 'Repeat Allowed';
const ITEM_AGE_GROUP = 'Age Group';
const ITEM_RANGE_LOW = 'Range Low (kg)';
const ITEM_RANGE_HIGH = 'Range High (kg)';
const ITEM_AGE_LOW = 'Age Low';
const ITEM_AGE_HIGH = 'Age High';
const ITEM_TITLE = 'Title';
const ITEM_WARNING = 'Warning';
const ITEM_INSTRUCTIONS = 'Instruction';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

const type = {
  Equipment: ['Equipment', 'EQUIPAMENT', 'Equip'],
  Medication: ['Medication', 'Med'],
  Infusion: ['Infusion', 'Infus'],
  Electrical: ['Electrical', 'Elec'],
};

type FilePage = {
  file: string;
  index: number;
};

type MedicationDose = {
  file: FilePage;
  medication: MedicationItem;
  medicationData: MedicationExtraction;
  isHM: boolean;
};

type MedicationExtraction = {
  index: number;
  name: string;
  dose: string;
  routes: string[];
  repeatAllowed: boolean;
  source: string;

  maxSingleDose?: string;
  minSingleDose?: string;
  maxTotalDose?: string;
  repeatTime?: string;

  ageGroup?: string;
  rangeLow?: string;
  rangeHigh?: string;
  ageLow?: string;
  ageHigh?: string;

  title?: string;
  warning?: string;
  instructions?: string;
  note?: string;
};

export const processDosesExcelFile = async (
  file: File,
  database: DatabaseResponse,
  createMedicationsCallback: (meds: MedicationItem[]) => void
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (
        file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        resolve({
          type: ResponseType.Failure,
          data: 'Only Excel Workbook files are allowed',
        });
      }
      let dbMeds = await getHinckleyMediations(database);
      // reject('Not implemented');
      // return;
      let promises = [
        readExcelFile(file, database, dbMeds),
        new Promise((resolve) => setTimeout(() => resolve('timeout'), 10000)),
      ];
      let response: any = await Promise.race(promises);
      console.log('Response:', response);
      if (response === 'timeout') {
        console.log('File is taking too long to process');
        resolve({
          type: ResponseType.Failure,
          data: 'File is taking too long to process',
        });
      } else {
        if (response.type === ResponseType.Success) {
          console.log('Success reading excel file:', response.data);
          let groupData = groupPages(database, dbMeds, response.data);
          resolve({ type: ResponseType.Success, data: groupData });
        } else {
          console.log('Error reading excel file:', response.data);
          resolve(response);
        }
      }
    } catch (error) {
      console.error('Error processing excel file:', error);
      reject(error);
    }
  });
};

function getHinckleyMediations(
  db: DatabaseResponse
): Promise<MedicationItem[]> {
  return new Promise(async (resolve, reject) => {
    try {
      // const medicationList = await DataStore.query(Medication, (m) =>
      //   m.and((m) => [
      //     m.departmentID.eq(HM_DB_ID),
      //     m.or((m) => [m.status.eq(undefined), m.status.eq('ACTIVE')]),
      //   ])
      // );
      // let meds = medicationList.map((med) => new MedicationItem(med));
      const medResponse = await fetchMedicationsFromAPI(
        db.department,
        HM_DB_ID
      );
      if (medResponse.type === ResponseType.Success) {
        resolve(medResponse.data);
      } else reject('Error fetching medications');
      // console.log('Medication List:', meds.length);
      // resolve(meds);
    } catch (error) {
      console.error(error);
    }
  });
}

function groupPages(
  database: DatabaseResponse,
  dbMeds: MedicationItem[],
  extractedDoses: any
): any {
  let data = {
    pages: [] as any[],
    newMedications: [] as MedicationItem[],
    medications: [] as MedicationItem[],
    misses: extractedDoses.misses,
  };

  for (let i = 0; i < extractedDoses.medication.length; i++) {
    let dose = extractedDoses.medication[i];
    let page = dose.file;

    /* Check if it is an HM medication */
    if (dose.isHM) {
      let medIndex = data.newMedications.findIndex(
        (m) => m.name === dose.medication.name
      );
      if (medIndex === -1) {
        data.newMedications.push(dose.medication);
        console.log('NEW MEDICATION DOSE:', dose);
      }
    } else {
      let medIndex = data.medications.findIndex(
        (m) => m.name === dose.medication.name
      );
      if (medIndex === -1) {
        data.medications.push(dose.medication);
      }
    }

    let pageData: any = data.pages.find(
      (p: any) => p.file === page.file && p.index === page.index
    );
    if (pageData == null) {
      pageData = {
        file: page.file,
        index: page.index,
        doses: [dose],
      };
      data.pages.push(pageData);
    } else {
      pageData.doses.push(dose);
      // /* Check if the medication already exists */
      // let medIndex = pageData.doses.findIndex(
      //   (d: any) => d.medication === dose.medication
      // );
      // if (medIndex === -1) {
      //   pageData.doses.push({
      //     medication: dose.medication,
      //     data: [dose],
      //   });
      // } else {
      //   pageData.doses[medIndex].data.push(dose);
      // }
    }
  }

  if (globals.debug) console.log('Grouped Doses:', data);
  data.newMedications.sort((a, b) => a.name.localeCompare(b.name));
  data.medications.sort((a, b) => a.name.localeCompare(b.name));
  return data;
}

async function readExcelFile(
  file: File,
  database: DatabaseResponse,
  dbMeds: MedicationItem[]
): Promise<Response> {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'buffer' });
      let misses = {
        category: [] as any[],
        protocol: [] as any[],
        medication: [] as any[],
        infusion: [] as any[],
        equipment: [] as any[],
        electrical: [] as any[],
      };
      for (let i = 0; i < workbook.SheetNames.length; i++) {
        const sheetName = workbook.SheetNames[i];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if (globals.debug) console.log(`Data from ${sheetName}:`, json);
        if (sheetName === 'Medication') {
          let curFilePage: FilePage | undefined;
          // let curProtocol: ProtocolItem | undefined;
          let medicationDoses: MedicationDose[] = [];
          let infusionDoses: MedicationDose[] = [];
          let equipmentDoses: MedicationDose[] = [];
          let electricalDoses: MedicationDose[] = [];

          let medMisses: any[] = [];
          json.forEach((row: any) => {
            /* Extract the current file page */
            if (row[FILE_NAME] != null) {
              try {
                curFilePage = {
                  file: row[FILE_NAME],
                  index: Number(row[FILE_PAGE_INDEX]),
                };
                console.log('Extracting file page:', curFilePage);
              } catch (error) {
                console.log('Error parsing file page:', error);
              }
              // curCategory = findCategory(row[CATEGORY_NAME], database);
              // if (curCategory == null)
              //   misses.category.push({ name: row[CATEGORY_NAME] });
            } else if (row[ITEM_INDEX] != null && curFilePage != null) {
              // console.log('Extracting item:', row[ITEM_NAME]);
              /* Extract the current item */
              /* Determine the type of the item */
              let itemType = row[ITEM_TYPE].toLowerCase()
                .trim()
                .replace(/\s+/g, ' ');

              /* Extract the medication data */
              if (itemType.includes('med')) {
                let med = extractMedicationData(row);
                // return;
                let medJson = med
                  ? findMedication(med.name, database, dbMeds)
                  : undefined;
                const dbMed = medJson?.med;
                const isHM = medJson?.isHM;

                if (med && dbMed) {
                  medicationDoses.push({
                    file: curFilePage,
                    medication: dbMed,
                    medicationData: med,
                    isHM: isHM,
                  });
                } else if (med && !dbMed) {
                  misses.medication.push({
                    row: row,
                  });
                  medMisses.push(med);
                }
              }

              /* Extract the equipment data */

              /* Extract the infusion data */

              /* Extract the electrical data */
            }
          });
          if (globals.debug) {
            // console.log('Categories Misses:', misses.category);
            // console.log('Protocols Misses:', misses.protocol);
            // console.log('Medication Misses:', misses.medication);
            console.log('Medication Misses:', medMisses);
            console.log('Medications hits', medicationDoses.length);
          }
          resolve({
            type: ResponseType.Success,
            data: {
              medication: medicationDoses,
              infusion: infusionDoses,
              equipment: equipmentDoses,
              electrical: electricalDoses,
              misses: {
                medication: medMisses,
                infusion: [],
                equipment: [],
                electrical: [],
              },
            },
          });
        }
      }
    } catch (error) {
      console.error('Error reading excel file===:', error);
      reject(error);
    }
  });
}

function findCategory(
  name: string,
  database: DatabaseResponse
): CategoryItem | undefined {
  // Options for Fuse.js
  const options = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
    keys: ['name'], // Fields to search in each object
  };
  const fuse = new Fuse(database.categories, options);
  const result = fuse.search(name);
  for (let i = 0; i < result.length; i++) {
    let cat: CategoryItem = result[i].item;
    if (result[i].score != null && (result[i].score as number) < 0.2) {
      return cat;
    }
  }
  return undefined;
}

/**
 * Search for the protocol based on hte inputted text using the nickname and name
 * @param nickname The nickname of the protocol
 * @param name The name of the protocol
 * @param database  The database to search in
 * @returns The protocol item if found, otherwise undefined
 */
function findProtocol(
  nickname: string,
  name: string,
  database: DatabaseResponse
): ProtocolItem | undefined {
  let processedProtocols: any[] = database.protocols.map((protocol) => {
    let _nickname = preprocessText(protocol.nickname);
    let _name = preprocessText(protocol.name);
    return {
      nickname: _nickname,
      name: _name,
      protocol: protocol,
    };
  });

  let _nickname = preprocessText(nickname);
  let _name = preprocessText(name);

  // Options for Fuse.js
  const nicknameOptions = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.4, // Threshold for matching. A lower number means more exact matches.
    keys: ['nickname'], // Fields to search in each object
  };
  const nameOptions = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.4, // Threshold for matching. A lower number means more exact matches.
    keys: ['name'], // Fields to search in each object
  };
  const nicknameFuse = new Fuse(processedProtocols, nicknameOptions);
  const nickNameResult = nicknameFuse.search(_nickname);

  const nameFuse = new Fuse(processedProtocols, nameOptions);
  const nameResult = nameFuse.search(_name);

  let foundScore = 1;
  let foundProtocol: ProtocolItem | undefined = undefined;
  for (let i = 0; i < nickNameResult.length; i++) {
    let protocol: ProtocolItem = nickNameResult[i].item.protocol;
    if (
      nickNameResult[i].score != null &&
      (nickNameResult[i].score as number) < 0.4
    ) {
      /* Exact match found */
      if ((nickNameResult[i].score as number) < 0.1) {
        return protocol;
      } else {
        foundProtocol = protocol;
        foundScore = nickNameResult[i].score as number;
        break;
      }
    }
  }

  for (let i = 0; i < nameResult.length; i++) {
    let protocol: ProtocolItem = nameResult[i].item.protocol;
    let score = nameResult[i].score;
    if (foundProtocol == protocol) score = foundScore + (score as number) / 2;

    if ((score as number) < 0.4) {
      if (foundProtocol == protocol) {
        return protocol;
      }
    }
  }

  return undefined;
}

function create_process_list(medList: MedicationItem[], isHM: boolean): any[] {
  let processList = [];
  // if (isHM) {
  for (let i = 0; i < medList.length; i++) {
    let med = medList[i];
    processList.push({
      name: preprocessText(med.name),
      med: med,
      isHM: isHM,
    });
    processList.push({
      name: preprocessText(med.dbMedication?.metaData?.fullName ?? ''),
      med: med,
      isHM: isHM,
    });
    processList.push({
      name: preprocessText(med.dbMedication?.metaData?.nickName ?? ''),
      med: med,
      isHM: isHM,
    });
    let names = med.dbMedication?.metaData?.brandNames ?? [];
    for (let j = 0; j < names.length; j++) {
      processList.push({
        name: preprocessText(names[j]),
        med: med,
        isHM: isHM,
      });
    }
  }
  // } else {
  //   for (let i = 0; i < medList.length; i++) {
  //     let med = medList[i];
  //     processList.push({
  //       name: preprocessText(med.name),
  //       med: med,
  //       isHM: false,
  //     });
  //   }
  // }

  return processList;
}

function findMedication(
  name: string,
  database: DatabaseResponse,
  dbMeds: MedicationItem[]
): any | undefined {
  const processedList = create_process_list(database.medications, false);

  // Options for Fuse.js
  const options = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
    keys: ['name'], // Fields to search in each object
  };
  const fuse = new Fuse(processedList, options);
  //   const splitName = name.split(' ');
  //   for (let i = 0; i < splitName.length; i++) {
  let search = name
    .toLowerCase()
    .trim()
    .replace(/()-\/\\_/g, '');
  const result = fuse.search(search);
  for (let i = 0; i < result.length; i++) {
    if (result[i].score != null && (result[i].score as number) < 0.2)
      return {
        med: result[i].item.med,
        isHM: result[i].item.isHM,
      };
  }

  const hmProcessedList = create_process_list(dbMeds, true);
  const fuseHM = new Fuse(hmProcessedList, options);
  const resultHM = fuseHM.search(search);
  for (let i = 0; i < resultHM.length; i++) {
    if (resultHM[i].score != null && (resultHM[i].score as number) < 0.2)
      return {
        med: resultHM[i].item.med,
        isHM: resultHM[i].item.isHM,
      };
  }
  // if (globals.debug) console.log('Medication not found:', search, result);
  //   }
  return undefined;
}
const preprocessText = (text: string) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[.\s()+\-\/\\_]/g, ''); // Modified regex to capture other characters you mentioned
};

function extractMedicationData(row: any): MedicationExtraction | undefined {
  let index = Number(row[ITEM_INDEX]) ? Number(row[ITEM_INDEX]) : 0;
  let name = row[ITEM_NAME];
  let dose = cleanDose(row[ITEM_DOSE]);
  let routes = row[ITEM_ROUTE] ? row[ITEM_ROUTE].split(',') : [];
  let source = row[ITEM_SOURCE];
  let maxSingleDose = cleanDose(row[ITEM_MAX_SINGLE]);
  let minSingleDose = cleanDose(row[ITEM_MIN_SINGLE]);
  let maxTotalDose = cleanDose(row[ITEM_MAX_TOTAL]);
  let repeatTime = row[ITEM_REPEAT_TIME];
  let repeatAllowed = row[ITEM_REPEAT_ALLOWED] != null;

  let rangeResult = cleanRange(
    row[ITEM_RANGE_LOW],
    row[ITEM_RANGE_HIGH],
    row[ITEM_AGE_GROUP]
  );
  let ageGroup = row[ITEM_AGE_GROUP];
  let rangeLow = rangeResult.low;
  let rangeHigh = rangeResult.high;
  let title = row[ITEM_TITLE];
  let warning = row[ITEM_WARNING];
  let instructions = row[ITEM_INSTRUCTIONS];
  let note = '';

  /* Validate the required fields */
  if (source == null || name == null) return undefined;

  /* 7-12-24 Hazlett: Colton added this to modify the titles and instructions to be more accurate */
  if (repeatAllowed && repeatTime) {
    note = instructions;
    instructions = 'May repeat EDIT ' + repeatTime + ' as needed';
  }

  if (maxTotalDose) {
    title = 'Max total ' + maxTotalDose + '. ' + title;
  }

  return {
    index: index,
    name: name,
    dose: dose,
    routes: routes,
    source: source,

    maxSingleDose: maxSingleDose,
    minSingleDose: minSingleDose,
    maxTotalDose: maxTotalDose,
    repeatTime: repeatTime,
    repeatAllowed: repeatAllowed,

    ageGroup: ageGroup,
    rangeLow: rangeLow,
    rangeHigh: rangeHigh,
    title: title,
    warning: warning,
    instructions: instructions,
    note: note,
  };
}

function cleanDose(dose_str: any): string {
  let dose = dose_str + '';
  // Trim, remove redundant spaces, and handle line breaks
  if (dose == null) return '';
  dose = dose
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[\r\n]+/g, '');

  // Pattern to find numeric values possibly with ranges and units, optionally followed by "/kg"
  const dosePattern =
    /(\d+\.?\d*)\s*-\s*(\d+\.?\d*)\s*(mg|mcg|g|ml|mL|MG|Mcg|G|ML|mEq|MEQ|gram|GRAM|gm|GM)\b|\b(\d+\.?\d*)\s*(mg|mcg|g|ml|MG|Mcg|G|ML)\b/g;
  const perKgPattern = /per kg|\/kg|mg\/kg|mcg\/kg|g\/kg|ml\/kg/i;

  let match,
    matches = [];

  // Check for range or single value doses
  while ((match = dosePattern.exec(dose)) !== null) {
    if (match[1] && match[2]) {
      // Range dose
      matches.push(`${match[1]}-${match[2]} ${match[3].toLowerCase()}`);
    } else if (match[4] && match[5]) {
      // Single dose
      matches.push(`${match[4]} ${match[5].toLowerCase()}`);
    }
  }

  // Determine if dose is per kg
  const perKg = perKgPattern.test(dose) ? '/kg' : '';

  // Concatenate all matches with the per kg notation if applicable
  return matches.join(' and ') + perKg;
}

function cleanRange(
  rangeLow: string,
  rangeHigh: string,
  ageGroup: string
): any {
  let weightLow = cleanWeight(rangeLow);
  let weightHigh = cleanWeight(rangeHigh);
  let ageRangeLow = cleanAgeGroup(ageGroup, false);
  let ageRangeHigh = cleanAgeGroup(ageGroup, true);

  if (weightLow || weightHigh) {
    return {
      low: weightLow,
      high: weightHigh,
    };
  } else if (ageRangeLow || ageRangeHigh) {
    return {
      low: ageRangeLow,
      high: ageRangeHigh,
    };
  }
  return {
    low: '',
    high: '',
  };
}

function cleanWeight(weight: string): string {
  if (!weight) return '';
  // Trim the input and replace multiple spaces with a single space
  weight = weight.trim().replace(/\s+/g, ' ');

  // Define a regular expression to match patterns like "5 kg" or "5kg" (numbers followed by 'kg')
  const weightPattern = /^(\d+\.?\d*)\s*kg$/i;

  let match = weight.match(weightPattern);

  if (match) {
    // If there's a match, format it correctly as "5 kg"
    return `${parseFloat(match[1])} kg`;
  }

  // If no valid format is found, return an empty string or a default message
  return '';
}

function cleanAgeGroup(ageGroup: string, isHigh: boolean): string {
  if (!ageGroup) return '';
  // Trim the input and convert to lowercase for case-insensitive comparison
  ageGroup = ageGroup.trim().toLowerCase();

  if (ageGroup === 'adult' && !isHigh) {
    return `${Number(ADULT_CUTOFF)} kg`;
  } else if (ageGroup === 'pediatric' && isHigh) {
    return `${Number(ADULT_CUTOFF)} kg`;
  }

  // If no valid age group is found, return an empty string or a default message
  return '';
}
