// Note: EditDB for ElectricalShock
// Date: 01/15/2024
// Author: Guruprasad Venkatraman

import { ProgressStatus } from '../../API';
import { DataStore, graphqlOperation } from 'aws-amplify';
import {
  ElectricalRange,
  ElectricalShock,
  ElectricalShockOption,
  ElectricalShockRange,
  ModelMetaData,
  User,
} from '../../models';
import { ResponseType, Response, DatabaseResponse } from '../AmplifyDB';
import ElectricalItem, { cloneElectrical } from '../model/ElectricalItem';
import DepartmentItem from '../model/DepartmentItem';
import ProtocolItem from '../model/ProtocolItem';
import { ProtocolDB, createProtocol, validatePointerID } from './ProtocolDB';
import {
  getObjectDifference,
  globals,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import ElectricalSubItem from '../model/ElectricalSubItem';
import { executeQuery } from '../GraphQL_API';
import { getElectricalShock } from '../../graphql/queries';

export type ElectricalJSON = {
  title: string;
  options: ElectricalShockOption[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  instruction?: string;
  rxNormCode?: string | null | undefined;
  warning?: string;
  note?: string;
  taggedProtocols?: ProtocolItem[];
  modifiedBy?: string;
  createdBy: string;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  version: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;
};

/**
 * This function will check if the electrical is a draft version and delete it
 * @param electricalItem The electrical to check
 * @returns Success if ready to create a new electrical or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: ElectricalShock[];
    if (isActive)
      results = await DataStore.query(ElectricalShock, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.activeID.eq(id)])
      );
    else
      results = await DataStore.query(ElectricalShock, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbEquip = results[0];
    if (dbEquip.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(ElectricalShock, dbEquip.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbEquip,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new electrical in the database and choose the version
 * @param electrical ElectricalJSON JSON format
 * @returns The successful electricalItem or the error
 */
export const createElectrical = async (
  electrical: ElectricalJSON | ElectricalItem,
  previousItem?: ElectricalItem
): Promise<Response> => {
  try {
    let json: ElectricalJSON;
    if (electrical instanceof ElectricalItem) {
      let elec = electrical as ElectricalItem;
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < elec.subElectricals.length; i++) {
        if (!protocols.includes(elec.subElectricals[i].parentProtocol))
          protocols.push(elec.subElectricals[i].parentProtocol);
      }
      json = {
        title: elec.name,
        rangeLow: elec.rangeLow,
        rangeHigh: elec.rangeHigh,
        instruction: elec.dbElectrical.instruction
          ? elec.dbElectrical.instruction
          : '',
        warning: elec.dbElectrical.warning ? elec.dbElectrical.warning : '',
        note: elec.dbElectrical.note ? elec.dbElectrical.note : '',
        status: elec.status,
        activeID: elec.activeID,
        departmentID: elec.depID,
        taggedProtocols: protocols,
        version: elec.version != null ? elec.version : 'v1.0.0',
        options: elec.options != null ? elec.options : [],
        createdBy: elec.model.createdBy ? elec.model.createdBy : '',
        modifiedBy: elec.modifiedBy ? elec.modifiedBy.id : undefined,
      };
    } else json = electrical as ElectricalJSON;

    /* Check if creating a draft version if there is already a draft */
    //  if(!json.isNew && json.status === ProgressStatus.DRAFT) {
    //     let id: string = (json.activeID == null ? (currentID ? currentID : '') : json.activeID)
    //     if(!id) {
    //         return {
    //             type: ResponseType.Failure,
    //             data: "The electrical does not have a activeID or currentID to upgrade the draft version",
    //         };
    //     }
    //     let draftResult: Response = await checkUpgradeDraftVersion(id, json.activeID != null);
    //     if(draftResult.type === ResponseType.Failure) {
    //         return {
    //             type: ResponseType.Failure,
    //             data: draftResult.data
    //         }
    //     }
    // }

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    let e: ElectricalShock;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbElec = await DataStore.query(ElectricalShock, previousItem.uid);
      if (dbElec == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Electrical does not exist could not update',
        };
      }

      if (globals.debug)
        console.log('Updating DRAFT Electrical: ', dbElec, json);

      e = await DataStore.save(
        ElectricalShock.copyOf(dbElec, (updated) => {
          updated.title = json.title;
          updated.options = json.options;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.instruction = json.instruction;
          updated.warning = json.warning;
          updated.note = json.note;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      if (globals.debug) console.log('Creating DRAFT Electrical: ', json);
      e = await DataStore.save(
        new ElectricalShock({
          title: json.title,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          version: json.version,
          options: json.options,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          instruction: json.instruction,
          warning: json.warning,
          note: json.note,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    let elecItem = new ElectricalItem(e);

    /* Validate the protocols point to the electrical --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        let elecID =
          elecItem.status === ProgressStatus.ACTIVE ||
          (elecItem.status === ProgressStatus.DRAFT &&
            elecItem.activeID == null)
            ? elecItem.uid
            : elecItem.activeID;
        if (elecID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The electrical does not have a proper version ID ' +
              elecItem.uid +
              ' ' +
              elecItem.activeID +
              ' ' +
              elecItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          elecID,
          json.modifiedBy,
          'Electrical'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: elecItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Duplicate the electrical doses from the one protocol to another protocol
 * @param protocol The new protocol that has just been created
 * @param previousProtocol The protocol that was duplicated
 * @returns An array of the electricals that were duplicated
 */
export const duplicateElectricalShocks = async (
  protocol: ProtocolItem,
  previousProtocol: ProtocolItem
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      previousProtocol.status === ProgressStatus.ACTIVE ||
      (previousProtocol.status === ProgressStatus.DRAFT &&
        previousProtocol.activeID == null)
        ? previousProtocol.uid
        : previousProtocol.activeID;
    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The previous protocol does not have a proper version ID ' +
          previousProtocol.uid +
          ' ' +
          previousProtocol.activeID +
          ' ' +
          previousProtocol.status,
      };
    }

    /* Loop through the electricals and create a new ElectricalShockOption */
    let electricals: ElectricalItem[] = [];
    let completed: ElectricalItem[] = [];
    for (let i = 0; i < previousProtocol.electrical.length; i++) {
      if (previousProtocol.electrical[i].parentElectrical == null) continue;
      const elec: ElectricalItem =
        previousProtocol.electrical[i].parentElectrical;

      /* Check if the electrical is already in the list */
      if (completed.find((m) => m.uid === elec.uid) == null) {
        /* Find the ElectricalShockOption for the previous protocol */
        const es = elec.dbElectrical.options.find((p) => {
          return p.protocolID === protID;
        });

        if (es == null) {
          console.error('ERROR: The ElectricalShockOption does not exist');
          continue;
        }

        /* Duplicate the ElectricalShockOption */
        let newID =
          protocol.status === ProgressStatus.ACTIVE ||
          (protocol.status === ProgressStatus.DRAFT &&
            protocol.activeID == null)
            ? protocol.uid
            : protocol.activeID;
        if (newID == null) {
          console.error('ERROR: The new protocol does not have a proper ID');
          continue;
        }
        let newElectricalShock = new ElectricalShockOption({
          protocolID: newID,
          ranges: es.ranges.map((o) => {
            return new ElectricalShockRange({
              basis: o.basis,
              rangeLow: o.rangeLow,
              rangeHigh: o.rangeHigh,
              title: o.title,
              warning: o.warning,
              instruction: o.instruction,
              note: o.note,
              calcMax: o.calcMax,
              fixedMax: o.fixedMax,
              index: o.index,
            });
          }),
        });

        /* Update the electrical with the new ElectricalShockOption */
        let options = [...elec.options, newElectricalShock];
        let newElec = cloneElectrical(elec);
        newElec.options = options;
        newElec.status = ProgressStatus.DRAFT;
        newElec.activeID =
          elec.status === ProgressStatus.ACTIVE ? elec.uid : elec.activeID;
        newElec.version = upgradeVersion(
          elec.version == null ? 'v1.0.0' : elec.version
        );
        newElec.modifiedBy = protocol.modifiedBy;

        let results = await createElectrical(newElec, elec);
        if (results.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: results.data,
          };
        }
        completed.push(elec);
        electricals.push(results.data as ElectricalItem);
      }
    }

    if (globals.debug)
      if (globals.debug)
        console.log('Successfully duplicated electricals:', electricals);
    return {
      type: ResponseType.Success,
      data: electricals,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if the protocol is already pointing at the electrical
 *    - Otherwise add the electrical to the protocol and create a new protocol draft
 * @param electrical The electrical o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the electrical
 * @param modifiedBy The user that modified the protocol
 * @returns Success if the electrical is paired with the protocol and returns a MedicationProtocol or Failure if there was an error
 */
export const validateElectricalProtocolPairing = async (
  electrical: ElectricalItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    if (electrical.dbElectrical == null) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical does not have a database object could be an infusion item',
      };
    }
    let electricalOption: ElectricalShockOption;
    let elecID =
      electrical.status === ProgressStatus.ACTIVE ||
      (electrical.status === ProgressStatus.DRAFT &&
        electrical.activeID == null)
        ? electrical.uid
        : electrical.activeID;
    let pairedID = protocol.model.electricalIDs?.find((id) => id === elecID);

    if (elecID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The electrical does not have a proper version ID ' +
          electrical.uid +
          ' ' +
          electrical.activeID +
          ' ' +
          electrical.status,
      };
    }

    /* Create a new protocol draft if the electrical is not paired with the protocol */
    if (pairedID == null) {
      let elecIDs = protocol.model.electricalIDs
        ? protocol.model.electricalIDs
        : [];
      elecIDs.push(elecID);

      let draftProtocol: ProtocolDB = {
        departmentID: protocol.parent.depID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDeps: protocol.pairedDeps,
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: protocol.medications.map((m) => m.uid),
        infusions: protocol.infusions.map((i) => i.uid),
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: elecIDs,
        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        keychainID: protocol.keychainID,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      electricalOption = new ElectricalShockOption({
        protocolID: protocol.uid,
        ranges: [],
      });
    } else {
      /* Find the electrical protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let eo = electrical.dbElectrical.options.find(
        (p) => p.protocolID === protID
      );
      if (eo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical protocol does not exist',
        };
      }
      electricalOption = eo;
    }

    return {
      type: ResponseType.Success,
      data: electricalOption,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Reorder the electrical shock inside of the protocol
 * @param electrical The parent electrical to reorder the electrical shock
 * @param protocol The protocol to reorder the electrcal dose in
 * @param electricalShocks The electrical dose to reorder
 * @param modifiedBy The user that modified the electrical dose
 * @returns Success if the electrical dose was reordered or Failure if there was an error
 */
export const reorderElectricalShock = async (
  electrical: ElectricalItem,
  protocol: ProtocolItem,
  electricalShocks: ElectricalSubItem[],
  modifiedBy: User
): Promise<Response> => {
  try {
    /* First check if the electrical and protocol are already paired together */
    let validateResult: Response = await validateElectricalProtocolPairing(
      electrical,
      protocol,
      modifiedBy
    );
    if (validateResult.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: validateResult.data,
      };
    }

    let elecOption: ElectricalShockOption =
      validateResult.data as ElectricalShockOption;

    /* Create the new electrical doses */
    let newElecRanges = electricalShocks.map((e, i) => {
      let range = e.range;
      if (globals.debug)
        console.log('Settiing range', range.title, range.basis, e.index);
      let elecRange = new ElectricalShockRange({
        basis: range.basis,
        rangeLow: range.rangeLow,
        rangeHigh: range.rangeHigh,
        title: range.title,
        warning: range.warning,
        instruction: range.instruction,
        note: range.note,
        calcMax: range.calcMax,
        fixedMax: range.fixedMax,
        index: e.index,
      });
      return elecRange;
    });

    /* Check if we are updating a previous dose and filter out the previous dose otherwise add the new range */
    let electricalRanges = [...elecOption.ranges];
    if (globals.debug)
      console.log('Previous Electrical Ranges:', electricalRanges);
    for (let i = 0; i < electricalShocks.length; i++) {
      let range = electricalShocks[i].range;
      electricalRanges = electricalRanges.filter(
        (m) => getObjectDifference(m, range).length != 0
      );
    }

    if (globals.debug) console.log('POST Electrical Ranges:', electricalRanges);
    if (globals.debug) console.log('New Electrical Ranges:', newElecRanges);

    let newElecOption = new ElectricalShockOption({
      protocolID: elecOption.protocolID,
      ranges: [...electricalRanges, ...newElecRanges],
    });

    let options = [...electrical.options];
    options = options.filter((p) => p.protocolID !== elecOption.protocolID);
    options.push(newElecOption);

    /* Update the parent electrical with the new MedicationProtocol */
    let newElec = cloneElectrical(electrical);
    newElec.options = options;
    newElec.version = upgradeVersion(
      electrical.version == null ? 'v1.0.0' : electrical.version
    );
    newElec.activeID =
      electrical.status === ProgressStatus.ACTIVE
        ? electrical.uid
        : electrical.activeID;
    newElec.status = ProgressStatus.DRAFT;
    newElec.modifiedBy = modifiedBy;

    let results = await createElectrical(newElec, electrical);

    if (results.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: results.data,
      };
    }
    let newElectrical = results.data as ElectricalItem;

    return {
      type: ResponseType.Success,
      data: newElectrical,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Publishes draft electricals for a given department.
 * @param department - The department for which to publish draft electrical.
 * @returns A Promise that resolves to a Response object.
 */
export const publishElectricalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftElectricals = await DataStore.query(ElectricalShock, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftElectricals.length; i++) {
      let elec: ElectricalShock = draftElectricals[i];
      let response: Response = await publishElectrical(elec);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: elec,
          message: `Published Folder: ${elec.title}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the electrical to the database
 *    1. Create a new ARCHEIVED electrical based on the current ACTIVE electrical
 *    2. Update the ACTIVE electrical with the new information
 *    3. Delete the DRAFT electrical
 * @param draftElectricalItem The electrical to publish
 */

export const publishElectrical = async (
  draftElectricalItem: ElectricalShock
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the electrical is configured correctly as a draft version */
    if (draftElectricalItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The electrical is not a draft version',
      };
    }

    let activeElectrical: ElectricalShock;

    /* Use case 1: Creating the FIRST active version */
    if (draftElectricalItem.activeID == null) {
      /* Update the draft electrical to be active */
      activeElectrical = await DataStore.save(
        ElectricalShock.copyOf(draftElectricalItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active electrical item */
      let id: string = draftElectricalItem.activeID;
      let curElectrical = await DataStore.query(ElectricalShock, id);

      /* Base Case 3 -- check if the active electrical exists */
      if (curElectrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active electrical does not exist',
        };
      }

      let newArcheivedElectrical = new ElectricalItem(curElectrical);
      newArcheivedElectrical.status = ProgressStatus.ARCHIVE;
      newArcheivedElectrical.activeID = curElectrical.id;

      // 2. Create a new ARCHEIVED electrical based on the current ACTIVE electrical
      let archiveResult: Response = await createElectrical(
        newArcheivedElectrical
      );
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedElectrical = archiveResult.data as ElectricalItem;

      // 2. Update the ACTIVE electrical with the new information
      activeElectrical = await DataStore.save(
        ElectricalShock.copyOf(curElectrical, (updated) => {
          updated.title = draftElectricalItem.title;
          updated.options = draftElectricalItem.options;
          updated.rangeLow = draftElectricalItem.rangeLow;
          updated.rangeHigh = draftElectricalItem.rangeHigh;
          updated.instruction = draftElectricalItem.instruction;
          updated.warning = draftElectricalItem.warning;
          updated.note = draftElectricalItem.note;

          updated.createdBy = draftElectricalItem.createdBy;
          updated.modifiedBy = draftElectricalItem.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftElectricalItem.version;
        })
      );

      // 3. Delete the DRAFT electrical
      let draftElectrical = await DataStore.delete(
        ElectricalShock,
        draftElectricalItem.id
      );
      if (draftElectrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft electrical does not exist',
        };
      }
    }

    let elecItem = new ElectricalItem(activeElectrical);
    return {
      type: ResponseType.Success,
      data: elecItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the electricals that are associated with the protocol
 * @param protocol The protocol to delete the medication from
 * @param modifiedBy The user that modified the medication
 * @returns Success: data is the number of electricals deleted or Failure if there was an error
 */
export const deleteElectricalsForProtocol = async (
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      protocol.status === ProgressStatus.ACTIVE ||
      (protocol.status === ProgressStatus.DRAFT && protocol.activeID == null)
        ? protocol.uid
        : protocol.activeID;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          protocol.uid +
          ' ' +
          protocol.activeID +
          ' ' +
          protocol.status,
      };
    }

    /* Get all the Medications that the department has */
    let electricalList = await DataStore.query(ElectricalShock, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
        ]),
      ])
    );

    let electricals: ElectricalItem[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      electricals.push(new ElectricalItem(electricalList[i]));
    }

    let n = 0;
    for (let i = 0; i < electricals.length; i++) {
      let elec = electricals[i];

      let options = elec.options;

      let elecOption: ElectricalShockOption | undefined = undefined;
      for (let i = 0; i < options.length; i++) {
        let eo = options[i];
        if (eo.protocolID === protID) {
          elecOption = eo;
          break;
        }
      }
      /* If there is no medication protocol then there is a problem */
      if (!elecOption) continue;

      /* Remove the medication protocol from the medication */
      let newOptions = options.filter(
        (p) => p.protocolID !== (elecOption as ElectricalShockOption).protocolID
      );

      let newElec = cloneElectrical(elec);
      newElec.options = newOptions;
      newElec.status = ProgressStatus.DRAFT;
      newElec.activeID =
        elec.status === ProgressStatus.ACTIVE ? elec.uid : elec.activeID;
      newElec.version = upgradeVersion(
        elec.version == null ? 'v1.0.0' : elec.version
      );
      newElec.modifiedBy = modifiedBy;

      //Increment the count modified
      n++;

      createElectrical(newElec, elec)
        .then((result) => {
          if (result.type === ResponseType.Failure) {
            console.error('ERROR DELETING ELECTRICAL:', result.data);
          }
          console.log(
            'DELETED PROTOCOL REFERENCE -> ELECTRICAL',
            elec.name,
            protID
          );
        })
        .catch((e) => {
          console.error('ERROR DELETING ELECTRICAL:', e);
        });
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteElectrical = async (
  electricalItem: ElectricalItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = electricalItem.uid;
    if (isSoft) {
      let electrical = await DataStore.query(ElectricalShock, id);
      if (electrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical does not exist',
        };
      }

      let result = await DataStore.save(
        ElectricalShock.copyOf(electrical, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical did not update correctly',
        };
      }
    } else {
      let electrical = await DataStore.delete(ElectricalShock, id);
      if (electrical == null) {
        return {
          type: ResponseType.Failure,
          data: 'The electrical does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: electricalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isElectricalDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(ElectricalShock, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getElectricalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(ElectricalShock, (e) =>
      e.and((e) => [
        // e.or((e) => [
        e.status.eq('DRAFT'),
        // e.status.eq("DELETED")
        // ]),
        e.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      if (model.status === 'DELETED')
        message = `Deleted Electrical: ${model.title}`;
      else if (model.activeID == null)
        message = `Created Electrical: ${model.title}`;
      else message = `Updated Electrical: ${model.title}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentElectricalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftElectricals = await DataStore.query(ElectricalShock, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftElectricals.length; i++) {
      let elec: ElectricalShock = draftElectricals[i];
      await DataStore.delete(elec);
      updates.push({
        model: elec,
        message: `Removed Folder: ${elec.title}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getElectricalByID = async (
  db: DatabaseResponse,
  id: string
): Promise<ElectricalItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbElec = db.electrical.find((c) => c.uid === id);
      if (dbElec != null) return resolve(dbElec);
      else {
        executeQuery(graphqlOperation(getElectricalShock, { id: id }), 1500)
          .then((data) => {
            if (data.data.getDrip == null) {
              resolve(null);
            } else {
              let elec = data.data.getElectricalShock as ElectricalShock;
              let electrcal = new ElectricalItem(elec);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(electrcal);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
