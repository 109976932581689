import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { User, WeightObject } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchUser,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { handleGetDepartment } from '../../../../store/actions';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  globals,
  upgradeVersion,
} from '../../../_global/common/Utils';
import { Button, Col, Row } from 'react-bootstrap';
import SearchableDropdown from '../../../components/SearchableDropdown';
import KeychainItem from '../../../../data/model/KeychainItem';
import { IoBook, IoDocumentOutline, IoLayers } from 'react-icons/io5';
import SearchableList from '../../../components/Search/SearchableList';
import CategoryItem from '../../../../data/model/CategoryItem';
import { BiFolderOpen } from 'react-icons/bi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { FaFolder, FaFolderOpen, FaLock } from 'react-icons/fa6';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { FaTimes } from 'react-icons/fa';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import {
  KeychainDB,
  createKeychain,
  deleteKeychain,
} from '../../../../data/functions/KeychainDB';
import { getHashedPin, getSalt } from '../../../_global/common/Encrypt';
import { set } from 'lodash';
import { updateCategoryAccess } from '../../../../data/functions/CategoryDB';
import { updateProtocolAccess } from '../../../../data/functions/ProtocolDB';
import { updateDepartmentAccess } from '../../../../data/functions/DepartmentDB';

const KeychainCreate = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [list, setList] = useState<(CategoryItem | ProtocolItem)[]>([]);
  const [isProtectingDepartment, setIsProtectingDepartment] = useState(false);

  const department: DepartmentItem = database.department;
  const [isDelete, setIsDelete] = useState(false);
  const [modifiedBy, setModifiedBy] = useState<User>();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [message, setMessage] = useState({
    title: '',
    description: '',
    errorCode: '',
  });

  const formik = useFormik({
    initialValues: {
      uid: '',
      name: '',
      password: '',
      createdBy: '',
      modifiedBy: '',
      version: 'v1.0.0',
      departmentID: department.id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      password: Yup.string().required('Password is required').min(4),
    }),
    onSubmit: async (values) => {
      /* Validate the values */
      if (formik.errors.name) return;
      let salt = getSalt();
      let json: KeychainDB = {
        name: values.name,
        hashedPin: getHashedPin(values.password, salt, 'SHA256'),
        saltPin: salt,
        hashType: 'SHA256',
        version: 'v1.0.0',
        departmentID: values.departmentID,
        createdBy: user.id,
      };

      let result = await createKeychain(json);
      if (result.type === ResponseType.Success) {
        let keychain = result.data as KeychainItem;
        if (globals.debug) console.log('Created keychain:', keychain);
        if (isProtectingDepartment) {
          let resp: Response = await updateDepartmentAccess(
            department,
            true,
            keychain
          );
          if (resp.type === ResponseType.Success) {
            let dep = resp.data as DepartmentItem;
            console.log('Updated department keychain:', dep);
          } else {
            if (resp.data === 'Department already has a keychain') {
              setMessage({
                title: 'Error creating Keychain',
                description: 'Department already has a keychain',
                errorCode: '',
              });
            }
            console.error('Error updating department keychain:', resp.data);
          }
        }

        if (list.length > 0) {
          let promises: any[] = [];
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if ((item as ProtocolItem).parent) {
              let protocol = item as ProtocolItem;
              promises.push(updateProtocolAccess(protocol, true, keychain));
            } else {
              let category = item as CategoryItem;
              promises.push(updateCategoryAccess(category, true, keychain));
            }
          }

          let results = await Promise.all(promises);
          for (let i = 0; i < results.length; i++) {
            let resp = results[i];
            if (resp.type === ResponseType.Success) {
              let item = resp.data;
              console.log('Updated item:', item);
            } else {
              console.error('Error updating item:', resp.data);
            }
          }
        }
        reloadDatabase()
          .then((db) => {
            navigate(`/database/list-keychains`, {
              state: { department: department, database: db },
            });
          })
          .catch((err) => {
            console.error('Error reloading database:', err);
          });
      }
      // result = await createWeightObject(values, user, pairedDeps);
      // else result = await editWeightObject(values, user, pairedDeps);
      // if (result.type === ResponseType.Success) {
      //   let obj = result.data as WeightObject;
      //   console.log(
      //     'WeightObject',
      //     parmKeychain ? ' editted' : ' created',
      //     obj
      //   );

      //   await reloadDatabase();
      else {
        setMessage({
          title: 'Error Creating Keychain',

          description: 'Error: ', //+ result.data,
          errorCode: '',
        });
      }
    },
  });

  const searchableProtocols = useMemo(() => {
    return database.protocols
      .filter((p) => {
        return (
          list.findIndex((l) => l.uid === p.uid) === -1 && p.keychainID == null
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [list, database.protocols]);

  const searchableCategories = useMemo(() => {
    return database.categories
      .filter((c) => {
        return (
          list.findIndex((l) => l.uid === c.uid) === -1 && c.keychainID == null
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [list, database.categories]);

  const reloadDatabase = async (): Promise<DatabaseResponse> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return database;
    }
  };

  const handleBack = () => {
    navigate(`/database/list-keychains`, {
      state: { department: department, data: database.keychains, database },
    });
  };

  const handleOptionClicked = (item: CategoryItem | ProtocolItem) => {
    let l = [...list];
    l.push(item);
    l.sort((a, b) => {
      if (
        ((a as ProtocolItem).parent && (b as ProtocolItem).parent) ||
        (!(a as ProtocolItem).parent && !(b as ProtocolItem).parent)
      )
        return a.name.localeCompare(b.name);
      else return (a as ProtocolItem).parent ? 1 : -1;
    });
    setList(l);
  };

  const isSaveValid = useMemo(() => {
    return formik.dirty && formik.isValid;
  }, [formik.dirty, formik.isValid]);

  const downloadKeychain = () => {
    let file = new Blob(
      [
        'Keychain: ' +
          formik.values.name +
          '\nPassword: ' +
          formik.values.password,
      ],
      {
        type: 'text/plain',
      }
    );
    // Create a link element
    const link = document.createElement('a');

    // Set the link's href to point to the Blob URL
    link.href = window.URL.createObjectURL(file);
    link.download = 'PasswordDetails.txt';

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Clean up by removing the link
    setTimeout(() => {
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    }, 0);
  };

  return (
    <div className="screen-container">
      <ConfirmModal
        isVisible={isSuccess}
        title={'Successfully created Keychain'}
        handleClose={() => {
          setMessage({ title: '', description: '', errorCode: '' });
          formik.handleSubmit();
          setIsSuccess(false);
        }}
        handleSubmit={() => {
          downloadKeychain();
          setIsSuccess(false);
          formik.handleSubmit();
        }}
        primaryBtnName="Continue"
        secondaryBtnName="Download"
        primaryDescription={
          'The keychain cannot be editted in the future make sure to save or remember the password.'
        }
        secondaryDescription={
          'You can download the keychain and save it in a secure location.'
        }
      />
      <ConfirmModal
        isVisible={message.title !== ''}
        title={message.title}
        handleClose={() => {
          setMessage({ title: '', description: '', errorCode: '' });
        }}
        handleSubmit={() => {
          setMessage({ title: '', description: '', errorCode: '' });
        }}
        isSingleBtn={true}
        secondaryBtnName="Okay"
        primaryDescription={message.description}
        secondaryDescription={message.errorCode}
      />

      <ProtocolHeader
        name={'Create Keychain'}
        type={'protocol'}
        page={'Keychains'}
        isCreateButton={true}
        isSaveButton={false}
        isDeleteButton={false}
        handleDelete={() => {
          setIsDelete(true);
        }}
        description={
          'Keychains are used to lock the department, folders, and protocols from public access.'
        }
        isSaveActive={isSaveValid}
        isBackButton={true}
        isDotButton={false}
        isCreateActive={isSaveValid}
        handleCancel={handleBack}
        handleCreate={() => setIsSuccess(true)}
        handleSave={() => formik.handleSubmit()}
        handleCancelEdit={handleBack}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Keychain Information</h5>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <span
            className="contentText greyText"
            style={{ fontSize: '13px', marginLeft: '10px' }}
          >
            Give the keychain a unique name that will help you identify it
            later.
          </span>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              name="name"
              data-testid="name"
              autoFocus
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
            <div className="input-border"></div>
            {formik.touched.name && formik.errors.name && (
              <div className="errorText">{formik.errors.name}</div>
            )}
          </div>
          <label
            htmlFor="Name"
            className="ketamine-general-label"
            style={{
              marginTop: '20px',
            }}
          >
            Password <span className="required-field">*</span>
          </label>
          <span
            className="contentText greyText"
            style={{ fontSize: '13px', marginLeft: '10px' }}
          >
            Password must be atleast 4 characters long.
          </span>
          <div className="input-container">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <InputText
                type={isPasswordVisible ? 'text' : 'password'}
                className="form-control-general"
                id="password"
                name="password"
                data-testid="password"
                value={formik.values.password}
                onChange={(e: any) => {
                  formik.setFieldValue('password', e.target.value);
                }}
              />
              {isPasswordVisible ? (
                <BsEye
                  className="form-icon-eye"
                  size={'2rem'}
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                />
              ) : (
                <BsEyeSlash
                  className="form-icon-eye"
                  size={'2rem'}
                  onClick={() => {
                    setIsPasswordVisible(!isPasswordVisible);
                  }}
                />
              )}
            </div>

            <div className="input-border"></div>
            {formik.touched.name && formik.errors.name && (
              <div className="errorText">{formik.errors.name}</div>
            )}
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Protect Your Protocols</h5>
          <span
            className="contentText greyText"
            style={{ fontSize: 'medium', marginLeft: '0px' }}
          >
            You can use this keychain to lock your department or folders and
            protocols. You can add items below to lock them.
          </span>
          {!department.keychainID && list.length === 0 && (
            <>
              {isProtectingDepartment ? (
                <div>
                  <div
                    className="departmentItemNoHover no-select"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      padding: '10px 10px',
                      cursor: 'pointer',
                    }}
                  >
                    <FaLock size="1.5rem" style={{ marginRight: '10px' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyItems: 'center',
                        alignItems: 'flex-start',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <h6
                        className="departmentItemText"
                        style={{
                          fontSize: '1.0rem',
                          fontWeight: '700',
                          flex: 1,
                        }}
                      >
                        Protecting {department.name} Protocol Set
                      </h6>
                      <p
                        style={{
                          display: 'flex',
                          fontSize: '0.8rem',
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        The protocol set is protected by this keychain from
                        public access.
                      </p>
                    </div>
                    <Button
                      className="btn-cancel"
                      // variant="link"
                      onClick={() => {
                        setIsProtectingDepartment(false);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsProtectingDepartment(true);
                  }}
                  className={`${list.length === 0 ? 'hoverable' : ''}`}
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    padding: '20px 0px',
                    marginTop: '20px',
                    border: '1px solid #E0E0E0',
                    borderRadius: '6px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                    }}
                  >
                    <FaLock size={36} style={{ color: 'gray' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                    }}
                  >
                    <h6
                      style={{
                        fontSize: '1.25rem',
                        fontWeight: '500',
                        marginBottom: '0px',
                      }}
                    >
                      Protect Department Protocol Set
                    </h6>
                  </div>
                </div>
              )}
            </>
          )}
          {!department.keychainID &&
            list.length === 0 &&
            !isProtectingDepartment && (
              <>
                <hr
                  style={{
                    margin: '1.5rem 25%',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  <h6
                    style={{
                      fontSize: '1.0rem',
                      fontWeight: '700',
                      marginBottom: '0px',
                    }}
                  >
                    Or
                  </h6>
                </div>
                <hr
                  style={{
                    margin: '1.5rem 25%',
                  }}
                />
              </>
            )}
          {!isProtectingDepartment && (
            <>
              <Row
                style={{
                  flex: 1,
                }}
              >
                <h6
                  style={{
                    fontSize: '1.0rem',
                    fontWeight: '700',
                    marginBottom: '16px',
                  }}
                >
                  Protect specific folders and protocols: {list.length} items
                </h6>
                <Col
                  sm={6}
                  style={{
                    borderRight: '1px solid #E0E0E0',
                  }}
                >
                  <SearchableDropdown<CategoryItem>
                    id="searchDropdown"
                    options={searchableCategories}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    onChange={(option: CategoryItem) => {
                      handleOptionClicked(option);
                    }}
                    multiSelect={true}
                    placeholder="Search folders..."
                    containerStyle={{ width: '100%' }}
                    // notFoundText="No folder found..."
                    // itemClassName="search-item"
                  />
                </Col>
                <Col sm={6}>
                  <SearchableDropdown<ProtocolItem>
                    id="searchDropdown"
                    options={searchableProtocols}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    onChange={(option: ProtocolItem) => {
                      handleOptionClicked(option);
                    }}
                    multiSelect={true}
                    placeholder="Search protocols..."
                    containerStyle={{ width: '100%' }}
                    // notFoundText="No protocols found..."
                    // itemClassName="search-item"
                  />
                </Col>
              </Row>
              {list.length > 0 && (
                <div
                  className="list-container"
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <ViewportList items={list}>
                    {(item: CategoryItem | ProtocolItem, index: any) => {
                      return (
                        <div
                          key={index}
                          className="hoverable"
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '10px 10px',
                            borderTopLeftRadius: index === 0 ? '6px' : '0px',
                            borderTopRightRadius: index === 0 ? '6px' : '0px',
                            borderBottomLeftRadius:
                              index === list.length - 1 ? '6px' : '0px',
                            borderBottomRightRadius:
                              index === list.length - 1 ? '6px' : '0px',
                            borderBottom:
                              index !== list.length - 1
                                ? '1px solid #E0E0E0'
                                : 'none',
                          }}
                        >
                          {(item as ProtocolItem).parent ? (
                            <IoDocumentOutline
                              size="1.5rem"
                              style={{ marginRight: '10px' }}
                            />
                          ) : (
                            <FaFolderOpen
                              size="1.5rem"
                              style={{ marginRight: '10px' }}
                            />
                          )}
                          <h6
                            className="departmentItemText"
                            style={{ fontSize: '1.0rem', flex: 1 }}
                          >
                            {item.name}
                            {item.status !== 'ACTIVE' && (
                              <Status status={item.status} />
                            )}
                          </h6>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              flex: 1,
                            }}
                          >
                            <FaTimes
                              size="1rem"
                              className="icon-cancel"
                              style={{ marginRight: '10px' }}
                              onClick={() => {
                                let l = [...list];
                                l.splice(index, 1);
                                setList(l);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </ViewportList>
                </div>
              )}
            </>
          )}
          {/* {list.length === 0 && (
            <div className="container">
              <div className="text" style={{ color: '#616161' }}>
                No Keychains Created...
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default KeychainCreate;
