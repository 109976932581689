import React, { useEffect, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  formatForMatchingUnits,
  formatForMatchingUnitsFormik,
} from '../../../data/model/EquipmentItem';

type EquipmentValueProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  dataLow?: string;
  dataHigh?: string;
  doseIndex?: number;
  handleSet: (dataLow: string, dataHigh: string) => void;
  type?: any;
};

/*10-25-2023,Arul: Created Equipment value modal for Equipment Edit Protocol */
const EquipmentValueModal: React.FC<EquipmentValueProps> = ({
  handleSet,
  type,
  dataLow,
  dataHigh,
  dialogVisible,
  setDialogVisible,
  headerText,
}) => {
  const formik = useFormik({
    initialValues: {
      amount1: '',
      amount2: '',
      unit1: '',
      unit2: '',
    },
    validationSchema: Yup.object({
      amount1: Yup.string().required(),
      amount2: Yup.string(),
      unit1: Yup.string(),
      unit2: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    // Function to parse the amount and unit from the data
    const parseAmountUnit = (data: string | undefined) => {
      if (!data || data === '+Add') {
        return { amount: '', unit: '' };
      }
      const spaceIndex = data.indexOf(' ');
      if (spaceIndex === -1) {
        return { amount: data, unit: '' };
      } else {
        // Space found, split the string into amount and unit
        const amount = data.slice(0, spaceIndex);
        const unit = data.slice(spaceIndex + 1);
        return { amount, unit };
      }
    };

    // Parsing dataLow and dataHigh
    const { amount: amount1, unit: unit1 } = parseAmountUnit(dataLow);
    const { amount: amount2, unit: unit2 } = parseAmountUnit(dataHigh);

    formik.setValues({
      ...formik.values,
      amount1: amount1,
      amount2: amount2,
      unit1: unit1,
      unit2: unit2,
    });
  }, [dataLow, dataHigh]);

  /*10-31-2023,Arul: Output value validation */
  const outputValue = useMemo(() => {
    let output = '';
    if (formik.values.amount1)
      output += formatForMatchingUnitsFormik(
        formik.values.amount1,
        formik.values.amount2,
        formik.values.unit1,
        formik.values.unit2
      );
    return output;
  }, [formik.values]);

  /*10-31-2023,Arul: handling function for submit*/
  const handleSubmit = () => {
    setDialogVisible(false);
    handleSet(
      formik.values.amount1 +
        (formik.values.unit1 ? ' ' + formik.values.unit1 : ''),
      formik.values.amount2 +
        (formik.values.unit2 ? ' ' + formik.values.unit2 : '')
    );
    formik.resetForm();
  };

  return (
    <Dialog
      header={type === 'Vital' ? 'Vital Value' : 'Equipment Value'}
      visible={dialogVisible}
      style={{ width: '50vw' }}
      onHide={() => setDialogVisible(false)}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <h6 className="headerText">{`Enter the value to display for this ${
                type === 'Vital' ? 'age' : 'weight'
              }`}</h6>
              <span className="contentText">
                <AiOutlineQuestionCircle className="icon-normal" />
              </span>
            </div>
            <span className="contentText">{`Enter the value to display for this ${
              type === 'Vital' ? 'age' : 'weight'
            }`}</span>
          </div>
          <div className="calculationDialogContent">
            <div>
              <div className="p-inputgroup flex-1">
                <div className="p-inputgroup-addon-left dialog_Item_label">
                  <span className="colAlignCenter">
                    Amount 1:<span className="required-field">*</span>
                  </span>
                </div>
                <div className="input-container">
                  <InputText
                    id="amount1"
                    name="amount1"
                    placeholder="Required..."
                    autoFocus={true}
                    value={formik.values.amount1}
                    onChange={formik.handleChange}
                    className="dialogInput "
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="dialogInput-border"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon-left">
                  <span style={{ width: '70px' }}>Amount 2:</span>
                </span>
                <div className="input-container">
                  <InputText
                    id="amount2"
                    name="amount2"
                    placeholder="Optional..."
                    value={formik.values.amount2}
                    onChange={formik.handleChange}
                    className="dialogInput"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="dialogInput-border"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="calculationDialogContent">
            <div>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon-left">
                  <span style={{ width: '70px' }}>Unit 1:</span>
                </span>
                <div className="input-container">
                  <InputText
                    id="unit1"
                    name="unit1"
                    placeholder="Optional..."
                    value={formik.values.unit1}
                    onChange={formik.handleChange}
                    className="dialogInput "
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="dialogInput-border"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon-left">
                  <span style={{ width: '70px' }}>Unit 2:</span>
                </span>
                <div className="input-container">
                  <InputText
                    id="unit2"
                    name="unit2"
                    placeholder="Optional..."
                    value={formik.values.unit2}
                    onChange={formik.handleChange}
                    className="dialogInput"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="dialogInput-border"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <div>
              <label htmlFor="Output" className="ketamine-general-label">
                Output:
              </label>
              <InputText
                type="text"
                className="calculationForm"
                value={outputValue}
                id="Output"
              />
            </div>
            <div className="" style={{ display: 'flex', alignItems: 'end' }}>
              <Button
                className="secondary-button-dialog btn-rightMargin"
                onClick={() => setDialogVisible(false)}
              >
                Cancel
              </Button>
              <Button
                className="primary-button"
                disabled={!outputValue}
                onClick={handleSubmit}
              >
                Set
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EquipmentValueModal;
