import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import {
  handleGetDepartment,
  handleSetProtocolData,
  handleSetSelectedDoseValue,
} from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import ProtocolDoseSideout from '../../../../components/SideOut/doseSideOut/ProtocolDoseSideout';
import DoseSelection from '../../../../components/dose/DoseSelection';
import ProtocolEditHeader from '../../ProtocolHeader';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import MedicationItem from '../../../../../data/model/MedicationItem';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import {
  ElectricalOption,
  ElectricalShockOption,
  ElectricalShockRange,
  User,
} from '../../../../../models';
import { ProgressStatus } from '../../../../../models';
import {
  ElectricalJSON,
  createElectrical,
  deleteElectrical,
} from '../../../../../data/functions/ElectricalDB';
import {
  getFormattedDate,
  getObjectDifference,
  globals,
  handleCopy,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import { Col, Row } from 'react-bootstrap';
import ElectricalDose from './ElectricalDose';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import ElectricalShockSideout from '../../../../components/SideOut/doseSideOut/ElectricalShockSideout';
const SHOW_PROTOCOL_ID = false;

interface ElectricalEditPageProps {
  stateData?: any;
}

/* 11-08-23 Praveen Created Electrical Edit Page component for Equipment layout */
const ElectricalProtocolEditPage: React.FC<ElectricalEditPageProps> = ({
  stateData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedProtocol, value, subValue, type, editType, editMode, page } =
    stateData;

  const [protocol, setProtocol] = useState<ProtocolItem>(selectedProtocol);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state.user);
  const department = database.department;

  const [parmElectrical, setParmElectrical] = useState<ElectricalItem>(value);
  const [subElectricalItems, setSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(
    protocol
      ? (value as ElectricalItem).subElectricals.filter(
          (item: ElectricalSubItem) => {
            return item.parentProtocol.uid === protocol.uid;
          }
        )
      : value.subElectricals
  );

  const [allSubElectricalItems, setAllSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(parmElectrical.subElectricals);
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    [...new Set(allSubElectricalItems.map((m) => m.parentProtocol))].sort(
      (a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      }
    )
  );
  const [expanded, setExpanded] = useState<string[]>([]);

  const [doseIndex, setDoseIndex] = useState(subElectricalItems.length);
  const [selectedData, setSelectedData] = useState<ElectricalSubItem | null>(
    null
  );
  const [deleteData, setDeleteData] = useState<any>(null);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [editDose, setEditDose] = useState(false);

  const [modalType, setModalType] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(editMode);

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);
  const [isCopied, setIsCopied] = useState<string | null>(null);

  useEffect(() => {
    let id = parmElectrical.dbElectrical?.modifiedBy;
    if (id == null) id = parmElectrical.dbElectrical?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmElectrical.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmElectrical]);

  /* 11-7-23 intialize the form initial values */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmElectrical.name,
      rangeLow: parmElectrical.rangeLow + '',
      rangeHigh: parmElectrical.rangeHigh + '',
      warning: parmElectrical.dbElectrical.warning ?? '',
      instruction: parmElectrical.dbElectrical.instruction ?? '',
      note: parmElectrical.dbElectrical.note ?? '',
      options: parmElectrical.dbElectrical.options ?? [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Must have a name for the Electrical Shock'),
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
      warning: Yup.string(),
      options: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (globals.debug) console.log('SUBMITTING ELECTRICAL', values);
      if (formik.dirty && formik.isValid) {
        const protocolList = findProtocols(values.options);
        const newElectrical: ElectricalJSON = {
          title: formik.values.name,
          rangeLow: !isNaN(Number(formik.values.rangeLow))
            ? Number(formik.values.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(formik.values.rangeHigh))
            ? Number(formik.values.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          options: values.options,
          taggedProtocols: protocolList,

          instruction: formik.values.instruction,
          note: formik.values.note,
          warning: formik.values.warning,
          createdBy: parmElectrical.dbElectrical.createdBy
            ? parmElectrical.dbElectrical.createdBy
            : '',
          modifiedBy: user.id,

          status: ProgressStatus.DRAFT,
          activeID:
            parmElectrical.status === ProgressStatus.ACTIVE
              ? parmElectrical.uid
              : parmElectrical.activeID,
          version:
            parmElectrical.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmElectrical.version == null
                    ? 'v1.0.0'
                    : parmElectrical.version
                )
              : parmElectrical.version == null
                ? 'v1.0.0'
                : parmElectrical.version,
        };

        let results: Response = await createElectrical(
          newElectrical,
          parmElectrical
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created Electrical', results.data);
          let newElectrical: ElectricalItem = results.data;
          updateElectrical(newElectrical);
        }
        formik.resetForm();
      }
    },
  });

  /** e68bc8f5-c88b-4b5f-9bcc-153ec603c9a5
   * Find the protocols from the electrical options
   * @param options The options to search for
   * @returns The protocols that were found
   */
  const findProtocols = (options: ElectricalShockOption[]): ProtocolItem[] => {
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < options.length; i++) {
      let po = options[i];
      let protocol = database.protocols.find((p: ProtocolItem) => {
        let id =
          p.status === ProgressStatus.ACTIVE ||
          (p.status === ProgressStatus.DRAFT && p.activeID == null)
            ? p.uid
            : p.activeID;
        return id === po.protocolID;
      });
      if (protocol) protocols.push(protocol);
    }
    return protocols;
  };

  /**
   * Update the formik and reload the database
   * @param newElectrical The new electrical item to update the form with
   */
  const updateElectrical = (newElectrical: ElectricalItem) => {
    reloadDatabase();
    formik.setValues({
      ...formik.values,
      name: newElectrical.name,
      rangeLow: newElectrical.rangeLow + '',
      rangeHigh: newElectrical.rangeHigh + '',
      instruction: newElectrical.dbElectrical.instruction ?? '',
      note: newElectrical.dbElectrical.note ?? '',
      warning: newElectrical.dbElectrical.warning ?? '',
      options: newElectrical.dbElectrical.options ?? [],
    });
  };

  /**
   * Reload the database from the server
   * @returns true when the database is reloaded
   */
  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));

      /* Search for the updated medication item in the database - ALWAYS search for activeID
       *  Use cases:
       *  1.  Medication was DRAFT FIRST RELEASE and updated item - match uid
       *  2.  Medication was ACTIVE and updated item - match old uid to new activeID
       */
      let updatedElectrical: ElectricalItem | null = null;
      for (let i = 0; i < newDB.medications.length; i++) {
        let elecID =
          parmElectrical.status === ProgressStatus.ACTIVE
            ? newDB.electrical[i].activeID
            : newDB.electrical[i].uid;
        if (elecID === parmElectrical.uid) {
          updatedElectrical = newDB.electrical[i];
          break;
        }
      }
      /* Search for the updated medication item in the database - ALWAYS search for activeID */
      // if(globals.debug) console.log('Searching for updated electrical item');
      // let updatedElectrical: ElectricalItem | null = null;
      // let electricalID =
      //   parmElectrical.status === ProgressStatus.ACTIVE
      //     ? parmElectrical.uid
      //     : parmElectrical.activeID;
      // for (let i = 0; i < newDB.electrical.length; i++) {
      //   if (newDB.electrical[i].activeID === electricalID) {
      //     updatedElectrical = newDB.electrical[i];
      //     break;
      //   }
      // }

      if (globals.debug) console.log('Searching for updated protocol item');
      let updatedProtocol: ProtocolItem | null = null;
      if (protocol) {
        for (let i = 0; i < newDB.protocols.length; i++) {
          /* Was ACTIVE and is ACTIVE now */
          if (
            selectedProtocol.status === ProgressStatus.ACTIVE &&
            newDB.protocols[i].uid === selectedProtocol.uid
          ) {
            updatedProtocol = newDB.protocols[i];
            break;
          } else if (
            /* Was ACTIVE and is DRAFT now */
            selectedProtocol.status === ProgressStatus.ACTIVE &&
            newDB.protocols[i].activeID === selectedProtocol.uid
          ) {
            updatedProtocol = newDB.protocols[i];
            break;
          } else if (
            /* Was DRAFT and is DRAFT now */
            selectedProtocol.status === ProgressStatus.DRAFT &&
            ((newDB.protocols[i].activeID == null &&
              newDB.protocols[i].uid === selectedProtocol.uid) ||
              (newDB.protocols[i].activeID != null &&
                newDB.protocols[i].activeID === selectedProtocol.activeID))
          ) {
            updatedProtocol = newDB.protocols[i];
            break;
          }
        }

        if (updatedProtocol) {
          setProtocol(updatedProtocol);
          if (globals.debug)
            console.log('Found updated protocol', updatedProtocol.name);
        } else if (globals.debug)
          console.log('Failed to find updated protocol', selectedProtocol);
      }

      /* Search for the right sub medications */
      if (updatedElectrical) {
        if (globals.debug)
          console.log('Found updated electrical', updatedElectrical);
        setParmElectrical(updatedElectrical);
        setSubElectricalItems(
          updatedProtocol
            ? updatedElectrical.subElectricals.filter(
                (item: ElectricalSubItem) =>
                  item.parentProtocol.uid === updatedProtocol?.uid
              )
            : updatedElectrical.subElectricals
        );
        setAllSubElectricalItems(updatedElectrical.subElectricals);
        setAllProtocols(
          [
            ...new Set(
              updatedElectrical.subElectricals.map((m) => m.parentProtocol)
            ),
          ].sort((a, b) => {
            if (a.parent.index === b.parent.index)
              return a.name.localeCompare(b.name);
            return a.parent.index - b.parent.index;
          })
        );
      } else if (globals.debug)
        console.log('Failed to find updated electrical');
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /**
   * Todo have this open up a sideout to view the history of the medication
   */
  const handleVersion = () => {
    /* Output why the formik is dirty in the console */
  };

  /**
   * Back button has been pressed
   */
  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty) {
      setIsWarningModal(true);
    } else if (protocol) {
      const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
      navigate(`/${formattedString}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-electricals`, {
        state: {
          department: department,
          data: database.medications,
          database: database,
        },
      });
    }
  };

  /**
   * Cancen button has been pressed reset the formik values
   */
  const handleCancel = () => {
    /* Check if the formik is dirty and if the warning modal is not open */
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    /* If the formik is not dirty or abandon has been pressed then reset it */ else {
      formik.resetForm();
      setIsEditMode(false);
      setIsWarningModal(false);

      setSubElectricalItems(
        protocol
          ? (value as ElectricalItem).subElectricals.filter(
              (item: ElectricalSubItem) => {
                return item.parentProtocol.uid === protocol.uid;
              }
            )
          : value.subElectricals
      );
      setAllSubElectricalItems(parmElectrical.subElectricals);
      setAllProtocols(
        [...new Set(allSubElectricalItems.map((m) => m.parentProtocol))].sort(
          (a, b) => {
            if (a.parent.index === b.parent.index)
              return a.name.localeCompare(b.name);
            return a.parent.index - b.parent.index;
          }
        )
      );
    }
  };

  /* 11-08-23  Function to handle on Add Dose click*/
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedData(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  /* 11-08-23  function to close the modal */
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
  };

  /* 1-23-24 Hazlett:  Function to remove the previous draft and make a new one */
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleOnSubmitElectricalShock = async (
    data: any,
    previousDose?: ElectricalSubItem,
    parentProtocol?: ProtocolItem
  ) => {
    if (globals.debug) console.log('Creating new shock', data);
    const protocol = parentProtocol ? parentProtocol : selectedProtocol;
    let protocolID =
      protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
        ? protocol.uid
        : protocol.activeID;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    /* Create the ElectricalShockRange for the dose */
    let elecRange = new ElectricalShockRange({
      index: protocol.electrical.length,
      basis: data.basis,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      calcMax: data.calcMax,
      fixedMax: data.fixedMax,
      title: data.title,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
    });

    if (globals.debug) console.log('Electrical range', elecRange);

    /* Then check if there is a electrical option already created */
    let elecOption: ElectricalShockOption | null = null;
    for (let i = 0; i < formik.values.options.length; i++) {
      let eo = formik.values.options[i];
      if (eo.protocolID === protocolID) {
        elecOption = eo;
        break;
      }
    }

    /* If there is no electrical option then create one */
    if (!elecOption) {
      if (globals.debug) console.log('Creating new electrical option');
      elecOption = new ElectricalShockOption({
        protocolID: protocolID,
        ranges: [elecRange],
      });
      /* Add the electrical option to the formik values */
      formik.setFieldValue('options', [...formik.values.options, elecOption]);
    } else {
      if (globals.debug)
        console.log('Updating electrical option', elecOption, previousDose);
      /* Check if we are updating a previous dose and filter out the previoud dose otherwise add the new range */
      let electricalRanges = previousDose
        ? elecOption.ranges.filter(
            (e) => getObjectDifference(e, previousDose.range).length !== 0
          )
        : elecOption.ranges;
      if (globals.debug)
        console.log('Electrical ranges', electricalRanges, '->', elecRange);
      // electricalRanges.push(elecRange);
      let ranges = [...electricalRanges, elecRange];
      ranges.sort((a: ElectricalShockRange, b: ElectricalShockRange) => {
        if (protocol) {
          return a.index - b.index;
        } else {
          if (a.rangeLow === b.rangeLow) return a.index - b.index;
          return a.rangeLow - b.rangeLow;
        }
      });

      elecOption = new ElectricalShockOption({
        protocolID: elecOption.protocolID,
        ranges: [...ranges],
      });
      if (globals.debug) console.log('Updated electrical option', elecOption);
      /* Add the electrical option to the formik values */
      let newOptions = formik.values.options.filter(
        (eo: ElectricalShockOption) => eo.protocolID !== protocolID
      );
      if (globals.debug) console.log('New options', newOptions);
      formik.setFieldValue('options', [...newOptions, elecOption]);
      if (globals.debug) console.log('Formik values', formik.values);
    }

    /* Need to create a MedicationSubItem for the new dose */
    let subElecs = previousDose
      ? subElectricalItems.filter(
          (item: ElectricalSubItem) => item !== previousDose
        )
      : subElectricalItems;
    let newElectricalSubItem = new ElectricalSubItem(
      parmElectrical,
      protocol,
      elecRange
    );
    setSubElectricalItems([...subElecs, newElectricalSubItem]);
    let allShocks = [
      ...new Set([...allSubElectricalItems, newElectricalSubItem]),
    ];
    setAllSubElectricalItems(allShocks);
    setAllProtocols(
      [...new Set(allShocks.map((m) => m.parentProtocol))].sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      })
    );
  };

  const handleCopyElectricalOption = (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    /* First find the Medication Protocol */
    let protocolID =
      fromProtocol.status === ProgressStatus.ACTIVE
        ? fromProtocol.uid
        : fromProtocol.activeID;
    let elecOption: ElectricalShockOption | null = null;
    for (let i = 0; i < formik.values.options.length; i++) {
      let po = formik.values.options[i];
      if (po.protocolID === protocolID) {
        elecOption = po;
        break;
      }
    }

    /* If there is no medication protocol then there is a problem */
    if (!elecOption) {
      console.error('Failed to find electrical protocol');
      return;
    }

    /* Copy the medication protocol to the new protocol */
    let newProtocolID =
      toProtocol.status === ProgressStatus.ACTIVE
        ? toProtocol.uid
        : toProtocol.activeID;
    if (!newProtocolID) {
      console.error('Failed to find new protocol ID');
      return;
    }
    let newElecOption = new ElectricalShockOption({
      protocolID: newProtocolID,
      ranges: [...elecOption.ranges],
    });

    /* Add the medication protocol to the formik values */
    let options = formik.values.options;
    formik.setFieldValue('options', [...options, newElecOption]);

    /* Create a new medicationSubItem for the new protocol */
    let newElecSubItems = [...subElectricalItems];
    for (let i = 0; i < newElecOption.ranges.length; i++) {
      let range = newElecOption.ranges[i];
      let newElectricalSubItem = new ElectricalSubItem(
        parmElectrical,
        toProtocol,
        range
      );
      newElecSubItems.push(newElectricalSubItem);
    }
    setSubElectricalItems(newElecSubItems);
    /* Filter duplicates from the all sub medication items */
    let allSubElecs = [...allSubElectricalItems, ...newElecSubItems];
    setAllSubElectricalItems([...new Set(allSubElecs)]);
    setAllProtocols(
      [
        ...new Set(
          ([...new Set(allSubElecs)] as ElectricalSubItem[]).map(
            (m) => m.parentProtocol
          )
        ),
      ].sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      })
    );
  };

  const handleAddElectricalProtocol = (eo: ElectricalShockOption) => {
    let protocolID = eo.protocolID;
    let elecOption: ElectricalShockOption | null = null;
    for (let i = 0; i < formik.values.options.length; i++) {
      let po = formik.values.options[i];
      if (po.protocolID === protocolID) {
        elecOption = po;
        break;
      }
    }

    /* If there is medication protocol throw an error */
    if (elecOption != null) {
      console.error('Protocol already exists in the Electrical');
      return;
    }

    /* Add the medication protocol to the formik values */
    let newProtocolOptions: ElectricalShockOption[] = [
      ...formik.values.options,
      eo,
    ];
    formik.setFieldValue('options', newProtocolOptions);
    console.log('Protocol Options', formik.values.options);

    let prot = database.protocols.find((p: ProtocolItem) =>
      p.status === ProgressStatus.ACTIVE || p.activeID == null
        ? p.uid === protocolID
        : p.activeID === protocolID
    );

    if (!prot) {
      console.error('Failed to find protocol');
      return;
    }

    console.log('Found Protocol', prot.name);

    /* Create a new medicationSubItem for the new protocol */
    let newElecSubItems = [...subElectricalItems];
    for (let i = 0; i < eo.ranges.length; i++) {
      let range = eo.ranges[i];
      let newElectricalSubItem = new ElectricalSubItem(
        parmElectrical,
        prot,
        range
      );
      newElecSubItems.push(newElectricalSubItem);
    }
    console.log(
      'Added Doses',
      (newElecSubItems as ElectricalSubItem[]).length -
        subElectricalItems.length
    );
    setSubElectricalItems(newElecSubItems);
    /* Filter duplicates from the all sub medication items */
    let allSubElecs = [...allSubElectricalItems, ...newElecSubItems];
    setAllSubElectricalItems([...new Set(allSubElecs)]);
    setAllProtocols(
      [
        ...new Set(
          ([...new Set(allSubElecs)] as ElectricalSubItem[]).map(
            (m) => m.parentProtocol
          )
        ),
      ].sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      })
    );
  };

  const handleRemoveElectricalShock = async (dose: ElectricalSubItem) => {
    if (globals.debug) console.log('Removing shock', dose);
    const protocol = dose.parentProtocol;

    let protocolID =
      protocol.status === ProgressStatus.ACTIVE
        ? protocol.uid
        : protocol.activeID;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    /* Then check if there is a electrical option already created */
    let elecOption: ElectricalShockOption | null = null;
    for (let i = 0; i < formik.values.options.length; i++) {
      let eo = formik.values.options[i];
      if (eo.protocolID === protocolID) {
        elecOption = eo;
        break;
      }
    }

    /* If there is no medication protocol then there is a problem */
    if (!elecOption) {
      console.error('Failed to find electrical option');
      return;
    }

    /* Check if we are updating a previous dose and filter out the previoud dose otherwise add the new range */
    let electricalRanges = elecOption.ranges.filter(
      (e) => getObjectDifference(e, dose.range).length != 0
    );

    elecOption = new ElectricalShockOption({
      protocolID: elecOption.protocolID,
      ranges: [...electricalRanges],
    });

    /* Add the electrical option to the formik values */
    let newOptions = formik.values.options.filter(
      (eo: ElectricalShockOption) => eo.protocolID !== protocolID
    );
    formik.setFieldValue('options', [...newOptions, elecOption]);

    /* Filter out the ElectricalSubItem from the list */
    let subElecs = dose
      ? subElectricalItems.filter((item: ElectricalSubItem) => item !== dose)
      : subElectricalItems;
    setSubElectricalItems([...subElecs]);
    let allShocks = [...new Set([...allSubElectricalItems, ...subElecs])];
    allShocks = allShocks.filter((item: ElectricalSubItem) => item !== dose);
    setAllSubElectricalItems(allShocks);
    setAllProtocols(
      [...new Set(allShocks.map((m) => m.parentProtocol))].sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      })
    );
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft =
      parmElectrical.status === ProgressStatus.DRAFT &&
      parmElectrical.activeID == null;
    let response = await deleteElectrical(parmElectrical, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete electrical', response.data);
    }
  };

  const Modals = () => {
    return (
      <>
        {isOpen && (
          <DraftSaveModal
            department={database.department}
            isVisible={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleContinue={() => {
              setIsEditMode(true);
              setIsOpen(false);
            }}
            handleNew={() => {
              setIsConfirmModal(true);
            }}
          />
        )}
        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleBack}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Electrical.  Click cancel to return to Electrical details.  To continue without saving changes, select Abandon.`}
          />
        )}
        {isConfirmModal && (
          <ConfirmModal
            isVisible={isConfirmModal}
            title="Make New Draft?"
            handleClose={() => {
              setIsConfirmModal(false);
            }}
            handleSubmit={handleMakeNew}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Make New"
            primaryDescription="Are you sure you would like to remove the 11 detected changes from the previous draft and override it and create a new one."
          />
        )}

        {isDelete && (
          <ConfirmModal
            isVisible={isDelete}
            title={'Delete ' + parmElectrical.name + '?'}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleSubmit={handleConfirmDeleteItem}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={`Are you sure you would like to delete ${parmElectrical.name}?`}
          />
        )}

        {sidebarVisible && (
          <ElectricalShockSideout
            visible={sidebarVisible}
            doseIndex={doseIndex}
            parentModel={parmElectrical}
            protocol={protocol}
            allProtocols={allProtocols}
            dose={selectedData ? selectedData : undefined}
            subElectrialItems={allSubElectricalItems}
            setVisible={handleCloseModal}
            editDose={editDose}
            onSubmit={handleOnSubmitElectricalShock}
            onRemove={handleRemoveElectricalShock}
            onSubmitElectrialOption={handleAddElectricalProtocol}
            onSubmitMultiDose={handleCopyElectricalOption}
          />
        )}
      </>
    );
  };

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(dose: ElectricalSubItem, index: number) => {
          const lastIndex = data.length === index + 1;
          return (
            <div
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (isEditMode) {
                  setSelectedData(dose);
                  setDoseIndex(index);
                  setEditDose(true);
                  setSidebarVisible(true);
                }
              }}
            >
              <ElectricalDose
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubElectricalItems.filter(
      (item: ElectricalSubItem) => item.parentProtocol.uid === protocol.uid
    );
    // if (globals.debug) console.log('Doses', doses);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            expanded.includes(protocol.uid) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          {expanded.includes(protocol.uid) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {protocol.name}
            </span>
            {SHOW_PROTOCOL_ID && (
              <span
                className="ketamine-general-label"
                style={{
                  fontWeight: '400',
                  fontSize: '11px',
                  marginTop: '-8px',
                }}
              >
                {protocol.status === ProgressStatus.ACTIVE ||
                protocol.activeID == null
                  ? protocol.uid
                  : protocol.activeID}

                <span>
                  {isCopied &&
                  isCopied ===
                    (protocol.status === ProgressStatus.ACTIVE ||
                    protocol.activeID == null
                      ? protocol.uid
                      : protocol.activeID) ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(
                          protocol.status === ProgressStatus.ACTIVE ||
                            protocol.activeID == null
                            ? protocol.uid
                            : protocol.activeID,
                          e,
                          setIsCopied
                        )
                      }
                    />
                  )}
                </span>
              </span>
            )}
          </div>
          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {doses.length} doses
          </span>
        </div>
        {expanded.includes(protocol.uid) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={doses} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="screen-container">
      <Modals />

      <ProtocolHeader
        name={
          parmElectrical.name
          // (parmMedication.status === "DRAFT" ? " (Draft)" : "")
        }
        status={parmElectrical.status}
        isBackButton={true}
        protocolDetail={protocol}
        page={protocol ? protocol.name : 'Electricals'}
        type={'protocol'}
        rightSideBtn={isEditMode ? 'save' : 'edit'}
        isSaveButton={isEditMode}
        isSaveActive={formik.dirty && formik.isValid}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmElectrical.uid : ''}
        // isVersionButton={true}
        isDeleteButton={isEditMode}
        isEditButton={!isEditMode}
        isDotButton={true}
        handleCancel={handleBack}
        handleCancelEdit={handleCancel}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft && !isEditMode) setIsEditMode(true);
          });
        }}
        handleDelete={handleDelete}
        handleSave={() => formik.submitForm()}
        handleVersion={handleVersion}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">General Information</h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {modifiedBy
                  ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmElectrical.dbElectrical?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(
                    parmElectrical.dbElectrical.updatedAt,
                    true
                  )}
                </div>
              </div>
            )}
            {/* {notification.notifyType && (
							<div style={{ display: 'flex' }} className='ketamine-general-label'>
							<div style={{ marginRight: '10px' }}>
								Style:
							</div>
							<div style={{fontWeight: '500'}}>
								{notification.notifyType}
							</div>
							</div>
						)} */}
            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmElectrical.version}</div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name<span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              required={true}
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              onBlur={formik.handleBlur}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          {formik.touched.name && formik.errors.name ? (
            <span className="errorText">{formik.errors.name}</span>
          ) : null}
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  disabled={!isEditMode}
                  value={
                    formik.values.rangeLow &&
                    Number(formik.values.rangeLow) !== 0
                      ? formik.values.rangeLow + ' kg'
                      : ''
                  }
                  // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  onChange={(e: any) => {
                    /* Check for "Min" */
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeLow', 'Invalid input');
                    else formik.setFieldValue('rangeLow', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeLow && formik.errors.rangeLow ? (
                <span className="errorText">{formik.errors.rangeLow}</span>
              ) : null}
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode}
                  value={
                    formik.values.rangeHigh &&
                    Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                      ? formik.values.rangeHigh + ' kg'
                      : ''
                  }
                  placeholder="Max"
                  onChange={(e: any) => {
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeHigh', 'Invalid input');
                    else formik.setFieldValue('rangeHigh', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                <span className="errorText">{formik.errors.rangeHigh}</span>
              ) : null}
            </Col>
          </Row>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              name="warning"
              required={true}
              data-testid="warning"
              value={formik.values.warning}
              onChange={(e: any) => {
                formik.setFieldValue('warning', e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Instruction" className="ketamine-general-label">
            Instruction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="instruction"
              name="instruction"
              required={true}
              data-testid="instruction"
              value={formik.values.instruction}
              onChange={(e: any) => {
                formik.setFieldValue('instruction', e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="note"
              name="note"
              required={true}
              data-testid="note"
              value={formik.values.note}
              onChange={(e: any) => {
                formik.setFieldValue('note', e.target.value);
              }}
              onBlur={formik.handleBlur}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Electrical Shocks</h5>
          {isEditMode && (
            <div style={{ marginTop: '5px' }}>
              <span className="ketamine-general-label">Shocks</span>
              <div
                onClick={onAddDoseClick}
                className={`contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder  ${
                  isEditMode && 'cursorPointer newRouteButton'
                }`}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  style={{
                    textDecoration: 'underLine',
                    textUnderlinePosition: 'under',
                  }}
                >
                  <HiPlus className="text-icon " /> Add Shock
                </span>
              </div>
            </div>
          )}
          {/* <ViewportList items={subElectricalItems}>
            {(dose: ElectricalSubItem, index: number) => {
              return (
                <div
                  style={{ cursor: 'pointer' }}
                  key={index.toLocaleString()}
                  onClick={() => {
                    if (isEditMode) {
                      setSelectedData(dose);
                      setDoseIndex(index);
                      setEditDose(true);
                      setSidebarVisible(true);
                    }
                  }}
                >
                  <ElectricalDose
                    dose={dose}
                    doseIndex={index + 1}
                    showProtocol={protocol == null}
                  />
                </div>
              );
            }}
          </ViewportList> */}
          {protocol ? (
            <ViewportList items={subElectricalItems}>
              {(dose: ElectricalSubItem, index: number) => {
                return (
                  <div
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (isEditMode) {
                        setSelectedData(dose);
                        setDoseIndex(index);
                        setEditDose(true);
                        setSidebarVisible(true);
                      }
                    }}
                  >
                    <ElectricalDose
                      dose={dose}
                      doseIndex={index + 1}
                      showProtocol={protocol == null}
                    />
                  </div>
                );
              }}
            </ViewportList>
          ) : (
            <ViewportList items={allProtocols}>
              {(protocol: ProtocolItem, index: number) => {
                return <ProtocolItemUI key={index} protocol={protocol} />;
              }}
            </ViewportList>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElectricalProtocolEditPage;
