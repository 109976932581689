import { InputText } from 'primereact/inputtext';
import React from 'react';
import { AiOutlineCloseCircle, AiOutlineThunderbolt } from 'react-icons/ai';
import { HiPencil } from 'react-icons/hi';
import { LiaHeartbeatSolid, LiaWeightHangingSolid } from 'react-icons/lia';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import vitalAgeImage from '../../../assets/images/vitalAgeImage.png';
import MedicationItem from '../../../data/model/MedicationItem';
import ElectricalItem from '../../../data/model/ElectricalItem';
import EquipmentItem, {
  formatForMatchingUnits,
} from '../../../data/model/EquipmentItem';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import VitalItem from '../../../data/model/VitalItem';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';
import { EquipmentOption, VitalOption } from '../../../models';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import { globals, mapIndexToRange } from '../../_global/common/Utils';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from '@mui/material';
import { FaInfoCircle } from 'react-icons/fa';

interface DoseSelectionProps {
  type: string;
  doseIndex: number;
  parent: MedicationItem | ElectricalItem | EquipmentItem | VitalItem | any;
  onRangeClick: (range: string, isLow: boolean, isVital: boolean) => void;
  onBasisClick?: (
    basis: string,
    parent: MedicationItem | ElectricalItem
  ) => void;
  onValueClick?: (
    value: string,
    parent: EquipmentItem | VitalItem | any
  ) => void;
  onMinMaxDoseClick: (
    value: string,
    type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
  ) => void;
  onRoutesClick: (route: string[]) => void;
  onSingleRouteClick: (route: string, isDelete: boolean) => void;
  handleSubmit: (dose: any) => void;

  formik: any;

  initialValue?:
    | MedicationSubItem
    | ElectricalSubItem
    | EquipmentOption
    | VitalOption;

  isEmergency?: boolean;

  // data?: any;
  // rangeLow?: string;
  // setRangeLow?: (value: string) => void;
  // rangeHigh?: string;
  // setRangeHigh?: (value: string) => void;
  // route?: string;
  // setRoute?: (value: string) => void;
  // basis?: string;
  // setCalculationBasis?: (value: string) => void;
  // minDose?: string;
  // setMinDose?: (value: string) => void;
  // maxDose?: string;
  // setMaxDose?: (value: string) => void;
  // instructions?: boolean;
  // header?: boolean;
  // routeOnClick?: (value: any) => void;
  // calculationBasisOnClick?: (value: any) => void;
  // minDoseOnClick?: (value: any) => void;
  // maxDoseOnClick?: (value: any) => void;
  // (isEditable && formik.values. !== '')?: boolean;
  // onRangeLowClick?: (value: any) => void;
  // onRangeHighClick?: (value: any) => void;
  // handleConfirmModal?: (value: any) => void;
  // onValueClick?: (value: any) => void;
  // editDose?: boolean;
  // !isEditable?: boolean;
  // modalName?: string;
  // newRouteOnClick?: () => void;
  // handleChangeFormValue?: any;
  // fixedMaxClick?: (value: any) => void;
  // calculatedMaxClick?: (value: any) => void;
  // isEditable?:any;
}

/* 10-17-23 Praveen: Added Global component for Dose Section  */
const DoseSelection: React.FC<DoseSelectionProps> = ({
  type,
  formik,
  doseIndex,
  parent,
  onRangeClick,
  onBasisClick,
  onValueClick,
  onMinMaxDoseClick,
  onRoutesClick,
  onSingleRouteClick,
  handleSubmit,
  initialValue,
  isEmergency,
}) => {
  const isEditable: boolean = true;
  const [isWarning, setIsWarning] = React.useState(
    (type === 'Medication' || type === 'Infusions') && formik.values.warning
      ? true
      : false
  );
  return (
    <div
      className={`model-input-container  ${
        !isEditable
          ? 'cursorPointerSpan cursorPointerDiv cursorPointerInput cursorPointerLabel'
          : ''
      }`}
    >
      <div className={`${!isEditable && 'doseGridPad'}`}>
        <div className="ketamine-container-pad">
          {/* Range Input */}
          <div className="ketamine-input-twocontent">
            {type === 'Vital' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: '5px',
                  }}
                >
                  <img
                    src={vitalAgeImage}
                    alt="Vital Icon"
                    className="protocol-vital-icons"
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="rangeLow"
                    className={`ketamine-label-equipment`}
                  >
                    Range Low
                    {!!isEditable && <span className="required-field">*</span>}
                  </label>
                  <div
                    className="p-input-icon-right"
                    onClick={() => {
                      if (isEditable)
                        onRangeClick(
                          mapIndexToRange(formik.values.rangeLow),
                          true,
                          true
                        );
                    }}
                  >
                    <InputText
                      type="text"
                      className={`form-control pointer ${
                        !isEditable ? '' : 'inputHover'
                      } `}
                      id="rangeLow"
                      name="rangeLow"
                      placeholder="Min"
                      value={
                        formik.values.rangeLow !== ''
                          ? !isNaN(formik.values.rangeLow)
                            ? mapIndexToRange(formik.values.rangeLow)
                            : typeof formik.values.rangeLow === 'string'
                              ? formik.values.rangeLow
                              : 'MIN'
                          : 'MIN'
                      }
                      onChange={formik.handleChange}
                      readOnly={true}
                      disabled={!isEditable}
                    />
                    {isEditable && <HiPencil className="icon-black" />}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="rangeHigh"
                    className={`ketamine-label-equipment`}
                  >
                    Range High{' '}
                    {isEditable && <span className="required-field">*</span>}
                  </label>
                  <div
                    className="p-input-icon-right"
                    onClick={() => {
                      if (isEditable)
                        onRangeClick(
                          mapIndexToRange(formik.values.rangeHigh),
                          false,
                          true
                        );
                    }}
                  >
                    <InputText
                      type="text"
                      className={`form-control highRange pointer ${
                        !isEditable ? '' : 'inputHover'
                      } `}
                      id="rangeHigh"
                      name="rangeHigh"
                      placeholder="Max"
                      value={
                        formik.values.rangeHigh !== ''
                          ? !isNaN(formik.values.rangeHigh)
                            ? mapIndexToRange(formik.values.rangeHigh)
                            : typeof formik.values.rangeHigh === 'string'
                              ? formik.values.rangeHigh
                              : 'MAX'
                          : 'MAX'
                      }
                      onChange={formik.handleChange}
                      readOnly={true}
                      disabled={!isEditable}
                    />
                    {isEditable && formik.values.rangeHigh !== '' && (
                      <HiPencil className="icon-black" />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: '5px',
                  }}
                >
                  <LiaWeightHangingSolid className="protocol-icons " />
                </div>
                <div className="form-group">
                  <label
                    htmlFor={isEditable ? 'rangeLow' : ''}
                    className={` ketamine-label`}
                  >
                    Range Low
                    <span className="required-field">
                      {isEditable ? '*' : ''}
                    </span>
                  </label>
                  <div
                    className="p-input-icon-right"
                    data-testid="editRangeLow"
                    onClick={() => {
                      if (isEditable)
                        onRangeClick(formik.values.rangeLow, true, false);
                    }}
                  >
                    <div
                      // type="text"
                      className={`form-control pointer ${
                        !isEditable
                          ? !formik.values.rangeLow
                            ? 'greyText'
                            : ''
                          : 'inputHover'
                      }`}
                      id="rangeLow"
                      // name="rangeLow"
                      placeholder="Min"
                      data-testid="rangeLow"
                      style={{
                        color: !formik.values.rangeLow ? '#6B6D70' : '#000000',
                      }}
                    >
                      {formik.values.rangeLow
                        ? formik.values.rangeLow + ' kg'
                        : 'Min'}
                    </div>
                    {isEditable && <HiPencil className="icon-black" />}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor={isEditable ? 'rangeHigh' : ''}
                    className={` ketamine-label cursorPointerDiv`}
                  >
                    Range High
                    <span className="required-field">
                      {isEditable ? '*' : ''}
                    </span>
                  </label>
                  <div
                    className="p-input-icon-right"
                    onClick={() => {
                      if (isEditable) {
                        let rangeHigh = formik.values.rangeHigh
                          ? formik.values.rangeHigh
                          : globals.MAX_VALUE.toLocaleString();
                        onRangeClick(formik.values.rangeHigh, false, false);
                      }
                    }}
                  >
                    <div
                      // type="text"
                      className={`form-control pointer ${
                        !isEditable
                          ? !formik.values.rangeHigh
                            ? 'greyText'
                            : ''
                          : 'inputHover'
                      }`}
                      id="rangeLow"
                      // name="rangeLow"
                      placeholder="Max"
                      data-testid="rangeLow"
                      style={{
                        color: !formik.values.rangeHigh ? '#6B6D70' : '#000000',
                      }}
                    >
                      {formik.values.rangeHigh
                        ? formik?.values?.rangeHigh + ' kg'
                        : 'Max'}
                    </div>
                    {isEditable && <HiPencil className="icon-black" />}
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Medication calculation Basis Input */}
          {!isEmergency && type === 'Medication' && (
            <div className="ketamine-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <PiSyringe className="protocol-icons " />
              </div>
              <div
                className="form-group"
                onClick={() => {
                  if (isEditable)
                    onBasisClick?.(
                      formik.values.basis,
                      parent as MedicationItem
                    );
                }}
              >
                <label
                  htmlFor="basis"
                  className={` ketamine-label cursorPointerLabel`}
                >
                  Calculation Basis
                  <span className="required-field">
                    {isEditable ? '*' : ''}
                  </span>
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer ${
                      formik.values.basis === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="basis"
                    name="basis"
                    placeholder=" "
                    data-testid="calculationBasisValue"
                    value={formik.values.basis}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable &&
                    formik.values.basis !== '' &&
                    formik.values.basis !== '+Add' && (
                      <HiPencil
                        className="icon-black"
                        style={{ marginRight: '8px' }}
                      />
                    )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="minDose" className={` ketamine-label`}>
                  Min Dose
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.minDose, 'MIN DOSE');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.minDose !== '+Add' ? '' : 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="minDose"
                    name="minDose"
                    placeholder="Min"
                    data-testid="minDose"
                    value={formik.values.minDose}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {formik.values.minDose !== '+Add' && isEditable && (
                    <HiPencil className="icon-black" />
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="maxDose" className={` ketamine-label`}>
                  Max Dose
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.maxDose, 'MAX_DOSE');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.maxDose !== '+Add' ? '' : 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={formik.values.maxDose}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable && formik.values.maxDose !== '+Add' && (
                    <HiPencil className="icon-black" />
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Infusion calculation Basis Input */}
          {type === 'Infusion' && (
            <div className="ketamine-input-content-equipment">
              <div className="ketamine-input-content-equipment">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: '5px',
                  }}
                >
                  <PiSyringe className="protocol-icons " />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="value" className="ketamine-label-equipment">
                  Calculation Basis:
                  {isEditable && <span className="required-field">*</span>}
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    onBasisClick?.(
                      formik.values.basis,
                      parent as MedicationItem
                    );
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer ${
                      formik.values.basis === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="basis"
                    name="basis"
                    placeholder=" "
                    data-testid="calculationBasisValue"
                    value={formik.values.basis}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable &&
                    formik.values.basis !== '' &&
                    formik.values.basis !== '+Add' && (
                      <HiPencil
                        className="icon-black"
                        style={{ marginRight: '8px' }}
                      />
                    )}
                </div>
              </div>
            </div>
          )}

          {/* Electrical calculation Basis Input */}
          {type === 'Electrical' && (
            <div className="electrical-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <AiOutlineThunderbolt className="protocol-vital-icons " />
              </div>
              <div
                className="form-group"
                onClick={() => {
                  if (isEditable)
                    onBasisClick?.(
                      formik.values.basis,
                      parent as ElectricalItem
                    );
                }}
              >
                <label htmlFor="basis" className={` ketamine-label`}>
                  Calculation Basis
                  <span className="required-field">
                    {isEditable ? '*' : ''}
                  </span>
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer ${
                      formik.values.basis === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="basis"
                    name="basis"
                    placeholder=" "
                    //value={formik.values.basis ? formik.values.basis : (!isEditable ? 'N/A' : '+Add')}
                    value={formik.values.basis}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable && (
                    <HiPencil
                      className="icon-black"
                      style={{ marginRight: '8px' }}
                    />
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="Value" className={`ketamine-label-equipment`}>
                  Calculated Max
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.calcMax, 'CALC_MAX');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.calcMax === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="calcMax"
                    name="calcMax"
                    placeholder="Max"
                    data-testid="calcMax"
                    value={formik.values.calcMax}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable && <HiPencil className="icon-black" />}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="Value" className={`ketamine-label-equipment`}>
                  Fixed Max
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.fixedMax, 'FIXED_MAX');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.fixedMax === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="fixedMax"
                    name="fixedMax"
                    placeholder="Max"
                    value={formik.values.fixedMax}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable && <HiPencil className="icon-black" />}
                </div>
              </div>
            </div>
          )}

          {/* Equipment and Vital Input */}
          {(type === 'Equipment' || type === 'Vital') && (
            <div className="ketamine-input-content-equipment">
              {type === 'Vital' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    marginBottom: '5px',
                  }}
                >
                  <LiaHeartbeatSolid className="protocol-icons " />
                </div>
              ) : (
                <div className="ketamine-input-content-equipment">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                    }}
                  >
                    <HiOutlineWrenchScrewdriver className="protocol-icons " />
                  </div>
                </div>
              )}
              <div className="form-group">
                <label htmlFor="value" className="ketamine-label-equipment">
                  Value:
                  {isEditable && <span className="required-field">*</span>}
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    onValueClick?.(
                      formatForMatchingUnits(
                        formik.values.valueLow,
                        formik.values.valueHigh
                      ),
                      parent as EquipmentItem | VitalItem
                    );
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer ${
                      formik.values.valueLow === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    id="value"
                    value={formatForMatchingUnits(
                      formik.values.valueLow,
                      formik.values.valueHigh
                    )}
                    data-testid="value-input"
                    placeholder=""
                    onChange={(e) => {
                      if (isEditable) formik.handleChange(e);
                    }}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable &&
                    formik.values.valueLow !== '' &&
                    formik.values.valueLow !== '+Add' && (
                      <HiPencil className="icon-black" />
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Route Input */}
      {!isEmergency && (type === 'Medication' || type === 'Infusion') && (
        <div className="sidebarInputContent">
          <label
            htmlFor={isEditable ? 'Route' : ''}
            className={` rowLabelWidth sidebarLabel`}
          >
            Route:{' '}
            <span className="required-field">{isEditable ? '*' : ''}</span>
          </label>
          <div
            data-div-id="outside"
            onClick={() => {
              if (isEditable) onRoutesClick(formik.values.routes);
            }}
            className={`selectionArea-hover ${
              isEditable ? 'cursorPointer ' : 'selectionArea-disable'
            } ${formik.values.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {formik.values.routes.length !== 0 && (
              <ViewportList items={formik.values.routes}>
                {(route: string, index: number) => {
                  return (
                    <div
                      data-div-id="grey"
                      onClick={(e) => {
                        if (isEditable) {
                          e.stopPropagation();
                          onSingleRouteClick(route, false);
                        }
                      }}
                      key={index}
                      className={`selectedGreybox ${
                        isEditable && 'cursorPointer'
                      }`}
                    >
                      <div>{route}</div>
                      {!!isEditable && (
                        <span
                          className={`close_icon_color ${
                            isEditable && 'cursorPointer'
                          }`}
                          onClick={(e) => {
                            if (isEditable) {
                              e.stopPropagation();
                              onSingleRouteClick(route, true);
                            }
                          }}
                        >
                          <AiOutlineCloseCircle className="cursorPointer icon-normal" />
                        </span>
                      )}
                    </div>
                  );
                }}
              </ViewportList>
            )}
            {formik.values.routes.length !== 0 && !!isEditable && (
              <div data-div-id="edit">
                <HiPencil className="cursorPointer icon-black rightIcon" />
              </div>
            )}
            {formik.values.routes.length === 0 && (
              <div>
                <span className="formisEditable formAnchorText">+Add</span>
              </div>
            )}
          </div>
        </div>
      )}

      {/* {modalName === 'Checklist' &&
                    <div className={`model-input-container  ${!isEditable && 'equipmentdosePad'}`}>
                        <div className={gridClass} style={{ marginBottom: '10px' }} >
                           <label htmlFor="rangeLow" className={`ketamine-label-equipment`}>Title:</label>
                            <div className="input-container">
                                <input
                                    type="text"
                                    className={`form-control-general `}
                                    id="title"
                                    name='title'
                                    placeholder="Enter Title here..."
                                    value={data.title}
                                    readOnly={true}
                                />
                                <div className="input-border"></div>
                            </div>
                        </div>
                        <div className='checklist-input-Dose'>
                           <div className="form-group">
                                <label htmlFor="rangeLow" className={`ketamine-label-equipment`}>Options:</label>
                                <div className="p-input-icon-right">
                                    <InputText
                                        type="text"
                                        className={`form-control pointer`}
                                        id="items"
                                        name="items"
                                        placeholder=""
                                        value={data.isDouble ? data.items.length*2 : data.items.length}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="rangeLow" className={`ketamine-label-equipment`}>Form Stucture </label>
                                <div className="p-input-icon-right">
                                    <InputText
                                        type="text"
                                        className={`form-control pointer ${!isEditable ? '' : 'inputHover'} `}
                                        id="stucture"
                                        name="stucture"
                                        placeholder=""
                                        value={data.isDouble ?"Double":"Single"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="rangeHigh" className={`ketamine-label-equipment`}>Form Type </label>
                                <div className={`p-input-icon-right ${data.isUrgent && 'urgentTextColr'} `}>
                                    <InputText
                                        type="text"
                                        className={` ${data.isUrgent ? 'urgentTextColr form-control highRange pointer':'form-control highRange pointer'} `}
                                        id="type"
                                        name="type"
                                        placeholder=""
                                        value={data.isUrgent ?"URGENT":"Normal"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                } */}

      {!(type === 'Vital' || type === 'Checklist' || type === 'Equipment') && (
        <div className={` ${!isEditable && 'sideoutFieldPad'}`}>
          {(isEditable || formik.values.title) && !isEmergency && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <Tooltip
                title={
                  isWarning
                    ? `Add a a RED message above where the ${type.toLocaleLowerCase()}'s name is displayed.`
                    : `Add a message above where the ${type.toLocaleLowerCase()}'s name is displayed.`
                }
                placement="left"
                arrow
              >
                <label
                  htmlFor="Title"
                  className={`sidebarLabel `}
                  style={{
                    color: isWarning ? '#880808' : 'black',
                  }}
                >
                  {isWarning ? 'Warning:' : 'Title:'}
                  <span>
                    <FaInfoCircle
                      color="#636363"
                      style={{ marginLeft: '5px', marginBottom: '2px' }}
                    />
                  </span>
                </label>
              </Tooltip>
              <div className="input-container">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <input
                    type="text"
                    className={`form-control-general `}
                    id="title"
                    name="title"
                    data-testid="title"
                    placeholder={`Enter ${isWarning ? 'Warning' : 'Title'} here...`}
                    value={
                      isWarning ? formik.values.warning : formik.values.title
                    }
                    //disabled={!isEditable}
                    onKeyDown={(e: any) => {
                      if (e.key === 'Enter') handleSubmit(formik.values);
                    }}
                    onChange={(e: any) => {
                      if (isWarning)
                        formik.setFieldValue('warning', e.target.value);
                      else formik.setFieldValue('title', e.target.value);
                    }}
                    disabled={!isEditable}
                  />
                  {(type === 'Medication' || type === 'Infusions') && (
                    <InputSwitch
                      tooltip="Swtich to Warning Title. Gives it red text."
                      tooltipOptions={{
                        position: 'left',
                        style: {
                          fontSize: '12px',
                        },
                      }}
                      name="warningTitle"
                      style={{ marginLeft: '10px' }}
                      checked={isWarning}
                      onChange={(e) => {
                        setIsWarning(e.value);
                        if (e.value) {
                          formik.setFieldValue('warning', formik.values.title);
                          formik.setFieldValue('title', '');
                        } else {
                          formik.setFieldValue('title', formik.values.warning);
                          formik.setFieldValue('warning', '');
                        }
                      }}
                    />
                  )}
                </div>
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {type === 'Electrical' && (isEditable || formik.values.warning) && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <Tooltip
                title={`Add a a RED message above where the ${type.toLocaleLowerCase()}'s name is displayed.`}
                placement="left"
                arrow
              >
                <label
                  htmlFor="warning"
                  className={`sidebarLabel `}
                  style={{
                    color: '#880808',
                  }}
                >
                  {'Warning:'}
                  <span>
                    <FaInfoCircle
                      color="#636363"
                      style={{ marginLeft: '5px', marginBottom: '2px' }}
                    />
                  </span>
                </label>
              </Tooltip>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="warning"
                  name="warning"
                  data-testid="warning"
                  placeholder="Enter Warning here..."
                  value={formik.values.warning}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit(formik.values);
                  }}
                  //disabled={!isEditable}
                  onChange={(e: any) => {
                    formik.setFieldValue('warning', e.target.value);
                  }}
                  disabled={!isEditable}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {(isEditable || formik.values.instruction) && !isEmergency && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <Tooltip
                title={`Add instructions for the ${type.toLocaleLowerCase()}. This will be displayed under the ${type.toLocaleLowerCase()}'s name.`}
                placement="left"
                arrow
              >
                <label htmlFor="instructions" className={`sidebarLabel `}>
                  Instructions:
                  <span>
                    <FaInfoCircle
                      color="#636363"
                      style={{ marginLeft: '5px', marginBottom: '2px' }}
                    />
                  </span>
                </label>
              </Tooltip>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="instruction"
                  name="instruction"
                  data-testid="instruction"
                  placeholder="Add Instructions here..."
                  value={formik.values.instruction}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit(formik.values);
                  }}
                  //disabled={!isEditable}
                  onChange={(e: any) => {
                    formik.setFieldValue('instruction', e.target.value);
                  }}
                  disabled={!isEditable}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {(isEditable || formik.values.note) && !isEmergency && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <Tooltip
                title={`Add additional information for the ${type.toLocaleLowerCase()}. This will be displayed ONLY in the dropdown when the ${type.toLocaleLowerCase()} is expaned.`}
                placement="left"
                arrow
              >
                <label htmlFor="note" className={`sidebarLabel `}>
                  Note:
                  <span>
                    <FaInfoCircle
                      color="#636363"
                      style={{ marginLeft: '5px', marginBottom: '2px' }}
                    />
                  </span>
                </label>
              </Tooltip>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general`}
                  id="note"
                  name="note"
                  data-testid="note"
                  placeholder="Enter Note here..."
                  value={formik.values.note}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit(formik.values);
                  }}
                  //disabled={!isEditable}
                  onChange={(e: any) => {
                    formik.setFieldValue('note', e.target.value);
                  }}
                  disabled={!isEditable}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DoseSelection;
