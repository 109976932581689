/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "9d28a98122f544799b7d5997910ea62b",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "9d28a98122f544799b7d5997910ea62b",
            "region": "us-east-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://szvx4iseyzf23hutse4cyebi5m.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7rfocyh5ovfz3dtgaxdrkkjoxq",
    "aws_cognito_identity_pool_id": "us-east-2:1383ca77-e3e6-4a2d-b2e9-cbd0b79ffbde",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_L2hMCogpW",
    "aws_user_pools_web_client_id": "5pm86a657iotgej304jkukmtqu",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": [
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "onedose-storage-protocol-0f3e5e2a134239-newdev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
