import React, { useEffect, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import {
  DatabaseResponse,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import MedicationItem from '../../../../../../data/model/MedicationItem';
import { fetchMedicationsFromAPI } from '../../../../../../data/GraphQL_API';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import SearchBar from '../../../../../components/Search/SearchBar';
import Status from '../../../../../components/ProgressStatus/ProgressStatus';
import { getFormattedDate } from '../../../../../_global/common/Utils';
import { ModelMetaData } from '../../../../../../models';
import { handleHinckleyCache } from '../../../../../../store/actions';
const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const HinckleyMedications = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const [totalDoses, setTotalDoses] = useState(-1);
  const hmState = useSelector((state: any) => state?.hinckley);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [sort, setSort] = useState('name_asc');
  const department = database.department;
  const [medications, setMedications] = useState<MedicationItem[]>(
    state?.list ? state.list : hmState.medications ? hmState.medications : []
  );

  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState<any[]>([]);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>([]);

  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    getHinckleyMediations(database).then((meds) => {
      setMedications(meds);
    });
  }, [database]);

  // const reloadDatabase = async () => {
  //   const response = await loadDatabase(department);
  //   if (response.type === ResponseType.Success) {
  //     const database = response.data;
  //     setMedications(database.medications);
  //     setDatabase(database);
  //     handleFilterChange(database.medications);
  //     dispatch<any>(handleGetDepartment(database));
  //   }
  // };

  const getHinckleyMediations = async (
    db: DatabaseResponse
  ): Promise<MedicationItem[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        // const medicationList = await DataStore.query(Medication, (m) =>
        //   m.and((m) => [
        //     m.departmentID.eq(HM_DB_ID),
        //     m.or((m) => [m.status.eq(undefined), m.status.eq('ACTIVE')]),
        //   ])
        // );
        // let meds = medicationList.map((med) => new MedicationItem(med));
        const medResponse = await fetchMedicationsFromAPI(
          db.department,
          HM_DB_ID
        );
        if (medResponse.type === ResponseType.Success) {
          resolve(medResponse.data);
        } else reject('Error fetching medications');
        dispatch<any>(
          handleHinckleyCache({
            medications: medResponse.data,
          })
        );
        // console.log('Medication List:', meds.length);
        // resolve(meds);
      } catch (error) {
        console.error(error);
      }
    });
  };

  useEffect(() => {
    let count = 0;
    medications.forEach((item) => {
      count += item.medicationSubItems.length;
    });
    setTotalDoses(count !== 0 ? count : -1);
  }, [database, medications]);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = medications.filter((item) => {
        const metaData: ModelMetaData | undefined | null =
          item.dbMedication?.metaData;
        return (
          item.name.toLowerCase().includes(searchQuery) ||
          (metaData &&
            metaData.fullName?.toLowerCase().includes(searchQuery)) ||
          (metaData &&
            metaData.brandNames &&
            metaData.brandNames
              .map((b) => b.toLowerCase())
              .includes(searchQuery))
        );
      });
    } else filteredList = medications;
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: MedicationItem, b: MedicationItem) => {
      return a.name.localeCompare(b.name);
    });
    handleSort(filteredList);
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(medications);
  }, [searchQuery, filters, categoriesFilter, sort, medications]);

  const handleSort = (list: MedicationItem[]): MedicationItem[] => {
    const sortedList = list.sort((a, b) => {
      if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'doses_desc') {
        if (a.medicationSubItems.length > b.medicationSubItems.length)
          return -1;
        else if (a.medicationSubItems.length < b.medicationSubItems.length)
          return 1;
        else return 0;
      } else if (sort === 'doses_asc') {
        if (a.medicationSubItems.length < b.medicationSubItems.length)
          return -1;
        else if (a.medicationSubItems.length > b.medicationSubItems.length)
          return 1;
        else return 0;
      } else if (!a.dbMedication || !b.dbMedication) return 0;
      else if (sort === 'modified_asc') {
        if (!a.dbMedication.updatedAt || !b.dbMedication.updatedAt) return 0;
        const aDate = new Date(a.dbMedication.updatedAt);
        const bDate = new Date(b.dbMedication.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.dbMedication.updatedAt || !b.dbMedication.updatedAt) return 0;
        const aDate = new Date(a.dbMedication.updatedAt);
        const bDate = new Date(b.dbMedication.updatedAt);
        return bDate.getTime() - aDate.getTime();
      }
      return 0;
    });
    return sortedList;
  };

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (medication: MedicationItem) => {
    const state = {
      medication: medication,
      list: medications,
    };
    navigate(`/actions/hinckley-database/view-medication`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    // const state = {
    //   selectedProtocol: null,
    //   value: null,
    //   subValue: null,
    //   type: 'Medication',
    //   editType: 'new',
    //   editMode: false,
    //   page: 'listMedicationsPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    // // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr 1fr 3fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/actions/hinckley-database`);
  };

  function getConcentrationString(medication: MedicationItem) {
    if (!medication.concentrations) return '';
    let str = medication.concentrations.length > 1 ? '[' : '';

    for (let i = 0; i < medication.concentrations.length; i++) {
      let concen = medication.concentrations[i];
      if (concen.firstAmnt <= 0) return 'N/A';
      str += concen.firstAmnt + ' ' + concen.firstUnit;
      if (!concen.secAmnt || concen.secAmnt <= 0) return str;
      if (concen ?? 0 > 0) {
        str += '/';
        if (concen.secAmnt != 1.0) str += concen.secAmnt + ' ' + concen.secUnit;
        else str += concen.secUnit;
      }
      if (i < medication.concentrations.length - 1) str += ', ';
    }
    if (medication.concentrations.length > 1) str += ']';
    return str;
  }

  return (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'HM Medications: ' + list.length + ' items'}
        page={'Hinckley DB'}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Medications...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div
                style={rowStyle}
                className="departmentItemNoHover"
                // onMouseEnter={() => setIsHovered(true)}
                // onMouseLeave={() => setIsHovered(false)}
              >
                {/* <h6 className='departmentItemText'>Version</h6> */}
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('name')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'name_desc') setSort('name_asc');
                    else setSort('name_desc');
                  }}
                >
                  Name
                  <span>
                    {sort === 'name_desc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Full Name</h6>
                <h6 className="departmentItemText">Concentration</h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('modified')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'modified_desc') setSort('modified_asc');
                    else setSort('modified_desc');
                  }}
                >
                  Modified Date
                  <span>
                    {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Nicknames</h6>
                {/* {department.subDeps && <h6 className='departmentItemText'>Sub-Deps</h6>} */}
              </div>
            </Accordion>
          </div>
          <ViewportList items={list}>
            {(item: MedicationItem, index) => {
              let id = item.dbMedication?.modifiedBy;
              if (id == null) id = item.dbMedication?.createdBy;
              let username = 'Hinckley Medical';
              if (id) {
                let user = database.users.find((user) => user.id === id);
                if (user) username = user.firstName + ' ' + user.lastName;
              }
              let metaData: ModelMetaData | undefined | null = item.dbMedication
                ? item.dbMedication.metaData
                : null;
              return (
                <div
                  key={index}
                  style={{
                    borderBottom: '1px solid #ccc',
                    flexDirection: 'row',
                  }}
                  onClick={() => handleItemClick(item)}
                >
                  <div
                    style={rowStyle}
                    className="departmentItem"
                    // onMouseEnter={() => setIsHovered(true)}
                    // onMouseLeave={() => setIsHovered(false)}
                  >
                    {/* <h6 className='departmentItemText'>{item.version}</h6> */}
                    <h6 className="departmentItemText">
                      {item.name}

                      {item.status !== 'ACTIVE' && (
                        <Status status={item.status} />
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {metaData?.fullName ? metaData.fullName : ''}
                    </h6>
                    <h6 className="departmentItemText">
                      {getConcentrationString(item)}
                    </h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.dbMedication?.updatedAt
                          ? item?.dbMedication?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                        }}
                      >
                        {metaData?.brandNames?.join(', ')}
                      </div>
                      <FaChevronRight
                        className="icon-normal "
                        style={{ margin: '4px' }}
                      />
                    </h6>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default HinckleyMedications;
