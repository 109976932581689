import ProtocolItem from './ProtocolItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { globals, roundToDec } from '../../ui/_global/common/Utils';
import ElectricalItem from './ElectricalItem';
import { ElectricalRange, ElectricalShockRange } from '../../models';
import { parse } from '@fortawesome/fontawesome-svg-core';
import { ProgressStatus } from '../../API';

export const cloneAllElectricalSubItems = (
  subElectricals: ElectricalSubItem[]
): ElectricalSubItem[] => {
  let clone: ElectricalSubItem[] = [];
  subElectricals.forEach((subElectrical: ElectricalSubItem) => {
    clone.push(cloneElectricalSubItem(subElectrical));
  });
  return clone;
};

export const cloneElectricalSubItem = (
  subElectrical: ElectricalSubItem
): ElectricalSubItem => {
  let clone = new ElectricalSubItem(
    subElectrical.parentElectrical,
    subElectrical.parentProtocol,
    subElectrical.range
  );
  clone.basis = subElectrical.basis;
  clone.basisHigh = subElectrical.basisHigh;
  clone.calcMax = subElectrical.calcMax;
  clone.fixedMax = subElectrical.fixedMax;
  clone.basisUnit = subElectrical.basisUnit;
  clone.calcUnit = subElectrical.calcUnit;
  clone.calcMaxUnit = subElectrical.calcMaxUnit;
  clone.fixedMaxUnit = subElectrical.fixedMaxUnit;
  clone.calcCalcMaxUnit = subElectrical.calcCalcMaxUnit;
  return clone;
};

/**
 *
 * @param item
 * @returns
 */
export const getElectricalBasisString = (item: ElectricalSubItem): string => {
  if (!item.calcUnit || item.calcUnit.length == 0) {
    if (item.basisHigh === globals.MAX_VALUE)
      return `${item.basis} ${item.basisUnit}`;
    return `${item.basis}-${item.basisHigh} ${item.basisUnit}`;
  } else {
    if (item.basisHigh === globals.MAX_VALUE)
      return `${item.basis} ${item.basisUnit}/${item.calcUnit}`;
    return `${item.basis}-${item.basisHigh} ${item.basisUnit}/${item.calcUnit}`;
  }
};
class ElectricalSubItem implements ModelItem {
  TAG = 'ElectricalSubItem';
  uid: string;
  name: string;
  title: string;
  warning: string;
  instruction: string;
  note: string;
  index: number;
  parentProtocol: ProtocolItem;
  parentElectrical: ElectricalItem;
  range: ElectricalShockRange;
  basisUnit: string = '';
  calcUnit: string = '';
  fixedMaxUnit: string = '';
  calcMaxUnit: string = '';
  calcCalcMaxUnit: string = '';
  basis: number = 0.0;
  basisHigh: number = globals.MAX_VALUE;
  calcMax: number = globals.MAX_VALUE;
  fixedMax: number = globals.MAX_VALUE;
  rangeLow: number = 0.0;
  rangeHigh: number = globals.MAX_VALUE;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';

  constructor(
    electrical: ElectricalItem,
    parent: ProtocolItem,
    range: ElectricalShockRange
  ) {
    this.uid = electrical.uid;
    this.name = electrical.name;
    this.range = range;
    this.parentElectrical = electrical;
    this.parentProtocol = parent;
    this.title = range.title;
    this.status = electrical.status;
    this.rangeLow = range.rangeLow ? range.rangeLow : 0;
    this.rangeHigh = range.rangeHigh ? range.rangeHigh : globals.MAX_VALUE;

    this.warning = range.warning ? range.warning : '';
    this.instruction = range.instruction ? range.instruction : '';
    this.note = range.note ? range.note : '';
    this.index = range.index;

    this.parseRange(range);
  }

  // constructor(parent : ProtocolItem, name: string) {
  //     this.name               = name;
  //     this.parentProtocol     = parent;
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.instruction        = "";
  //     this.warning            = "";
  //     this.option             = {
  //         title : "1st Dose",
  //         ranges: [{
  //             rangeLow: 0,
  //             rangeHigh: globals.MAX_VALUE,
  //             basis: "1 J/kg",
  //             basisHigh: "2 J/kg",
  //             calcMax: "10 J",
  //             fixedMax: "10 J"
  //         }]};
  //     this.dbElectrical       = null;
  // }

  getAmount(weight: PatientWeight): string {
    if (!this.inRange(weight)) return '';

    try {
      /* First check if it a single dose or a range */
      if (this.basisHigh === globals.MAX_VALUE) {
        /* If there is no calculation needed return the dose amount */
        if (this.calcUnit == null || this.calcUnit.length == 0)
          return `${roundToDec(this.basis, 1).toFixed(1)} ${this.basisUnit}`;
        else {
          /* First calculate the dose based on the basis */
          let dose: number = roundToDec(this.basis * weight.getWeightKg(), 1);

          /* If there is a calcMax and it is not zero, then use it to limit the dose */
          if (this.calcMax != -1.0) {
            if (this.basisUnit !== this.calcMaxUnit) {
              console.error(
                'ERROR: Basis unit is not the same as calcMax unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.calcMaxUnit
              );
              return '';
            }
            dose = roundToDec(
              Math.min(dose, this.calcMax * weight.getWeightKg()),
              1
            );
          }

          /* If there is a fixedMax and it is not zero, then use it to limit the dose */
          if (this.fixedMax != -1.0) {
            if (this.basisUnit !== this.fixedMaxUnit) {
              console.error(
                'ERROR: Basis unit is not the same as fixedMax unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.fixedMaxUnit
              );
              return '';
            }
            dose = roundToDec(Math.min(dose, this.fixedMax), 1);
          }

          /* Return the dose */
          return `${dose.toFixed(1)} ${this.basisUnit}`;
        }
      } else {
        /* Otherwise it is a range dose */
        /* If there is no calculation needed return the dose amount */
        if (!this.calcUnit || this.calcUnit.length == 0) {
          if (this.basis === this.basisHigh)
            return `${roundToDec(this.basis, 1).toFixed(1)} ${this.basisUnit}`;
          return `${roundToDec(this.basis, 1).toFixed(1)}-${roundToDec(this.basisHigh, 1).toFixed(1)} ${this.basisUnit}`;
        } else {
          /* First calculate the dose based on the basis low and high */
          let doseLow: number = roundToDec(
            this.basis * weight.getWeightKg(),
            1
          );
          let doseHigh: number = roundToDec(
            this.basisHigh * weight.getWeightKg(),
            1
          );

          /* Next check if there is a calculated max dose and check the units match, if so check to see if it is within the bounds */
          if (this.calcMax != -1.0) {
            if (this.basisUnit !== this.calcMaxUnit) {
              console.error(
                'ERROR: Basis unit is not the same as calcMax unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.calcMaxUnit
              );
              return '';
            }
            doseLow = roundToDec(
              Math.min(doseLow, this.calcMax * weight.getWeightKg()),
              1
            );
            doseHigh = roundToDec(
              Math.min(doseHigh, this.calcMax * weight.getWeightKg()),
              1
            );
          }

          /* Next check if there is a fixed max dose and check the units match, if so check to see if it is within the bounds */
          if (this.fixedMax != -1.0) {
            if (this.basisUnit !== this.fixedMaxUnit) {
              console.error(
                'ERROR: Basis unit is not the same as fixedMax unit: ' +
                  this.basisUnit +
                  ' ' +
                  this.fixedMaxUnit
              );
              return '';
            }
            doseLow = roundToDec(Math.min(doseLow, this.fixedMax), 1);
            doseHigh = roundToDec(Math.min(doseHigh, this.fixedMax), 1);
          }

          /* Lastly, check if the values are the same, if so return a single value, otherwise return a range */
          if (doseLow == doseHigh)
            return `${doseLow.toFixed(1)} ${this.basisUnit}`;
          return `${doseLow.toFixed(1)}-${doseHigh.toFixed(1)} ${this.basisUnit}`;
        }
      }
    } catch (error) {
      console.error(
        'ERROR: Failed to get electrical -> ' +
          this.basis +
          '-' +
          this.basisHigh +
          ' ' +
          this.basisUnit
      );
    }
    return '';
  }

  getCalculatedMax(weight: PatientWeight): string {
    if (this.calcMax && this.calcMax < globals.MAX_VALUE)
      return `${roundToDec(this.calcMax * weight.getWeightKg(), 1).toFixed(1)} ${this.calcMaxUnit} (${roundToDec(this.calcMax, 1).toFixed(1)} ${this.calcMaxUnit}/${this.calcUnit})`;
    else return '';
  }

  getFixedMax(): string {
    if (this.fixedMax != -1.0 && this.fixedMaxUnit != null)
      return `${roundToDec(this.fixedMax, 1).toFixed(1)} ${this.fixedMaxUnit}`;
    else return '';
  }

  getAmountLow(weight: PatientWeight): number {
    /* First check if it is a calculated dose or a standard */
    if (this.calcUnit == null || this.calcUnit.length == 0) return this.basis;

    /* Next calculate the dose based on the basis */
    let dose = roundToDec(this.basis * weight.getWeightKg(), 1);

    /* If there is a calcMax and it is not zero, then use it to limit the dose */
    if (this.calcMax != -1.0) {
      if (this.basisUnit !== this.calcMaxUnit) {
        console.error(
          'ERROR: Basis unit is not the same as calcMax unit: ' +
            this.basisUnit +
            ' ' +
            this.calcMaxUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.calcMax * weight.getWeightKg()), 1);
    }

    /* If there is a fixedMax and it is not zero, then use it to limit the dose */
    if (this.fixedMax != -1.0) {
      if (this.basisUnit !== this.fixedMaxUnit) {
        console.error(
          'ERROR: Basis unit is not the same as fixedMax unit: ' +
            this.basisUnit +
            ' ' +
            this.fixedMaxUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.fixedMax), 1);
    }
    return dose;
  }

  getAmountHigh(weight: PatientWeight): number {
    /* First check if it is a calculated dose or a standard */
    if (this.calcUnit == null || this.calcUnit.length == 0)
      return this.basisHigh;

    /* Next calculate the dose based on the basis */
    let dose = roundToDec(this.basisHigh * weight.getWeightKg(), 1);

    /* If there is a calcMax and it is not zero, then use it to limit the dose */
    if (this.calcMax != -1.0) {
      if (this.basisUnit !== this.calcMaxUnit) {
        console.error(
          'ERROR: Basis unit is not the same as calcMax unit: ' +
            this.basisUnit +
            ' ' +
            this.calcMaxUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.calcMax * weight.getWeightKg()), 1);
    }

    /* If there is a fixedMax and it is not zero, then use it to limit the dose */
    if (this.fixedMax != -1.0) {
      if (this.basisUnit !== this.fixedMaxUnit) {
        console.error(
          'ERROR: Basis unit is not the same as fixedMax unit: ' +
            this.basisUnit +
            ' ' +
            this.fixedMaxUnit
        );
        return -1;
      }
      dose = roundToDec(Math.min(dose, this.fixedMax), 1);
    }
    return dose;
  }

  getBasisString(): string {
    if (!this.calcUnit || this.calcUnit.length == 0) {
      if (this.basisHigh === globals.MAX_VALUE)
        return `${this.basis} ${this.basisUnit}`;
      return `${this.basis}-${this.basisHigh} ${this.basisUnit}`;
    } else {
      if (this.basisHigh === globals.MAX_VALUE)
        return `${this.basis} ${this.basisUnit}/${this.calcUnit}`;
      return `${this.basis}-${this.basisHigh} ${this.basisUnit}/${this.calcUnit}`;
    }
  }

  /**
   * Get the interval to use for the slider
   * @param weight The patient's weight
   * @returns The interval to use for the slider
   */
  getInterval(weight: PatientWeight): number {
    let low: number = this.getAmountLow(weight);
    let high: number = this.getAmountHigh(weight);
    if (high - low > 10) return 1;
    else if (high - low > 5) return 0.1;
    else return 0.05;
  }

  getBasisUnit(): string {
    return this.basisUnit;
  }

  inRange(weight: PatientWeight): boolean {
    return (
      this.rangeLow <= weight.getWeightKg() &&
      weight.getWeightKg() < this.rangeHigh
    );
  }

  isRangeElectrical(): boolean {
    return this.basisHigh !== globals.MAX_VALUE;
  }

  getParentProtocol(): ProtocolItem {
    return this.parentProtocol;
  }

  getUid(): string {
    return this.parentElectrical?.getUid();
  }

  /**
   * Gets the department id of the owner of this category.
   * @returns the department id of the owner of this category
   */
  getDepID(): string {
    return this.parentElectrical.getDepID();
  }

  getName(): string {
    return this.parentElectrical.getName();
  }

  getTitle(): string {
    return this.title;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof ElectricalSubItem)) return false;
    let other: ElectricalSubItem = obj as ElectricalSubItem;
    return (
      this.uid === other.uid &&
      this.name === other.name &&
      this.title === other.title &&
      this.rangeLow === other.rangeLow &&
      this.rangeHigh === other.rangeHigh &&
      this.basis === other.basis &&
      this.basisHigh === other.basisHigh &&
      this.calcMax === other.calcMax &&
      this.fixedMax === other.fixedMax &&
      this.basisUnit === other.basisUnit &&
      this.calcUnit === other.calcUnit &&
      this.calcMaxUnit === other.calcMaxUnit &&
      this.fixedMaxUnit === other.fixedMaxUnit &&
      this.calcCalcMaxUnit === other.calcCalcMaxUnit
    );
  }

  /**
   * Parses the range of this subitem into the variables of this subitem.
   * @param range The ElectricalRange element to parse
   * @returns A boolean indicating whether the parsing was successful
   */
  private parseRange(range: ElectricalRange): boolean {
    if (!range.basis || range.basis === '') return false;

    let obj = this.parseBasisString(range.basis);
    this.basis = obj[0];
    this.basisHigh = obj[1];
    this.basisUnit = obj[2];
    this.calcUnit = obj[3];

    if (range.calcMax) {
      obj = this.parseBasisString(range.calcMax);
      this.calcMax = obj[0];
      this.calcMaxUnit = obj[2];
      this.calcCalcMaxUnit = obj[3];
    }

    if (range.fixedMax) {
      obj = this.parseBasisString(range.fixedMax);
      this.fixedMax = obj[0];
      this.fixedMaxUnit = obj[2];
    }

    return true;
  }

  /**
   * Parses a basis string into a basis value, basis unit, and calculation unit.
   * @param basis The basis string to parse
   * @returns An array of the basis value, basis unit, and calculation unit
   */
  private parseBasisString(basis: string): any {
    let basisVal: number = 0.0;
    let basisValHigh: number = globals.MAX_VALUE;
    let basisUnit: string = '';
    let calcUnit: string | null = null;

    try {
      let basisArr: string[] = basis.split(' ');
      if (basisArr[0].includes('-')) {
        let basisArr2: string[] = basisArr[0].split('-');
        basisVal = parseFloat(basisArr2[0]);
        basisValHigh = parseFloat(basisArr2[1]);
      } else basisVal = parseFloat(basisArr[0]);
      let unitArr: string[] = basisArr[1].split('/');
      basisUnit = unitArr[0];
      if (unitArr.length > 1) {
        calcUnit = unitArr[1];
        if (calcUnit !== 'kg')
          if (globals.debug)
            console.log(
              this.TAG,
              'ERROR: Basis unit calculation is not kg -> ' +
                calcUnit +
                ' basis: ' +
                basis
            );
      }
      return [basisVal, basisValHigh, basisUnit, calcUnit];
    } catch (e) {
      if (globals.debug)
        console.log(this.TAG, 'ERROR: Failed to parse basis -> ' + basis);
      return [basisVal, basisValHigh, basisUnit, calcUnit];
    }
  }

  toString(): string {
    return `ElectricalSubItem -> {
            uid=${this.getUid()}, 
            name=${this.getName()},  
            basis=${this.basis},
            basisHigh=${this.basisHigh},
            calcMax=${this.calcMax},
            fixedMax=${this.fixedMax},
            rangeLow=${this.rangeLow},
            rangeHigh=${this.rangeHigh},
            basisUnit=${this.basisUnit},
            calcUnit=${this.calcUnit},
            calcMaxUnit=${this.calcMaxUnit},
            fixedMaxUnit=${this.fixedMaxUnit},
            calcCalcMaxUnit=${this.calcCalcMaxUnit}
        }`;
  }
}

export default ElectricalSubItem;
