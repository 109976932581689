import { useState } from 'react';
import { Auth } from 'aws-amplify';
import './Login.css';

import { CgProfile, CgRename } from 'react-icons/cg';
import { BsFillLockFill } from 'react-icons/bs';
// import { MdEmail } from 'react-icons/md'
// import { ImProfile } from 'react-icons/im'
// import { BiBuildings } from 'react-icons/bi'
import { MdPassword } from 'react-icons/md';
import { globals } from '../../_global/common/Utils';

function ForgotPassword(props) {
  const { onChange, state, onSuccess, handleClose, setLoadingStateFromChild } =
    props;
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');

  function validatePassword(new_password) {
    const minLength = /.{6,}/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    return (
      minLength.test(new_password) &&
      hasNumber.test(new_password) &&
      hasSpecialChar.test(new_password)
    );
  }

  const validateNewPasswordForm = () => {
    if (state.username.length == 0) {
      setError('Username is required');
      return false;
    }

    //Check if the username has a space or period
    if (state.username.includes(' ') || state.username.includes('.')) {
      setError('Username cannot contain spaces or periods');
      return false;
    }

    if (state.password.length == 0) {
      setError('Password is required');
      return false;
    } else if (state.password2.length == 0) {
      setError('Confirm password is required');
      return false;
    } else if (state.password !== state.password2) {
      setError('Passwords do not match');
      return false;
    } else if (!validatePassword(state.password)) {
      setError(
        'Password does not meet requirements:\nIt must be 6 characters long, contain a number and special character'
      );
      return false;
    }

    return true;
  };

  // Author: Guruprasad Venkatraman (01-04-2024)
  // Modified the forgot Password function to better handle the errors and also added a check for password validation
  // and passing the loading state to parent component to render the loading animation based on the props received.
  const forgotPasswordConfirm = async (event) => {
    event.preventDefault();
    if (validateNewPasswordForm()) {
      if (state.username.length === 0) {
        setError('Username required for Forgot Password');
      } else {
        try {
          setLoadingStateFromChild(true);
          await Auth.forgotPasswordSubmit(
            state.username,
            state.authCode,
            state.password
          );
          await Auth.signIn(state.username, state.password, {
            captcha: token,
          });
          let userinfo = await Auth.currentAuthenticatedUser();
          if(globals.debug) console.log('Successfully reset password in', userinfo);
          setLoadingStateFromChild(false);
          onSuccess({ user: userinfo, isSignedIn: true });
          handleClose();
        } catch (err) {
          if(globals.debug) console.log('Error:', err);
          if (err.code) {
            switch (err.code) {
              case 'UserNotConfirmedException':
                setError('Account not confirmed');
                break;
              case 'NotAuthorizedException':
                setError('Incorrect username or password');
                break;
              case 'UserNotFoundException':
                setError('User does not exist. Enter a valid username');
                break;
              case 'PasswordResetRequiredException':
                setError('Password reset required');
                break;
              default:
                setError('Error signing in');
                break;
            }
          }
          setLoadingStateFromChild(false);
        }
      }
    }
  };

  // Author: Guruprasad Venkatraman (01-04-2024)
  // Fixed an issue with resend Code where resendSignUp function was replaced.
  // Since resendSignUp will never resend the verification code as it checks for the user confimation status.
  // So I used the forgotPassword instead.
  const resendConfirmCode = async (event) => {
    if(globals.debug) console.log('Resending confirmation code to ' + state.username);
    try {
      await Auth.forgotPassword(state.username);
      setError('');
      setMessage('Successfully resent the confirmation code');
    } catch (err) {
      if(globals.debug) console.log('ERROR: could not resend confirmation code');
      setError('Could not resend confirmation code');
    }
  };

  const validateForm = (type) => {
    // if (type === 'auth')
    //   return (state.username.length > 0 && state.authCode.length > 0).toString();
    // else if (type === 'signup')
    //   return (state.email.length > 0 && state.given_name.length > 0 && state.family_name.length > 0 && state.role.length > 0).toString();
    return state.username.length > 0 && state.password.length > 0;
  };

  // Author: Guruprasad Venkatraman (01-04-2024)
  // Added confirm password field to ensure password correctness while resetting the password and removed loading bubble animation from this component.
  return (
    <div>
      {error !== '' && <div className="login-error">ERROR: {error}</div>}
      {message !== '' && <div className="login-message">{message}</div>}
      <form onSubmit={forgotPasswordConfirm}>
        <div className="inputContainer" size="lg">
          <MdPassword size={30} />
          <input
            className="text-input"
            autoFocus
            placeholder="Authentication Code"
            type="string"
            name="authCode"
            onChange={onChange}
          />
        </div>
        <div className="inputContainer" size="lg">
          <CgProfile size={30} />
          <input
            className="text-input"
            autoFocus
            type="username"
            name="username"
            placeholder="Username"
            // value={this.state.username}
            onChange={onChange}
          />
        </div>
        <div className="inputContainer" size="lg">
          <BsFillLockFill size={30} />
          <input
            className="text-input"
            autoFocus
            placeholder="New Password"
            type="password"
            name="password"
            onChange={onChange}
          />
        </div>
        <div className="inputContainer" size="lg">
          <BsFillLockFill size={30} />
          <input
            className="text-input"
            autoFocus
            placeholder="Confirm New Password"
            type="password"
            name="password2"
            onChange={onChange}
          />
        </div>
        <button
          className="login-btn"
          block
          size="lg"
          type="submit"
          disabled={!validateForm()}
        >
          Reset Password
        </button>
      </form>
      <button
        className="reset"
        block
        size="lg"
        type="button"
        onClick={() => {
          resendConfirmCode();
        }}
      >
        Resend Code
      </button>
    </div>
  );
}

export default ForgotPassword;
