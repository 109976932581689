import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';
import MedicationSubItem, {
  cloneMedicationSubItems,
} from './MedicationSubItem';
import {
  roundToDec,
  globals,
  getObjectDifference,
  isObjectInArray,
} from '../../ui/_global/common/Utils';
import {
  Drip,
  Medication,
  MedicationConcentration,
  MedicationProtocol,
  User,
} from '../../models';
import { ProgressStatus } from '../../API';

export const cloneMedication = (med: MedicationItem): MedicationItem => {
  if (med.dbMedication) {
    let newMed = new MedicationItem(med.dbMedication);
    newMed.medicationSubItems = cloneMedicationSubItems(
      newMed,
      med.medicationSubItems
    );
    return newMed;
  } else if (med.dbDrip) {
    let newMed = new MedicationItem(med.dbDrip);
    return newMed;
  } else
    throw new Error(
      'ERROR: Cannot clone a medication that is not a medication'
    );
};

export const getConcentrationString = (
  concen: MedicationConcentration
): string => {
  if (concen.firstAmnt <= 0) return 'N/A';

  let str = '';
  str += concen.firstAmnt + ' ' + concen.firstUnit;
  if (!concen.secAmnt || concen.secAmnt <= 0) return str;
  str += '/';
  if (concen.secAmnt !== 1.0) str += concen.secAmnt + ' ' + concen.secUnit;
  else str += concen.secUnit;

  return str;
};

class MedicationItem implements ModelItem {
  TAG = 'MedicationItem';
  uid: string;
  depID: string;
  name: string;
  dbMedication?: Medication;
  dbDrip?: Drip;
  concentrations: MedicationConcentration[];
  medicationSubItems: MedicationSubItem[];
  protocolOptions: MedicationProtocol[];
  routes: string[];
  version: string;
  rxNormCode: string | null | undefined;
  activeID: string | null | undefined;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  rangeLow: number;
  rangeHigh: number;
  modifiedBy: User | null | undefined;
  contraindication: string;
  warning: string;
  indication: string;
  interaction: string;
  onset: string;
  duration: string;
  note: string;
  medClass:string;
  action: string;





  // constructor(medication: Medication, parent: ProtocolItem, concentration: MedicationConcentration, option: MedicationOption, range: MedicationRange) {
  //     this.uid                = medication.id;
  //     this.name               = medication.name;
  //     this.parentProtocol     = parent;
  //     this.dbMedication       = medication;
  //     this.concentration      = concentration;

  //     this.title              = option.title;
  //     this.note               = range.note;
  //     this.instruction        = range.instruction;
  //     this.warning            = range.warning;
  //     this.routes             = range.route;
  //     this.rangeLow           = range.rangeLow;
  //     this.rangeHigh          = range.rangeHigh;

  //     this.basisUnit          = "";
  //     this.calcUnit           = "";
  //     this.maxDoseUnit        = "";
  //     this.administeredAmount = 0.0;
  //     this.basis              = -1;
  //     this.basisHigh          = -1
  //     this.maxDose            = -1;

  //     try {
  //         /* ---------------- Parse the data for the basis variables ---------------- */
  //         let basisArr : string[] = range.basis.split(" ", 2);

  //         /* Parse basis amount to a double for comparison */
  //         let basisNums : string[]  = basisArr[0].split("-");
  //         this.basis                = parseFloat(basisNums[0]);
  //         if (basisNums.length > 1) this.basisHigh = parseFloat(basisNums[1]);
  //         else                      this.basisHigh = globals.MAX_VALUE;

  //         /* Parse the units for the basis to validate calculations */
  //         let basisUnitsArr : string[] = basisArr[1].split("/");
  //         this.basisUnit = basisUnitsArr[0];
  //         if (basisUnitsArr.length > 1) {
  //             this.calcUnit = basisUnitsArr[1];
  //             if (this.calcUnit !== "kg")
  //                 if(globals.debug) console.log(this.TAG, "ERROR: Basis unit calculation is not kg -> " + this.calcUnit + " basis: " + range.basis);
  //         }

  //         /* ---------------- Parse the data for the max dose variables ---------------- */
  //         let maxDoseArr : string[] = range.maxDose.split(" ", 2);

  //         if(maxDoseArr.length != 2) {
  //             if(globals.debug) console.log(this.TAG, "ERROR: Max dose is not in the format of 'number units' -> " + range.maxDose + " length: " + maxDoseArr.length);
  //             this.maxDose = globals.MAX_VALUE;
  //             return;
  //         }

  //         /* Parse basis amount to a double for comparison */
  //         this.maxDose = parseFloat(maxDoseArr[0]);

  //         /* Parse the units for the basis to validate calculations */
  //         this.maxDoseUnit = maxDoseArr[1];
  //         if (this.maxDoseUnit !== this.basisUnit)
  //             if(globals.debug) console.log(this.TAG, "ERROR: Max dose unit does not match basis unit -> " + range.maxDose + " basis: " + this.basisUnit + " maxDose: " + this.maxDoseUnit);
  //     } catch (e){
  //         if(globals.debug) console.log(this.TAG, "ERROR: Failed to parse basis -> " + range.basis);
  //     }
  // }

  constructor(model: Medication | Drip) {
    // console.log('MEDICATION:', model.name, model.rxNormCode);
    this.uid = model.id;
    this.depID = model.departmentID;
    this.name = model.name;
    this.rxNormCode = model.rxNormCode;
    this.medicationSubItems = [];
    this.concentrations = model.concentration ? model.concentration : [];
    this.routes = model.route ? model.route : [];
    this.rangeLow = model.rangeLow ? model.rangeLow : 0;
    this.rangeHigh = model.rangeHigh ? model.rangeHigh : globals.MAX_VALUE;

    this.version = model.version ? model.version : 'v1.0.0';
    this.activeID = model.activeID;
    this.status = model.status != null ? model.status : ProgressStatus.ACTIVE;

    if ((model as any).protocolOptions != null) {
      this.dbMedication = model as Medication;
      this.protocolOptions = this.dbMedication.protocolOptions
        ? this.dbMedication.protocolOptions
        : [];
    } else if ((model as any).dripOptions != null) {
      this.dbDrip = model as Drip;
      this.protocolOptions = this.dbDrip.dripOptions
        ? this.dbDrip.dripOptions
        : [];
    } else {
      this.dbMedication = undefined;
      this.dbDrip = undefined;
      this.protocolOptions = [];
    }

    this.contraindication = model.contraindication ? model.contraindication : '';
    this.warning = model.warning ? model.warning : '';
    this.indication = model.indication ? model.indication : '';
    this.interaction = model.interaction ? model.interaction : '';
    this.onset = model.onset ? model.onset : '';
    this.duration = model.duration ? model.duration : '';
    this.note = model.note ? model.note : '';
    this.medClass = model.medClass ? model.medClass : '';
    this.action = model.action ? model.action : '';

  }

  // constructor(name: string){
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.name               = name;
  //     this.dbMedication       = null;
  //     this.medicationSubItems = [];
  //     this.concentrations     = [{
  //         firstAmnt: 10.0,
  //         firstUnit: "mg",
  //         secAmnt: 1,
  //         secUnit: "mL"
  //     }]

  // }

  /**
   * Return nothing for the parent
   * @param weight The patient's weight
   * @returns The amount to administer in liquid and solid form Ex. "1.5 mL (10.2 mg)"
   */
  getAmount(weight: PatientWeight): string {
    return '';
  }

  /**
   * Get the list of sub items for this medication
   * @returns Get the list of sub items for this medication
   */
  getMedicationSubItems(): MedicationSubItem[] {
    return this.medicationSubItems;
  }

  /**
   * Add a sub item to this medication
   * @param medicationSubItem Add a sub item to this medication
   */
  addMedicationSubItem(medicationSubItem: MedicationSubItem) {
    if (isObjectInArray(this.medicationSubItems, medicationSubItem)) return;
    this.medicationSubItems.push(medicationSubItem);
    this.medicationSubItems.sort((a, b) => a.index - b.index);
  }

  /**
   * Get the string representation of the concentration
   * @returns The string representation of the concentration Ex. "1 mg/mL"
   */
  getConcentrations(): MedicationConcentration[] {
    return this.concentrations;
  }

  /**
   * Get the medication model object
   */
  getModelMedication(): Medication | undefined {
    return this.dbMedication;
  }

  /**
   * Get the drip model object
   */
  getModelInfusion(): Drip | undefined {
    return this.dbDrip;
  }

  getProtocolOptions(): MedicationProtocol[] {
    if (this.dbMedication) return this.dbMedication.protocolOptions;
    else if (this.dbDrip) return this.dbDrip.dripOptions;
    return [];
  }

  /**
   * Compare this MedicationItem to another MedicationItem first by name then by concentration
   * @param other A MedicationItem to compare to
   * @returns 0 if the two items are equal, 1 if this item is greater than the other, -1 if this item is less than the other
   */
  compareTo(other: ModelItem): number {
    if (other instanceof MedicationItem) {
      let med = other as MedicationItem;
      if (this.name === med.name) {
        return this.uid === med.uid ? 0 : this.uid > med.uid ? 1 : -1;
      } else if (this.name > med.name) return 1;
      return -1;
    }
    return -1;
  }

  /**
   * Get if the medication is in range of the patient's weight
   * @param weight The patient's weight
   */
  inRange(weight: PatientWeight): boolean {
    return true;
  }

  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the department id of the owner of this category.
   * @returns the department id of the owner of this category
   */
  getDepID(): string {
    return this.depID;
  }

  getName(): string {
    return this.name;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  //Create a toString function to output the data in a readable format
  toString(): string {
    return `MedicationItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
            subItems=${this.medicationSubItems.length}
        }`;
  }
}

export default MedicationItem;
