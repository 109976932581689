import { useFormik } from 'formik';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { HiPlus, HiX } from 'react-icons/hi';
import * as Yup from 'yup';

type SnapshotModalProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  isReviewContext?: boolean;
  handleSnapshotSubmit?: () => void;
  handleCloseModals?: () => void;
};

/* 11-13-23 Arul: Created the SnapshotModal component for globally for review sideout */
const SnapshotModal: React.FC<SnapshotModalProps> = ({
  dialogVisible,
  setDialogVisible,
  headerText,
  isReviewContext,
  handleSnapshotSubmit,
  handleCloseModals,
}) => {
  const [description, setDescription] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const MAX_DESCRIPTION_LENGTH = 500;
  const MAX_TITLE_LENGTH = 100;

  const customHeader = (
    <div className="pointer">
      <h4 className="headerText" style={{ marginLeft: '-32px' }}>
        Create Snapshot
      </h4>
    </div>
  );

  /* 11-13-23 Arul: handling function for validation */
  const isCheck = useMemo(() => {
    if (title) {
      if (description) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }, [title, description]);

  return (
    <Dialog
      header={customHeader}
      visible={dialogVisible}
      style={{ width: '45vw', maxWidth: '750px' }}
      onHide={() => setDialogVisible(false)}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px 0px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '-5px',
              }}
            >
              <span className="headerTextMargin">
                Snapshot version code: 12{' '}
              </span>
            </div>
            <span className="contentText">
              Enter a title and description about the change so it can be
              referenced later for the user to understand why it was changed.
              This is different from a notification as the administration will
              only be able to see the description.
            </span>
          </div>
          <div className="p-inputgroup">
            <label
              htmlFor="title"
              className="p-inputgroup-addon-left"
              style={{ marginBottom: '24px', width: '15%' }}
            >
              <span style={{ marginRight: '24px', display: 'flex' }}>
                Title:<span className="required-field">*</span>
              </span>
            </label>
            <div className="input-container rangeContainer">
              <InputText
                id="title"
                name="title"
                required={true}
                value={title}
                onChange={(e: any) => setTitle(e.target.value)}
                placeholder=""
                className="dialogInput"
                maxLength={MAX_TITLE_LENGTH}
              />
              <div style={{ marginBottom: '0px', textAlign: 'right' }}>
                {title.length}/{MAX_TITLE_LENGTH}
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <label
              htmlFor="description"
              style={{ alignItems: 'flex-start' }}
              className="headerTextMargin"
            >
              Description:<span className="required-field">*</span>
            </label>
            <InputTextarea
              id="description"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
              placeholder=""
              className="dialogInput"
              style={{ height: '140px', verticalAlign: 'top' }} // Set a fixed height with a scrollbar
              maxLength={MAX_DESCRIPTION_LENGTH} // Set maximum character limit
            />
            <div
              style={{
                marginTop: '5px',
                textAlign: 'right',
                color:
                  description.length > MAX_DESCRIPTION_LENGTH ? 'red' : 'black',
              }}
            >
              {description.length}/{MAX_DESCRIPTION_LENGTH}
            </div>
          </div>
          <div className="dialogButtons" style={{ marginTop: '10px' }}>
            <Button
              className="secondary-button-dialog btn-rightMargin"
              style={{ width: '20%' }}
              onClick={() => setDialogVisible(false)}
            >
              {' '}
              <HiX /> Cancel
            </Button>
            <Button
              className="primary-button"
              style={{ width: '20%' }}
              disabled={!isCheck}
              onClick={handleSnapshotSubmit}
            >
              <HiPlus /> Create
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SnapshotModal;
