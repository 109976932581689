import React, { useEffect, useState } from 'react';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { HiPencil } from 'react-icons/hi';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { globals } from '../../../../_global/common/Utils';
import { useSelector } from 'react-redux';
import { User } from '../../../../../models';

interface MedicationDoseProps {
  dose: MedicationSubItem;
  doseIndex: number;
  showProtocol: boolean;
}
const MedicationDose: React.FC<MedicationDoseProps> = (props) => {
  const { dose, doseIndex, showProtocol } = props;
  const user: User = useSelector((state: any) => state?.user);

  return (
    <div>
      <div className="addDoseHeader">
        <span className="ketamine-general-label">
          Dose {doseIndex}
          {showProtocol ? ' - ' + dose.parentProtocol.name : ''}
        </span>
      </div>
      <div
        className={`model-input-container  ${'cursorPointerDiv hoverableBackground'}`}
      >
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Range Low
                </label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`form-control pointer ${!dose.rangeLow ? 'greyText' : ''}`}
                    id="rangeLow"
                    name="rangeLow"
                    placeholder="Min"
                    value={dose.rangeLow ? dose.rangeLow + ' kg' : ''}
                    data-testid="rangeLow"
                    readOnly={true}
                    disabled={true}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor={''}
                  className={` ketamine-label cursorPointerDiv`}
                >
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control highRange pointer ${Number(dose.rangeLow) === globals.MAX_VALUE ? 'greyText' : ''}`}
                    id="rangeHigh"
                    name="rangeHigh"
                    placeholder="Max"
                    data-testid="rangeHigh"
                    value={
                      dose.rangeHigh && dose.rangeHigh !== globals.MAX_VALUE
                        ? dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="ketamine-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <PiSyringe className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Calculation Basis
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      dose.range.basis === '-1' ? 'EMERGENCY' : dose.range.basis
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Min Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="minDose"
                    name="minDose"
                    placeholder="Min"
                    data-testid="minDose"
                    value={dose.range.minDose ? dose.range.minDose : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Max Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={dose.range.maxDose ? dose.range.maxDose : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {dose.range.basis !== '-1' && (
            <div className="sidebarInputContent">
              <label htmlFor={''} className={` rowLabelWidth sidebarLabel`}>
                Route:
              </label>
              <div
                data-div-id="outside"
                className={`selectionArea-hover selectionArea-disable`}
              >
                {dose.routes.length !== 0 && (
                  <ViewportList items={dose.routes}>
                    {(item, index) => {
                      return (
                        <div
                          data-div-id="grey"
                          key={index}
                          className={`selectedGreybox`}
                        >
                          <div>{item}</div>
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
                {user.type === 'ADMIN' && dose.nemsisRoutes.length !== 0 && (
                  <ViewportList items={dose.nemsisRoutes}>
                    {(item, index) => {
                      return (
                        <div
                          data-div-id="grey"
                          key={index}
                          className={`selectedGreybox`}
                        >
                          <div>{item.route + ' (' + item.code + ')'}</div>
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={` ${'sideoutFieldPad'}`}>
          {dose.title && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Title" className={`sidebarLabel `}>
                Title:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder="Enter Title here..."
                  value={dose.title}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.warning && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Warning" className={`sidebarLabel`}>
                Warning:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="warning"
                  name="warning"
                  data-testid="warning"
                  placeholder="Enter Warning here..."
                  value={dose.warning}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.instruction && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="instructions" className={`sidebarLabel`}>
                Instructions:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="instruction"
                  name="instruction"
                  data-testid="instruction"
                  placeholder="Add Instructions here..."
                  value={dose.instruction}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.note && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Note" className={`sidebarLabel`}>
                Note:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general`}
                  id="note"
                  name="note"
                  data-testid="note"
                  placeholder="Enter Note here..."
                  value={dose.note}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MedicationDose;
