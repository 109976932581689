import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { Button, Col, Row } from 'react-bootstrap';
import { Storage } from '@aws-amplify/storage';
import Loading from '../../../../components/Loading/Loading';
import { InputText } from 'primereact/inputtext';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import { globals, toTitleCase } from '../../../../_global/common/Utils';
import { FaTimes } from 'react-icons/fa';
import { Category, LazyCategory, User } from '../../../../../models';
import { useFormik } from 'formik';
import { ProgressStatus } from '../../../../../models';

import * as Yup from 'yup';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  CategoryJSON,
  createCategory,
} from '../../../../../data/functions/CategoryDB';
import './AIPdfParser.scss';
import { ViewportList } from 'react-viewport-list';
import CategoryItem from '../../../../../data/model/CategoryItem';

import DepartmentItem from '../../../../../data/model/DepartmentItem';
import Fuse from 'fuse.js';
import MedicationItem from '../../../../../data/model/MedicationItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  createProtocol,
  ProtocolDB,
  uploadInitialPDFToS3,
} from '../../../../../data/functions/ProtocolDB';
import { handleGetDepartment } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { BiRename } from 'react-icons/bi';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
interface Dose {
  type: string;
  name: string;
  dose: string;
  administrationRoutes: string;
  maxSingleDose: string;
  minSingleDose: string;
  maxTotalDose: string;
  repeatTime: string;
  repeatAllowed: string;
  ageGroup: string;
  weightLow: string;
  weightHigh: string;
  ageLow: string;
  ageHigh: string;
  title: string;
  instruction: string;
  warning: string;
  source: string;
}

interface MedicationData {
  file: string;
  index: number;
  doses: Dose[];
}

const AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});

const lambda = new AWS.Lambda();

const NEW_PROTOCOL = 0;
const COPY_PROTOCOL = 1;
const AIPdfParserProtocol = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [categories, setCategories] = useState<CategoryItem[]>(
    database.categories
  );
  const department = database.department;

  const [aiParsedData, setAIParsedData] = useState([]);
  const [categoryData, setCategoryData] = useState<CategoryItem | null>(null);
  const [protocolData, setProtocolData] = useState<any>(null);
  const [isNoDataModal, setIsNoDataModal] = useState(false);

  const [AIResultButton, setAIResultButton] = useState<boolean>(false);
  const [workbookURL, setWorkbookURL] = useState<string>('');

  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [dbMeds, setDbMeds] = useState<MedicationItem[]>([]);
  const [dbInfusions, setDbInfusions] = useState<MedicationItem[]>([]);

  const [loading, setLoading] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);
  const type: string =
    location.state && location.state.type ? location.state.type : 'Full';

  const [state, setState] = useState<any>({
    startPage:
      location.state && location.state.startPage
        ? location.state.startPage
        : '',
    endPage:
      location.state && location.state.endPage ? location.state.endPage : '',
    protocolName:
      location.state && location.state.protocolName
        ? location.state.protocolName
        : '',
    protocol: undefined as ProtocolItem | undefined,
    folder:
      location.state && location.state.folder ? location.state.folder : '',
    file: location.state && location.state.file ? location.state.file : null,
    pdf: null,
    pdfKey:
      location.state && location.state.pdfKey ? location.state.pdfKey : '',
    pairedDeps: [],
    isMadeProtocols: false,
    workbookURL:
      location.state && location.state.workbookURL
        ? location.state.workbookURL
        : '',
  });

  useEffect(() => {
    reloadDatabase();
  }, []);

  useEffect(() => {
    if (location.state) {
      setState({
        ...state,
        file: location.state.file,
        pdfKey: location.state.pdfKey,
        startPage: location.state.startPage,
        endPage: location.state.endPage,
      });
    }
    if (location.state && location.state.dbMeds) {
      setDbMeds(location.state.dbMeds);
    }
    if (location.state && location.state.dbInfusions) {
      setDbInfusions(location.state.dbInfusions);
    }
    if (location.state && location.state.aiParsedData) {
      console.log('location.state.aiParsedData', location.state.aiParsedData);
      setAIParsedData(location.state.aiParsedData);
    }
  }, [
    location.state,
    location.state.pdfKey,
    location.state.file,
    location.state.dbMeds,
    location.state.dbInfusions,
    location.state.startPage,
    location.state.endPage,
    location.state.aiParsedData,
  ]);

  console.log('PDF KEY', state.pdfKey);

  const objURL = useMemo(() => {
    if (state.file) return URL.createObjectURL(state.file);
    else return '';
  }, [state.file]);

  useEffect(() => {
    const fetchURL = async () => {
      return await Storage.get(state.workbookURL, {
        level: 'public',
      });
    };
    fetchURL().then((result) => {
      setWorkbookURL(result as string);
    });
  }, [state.workbookURL]);
  // const filteredList: CategoryItem[] = useMemo(() => {
  //   if (!state.name) return [];
  //   return database.categories.filter((folder) =>
  //     folder.name.toLowerCase().includes(state.name.toLowerCase())
  //   );
  // }, [state.name, database]);
  // Clean up the object URL when the component unmounts
  useEffect(() => {
    return () => {
      if (objURL) {
        URL.revokeObjectURL(objURL);
      }
    };
  }, [objURL]);

  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !state.pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, state.pairedDeps]);

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleStartPageChange = (e: any) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setState({ ...state, startPage: value });
    } else {
      setError('Please enter a valid  start page number');
    }
  };

  const handleEndPageChange = (e: any) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setState({ ...state, endPage: value });
    } else {
      setError('Please enter a valid end page number');
    }
  };

  const valid = () => {
    let {
      startPage,
      endPage,
      protocolName,
      protocol,
      startPageError,
      endPageError,
      protocolNameError,
    } = state;

    const startPageNum = Number(startPage);
    const endPageNum = endPage ? Number(endPage) : Number(startPage);
    if (protocolName === '' && protocol == null) {
      setError('Protocol cannot be empty');
      console.log('Protocol cannot be empty');
      return false;
    }
    if (
      isNaN(startPageNum) ||
      startPageNum < 1 ||
      isNaN(endPageNum) ||
      (endPageNum < 1 && endPageNum !== 0)
    ) {
      if (isNaN(startPageNum) || startPageNum < 1) {
        console.log('Start Page must be a valid number greater than 0');
        setError('Start Page must be a valid number greater than 0');
      }
      return false;
    }

    if (endPageNum !== 0) {
      if (startPageNum > endPageNum) {
        setError('End Page must be greater than or equal to Start Page');
        return false;
      }
    }
    return true;
  };

  const handleSplittingPDF = async () => {
    let { startPage, endPage, file, pdfKey, protocolName, errorText } = state;

    let id = pdfKey;
    if (globals.debug) console.log('id', id);
    if (!id) return;
    if (!valid()) {
      console.log('Invalid');
      return;
    }
    const url =
      process.env.REACT_APP_API_GATEWAY_URL ??
      (function () {
        throw new Error('API URL is not defined');
      })();
    const bucketName =
      process.env.REACT_APP_BUCKET_NAME ??
      (function () {
        throw new Error('BUCKET NAME is not defined');
      })();
    const apiUrl = url;

    const requestBody = {
      bucket: bucketName,
      key: `public/${id}`,
      startPage,
      endPage: endPage ? endPage : startPage,
      customName: `workBook-${startPage}-${endPage}`,
      depID: department.id,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (globals.debug) console.log('result', result);
      if (result.statusCode !== 200) {
        const errorMessages = JSON.parse(result.body);
        if (errorMessages.error === 'sequence index out of range') {
          setError('Error: Invalid page range. Please try again.');
        } else {
          setError('Error: Unknown error occured');
          console.error('Error:', errorMessages);
        }
      }

      const parsedBody = JSON.parse(result.body);
      if (globals.debug) console.log('parsedBody', parsedBody);
      const processedPdfUrl = parsedBody.processedPdfUrl;
      if (!processedPdfUrl) {
        setError('Error: No processed PDF URL');
      } else {
        const key = processedPdfUrl.split('.com/')[1];
        const keywWithoutPublic = key.split('public/')[1];
        const extractedWorkbook: any = await Storage.get(keywWithoutPublic, {
          level: 'public',
          contentType: 'application/pdf',
          download: true,
        });
        if (extractedWorkbook == null || extractedWorkbook.Body == null) {
          throw new Error('Error extracting workbook');
        }

        const fileUploaded = new File(
          [extractedWorkbook.Body],
          file ? file.name : 'fileName',
          {
            type: 'application/pdf',
          }
        );
        setError(null);
        return {
          key,
          processedPdfUrl: processedPdfUrl,
          fileUploaded: fileUploaded,
        };
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const isSimilar = (str1: string, str2: string, threshold = 0.8): boolean => {
    if (!str1 || !str2) return false;

    const fuse = new Fuse([str1], {
      includeScore: true,
      threshold: 1 - threshold, // Inverse the threshold for matching
      keys: [''],
    });

    const result = fuse.search(str2);
    return (
      result.length > 0 &&
      result[0].score !== undefined &&
      result[0].score <= 1 - threshold
    );
  };

  const isSimilarOrEmpty = (
    field1: string,
    field2: string,
    threshold = 0.8
  ): boolean => {
    if (!field1 || !field2) return true;

    if (field1.toLowerCase() === field2.toLowerCase()) return true;

    return isSimilar(field1, field2, threshold);
  };

  const handleDuplicate = (
    doses: Dose[],
    uniqueRows: { [key: string]: Dose[] }
  ): Dose[] => {
    if (globals.debug) console.log('uniqueRows', uniqueRows);
    const pageUniqueRows: { [key: string]: Dose[] } = {};
    const criticalFields = ['type', 'name', 'dose'];
    const subCriticalFields = [
      'administrationRoutes',
      'maxSingleDose',
      'minSingleDose',
      'maxTotalDose',
      'repeatTime',
      'repeatAllowed',
    ];
    const nonCriticalFields = [
      'ageGroup',
      'weightLow',
      'weightHigh',
      'ageLow',
      'ageHigh',
      'title',
      'instruction',
      'warning',
    ];

    const preprocessDose = (dose: any): string => {
      try {
        if (!dose) return dose;
        const doseStr = dose.toString(); // Convert to string
        const match = doseStr.match(/([\d\.]+)\s*([a-zA-Z\/]+)/);
        if (match) {
          const value = parseFloat(match[1]).toFixed(2);
          const unit = match[2].toLowerCase();
          return `${value} ${unit}`;
        }
      } catch (error) {
        console.error('Error preprocessing dose:', error);
      }
      return dose.toString();
    };

    doses.forEach((item) => {
      item.dose = preprocessDose(item.dose);
      item.maxSingleDose = preprocessDose(item.maxSingleDose);
      item.minSingleDose = preprocessDose(item.minSingleDose);
      item.maxTotalDose = preprocessDose(item.maxTotalDose);

      const criticalFieldValues = tupleToStringArray(item, criticalFields);
      const subCriticalFieldValues = tupleToStringArray(
        item,
        subCriticalFields
      );
      const nonCriticalFieldValues = tupleToStringArray(
        item,
        nonCriticalFields
      );
      const criticalFieldKey = criticalFieldValues.join('|'); // Join array into a single string using a separator

      if (!(criticalFieldKey in uniqueRows)) {
        uniqueRows[criticalFieldKey] = [item];
        pageUniqueRows[criticalFieldKey] = [item];
      } else {
        const existingItems: Dose[] = uniqueRows[criticalFieldKey];
        let isDuplicate = false;

        existingItems.forEach((existingItem) => {
          const existingSubCriticalFields = tupleToStringArray(
            existingItem,
            subCriticalFields
          );
          const existingNonCriticalFields = tupleToStringArray(
            existingItem,
            nonCriticalFields
          );

          // Check for partial match in administrationRoutes
          const adminRoutes = new Set([
            ...subCriticalFieldValues[0].split(','),
            ...existingSubCriticalFields[0].split(','),
          ]);
          const isAdminRoutesDuplicate = [...adminRoutes].some((route) =>
            existingSubCriticalFields[0].includes(route)
          );

          if (
            isAdminRoutesDuplicate &&
            subCriticalFields
              .slice(1)
              .every((_, idx) =>
                isSimilarOrEmpty(
                  existingSubCriticalFields[idx + 1],
                  subCriticalFieldValues[idx + 1]
                )
              )
          ) {
            isDuplicate = true;

            subCriticalFields.forEach((field, idx) => {
              if (!existingSubCriticalFields[idx]) {
                (existingItem as any)[field] = subCriticalFieldValues[idx];
              } else if (!subCriticalFieldValues[idx]) {
                (item as any)[field] = existingSubCriticalFields[idx];
              }
            });

            nonCriticalFields.forEach((field, idx) => {
              if (!existingNonCriticalFields[idx]) {
                (existingItem as any)[field] = nonCriticalFieldValues[idx];
              } else if (!nonCriticalFieldValues[idx]) {
                (item as any)[field] = existingNonCriticalFields[idx];
              }
            });

            // Combine administrationRoutes
            existingItem.administrationRoutes = [...adminRoutes].join(',');
            item.administrationRoutes = [...adminRoutes].join(',');

            existingItem.source = 'BOTH';
            item.source = 'BOTH';
          }
        });

        if (!isDuplicate) {
          uniqueRows[criticalFieldKey].push(item);
          pageUniqueRows[criticalFieldKey] = [item];
        }
      }
    });

    let updatedData: Dose[] = [];

    // Directly iterate over the entries of pageUniqueRows
    Object.entries(pageUniqueRows).forEach(([, items]) => {
      items.forEach((item) => {
        updatedData.push(item);
      });
    });

    console.log('updatedData', updatedData);

    return updatedData;
  };

  // Helper function to convert tuple fields to string array
  const tupleToStringArray = (item: any, fields: string[]): string[] => {
    return fields.map((field) => String(item[field] ?? ''));
  };

  const removeDuplicateByProtocol = (startPage: string, endPage: string) => {
    const startPageNum: number = Number(startPage);
    const endPageNum: number = endPage ? Number(endPage) : Number(startPage);
    const filteredData: MedicationData[] = (
      aiParsedData as MedicationData[]
    ).filter((item) => item.index >= startPageNum && item.index <= endPageNum);

    const finalJson: any[] = [];
    const uniqueRows: { [key: string]: Dose[] } = {};

    for (let i = 0; i < filteredData.length; i++) {
      const item = filteredData[i];
      const updatedData = handleDuplicate(item.doses, uniqueRows);

      finalJson.push({
        file: item.file,
        index: item.index,
        doses: updatedData,
      });
    }

    return finalJson;
  };

  const groupDataByType = (
    data: MedicationData[]
  ): { [key: string]: MedicationData[] } | undefined => {
    const groupedByType: { [key: string]: MedicationData[] } = {};

    data.forEach((item) => {
      const { file, index, doses } = item;
      doses.forEach((dose) => {
        const doseType = dose.type;
        if (!groupedByType[doseType]) {
          groupedByType[doseType] = [];
        }
        groupedByType[doseType].push({
          file,
          index,
          doses: [dose],
        });
      });
    });

    /* Make sure all the types are an empty array if it doesn't exist */
    const allTypes = ['Electrical', 'Infusion', 'Medication'];
    allTypes.forEach((type) => {
      if (!groupedByType[type]) {
        groupedByType[type] = [];
      }
    });

    let isDoses = false;
    Object.keys(groupedByType).forEach((key) => {
      if (groupedByType[key].length > 0) {
        isDoses = true;
      }
    });

    return isDoses ? groupedByType : undefined;
  };
  const handleExtractPDF = async () => {
    let { startPage, endPage, file, pdfKey, protocolName, errorText } = state;

    try {
      setLoading('Extracting PDFs...');
      const result = await handleSplittingPDF();
      if (result) {
        const { key, processedPdfUrl, fileUploaded } = result;
        setState({ ...state, pdf: fileUploaded });
        const aiParsedResult: MedicationData[] = removeDuplicateByProtocol(
          startPage,
          endPage
        );
        const groupByType = groupDataByType(aiParsedResult);
        console.log('aiParsedResult', aiParsedResult);
        console.log('groupByType', groupByType);
        // console.log(aiParsedResult);
        // console.log(typeof aiParsedResult);
        // console.log(typeof aiParsedResult.body);
        // console.log(JSON.parse(aiParsedResult.body));

        if (fileUploaded && aiParsedResult && groupByType) {
          setLoading(null);
          navigate('/actions/ai-pdf-parser/results', {
            state: {
              file: file,
              pdfKey: pdfKey, // Pass the file path if needed
              fileUploaded: fileUploaded, // Pass the file path if needed
              folder: state.folder,
              protocol: protocolName,
              startPage: startPage,
              endPage: endPage,
              data: {
                pages: groupByType.Electrical,
                newMedications: [],
              },
              database: database,
              dbMeds: dbMeds,
              dbInfusions: dbInfusions,
              aiParsedData: aiParsedData,
              allTypeData: {
                Electrical: groupByType.Electrical,
                Infusion: groupByType.Infusion,
                Medication: groupByType.Medication,
              },
              workbookURL: state.workbookURL,
            },
          });
        }
      } else {
        console.error('No result from handleSplittingPDF');
        setLoading(null);
      }
    } catch (error) {
      console.error('Error in handleExtractPDF:', error);
    } finally {
      setLoading(null);
    }
  };

  const handleBack = () => {
    navigate(`/actions/ai-pdf-parser`, {
      state: {
        file: state.file,
        workbookURL: state.workbookURL,
        type: type,
      },
    });
  };

  const handleFolderSelection = async (category: CategoryItem) => {
    // if (globals.debug) console.log('Cloning category', category);
    // setState({ ...state, copyCategory: category });
    // setShowDropdown(false);
    // //Populate the formik values
    // formik.setFieldValue('name', category.name);
    // let deps = [];
    // if (department.subDeps) {
    //   for (let i = 0; i < department.subDeps.length; i++) {
    //     if (
    //       category.pairedDepIDs &&
    //       category.pairedDepIDs.includes(department.subDeps[i].id)
    //     )
    //       deps.push(department.subDeps[i]);
    //   }
    // }
    // formik.setFieldValue('pairedDeps', deps);
    // setModalName('Append_Protocol');
    // setCategoryData(category);
  };
  // const handleSearch = (value: string) => {
  //   setShowDropdown(true);
  //   formik.setFieldValue('name', value);
  //   setModalName('New_Folder_Protocol');
  //   formik.setFieldValue('pairedDeps', department.subDeps);
  // };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...state.pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    // formik.setFieldValue('pairedDeps', l);
    setState({ ...state, pairedDeps: l });
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = state.pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    // formik.setFieldValue('pairedDeps', l);
    setState({ ...state, pairedDeps: l });
  };
  const handleClearDepartments = () => {
    // formik.setFieldValue('pairedDeps', []);
    setState({ ...state, pairedDeps: [] });
  };

  const handleProtocolFolderSubmit = async () => {
    const result = await handleSplittingPDF();

    let data = {
      value: state.name.trim(),
      nickName: state.nickName ? state.nickName.trim() : state.name.trim(),
      pdf: result?.fileUploaded,
      pairedDeps: state.pairedDeps,
    };

    // if (modalName === 'New_Folder_Protocol') {
    //   await handleAdd(data);
    // } else {
    //   setProtocolData(data);
    //   saveProtocolData(data);
    // }
  };

  const handleAdd = async (data: any) => {
    /* Add the new protocol */

    if (categoryData == null) {
      setLoading('Saving the Folder in DB.....');
      if (globals.debug)
        console.log('Creating new folder in the base folder', data);
      let ids = data.pairedDeps
        ? data.pairedDeps.map((dep: DepartmentItem) => dep.id)
        : [];
      let json: CategoryJSON = {
        name: data.value,
        status: ProgressStatus.DRAFT,
        index: categories.length,
        departmentID: database.department.id,
        pairedDeps: ids,
        activeID: null,
        version: 'v1.0.0',
        createdBy: user.id,
        isPublic: database.department.isPublic,
        keychainID: null,
      };

      try {
        const response = await createCategory(json);

        if (response.type === ResponseType.Success) {
          setCategoryData(response.data);
          setProtocolData(data);
          // saveProtocolData(data);
          //reloadDatabase();
        } else {
          console.error('ERROR CREATING CATEGORY', response.data);
        }
      } catch (error) {
        console.error('ERROR CREATING CATEGORY', error);
      }
    }
    setLoading(null);
  };

  const handleContinue = async () => {
    let { file, folder, protocol, protocolName, startPage, endPage, pdfKey } =
      state;

    setLoading('Extracting PDFs...');
    /* 1. First split the PDF to get the pages */
    let result: any = await handleSplittingPDF();
    if (result == null) {
      console.error('ERROR SPLITTING PDF');
      setLoading(null);
      return;
    }
    let { fileUploaded, key, processedPdfUrl } = result;

    if (fileUploaded == null) {
      console.error('fileUploaded is null ERROR SPLITTING PDF');
      return;
    }
    setLoading('Creating Protocol.....');
    /* 2. Create the new protocol */
    let newProtocol: ProtocolItem | undefined;
    if (protocol == null) {
      newProtocol = await handleCreateProtocol(
        folder,
        protocolName,
        fileUploaded,
        state.pairedDeps
      );
      if (newProtocol == null) {
        console.error('ERROR CREATING PROTOCOL');
        return;
      }
    } else newProtocol = protocol;

    setLoading('Extracting AI Data..');
    /* 3. Save the AI Data */
    let aiParsedResult: MedicationData[] | undefined = undefined;
    let groupByType: { [key: string]: MedicationData[] } | undefined =
      undefined;
    if (type === 'Full') {
      aiParsedResult = removeDuplicateByProtocol(startPage, endPage);
      groupByType = groupDataByType(aiParsedResult);
      console.log('aiParsedResult', aiParsedResult);
      console.log('groupByType', groupByType);
    } else {
    }
    if (groupByType == null) {
      console.log('NO DATA FOUND');
      if (type === 'Full') setIsNoDataModal(true);
      setLoading(null);
    } else if (fileUploaded && aiParsedResult) {
      setLoading(null);
      navigate('/actions/ai-pdf-parser/results', {
        state: {
          file: file,
          pdfKey: pdfKey,
          fileUploaded: fileUploaded, // Pass the file path if needed
          folder: folder,
          protocol: newProtocol,
          startPage: startPage,
          endPage: endPage,
          database: database,
          dbMeds: dbMeds,
          dbInfusions: dbInfusions,
          aiParsedData: aiParsedData,
          allTypeData: {
            Electrical: groupByType.Electrical,
            Infusion: groupByType.Infusion,
            Medication: groupByType.Medication,
          },
          workbookURL: state.workbookURL,
        },
      });
    }
  };

  const handleCreateProtocol = async (
    parent: CategoryItem,
    name: string,
    pdf: File,
    pairedDeps: DepartmentItem[]
  ): Promise<ProtocolItem | undefined> => {
    try {
      // not adding else since its common for both cases
      setLoading('Saving the Protocol in DB.....');
      if (parent == null || name == null || pdf == null) {
        console.error(
          'ERROR CREATING PROTOCOL: MISSING DATA',
          parent,
          name,
          pdf
        );
        return;
      }
      /* Upload the PDF to the S3 bucket */
      let result: Response = await uploadInitialPDFToS3(
        database.department,
        parent,
        name,
        pdf,
        (error: any) => {
          console.error('ERROR UPLOADING PDF TO S3', error);
        }
      );
      if (result.type === ResponseType.Failure) {
        console.error('ERROR UPLOADING PDF TO S3', result.data);
        return;
      }

      let pdfID: string = result.data;

      /* Create the new protocol item */
      let json: ProtocolDB = {
        departmentID: database.department.id,
        name: name,
        nickname: name,
        pdfID: pdfID,
        rangeLow: 0,
        rangeHigh: globals.MAX_VALUE,
        parentCategory: parent,
        pairedDeps: pairedDeps,
        pairedProtocols: [],
        medications: [],
        infusions: [],
        equipment: [],
        electrical: [],
        forms: [],
        createdBy: user.id,
        status: ProgressStatus.DRAFT,
        activeID: null,
        version: 'v1.0.0',
        pdfVersion: 'v1.0.0',
        index: parent.protocols.length,
        isPublic: department.isPublic,
        keychainID: null,
      };

      /* Create the protocol item */

      let results: Response = await createProtocol(json);
      if (results.type === ResponseType.Success) {
        let newProtocol: ProtocolItem = results.data;
        if (globals.debug) console.log('CREATED DRAFT PROTOCOL:', newProtocol);
        return newProtocol;

        /* Reload the database -- OR use the subscribe method */
        //reloadDatabase();
        setAIResultButton(true);
      } else {
        if (globals.debug)
          console.log('ERROR CREATING DRAFT PROTOCOL:', results.data);
      }

      setLoading(null);
    } catch (error) {
      console.error('ERROR CREATING PROTOCOL', error);
    }
  };

  // const handleSave = async () => {};

  const isSaveActive = useMemo(() => {
    return (
      state.startPage && (state.protocolName || state.protocol) && state.folder
    );
  }, [state]);

  return (
    <div className="screen-container">
      {loading !== null && <Loading type="bubbles" message={loading} />}
      <ProtocolHeader
        page={'AI PDF Parser'}
        name={'Extract Protocol'}
        description={
          'To extract protocol from the workbook either enter the page number to extract one page or a range to extract multiple pages.'
        }
        isBackButton={true}
        type={'protocol'}
        isCancelButton={true}
        isCustomButton={true}
        handleCancelEdit={() => {
          handleBack();
        }}
        isCustomActive={isSaveActive}
        customText="Continue"
        // rightSideBtn={'add'}
        handleCustom={() => {
          handleContinue();
        }}
        handleCancel={handleBack}
      />

      <ConfirmModal
        isVisible={isNoDataModal}
        title={'Successfully Created Protocol: ' + state.protocolName}
        handleClose={() => {
          setIsNoDataModal(false);
        }}
        handleSubmit={() => {
          setIsNoDataModal(false);
          setState({
            ...state,
            startPage:
              Number(state.endPage ? state.endPage : state.startPage) + 1 + '',
            endPage: '',
            protocolName: '',
            protocol: undefined,
            pdf: null,
            pairedDeps: [],
          });
        }}
        isDeleteBtn={false}
        isSingleBtn={true}
        primaryBtnName={'Continue'}
        secondaryBtnName={'Continue'}
        primaryDescription={
          'The protocol has been successfully created. It has no additional medications, infusions, electricals, or equipment items to add.'
        }
      />

      <div className="content-container">
        <div className="left-panel">
          <div
            style={{
              flex: 1,
              padding: '20px',
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'column',
              // border: '1px solid #e0e0e0',
              // borderRadius: '5px',
              height: '100%',
            }}
            className="ketamine-general-label"
          >
            <div
              className="calculationDialogContent"
              style={{ marginBottom: '0px' }}
            >
              <label
                htmlFor="reuseProtocol"
                className="departmentItemText"
                style={{ flexDirection: 'row' }}
              >
                Reuse Protocol
                <InputSwitch
                  name="reuseProtocol"
                  style={{ marginLeft: '10px' }}
                  checked={state.isMadeProtocols}
                  onChange={(e) => {
                    setState({ ...state, isMadeProtocols: e.value });
                  }}
                />
              </label>
            </div>
            <Row>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: 'auto',
                    marginTop: '10px',
                  }}
                >
                  <label
                    htmlFor="protocolName"
                    className="ketamine-general-label"
                  >
                    Find Folder<span className="required-field">*</span>
                  </label>
                  <SearchableDropdown<CategoryItem>
                    id="searchDropdown"
                    value={state.folder}
                    onChange={(option) => {
                      setState({ ...state, folder: option });
                    }}
                    onClear={() => {
                      setState({ ...state, folder: '' });
                    }}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    options={database.categories}
                    placeholder="Search Folder..."
                    containerStyle={{ width: '100%' }}
                    autoFocus={state.folder == null}
                    onCreate={(e) => {}}
                  />
                  {/* <SearchBar
                  value={state.name}
                  onChange={handleSearch}
                  placeholder="Search"
                  containerStyle={{ width: '100%' }}
                  autoFocus={true}
                  onBlur={() => formik.setFieldTouched('name', true)}
                />
                {showDropdown && filteredList.length > 0 && (
                  <div className="contentBorder protocolCalculationPad">
                    <ViewportList items={filteredList}>
                      {(item: CategoryItem, index: any) => (
                        <div
                          key={item?.name + index}
                          onClick={() => {
                            handleFolderSelection(item as CategoryItem);
                          }}
                          className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight`}
                          style={{
                            borderBottom:
                              index === filteredList.length - 1
                                ? 'none'
                                : '1px solid #ccc',
                          }}
                        >
                          {item.name}
                        </div>
                      )}
                    </ViewportList>
                  </div>
                )} */}
                </div>

                <label
                  htmlFor="protocolName"
                  className="ketamine-general-label"
                >
                  Protocol{state.isMadeProtocols ? '' : ' Name'}
                  <span className="required-field">*</span>
                  {!state.isMadeProtocols && (
                    <BiRename
                      className="refresh-icon"
                      onClick={() => {
                        setState({
                          ...state,
                          protocolName: toTitleCase(state.protocolName),
                        });
                      }}
                      size="1.25rem"
                      style={{ marginRight: '5px', cursor: 'pointer' }}
                    />
                  )}
                </label>
                {state.isMadeProtocols ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: 'auto',
                      marginTop: '10px',
                    }}
                  >
                    <SearchableDropdown<ProtocolItem>
                      id="searchDropdown"
                      value={state.protocol}
                      onChange={(option) => {
                        setState({ ...state, protocol: option });
                      }}
                      onClear={() => {
                        setState({ ...state, protocol: '' });
                      }}
                      labelField={(option) => option.name}
                      valueField={(option) => option.name}
                      options={database.protocols}
                      placeholder="Search Protocol..."
                      containerStyle={{ width: '100%' }}
                    />
                  </div>
                ) : (
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="name"
                      name="protocol"
                      data-testid="protocol"
                      value={state.protocolName}
                      placeholder="Enter Protocol Name"
                      onChange={(e) => {
                        setState({
                          ...state,
                          protocolName: e.target.value,
                          protocolNameError: '',
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleContinue();
                        }
                      }}
                    />
                  </div>
                )}
              </Col>
            </Row>
            {error && (
              <span
                style={{
                  color: 'red',
                  alignSelf: 'flex-start',
                  marginLeft: '10px',
                }}
              >
                Error: {error}
              </span>
            )}

            <Row>
              <Col sm={6}>
                <label htmlFor="startPage" className="ketamine-general-label">
                  Start Page <span className="required-field">*</span>
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontWeight: '500' }}
                >
                  This is inclusive of the page number.
                </span>

                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="name"
                    name="startPage"
                    data-testid="startPage"
                    value={state.startPage}
                    placeholder="Starting Page Number"
                    onChange={handleStartPageChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleContinue();
                      }
                    }}
                  />
                  <div className="input-border"></div>
                </div>
              </Col>
              <Col sm={6}>
                <label htmlFor="endPage" className="ketamine-general-label">
                  End Page
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontWeight: '500' }}
                >
                  Optional: This is inclusive of the page number.
                </span>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="name"
                    name="endPage"
                    data-testid="endPage"
                    value={state.endPage}
                    placeholder="Optional"
                    onChange={handleEndPageChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleContinue();
                      }
                    }}
                  />

                  <div className="input-border"></div>
                </div>
              </Col>
            </Row>
            <div
              style={{
                marginBottom: '20px',
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '10px',
              }}
            >
              {Number(state.startPage) > 0 &&
                !isNaN(Number(state.startPage)) && (
                  <label
                    htmlFor="endPage"
                    className="ketamine-general-label"
                    style={{ marginBottom: '0px' }}
                  >
                    {`Extracting ${state.endPage && Number(state.endPage) > Number(state.startPage) ? 'Pages' : 'Page'}: ${state.startPage}${state.endPage && Number(state.endPage) > Number(state.startPage) ? ' - ' + state.endPage : ''} (${state.endPage && Number(state.endPage) > Number(state.startPage) ? Number(state.endPage) - Number(state.startPage) + 1 + ' pages' : '1 page'})`}
                  </label>
                )}
            </div>

            {department &&
              department.subDeps &&
              department.subDeps.length > 0 && (
                <>
                  <label htmlFor="" className={`notification-css-title`}>
                    <span
                      className="headerTextMargin"
                      style={{ fontSize: '16px', marginTop: '10px' }}
                    >
                      Subscribed Departments: {state.pairedDeps.length} /{' '}
                      {department.subDeps.length}
                      <span
                        onClick={() => {
                          if (department.subDeps)
                            setState({
                              ...state,
                              pairedDeps: [...department.subDeps],
                            });
                        }}
                      >
                        <div className="clickableText">Add All</div>
                      </span>
                    </span>
                  </label>
                  <span
                    className="contentText greyText"
                    style={{ fontSize: '13px', marginLeft: '10px' }}
                  >
                    This is a list of every department that will subscribe to
                    the protocol.
                  </span>
                  <SearchableDropdown<DepartmentItem>
                    id="searchDropdown"
                    options={departmentList}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    multiSelect={true}
                    onChange={(option: DepartmentItem) =>
                      handleAddDepartment(option)
                    }
                    onClear={handleClearDepartments}
                    placeholder="Search department..."
                  />
                  {state.pairedDeps.length === 0 && (
                    <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                      No paired departments...
                    </h6>
                  )}
                  <div
                    style={{
                      border:
                        state.pairedDeps.length === 0
                          ? '0px'
                          : '1px solid #ccc',
                      borderRadius: '5px',
                      marginBottom: '20px',
                      marginTop: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ViewportList items={state.pairedDeps}>
                      {(item: DepartmentItem, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '16fr 1fr',
                            padding: '6px 10px',
                            alignItems: 'center',
                            borderBottom:
                              index === state.pairedDeps.length - 1
                                ? ''
                                : '1px solid #ccc',
                          }}
                          className="listItem"
                        >
                          <div className="contentText">{item.name}</div>
                          <FaTimes
                            className="icon-cancel"
                            size={16}
                            onClick={(e) => handleRemoveDepartment(item, e)}
                          />
                        </div>
                      )}
                    </ViewportList>
                  </div>
                </>
              )}
            <div
              style={{
                display: 'flex',
                marginTop: '10px',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* <Button
                className="primary-button"
                style={{
                  flex: 1,
                  marginRight: '10px', // Add margin to the right of the first button for spacing
                  borderRadius: '5px',
                }}
                onClick={() => {
                  handleProtocolFolderSubmit();
                }}
              >
                Save Protocol and Folder
              </Button> */}

              {/* <Button
                className="primary-button"
                style={{
                  flex: 1,
                  marginRight: '10px', // Add margin to the right of the first button for spacing
                  borderRadius: '5px',
                }}
                onClick={() => {
                  handleExtractPDF();
                }}
                disabled={!AIResultButton}
              >
                Get AI Results
              </Button> */}
            </div>
          </div>
        </div>
        <div className="right-panel">
          {objURL ? (
            <embed
              title="PDF Document"
              type="application/pdf"
              src={objURL}
              style={{ height: '100%', width: '100%' }}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AIPdfParserProtocol;
