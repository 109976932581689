import { DataStore } from '@aws-amplify/datastore';
import {
  Category,
  Department,
  Drip,
  Electrical,
  Equipment,
  Medication,
  Protocol,
  Vitals,
  Form,
  MedicationProtocol,
  User,
  Contact,
  InputForm,
  ElectricalShock,
  Workbook,
  WeightObject,
} from '../models';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import DepartmentItem from './model/DepartmentItem';
import { Notification } from '../models';
import { Response, ResponseType } from './AmplifyDB';
import { globals } from '../ui/_global/common/Utils';

export const copyVitalsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const vitals = await DataStore.query(Vitals, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );

    let i = 0,
      n = vitals.length;
    for (const vital of vitals) {
      const newVital = new Vitals({
        title: vital.title,
        departmentID: departmentTo.id,
        options: vital.options,
        status: vital.status,
        version: 'v1.0.0',
        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newVital);
      if (response === null) {
        console.error('copyVitalsFromDeptoDep: ', response);
      }
      if (globals.debug)
        if (globals.debug)
          console.log(
            'Copied Vitals: ',
            i++,
            ' / ',
            n,
            ' -> ',
            departmentTo.name
          );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyEquipmentFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const equipments = await DataStore.query(Equipment, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );
    let i = 0,
      n = equipments.length;
    for (const equipment of equipments) {
      const newEquipment = new Equipment({
        name: equipment.name,
        departmentID: departmentTo.id,
        optionItems: equipment.optionItems,
        note: equipment.note,
        warning: equipment.warning,
        title: equipment.title,
        instruction: equipment.instruction,
        version: 'v1.0.0',
        status: equipment.status,

        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newEquipment);
      if (response === null) {
        console.error('copyEquipmentFromDeptoDep: ', response);
      }
      if (globals.debug)
        if (globals.debug)
          console.log(
            'Copied Equipment: ',
            i++,
            ' / ',
            n,
            ' -> ',
            departmentTo.name
          );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyElectricalFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const electricals = await DataStore.query(ElectricalShock, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );
    let i = 0,
      n = electricals.length;
    for (const electrical of electricals) {
      const newElectrical = new ElectricalShock({
        departmentID: departmentTo.id,
        title: electrical.title,
        options: [],
        rxNormCode: electrical.rxNormCode,
        warning: electrical.warning,
        instruction: electrical.instruction,
        note: electrical.note,
        rangeHigh: electrical.rangeHigh,
        rangeLow: electrical.rangeLow,
        createdBy: 'OneBot',
        metaData: electrical.metaData,
        status: electrical.status,
        version: 'v1.0.0',
      });
      let response = await DataStore.save(newElectrical);
      if (response === null) {
        console.error('copyElectricalFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Electrical: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyMedicationFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department,
  includeDoses: boolean
): Promise<Response> => {
  try {
    const medications = await DataStore.query(Medication, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );
    let i = 0,
      n = medications.length;
    for (const med of medications) {
      const newMed = new Medication({
        departmentID: departmentTo.id,
        name: med.name,
        contraindication: med.contraindication,
        note: med.note,
        warning: med.warning,
        route: med.route,
        concentration: med.concentration,
        medClass: med.medClass,
        action: med.action,
        indication: med.indication,
        interaction: med.interaction,
        onset: med.onset,
        duration: med.duration,
        protocolOptions: includeDoses ? med.protocolOptions : [],
        status: med.status,
        version: 'v1.0.0',
        rangeLow: med.rangeLow,
        rangeHigh: med.rangeHigh,

        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newMed);
      if (response === null) {
        console.error('copyMedicationFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Medication: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyInfusionsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const infusions = await DataStore.query(Drip, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );
    let i = 0,
      n = infusions.length;
    for (const drip of infusions) {
      const newDrip = new Drip({
        departmentID: departmentTo.id,
        name: drip.name,
        dripOptions: drip.dripOptions,
        contraindication: drip.contraindication,
        note: drip.note,
        warning: drip.warning,
        route: drip.route,
        concentration: drip.concentration,
        medClass: drip.medClass,
        action: drip.action,
        indication: drip.indication,
        interaction: drip.interaction,
        onset: drip.onset,
        duration: drip.duration,
        status: drip.status,
        version: 'v1.0.0',
        rangeLow: drip.rangeLow,
        rangeHigh: drip.rangeHigh,
        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newDrip);
      if (response === null) {
        console.error('copyInfusionsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Infusion: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyCategoriesAndProtocolFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    let newCategories: Category[] = [];
    const categories = await DataStore.query(Category, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );
    let i = 0,
      n = categories.length;
    for (const cat of categories) {
      const newCat = new Category({
        departmentID: departmentTo.id,
        name: cat.name,
        index: cat.index,
        status: cat.status,
        version: 'v1.0.0',
        createdBy: 'OneBot',
        isPublic: cat.isPublic,
      });

      let response = await DataStore.save(newCat);
      if (response === null) {
        console.error('copyCategoriesAndProtocolFromDeptoDep: ', response);
      }
      newCategories.push(newCat);
      if (globals.debug)
        console.log(
          'Copied Category: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }

    i = 0;
    n = categories.length;
    let n2 = 0;
    for (let i = 0; i < categories.length; i++) {
      let oldCat = categories[i];
      let newCat = newCategories[i];
      const protocols = await DataStore.query(Protocol, (c) =>
        c.and((c) => [c.categoryID.eq(oldCat.id), c.status.eq('ACTIVE')])
      );
      let j = 0,
        m = protocols.length;
      n2 += m;
      for (const prot of protocols) {
        const newProt = new Protocol({
          departmentID: departmentTo.id,
          categoryID: newCat.id,
          name: prot.name,
          nickname: prot.nickname,
          pairedDepIDs: prot.pairedDepIDs,
          index: prot.index,
          pairedProtocols: prot.pairedProtocols,
          medicationIDs: prot.medicationIDs,
          equipmentIDs: prot.equipmentIDs,
          electricalIDs: prot.electricalIDs,
          dripIDs: prot.dripIDs,
          formIDs: prot.formIDs,
          pdfID: prot.pdfID,
          rangeHigh: prot.rangeHigh,
          rangeLow: prot.rangeLow,
          status: prot.status,
          version: 'v1.0.0',
          createdBy: 'OneBot',
          isPublic: prot.isPublic,
        });
        let response = await DataStore.save(newProt);
        if (response === null) {
          console.error('copyCategoriesAndProtocolFromDeptoDep: ', response);
        }
        if (globals.debug)
          console.log(
            'Copied Protocol: ',
            j++,
            ' / ',
            m,
            ' -> ',
            departmentTo.name
          );
      }
      if (globals.debug)
        console.log(
          'Copied Protocols from Category: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: [n, n2],
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyChecklistsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const forms = await DataStore.query(Form, (c) =>
      c.and((c) => [
        c.departmentID.eq(departmentFrom.id),
        c.status.eq('ACTIVE'),
      ])
    );
    let i = 0,
      n = forms.length;
    for (const form of forms) {
      const newForm = new Form({
        departmentID: departmentTo.id,
        name: form.name,
        items: form.items,
        status: form.status,
        version: 'v1.0.0',
        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newForm);
      if (response === null) {
        console.error('copyChecklistsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Checklist: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyNotificationsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department,
  pairedDepIDs: string[]
): Promise<Response> => {
  try {
    const notifications = await DataStore.query(Notification, (c) =>
      c.and((c) => [c.departmentID.eq(departmentFrom.id)])
    );
    let i = 0,
      n = notifications.length;
    for (const not of notifications) {
      const newNotification = new Notification({
        departmentID: departmentTo.id,
        type: not.type,
        title: not.title,
        message: not.message,
        timestamp: new Date().toISOString(),
        deadlineTimestamp: not.deadlineTimestamp,
        isReadIDs: [],
        isAckIDs: [],
        imageURLs: not.imageURLs,
        videoURLs: not.videoURLs,
        fileURLs: not.fileURLs,
        taggedProtocols: not.taggedProtocols,
        questions: not.questions,
        isPush: false,
        pairedDepIDs: pairedDepIDs,
        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newNotification);
      if (response === null) {
        console.error('copyNotificationsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Notifications: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const replaceOldIDsToNew = async (
  protocols: ProtocolItem[],
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    let newMeds = await DataStore.query(Medication, (c) =>
      c.departmentID.eq(departmentTo.id)
    );
    let newDrips = await DataStore.query(Drip, (c) =>
      c.departmentID.eq(departmentTo.id)
    );

    let oldMeds = await DataStore.query(Medication, (c) =>
      c.departmentID.eq(departmentFrom.id)
    );
    let oldDrips = await DataStore.query(Drip, (c) =>
      c.departmentID.eq(departmentFrom.id)
    );

    let n = 0;
    for (let i = 0; i < oldMeds.length; i++) {
      let oldMed = oldMeds[i];
      let newMed = newMeds.find((med) => med.name === oldMed.name);
      if (newMed == null) continue;

      /* Search through all protocols and update the medication IDs */
      for (let j = 0; j < protocols.length; j++) {
        let protocol = protocols[j];
        let medIDs = protocol.getModel().medicationIDs;
        if (medIDs) {
          let index = medIDs.indexOf(oldMed.id);
          if (index !== -1) {
            let newMedIDs = [...medIDs];
            newMedIDs = newMedIDs.filter((id) => id !== oldMed.id);
            newMedIDs[index] = newMed.id;
            let response = await DataStore.save(
              Protocol.copyOf(protocol.getModel(), (updated) => {
                updated.medicationIDs = newMedIDs;
              })
            );
            if (globals.debug)
              console.log('replaced medID: ', oldMed.name, ' -> ', newMed.name);
            if (response === null) {
              console.error('replaceOldIDsToNew: ', response);
            }
            n++;
          }
        }
      }
    }

    for (let i = 0; i < oldDrips.length; i++) {
      let oldDrip = oldDrips[i];
      let newDrip = newDrips.find((drip) => drip.name === oldDrip.name);
      if (newDrip == null) continue;

      /* Search through all protocols and update the medication IDs */
      for (let j = 0; j < protocols.length; j++) {
        let protocol = protocols[j];
        let dripIDs = protocol.getModel().dripIDs;
        if (dripIDs) {
          let index = dripIDs.indexOf(oldDrip.id);
          if (index !== -1) {
            let newDripIDs = [...dripIDs];
            newDripIDs = newDripIDs.filter((id) => id !== oldDrip.id);
            newDripIDs[index] = newDrip.id;
            let response = await DataStore.save(
              Protocol.copyOf(protocol.getModel(), (updated) => {
                updated.dripIDs = newDripIDs;
              })
            );
            if (globals.debug)
              console.log(
                'replaced dripID: ',
                oldDrip.name,
                ' -> ',
                newDrip.name
              );
            if (response === null) {
              console.error('replaceOldIDsToNew: ', response);
            }
            n++;
          }
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const replaceOldIdToNewProtocol = async (
  newProtocol: ProtocolItem,
  oldProtocol: ProtocolItem,
  department: Department
): Promise<Response> => {
  try {
    let count = 0;
    /* Load all the medications and drips*/
    const medications = await DataStore.query(Medication, (c) =>
      c.departmentID.eq(department.id)
    );
    const drips = await DataStore.query(Drip, (c) =>
      c.departmentID.eq(department.id)
    );

    /* Search for the medication and drips in the old protocol ID in the MedicationProtocol and MedicationRange tables */
    for (let i = 0; i < medications.length; i++) {
      const med = medications[i];
      let medProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < med.protocolOptions.length; i++) {
        const medProt = med.protocolOptions[i];
        if (medProt.protocolID === oldProtocol.uid) {
          /* Create a new MedicationProtocol with the new protocol ID */
          const newMedProt = new MedicationProtocol({
            protocolID: newProtocol.uid,
            options: medProt.options,
          });
          count++;
          isChanged = true;
          medProts.push(newMedProt);
        } else medProts.push(medProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newMed = Medication.copyOf(med, (updated) => {
          updated.protocolOptions = medProts;
        });
        let response = await DataStore.save(newMed);
        if (response === null) {
          console.error('replaceOldIdToNewProtocol: ', response);
        }
      }
    }

    /* Search for the medication and drips in the old protocol ID in the MedicationProtocol and MedicationRange tables */
    for (let i = 0; i < drips.length; i++) {
      const drip = drips[i];
      let dripProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < drip.dripOptions.length; i++) {
        const dripProt = drip.dripOptions[i];
        if (!dripProt) continue;
        if (dripProt.protocolID === oldProtocol.uid) {
          /* Create a new MedicationProtocol with the new protocol ID */
          const newMedProt = new MedicationProtocol({
            protocolID: newProtocol.uid,
            options: dripProt.options,
          });
          count++;
          isChanged = true;
          dripProts.push(newMedProt);
        } else dripProts.push(dripProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newDrip = Drip.copyOf(drip, (updated) => {
          updated.dripOptions = dripProts;
        });
        let response = await DataStore.save(newDrip);
        if (response === null) {
          console.error('replaceOldIdToNewProtocol: ', response);
        }
      }
    }

    /* Search through the departments medications and update the IDs */

    return {
      type: ResponseType.Success,
      data: count,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const setDepartmentProtocolOwners = async (
  department: DepartmentItem,
  ownerList: DepartmentItem[],
  protocolList: ProtocolItem[]
): Promise<Response> => {
  try {
    const ownerIDs = ownerList.map((owner: DepartmentItem) => owner.id);
    let i = 0,
      n = protocolList.length;
    for (const protocol of protocolList) {
      const dbProt = await DataStore.query(Protocol, protocol.uid);
      if (dbProt == null) {
        console.error('setDepartmentProtocolOwners: ', dbProt);
        return {
          type: ResponseType.Failure,
          data: dbProt,
        };
      }

      const newProtocol = Protocol.copyOf(dbProt, (updated) => {
        updated.pairedDepIDs = ownerIDs;
      });

      let response = await DataStore.save(newProtocol);

      if (response === null) {
        console.error('setDepartmentProtocolOwners: ', response);
        return {
          type: ResponseType.Failure,
          data: response,
        };
      }

      if (globals.debug)
        console.log(
          'Successfuly set owners for protocol: ',
          i++,
          ' / ',
          n,
          ' -> ',
          protocol.name
        );
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    console.error('setDepartmentProtocolOwners: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const setDepartmentCategoryOwners = async (
  department: DepartmentItem,
  ownerList: DepartmentItem[],
  categoryList: CategoryItem[]
): Promise<Response> => {
  try {
    const ownerIDs = ownerList.map((owner: DepartmentItem) => owner.id);
    let i = 0,
      n = categoryList.length;
    for (const category of categoryList) {
      const dbCat = await DataStore.query(Category, category.uid);
      if (dbCat == null) {
        console.error('setDepartmentCategoryOwners: ', dbCat);
        return {
          type: ResponseType.Failure,
          data: dbCat,
        };
      }

      const newCategory = Category.copyOf(dbCat, (updated) => {
        updated.pairedDepIDs = ownerIDs;
      });

      let response = await DataStore.save(newCategory);

      if (response === null) {
        console.error('setDepartmentCategoryOwners: ', response);
        return {
          type: ResponseType.Failure,
          data: response,
        };
      }

      if (globals.debug)
        console.log(
          'Successfuly set owners for category: ',
          i++,
          ' / ',
          n,
          ' -> ',
          category.name
        );
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    console.error('setDepartmentCategoryOwners: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteDepartmentIDFromAllReferences = async (
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the models that have the pairedDepIDs */
    const departments = await DataStore.query(Department, (c) =>
      c.subDepIDs.contains(id)
    );
    const users = await DataStore.query(User, (c) =>
      c.pairedDepIDs.contains(id)
    );
    const notifications = await DataStore.query(Notification, (c) =>
      c.pairedDepIDs.contains(id)
    );
    const categories = await DataStore.query(Category, (c) =>
      c.pairedDepIDs.contains(id)
    );
    const protocols = await DataStore.query(Protocol, (c) =>
      c.pairedDepIDs.contains(id)
    );
    const contacts = await DataStore.query(Contact, (c) =>
      c.pairedDepIDs.contains(id)
    );
    const inputForms = await DataStore.query(InputForm, (c) =>
      c.pairedDepIDs.contains(id)
    );

    /* Search through the departments and update the IDs */
    for (let i = 0; i < departments.length; i++) {
      const dep = departments[i];
      let depIDs: string[] = [];
      let isChanged = false;
      if (dep.subDepIDs == null) continue;
      for (let i = 0; i < dep.subDepIDs.length; i++) {
        const depID = dep.subDepIDs[i];
        if (depID === id) {
          n++;
          isChanged = true;
        } else depIDs.push(depID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Department table */
        const newDep = Department.copyOf(dep, (updated) => {
          updated.subDepIDs = depIDs;
        });
        let response = await DataStore.save(newDep);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Department table: ',
              newDep.name,
              '->',
              n
            );
        }
      }
    }

    /* Search through the users and update the IDs */
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      let userIDs: string[] = [];
      let isChanged = false;
      if (user.pairedDepIDs == null) continue;
      for (let i = 0; i < user.pairedDepIDs.length; i++) {
        const userID = user.pairedDepIDs[i];
        if (userID === id) {
          n++;
          isChanged = true;
        } else userIDs.push(userID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the User table */
        const newUser = User.copyOf(user, (updated) => {
          updated.pairedDepIDs = userIDs;
        });
        let response = await DataStore.save(newUser);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in User table: ',
              newUser.firstName,
              newUser.lastName,
              '->',
              n
            );
        }
      }
    }

    /* Search through the notifications and update the IDs */
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      let notificationIDs: string[] = [];
      let isChanged = false;
      if (notification.pairedDepIDs == null) continue;
      for (let i = 0; i < notification.pairedDepIDs.length; i++) {
        const notificationID = notification.pairedDepIDs[i];
        if (notificationID === id) {
          n++;
          isChanged = true;
        } else notificationIDs.push(notificationID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Notification table */
        const newNotification = Notification.copyOf(notification, (updated) => {
          updated.pairedDepIDs = notificationIDs;
        });
        let response = await DataStore.save(newNotification);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Notification table: ',
              newNotification.title,
              '->',
              n
            );
        }
      }
    }

    /* Search through the contacts and update the IDs */
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      let contactIDs: string[] = [];
      let isChanged = false;
      if (contact.pairedDepIDs == null) continue;
      for (let i = 0; i < contact.pairedDepIDs.length; i++) {
        const contactID = contact.pairedDepIDs[i];
        if (contactID === id) {
          n++;
          isChanged = true;
        } else contactIDs.push(contactID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Contact table */
        const newContact = Contact.copyOf(contact, (updated) => {
          updated.pairedDepIDs = contactIDs;
        });
        let response = await DataStore.save(newContact);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Contact table: ',
              newContact.fullName,
              '->',
              n
            );
        }
      }
    }

    /* Search through the inputForms and update the IDs */
    for (let i = 0; i < inputForms.length; i++) {
      const inputForm = inputForms[i];
      let inputFormIDs: string[] = [];
      let isChanged = false;
      if (inputForm.pairedDepIDs == null) continue;
      for (let i = 0; i < inputForm.pairedDepIDs.length; i++) {
        const inputFormID = inputForm.pairedDepIDs[i];
        if (inputFormID === id) {
          n++;
          isChanged = true;
        } else inputFormIDs.push(inputFormID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the InputForm table */
        const newInputForm = InputForm.copyOf(inputForm, (updated) => {
          updated.pairedDepIDs = inputFormIDs;
        });
        let response = await DataStore.save(newInputForm);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in InputForm table: ',
              newInputForm.name,
              '->',
              n
            );
        }
      }
    }

    /* Search through the categories and update the IDs */
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      let categoryIDs: string[] = [];
      let isChanged = false;
      if (category.pairedDepIDs == null) continue;
      for (let i = 0; i < category.pairedDepIDs.length; i++) {
        const categoryID = category.pairedDepIDs[i];
        if (categoryID === id) {
          n++;
          isChanged = true;
        } else categoryIDs.push(categoryID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Category table */
        const newCategory = Category.copyOf(category, (updated) => {
          updated.pairedDepIDs = categoryIDs;
        });
        let response = await DataStore.save(newCategory);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Category table: ',
              newCategory.name,
              '->',
              n
            );
        }
      }
    }

    /* Search through the protocols and update the IDs */
    for (let i = 0; i < protocols.length; i++) {
      const protocol = protocols[i];
      let protocolIDs: string[] = [];
      let isChanged = false;
      if (protocol.pairedDepIDs == null) continue;
      for (let i = 0; i < protocol.pairedDepIDs.length; i++) {
        const protocolID = protocol.pairedDepIDs[i];
        if (protocolID === id) {
          n++;
          isChanged = true;
        } else protocolIDs.push(protocolID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Protocol table */
        const newProtocol = Protocol.copyOf(protocol, (updated) => {
          updated.pairedDepIDs = protocolIDs;
        });
        let response = await DataStore.save(newProtocol);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Protocol table: ',
              newProtocol.name,
              '->',
              n
            );
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteIDFromAllReferences = async (
  department: DepartmentItem,
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the medications and drips*/
    const medications = await DataStore.query(Medication, (c) =>
      c.departmentID.eq(department.id)
    );
    const drips = await DataStore.query(Drip, (c) =>
      c.departmentID.eq(department.id)
    );
    const equipments = await DataStore.query(Equipment, (c) =>
      c.departmentID.eq(department.id)
    );
    const electricals = await DataStore.query(Electrical, (c) =>
      c.departmentID.eq(department.id)
    );
    const forms = await DataStore.query(Form, (c) =>
      c.departmentID.eq(department.id)
    );
    const categories = await DataStore.query(Category, (c) =>
      c.departmentID.eq(department.id)
    );
    let protocols: Protocol[] = [];
    for (const category of categories) {
      const catProtocols = await DataStore.query(Protocol, (c) =>
        c.categoryID.eq(category.id)
      );
      protocols = [...protocols, ...catProtocols];
    }

    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      // let categoryProtIDs: string[] = [];
      let pairedDepIDs: string[] = [];
      // let subCategoryIDs: string[] = [];
      let isChanged = false;

      // if(category.pairedProtocols == null) continue;
      // for(let i = 0; i < category.pairedProtocols.length; i++) {
      //     const categoryProtID = category.pairedProtocols[i];
      //     if(categoryProtID === id) {
      //         n++;
      //         isChanged = true;
      //     }
      //     else
      //         categoryProtIDs.push(categoryProtID);
      // }

      if (category.pairedDepIDs == null) continue;
      for (let i = 0; i < category.pairedDepIDs.length; i++) {
        const pairedDepID = category.pairedDepIDs[i];
        if (pairedDepID === id) {
          n++;
          isChanged = true;
        } else pairedDepIDs.push(pairedDepID);
      }

      // if(category.subCategories == null) continue;
      // for(let i = 0; i < category.subCategories.length; i++) {
      //     const subCategoryID = category.subCategories[i];
      //     if(subCategoryID == null) continue;
      //     if(subCategoryID === id) {
      //         n++;
      //         isChanged = true;
      //     }
      //     else
      //         subCategoryIDs.push(subCategoryID);
      // }

      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newCategory = Category.copyOf(category, (updated) => {
          // updated.pairedProtocols = categoryProtIDs;
          updated.pairedDepIDs = pairedDepIDs;
          // updated.subCategories = subCategoryIDs;
        });
        let response = await DataStore.save(newCategory);
        if (response === null) {
          console.error('deleteIDFromAllReferences: ', response);
        }
      }
    }

    /* Search for the medication and drips in the old protocol ID in the MedicationProtocol and MedicationRange tables */
    for (let i = 0; i < medications.length; i++) {
      const med = medications[i];
      let medProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < med.protocolOptions.length; i++) {
        const medProt: MedicationProtocol = med.protocolOptions[i];
        if (medProt.protocolID === id) {
          /* Delete the MedicationProtocol */
          n++;
          isChanged = true;
        } else medProts.push(medProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newMed = Medication.copyOf(med, (updated) => {
          updated.protocolOptions = medProts;
        });
        let response = await DataStore.save(newMed);
        if (response === null) {
          console.error('deleteIDFromAllReferences: ', response);
        }
      }
    }

    for (let i = 0; i < drips.length; i++) {
      const drip = drips[i];
      let dripProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < drip.dripOptions.length; i++) {
        const dripProt = drip.dripOptions[i];
        if (!dripProt) continue;
        if (dripProt.protocolID === id) {
          n++;
          isChanged = true;
        } else dripProts.push(dripProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newDrip = Drip.copyOf(drip, (updated) => {
          updated.dripOptions = dripProts;
        });
        let response = await DataStore.save(newDrip);
        if (response === null) {
          console.error('deleteIDFromAllReferences: ', response);
        }
      }
    }

    // for(let i = 0; i < protocols.length; i++) {
    //     const protocol = protocols[i];
    //     let protocolProtIDs: string[] = [];
    //     let isChanged = false;

    //     /* Check for */
    //     for(let i = 0; i < protocol.pairedProtocols.length; i++) {
    //         const protocolProtID = protocol.pairedProtocols[i];
    //         if(protocolProtID === id) {
    //             n++;
    //             isChanged = true;
    //             protocolProtIDs.push("");
    //         }
    //         else
    //             protocolProtIDs.push(protocolProtID);
    //     }
    //     if(isChanged) {
    //         /* Save the new MedicationProtocol array to the Medication table */
    //         const newProtocol = Protocol.copyOf(protocol, updated => {
    //             updated.pairedProtocols = protocolProtIDs;
    //         });
    //         let response = await DataStore.save(newProtocol);
    //         if(response === null) {
    //             console.error("deleteIDFromAllReferences: ", response);
    //         }
    //     }
    // }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const addDepartmentIDToAllReferences = async (
  department: DepartmentItem,
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the medications and drips*/
    let promises = [
      DataStore.query(Category, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Protocol, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Workbook, (c) => c.departmentID.eq(department.id)),
      DataStore.query(WeightObject, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Contact, (c) => c.departmentID.eq(department.id)),
      DataStore.query(InputForm, (c) => c.departmentID.eq(department.id)),
    ];
    let [
      categories,
      protocols,
      workbooks,
      weightObjects,
      contacts,
      inputForms,
    ] = await Promise.all(promises);
    // const medications = await DataStore.query(Medication, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const drips = await DataStore.query(Drip, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const equipments = await DataStore.query(Equipment, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const electricals = await DataStore.query(Electrical, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const forms = await DataStore.query(Form, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const categories = await DataStore.query(Category, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const protocols = await DataStore.query(Protocol, (c) =>
    //   c.departmentID.eq(department.id)
    // );

    let cats: Category[] = categories as Category[];
    for (let i = 0; i < cats.length; i++) {
      const category = cats[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(category.pairedDepIDs ? [...category.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newCategory = Category.copyOf(category, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      DataStore.save(newCategory)
        .then((response) => {
          if (response === null)
            console.error('addDepartmentIDToAllReferences: ', response);
        })
        .catch((error) =>
          console.error('addDepartmentIDToAllReferences: ', error)
        );
      n++;
    }

    let prots: Protocol[] = protocols as Protocol[];
    for (let i = 0; i < prots.length; i++) {
      const protocol = prots[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(protocol.pairedDepIDs ? [...protocol.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newProtocol = Protocol.copyOf(protocol, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      DataStore.save(newProtocol)
        .then((response) => {
          if (response === null)
            console.error('addDepartmentIDToAllReferences: ', response);
        })
        .catch((error) =>
          console.error('addDepartmentIDToAllReferences: ', error)
        );
      n++;
    }

    let wbs: Workbook[] = workbooks as Workbook[];
    for (let i = 0; i < wbs.length; i++) {
      const workbook = wbs[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(workbook.pairedDepIDs ? [...workbook.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newWorkbook = Workbook.copyOf(workbook, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      DataStore.save(newWorkbook)
        .then((response) => {
          if (response === null)
            console.error('addDepartmentIDToAllReferences: ', response);
        })
        .catch((error) =>
          console.error('addDepartmentIDToAllReferences: ', error)
        );
      n++;
    }

    let wos: WeightObject[] = weightObjects as WeightObject[];
    for (let i = 0; i < wos.length; i++) {
      const weightObject = wos[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(weightObject.pairedDepIDs ? [...weightObject.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newWeightObject = WeightObject.copyOf(weightObject, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      DataStore.save(newWeightObject)
        .then((response) => {
          if (response === null)
            console.error('addDepartmentIDToAllReferences: ', response);
        })
        .catch((error) =>
          console.error('addDepartmentIDToAllReferences: ', error)
        );
      n++;
    }

    let conts: Contact[] = contacts as Contact[];
    for (let i = 0; i < conts.length; i++) {
      const contact = conts[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(contact.pairedDepIDs ? [...contact.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newContact = Contact.copyOf(contact, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      DataStore.save(newContact)
        .then((response) => {
          if (response === null)
            console.error('addDepartmentIDToAllReferences: ', response);
        })
        .catch((error) =>
          console.error('addDepartmentIDToAllReferences: ', error)
        );
      n++;
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
