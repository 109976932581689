import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaSearch, FaCaretDown, FaTimes } from 'react-icons/fa';
import { ViewportList } from 'react-viewport-list';
import { ProgressStatus } from '../../../API';
import ModelItem from '../../../data/model/ModelItem';
import { toTitleCase } from '../../_global/common/Utils';
import { UserStatus } from '../../../models';

interface ProgressStatusInterface {
  status: UserStatus | 'ACTIVE' | 'SUSPENDED' | 'DELETED' | null | undefined;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const UserStatusUI: React.FC<ProgressStatusInterface> = (props) => {
  const [status, setStatus] = useState<
    UserStatus | 'ACTIVE' | 'SUSPENDED' | 'DELETED'
  >(props.status ? props.status : 'ACTIVE');

  useEffect(() => {
    setStatus(props.status ? props.status : 'ACTIVE');
  }, [props.status]);

  return (
    <div
      className={`user-status-container ${status}`}
      style={{ ...props.style }}
    >
      <h6 className={`user-status ${status}`} style={{ ...props.textStyle }}>
        {toTitleCase(status)}
      </h6>
    </div>
  );
};

export default UserStatusUI;
