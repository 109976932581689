import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { User } from '../../../../models';
import { useSelector } from 'react-redux';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import { RiLockPasswordFill } from 'react-icons/ri';
import { InputText } from 'primereact/inputtext';
import '../../login/css/Login.scss';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { getHashedPin, getSalt } from '../../../_global/common/Encrypt';
import DepartmentItem from '../../../../data/model/DepartmentItem';
interface ResetPinModalInterface {
  isVisible: boolean;
  value?: string;
  department: DepartmentItem;
  handleClose: () => void;
  handleSubmit: (pin: string, salt: string, hash: string) => void;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const ResetPinModal: React.FC<ResetPinModalInterface> = (
  props: ResetPinModalInterface
) => {
  const { isVisible, department, value, handleClose, handleSubmit } = props;

  const [state, setState] = React.useState({
    pin: '',
    error: '',
    passwordVisible: false,
  });
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const user: User = useSelector((state: any) => state.user);

  useEffect(() => {
    setState({ ...state, pin: value ? value : '' });
  }, [value]);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const handleButton = () => {
    if (!isVisible) return;
    if (state.pin.length === 0) {
      setState({ ...state, error: 'Pin is required' });
      return;
    } else if (state.pin.length < 4 || state.pin.length > 8) {
      setState({ ...state, error: 'Pin must be 4-8 digit number' });
      return;
    }
    let salt = getSalt();

    let hash = getHashedPin(state.pin, salt);

    handleSubmit(state.pin, salt, hash);
  };

  const resetState = () => {
    setState({
      pin: '',
      error: '',
      passwordVisible: false,
    });
  };

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          // width: '50vw',
          // maxWidth: '800px',
          flex: 1,
          // maxWidth
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '600px',
        }}
        onHide={() => {
          handleClose();
          resetState();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '10px' }}
        >
          <h4 className="keychain-title">
            <span
              style={{
                marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              Enter New Pin
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={handleClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <form
            className="keychain-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleButton();
            }}
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              // alignItems: 'center',
              background: 'transparent',
              border: 'none',
            }}
          >
            <div
              className="input-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              {department && department.logoVerifiedUrl && (
                <img
                  className="department-logo-public"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    marginRight: '10px',
                  }}
                  src={department.logoVerifiedUrl}
                  alt="Agency Logo"
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // width: '100%',
                }}
              >
                <label
                  className='"ketamine-general-label'
                  htmlFor="password"
                  style={{
                    fontWeight: '600',
                    color: 'black',
                    fontSize: '1rem',
                  }}
                >
                  {department.name}
                </label>
              </div>
            </div>

            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              The department code is unique to your department and the pin can
              be reset to create a new OneDose account.
            </p>

            <label
              htmlFor="password"
              className={`keychain-title`}
              style={{
                marginTop: '10px',
              }}
            >
              Department Code:
            </label>
            <label
              htmlFor="password"
              className={`keychain-title`}
              style={{
                marginTop: '-16px',
                fontWeight: '400',
              }}
            >
              {department.departmentCode}
              <span>
                {isCopied && isCopied === department.departmentCode ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size="1rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(department.departmentCode, e)}
                  />
                )}
              </span>
            </label>

            <label
              htmlFor="password"
              className={`keychain-title`}
              style={{
                marginTop: '10px',
              }}
            >
              New Sign-Up Pin
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                // width: '100%',
                flex: 1,
                margin: 0,
                padding: 0,
                // marginBottom: '10px',
              }}
            >
              <div className="public-input-container">
                <RiLockPasswordFill className="form-icon-left" size={20} />
                <InputText
                  type={state.passwordVisible ? 'text' : 'password'}
                  className="password-input"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required={true}
                  value={state.pin}
                  autoFocus={true}
                  onChange={(e: any) => {
                    let num = Number(e.target.value);
                    if (isNaN(num)) {
                      setState({
                        ...state,
                        error: 'Pin must be a 4-8 digit number',
                        pin: e.target.value,
                      });
                    } else {
                      setState({
                        ...state,
                        error:
                          e.target.value.length > 8
                            ? 'Pin must be 4-8 digit number'
                            : '',
                        pin: e.target.value,
                      });
                    }
                  }}
                  style={{
                    borderColor: state.error !== '' ? 'darkred' : 'gray',
                  }}
                />
              </div>

              {state.passwordVisible ? (
                <BsEye
                  className="form-icon-eye"
                  size={'2rem'}
                  onClick={() => {
                    setState({
                      ...state,
                      passwordVisible: !state.passwordVisible,
                    });
                  }}
                />
              ) : (
                <BsEyeSlash
                  className="form-icon-eye"
                  size={'2rem'}
                  onClick={() => {
                    setState({
                      ...state,
                      passwordVisible: !state.passwordVisible,
                    });
                  }}
                />
              )}
            </div>
            {state.error !== '' && (
              <label
                htmlFor="password"
                className="error-text"
                style={{
                  color: 'darkred',
                  fontSize: '1rem',
                  marginLeft: '10px',
                  marginTop: '6px',
                }}
              >
                {state.error}
              </label>
            )}

            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              style={{
                marginTop: '3rem',
              }}
              onClick={handleButton}
            >
              Reset Pin
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default ResetPinModal;
