import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Import the necessary components
import { Provider as StoreProvider } from 'react-redux';
//Use next two lines only if syncing with the cloud
import { Amplify } from 'aws-amplify';
import { AuthModeStrategyType } from 'aws-amplify';
import store from './store/store';
import { ThemeProvider } from 'react-bootstrap';
import awsconfig from './aws-exports';
import { createRoot } from 'react-dom/client';

Amplify.configure({
  ...awsconfig,
  DataStore: {
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider>
    <StoreProvider store={store}>
      <Router>
        <script src="https://mozilla.github.io/pdf.js/build/pdf.js"></script>
        <App />
      </Router>
    </StoreProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
