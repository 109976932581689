// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PwsType = {
  "PWS_A": "PWS_A",
  "PWS_B": "PWS_B",
  "PWS_C": "PWS_C",
  "PWS_D": "PWS_D"
};

const UserType = {
  "ADMIN": "ADMIN",
  "DEPT_ADMIN": "DEPT_ADMIN",
  "DEPT": "DEPT",
  "USER": "USER"
};

const ProgressStatus = {
  "DRAFT": "DRAFT",
  "ACTIVE": "ACTIVE",
  "ARCHIVE": "ARCHIVE",
  "DELETED": "DELETED"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "SUSPENDED": "SUSPENDED",
  "DELETED": "DELETED"
};

const FormType = {
  "SHORT_ANSWER": "SHORT_ANSWER",
  "LONG_ANSWER": "LONG_ANSWER",
  "DROPDOWN": "DROPDOWN",
  "SINGLE_SELECT": "SINGLE_SELECT",
  "MULTI_SELECT": "MULTI_SELECT",
  "DATE_ENTRY": "DATE_ENTRY",
  "NUMBER_ANSWER": "NUMBER_ANSWER",
  "TRUE_FALSE": "TRUE_FALSE"
};

const SoftwareType = {
  "SOFTWARE_ONLY": "SOFTWARE_ONLY",
  "ESSENTIALS": "ESSENTIALS",
  "VIEW_ONLY": "VIEW_ONLY"
};

const CalculatorType = {
  "PARKLAND": "PARKLAND",
  "RACE": "RACE",
  "RASS": "RASS"
};

const NotificationType = {
  "GENERAL": "GENERAL",
  "ACKNOWLEDGE": "ACKNOWLEDGE",
  "TRAINING": "TRAINING"
};

const DevNotifyType = {
  "FORCE_UPDATE": "FORCE_UPDATE",
  "GENERAL_UPDATE": "GENERAL_UPDATE"
};

const PatientAgeGroup = {
  "NEONATE": "NEONATE",
  "PEDIATRIC": "PEDIATRIC",
  "ADULT": "ADULT",
  "GERIATRIC": "GERIATRIC"
};

const { Department, MedicShift, PatientInteraction, Keychain, Contact, FormLog, InputForm, Notification, Drip, Equipment, Medication, Protocol, Ambulance, User, Category, Electrical, ElectricalShock, DeveloperNotification, Vitals, OneWeight, Form, Log, RequireSync, HardwareVersion, FirmwareVersion, Workbook, WeightObject, CPRAssist, LogUser, Invite, LogElectrical, CPRMetaData, FormQuestion, FormResponse, AdultRange, Question, LogMedication, LogDrip, LogEquipment, LogForm, LogWeightObject, LogEvent, LogMedicShift, ElectricalRange, ElectricalShockRange, ElectricalOption, ElectricalShockOption, VitalOption, ModelMetaData, MedicationRoute, MedicationProtocol, MedicationConcentration, MedicationRange, EquipmentOption, FormOption, FormGroup, DepartmentConfig } = initSchema(schema);

export {
  Department,
  MedicShift,
  PatientInteraction,
  Keychain,
  Contact,
  FormLog,
  InputForm,
  Notification,
  Drip,
  Equipment,
  Medication,
  Protocol,
  Ambulance,
  User,
  Category,
  Electrical,
  ElectricalShock,
  DeveloperNotification,
  Vitals,
  OneWeight,
  Form,
  Log,
  RequireSync,
  HardwareVersion,
  FirmwareVersion,
  Workbook,
  WeightObject,
  CPRAssist,
  LogUser,
  PwsType,
  UserType,
  ProgressStatus,
  UserStatus,
  FormType,
  SoftwareType,
  CalculatorType,
  NotificationType,
  DevNotifyType,
  PatientAgeGroup,
  Invite,
  LogElectrical,
  CPRMetaData,
  FormQuestion,
  FormResponse,
  AdultRange,
  Question,
  LogMedication,
  LogDrip,
  LogEquipment,
  LogForm,
  LogWeightObject,
  LogEvent,
  LogMedicShift,
  ElectricalRange,
  ElectricalShockRange,
  ElectricalOption,
  ElectricalShockOption,
  VitalOption,
  ModelMetaData,
  MedicationRoute,
  MedicationProtocol,
  MedicationConcentration,
  MedicationRange,
  EquipmentOption,
  FormOption,
  FormGroup,
  DepartmentConfig
};