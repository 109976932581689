import React, { ReactNode, useEffect, useState } from 'react';
import Wrapper from '../components/Wrapper';
import SideBar from '../components/sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';
import { useSelector } from 'react-redux';
import Popup from '../components/Popup';
import Login from '../pages/login/Login';
import { Col, Row } from 'react-grid-system';
import { DatabaseResponse } from '../../data/AmplifyDB';
import { User } from '../../models';
import { globals } from '../_global/common/Utils';

interface DashboardProps {
  children?: ReactNode;
  pageTitle: string;
  component?: ReactNode;
}

/* 09-28-23 Arul: Created the Dashboard layout component to wrapper the navbar, sidebar and main component */
const Dashboard: React.FC<DashboardProps> = ({
  children,
  pageTitle,
  component,
}) => {
  const Sidebar = useSelector((state: any) => state.sidebar);
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [isOpen, setIsOpen] = useState(false);
  const user: User = useSelector((state: any) => state.user);

  const handleUserInfo = (props: any) => {
    setIsOpen(!isOpen);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ flex: 1 }}>
      {isOpen && (
        <Popup
          isVisible={isOpen}
          content={
            <Login onSuccess={handleUserInfo} handleClose={togglePopup} />
          }
          handleClose={togglePopup}
        />
      )}
      <Navbar handleLogin={togglePopup} />
      <SideBar
        show={Sidebar}
        name={!isLoggedIn || !user ? 'Guest' : user.firstName}
      />
      <div className={`main ${Sidebar ? 'with-sidebar' : ''}`}>
        {pageTitle}
        {children}
        {component}
      </div>
    </div>
  );
};

export default Dashboard;
