import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import '../Popup.css';
import { useState } from 'react';
import { set } from 'lodash';
import { check } from 'prettier';

interface ConfirmModalProps {
  isVisible: boolean;
  handleClose?: any;
  handleSubmit?: any;
  handleCheck?: any;
  isDeleteBtn?: any;
  isPermanentlyDeleteBtn?: any;
  title: any;
  primaryDescription?: any;
  secondaryDescription?: any;
  primaryBtnName?: any;
  secondaryBtnName?: any;
  isChecked?: boolean;
  isSingleBtn?: boolean;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const ConfirmModal: React.FC<ConfirmModalProps> = (props: any) => {
  const {
    primaryBtnName,
    secondaryBtnName,
    isVisible,
    handleClose,
    handleSubmit,
    handleCheck,
    isNewBtn,
    isDeleteBtn,
    title,
    primaryDescription,
    secondaryDescription,
    isChecked,
    isPermanentlyDeleteBtn,
    isSingleBtn,
  } = props;
  const [checked, setChecked] = useState(isChecked ? isChecked : false);

  const customHeader = (
    <div className="pointer" style={{ marginLeft: '-10px' }}>
      <h4 className="headerText">{title}</h4>
    </div>
  );

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{ width: '40vw', maxWidth: '600px', backgroundColor: 'white' }}
        onHide={handleClose}
        header={customHeader}
        className="confirmDialog routeConfirmModal"
      >
        <div style={{ background: 'white' }}>
          <div className="">
            {primaryDescription && (
              <div className="contentText contentTextBlackColor">
                {primaryDescription}
              </div>
            )}
            {secondaryDescription && (
              <div
                className="contentText contentTextBlackColor"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                {secondaryDescription}
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            {isPermanentlyDeleteBtn && (
              <div
                className="colAlignCenter"
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
              >
                <input
                  className="checkBoxSelectedColor custom-checkbox-button-input"
                  name="deleteCheckbox"
                  type="checkbox"
                  id="reviewCheckbox"
                  checked={checked}
                  onChange={(e) => {
                    e.stopPropagation();
                    setChecked(!checked);
                  }}
                />
                <label
                  htmlFor="deleteCheckbox"
                  className="contentHeading hoverErrorText"
                  style={{ color: checked ? '#880808' : '#000' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCheck(!checked);
                    setChecked(!checked);
                  }}
                >
                  Permentantely delete
                </label>
              </div>
            )}
            <div
              className="dialogButtons"
              style={{ marginTop: '20px', alignItems: 'flex-end' }}
            >
              {!isSingleBtn && (
                <Button
                  data-testid="cancel-Bttn"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleClose}
                >
                  {' '}
                  {primaryBtnName}
                </Button>
              )}
              {!isDeleteBtn && (
                <Button
                  data-testid="set-Bttn"
                  className="primary-button btn-rightMargin"
                  onClick={handleSubmit}
                >
                  {secondaryBtnName}
                </Button>
              )}
              {isDeleteBtn && (
                <Button
                  data-testid="delete-Bttn"
                  className="red-background-button btn-rightMargin "
                  onClick={handleSubmit}
                >
                  {secondaryBtnName}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
