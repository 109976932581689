import React, { useEffect, useState } from 'react';
import { ViewportList } from 'react-viewport-list';
import { getFormattedDate } from '../../../_global/common/Utils';
import { ProgressStatus } from '../../../../API';
import MedicationItem from '../../../../data/model/MedicationItem';
import { Accordion, Col, Row } from 'react-bootstrap';
import SearchBar from '../../../components/Search/SearchBar';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drip, Medication, User } from '../../../../models';
import { FaChevronRight } from 'react-icons/fa6';

const ListArchiveInfusions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: infusions, department, database } = state;

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filteredInfusions, setFilteredInfusions] = useState(infusions);
  const [modifiedBy, setModifiedBy] = useState<User | undefined>();

  useEffect(() => {
    const filtered = infusions.filter(
      (infusions: any) =>
        infusions.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (infusions.modifiedBy &&
          infusions.modifiedBy
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
    );
    setFilteredInfusions(filtered);
  }, [searchQuery, infusions]);

  const handleItemClick = (medication: Drip) => {
    // const state = {
    //   selectedProtocol: null,
    //   value: medication,
    //   subValue: null,
    //   type: 'Infusion',
    //   editType: 'edit',
    //   editMode: false,
    //   page: 'listInfusionsPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
  };

  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 2fr 2fr 2fr 1fr',
  };

  const handleBack = () => {
    navigate(`/archive`, { state: department });
  };

  const calculateDoses = (medication: Drip) => {
    let doses = 0;
    medication.dripOptions.forEach((option) => {
      doses += option.options.length;
    });
    return doses;
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={'Infusions: ' + filteredInfusions.length + ' items'}
        status={ProgressStatus.ARCHIVE}
        page={department.name}
        type={'protocol'}
      />
      <Row>
        <Col sm={12}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Infusions...'}
          />
        </Col>
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6 className="departmentItemText">Version</h6>
                <h6 className="departmentItemText">Name</h6>
                <h6 className="departmentItemText">Concentration</h6>
                <h6 className="departmentItemText">Archive Date</h6>
                <h6 className="departmentItemText">Archive By</h6>
                <h6 className="departmentItemText">Doses</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={filteredInfusions}>
            {(item: Drip, index) => {
              // let username = item.modifiedBy
              //   ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
              //   : 'Hinckley Medical';
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.version}</h6>
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">
                      {item.concentration && item.concentration.length
                        ? item.concentration[0].firstAmnt +
                          ' ' +
                          item.concentration[0].firstUnit +
                          '/' +
                          item.concentration[0].secAmnt +
                          ' ' +
                          item.concentration[0].secUnit
                        : 'N/A'}
                    </h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.updatedAt ? item?.updatedAt : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {modifiedBy
                        ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {calculateDoses(item)}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListArchiveInfusions;
