import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import { ageDropdownValues } from '../../_global/constants/Protocol_constants';
import { mapIndexToRange } from '../../_global/common/Utils';

type RangeDialogProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  headerTitle?: string;
  InputLabel?: string;
  value?: number | string | undefined;
  handleSet?: any;
  type?: any;
};

/* 10-20-23 Praveen: Created the popup globally for Max and Min Range related changes */
const RangeDialog: React.FC<RangeDialogProps> = ({
  value,
  dialogVisible,
  setDialogVisible,
  headerText,
  headerTitle,
  InputLabel,
  handleSet,
  type,
}) => {
  const [rangeSelect, setRangeSelect] = useState('No Min - 0 Kg');
  const [rangeValue, setRangeValue] = useState<any>(value ? value : 0.0);
  const [rangeButtonValue, setRangeButtonValue] = useState<any>(0);
  const [selectedAge, setSelectedAge] = useState<any>();

  const handleButtonClick = (label: string) => {
    setRangeSelect(label);
    setRangeValue(0.0);
  };

  //populate the range value in field
  useEffect(() => {
    if (type === 'Vital') {
      if (headerText === 'Low Range' || headerText === 'High Range') {
        if (value !== undefined && !isNaN(Number(value))) {
          if (value !== 'MIN' && value !== 'MAX') {
            const numericValue = Number(value);
            const rangeString = mapIndexToRange(numericValue);
            setSelectedAge({ name: rangeString, code: rangeString });
          } else {
            setSelectedAge({ name: value.toString(), code: value.toString() });
          }
        }
      }
    } else {
      if (headerText === 'Low Range') {
        if (value === 0) {
          setRangeSelect('No Min - 0 Kg');
          setRangeValue(0.0);
        } else {
          setRangeValue(value);
          setRangeSelect('');
        }
      } else {
        if (value === 2147483647) {
          setRangeSelect('No max - Inf.');
          setRangeValue(0.0);
        } else {
          setRangeValue(value);
          setRangeSelect('');
        }
      }
    }
  }, [value]);

  /*10-31-2023,Arul: handling function for submit*/
  const handleRageSet = async () => {
    if (type === 'Vital') {
      let selectedValue = selectedAge?.code;

      if (selectedValue === 'MIN') {
        selectedValue = 0;
      } else if (selectedValue === 'MAX') {
        selectedValue = 29;
      }

      handleSet(selectedValue);
    } else {
      if (rangeValue === 0.0) {
        await handleSet(rangeButtonValue);
      } else {
        await handleSet(rangeValue);
      }
    }
  };

  return (
    <Dialog
      header={headerText}
      visible={dialogVisible}
      style={{ width: '50vw', maxWidth: '750px' }}
      onHide={() => setDialogVisible(false)}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            {type !== 'Vital' && (
              <>
                <div>
                  <div style={{ marginBottom: '10px' }}>
                    <h6 className="headerTextMargin">
                      Department Preset Ranges
                    </h6>
                    <span className="contentText">
                      Select the preset cuttoffs determine by the department.
                    </span>
                    <span className="contentText">
                      NOTE: This is the {InputLabel} bound so if this is an
                      adult dose the{' '}
                      {headerText === 'Low Range' ? headerTitle : 'lower'} bound
                      is set at the cut off between pediatric and adult.
                    </span>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        handleButtonClick(
                          headerText === 'Low Range'
                            ? 'No Min - 0 Kg'
                            : 'No max - Inf.'
                        );
                        setRangeButtonValue(
                          headerText === 'Low Range' ? 0 : 2147483647
                        );
                      }}
                      className={` btn-rightMargin ${
                        rangeSelect === 'No Min - 0 Kg' ||
                        rangeSelect === 'No max - Inf.'
                          ? 'secondary-button-range'
                          : 'secondary-button-large'
                      }`}
                    >
                      {headerText === 'Low Range'
                        ? 'No Min - 0 Kg'
                        : 'No max - Inf.'}
                    </Button>
                    <Button
                      onClick={() => {
                        handleButtonClick(
                          `${
                            headerText === 'Low Range' ? 'Pediatric' : 'Neonate'
                          } - 5 Kg`
                        );
                        setRangeButtonValue(5);
                      }}
                      className={` btn-rightMargin ${
                        rangeSelect === 'Pediatric - 5 Kg' ||
                        rangeSelect === 'Neonate - 5 Kg'
                          ? 'secondary-button-range'
                          : 'secondary-button-large'
                      }`}
                    >
                      {`${
                        headerText === 'Low Range' ? 'Pediatric' : 'Neonate'
                      } - 5 Kg `}
                    </Button>
                    <Button
                      onClick={() => {
                        handleButtonClick(
                          `${
                            headerText === 'Low Range' ? 'Adult' : 'Pediatric'
                          } - 40 Kg`
                        );
                        setRangeButtonValue(40);
                      }}
                      className={` btn-rightMargin ${
                        rangeSelect === 'Adult - 40 Kg' ||
                        rangeSelect === 'Pediatric - 40 Kg'
                          ? 'secondary-button-range'
                          : 'secondary-button-large'
                      }`}
                    >
                      {`${
                        headerText === 'Low Range' ? 'Adult' : 'Pediatric'
                      } - 40 Kg `}
                    </Button>
                    <hr />
                  </div>
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <h6 className="headerTextMargin">Direct Entry</h6>
                  <span className="contentText">
                    Entry the{' '}
                    {headerText === 'Low Range' ? headerTitle : 'lower'} bound
                    directyly in Kilograms.
                  </span>
                  <span className="contentText">
                    NOTE: This is the {InputLabel} bound cut off for this dose.
                  </span>
                </div>
                <div>
                  <div>
                    <div className="p-inputgroup flex-1">
                      <span className="p-inputgroup-addon-left">
                        <span>Kg:</span>
                      </span>
                      <div className="input-container rangeContainer">
                        <InputNumber
                          value={rangeValue}
                          mode="decimal"
                          data-testid="rangeValue"
                          maxFractionDigits={1}
                          autoFocus={true}
                          onChange={(e: any) => {
                            setRangeValue(e?.value);
                            setRangeSelect('');
                          }}
                          placeholder=""
                          className="dialogInput"
                          onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                              handleRageSet();
                            }
                          }}
                        />
                        <div className="dialogInput-border"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {type === 'Vital' && (
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <h6 className="headerTextMargin">Direct Entry</h6>
                  <span className="contentText">
                    Entry the{' '}
                    {headerText === 'Low Range' ? headerTitle : 'lower'} bound
                    directyly in Kilograms.
                  </span>
                  <span className="contentText">
                    NOTE: This is the {InputLabel} bound cut off for this dose.
                  </span>
                </div>
                <div>
                  <Row>
                    <Col sm={12}>
                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon-left calculationMinWidth">
                          <span>
                            {headerText === 'Low Range'
                              ? 'Low Age'
                              : 'High Age'}
                          </span>
                        </span>
                        <div className="doseDropdwnWidth p-inputgroup-addon">
                          <Dropdown
                            value={selectedAge}
                            onChange={(e: any) => {
                              setSelectedAge(e.value);
                            }}
                            options={ageDropdownValues}
                            optionLabel="name"
                            className="selectDropdown"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>

          <div className="dialogButtons">
            <Button
              data-testid="cancelBtnn"
              className="secondary-button-dialog btn-rightMargin"
              onClick={() => setDialogVisible(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="saveBtnn"
              className="primary-button"
              disabled={type === 'Vital' && !selectedAge}
              onClick={handleRageSet}
            >
              Set
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RangeDialog;
