import { useFormik } from 'formik';
import * as _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleSetNotification } from '../../../../store/actions';
import {
  createNotificationData,
  createNotificationValues,
  notificationTypes,
} from '../../../_global/constants/Protocol_constants';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import NotificationProtocolSideout from '../../../components/SideOut/notificationSideOut/NotificationProtocolSideout';
import ProtocolHeader from '../../../pages/protocol/ProtocolHeader';
import DragDropPreviewPage from './DragDropPreviewPage';
import QuestionSideout from '../../../components/SideOut/notificationSideOut/QuestionSideout';
import {
  NotificationJSON,
  createNotification,
  uploadAllFilesToS3,
} from '../../../../data/functions/NotificationDB';
import { NotificationType, Question, User } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import Loading from '../../../components/Loading/Loading';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';
import { globals } from '../../../_global/common/Utils';

/* 11-29-23 Arul: Created the  common component for notification create page*/
const NotificationCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const [department, setDepartment] = useState<DepartmentItem>(
    database.department
  );
  const user: User = useSelector((state: any) => state.user);

  const [type, setNotificationType] = useState('GENERAL');
  const [isQuestionModal, setIsQuestionModal] = useState(false);

  const [selectedData, setSelectedData] = useState<any>('');

  const [isDelete, setIsDelete] = useState(false);
  const [isModalVisisble, setIsModalVisible] = useState(false);
  const [protocolData, setProtocolData] = useState([]);
  const [isDeleteProtocol, setIsDeleteProtocol] = useState(false);
  const [deleteProtocolIndex, setDeleteProtocolIndex] = useState<any>();
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isNoSubDepsWarning, setIsNoSubDepsWarning] = useState(false);
  const [isDataChange, setisDataChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    'Creating Notification...'
  );
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);

  //initialize the formik values
  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      notificationStyle: 'urgent',
      type: NotificationType.GENERAL,
      pairedProtocol: '',
      questions: [] as Question[],
      attachments: [],
      deadline: null as Date | null,
      pairedDeps: [] as DepartmentItem[],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Must have a title'),
      message: Yup.string(),
      type: Yup.string().required('Must have a type'),
      notificationStyle: Yup.string(),
      pairedProtocol: Yup.string(),
      question: Yup.array(),
      attachments: Yup.array(),
      deadline: Yup.date().nullable(),
      pairedDeps: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (department.subDeps && department.subDeps.length > 0) {
        if (formik.values.pairedDeps.length === 0 && !isNoSubDepsWarning) {
          setIsNoSubDepsWarning(true);
          return;
        }
      }
      if (formik.isValid) {
        setIsLoading(true);
        /* First uplad all the files to S3 and get the URLs */
        let results: Response;
        let fileURLs: string[] = [];
        if (values.attachments.length > 0) {
          results = await uploadAllFilesToS3(
            department,
            values.attachments,
            setLoadingMessage
          );
          if (results.type === ResponseType.Success) {
            fileURLs = results.data;
            if (fileURLs.length !== values.attachments.length) {
              if (globals.debug)
                console.log(
                  'Error uploading files to S3',
                  fileURLs.length,
                  fileURLs
                );
              setIsLoading(false);
              alert('Error uploading files please try again');
              return;
            }
            if (globals.debug)
              console.log('Uploaded files to S3', fileURLs.length, fileURLs);
          } else if (globals.debug)
            console.log('Error uploading files to S3', results.data);
        }

        /* Format the formik into the JSON to create the notification */
        let json: NotificationJSON = {
          title: values.title,
          message: values.message,
          timestamp: new Date(),
          deadline: values.deadline ? values.deadline : undefined,
          type: values.type as NotificationType,
          isReadIDs: [],
          isAckIDs: [],
          departmentID: department.id,
          fileURLs: fileURLs,
          taggedProtocols: [],
          questions: values.questions,
          isPush: values.notificationStyle === 'urgent' ? true : false,
          pairedDeps: isNoSubDepsWarning ? departmentList : values.pairedDeps,
        };

        /* Create the notification */
        results = await createNotification(json, user);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Notification created successfully', results.data);
          navigate('/notification');
        } else if (globals.debug)
          console.log('Notification creation failed', results.data);

        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !formik.values.pairedDeps.some(
          (d: DepartmentItem) => d.id === dep.id
        );
      });
      setDepartmentList(l);
    }
  }, [department, formik.values.pairedDeps]);
  /* 11-29-23 Arul: function for return protocol main page*/
  const handleBack = () => {
    if (isDataChange && !isWarningModal) {
      setIsWarningModal(true);
    } else {
      navigate('/notification');
    }
  };

  /* 11-29-23 Arul: function for question and answer submit*/
  const handleSubmitQuestion = useCallback(
    (newQuestion: Question, prevQuestion?: Question) => {
      const questions: Question[] = [...formik.values.questions];
      if (prevQuestion) {
        const index = questions.findIndex(
          (item: Question) => item === prevQuestion
        );
        questions[index] = newQuestion;
      } else questions.push(newQuestion);

      formik.setValues({
        ...formik.values,
        questions: questions,
      });
      setIsQuestionModal(false);
      setSelectedData(null);
    },
    [formik.values]
  );

  /* 11-28-23 Arul: function for delete question and answer*/
  const handleDeleteQuestionAnswer = useCallback(() => {
    const data: Question[] = [...formik.values.questions];
    data.filter((item: Question) => item !== selectedData);
    formik.setValues({
      ...formik.values,
      questions: data,
    });
    setIsDelete(false);
    setIsQuestionModal(false);
    setSelectedData(null);
  }, [formik.values, selectedData, setSelectedData]);

  const handleCreate = async () => {};

  const handleAddPairedProtocol = (data: any) => {
    setIsModalVisible(false);
    setProtocolData(data);
  };

  const handleDeletePairedProtocol = useCallback(() => {
    const data: any = [...protocolData];
    data.splice(deleteProtocolIndex, 1);
    setProtocolData(data);
    setIsDeleteProtocol(false);
    setDeleteProtocolIndex(null);
  }, [protocolData, deleteProtocolIndex, setProtocolData]);

  //open notification sideout
  const handleprotocolEdit = (event: any) => {
    const divId = event.target.getAttribute('data-div-id');
    if (divId === 'outsideClick') {
      setIsModalVisible(true);
    }
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...formik.values.pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    formik.setFieldValue('pairedDeps', l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = formik.values.pairedDeps.filter(
      (d: DepartmentItem) => d.id !== option.id
    );
    formik.setFieldValue('pairedDeps', l);
  };

  const handleClearDepartments = () => {
    formik.setFieldValue('pairedDeps', []);
  };

  return (
    <div className="screen-container">
      {/* Add the CalculationBasisDialog component */}
      <QuestionSideout
        dialogVisible={isQuestionModal}
        onClose={() => {
          setIsQuestionModal(false);
          setSelectedData(null);
        }}
        headerText="Notification Questions"
        handleSet={handleSubmitQuestion}
        question={selectedData}
        handleDeleteQuestion={() => {
          setIsDelete(true);
        }}
      />
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove question?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeleteQuestionAnswer}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want remove this question from the notification?'
          }
        />
      )}
      {isModalVisisble && (
        <NotificationProtocolSideout
          isVisible={isModalVisisble}
          handleClose={() => {
            setIsModalVisible(false);
          }}
          data={protocolData}
          handleAdd={handleAddPairedProtocol}
        />
      )}
      {isDeleteProtocol && (
        <ConfirmModal
          isVisible={isDeleteProtocol}
          title="Remove Paired Protocol?"
          handleClose={() => {
            setIsDeleteProtocol(false);
          }}
          handleSubmit={handleDeletePairedProtocol}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want remove this paired protocol?"
        />
      )}
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Notification New page. Click cancel to return Notification New page.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      {isNoSubDepsWarning && (
        <ConfirmModal
          isVisible={isNoSubDepsWarning}
          title="Warning: No sub-departments selected."
          handleClose={() => {
            setIsNoSubDepsWarning(false);
          }}
          handleSubmit={() => {
            formik.submitForm();
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Create"
          primaryDescription={`Creating a notification without paired departments will send the notification to all users.  Are you sure you want to continue?`}
        />
      )}
      <ProtocolHeader
        name={'Create Notification'}
        page="Notifications"
        isBackButton={true}
        type={'protocol'}
        isSendButton={true}
        isSendActive={formik.isValid && formik.dirty}
        isDotButton={true}
        handleCancel={handleBack}
        handleCreate={() => formik.submitForm()}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Notification</h5>
          <div className="infolist">
            <div className="display_flex">
              <span
                className="ketamine-general-label"
                style={{
                  marginLeft: '-12px',
                  fontSize: '15px',
                  marginRight: '-13px',
                }}
              >
                Notification Style:
              </span>
              <div className="tab-container">
                <div
                  className={`tab ${
                    formik.values.notificationStyle === 'normal'
                      ? 'tab-active-red'
                      : ''
                  }`}
                  onClick={() =>
                    formik.setFieldValue('notificationStyle', 'normal')
                  }
                >
                  <div style={{ marginTop: '4px' }}>Silent</div>
                </div>
                <div
                  className={`tab ${
                    formik.values.notificationStyle === 'urgent'
                      ? 'tab-active-green'
                      : ''
                  }`}
                  onClick={() =>
                    formik.setFieldValue('notificationStyle', 'urgent')
                  }
                >
                  <div style={{ marginTop: '4px' }}>Push</div>
                </div>
              </div>
            </div>
          </div>
          {formik.values.notificationStyle === 'normal' ? (
            <span className={`notification-css`}>
              Silent: Delivered in-app, displaying a badge on the Notification
              tab when opened.
            </span>
          ) : (
            <span className={`notification-css`}>
              Push: Pushes the notification to all devices so they will
              IMMEDIATELY receive the notification
            </span>
          )}
          <label htmlFor="title" className={`notification-css-title`}>
            Title<span className="required-field ">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general cursorPointer titleForm"
              id="title"
              name="title"
              required={true}
              value={formik.values.title}
              onChange={formik.handleChange}
            />
            <div className="input-border"></div>
          </div>
          <div style={{ width: '100%' }}>
            <label htmlFor="type" className={`notification-css-title`}>
              Notification Type <span className="required-field ">*</span>
            </label>
            <div className="input-container-we cursorPointer">
              <Dropdown
                id="type"
                name="type"
                data-testid="type"
                value={type}
                options={notificationTypes}
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    type: e.value,
                  });
                  setNotificationType(e.value);
                }}
                style={{ width: '100%', borderRadius: '10px' }}
                className="dropDownIconWidth input-container-we"
              />
            </div>
          </div>
          {type === 'TRAINING' && (
            <div style={{ width: '100%' }}>
              <label htmlFor="type" className={`notification-css-title`}>
                Training Deadline
              </label>
              <div className="input-container-we cursorPointer">
                <Calendar
                  value={formik.values.deadline}
                  className="calenderWidthStyle"
                  data-testid="training_calender"
                  onChange={(e) => {
                    formik.setFieldValue('deadline', e.value);
                  }}
                  showIcon
                />
                {formik.values.deadline && (
                  <span className="date-close-icon">
                    <AiOutlineCloseCircle
                      data-testid="delete_date"
                      onClick={() => {
                        formik.setFieldValue('deadline', null);
                      }}
                      className="cursorPointer pairedProtocol-cancel-icon"
                    />
                  </span>
                )}
              </div>
            </div>
          )}
          {/* <div>
            <label htmlFor="question" className={`notification-css-title`}>
              Paired Protocol
            </label>
            <div data-div-id="outsideClick" data-testid="pairedProtocolData" onClick={handleprotocolEdit} style={{ paddingBlock: '1px' }} className={`protocol-hover flex_wrap cursorPointer ${protocolData.length ? '' : 'centerContent'}`}>
              {protocolData.length >= 1 &&
                <ViewportList
                  items={protocolData}
                >
                  {(item: any, index: any) => {
                    return (
                      <div key={index} className={`selectedGreybox cursorPointer`} style={{ margin: '4px 2px' }}>
                        <div>{item.name}</div>
                        <span className="close_icon_color"><AiOutlineCloseCircle   data-testid={`delete_protocol-${index}`}  className={`icon-normal cursorPointer`} onClick={() => { setIsDeleteProtocol(true); setDeleteProtocolIndex(index) }} /></span>
                      </div>
                    )
                  }}
                </ViewportList>
              }
              {protocolData.length >= 1 && <HiPencil  data-testid="edit_protocol" className={`icon-black rightIcon cursorPointer`} onClick={() => { setIsModalVisible(true); }} />}
              {!(protocolData.length >= 1) &&
                <div onClick={() => { setIsModalVisible(true); }}>
                  <span data-testid="add_protocol" className="cursorPointer formAnchorText">+Add</span>
                </div>
              }
            </div>
          </div> */}
          {type === 'TRAINING' && (
            <div style={{ marginTop: '0px' }}>
              <label
                data-testid="question"
                htmlFor="question"
                className={`notification-css-title`}
              >
                Question
              </label>
              <div className="contentBorder cursorPointer">
                {formik.values.questions.length ? (
                  <ViewportList items={formik.values.questions}>
                    {(item: Question, index: any) => (
                      <div
                        key={index}
                        data-testid={`edit-question-${index}`}
                        style={
                          formik.values.questions.length !== index + 1
                            ? { borderBottom: '1px solid grey' }
                            : {}
                        }
                      >
                        <div
                          onClick={() => {
                            setSelectedData(item);
                            setIsQuestionModal(true);
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0px 5px',
                            fontSize: '15px',
                          }}
                        >
                          <div className="contentHeading">{item.question}</div>
                          <div style={{ display: 'flex' }}>
                            <div className="contentHeading">
                              {item.options.length} answers
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '5px',
                              }}
                            >
                              {' '}
                              <IoChevronForward
                                size="1.5rem"
                                style={{ marginRight: '10px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ViewportList>
                ) : (
                  <div
                    className="contentHeading greyText"
                    style={{ padding: '2px', marginLeft: '10px' }}
                    onClick={() => {
                      setSelectedData(null);
                      setIsQuestionModal(true);
                    }}
                  >
                    None added
                  </div>
                )}
              </div>
              <div
                className={`protocolCalculationPad contentHeading newProtocolBorder`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedData(null);
                  setIsQuestionModal(true);
                }}
                data-testid="add_question"
              >
                <span>
                  <HiPlus
                    data-testid="add_question_plus"
                    className="text-icon cursorPointer"
                  />
                  Add
                </span>
              </div>
            </div>
          )}
          <label htmlFor="type" className={`notification-css-title`}>
            Message
          </label>
          <InputTextarea
            className="form-control-general"
            id="message"
            name="message"
            required={true}
            data-testid="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            style={{ height: '110px', verticalAlign: 'top' }}
          />
          {department.subDeps && department.subDeps.length > 0 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {formik.values.pairedDeps.length} /{' '}
                  {department.subDeps.length}
                  <span
                    onClick={() =>
                      formik.setFieldValue('pairedDeps', department.subDeps)
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that the user subscribes to.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {formik.values.pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: '200px',
                  border:
                    formik.values.pairedDeps.length === 0
                      ? '0px'
                      : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={formik.values.pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === formik.values.pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => handleRemoveDepartment(item, e)}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Attachments</h5>
          <div className="infolist" style={{ display: 'block' }}>
            <span className="contentText" style={{ fontSize: '18px' }}>
              Add Attachment
            </span>
            <DragDropPreviewPage
              handleChangefiles={(e: any) => {
                formik.setFieldValue('attachments', e);
              }}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loading type="bubbles" message={loadingMessage} />}
    </div>
  );
};

export default NotificationCreatePage;
