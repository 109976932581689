import { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { BiCopy } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import MedicationItem from '../../../../../data/model/MedicationItem';
import {
  ResponseType,
  fetchCategoriesWithProtocols,
  fetchMedications,
  getDepartments,
} from '../../../../../data/AmplifyDB';
import {
  copyMedicationFromDeptoDep,
  replaceOldIDsToNew,
  replaceOldIdToNewProtocol,
} from '../../../../../data/AmplifyActions';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { globals } from '../../../../_global/common/Utils';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const MedicationCopy = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const [includeDoses, setIncludeDoses] = useState(false);

  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);

  const [info, setInfo] = useState<any>({
    departmentFrom: null,
    departmentTo: null,
    medicationListFrom: [],
    medicationListTo: [],
    protocolFrom: null,
    protocolTo: null,
    protocolListFrom: [],
    protocolListTo: [],
  });

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadData = async (
    department: DepartmentItem,
    isFrom: boolean
  ): Promise<VitalItem[] | null> => {
    const response = await fetchMedications(department.id);
    const response2 = await fetchCategoriesWithProtocols(department.id);
    if (
      response.type === ResponseType.Success &&
      response2.type === ResponseType.Success
    ) {
      let medication = response.data;
      let protocols = response2.data[1];
      /* Filter out the protocols that are not vital */
      if (isFrom)
        protocols = protocols.filter((item: ProtocolItem) => {
          let meds = item.getModel().medicationIDs;
          let drips = item.getModel().dripIDs;
          if (meds && drips) return meds.length > 0 || drips.length > 0;
          return false;
        });
      return [medication, protocols];
    }
    return null;
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  useEffect(() => {
    if (info.departmentFrom && info.departmentTo) {
    }
  }, [info.departmentFrom, info.departmentTo]);

  const handleBack = () => {
    navigate(`/actions/copy`);
  };

  const handleDepartmentSelected = async (
    department: DepartmentItem,
    isFrom: boolean
  ) => {
    let results = await loadData(department, isFrom);
    let medication = results ? results[0] : [];
    let protocols = results ? results[1] : [];
    if (isFrom)
      setInfo({
        ...info,
        departmentFrom: department,
        medicationListFrom: medication,
        protocolListFrom: protocols,
      });
    else
      setInfo({
        ...info,
        departmentTo: department,
        medicationListTo: medication,
        protocolListTo: protocols,
      });
  };

  useEffect(() => {
    let l = [...departments];
    l.sort((a, b) => a.name.localeCompare(b.name));
    /* Remove the current department from the list */
    l = l.filter(
      (item) =>
        item.id !== info.departmentFrom?.id && item.id !== info.departmentTo?.id
    );
    setList(l);
  }, [info]);

  const handleRouteToMedication = (department: DepartmentItem) => {};

  const handleSubmit = async () => {
    if (info.departmentFrom === null || info.departmentTo === null) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    } else if (info.medicationListFrom.length === 0) {
      alert(
        'Error: trying to copy an EMPTY list of medication\nFrom: ' +
          info.departmentFrom.name +
          '\nTo: ' +
          info.departmentTo.name
      );
      return;
    }
    let result = await copyMedicationFromDeptoDep(
      info.departmentFrom,
      info.departmentTo,
      includeDoses
    );
    if (result.type === ResponseType.Success) {
      let results = result.data as number;
      let isConfirmed = window.confirm(
        `Copied ${results} medication from ${info.departmentFrom.name} to ${info.departmentTo.name}, route to ${info.departmentTo.name} Equipment?`
      );
      if (isConfirmed) {
        handleRouteToMedication(info.departmentTo);
      }
    } else {
      if (globals.debug)
        console.log(
          'Copy medication from ' +
            info.departmentFrom.name +
            ' to ' +
            info.departmentTo.name +
            ' failed: ',
          result.data
        );
    }
  };

  const handleChangeProtocol = async () => {
    if (info.departmentFrom === null || info.departmentTo === null) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    } else if (info.protocolListFrom === null || info.protocolListTo === null) {
      if (globals.debug) console.log('Error: missing protocol', info);
      alert('Please fill out all fields');
      return;
    }
    let n = 0;
    let result = await replaceOldIDsToNew(
      info.protocolListTo,
      info.departmentFrom,
      info.departmentTo
    );
    if (result.type === ResponseType.Success) {
      let results = result.data as number;
      if (globals.debug)
        console.log('Replaced', results, 'ids for medications and drips');
      n += results;
      // let isConfirmed = window.confirm(`Copied ${results} medication from ${info.departmentFrom.name} to ${info.departmentTo.name}, route to ${info.departmentTo.name} Equipment?`);
      // if(isConfirmed) {
      //     handleRouteToMedication(info.departmentTo);
      // }
    } else {
      if (globals.debug)
        console.log(
          'Copy medication from ' +
            info.departmentFrom.name +
            ' to ' +
            info.departmentTo.name +
            ' failed: ',
          result.data
        );
    }
    /* Loop through every old protocol and replace the old id with the new id */
    for (let i = 0; i < info.protocolListFrom.length; i++) {
      let oldProtocol: ProtocolItem = info.protocolListFrom[i];
      let newProtocol = info.protocolListTo.find(
        (item: ProtocolItem) => item.name === oldProtocol.name
      );
      if (!newProtocol) {
        if (globals.debug)
          console.log('Error: could not find protocol', oldProtocol.name);
        continue;
      }
      if (globals.debug)
        console.log('Old protocol', oldProtocol, 'New protocol', newProtocol);
      let result = await replaceOldIdToNewProtocol(
        newProtocol,
        oldProtocol,
        info.departmentTo
      );
      if (result.type === ResponseType.Success) {
        let results = result.data as number;
        n += results;
        if (globals.debug)
          console.log(
            'Replaced',
            results,
            'ids for protocol',
            oldProtocol.name,
            'to',
            newProtocol.name
          );
      } else {
        if (globals.debug)
          console.log(
            'Copy protocol from ' +
              oldProtocol.name +
              ' to ' +
              newProtocol.name +
              ' failed: ',
            result.data
          );
      }
    }

    let isConfirmed = window.confirm(
      `Updated ${n} medication protocols from ${info.departmentFrom.name} to ${info.departmentTo.name} to the new IDs`
    );
  };

  const isSaveValid = useMemo(() => {
    return (
      info.departmentFrom !== null &&
      info.departmentTo !== null &&
      info.medicationListFrom.length > 0
    );
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Copy Actions'}
        name={'Copy Medications'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={true}
        isSaveActive={isSaveValid}
        handleSave={handleSubmit}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <Row>
        <Col sm={6}>
          {' '}
          {/* Left column */}
          {/* <div style={{display: "flex", flexDirection: 'column'}}>
                    <label htmlFor="Name" className="ketamine-general-label">
                        Include Doses: <span className="required-field">*</span>
                    </label>
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        style={{marginTop: 5, marginLeft: 10}}
                        label=""
                        checked={includeDoses}
                        onChange={() => setIncludeDoses(!includeDoses)}
                    />
                    </div> */}
          <label className="ketamine-general-label">
            Department FROM: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to copy medications from.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={list}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            onChange={(option: DepartmentItem) => {
              handleDepartmentSelected(option, true);
            }}
            onClear={() => {
              setInfo({
                ...info,
                departmentFrom: null,
                medicationListFrom: [],
              });
            }}
            placeholder="Search department..."
          />
          <label
            className="ketamine-general-label"
            style={{ marginTop: '20px' }}
          >
            Department TO: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to copy medications to.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={list}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            onChange={(option: DepartmentItem) => {
              handleDepartmentSelected(option, false);
            }}
            onClear={() => {
              setInfo({ ...info, departmentTo: null, medicationListTo: [] });
            }}
            placeholder="Search department..."
          />
          {includeDoses && info.departmentFrom && info.departmentTo && (
            <>
              {/* <label>Protocol FROM:</label>
                        <SearchableDropdown<ProtocolItem>
                            id="searchDropdown"
                            options={info.protocolListFrom}
                            labelField={option => option.name + " - " + option.uid}
                            valueField={option => option.name + " - " + option.uid}
                            onChange={(option: ProtocolItem) => {
                                setInfo({...info, protocolFrom: option});
                            }}
                            onClear={() => {
                                setInfo({...info, protocolFrom: null});
                            }}
                            placeholder="Search protocol..."
                        />    
                        <label>Protocol TO:</label>
                        <SearchableDropdown<ProtocolItem>
                            id="searchDropdown"
                            options={info.protocolListTo}
                            labelField={option => option.name + " - " + option.uid}
                            valueField={option => option.name + " - " + option.uid}
                            onChange={(option: ProtocolItem) => {
                                setInfo({...info, protocolTo: option});
                            }}
                            onClear={() => {
                                setInfo({...info, protocolTo: null});
                            }}
                            placeholder="Search protocol..."
                        />     */}
              <Button
                className="primary-button-small"
                type="submit"
                onClick={handleChangeProtocol}
              >
                <span>
                  <BiCopy color={'white'} className="icon-size" />
                </span>
                Update ID
              </Button>
            </>
          )}
        </Col>
        <Col sm={6}>
          {' '}
          {/* Right column */}
          <label style={{ marginTop: 20 }} className="ketamine-general-label">
            FROM Options - {info.medicationListFrom.length} items:
          </label>
          <hr style={{ margin: 10 }} />
          {info.medicationListFrom.map(
            (item: MedicationItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Concentrations: {item.concentrations?.length}</h6>
                </div>
              </div>
            )
          )}
          <label style={{ marginTop: 20 }} className="ketamine-general-label">
            TO Options - {info.medicationListTo.length} items:
          </label>
          <hr style={{ margin: 10 }} />
          {info.medicationListTo.map((item: MedicationItem, index: number) => (
            <div
              className="string-list-item"
              key={index.toString()}
              style={{ marginTop: 6, marginBottom: 6 }}
            >
              <div>
                <h6>Name: {item.name}</h6>
                <h6>Concentrations: {item.concentrations?.length}</h6>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default MedicationCopy;
