import React from 'react';

/* 09-29-23 Arul: Created PdfScreen component  for Protocol detail page*/
const PDFScreen = (props: any) => {
  return (
    <div
      className="pdfContainer"
      style={{
        ...props.style,
      }}
    >
      <embed src={props.pdf} title="PDF Viewer" width="100%" height="100%" />
    </div>
  );
};

export default PDFScreen;
