import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa6';
import MedicationItem, {
  getConcentrationString,
} from '../../../../../../data/model/MedicationItem';
import { globals } from '../../../../../_global/common/Utils';
import ConcentrationDialog from '../../../../../components/Modal/ConcentrationDialogModal';
import { MedicationConcentration } from '../../../../../../models';
import AddRouteModal from '../../../../../components/Modal/AddRouteModal';

interface MedicationDoseProps {
  medData: any;
  index: number;
  onChange: (data: any, medication: MedicationItem, index: number) => void;
  onDelete?: (medication: MedicationItem, index: number) => void;
}
const NewMedicationInput: React.FC<MedicationDoseProps> = (props) => {
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [concentrationDialogVisible, setConcentrationDialogVisible] =
    useState(false);
  const [isRouteModal, setIsRouteModal] = useState(false);
  const { medData, index, onChange, onDelete } = props;
  const { medication, data } = medData;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name,
      concentrations: data.concentrations,
      routes: data.routes,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      concentrations: Yup.array(),
      routes: Yup.array().of(Yup.string()).required('Required'),
    }),

    onSubmit: (values) => {
      if (globals.debug) console.log('values', values);
    },
  });

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  useEffect(() => {
    onChange(formik.values, medication, index);
  }, [formik.values]);

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationSet = async (
    amount1: any,
    amount2: any,
    unit1: any,
    unit2: any
  ) => {
    setConcentrationDialogVisible(false);
    let newConcentration: MedicationConcentration = {
      firstAmnt: amount1,
      secAmnt: amount2,
      firstUnit: unit1,
      secUnit: unit2,
    };

    formik.setFieldValue('concentrations', [
      ...formik.values.concentrations,
      newConcentration,
    ]);
  };

  const onConcentration = () => {
    setConcentrationDialogVisible(true);
  };

  const onRoutesClick = () => {
    setIsRouteModal(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <ConcentrationDialog
        dialogVisible={concentrationDialogVisible}
        handleSet={handleConcentrationSet}
        setDialogVisible={setConcentrationDialogVisible}
        headerText="Concentration"
      />

      {/* Add Route Popup */}
      <AddRouteModal
        dialogVisible={isRouteModal}
        handleSet={(route: string) => {
          formik.setFieldValue('routes', [...formik.values.routes, route]);
          setIsRouteModal(false);
        }}
        onNewRouteCancel={() => {
          setIsRouteModal(false);
        }}
        headerText="New Administration Route"
      />

      <div
        style={{ display: 'flex', marginTop: '5px' }}
        className="ketamine-general-label"
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          {/* <div style={{ marginRight: '10px' }}>Protocol:</div>
          <div style={{ fontWeight: '500' }}>{protocol.name}</div> */}
        </div>
        {onDelete && (
          <FaTrash
            className="cursorPointer"
            color={'#8c1010'}
            onClick={() => {
              onDelete(medication, index);
            }}
          />
        )}
      </div>
      <div className={`filled-input-container  ${''}`}>
        <div className={` ${'sideoutFieldPad'}`}>
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <label htmlFor={''} className={` rowLabelWidth sidebarLabel`}>
              Name:
            </label>
            <InputText
              type="text"
              className={`input-control`}
              placeholder="Enter title..."
              data-testid="rangeHigh"
              value={formik.values.name}
              onChange={(e) => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
          </div>
        </div>
        <div className={`${'doseGridPad'}`}>
          <div className="sidebarInputContent">
            <label htmlFor={''} className={` rowLabelWidth sidebarLabel`}>
              Route:
            </label>
            <div
              data-div-id="outside"
              className={`selectionArea-hover `}
              onClick={() => {
                onRoutesClick();
              }}
            >
              {formik.values.routes.length !== 0 && (
                <ViewportList items={formik.values.routes}>
                  {(item: string, index) => {
                    return (
                      <div
                        data-div-id="grey"
                        key={index}
                        className={`selectedGreybox`}
                      >
                        <div>{item}</div>
                        <span
                          className={`close_icon_color ${'cursorPointer'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            formik.setFieldValue(
                              'routes',
                              formik.values.routes.filter(
                                (r: string) => r !== item
                              )
                            );
                          }}
                        >
                          <AiOutlineCloseCircle className="cursorPointer icon-normal" />
                        </span>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
            <label htmlFor={''} className={` rowLabelWidth sidebarLabel`}>
              Concentrations:
            </label>
            <div
              data-div-id="outside"
              className={`selectionArea-hover `}
              onClick={() => {
                onConcentration();
              }}
            >
              {formik.values.concentrations.length !== 0 && (
                <ViewportList items={formik.values.concentrations}>
                  {(item: MedicationConcentration, index) => {
                    return (
                      <div
                        data-div-id="grey"
                        key={index}
                        className={`selectedGreybox`}
                      >
                        <div>{getConcentrationString(item)}</div>
                        <span
                          className={`close_icon_color ${'cursorPointer'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            formik.setFieldValue(
                              'concentrations',
                              formik.values.concentrations.filter(
                                (r: MedicationConcentration) => r !== item
                              )
                            );
                          }}
                        >
                          <AiOutlineCloseCircle className="cursorPointer icon-normal" />
                        </span>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewMedicationInput;
