// Note: EditDB for CheckList/Form
// Date: 01/24/2024
// Author: Guruprasad Venkatraman

import { DataStore } from 'aws-amplify';
import { ResponseType, Response } from '../AmplifyDB';
import { ProgressStatus } from '../../API';
import DepartmentItem from '../model/DepartmentItem';
import { Form, FormGroup } from '../../models';
import FormItem from '../model/FormItem';

export type CheckListDB = {
  name: string;
  items: FormGroup[];
  departmentID: string;
  activeID?: string | null | undefined;
  version?: string | null | undefined;
  status?:
    | ProgressStatus
    | 'DRAFT'
    | 'ACTIVE'
    | 'ARCHIVE'
    | 'DELETED'
    | null
    | undefined;
  modifiedBy?: string;
  createdBy: string;
};

/**
 * This function will check if the CheckList is a draft version and delete it
 * @param CheckListItem The CheckList to check
 * @returns Success if ready to create a new CheckList or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Form[];
    if (isActive)
      results = await DataStore.query(Form, (f) =>
        f.and((f) => [f.activeID.eq(id), f.status.eq('DRAFT')])
      );
    else
      results = await DataStore.query(Form, (f) =>
        f.and((f) => [f.status.eq('DRAFT'), f.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCheckList = results[0];
    if (dbCheckList.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Form, dbCheckList.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The dbCheckList did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCheckList,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new dbCheckList in the database and choose the version
 * @param dbCheckList CheckListDB JSON format
 * @returns The successful CheckListItem or the error
 */
export const createCheckList = async (
  checkList: CheckListDB | FormItem,
  previousItem?: FormItem
): Promise<Response> => {
  try {
    let json: CheckListDB;
    if (checkList instanceof FormItem) {
      json = {
        name: checkList.name,
        status: checkList.status,
        activeID: checkList.activeID,
        departmentID: checkList.depID,
        version: checkList.version != null ? checkList.version : 'v1.0.0',
        items: checkList.items != null ? checkList.items : [],
        createdBy: checkList.model.createdBy ? checkList.model.createdBy : '',
        modifiedBy: checkList.modifiedBy ? checkList.modifiedBy.id : undefined,
      };
    } else json = checkList;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let c: Form;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbCheck = await DataStore.query(Form, previousItem.uid);
      if (dbCheck == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Checklist does not exist could not update',
        };
      }

      c = await DataStore.save(
        Form.copyOf(dbCheck, (updated) => {
          updated.name = json.name;
          updated.items = json.items;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      c = await DataStore.save(
        new Form({
          name: json.name,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          version: json.version,
          items: json.items,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    let checkListItem = new FormItem(c);
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    console.error('Error in checkList function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Publishes draft checkList for a given department.
 * @param department - The department for which to publish draft checkList.
 * @returns A Promise that resolves to a Response object.
 */
export const publishCheckListDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCheckList = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftCheckList.length; i++) {
      let checkList: Form = draftCheckList[i];
      let response: Response = await publishCheckList(checkList);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: checkList,
          message: `Published Folder: ${checkList.name}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the checkList to the database
 *    1. Create a new ARCHEIVED checkList based on the current ACTIVE checkList
 *    2. Update the ACTIVE checkList with the new information
 *    3. Delete the DRAFT checkList
 * @param draftCheckListItem The checkList to publish
 */

export const publishCheckList = async (
  draftCheckListItem: Form
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the checkList is configured correctly as a draft version */
    if (draftCheckListItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The checkList is not a draft version',
      };
    }

    let activeCheckList: Form;

    /* Use case 1: Creating the FIRST active version */
    if (draftCheckListItem.activeID == null) {
      /* Update the draft checkList to be active */
      activeCheckList = await DataStore.save(
        Form.copyOf(draftCheckListItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active checkList item */
      let id: string = draftCheckListItem.activeID;
      let curCheckList = await DataStore.query(Form, id);

      /* Base Case 3 -- check if the active checkList exists */
      if (curCheckList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active checkList does not exist',
        };
      }

      let newArcheivedCheckList = new FormItem(curCheckList);
      newArcheivedCheckList.status = ProgressStatus.ARCHIVE;
      newArcheivedCheckList.activeID = curCheckList.id;

      // 2. Create a new ARCHEIVED checkList based on the current ACTIVE checkList
      let archiveResult: Response = await createCheckList(
        newArcheivedCheckList
      );
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedCheckList = archiveResult.data as FormItem;

      // 2. Update the ACTIVE checkList with the new information
      activeCheckList = await DataStore.save(
        Form.copyOf(curCheckList, (updated) => {
          updated.name = draftCheckListItem.name;
          updated.items = draftCheckListItem.items;

          updated.createdBy = draftCheckListItem.createdBy;
          updated.modifiedBy = draftCheckListItem.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCheckListItem.version;
        })
      );

      // 3. Delete the DRAFT checkList
      let draftCheckList = await DataStore.delete(Form, draftCheckListItem.id);
      if (draftCheckList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft checkList does not exist',
        };
      }
    }

    let checkListItem = new FormItem(activeCheckList);
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteCheckList = async (
  checkListItem: FormItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = checkListItem.uid;
    if (isSoft) {
      let checkList = await DataStore.query(Form, id);
      if (checkList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList does not exist',
        };
      }

      let result = await DataStore.save(
        Form.copyOf(checkList, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList did not update correctly',
        };
      }
    } else {
      let checkList = await DataStore.delete(Form, id);
      if (checkList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCheckListDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCheckListDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Form, (f) =>
      f.and((f) => [
        // f.or((f) => [f.status.eq('DRAFT'), f.status.eq('DELETED')]),
        f.status.eq('DRAFT'),
        f.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      if (model.activeID == null) message = `Created CheckList: ${model.name}`;
      else message = `Updated CheckList: ${model.name}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentCheckListDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCheckList = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftCheckList.length; i++) {
      let checkList: Form = draftCheckList[i];
      await DataStore.delete(checkList);
      updates.push({
        model: checkList,
        message: `Removed Checklist: ${checkList.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
