// Note: EditDB for Vital
// Date: 01/19/2024
// Author: Guruprasad Venkatraman

import { DataStore } from 'aws-amplify';
import { Vitals } from '../../models';
import { ResponseType, Response } from '../AmplifyDB';
import { ProgressStatus } from '../../API';
import VitalItem from '../model/VitalItem';
import DepartmentItem from '../model/DepartmentItem';
import { globals } from '../../ui/_global/common/Utils';

export type VitalDB = {
  title: string;
  optionItems: any[];
  departmentID: string;
  activeID: string | null | undefined;
  version: string | null | undefined;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  modifiedBy?: string;
  createdBy: string;
};

/**
 * This function will check if the Vital is a draft version and delete it
 * @param vitalItem The vital to check
 * @returns Success if ready to create a new vital or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Vitals[];
    if (isActive)
      results = await DataStore.query(Vitals, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.activeID.eq(id)])
      );
    else
      results = await DataStore.query(Vitals, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbVital = results[0];
    if (dbVital.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Vitals, dbVital.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbVital,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new vital in the database and choose the version
 * @param vital VitalDB JSON format
 * @returns The successful vitalItem or the error
 */
export const createVital = async (
  vital: VitalDB | VitalItem,
  previousItem?: VitalItem
): Promise<Response> => {
  try {
    let json: VitalDB;
    if (vital instanceof VitalItem) {
      json = {
        title: vital.name,
        status: vital.status,
        activeID: vital.activeID,
        departmentID: vital.depID,
        version: vital.version != null ? vital.version : 'v1.0.0',
        optionItems: vital.options != null ? vital.options : [],
        createdBy: vital.model.createdBy ? vital.model.createdBy : '',
        modifiedBy: vital.modifiedBy ? vital.modifiedBy.id : undefined,
      };
    } else json = vital;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let v: Vitals;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbVital = await DataStore.query(Vitals, previousItem.uid);
      if (dbVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Vital does not exist could not update',
        };
      }

      v = await DataStore.save(
        Vitals.copyOf(dbVital, (updated) => {
          updated.title = json.title;
          updated.options = json.optionItems;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      v = await DataStore.save(
        new Vitals({
          title: json.title,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          version: json.version,
          options: json.optionItems,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    if (globals.debug) console.log('Created vital:', v);
    let vitalItem = new VitalItem(v);
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    console.error('Error in createVital function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Publishes draft vitals for a given department.
 * @param department - The department for which to publish draft vital.
 * @returns A Promise that resolves to a Response object.
 */
export const publishVitalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftVitals = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftVitals.length; i++) {
      let vital: Vitals = draftVitals[i];
      let response: Response = await publishVital(vital);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: vital,
          message: `Published Folder: ${vital.title}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the vital to the database
 *    1. Create a new ARCHEIVED vital based on the current ACTIVE vital
 *    2. Update the ACTIVE vital with the new information
 *    3. Delete the DRAFT vital
 * @param draftVitalItem The vital to publish
 */

export const publishVital = async (
  draftVitalItem: Vitals
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the vital is configured correctly as a draft version */
    if (draftVitalItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The vital is not a draft version',
      };
    }

    let activeVital: Vitals;

    /* Use case 1: Creating the FIRST active version */
    if (draftVitalItem.activeID == null) {
      /* Update the draft vital to be active */
      activeVital = await DataStore.save(
        Vitals.copyOf(draftVitalItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
      if (globals.debug)
        if (globals.debug)
          console.log('Created the first release of the vital:', activeVital);
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active vital item */
      let id: string = draftVitalItem.activeID;
      let curVital = await DataStore.query(Vitals, id);

      /* Base Case 3 -- check if the active vital exists */
      if (curVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active vital does not exist',
        };
      }

      let newArcheivedVital = new VitalItem(curVital);
      newArcheivedVital.status = ProgressStatus.ARCHIVE;
      newArcheivedVital.activeID = curVital.id;

      // 2. Create a new ARCHEIVED vital based on the current ACTIVE vital
      let archiveResult: Response = await createVital(newArcheivedVital);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedVital = archiveResult.data as VitalItem;

      // 2. Update the ACTIVE vital with the new information
      activeVital = await DataStore.save(
        Vitals.copyOf(curVital, (updated) => {
          updated.title = draftVitalItem.title;
          updated.options = draftVitalItem.options;

          updated.modifiedBy = draftVitalItem.modifiedBy;
          updated.createdBy = draftVitalItem.createdBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftVitalItem.version;
        })
      );

      // 3. Delete the DRAFT vital
      let draftVital = await DataStore.delete(Vitals, draftVitalItem.id);
      if (draftVital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft vital does not exist',
        };
      }
    }

    let vitalItem = new VitalItem(activeVital);
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteVital = async (
  vitalItem: VitalItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = vitalItem.uid;
    if (isSoft) {
      let vital = await DataStore.query(Vitals, id);
      if (vital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital does not exist',
        };
      }

      let result = await DataStore.save(
        Vitals.copyOf(vital, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital did not update correctly',
        };
      }

      if (globals.debug) console.log('Soft Deleted vital:', vital);
    } else {
      let vital = await DataStore.delete(Vitals, id);
      if (vital == null) {
        return {
          type: ResponseType.Failure,
          data: 'The vital does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted vital:', vital);
    }
    return {
      type: ResponseType.Success,
      data: vitalItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isVitalDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getVitalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Vitals, (v) =>
      v.and((v) => [
        // v.or((v) => [v.status.eq('DRAFT'), v.status.eq('DELETED')]),
        v.status.eq('DRAFT'),
        v.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if (model.status === 'DELETED') message = `Deleted Vital: ${model.title}`;
      if (model.activeID == null) message = `Created Vital: ${model.title}`;
      else message = `Updated Vital: ${model.title}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentVitalDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftVitals = await DataStore.query(Vitals, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    if (globals.debug) console.log('Found draft vitals:', draftVitals.length);
    for (let i = 0; i < draftVitals.length; i++) {
      let vital: Vitals = draftVitals[i];
      if (globals.debug) console.log('Removing vitals:', vital.title);
      await DataStore.delete(vital);
      updates.push({
        model: vital,
        message: `Removed Folder: ${vital.title}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
