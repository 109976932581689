import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiArrowBack, BiSolidChevronRight } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { sideoutList } from '../../_global/constants/Protocol_constants';
import ConfirmModal from '../Modal/ConfirmModal';
import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import { validatePointerID } from '../../../data/functions/ProtocolDB';
import { ProgressStatus } from '../../../API';
import EquipmentItem from '../../../data/model/EquipmentItem';
import FormItem from '../../../data/model/FormItem';
import SearchBar from '../Search/SearchBar';
import {
  fetchDripsFromAPI,
  fetchMedicationsFromAPI,
} from '../../../data/GraphQL_API';
import { handleHinckleyCache } from '../../../store/actions';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

interface AddProtocolSideoutProps {
  handleClose: () => void;
  handleAdd?: (
    items: ProtocolItem[] | EquipmentItem[] | FormItem[],
    type: string
  ) => void | any;
  details?: any;
  title?: string;
  isVisible?: boolean;
  isEditMode?: any;
  page?: any;
  protocol?: ProtocolItem;
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const AddProtocolSideout: React.FC<AddProtocolSideoutProps> = (props) => {
  const { isVisible, handleClose, isEditMode, page, handleAdd, protocol } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const [isSecondPage, setIsSecondPage] = useState(false);
  const [isNewMedication, setIsNewMedication] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const [hmDB, setHmDB] = useState<any>({
    medications: [],
    infusions: [],
    electrical: [],
    equipment: [],
    vitals: [],
    checklists: [],
  });

  const editProtocol = useSelector(
    (state: any) => state?.protocol?.editProtocol
  );
  const departmentItem = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const pairedProtocolList: any = [];
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [selectedProtocolList, setSelectedProtocolList] = useState<any>([]);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const hinckleyState = useSelector((state: any) => state.hinckley);

  useEffect(() => {
    if (protocol && selectedData.type !== 'Paired Protocol') {
      setSelectedProtocolList(protocol);
    }
    setSelectedProtocolList(editProtocol?.pairedProtocols);
  }, [protocol, editProtocol?.pairedProtocols, selectedData.type]);

  useEffect(() => {
    if (isVisible) {
      setHmDB({
        medications: hinckleyState.medications,
        infusions: hinckleyState.infusions,
        electrical: hinckleyState.electricals,
        equipment: hinckleyState.equipment,
        vitals: hinckleyState.vitals,
        checklists: hinckleyState.checklists,
      });
    }
  }, [isVisible, hinckleyState]);

  const loadHinckleyDB = async () => {
    console.log('Loading Hinckley DB ' + selectedData.type + '...');
    if (selectedData.type === 'Medication') {
      let response: Response = await fetchMedicationsFromAPI(
        database.department,
        HM_DB_ID
      );
      if (response.type === ResponseType.Success) {
        setHmDB({
          ...hmDB,
          medications: response.data,
        });
        dispatch<any>(
          handleHinckleyCache({
            medications: response.data,
          })
        );
        console.log('Successfully fetched medications from API', response.data);
      } else {
        console.error('Failed to fetch medications from API', response.data);
      }
    } else if (selectedData.type === 'Infusion') {
      let response: Response = await fetchDripsFromAPI(
        database.department,
        HM_DB_ID
      );
      if (response.type === ResponseType.Success) {
        setHmDB({
          ...hmDB,
          infusions: response.data,
        });
        dispatch<any>(
          handleHinckleyCache({
            infusions: response.data,
          })
        );
      } else {
        console.error('Failed to fetch infusions from API', response.data);
      }
    }
  };

  /* 11-07-23 Arul: handle function for radio buttton onChange */
  const handleCheckboxChange = (option: any, item: any) => {
    if (selectedData.type === 'Checklist') {
      if (selectedOptions.includes(option)) {
        // If the option is already selected, remove it
        setSelectedList([]);
        return setSelectedOptions([]);
      } else {
        // If the option is not selected, add it
        setSelectedList(item);
        return setSelectedOptions(option);
      }
    } else {
      if (selectedOptions.includes(option)) {
        // If the option is already selected, remove it
        setSelectedList(
          selectedList?.filter((item: any) => item.name !== option)
        );
        return setSelectedOptions(
          selectedOptions?.filter((item: any) => item !== option)
        );
      } else {
        // If the option is not selected, add it
        setSelectedList([...selectedList, item]);
        return setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  /* 10-13-23 Praveen: function for handling back to parent side out*/
  const handleBack = () => {
    setIsSecondPage(false);
    setSelectedData('');
    setSelectedOptions([]);
    setSelectedList([]);
  };

  /* 10-13-23 Praveen: handling fuction to navigate edit protocol page*/
  const handleNavigateToEditPage = (data: any) => {
    const state = {
      selectedProtocol: protocol === undefined ? null : protocol,
      value: data,
      type: selectedData?.type,
      editType: 'edit',
      editMode: true,
      page,
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
  };

  /* 10-13-23 Praveen: handled to navigate new protocol page*/
  const handleNavigateToNewPage = (selectedDataType: any) => {
    const state = {
      selectedProtocol: protocol,
      value: '',
      type: selectedDataType,
      editType: 'new',
      editMode: isEditMode,
      page,
    };
    navigate(`/protocol/edit/new-protocol`, { state });
  };

  /* 7-22-24 Hazlett: handled to navigate create from Hinckley page*/
  const handleNavigateToCreateFromHinckley = (data: any) => {
    const state = {
      selectedProtocol: protocol,
      value: data,
      type: selectedData.type,
      editType: 'new_hm',
      editMode: isEditMode,
      page,
    };
    navigate(`/protocol/edit/new-protocol`, { state });
  };

  const dataList = useMemo(() => {
    if (selectedData.type === 'Medication') {
      console.log('HM DB:', hmDB);
      if (isNewMedication) return hmDB.medications;
      return database.medications;
    } else if (selectedData.type === 'Paired Protocol') {
      let sortedArray = departmentItem?.protocols.sort(
        (a: ProtocolItem, b: ProtocolItem) => a.name.localeCompare(b.name)
      );
      sortedArray = sortedArray?.filter((item: ProtocolItem) => {
        return item.uid !== protocol?.uid;
      });
      return sortedArray;
    } else if (selectedData.type === 'Infusion') {
      return database.infusions;
    } else if (selectedData.type === 'Electrical') {
      return database.electrical;
    } else if (selectedData.type === 'Equipment') {
      return database.equipment;
    } else if (selectedData.type === 'Vital') {
      return database.vitals;
    } else if (selectedData.type === 'Checklist') {
      return database.checklists;
    }
  }, [editProtocol, selectedData, isNewMedication, hmDB.medications]);

  useEffect(() => {
    setOptions(dataList);
  }, [dataList]);

  /* 10-13-23 Praveen: For filtering the protocol sub item list */
  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        const filterData = dataList?.filter((item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filterData);
      } else {
        setOptions(dataList);
      }
    },
    [value, dataList, setOptions]
  );

  /* 10-13-23 Praveen: to handle the search input*/
  const handleSearch = (text: string) => {
    setValue(text);
    handleFilter(text);
  };

  /* 11-07-23 Arul: matching paired protocol list */
  const pariredProtocolMatch = useMemo(() => {
    // if (selectedProtocolList?.length === 2) {
    //  setMaxHeight("45%");
    // } else if (selectedProtocolList?.length === 3) {
    //  setMaxHeight("40%");
    // } else if (selectedProtocolList?.length >= 4) {
    //  setMaxHeight("30%");
    // }
    if (selectedData.type === 'Paired Protocol') {
      const result = options?.filter(
        (item: any) =>
          !selectedProtocolList?.some((e: any) => e.name === item.name)
      );
      return result;
    } else {
      return [];
    }
  }, [options, selectedProtocolList]);

  /* 11-07-23 Arul: select the paired protocol */
  const handleDeletePairedProtocol = () => {
    setIsDelete(false);
    const data = selectedProtocolList?.filter(
      (item: any) => item.name !== deleteItem.name
    );
    setSelectedProtocolList(data);
  };

  /**
   * Author: Guruprasad Venkatraman (01-29-2024)
   * Handles the addition of a paired protocol.
   * If the selected data type is not "Checklist", it calls the handleAdd function with the selected list of uids, selected data type, and selected list.
   * If the selected data type is "Checklist", it calls the handleAdd function with the selected list uid, selected data type, and selected list.
   * After adding the protocol, it navigates to the protocol detail page with the selected protocol state.
   * Finally, it resets the state and closes the sideout.
   */
  const handleAddPairedProtocol = async () => {
    if (handleAdd) {
      handleAdd(selectedList, selectedData.type);
      handleCloseSideouts();
    }
  };

  const handleCloseSideouts = () => {
    setIsSecondPage(false);
    setIsNewMedication(false);
    setIsDelete(false);
    setSelectedData('');
    setSelectedOptions([]);
    setSelectedList([]);
    handleClose();
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleCloseSideouts}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add
      </span>
    </div>
  );

  return (
    <div>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove Paired Protocol?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeletePairedProtocol}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want remove this paired protocol?"
        />
      )}
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleCloseSideouts}
        style={{ width: '25%', minWidth: '400px' }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
        header={customHeader}
      >
        <Sidebar
          visible={isSecondPage}
          position="right"
          onHide={handleCloseSideouts}
          style={{
            width: '25%',
            minWidth: '400px',
          }}
          className={
            isSecondPage ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
          }
        >
          {/* Third Sidebar for New Medication */}
          {selectedData != null && (
            <Sidebar
              visible={isNewMedication}
              position="right"
              onHide={handleCloseSideouts}
              style={{
                width: '25%',
                minWidth: '400px',
              }}
              className={
                isSecondPage ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
              }
            >
              <div style={{ height: '100%', padding: '10px' }}>
                <div
                  className="buttonContainer contentTitleLarge hoverText"
                  onClick={() => {
                    setIsNewMedication(false);
                    setOptions(dataList);
                  }}
                >
                  <span className="headerTilte">
                    <BiArrowBack
                      className="header-icon cursorPointer"
                      style={{ paddingLeft: '4px' }}
                    />
                    <HiPlus
                      className="header-icon "
                      style={{ marginLeft: '8px' }}
                    />{' '}
                    Create New {selectedData.type}
                  </span>
                </div>
                {selectedData.type === 'Paired Protocol' ? (
                  <div className="contentText">
                    Manage paired protocols to this protocol and add new by
                    searching for existing protocols
                  </div>
                ) : (
                  <div className="contentText">
                    Create a new {selectedData?.type?.toLowerCase()} from
                    Hinckley Medical's medication list. This will populate all
                    the concentrations, routes, and etc. The information can
                    always be edited later for your agency.
                  </div>
                )}
                {selectedData.type !== 'Paired Protocol' && (
                  <div>
                    <div className="contentLabelBold">
                      Custom {selectedData?.type}
                    </div>
                    <div
                      onClick={() => handleNavigateToNewPage(selectedData.type)}
                      className="createBtnhover cursorPointer contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder "
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <span>
                        <HiPlus className="text-icon " /> Create Custom{' '}
                        {selectedData.type}
                      </span>
                    </div>
                  </div>
                )}
                <div className="contentLabelBold">
                  Hinckley Medical {selectedData?.type}s
                </div>
                {/* <div className="search-custom-input">
              <span className="icon_search_pad">
                <FiSearch className="icon-normal" />
              </span>
              <InputText
                value={value}
                onChange={handleSearch}
                className="search-inputs"
              />
            </div> */}
                <SearchBar
                  value={value}
                  onChange={handleSearch}
                  placeholder="Search"
                  containerStyle={{ width: '100%' }}
                />
                <div
                  style={{ maxHeight: '60%' }}
                  className="contentBorder protocolCalculationPad secondaryListScroll"
                >
                  {selectedData.type === 'Paired Protocol' ||
                  selectedData.type === 'Checklist' ||
                  selectedData.type === 'Equipment' ? (
                    <ViewportList
                      items={
                        selectedData.type === 'Checklist' ||
                        selectedData.type === 'Equipment'
                          ? options
                          : pariredProtocolMatch
                      }
                    >
                      {(item: any, index: any) => (
                        <div
                          key={item?.name + index}
                          onClick={() => {
                            handleCheckboxChange(item.name, item);
                          }}
                          className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                            selectedData.type === 'Checklist' &&
                            options?.length !== index + 1
                              ? 'contentUnderline '
                              : ''
                          } ${
                            selectedData.type === 'Paired Protocol' &&
                            pairedProtocolList?.length !== index + 1
                              ? 'contentUnderline '
                              : ''
                          }
                      ${
                        selectedData.type === 'Vital' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }
                      ${
                        selectedData.type === 'Equipment' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }`}
                        >
                          <RadioButton
                            inputId="pairedProtocol"
                            name="pairedProtocol"
                            className="radioBtnColor"
                            style={{ marginRight: '10px' }}
                            value={item.name}
                            checked={selectedOptions?.includes(item.name)}
                            onChange={() => {
                              handleCheckboxChange(item.name, item);
                            }}
                          />
                          <div>{item.name}</div>
                          {selectedData.type !== 'Paired Protocol' && (
                            <div className="editIconContainer">
                              <HiPencil
                                className="editIcon"
                                onClick={() => {
                                  handleNavigateToEditPage(item);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </ViewportList>
                  ) : (
                    <div>
                      {options && options.length === 0 && (
                        <div
                          className="contentHeading"
                          style={{
                            color: '#636363',
                          }}
                        >
                          No {selectedData.type} found...
                        </div>
                      )}
                      <ViewportList items={options}>
                        {(item: any, index: any) => (
                          <div
                            key={item + index}
                            onClick={() => {
                              handleNavigateToCreateFromHinckley(item);
                            }}
                            className={`listhover cursorPointer item contentHeading ${
                              options.length !== index + 1
                                ? 'contentUnderline'
                                : ''
                            }`}
                          >
                            {item?.name}
                          </div>
                        )}
                      </ViewportList>
                    </div>
                  )}
                </div>
                {(selectedData.type === 'Paired Protocol' ||
                  selectedData.type === 'Checklist' ||
                  selectedData.type === 'Equipment') && (
                  <div className="btn_Bottom">
                    <Button
                      data-testid="cancel"
                      className="secondary-button btn-rightMargin"
                      onClick={handleCloseSideouts}
                    >
                      X Cancel
                    </Button>
                    <Button
                      data-testid="save"
                      className="primary-button"
                      disabled={!(selectedOptions?.length > 0)}
                      onClick={handleAddPairedProtocol}
                    >
                      + Add
                    </Button>
                  </div>
                )}
              </div>
            </Sidebar>
          )}
          <div style={{ height: '100%', padding: '10px' }}>
            <div
              className="buttonContainer contentTitleLarge hoverText"
              onClick={handleBack}
            >
              <span className="headerTilte">
                <BiArrowBack
                  className="header-icon cursorPointer"
                  style={{ paddingLeft: '4px' }}
                />
                <HiPlus
                  className="header-icon "
                  style={{ marginLeft: '8px' }}
                />{' '}
                {selectedData.name}
              </span>
            </div>
            {selectedData.type === 'Paired Protocol' ? (
              <div className="contentText">
                Manage paired protocols to this protocol and add new by
                searching for existing protocols
              </div>
            ) : (
              <div className="contentText">
                Add a {selectedData?.type} to this protocol either be searching
                already made {selectedData?.type} or by creating a new{' '}
                {selectedData?.type}.
              </div>
            )}
            {selectedData.type !== 'Paired Protocol' && (
              <div>
                <div className="contentLabelBold">
                  Create New {selectedData?.type}
                </div>
                <div
                  onClick={() => {
                    if (selectedData.type === 'Medication') {
                      if (hmDB.medications.length === 0) loadHinckleyDB();
                      setIsNewMedication(true);
                    } else handleNavigateToNewPage(selectedData.type);
                  }}
                  className="createBtnhover cursorPointer contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder "
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <span>
                    <HiPlus className="text-icon " /> Create New{' '}
                    {selectedData.type}
                  </span>
                </div>
              </div>
            )}
            <div className="contentLabelBold">Select {selectedData?.type}</div>
            {/* <div className="search-custom-input">
              <span className="icon_search_pad">
                <FiSearch className="icon-normal" />
              </span>
              <InputText
                value={value}
                onChange={handleSearch}
                className="search-inputs"
              />
            </div> */}
            <SearchBar
              value={value}
              onChange={handleSearch}
              placeholder="Search"
              containerStyle={{ width: '100%' }}
            />
            <div
              style={{ maxHeight: '60%' }}
              className="contentBorder protocolCalculationPad secondaryListScroll"
            >
              {selectedData.type === 'Paired Protocol' ||
              selectedData.type === 'Checklist' ||
              selectedData.type === 'Equipment' ? (
                <ViewportList
                  items={
                    selectedData.type === 'Checklist' ||
                    selectedData.type === 'Equipment'
                      ? options
                      : pariredProtocolMatch
                  }
                >
                  {(item: any, index: any) => (
                    <div
                      key={item?.name + index}
                      onClick={() => {
                        handleCheckboxChange(item.name, item);
                      }}
                      className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                        selectedData.type === 'Checklist' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      } ${
                        selectedData.type === 'Paired Protocol' &&
                        pairedProtocolList?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }
                      ${
                        selectedData.type === 'Vital' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }
                      ${
                        selectedData.type === 'Equipment' &&
                        options?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      }`}
                    >
                      <RadioButton
                        inputId="pairedProtocol"
                        name="pairedProtocol"
                        className="radioBtnColor"
                        style={{ marginRight: '10px' }}
                        value={item.name}
                        checked={selectedOptions?.includes(item.name)}
                        onChange={() => {
                          handleCheckboxChange(item.name, item);
                        }}
                      />
                      <div>{item.name}</div>
                      {selectedData.type !== 'Paired Protocol' && (
                        <div className="editIconContainer">
                          <HiPencil
                            className="editIcon"
                            onClick={() => {
                              handleNavigateToEditPage(item);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </ViewportList>
              ) : (
                <ViewportList items={options}>
                  {(item: any, index: any) => (
                    <div
                      key={item + index}
                      onClick={() => {
                        handleNavigateToEditPage(item);
                      }}
                      className={`listhover cursorPointer item contentHeading ${
                        options.length !== index + 1 ? 'contentUnderline' : ''
                      }`}
                    >
                      {item?.name}
                    </div>
                  )}
                </ViewportList>
              )}
            </div>
            {(selectedData.type === 'Paired Protocol' ||
              selectedData.type === 'Checklist' ||
              selectedData.type === 'Equipment') && (
              <div className="btn_Bottom">
                <Button
                  data-testid="cancel"
                  className="secondary-button btn-rightMargin"
                  onClick={handleCloseSideouts}
                >
                  X Cancel
                </Button>
                <Button
                  data-testid="save"
                  className="primary-button"
                  disabled={!(selectedOptions?.length > 0)}
                  onClick={handleAddPairedProtocol}
                >
                  + Add
                </Button>
              </div>
            )}
          </div>
        </Sidebar>
        <div className="sidebarContainer">
          <div>
            <div
              className="buttonContainer contentTitleLarge"
              onClick={handleCloseSideouts}
            >
              <span className="">
                <HiPlus
                  className="header-icon"
                  style={{ marginLeft: '0px', color: '#000' }}
                />
                Add
              </span>
            </div>
            <div className="contentText">
              Select an option to continue to edit the information that will be
              displayed on the Protocols Screen.
            </div>
            <div className="contentLabelBold">Select Option</div>
            <div className="contentBorder protocolCalculationPad primaryListScroll">
              <ViewportList items={sideoutList}>
                {(item: any, index: any) => (
                  <div
                    key={item + index}
                    onClick={() => {
                      setSelectedData(item);
                      setIsSecondPage(true);
                    }}
                    className={`listhover spaceBetween cursorPointer item contentHeading ${
                      sideoutList?.length !== index + 1
                        ? 'contentUnderline'
                        : ''
                    }`}
                  >
                    <div>{item?.name}</div>
                    <div>
                      <span style={{ marginLeft: '0px' }}>
                        <BiSolidChevronRight className="icon_medium_small " />
                      </span>
                    </div>
                  </div>
                )}
              </ViewportList>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default AddProtocolSideout;
