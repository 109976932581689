import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { PiSyringe } from 'react-icons/pi';
import { PiUserLight } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { globals } from '../../../../_global/common/Utils';
import MedicationItem, {
  getConcentrationString,
} from '../../../../../data/model/MedicationItem';
import AddRouteModal from '../../../../components/Modal/AddRouteModal';
import { FaCopy, FaTrash } from 'react-icons/fa6';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import { BiCheckCircle } from 'react-icons/bi';

interface AIMedicationDoseProps {
  medication: MedicationItem;
  isHMMedication: boolean;
  dose: any;
  index: number;
  db: DatabaseResponse;
  dbMeds: any;
  page_number: number;
  onChange: (dose: any, index: number, med: MedicationItem | null) => void;
  onDelete?: (index: number) => void;
  onCopy?: (index: number) => void;
}
const AIPdfParserMedicationInput: React.FC<AIMedicationDoseProps> = (props) => {
  const {
    medication,
    isHMMedication,
    db,
    dose,
    index,
    dbMeds,
    page_number,
    onChange,
    onDelete,
    onCopy,
  } = props;

  const [isRouteModal, setIsRouteModal] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [med, setMed] = useState<MedicationItem | null>(medication);
  const [meds, setMeds] = useState<MedicationItem[]>(
    dose.type === 'Medication' ? db.medications : db.infusions
  );
  const [hmMeds, setHmMeds] = useState<MedicationItem[]>(dbMeds);

  useEffect(() => {
    setMed(medication);
    setMeds(dose.type === 'Medication' ? db.medications : db.infusions);
    setHmMeds(dbMeds);
  }, [medication, db, dbMeds]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: dose.type ? dose.type : '',
      name: dose.name ? dose.name : '',
      repeatTime: dose.repeatTime ? dose.repeatTime : '',
      repeatAllowed: dose.repeatAllowed ? dose.repeatAllowed : '',
      weightLow: dose.weightLow ? dose.weightLow : '',
      weightHigh: dose.weightHigh ? dose.weightHigh : '',
      ageLow: dose.ageLow ? dose.ageLow : '',
      ageHigh: dose.ageHigh ? dose.ageHigh : '',
      dose: dose.dose
        ? cleanDose(dose.dose)
        : dose.basis
          ? cleanDose(dose.basis)
          : '',
      minSingleDose: dose.minSingleDose ? cleanDose(dose.minSingleDose) : '',
      maxSingleDose: dose.maxSingleDose ? cleanDose(dose.maxSingleDose) : '',
      maxTotalDose: dose.maxTotalDose ? cleanDose(dose.maxTotalDose) : '',
      title: dose.instructionSecond
        ? dose.instructionSecond
        : dose.title
          ? dose.title
          : '',
      warning: dose.warning ? dose.warning : '',
      instruction: dose.instructionMain
        ? dose.instructionMain
        : dose.instructions
          ? dose.instructions
          : dose.instruction
            ? dose.instruction
            : '',
      administrationRoutes: dose.administrationRoutes
        ? dose.administrationRoutes
        : dose.route
          ? dose.route
          : [],
      ageGroup: dose.ageGroup ? dose.ageGroup : '',
      source: dose.source ? dose.source : '',
      isHmMedication: isHMMedication || dose.isHmMedication,
    },
    validationSchema: Yup.object({
      type: Yup.string(),
      name: Yup.string(),
      repeatTime: Yup.string(),
      repeatAllowed: Yup.boolean(),
      weightLow: Yup.number(),
      weightHigh: Yup.number(),
      ageLow: Yup.number(),
      ageHigh: Yup.number(),
      dose: Yup.string().required('Required'),
      minDose: Yup.number(),
      maxDose: Yup.number(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      administrationRoutes: Yup.array(),
      ageGroup: Yup.string(),
      source: Yup.string(),
    }),

    onSubmit: (values) => {
      if (globals.debug) console.log('values', values);
    },
  });

  function cleanDose(dose_str: any): string {
    // return dose_str;
    let dose = dose_str + '';
    // Trim, remove redundant spaces, and handle line breaks
    if (dose == null) return '';
    dose = dose
      .trim()
      .replace(/\s+/g, ' ')
      .replace(/[\r\n]+/g, '');
    return dose;

    // // Pattern to find numeric values possibly with ranges and units, optionally followed by "/kg"
    // const dosePattern =
    //   /(\d+\.?\d*)\s*-\s*(\d+\.?\d*)\s*(mg|mcg|g|ml|l|g|meq|gram|grams|gm|j|J)\b|\b(\d+\.?\d*)\s*(mg|mcg|g|ml|l|g|meq|gram|grams|gm|j|J)\b/g;
    // const perKgPattern = /per kg|\/kg|mg\/kg|mcg\/kg|g\/kg|ml\/kg/i;
    // const timePattern = /(min|hr|hour|minute)s?/i;

    // // let match,
    // //   matches = [];

    // // Check for range or single value doses
    // // while ((match = dosePattern.exec(dose.toLocaleLowerCase())) !== null) {
    // //   if (match[1] && match[2]) {
    // //     // Range dose
    // //     matches.push(`${match[1]}-${match[2]} ${match[3]}`);
    // //   } else if (match[4] && match[5]) {
    // //     // Single dose
    // //     matches.push(`${match[4]} ${match[5]}`);
    // //   }
    // // }

    // // Determine if dose is per kg
    // const perKg = perKgPattern.test(dose) ? '/kg' : '';

    // // Check for time interval
    // const timeMatch = timePattern.exec(dose);
    // let time = '';
    // if (timeMatch) {
    //   time = '/' + timeMatch[0];
    // }

    // Concatenate all matches with the per kg notation if applicable
    // return matches.join(' and ') + perKg + time;
  }

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  useEffect(() => {
    onChange(formik.values, index, med ? med : medication);
  }, [formik.values, med]);

  const onRoutesClick = () => {
    setIsRouteModal(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {/* Add Route Popup */}
      <AddRouteModal
        dialogVisible={isRouteModal}
        handleSet={(route: string) => {
          formik.setFieldValue('administrationRoutes', [
            ...formik.values.administrationRoutes,
            route,
          ]);
          setIsRouteModal(false);
        }}
        onNewRouteCancel={() => {
          setIsRouteModal(false);
        }}
        headerText="New Administration Route"
      />

      <div
        style={{ display: 'flex', marginTop: '5px' }}
        className="ketamine-general-label"
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          {/* <div style={{ marginRight: '10px' }}>Medication:</div>
          <div style={{ fontWeight: '500' }}>{medication.name}</div> */}
        </div>
        {onCopy && (
          <FaCopy
            className="cursorPointer"
            color={'#0479b3'}
            style={{
              marginRight: '12px',
            }}
            onClick={() => {
              onCopy(index);
            }}
          />
        )}
        {onDelete && (
          <FaTrash
            className="cursorPointer"
            color={'#8c1010'}
            onClick={() => {
              onDelete(index);
            }}
          />
        )}
      </div>
      <div className={`filled-input-container  ${''}`}>
        <div className={`${'doseGridPad'}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={`input-label`}>
                {db.department.name}
              </label>

              <SearchableDropdown<MedicationItem>
                id="searchDropdown"
                value={med && med.depID === db.department.id ? med : undefined}
                options={meds}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                redBorder={med == null}
                onChange={(option: MedicationItem) => {
                  // formik.setFieldValue('medication', option);
                  setMed(option);
                  onChange(formik.values, index, option);
                }}
                onClear={() => {
                  // formik.setFieldValue('medication', null);
                  setMed(null);
                  onChange(formik.values, index, null);
                }}
                isActive={true}
                placeholder={'Search ' + formik.values.type + '...'}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={`input-label`}>
                Hinckley Medical DB
              </label>

              <SearchableDropdown<MedicationItem>
                id="searchDropdown"
                value={med && med.depID !== db.department.id ? med : undefined}
                options={hmMeds}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                redBorder={med == null}
                onChange={(option: MedicationItem) => {
                  // formik.setFieldValue('medication', option);
                  setMed(option);
                  onChange(formik.values, index, option);
                }}
                onClear={() => {
                  // formik.setFieldValue('medication', null);
                  setMed(null);
                  onChange(formik.values, index, null);
                }}
                isActive={true}
                placeholder={'Search ' + formik.values.type + '...'}
              />
            </div>
          </div>

          <div className="ketamine-container-pad">
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // flex: 0.25,
                }}
              >
                <LiaWeightHangingSolid
                  style={{
                    fontSize: '2rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Weight Low (kg)
                </label>
                <div className="p-input-icon-right" data-testid="editWeightLow">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="weightLow"
                    name="weightLow"
                    placeholder="Min"
                    value={
                      formik.values.weightLow ? formik.values.weightLow : ''
                    }
                    data-testid="weightLow"
                    onChange={(e) => {
                      if (globals.debug) console.log('e', e.target.value);
                      formik.setFieldValue('weightLow', e.target.value);
                    }}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Weight High (kg)
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="weightHigh"
                    name="weightHigh"
                    placeholder="Max"
                    data-testid="weightHigh"
                    value={
                      formik.values.weightHigh &&
                      formik.values.weightHigh !== globals.MAX_VALUE
                        ? formik.values.weightHigh
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('weightHigh', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Age Low
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="ageLow"
                    name="ageLow"
                    data-testid="ageLow"
                    placeholder="Max"
                    value={formik.values.ageLow ? formik.values.ageLow : ''}
                    onChange={(e) => {
                      formik.setFieldValue('ageLow', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Age High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="ageHigh"
                    name="ageHigh"
                    data-testid="ageHigh"
                    placeholder="Max"
                    value={formik.values.ageHigh ? formik.values.ageHigh : ''}
                    onChange={(e) => {
                      formik.setFieldValue('ageHigh', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Repeat Time
                  {formik.values.repeatAllowed && (
                    <span>
                      <BiCheckCircle
                        style={{
                          fontSize: '1rem',
                          color: '#00534C',
                          marginLeft: '5px',
                        }}
                      />
                    </span>
                  )}
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="repeatTime"
                    name="repeatTime"
                    data-testid="repeatTime"
                    placeholder="Optional..."
                    value={
                      formik.values.repeatTime ? formik.values.repeatTime : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('repeatTime', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className=""
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PiSyringe
                  style={{
                    fontSize: '2rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label className="input-label">Calculation Basis</label>
                <div
                  className={`p-input-icon-right ${formik.values.dose === '' ? 'red-border' : ''}`}
                >
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      formik.values.dose === '-1'
                        ? 'EMERGENCY'
                        : formik.values.dose
                    }
                    onChange={(e) => {
                      formik.setFieldValue('dose', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Min Single Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="minDose"
                    name="minDose"
                    data-testid="minDose"
                    placeholder="Max"
                    value={
                      formik.values.minSingleDose
                        ? formik.values.minSingleDose
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('minSingleDose', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Max Single Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={
                      formik.values.maxSingleDose
                        ? formik.values.maxSingleDose
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('maxSingleDose', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Max Total Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="maxTotalDose"
                    name="maxTotalDose"
                    data-testid="maxTotalDose"
                    placeholder="Max"
                    value={
                      formik.values.maxTotalDose
                        ? formik.values.maxTotalDose
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('maxTotalDose', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={` ${'sideoutFieldPad'}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '10px',
              flex: 1,
              marginTop: '10px',
            }}
          >
            <div
              className=""
              style={{
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <label htmlFor="Title" className={`input-label `}>
                Title:{' '}
              </label>
              <InputText
                type="text"
                className={`input-control`}
                placeholder="Enter title..."
                data-testid="rangeHigh"
                value={formik.values.title}
                onChange={(e) => {
                  formik.setFieldValue('title', e.target.value);
                }}
              />
            </div>
            <div
              className=""
              style={{
                marginBottom: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <label
                htmlFor="Title"
                className={`input-label`}
                style={{
                  color: '#8B0000',
                }}
              >
                Warning:{' '}
              </label>
              <InputText
                type="text"
                className={`input-control`}
                placeholder="Enter warning..."
                data-testid="warning"
                value={formik.values.warning}
                onChange={(e) => {
                  formik.setFieldValue('warning', e.target.value);
                }}
              />
            </div>
          </div>
          <div className="sidebarInputContent">
            <label htmlFor={''} className={` rowLabelWidth sidebarLabel`}>
              Routes:
            </label>

            <div
              data-div-id="outside"
              className={`selectionArea-hover ${formik.values.administrationRoutes.length === 0 ? 'red-border' : ''}`}
              onClick={() => {
                onRoutesClick();
              }}
            >
              {formik.values.administrationRoutes.length !== 0 && (
                <ViewportList items={formik.values.administrationRoutes}>
                  {(item: string, index) => {
                    return (
                      <div
                        data-div-id="grey"
                        key={index}
                        className={`selectedGreybox`}
                      >
                        <div>{item}</div>
                        <span
                          className={`close_icon_color ${'cursorPointer'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            formik.setFieldValue(
                              'administrationRoutes',
                              formik.values.administrationRoutes.filter(
                                (r: string) => r !== item
                              )
                            );
                          }}
                        >
                          <AiOutlineCloseCircle className="cursorPointer icon-normal" />
                        </span>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
              {formik.values.administrationRoutes.length === 0 && (
                <div className={`input-placeholder`}>Required...</div>
              )}
            </div>
          </div>
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <label htmlFor="Title" className={`input-sidebarLabel`}>
              Instructions:{' '}
            </label>
            <InputText
              type="text"
              className={`input-control`}
              placeholder="Enter instructions..."
              data-testid="instruction"
              value={formik.values.instruction}
              onChange={(e) => {
                formik.setFieldValue('instruction', e.target.value);
              }}
            />
          </div>
          <div className="" style={{ marginBottom: '10px' }}>
            <label htmlFor="page_number" className={`input-sidebarLabel`}>
              <div style={{}}>
                Page Number: {page_number} - Concentrations:{' '}
                {med?.concentrations.map(getConcentrationString).join(', ')}
                {' - '}
                Inputted Name: {dose.name}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AIPdfParserMedicationInput;
