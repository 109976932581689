import ProtocolItem from './ProtocolItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import {
  getObjectDifference,
  globals,
  isObjectInArray,
} from '../../ui/_global/common/Utils';
import {
  Electrical,
  ElectricalShock,
  ElectricalShockOption,
  User,
} from '../../models';
import ElectricalSubItem, {
  cloneAllElectricalSubItems,
} from './ElectricalSubItem';
import { ProgressStatus } from '../../API';

export const cloneElectrical = (electrical: ElectricalItem): ElectricalItem => {
  let clone = new ElectricalItem(electrical.model);
  clone.subElectricals = cloneAllElectricalSubItems(electrical.subElectricals);
  clone.parents = electrical.parents;
  return clone;
};

class ElectricalItem implements ModelItem {
  private TAG = 'ElectricalItem';
  uid: string;
  name: string;
  depID: string;
  dbElectrical: ElectricalShock;
  model: ElectricalShock;
  subElectricals: ElectricalSubItem[] = [];
  parents: ProtocolItem[] = [];
  options: ElectricalShockOption[];
  modifiedBy: User | null | undefined;
  activeID: string | null | undefined;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  version: string | null | undefined;
  rangeLow: number;
  rangeHigh: number;

  constructor(electrical: ElectricalShock) {
    this.uid = electrical.id;
    this.name = electrical.title;
    this.depID = electrical.departmentID;
    this.dbElectrical = electrical;
    this.options = electrical.options;
    this.parents = [];
    this.activeID = electrical.activeID;
    this.version = electrical.version;
    this.model = electrical;
    this.status = electrical.status ? electrical.status : ProgressStatus.ACTIVE;
    this.rangeLow = electrical.rangeLow ? electrical.rangeLow : 0;
    this.rangeHigh = electrical.rangeHigh
      ? electrical.rangeHigh
      : globals.MAX_VALUE;
  }

  getAmount(weight: PatientWeight): string {
    return '';
  }

  // constructor(parent : ProtocolItem, name: string) {
  //     this.name               = name;
  //     this.parentProtocol     = parent;
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.instruction        = "";
  //     this.warning            = "";
  //     this.option             = {
  //         title : "1st Dose",
  //         ranges: [{
  //             rangeLow: 0,
  //             rangeHigh: globals.MAX_VALUE,
  //             basisLow: "1 J/kg",
  //             basisHigh: "2 J/kg",
  //             calcMax: "10 J",
  //             fixedMax: "10 J"
  //         }]};
  //     this.dbElectrical       = null;
  // }

  getModelElectrical(): ElectricalShock {
    return this.dbElectrical;
  }

  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the department id of the owner of this category.
   * @returns the department id of the owner of this category
   */
  getDepID(): string {
    return this.depID;
  }

  getName(): string {
    return this.name;
  }

  getSubElectricals(): ElectricalSubItem[] {
    return this.subElectricals;
  }

  addSubElectrical(subElectrical: ElectricalSubItem): void {
    if (isObjectInArray(this.subElectricals, subElectrical)) return;
    this.subElectricals.push(subElectrical);
    this.subElectricals.sort((a, b) =>
      a
        .getParentProtocol()
        .getName()
        .localeCompare(b.getParentProtocol().getName())
    );
  }

  addParent(parent: ProtocolItem): void {
    this.parents.push(parent);
  }

  getParent(): ProtocolItem[] {
    return this.parents;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof ElectricalItem)) return false;
    let other: ElectricalItem = obj as ElectricalItem;
    return this.uid === other.getUid() && this.name === other.getName();
  }

  toString(): string {
    return `ElectricalItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
        }`;
  }
}

export default ElectricalItem;
