import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import './Navbar.scss';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { handleLogOut, handleShowSideBar } from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { Auth, DataStore } from 'aws-amplify';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';
import logo from '../../../assets/images/hinckleymedical_logo.png';
import { BiExit } from 'react-icons/bi';
import { globals } from '../../_global/common/Utils';
import { FaChevronDown } from 'react-icons/fa6';

/* 09-28-23 Arul: Created the  Navbar component globally  */
const NavbarComponent = ({ handleLogin }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(true);
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const department: DepartmentItem = database.department;

  const options = [
    // { value: 'Settings', label: 'Settings' },
    { value: 'Log Out', label: 'Log Out', icon: <BiExit size={20} /> },
  ];

  const handleUserInfo = async () => {
    if (globals.debug) console.log('Logging out');
    setLoading(true);
    try {
      navigate(`/login`);
      await Auth.signOut();
      // dispatch<any>(handleUpdateUser(null));
      await DataStore.stop();
      await DataStore.clear();
      await localStorage.clear();
      dispatch<any>(handleLogOut);

      if (globals.debug) console.log('Successfully logged out');
    } catch (error) {
      if (globals.debug) console.log('error signing out: ', error);
    }
    setLoading(false);
    // dispatch<any>(handleLogOut);
    // dispatch<any>(handleUpdateUser());
    // navigate(`/protocol`);
  };

  const handleShowSidebar = (e: any) => {
    e.stopPropagation();
    setShowSidebar(!showSidebar);
    if (showSidebar) {
      dispatch<any>(handleShowSideBar({ sidebar: false }));
    } else {
      dispatch<any>(handleShowSideBar({ sidebar: true }));
    }
  };

  /* Use this effect to close the dropdown when clicked outside */
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        isOpen &&
        divRef.current &&
        !divRef.current.contains(e.target as Node)
      ) {
        setTimeout(() => {
          if (isOpen) setIsOpen(false);
        }, 150);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [divRef, isOpen]);

  return (
    <div className="fixedNavbar">
      <header className="navBarContainer">
        {/* Controls the menu bar in the top right */}
        <div className="logo-container-left">
          <GiHamburgerMenu
            className="menu-bars"
            size={30}
            color="#00534C"
            onClick={handleShowSidebar}
          />
          <div
            className="hover-raise-elevation"
            onClick={(e) => {
              navigate('/protocol');
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              padding: '0.25rem',
              cursor: 'pointer',
              gap: '0.5rem',
              borderRadius: '0.5rem',
            }}
          >
            <img
              className="no-select"
              src={logo}
              alt="Logo"
              style={{
                margin: 0,
                padding: 0,
              }}
            />
          </div>
        </div>
        {/* {department && department.logoVerifiedUrl && (<img src={department.logoVerifiedUrl} alt='Agency Logo' />)} */}
        {/* {department && <div className="DepartmentName">{department.name}</div>} */}
        {/* <img src={logo} alt='Logo' /> The HM logo */}
        <div className="logo-container-right">
          {!isLoggedIn && (
            <Button className="login-button" onClick={handleLogin}>
              Log In
            </Button>
          )}{' '}
          {/* Shows the login button if the user is not logged in*/}
          {isLoggedIn && (
            <div className="logo-container-right">
              {/* <Accountbar> */}
              {/* <DropItem icon={<BiBell />}>
                <NotificationDropDown />
              </DropItem> */}
              <div
                className="border hover-raise-elevation"
                onClick={(e) => {
                  if (!isOpen) setIsOpen(!isOpen);
                }}
              >
                {department && department.logoVerifiedUrl && (
                  <img
                    className="department-logo"
                    src={department.logoVerifiedUrl}
                    alt="Agency Logo"
                  />
                )}
                {department && (
                  <div
                    className="departmentName no-select"
                    style={{
                      marginLeft: '6px',
                      marginRight: '6px',
                    }}
                  >
                    {department.name}
                  </div>
                )}
                <FaChevronDown size={12} />
                {!department && (
                  <>
                    <div className="loadingText">Loading</div>
                    <ReactLoading
                      className="load"
                      type="bubbles"
                      height={24}
                      width={24}
                    />
                  </>
                )}
              </div>
              {isOpen && (
                <ul className="c-dropdown_list" ref={divRef}>
                  {options.map((option) => {
                    return (
                      <li
                        className="c-multi-select-dropdown__option"
                        key={option.value}
                        onClick={(e) => {
                          if (globals.debug) console.log('Option clicked');
                          e.stopPropagation();
                          handleUserInfo();
                        }}
                      >
                        <div className={`c_dropdown_option`}>
                          {option.icon && option.icon}
                          <div
                            className="option-label no-select"
                            style={{ marginLeft: '10px' }}
                          >
                            {option.label}
                          </div>
                          {/* <span>{labelField(option)}</span> */}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {/* <DropItem
                  icon={
                    
                  }
                >
                  <AccountDropDownMenu onLogOut={handleUserInfo} />
                </DropItem> */}
              {/* </Accountbar> */}
            </div>
          )}
        </div>
        {loading && <Loading type="bubbles" />}
      </header>
    </div>
  );
};

export default NavbarComponent;
