import ProtocolItem from './ProtocolItem';
import MedicationItem from './MedicationItem';
import MedicationSubItem from './MedicationSubItem';
import EquipmentItem from './EquipmentItem';
import ElectricalItem from './ElectricalItem';
import VitalItem from './VitalItem';
import CategoryItem from './CategoryItem';
import FormItem from './FormItem';
import { getHashedPin } from '../../ui/_global/common/Encrypt';
import { LazyDepartment, SoftwareType } from '../../models';
import KeychainItem from './KeychainItem';
import { Department } from '../../API';
// import symbol from '../../assets/images/symbol.png';

class DepartmentItem {
  TAG = '';
  id: string;
  name: string;
  location: string;
  logoURL: string;
  protocolVersion: string;
  shiftTypes: string[];
  subDepIDs: string[];
  parentDepID: string | null | undefined;
  hashedPin: string;
  salt: string;
  departmentCode: string;

  logoVerifiedUrl: string | null | undefined;

  model: Department | LazyDepartment;
  subDeps: DepartmentItem[] | null;
  activeSubDep: DepartmentItem | null | undefined;
  isSoftwareOnly: boolean = false;
  isMultiDep: boolean = false;
  config: any | undefined;

  keychainID: string | null | undefined;
  isPublic: boolean = false;
  keychain: KeychainItem | null | undefined;
  isNemsisConfig: boolean = false;
  isOneWeightEnabled: boolean = true;
  infusionCalculation: boolean = false;
  isRealTimeEnabled: boolean = false;
  isAgeFilterEnabled: boolean = false;

  constructor(depInfo: Department | LazyDepartment) {
    this.id = depInfo.id;
    this.name = depInfo.name;
    this.location = depInfo.location ? depInfo.location : 'N/A';
    this.logoURL = depInfo.logoID;

    this.shiftTypes = depInfo.shiftTypes ? depInfo.shiftTypes : [];
    this.subDepIDs = depInfo.subDepIDs ? depInfo.subDepIDs : [];
    this.parentDepID = depInfo.parentDepID ? depInfo.parentDepID : null;
    this.hashedPin = depInfo.hashedPin ? depInfo.hashedPin : '';
    this.salt = depInfo.saltedPin ? depInfo.saltedPin : '';
    this.protocolVersion = depInfo.protocolVersions
      ? depInfo.protocolVersions
      : 'v1.0.0';
    this.isSoftwareOnly = depInfo.softwarePlan === SoftwareType.SOFTWARE_ONLY;
    this.keychainID = depInfo.keychainID;
    this.isPublic = depInfo.isPublic ? depInfo.isPublic : false;
    this.config = depInfo.config ? depInfo.config : null;
    this.departmentCode = depInfo.uniqueCode ? depInfo.uniqueCode : '';

    if (depInfo.subDepIDs && depInfo.subDepIDs.length > 0) {
      this.subDeps = [];
      this.isMultiDep = true;
    } else this.subDeps = null;

    this.model = depInfo;
    this.activeSubDep = null;
    if (this.config) {
      this.isOneWeightEnabled =
        this.config.oneweightEnabled === false ? false : true;
      this.isNemsisConfig =
        this.config.epcrProvider != null && this.config.epcrProvider !== ''
          ? true
          : false;
      this.infusionCalculation =
        this.config.infusionCalculation === true ? true : false;
      this.isRealTimeEnabled =
        this.config.realTimeUpdating === true ? true : false;
      this.isAgeFilterEnabled =
        this.config.ageFilterEnabled === true ? true : false;
    }
  }

  addSubDep(dep: DepartmentItem) {
    if (this.subDeps) {
      this.subDeps.push(dep);
      this.subDeps.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}

export default DepartmentItem;
