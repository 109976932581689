import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css';

const Loading = (props) => {
  return (
    <div className="loading-background">
      <ReactLoading className="load" type={props.type ? props.type : 'bubbles'} size={60} />
      <h3>{props.message ? props.message : 'Loading...'}</h3>
    </div>
  );
};

export default Loading;
