import React, { useEffect, useState } from 'react';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { HiPencil } from 'react-icons/hi';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle, AiOutlineThunderbolt } from 'react-icons/ai';
import { globals } from '../../../../_global/common/Utils';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';

interface MedicationDoseProps {
  dose: ElectricalSubItem;
  doseIndex: number;
  showProtocol: boolean;
}
const ElectricalDose: React.FC<MedicationDoseProps> = (props) => {
  const { dose, doseIndex, showProtocol } = props;

  return (
    <div>
      <div className="addDoseHeader">
        <span className="ketamine-general-label">
          Shock {doseIndex}
          {showProtocol ? ' - ' + dose.parentProtocol?.name : ''}
        </span>
      </div>
      <div
        className={`model-input-container  ${'cursorPointerDiv hoverableBackground'}`}
      >
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Range Low
                </label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`form-control pointer ${!dose.rangeLow ? 'greyText' : ''}`}
                    id="rangeLow"
                    name="rangeLow"
                    placeholder="Min"
                    value={dose.rangeLow ? dose.rangeLow + ' kg' : ''}
                    data-testid="rangeLow"
                    readOnly={true}
                    disabled={true}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor={''}
                  className={` ketamine-label cursorPointerDiv`}
                >
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control highRange pointer ${Number(dose.rangeLow) === globals.MAX_VALUE ? 'greyText' : ''}`}
                    id="rangeHigh"
                    name="rangeHigh"
                    placeholder="Max"
                    data-testid="rangeHigh"
                    value={
                      dose.rangeHigh && dose.rangeHigh !== globals.MAX_VALUE
                        ? dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="ketamine-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <AiOutlineThunderbolt className="protocol-vital-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Calculation Basis
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={dose.range.basis}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Calculated Max
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="calcMax"
                    name="valcalcMaxue"
                    placeholder="Max"
                    value={dose.range.calcMax ?? ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Fixed Max
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="fixedMax"
                    name="fixedMax"
                    placeholder="Max"
                    value={dose.range.fixedMax ?? ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={` ${'sideoutFieldPad'}`}>
          {dose.title && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Title" className={`sidebarLabel `}>
                Title:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder="Enter Title here..."
                  value={dose.title}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.warning && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label
                htmlFor="Warning"
                className={`sidebarLabel `}
                style={{
                  color: '#880808',
                }}
              >
                Warning:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="warning"
                  name="warning"
                  data-testid="warning"
                  placeholder="Enter Warning here..."
                  value={dose.warning}
                  style={{
                    color: '#880808',
                  }}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.instruction && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Instruction" className={`sidebarLabel `}>
                Instructions:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="instruction"
                  name="instruction"
                  data-testid="instruction"
                  placeholder="Enter Instructions here..."
                  value={dose.instruction}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.note && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Note" className={`sidebarLabel `}>
                Note:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="note"
                  name="note"
                  data-testid="note"
                  placeholder="Enter Note here..."
                  value={dose.note}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ElectricalDose;
