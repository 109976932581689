// import { Equipment, EquipmentOption, ProtocolEquipment } from 'your_module_path_here';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import ProtocolItem from './ProtocolItem';
import ModelItem from './ModelItem';
import { globals } from '../../ui/_global/common/Utils';
import { Equipment, EquipmentOption, User } from '../../models';
import { ProgressStatus } from '../../API';

export function formatForMatchingUnitsFormik(
  firstAmnt: string,
  secondAmnt?: string | null,
  firstUnit?: string | null,
  secondUnit?: string | null
): string {
  if (!firstAmnt) return '';

  try {
    /* Base Case 1: No second amount */
    if (!secondAmnt) return firstAmnt + (firstUnit ? ' ' + firstUnit : '');

    // /* Base Case 2: No units */
    // if (!firstUnit && !secondUnit) return `${firstAmnt}/${secondAmnt}`;

    /* Base Case 3: Same units */
    if (firstUnit === secondUnit)
      return `${firstAmnt} - ${secondAmnt}${firstUnit ? ' ' + firstUnit : ''}`;
    return `${firstAmnt}${firstUnit ? ' ' + firstUnit : ''}/${secondAmnt}${
      secondUnit ? ' ' + secondUnit : ''
    }`;
  } catch (e) {
    console.error(
      'ERROR: Failed to formatForMatchingUnits firstAmnt -> ',
      firstAmnt,
      ' secondAmnt -> ',
      secondAmnt
    );
  }

  return '';
}

export function formatForMatchingUnits(
  firstAmnt: string,
  secondAmnt: string
): string {
  if (firstAmnt === '+Add') return '+Add';
  if (firstAmnt == null) return '';
  if (secondAmnt == null || secondAmnt === '') return firstAmnt;

  try {
    let firstArr = firstAmnt.split(' ', 2);
    let secondArr = secondAmnt.split(' ', 2);

    if (firstArr.length > 1 && secondArr.length > 1) {
      if (firstArr[1] === secondArr[1])
        return `${firstArr[0]} - ${secondArr[0]} ${firstArr[1]}`;
      else
        return `${firstArr[0]} ${firstArr[1]}/${secondArr[0]} ${secondArr[1]}`;
    } else if (firstArr.length > 1 && secondArr.length === 1)
      return `${firstArr[0]} ${firstArr[1]}/${secondArr[0]}`;
    else if (firstArr.length === 1 && secondArr.length > 1)
      return `${firstArr[0]}/${secondArr[0]} ${secondArr[1]}`;
    else return `${firstArr[0]}/${secondArr[0]}`;
  } catch (e) {
    console.error(
      'ERROR: Failed to formatForMatchingUnits firstAmnt -> ',
      firstAmnt,
      ' secondAmnt -> ',
      secondAmnt
    );
  }

  return '';
}
class EquipmentItem implements ModelItem {
  TAG = 'EquipmentItem';
  uid: string;
  depID: string;
  name: string;
  instruction: string;
  warning: string;
  title: string;
  note: string;
  parents: ProtocolItem[];
  dbEquipment: Equipment;
  activeID: string | null | undefined;
  model: Equipment;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  version: string;
  options: EquipmentOption[];
  modifiedBy: User | null | undefined;
  // private protocolEquipments  : ProtocolEquipment[];

  constructor(equipment: Equipment) {
    this.TAG = 'EquipmentItem';
    this.uid = equipment.id;
    this.name = equipment.name;
    this.depID = equipment.departmentID;
    this.activeID = equipment.activeID;
    this.note = '';
    this.title = '';
    this.status =
      equipment.status != null ? equipment.status : ProgressStatus.ACTIVE;
    this.version = equipment.version ? equipment.version : 'v1.0.0';
    this.dbEquipment = equipment;
    this.instruction = '';
    this.warning = '';
    this.model = equipment;
    this.parents = [];
    this.options = equipment.optionItems;
  }

  getAmount(weight: PatientWeight): string {
    if (this.options) {
      for (let option of this.options) {
        if (!option) continue;
        let high = option.rangeHigh ? option.rangeHigh : globals.MAX_VALUE;
        if (
          weight.getWeightKg() >= option.rangeLow &&
          weight.getWeightKg() < high
        ) {
          if (
            option.amntHigh !== null &&
            option.amntHigh !== '' &&
            option.amntHigh !== undefined
          )
            return formatForMatchingUnits(option.amnt, option.amntHigh);
          return option.amnt;
        }
      }
    }
    return '';
  }

  getInstruction(): string {
    return this.instruction;
  }

  getWarning(): string {
    return this.warning;
  }

  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the department id of the owner of this category.
   * @returns the department id of the owner of this category
   */
  getDepID(): string {
    return this.depID;
  }

  getName(): string {
    return this.name;
  }

  addParentProtocol(protocol: ProtocolItem): void {
    if (!this.parents.includes(protocol)) this.parents.push(protocol);
  }

  getParentProtocols(): ProtocolItem[] {
    return this.parents;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof EquipmentItem)) return false;
    let other: EquipmentItem = obj as EquipmentItem;
    return this.uid === other.getUid() && this.name === other.getName();
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  //Create a toString finction to output the EquipmentItem object
  toString(): string {
    return `EquipmentItem -> {
            uid=${this.uid},
            name=${this.name},
            instruction=${this.instruction},
            warning=${this.warning},
            dbEquipment=${this.dbEquipment},
            options=${this.options?.length},
            title=${this.title},
            note=${this.note},
        }`;
    // protocolEquipments=${this.protocolEquipments}
  }
}

export default EquipmentItem;
