import { useFormik } from 'formik';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { handleSetSelectedDoseValue } from '../../../../store/actions';
import AddRouteModal from '../../Modal/AddRouteModal';
import CalculationBasisDialog from '../../Modal/CalculationBasisModal';
import ConfirmWarningModal from '../../Modal/ConfirmWarningModal';
import DoseFixedCalcDialogModal from '../../Modal/DoseFixedCalcDialogModal';
import EquipmentVitalValueModal from '../../Modal/EquipmentVitalValueModal';
import RangeDialog from '../../Modal/RangeDialogModal';
import RouteDialog from '../../Modal/RouteDialogModal';
import DoseSelection from '../../dose/DoseSelection';

import * as _ from 'lodash';
import ConfirmModal from '../../Modal/ConfirmModal';
import { EquipmentOption, VitalOption } from '../../../../models';
import { globals } from '../../../_global/common/Utils';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import VitalItem from '../../../../data/model/VitalItem';
import { BiArrowBack } from 'react-icons/bi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import SearchableDropdown from '../../SearchableDropdown';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import GeneralSelection from '../../Selection/GeneralSelection';
import { ViewportList } from 'react-viewport-list';
import MedicationDose from '../../../pages/protocol/edit/Medication/MedicationDose';
import { FaChevronRight } from 'react-icons/fa6';
import { InputSwitch } from 'primereact/inputswitch';

interface InfusionDoseSideoutProps {
  visible: boolean;
  dose?: MedicationSubItem;
  solidUnit: string;
  parentModel: MedicationItem;
  subMedicationItems: MedicationSubItem[];
  setVisible: (visible: boolean) => void;
  editDose?: boolean;
  protocol: ProtocolItem | null;
  allProtocols?: ProtocolItem[];

  adminRoutes?: string[];
  doseIndex?: any;
  onSideOutClose?: any;
  onRemove: (dose: any) => void;
  onSubmit: (
    dose: any,
    prevDose?: MedicationSubItem,
    parentProtocol?: ProtocolItem
  ) => void;
  onSubmitMultiDose?: (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => void;
  onSetNewRoutes?: (newRoutes: string[]) => void;
}

const NEW_DOSE = 0;
const COPY_DOSE = 1;
const COPY_PROTOCOL_DOSE = 2;

/*10-16-23 Praveen:  Created common component for Edit and New Dose section sideout view*/
const InfusionDoseSideout: React.FC<InfusionDoseSideoutProps> = ({
  doseIndex,
  protocol,
  parentModel,
  adminRoutes,
  subMedicationItems,
  dose,
  solidUnit,
  visible,
  setVisible,
  editDose,
  onSubmit,
  onSideOutClose,
  onSubmitMultiDose,
  onSetNewRoutes,
  onRemove,
}) => {
  const dispatch = useDispatch();
  const [maxDialogVisible, setMaxDialogVisible] = useState(false);
  const [minDialogVisible, setMinDialogVisible] = useState(false);
  const [lowRangeDialogVisible, setLowRangeDialogVisible] = useState(false);
  const [highRangeDialogVisible, setHighRangeDialogVisible] = useState(false);
  const [calculationDialogVisible, setCalculationDialogVisible] =
    useState(false);
  const [routeDialogVisible, setRouteDialogVisible] = useState(false);
  const [newRouteDialogVisible, setNewRouteDialogVisible] = useState(false);
  const [isConfirmModal, setIsConfirmMOdal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [calculatedMaxDialogVisible, setCalculatedMaxDialogVisible] =
    useState(false);
  const [fixedMaxDialogVisible, setFixedMaxDialogVisible] = useState(false);
  const [equipmentValueModalVisible, setEquipmentValueModalVisible] =
    useState(false);

  const [deleteData, setDeleteData] = useState<any>(null);
  const [modalType, setModalType] = useState('');
  const [editData, setEditData] = useState(null);
  const [routeModal, setRouteModal] = useState<any>(null);

  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    database.protocols.sort((a, b) => a.name.localeCompare(b.name))
  );
  const [parentProtocol, setParentProtocol] = useState<ProtocolItem | null>(
    protocol ? protocol : dose ? dose.parentProtocol : null
  );

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [inputType, setInputType] = useState(NEW_DOSE);
  const [doseProtocols, setDoseProtocols] = useState<ProtocolItem[]>([]);

  const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
    null
  );
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolItem | null>(
    null
  );
  const [selectedProtocolDoses, setSelectedProtocolDoses] = useState<
    MedicationSubItem[]
  >([]);

  const [isEmergency, setIsEmergency] = useState(
    dose ? dose.range.basis === '-1' : false
  );
  useEffect(() => {
    let prots: ProtocolItem[] = [];
    for (let i = 0; i < subMedicationItems.length; i++) {
      let found = false;
      for (let j = 0; j < prots.length; j++) {
        if (prots[j].uid === subMedicationItems[i].parentProtocol.uid) {
          found = true;
          break;
        }
      }
      if (!found) prots.push(subMedicationItems[i].parentProtocol);
    }
    setDoseProtocols(prots);
  }, [subMedicationItems]);

  const formik = useFormik({
    initialValues: {
      /* Shared on all models */
      rangeLow: dose ? dose.rangeLow : '',
      rangeHigh: dose
        ? dose.rangeHigh === globals.MAX_VALUE
          ? ''
          : dose.rangeHigh
        : '',

      /* Medication, Electrical and Infusion */
      basis: dose ? dose.range.basis : '+Add',
      // parentProtocol: protocol,

      /* Medication and infusion */
      routes: dose ? dose.routes : [],
      minDose: dose && dose.range.minDose ? dose.range.minDose : '',
      maxDose: dose && dose.range.maxDose ? dose.range.maxDose : '',

      title: dose ? dose.title : '',
      warning: dose ? dose.warning : '',
      instruction: dose ? dose.instruction : '',
      note: dose ? dose.note : '',
    },
    validationSchema: Yup.object({
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      routes: Yup.array(),
      basis: Yup.string(),
      minDose: Yup.string(),
      maxDose: Yup.string(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  /*10-16-23 Praveen: Range button handler*/
  const handleRangeClick = (value: any, type: any) => {
    setEditData(value);
    if (type === 'low') setLowRangeDialogVisible(!lowRangeDialogVisible);
    else setHighRangeDialogVisible(!highRangeDialogVisible);
  };

  /*10-16-23 Praveen: Dose button handler*/
  const handleDoseClick = (
    value: any,
    type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
  ) => {
    setEditData(value);
    if (type === 'MIN DOSE') setMinDialogVisible(!minDialogVisible);
    else if (type === 'MAX_DOSE') setMaxDialogVisible(!maxDialogVisible);
    else if (type === 'CALC_MAX')
      setCalculatedMaxDialogVisible(!calculatedMaxDialogVisible);
    else if (type === 'FIXED_MAX')
      setFixedMaxDialogVisible(!fixedMaxDialogVisible);
  };

  /*10-16-23 Praveen: Route handler*/
  const handleRouteClick = (value: any) => {
    setEditData(value);
    setRouteDialogVisible(!routeDialogVisible);
  };

  /*10-16-23 Praveen: Calculation basis  handler*/
  const handleCalculationBasisClick = (value: any) => {
    setEditData(value);
    setCalculationDialogVisible(!calculationDialogVisible);
  };

  /*10-16-23 Praveen: Route button for the dialog handler*/
  const handleNewRoute = () => {
    setNewRouteDialogVisible(true);
    setRouteDialogVisible(!routeDialogVisible);
  };

  const handleConfirm = (value: any) => {
    setDeleteData(value);
    setIsConfirmMOdal(true);
    setModalType('Route');
  };

  const handleValueClick = (value: any) => {
    setEditData(value);
    setEquipmentValueModalVisible(!equipmentValueModalVisible);
  };

  //Modal Update Function handling
  /*10-30-23 Arul: handling function for range popup onsubmit */
  const handleRangeset = (value: any, type: any) => {
    setLowRangeDialogVisible(false);
    setHighRangeDialogVisible(false);
    if (type === 'low') formik.setFieldValue('rangeLow', value);
    else
      formik.setFieldValue(
        'rangeHigh',
        value === globals.MAX_VALUE ? '' : value
      );
  };

  /* 11-01-23 Praveen: handled function to set dose popup value */
  const handleDoseFixedCalcset = (data: any, type: any) => {
    setMaxDialogVisible(false);
    setMinDialogVisible(false);
    setCalculatedMaxDialogVisible(false);
    setFixedMaxDialogVisible(false);

    if (type === 'min') {
      formik.setFieldValue('minDose', data.value + ' ' + data.unit);
      // handleDispatchDose({ minDoseUnit: e.unit, minDose: e.value })
    }
    if (type === 'max') {
      formik.setFieldValue('maxDose', data.value + ' ' + data.unit);
      // handleDispatchDose({ maxDoseUnit: e.unit, maxDose: e.value })
    }
  };

  const fullBasisUnitFormat = (e: any) => {
    if (e?.calculationUnit) {
      return `${e.medicationUnit.code}/${e?.calculationUnit}/${e?.timeUnit.code}`;
    } else {
      return `${e.medicationUnit.code}/${e?.timeUnit.code}`;
    }
  };

  /* 11-01-23 Praveen: handled function to set calcaulation basis popup value */
  const handleCalculationBasisset = async (newBasis: string) => {
    setCalculationDialogVisible(false);
    formik.setFieldValue('basis', newBasis);
  };

  /* 11-01-23 Praveen:  To select and unselect route value */
  const handleEditRouteset = useCallback(
    (routes: string[]) => {
      formik.setFieldValue('routes', routes);
      setRouteDialogVisible(false);
    },
    [formik]
  );

  /* 11-01-23 Praveen: To add new route value */
  const handleNewRouteset = (route: string) => {
    setNewRouteDialogVisible(false);
    if (routeModal !== 'new') {
      setRouteDialogVisible(true);
    }
    setRouteModal(null);
    const routes = (
      adminRoutes
        ? [...adminRoutes]
        : [...(parentModel as MedicationItem)?.routes]
    ) as string[];
    routes.push(route);
    onSetNewRoutes && onSetNewRoutes(routes);
  };

  /* 11-01-23 Praveen: To delete the route value */
  const handleConfirmDelete = (type: any) => {
    setIsConfirmMOdal(false);
    if (type === 'Route')
      formik.setFieldValue(
        'routes',
        formik.values.routes.filter((route: any) => route !== deleteData)
      );
  };

  //*10-30-23 Arul: handling function for create and edit dose sideout */
  const handleSubmit = async () => {
    let newDose = formik.values;
    if (inputType === COPY_PROTOCOL_DOSE) {
      if (onSubmitMultiDose && selectedProtocol && parentProtocol)
        onSubmitMultiDose(parentProtocol, selectedProtocol);
    } else {
      if (isEmergency) {
        newDose.basis = '-1';
        newDose.routes = [];
      }
      onSubmit(
        newDose,
        dose,
        parentProtocol ? parentProtocol : dose ? dose.parentProtocol : undefined
      );
    }
    // if (onDoseCreationComplete) {
    // 	onDoseCreationComplete();
    // }
    setVisible(false);

    // if (type === "Medication" || type === "Infusion") {
    // 	if (editDose) {
    // 		handleDispatchMedicationInfusionValue("medications", "edit");
    // 	} else {
    // 		handleDispatchMedicationInfusionValue("medications", "new");
    // 	}
    // } else if (type === "Equipment" || type === "Vital") {
    // 	if (editDose) {
    // 		handleDispatchEquipmentVitalValue("equipment", "edit");
    // 	} else {
    // 		handleDispatchEquipmentVitalValue("equipment", "new");
    // 	}
    // } else if (type === "Electrical") {
    // 	if (editDose) {
    // 		handleDispatchElectricalValue("electrical", "edit");
    // 	} else {
    // 		handleDispatchElectricalValue("electrical", "new");
    // 	}
    // }
  };

  // /* 11-02-23 Praveen: Onclick function for dose new administration Route popup*/
  // const handleNewDoseRoute = useCallback(() => {
  //     setRouteDialogVisible(true);
  // }, [routeList])

  /* 11-02-23 Praveen: submit button validation*/
  const isSaveValid = useMemo(() => {
    if (inputType === COPY_PROTOCOL_DOSE)
      return (
        parentProtocol && selectedProtocol && selectedProtocolDoses.length > 0
      );
    else if (inputType === COPY_DOSE) return selectedDose && parentProtocol;

    if (
      isEmergency &&
      formik.values.warning &&
      parentProtocol != null &&
      formik.dirty
    )
      return true;
    else if (formik.values.routes.length === 0 || parentProtocol == null)
      return false;
    else if (formik.values.basis === '' || formik.values.basis === '+Add')
      return false;
    return formik.dirty && formik.isValid;
  }, [
    formik,
    parentProtocol,
    selectedProtocol,
    selectedProtocolDoses,
    selectedDose,
    isEmergency,
    inputType,
  ]);

  const handleCloseSideout = () => {
    if (formik.dirty) {
      setIsWarningModal(true);
    } else {
      setVisible(false);
      // onSideOutClose && onSideOutClose();
      // dispatch<any>(handleSetSelectedDoseValue(""));
    }
  };

  const handleCloseWarningModal = () => {
    setVisible(false);
    dispatch<any>(handleSetSelectedDoseValue(''));
  };

  const customHeader = () => {
    return (
      <h4 className="headerText hoverText" onClick={handleCloseSideout}>
        <span className="">
          <BiArrowBack className="header-icon" data-testid="isBackBtn" />
        </span>{' '}
        {editDose ? 'Edit' : 'Create'} Infusion Dose
      </h4>
    );
  };

  const handleDeleteDose = () => {
    if (onRemove) onRemove(dose);
    setIsDeleteModal(false);
    handleCloseSideout();
  };

  const handleSelectCopyDose = (dose: MedicationSubItem | null) => {
    setSelectedDose(dose);
    formik.setFieldValue('rangeLow', dose ? dose.rangeLow : '');
    formik.setFieldValue(
      'rangeHigh',
      dose ? (dose.rangeHigh === globals.MAX_VALUE ? '' : dose.rangeHigh) : ''
    );
    formik.setFieldValue('basis', dose ? dose.range.basis : '+Add');
    formik.setFieldValue('routes', dose ? dose.routes : []);
    formik.setFieldValue(
      'minDose',
      dose && dose.range.minDose ? dose.range.minDose : ''
    );
    formik.setFieldValue(
      'maxDose',
      dose && dose.range.maxDose ? dose.range.maxDose : ''
    );
    formik.setFieldValue('title', dose ? dose.title : '');
    formik.setFieldValue('warning', dose ? dose.warning : '');
    formik.setFieldValue('instruction', dose ? dose.instruction : '');
    formik.setFieldValue('note', dose ? dose.note : '');
  };

  const buttonTitle = useMemo(() => {
    if (inputType === NEW_DOSE) return editDose ? 'Save' : 'Create';
    else if (inputType === COPY_DOSE) return 'Copy Dose';
    else if (inputType === COPY_PROTOCOL_DOSE)
      return 'Copy ' + selectedProtocolDoses.length + ' Doses';
  }, [editDose, inputType, selectedProtocolDoses]);

  const Modals = () => {
    return (
      <>
        {/* Max Dose Popup */}
        <DoseFixedCalcDialogModal
          unit={solidUnit}
          dialogVisible={maxDialogVisible}
          value={formik.values.maxDose}
          handleSet={(e: any) => {
            handleDoseFixedCalcset(e, 'max');
          }}
          setDialogVisible={() => {
            setMaxDialogVisible(false);
            setEditData(null);
          }}
          headerText="Max Dose"
          headerTitle="Max"
          InputLabel="Max"
        />

        {/* Min Dose Popup */}
        <DoseFixedCalcDialogModal
          unit={solidUnit}
          dialogVisible={minDialogVisible}
          value={formik.values.minDose}
          handleSet={(e: any) => {
            handleDoseFixedCalcset(e, 'min');
          }}
          setDialogVisible={() => {
            setMinDialogVisible(false);
            setEditData(null);
          }}
          headerText="Min Dose"
          headerTitle="Min"
          InputLabel="Min"
        />

        {/*Low Range Popup */}
        <RangeDialog
          dialogVisible={lowRangeDialogVisible}
          type={'Infusion'}
          value={formik.values.rangeLow}
          handleSet={(e: any) => {
            handleRangeset(e, 'low');
          }}
          setDialogVisible={() => {
            setLowRangeDialogVisible(false);
            setEditData(null);
          }}
          headerText="Low Range"
          headerTitle="Lower"
          InputLabel="LOWER"
        />

        {/* High Range  Popup */}
        <RangeDialog
          dialogVisible={highRangeDialogVisible}
          type={'Infusion'}
          value={formik.values.rangeHigh}
          handleSet={(e: any) => {
            handleRangeset(e, 'high');
          }}
          setDialogVisible={() => {
            setHighRangeDialogVisible(false);
            setEditData(null);
          }}
          headerText="High Range"
          headerTitle="Upper"
          InputLabel="UPPER"
        />

        {/*Confirm and Warning Popup */}
        <ConfirmWarningModal
          isVisible={isConfirmModal}
          type={modalType}
          handleClose={() => {
            setIsConfirmMOdal(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        {/* Edit Route Popup */}
        {routeDialogVisible && (
          <RouteDialog
            visible={routeDialogVisible}
            // routeItem={addedRoutesList}
            availableRoutes={adminRoutes ?? parentModel?.routes}
            selectedRoutes={formik.values.routes}
            onClose={() => {
              setRouteDialogVisible(false);
              setEditData(null);
            }}
            handleNewRoute={handleNewRoute}
            handleSet={(routes: string[]) => {
              handleEditRouteset(routes);
            }}
          />
        )}

        {/* Add Route Popup */}
        <AddRouteModal
          dialogVisible={newRouteDialogVisible}
          handleSet={handleNewRouteset}
          onNewRouteCancel={() => {
            setNewRouteDialogVisible(false);
            routeModal !== 'new' && setRouteDialogVisible(true);
            setRouteModal('');
          }}
          headerText="New Administration Route"
        />

        {/* CalculationBasis Popup */}
        <CalculationBasisDialog
          unit={solidUnit}
          dialogVisible={calculationDialogVisible}
          type={'Infusion'}
          handleSet={handleCalculationBasisset}
          basis={formik.values.basis}
          setDialogVisible={() => {
            setCalculationDialogVisible(false);
          }}
          headerText="Calculation Basis"
        />

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleCloseWarningModal}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Infusion Dose.  Click cancel to return to Infusion Dose details.  To continue without saving changes, select Abandon Changes.`}
          />
        )}

        {isDeleteModal && (
          <ConfirmModal
            isVisible={isDeleteModal}
            title="Confirm Delete?"
            handleClose={() => {
              setIsDeleteModal(false);
            }}
            handleSubmit={handleDeleteDose}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={` Are you sure you want to delete this Infusion Dose.`}
          />
        )}
      </>
    );
  };

  const findDoses = (protocol: ProtocolItem) => {
    let doses = subMedicationItems.filter(
      (dose: MedicationSubItem) => dose.parentProtocol.uid === protocol.uid
    );
    return doses.length + ' doses';
  };

  return (
    <div>
      <Modals />

      <Sidebar
        header={customHeader}
        visible={visible}
        position="right"
        onHide={() => {
          if (!formik.dirty) handleCloseSideout();
        }}
        style={{
          minWidth: '600px',
        }}
        className="sidebarWidth"
      >
        <div style={{ padding: '0px 15px' }}>
          <p className="sidebarText">
            {dose ? 'Edit' : 'Create'} the information for{' '}
            {protocol ? protocol.name : ''} medications's dose.
          </p>
          <p className="sidebarText">
            Need more information: <a className="sidebarLink">Learn More</a>
          </p>
        </div>
        {!dose && (
          <div className="" style={{ padding: '0px 15px' }}>
            <span
              className="ketamine-general-label"
              style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
            >
              Input Type
            </span>
            <GeneralSelection
              items={['Create New', 'Copy Dose', 'Copy From Protocol']}
              selectedIndex={inputType}
              onPress={(item, index) => {
                if (inputType === COPY_PROTOCOL_DOSE)
                  handleSelectCopyDose(null);
                else handleSelectCopyDose(null);
                setParentProtocol(null);
                setInputType(index);
              }}
              labelField={(option) => option}
              valueField={(option) => option}
            />
          </div>
        )}

        {inputType === NEW_DOSE && (
          <>
            {!protocol && (
              <div className="" style={{ padding: '0px 15px' }}>
                <div style={{ marginTop: '15px' }}>
                  <span
                    className="ketamine-general-label"
                    style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
                  >
                    Protocol
                  </span>
                  <p className="sidebarText">
                    This is the protocol that this dose will belong to.
                  </p>
                </div>
                <SearchableDropdown<ProtocolItem>
                  id="searchDropdown"
                  value={parentProtocol ? parentProtocol : undefined}
                  options={allProtocols}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  onChange={(option: ProtocolItem) => {
                    setParentProtocol(option);
                  }}
                  onClear={() => {
                    setParentProtocol(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search protocol..."
                />
              </div>
            )}
            <div className="siderbarContent">
              <div style={{ marginTop: '15px' }}>
                <span className="ketamine-general-label">
                  Dose {doseIndex + 1}
                </span>
              </div>

              <div className="sidebarInputContainer">
                <DoseSelection
                  isEmergency={isEmergency}
                  type={'Infusion'}
                  formik={formik}
                  doseIndex={doseIndex}
                  parent={dose ? dose : '+ADD'}
                  onRangeClick={(
                    range: string,
                    isLow: boolean,
                    isVital: boolean
                  ) => {
                    handleRangeClick(range, isLow ? 'low' : 'high');
                  }}
                  onBasisClick={(
                    basis: string,
                    parent: MedicationItem | ElectricalItem
                  ) => {
                    setCalculationDialogVisible(true);
                  }}
                  onValueClick={(
                    value: string,
                    parent: EquipmentItem | VitalItem
                  ) => {
                    handleValueClick(value);
                  }}
                  onMinMaxDoseClick={(
                    value: string,
                    type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
                  ) => {
                    handleDoseClick(value, type);
                  }}
                  onRoutesClick={(routes: string[]) => {
                    // setRoute(routes.join(','));
                    setRouteDialogVisible(true);
                  }}
                  onSingleRouteClick={(route: string, isDelete: boolean) => {
                    if (isDelete) handleConfirm(route);
                  }}
                  handleSubmit={(dose: any) => {}}
                />
              </div>
              <div
                className="calculationDialogContent"
                style={{ marginBottom: '0px' }}
              >
                <label
                  htmlFor="isSolidOnly"
                  className="departmentItemText"
                  style={{ flexDirection: 'row' }}
                >
                  Emergency Note:
                  <InputSwitch
                    name="isSolidOnly"
                    style={{ marginLeft: '10px' }}
                    checked={isEmergency}
                    onChange={(e) => {
                      setIsEmergency(e.value);
                    }}
                  />
                </label>
              </div>
              <p className="sidebarText" style={{ marginLeft: '12px' }}>
                This will display a large red text for the medics and will NOT
                show any medication dose details.
              </p>
            </div>
          </>
        )}

        {inputType === COPY_DOSE && (
          <>
            {!selectedDose && (
              <span
                className="ketamine-general-label"
                style={{
                  padding: '0px 15px',
                  marginTop: 10,
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: 0,
                  fontSize: '18px',
                }}
              >
                Select Dose to Copy From
              </span>
            )}
            {selectedDose && (
              <h6
                className="headerText hoverText"
                style={{ padding: '0px 15px', marginTop: 10 }}
                onClick={() => {
                  handleSelectCopyDose(null);
                }}
              >
                <span className="" style={{ marginRight: '8px' }}>
                  <BiArrowBack
                    className="header-icon"
                    data-testid="isBackBtn"
                  />
                </span>
                {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}
                Edit Copied Dose
                {/* </span> */}
              </h6>
            )}
            {!protocol && selectedDose && (
              <div
                className=""
                style={{ padding: '0px 15px', marginBottom: 20 }}
              >
                <div style={{ marginTop: '0px' }}>
                  <span
                    className="ketamine-general-label"
                    style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
                  >
                    Protocol
                  </span>
                  <p className="sidebarText">
                    Select the protocol to add the doses to.
                  </p>
                </div>
                <SearchableDropdown<ProtocolItem>
                  id="searchDropdown"
                  value={parentProtocol ? parentProtocol : undefined}
                  options={allProtocols}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  onChange={(option: ProtocolItem) => {
                    setParentProtocol(option);
                  }}
                  onClear={() => {
                    setParentProtocol(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search protocol..."
                />
              </div>
            )}
            <div className="siderbarContent">
              {!selectedDose && <hr style={{ margin: 0, padding: 0 }} />}
              {subMedicationItems.length === 0 && (
                <div style={{ marginTop: '15px' }}>
                  <p className="sidebarText">
                    There are no doses to copy from.
                  </p>
                </div>
              )}
              {selectedDose ? (
                <div className="sidebarInputContainer">
                  <DoseSelection
                    type={'Infusion'}
                    formik={formik}
                    doseIndex={doseIndex}
                    parent={dose ? dose : '+ADD'}
                    onRangeClick={(
                      range: string,
                      isLow: boolean,
                      isVital: boolean
                    ) => {
                      handleRangeClick(range, isLow ? 'low' : 'high');
                    }}
                    onBasisClick={(
                      basis: string,
                      parent: MedicationItem | ElectricalItem
                    ) => {
                      setCalculationDialogVisible(true);
                    }}
                    onValueClick={(
                      value: string,
                      parent: EquipmentItem | VitalItem
                    ) => {
                      handleValueClick(value);
                    }}
                    onMinMaxDoseClick={(
                      value: string,
                      type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
                    ) => {
                      handleDoseClick(value, type);
                    }}
                    onRoutesClick={(routes: string[]) => {
                      // setRoute(routes.join(','));
                      setRouteDialogVisible(true);
                    }}
                    onSingleRouteClick={(route: string, isDelete: boolean) => {
                      if (isDelete) handleConfirm(route);
                    }}
                    handleSubmit={(dose: any) => {}}
                  />
                </div>
              ) : (
                <ViewportList items={subMedicationItems}>
                  {(dose: MedicationSubItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleSelectCopyDose(dose);
                        }}
                      >
                        <MedicationDose
                          dose={dose}
                          doseIndex={index + 1}
                          showProtocol={true}
                        />
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
          </>
        )}

        {inputType === COPY_PROTOCOL_DOSE && (
          <>
            {!selectedProtocol && (
              <span
                className="ketamine-general-label"
                style={{
                  padding: '0px 15px',
                  marginTop: 10,
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: 0,
                  fontSize: '18px',
                }}
              >
                Select Protocol to copy doses from
              </span>
            )}
            {selectedProtocol && (
              <h6
                className="headerText hoverText"
                style={{ padding: '0px 15px', marginTop: 10 }}
                onClick={() => {
                  setSelectedProtocol(null);
                  setSelectedProtocolDoses([]);
                }}
              >
                <span className="" style={{ marginRight: '8px' }}>
                  <BiArrowBack
                    className="header-icon"
                    data-testid="isBackBtn"
                  />
                </span>
                {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}
                View Doses
                {/* </span> */}
              </h6>
            )}
            {!protocol && selectedProtocol && (
              <div
                className=""
                style={{ padding: '0px 15px', marginBottom: 20 }}
              >
                <div style={{ marginTop: '0px' }}>
                  <span
                    className="ketamine-general-label"
                    style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
                  >
                    Protocol
                  </span>
                  <p className="sidebarText">
                    Select the protocol to add the doses to.
                  </p>
                </div>
                <SearchableDropdown<ProtocolItem>
                  id="searchDropdown"
                  value={parentProtocol ? parentProtocol : undefined}
                  options={allProtocols}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  onChange={(option: ProtocolItem) => {
                    setParentProtocol(option);
                  }}
                  onClear={() => {
                    setParentProtocol(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search protocol..."
                />
              </div>
            )}
            <div className="siderbarContent">
              {!selectedDose && <hr style={{ margin: 0, padding: 0 }} />}
              {doseProtocols.length === 0 && (
                <div style={{ marginTop: '15px' }}>
                  <p className="sidebarText">
                    There are no protocols with {parentModel.name} doses.
                  </p>
                </div>
              )}
              {selectedProtocol ? (
                <>
                  {doseProtocols.length === 0 && (
                    <div style={{ marginTop: '15px' }}>
                      <p className="sidebarText">
                        The protocol does not have {parentModel.name} doses.
                      </p>
                    </div>
                  )}
                  <ViewportList items={selectedProtocolDoses}>
                    {(dose: MedicationSubItem, index: number) => {
                      return (
                        <div
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleSelectCopyDose(dose);
                          }}
                        >
                          <MedicationDose
                            dose={dose}
                            doseIndex={index + 1}
                            showProtocol={false}
                          />
                        </div>
                      );
                    }}
                  </ViewportList>
                </>
              ) : (
                <ViewportList items={doseProtocols}>
                  {(prot: ProtocolItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ borderBottom: '1px solid #ccc' }}
                      >
                        <div
                          className="departmentItem"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '4fr 1fr',
                          }}
                          onClick={() => {
                            setSelectedProtocol(prot);
                            let doses = subMedicationItems.filter(
                              (dose: MedicationSubItem) =>
                                dose.parentProtocol.uid === prot.uid
                            );
                            setSelectedProtocolDoses(doses);
                          }}
                        >
                          <h6 className="departmentItemText">{prot.name}</h6>
                          <h6
                            className="departmentItemText"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {findDoses(prot)}
                            <span>
                              <FaChevronRight
                                className="icon-normal "
                                style={{ margin: '4px' }}
                              />
                            </span>
                          </h6>
                        </div>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
          </>
        )}

        <div className="sidebarButtons">
          <Button
            className="secondary-button btn-rightMargin"
            data-testid="cancelBtn"
            onClick={handleCloseSideout}
          >
            <span>
              <AiOutlineClose className="icon-normal" />
            </span>{' '}
            Cancel
          </Button>
          {editDose && (
            <Button
              className="red-background-button btn-rightMargin "
              data-testid="deleteBtn"
              onClick={() => {
                setIsDeleteModal(true);
              }}
            >
              Delete
            </Button>
          )}
          <Button
            className="primary-button"
            data-testid="saveBtn"
            onClick={handleSubmit}
            disabled={!isSaveValid}
          >
            <span>
              <AiOutlinePlus className="icon-normal" />
            </span>{' '}
            {buttonTitle}
          </Button>
        </div>
      </Sidebar>
    </div>
  );
};

export default InfusionDoseSideout;
