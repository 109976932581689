import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { globals } from '../../../../_global/common/Utils';
import MedicationItem from '../../../../../data/model/MedicationItem';
import AddRouteModal from '../../../../components/Modal/AddRouteModal';
import { FaCopy, FaTrash } from 'react-icons/fa6';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';

interface MedicationDoseProps {
  medication: MedicationItem;
  dose: any;
  index: number;
  db: DatabaseResponse;
  onMedicationChange?: (medication: MedicationItem | null) => void;
  onChange: (dose: any, index: number) => void;
  onDelete?: (index: number) => void;
  onCopy?: (index: number) => void;
}
const MedicationDoseInput: React.FC<MedicationDoseProps> = (props) => {
  const {
    medication,
    db,
    dose,
    index,
    onChange,
    onDelete,
    onCopy,
    onMedicationChange,
  } = props;
  const [med, setMed] = useState<MedicationItem | null>(medication);
  const [isRouteModal, setIsRouteModal] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);

  useEffect(() => {
    setMed(medication);
  }, [medication]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rangeLow: dose.rangeLow ? dose.rangeLow : '',
      rangeHigh: dose.rangeHigh ? dose.rangeHigh : '',
      dose: dose.dose ? dose.dose : dose.basis ? dose.basis : '',
      minSingleDose: dose.minSingleDose ? dose.minSingleDose : '',
      maxSingleDose: dose.maxSingleDose ? dose.maxSingleDose : '',
      maxTotalDose: dose.maxTotalDose ? dose.maxTotalDose : '',
      title: dose.title ? dose.title : '',
      warning: dose.warning ? dose.warning : '',
      instruction: dose.instructions
        ? dose.instructions
        : dose.instruction
          ? dose.instruction
          : '',
      note: dose.note ? dose.note : '',
      routes: dose.routes ? dose.routes : dose.route ? dose.route : [],
      ageGroup: dose.ageGroup ? dose.ageGroup : '',
    },
    validationSchema: Yup.object({
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      dose: Yup.string().required('Required'),
      minDose: Yup.number(),
      maxDose: Yup.number(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
      routes: Yup.array(),
      ageGroup: Yup.string(),
    }),

    onSubmit: (values) => {
      if (globals.debug) console.log('values', values);
    },
  });

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  useEffect(() => {
    onChange(formik.values, index);
  }, [formik.values]);

  const onRoutesClick = () => {
    setIsRouteModal(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      {/* Add Route Popup */}
      <AddRouteModal
        dialogVisible={isRouteModal}
        handleSet={(route: string) => {
          formik.setFieldValue('routes', [...formik.values.routes, route]);
          setIsRouteModal(false);
        }}
        onNewRouteCancel={() => {
          setIsRouteModal(false);
        }}
        headerText="New Administration Route"
      />

      <div
        style={{ display: 'flex', marginTop: '5px' }}
        className="ketamine-general-label"
      >
        <div
          style={{
            flex: 1,
            flexDirection: 'row',
            display: 'flex',
          }}
        >
          {/* <div style={{ marginRight: '10px' }}>Medication:</div>
          <div style={{ fontWeight: '500' }}>{medication.name}</div> */}
        </div>
        {onCopy && (
          <FaCopy
            className="cursorPointer"
            color={'#0479b3'}
            style={{
              marginRight: '12px',
            }}
            onClick={() => {
              onCopy(index);
            }}
          />
        )}
        {onDelete && (
          <FaTrash
            className="cursorPointer"
            color={'#8c1010'}
            onClick={() => {
              onDelete(index);
            }}
          />
        )}
      </div>
      <div className={`filled-input-container  ${''}`}>
        <div className={`${'doseGridPad'}`}>
          {!onMedicationChange ? (
            <label htmlFor={''} className={`import-medication-title`}>
              {medication.name}
            </label>
          ) : (
            <div>
              <label htmlFor={''} className={`input-label`}>
                Medication
              </label>
              <SearchableDropdown<MedicationItem>
                id="searchDropdown"
                value={med ? med : undefined}
                options={db.medications}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                redBorder={med == null}
                onChange={(option: MedicationItem) => {
                  setMed(option);
                  onMedicationChange(option);
                }}
                onClear={() => {
                  setMed(null);
                  onMedicationChange(null);
                }}
                isActive={true}
                placeholder="Search Medication..."
              />
            </div>
          )}
          <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={`input-label`}>
                  Range Low
                </label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="rangeLow"
                    name="rangeLow"
                    placeholder="Min"
                    value={formik.values.rangeLow ? formik.values.rangeLow : ''}
                    data-testid="rangeLow"
                    onChange={(e) => {
                      if (globals.debug) console.log('e', e.target.value);
                      formik.setFieldValue('rangeLow', e.target.value);
                    }}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` input-label`}>
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="rangeHigh"
                    name="rangeHigh"
                    placeholder="Max"
                    data-testid="rangeHigh"
                    value={
                      formik.values.rangeHigh &&
                      formik.values.rangeHigh !== globals.MAX_VALUE
                        ? formik.values.rangeHigh
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('rangeHigh', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ketamine-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <PiSyringe className="protocol-icons " />
              </div>
              <div className="form-group">
                <label className="input-label">Calculation Basis</label>
                <div
                  className={`p-input-icon-right ${formik.values.dose === '' ? 'red-border' : ''}`}
                >
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      formik.values.dose === '-1'
                        ? 'EMERGENCY'
                        : formik.values.dose
                    }
                    onChange={(e) => {
                      formik.setFieldValue('dose', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` input-label`}>
                  Min Single Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="minDose"
                    name="minDose"
                    data-testid="minDose"
                    placeholder="Max"
                    value={
                      formik.values.minSingleDose
                        ? formik.values.minSingleDose
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('minSingleDose', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` input-label`}>
                  Max Single Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`input-control`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={
                      formik.values.maxSingleDose
                        ? formik.values.maxSingleDose
                        : ''
                    }
                    onChange={(e) => {
                      formik.setFieldValue('maxSingleDose', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sidebarInputContent">
            <label htmlFor={''} className={` rowLabelWidth sidebarLabel`}>
              Route:
            </label>
            <div
              data-div-id="outside"
              className={`selectionArea-hover ${formik.values.routes.length === 0 ? 'red-border' : ''}`}
              onClick={() => {
                onRoutesClick();
              }}
            >
              {formik.values.routes.length !== 0 && (
                <ViewportList items={formik.values.routes}>
                  {(item: string, index) => {
                    return (
                      <div
                        data-div-id="grey"
                        key={index}
                        className={`selectedGreybox`}
                      >
                        <div>{item}</div>
                        <span
                          className={`close_icon_color ${'cursorPointer'}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            formik.setFieldValue(
                              'routes',
                              formik.values.routes.filter(
                                (r: string) => r !== item
                              )
                            );
                          }}
                        >
                          <AiOutlineCloseCircle className="cursorPointer icon-normal" />
                        </span>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
              {formik.values.routes.length === 0 && (
                <div className={`input-placeholder`}>Required...</div>
              )}
            </div>
          </div>
        </div>

        <div className={` ${'sideoutFieldPad'}`}>
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <label htmlFor="Title" className={`input-sidebarLabel `}>
              Title:{' '}
            </label>
            <InputText
              type="text"
              className={`input-control`}
              placeholder="Enter title..."
              data-testid="rangeHigh"
              value={formik.values.title}
              onChange={(e) => {
                formik.setFieldValue('title', e.target.value);
              }}
            />
          </div>
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <label htmlFor="Title" className={`input-sidebarLabel red`}>
              Warning:{' '}
            </label>
            <InputText
              type="text"
              className={`input-control`}
              placeholder="Enter warning..."
              data-testid="warning"
              value={formik.values.warning}
              onChange={(e) => {
                formik.setFieldValue('warning', e.target.value);
              }}
            />
          </div>
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <label htmlFor="Title" className={`input-sidebarLabel`}>
              Instructions:{' '}
            </label>
            <InputText
              type="text"
              className={`input-control`}
              placeholder="Enter instructions..."
              data-testid="instruction"
              value={formik.values.instruction}
              onChange={(e) => {
                formik.setFieldValue('instruction', e.target.value);
              }}
            />
          </div>
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <label htmlFor="Title" className={`input-sidebarLabel`}>
              Note:{' '}
            </label>
            <InputText
              type="text"
              className={`input-control`}
              placeholder="Enter note..."
              data-testid="note"
              value={formik.values.note}
              onChange={(e) => {
                formik.setFieldValue('note', e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MedicationDoseInput;
