import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { useEffect, useMemo, useState } from 'react';
import '../import/Import.scss';
import { ViewportList } from 'react-viewport-list';
import { FaChevronDown, FaChevronRight, FaPlus } from 'react-icons/fa6';
import { globals, upgradeVersion } from '../../../../_global/common/Utils';
import AIPdfParserMedicationInput from './AIPdfParserMedicationInput';
import {
  ElectricalOption,
  ElectricalRange,
  ElectricalShockOption,
  ElectricalShockRange,
  MedicationProtocol,
  MedicationRange,
  ProgressStatus,
} from '../../../../../models';
import MedicationItem from '../../../../../data/model/MedicationItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  MedicationJSON,
  createParentMedication,
} from '../../../../../data/functions/MedicationDB';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import { find } from 'lodash';
// import NewMedicationInput from './NewMedicationInput';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../../store/actions';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  ProtocolDB,
  createProtocol,
} from '../../../../../data/functions/ProtocolDB';
import Fuse from 'fuse.js';
import { Button, Col, Row } from 'react-bootstrap';
import { error, group } from 'console';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  createParentInfusion,
  InfusionJSON,
} from '../../../../../data/functions/InfusionDB';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import Loading from '../../../../components/Loading/Loading';
import { IoArrowBack } from 'react-icons/io5';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import {
  createElectrical,
  ElectricalJSON,
} from '../../../../../data/functions/ElectricalDB';
import AIPdfParserElectricalInput from './AIPdfParserElectricalInput';

interface Dose {
  name: string;
  dose: string;
  administrationRoutes: any;
  maxSingleDose: string;
  minSingleDose: string;
  maxTotalDose: string;
  repeatTime: string;
  repeatAllowed: string;
  ageGroup: string;
  weightLow: string;
  weightHigh: string;
  ageLow: string;
  ageHigh: string;
  title: string;
  instruction: string;
  warning: string;
  source: string;
  medication?: MedicationItem;
  confirmedData?: any;
  isHmMedication?: boolean;
}
interface MedicationData {
  file: string;
  index: number;
  doses: Dose[];
}
interface ExpandedStates {
  [key: string]: boolean;
}
const AIPdfParserResultAutomatic = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    database,
    dbMeds,
    dbInfusions,
    dbElectricals,
    pdfKey,
    file,
    folder,
    startPage,
    endPage,
    aiParsedData,
    parsedProtocolData,
    pattern,
  } = location.state;

  console.log('RESULTS', location.state.results);
  console.log('Electricals', dbElectricals);

  const [completedResults, setCompletedResults] = useState<any[]>(
    location.state.completedResults || []
  );
  const [results, setResults] = useState<any[]>(location.state.results);
  const [selectedResult, setSelectResult] = useState<any | undefined>();

  const [db, setDatabase] = useState<DatabaseResponse>(database);
  const [meds, setMedications] = useState<any[]>(database.medications);
  const department: DepartmentItem = db.department;

  const [expandedStates, setExpandedStates] = useState<ExpandedStates>({
    Electrical: true,
    Infusion: true,
    Medication: true,
  });
  const [loading, setLoading] = useState<string | null>(null);

  const [final, setFinal] = useState<any>();

  const objURL = useMemo(
    () =>
      selectedResult
        ? URL.createObjectURL(selectedResult.fileUploaded)
        : ((file ? URL.createObjectURL(file) : '') as string),
    [selectedResult?.fileUploaded, file]
  );

  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });

  useEffect(() => {
    let res = [...location.state.results];
    for (let i = 0; i < res.length; i++) {
      if (res[i].groupByType == null) continue;
      /* Map all the medications */
      let medicationData = res[i].groupByType.Medication;
      for (let j = 0; j < medicationData.length; j++) {
        let doses = medicationData[j].doses;
        for (let k = 0; k < doses.length; k++) {
          let dose = doses[k];
          let medication = findMedicationOrElectrical(dose.name, meds, dbMeds);
          if (medication) {
            dose.medication = medication.med;
            dose.isHmMedication = medication.isHM;
          }
        }
      }
      /* Map all the infusions */
      let infusionData = res[i].groupByType.Infusion;
      for (let j = 0; j < infusionData.length; j++) {
        let doses = infusionData[j].doses;
        for (let k = 0; k < doses.length; k++) {
          let dose = doses[k];
          let medication = findMedicationOrElectrical(
            dose.name,
            database.infusions,
            dbInfusions
          );
          if (medication) {
            dose.medication = medication.med;
            dose.isHmMedication = medication.isHM;
          }
        }
      }

      /* Map all the electrical */
      let electricalData = res[i].groupByType.Electrical;
      for (let j = 0; j < electricalData.length; j++) {
        let doses = electricalData[j].doses;
        for (let k = 0; k < doses.length; k++) {
          let dose = doses[k];
          let medication = findMedicationOrElectrical(
            dose.name,
            database.electrical,
            dbElectricals
          );
          if (medication) {
            dose.medication = medication.med;
            dose.isHmMedication = medication.isHM;
          }
        }
      }

      /* Update the results */
      res[i].groupByType.Medication = medicationData;
      res[i].groupByType.Infusion = infusionData;
      res[i].groupByType.Electrical = electricalData;
    }
    setResults(res);
  }, [location.state.results]);

  const handleBack = () => {
    navigate(`/actions/ai-pdf-parser/auto-protocol-extraction`, {
      state: {
        results: results,
        database: db,
        dbMeds: dbMeds,
        dbInfusions: dbInfusions,
        dbElectricals: dbElectricals,
        pdfKey: pdfKey,
        folder: folder,
        startPage: startPage,
        endPage: endPage,
        file: file,
        aiParsedData: aiParsedData,
        parsedProtocolData: parsedProtocolData,
        pattern: pattern,
        completedResults: completedResults,
      },
    });
  };

  const handleCreateMedications = async (
    groupedMedications: any
  ): Promise<MedicationItem[]> => {
    if (globals.debug) console.log('Creating medications');
    let newMeds: MedicationItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < groupedMedications.length; i++) {
      let newMed = groupedMedications[i];
      let medication: MedicationItem = newMed.medication;
      let importedOptions = newMed.protocolOptions;
      let currentMedicationOptions = medication.protocolOptions;
      if (globals.debug)
        console.log(
          'Creating medication',
          currentMedicationOptions,
          importedOptions
        );

      /* Merge the new options with the current options */
      let options = [...currentMedicationOptions];
      for (let j = 0; j < importedOptions.length; j++) {
        /* Check if the protocol is already in the current medication */
        let find = options.find(
          (item) => item.protocolID === importedOptions[j].protocolID
        );
        if (find) {
          /* Merge the options */
          let newMP = {
            protocolID: find.protocolID,
            options: [...find.options, ...importedOptions[j].options],
          };
          options = options.map((item) =>
            item.protocolID === newMP.protocolID ? newMP : item
          );
        } else {
          /* Add the new protocol to the options */
          options.push(importedOptions[j]);
        }
      }
      let createdBy =
        medication.depID !== department.id
          ? 'OneBot'
          : medication.dbMedication?.createdBy
            ? medication.dbMedication?.createdBy
            : 'OneBot';
      let activeID =
        medication.depID !== department.id
          ? null
          : medication.activeID
            ? medication.activeID
            : medication.uid;
      let version =
        medication.depID !== department.id
          ? null
          : medication.version
            ? upgradeVersion(medication.version)
            : 'v1.0.0';

      const newMedication: MedicationJSON = {
        name: medication.name,
        concentration: medication.concentrations,
        routes: medication.routes,
        rangeLow: !isNaN(Number(medication.rangeLow))
          ? Number(medication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(medication.rangeHigh))
          ? Number(medication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        protocolOptions: options,

        rxNormCode: medication.dbMedication?.rxNormCode,
        nemsisRoutes: medication.dbMedication?.nemsisRoutes,
        minDose: medication.dbMedication?.minDose,
        maxDose: medication.dbMedication?.maxDose,
        metaData: medication.dbMedication?.metaData,

        contraindication: medication.dbMedication?.contraindication,
        note: medication.dbMedication?.note,
        warning: medication.dbMedication?.warning,
        medClass: medication.dbMedication?.medClass,
        action: medication.dbMedication?.action,
        indication: medication.dbMedication?.indication,
        interaction: medication.dbMedication?.interaction,
        onset: medication.dbMedication?.onset,
        duration: medication.dbMedication?.duration,
        taggedProtocols: [], //protocolList,

        createdBy: createdBy,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        version: version,
      };

      if (globals.debug) console.log('Creating New Medication', newMedication);
      let results: Response = await createParentMedication(
        newMedication,
        medication.depID !== department.id ? undefined : medication
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created medication', results.data);
        let newMedication: MedicationItem = results.data;
        newMeds.push(newMedication);
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }
    if (globals.debug)
      console.log(
        'All Medications Created',
        index,
        '/',
        groupedMedications.length
      );
    return newMeds;
  };

  const handleCreateInfusions = async (
    groupedInfusions: any
  ): Promise<MedicationItem[]> => {
    if (globals.debug) console.log('Creating infusions');
    let newMeds: MedicationItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < groupedInfusions.length; i++) {
      let newMed = groupedInfusions[i];
      let medication: MedicationItem = newMed.medication;
      let importedOptions = newMed.protocolOptions;
      let currentMedicationOptions = medication.protocolOptions;
      if (globals.debug)
        console.log(
          'Creating infusion',
          currentMedicationOptions,
          importedOptions
        );

      /* Merge the new options with the current options */
      let options = [...currentMedicationOptions];
      for (let j = 0; j < importedOptions.length; j++) {
        /* Check if the protocol is already in the current medication */
        let find = options.find(
          (item) => item.protocolID === importedOptions[j].protocolID
        );
        if (find) {
          /* Merge the options */
          let newMP = {
            protocolID: find.protocolID,
            options: [...find.options, ...importedOptions[j].options],
          };
          options = options.map((item) =>
            item.protocolID === newMP.protocolID ? newMP : item
          );
        } else {
          /* Add the new protocol to the options */
          options.push(importedOptions[j]);
        }
      }
      let createdBy =
        medication.depID !== department.id
          ? 'OneBot'
          : medication.dbMedication?.createdBy
            ? medication.dbMedication?.createdBy
            : 'OneBot';
      let activeID =
        medication.depID !== department.id
          ? null
          : medication.activeID != null
            ? medication.activeID
            : medication.uid;
      let version =
        medication.depID !== department.id
          ? null
          : medication.version
            ? upgradeVersion(medication.version)
            : 'v1.0.0';

      const newMedication: InfusionJSON = {
        name: medication.name,
        concentration: medication.concentrations,
        routes: medication.routes,
        rangeLow: !isNaN(Number(medication.rangeLow))
          ? Number(medication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(medication.rangeHigh))
          ? Number(medication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        dripOptions: options,

        rxNormCode: medication.dbMedication?.rxNormCode,
        nemsisRoutes: medication.dbMedication?.nemsisRoutes,
        minDose: medication.dbMedication?.minDose,
        maxDose: medication.dbMedication?.maxDose,
        metaData: medication.dbMedication?.metaData,

        contraindication: medication.dbMedication?.contraindication,
        note: medication.dbMedication?.note,
        warning: medication.dbMedication?.warning,
        medClass: medication.dbMedication?.medClass,
        action: medication.dbMedication?.action,
        indication: medication.dbMedication?.indication,
        interaction: medication.dbMedication?.interaction,
        onset: medication.dbMedication?.onset,
        duration: medication.dbMedication?.duration,
        taggedProtocols: [], //protocolList,

        createdBy: createdBy,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        version: version,
      };

      if (globals.debug) console.log('Creating New Infusion', newMedication);
      if (globals.debug)
        console.log(
          'Previous Infusion',
          medication.depID !== department.id ? undefined : medication
        );
      let results: Response = await createParentInfusion(
        newMedication,
        medication.depID !== department.id ? undefined : medication
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created infusion', results.data);
        let newMedication: MedicationItem = results.data;
        newMeds.push(newMedication);
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }
    if (globals.debug)
      console.log(
        'All Medications Created',
        index,
        '/',
        groupedInfusions.length
      );
    return newMeds;
  };

  const handleCreateElectricals = async (
    groupedElectricals: any
  ): Promise<ElectricalItem[]> => {
    if (globals.debug) console.log('Creating electricals');
    let newElecs: ElectricalItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < groupedElectricals.length; i++) {
      let newElec = groupedElectricals[i];
      let electrical: ElectricalItem = newElec.medication;
      let importedOptions = newElec.protocolOptions;
      let currentElectricalOptions = electrical.options;
      if (globals.debug)
        console.log(
          'Creating electrical',
          currentElectricalOptions,
          importedOptions
        );

      /* Merge the new options with the current options */
      let options = [...currentElectricalOptions];
      for (let j = 0; j < importedOptions.length; j++) {
        /* Check if the protocol is already in the current medication */
        let find = options.find(
          (item) => item.protocolID === importedOptions[j].protocolID
        );
        if (find) {
          /* Merge the options */
          let newEO: ElectricalShockOption = {
            protocolID: find.protocolID,
            ranges: [...find.ranges, ...importedOptions[j].ranges],
          };
          options = options.map((item) =>
            item.protocolID === newEO.protocolID ? newEO : item
          );
        } else {
          /* Add the new protocol to the options */
          options.push(importedOptions[j]);
        }
      }
      let createdBy =
        electrical.depID !== department.id
          ? 'OneBot'
          : electrical.model.createdBy
            ? electrical.model.createdBy
            : 'OneBot';
      let activeID =
        electrical.depID !== department.id
          ? null
          : electrical.activeID != null
            ? electrical.activeID
            : electrical.uid;
      let version =
        electrical.depID !== department.id
          ? null
          : electrical.version
            ? upgradeVersion(electrical.version)
            : 'v1.0.0';

      const newElectrical: ElectricalJSON = {
        title: electrical.name,
        rangeLow: !isNaN(Number(electrical.rangeLow))
          ? Number(electrical.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(electrical.rangeHigh))
          ? Number(electrical.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        options: options,

        rxNormCode: electrical.dbElectrical?.rxNormCode,
        // minDose: electrical.dbMedication?.minDose,
        // maxDose: electrical.dbElectrical,
        metaData: electrical.dbElectrical?.metaData,

        note: electrical.dbElectrical?.note ?? '',
        warning: electrical.dbElectrical?.warning ?? '',
        taggedProtocols: [], //protocolList,

        createdBy: createdBy,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        version: version,
      };

      if (globals.debug) console.log('Creating New Electrical', newElectrical);
      if (globals.debug)
        console.log(
          'Previous Electrical',
          electrical.depID !== department.id ? undefined : electrical
        );
      let results: Response = await createElectrical(
        newElectrical,
        electrical.depID !== department.id ? undefined : electrical
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created infusion', results.data);
        let newMedication: ElectricalItem = results.data;
        newElecs.push(newMedication);
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }
    if (globals.debug)
      console.log(
        'All Electricals Created',
        index,
        '/',
        groupedElectricals.length
      );
    return newElecs;
  };

  const handleUpdateProtocol = async (
    protocol: ProtocolItem,
    medications: MedicationItem[],
    infusions: MedicationItem[],
    electricals: ElectricalItem[]
  ): Promise<ProtocolItem> => {
    let p: ProtocolItem = protocol;
    if (globals.debug) console.log('Updating Protocols', p);
    let medIDs: string[] = p.model.medicationIDs
      ? ((protocol as ProtocolItem).model.medicationIDs as string[])
      : [];
    let infIDs: string[] = p.model.dripIDs ? (p.model.dripIDs as string[]) : [];
    let elecIDs: string[] = p.model.electricalIDs
      ? (p.model.electricalIDs as string[])
      : [];
    let newMedIDs = [...medIDs, ...medications.map((item) => item.uid)];
    let newInfIDs = [...infIDs, ...infusions.map((item) => item.uid)];
    let newElecIDs = [...elecIDs, ...electricals.map((item) => item.uid)];

    let json: ProtocolDB = {
      departmentID: database.department.id,
      name: p.name,
      nickname: p.nickname,
      pdfID: p.pdfUrl,
      rangeLow: p.rangeLow,
      rangeHigh: p.rangeHigh,
      parentCategory: p.parent,
      pairedDeps: p.pairedDeps,
      pairedProtocols: p.model.pairedProtocols ? p.model.pairedProtocols : [],
      medications: newMedIDs,
      infusions: newInfIDs,
      equipment: p.model.equipmentIDs ? p.model.equipmentIDs : [],
      electrical: newElecIDs,
      forms: p.model.formIDs ? p.model.formIDs : [],
      createdBy: p.model.createdBy ? p.model.createdBy : '',
      status: ProgressStatus.DRAFT,
      activeID: null,
      version: upgradeVersion(p.version),
      pdfVersion: p.pdfVersion,
      index: p.parent.protocols.length,
      isPublic: department.isPublic,
      keychainID: null,
    };

    let resp: Response = await createProtocol(json, protocol);
    if (resp.type === ResponseType.Success) {
      let newProtocol: ProtocolItem = resp.data;
      if (globals.debug) console.log('Updated Protocol', newProtocol);
      return newProtocol;
    } else {
      console.error('Failed to update protocol', resp.data);
      return protocol;
    }
  };

  const handleContinue = async () => {
    setLoading('Uploading Treatments to Protocols...');
    let allGroupedMeds: any[] = [];
    let allGroupedInfus: any[] = [];
    let allGroupedElectricals: any[] = [];

    for (let i = 0; i < results.length; i++) {
      let result = results[i];
      if (result.groupByType == null) continue;
      let protocol: ProtocolItem = result.newProtocol;
      let allTypeData = result.groupByType;

      /* First Update all the medications */
      let medicationsDoses: Dose[] = allTypeData.Medication?.reduce(
        (acc: Dose[], page: MedicationData) => {
          return [...acc, ...page.doses];
        },
        []
      );

      let infusionsDoses: Dose[] = allTypeData.Infusion?.reduce(
        (acc: Dose[], page: MedicationData) => {
          return [...acc, ...page.doses];
        },
        []
      );

      let electricalDoses: Dose[] = allTypeData.Electrical?.reduce(
        (acc: Dose[], page: MedicationData) => {
          return [...acc, ...page.doses];
        },
        []
      );

      // console.log('Group Medication Doses by Medication');
      allGroupedMeds = medicationsDoses
        ? groupMedicationsDataByParent(
            protocol,
            allGroupedMeds,
            medicationsDoses
          )
        : [];
      allGroupedInfus = infusionsDoses
        ? groupMedicationsDataByParent(
            protocol,
            allGroupedInfus,
            infusionsDoses
          )
        : [];
      allGroupedElectricals = electricalDoses
        ? groupElectricalDataByParent(
            protocol,
            allGroupedElectricals,
            electricalDoses
          )
        : [];
    }
    let medications = await handleCreateMedications(allGroupedMeds);
    let infusions = await handleCreateInfusions(allGroupedInfus);
    console.log('ALL GROUOPDED Electricals', allGroupedElectricals);
    let electricals = await handleCreateElectricals(allGroupedElectricals);

    for (let i = 0; i < results.length; i++) {
      let result = results[i];
      let protocol: ProtocolItem = result.newProtocol;
      let newProtocol = await handleUpdateProtocol(
        protocol,
        medications,
        infusions,
        electricals
      );
      console.log('Successfully Updated Protocol', newProtocol.name);
    }

    /* Now reload the database */
    let success = await reloadDatabase();
    if (success) {
      setCompletedResults([...completedResults, ...results]);
      setModalState({
        isVisible: true,
        title: 'Successfully Updated ' + folder.name + ' Protocols',
        description:
          'Successfully updated the folder ' +
          folder.name +
          ' with ' +
          results.length +
          ' protocols. You can now view the updated protocols in the ' +
          folder.name +
          ' folder.',
        primaryBtnName: 'Okay',
        secondaryBtnName: 'Okay',
        primaryDescription: '',
        secondaryDescription: '',
      });
    } else {
      console.error('ERROR RELOADING DATABASE');
    }

    setLoading(null);

    /* Now create all the medications & infusions */

    // for (let i = 0; i < allTypeData.Medication.length; i++) {
  };

  const groupMedicationsDataByParent = (
    protocol: ProtocolItem,
    curGroupedMeds: any[],
    doses: any[]
  ) => {
    let groupedMeds = [...curGroupedMeds];
    // let groupedMeds: any[] = [];
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (groupedMeds.length === 0) {
        groupedMeds.push({
          medication: dose.medication,
          protocolOptions: [
            {
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              options: [create_medication_range(dose)],
            },
          ] as MedicationProtocol[],
        });
      } else {
        let foundMedication = groupedMeds.find(
          (item) => item.medication.uid === dose.medication.uid
        );
        if (!foundMedication) {
          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: [
              {
                protocolID: protocol.activeID
                  ? protocol.activeID
                  : protocol.uid,
                options: [create_medication_range(dose)],
              },
            ] as MedicationProtocol[],
          });
        } else {
          /* Parse out the found medication */
          groupedMeds = groupedMeds.filter(
            (item) => item.medication.uid !== dose.medication.uid
          );

          let protOptions = [...foundMedication.protocolOptions];
          let index = protOptions.findIndex(
            (item) =>
              item.protocolID ===
              (protocol.activeID ? protocol.activeID : protocol.uid)
          );
          if (index === -1) {
            protOptions.push({
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              options: [create_medication_range(dose)],
            });
          } else {
            protOptions[index].options.push(create_medication_range(dose));
          }

          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: protOptions,
          });
        }
      }
    }

    return groupedMeds;
  };

  const groupElectricalDataByParent = (
    protocol: ProtocolItem,
    curGroupedElecs: any[],
    doses: any[]
  ) => {
    let groupedMeds = [...curGroupedElecs];
    // let groupedMeds: any[] = [];
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (groupedMeds.length === 0) {
        groupedMeds.push({
          medication: dose.medication,
          protocolOptions: [
            {
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              ranges: [create_electrical_range(dose)],
            },
          ] as ElectricalShockOption[],
        });
      } else {
        let foundMedication = groupedMeds.find(
          (item) => item.medication.uid === dose.medication.uid
        );
        if (!foundMedication) {
          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: [
              {
                protocolID: protocol.activeID
                  ? protocol.activeID
                  : protocol.uid,
                ranges: [create_electrical_range(dose)],
              },
            ] as ElectricalShockOption[],
          });
        } else {
          /* Parse out the found medication */
          groupedMeds = groupedMeds.filter(
            (item) => item.medication.uid !== dose.medication.uid
          );

          let protOptions = [...foundMedication.protocolOptions];
          let index = protOptions.findIndex(
            (item) =>
              item.protocolID ===
              (protocol.activeID ? protocol.activeID : protocol.uid)
          );
          if (index === -1) {
            protOptions.push({
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              ranges: [create_electrical_range(dose)],
            });
          } else {
            protOptions[index].ranges.push(create_electrical_range(dose));
          }

          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: protOptions,
          });
        }
      }
    }

    return groupedMeds;
  };

  const create_medication_range = (dose: any, index?: number) => {
    console.log('Creating Medication Range', dose);
    /* Convert the administration routes to an array */
    if (typeof dose.administrationRoutes === 'string')
      dose.administrationRoutes = dose.administrationRoutes.split(',');

    let range = new MedicationRange({
      basis: dose.dose,
      rangeLow: dose.weightLow
        ? Number(dose.weightLow.split(' ')[0])
        : dose.rangeLow
          ? Number(dose.rangeLow.split(' ')[0])
          : 0,
      rangeHigh: dose.weightHigh
        ? Number(dose.weightHigh.split(' ')[0])
        : dose.rangeHigh
          ? Number(dose.rangeHigh.split(' ')[0])
          : globals.MAX_VALUE,
      route: dose.administrationRoutes,
      title: dose.title,
      warning: dose.warning,
      instruction: dose.instruction,
      note: dose.note,
      maxDose: dose.maxSingleDose,
      minDose: dose.minSingleDose,
      calcMax: dose.calcMax,
      index: index ? index : 0,
      maxTotalDose: dose.maxTotalDose,
      repeatTime: dose.repeatTime,
      ageHigh: cleanAge(dose.ageHigh),
      ageLow: cleanAge(dose.ageLow),
    });
    return range;
  };

  const create_electrical_range = (dose: any, index?: number) => {
    console.log('Creating Electrical Range', dose);
    /* Convert the administration routes to an array */
    if (typeof dose.administrationRoutes === 'string')
      dose.administrationRoutes = dose.administrationRoutes.split(',');

    let range = new ElectricalShockRange({
      basis: dose.dose,
      rangeLow: dose.weightLow
        ? Number(dose.weightLow.split(' ')[0])
        : dose.rangeLow
          ? Number(dose.rangeLow.split(' ')[0])
          : 0,
      rangeHigh: dose.weightHigh
        ? Number(dose.weightHigh.split(' ')[0])
        : dose.rangeHigh
          ? Number(dose.rangeHigh.split(' ')[0])
          : globals.MAX_VALUE,
      title: dose.title,
      warning: dose.warning,
      instruction: dose.instruction,
      note: dose.note,
      fixedMax: dose.maxSingleDose,
      // minDose: dose.minSingleDose,
      calcMax: dose.calcMax,
      index: index ? index : 0,
      // maxTotalDose: dose.maxTotalDose,
      // repeatTime: dose.repeatTime,
      ageHigh: cleanAge(dose.ageHigh),
      ageLow: cleanAge(dose.ageLow),
    });
    return range;
  };

  const cleanAge = (age: string) => {
    if (age == null) return null;
    //Test if it is a number
    if (!isNaN(Number(age))) {
      return Number(age);
    }
    return null;
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(db.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const preprocessText = (text: string) => {
    return text
      .toLowerCase()
      .trim()
      .replace(/[.\s()+\-\/\\_]/g, ''); // Modified regex to capture other characters you mentioned
  };

  function create_process_list(
    medList: (MedicationItem | ElectricalItem)[],
    isHM: boolean
  ): any[] {
    let processList = [];
    // if (isHM) {
    for (let i = 0; i < medList?.length; i++) {
      let med = medList[i];
      let fullName =
        med instanceof MedicationItem
          ? med.dbMedication?.metaData?.fullName ?? ''
          : med.dbElectrical?.metaData?.fullName ?? '';
      let nickName =
        med instanceof MedicationItem
          ? med.dbMedication?.metaData?.nickName ?? ''
          : med.dbElectrical?.metaData?.nickName ?? '';
      let brandNames =
        med instanceof MedicationItem
          ? med.dbMedication?.metaData?.brandNames ?? []
          : [];
      processList.push({
        name: preprocessText(med.name),
        med: med,
        isHM: isHM,
      });
      processList.push({
        name: preprocessText(fullName),
        med: med,
        isHM: isHM,
      });
      processList.push({
        name: preprocessText(nickName),
        med: med,
        isHM: isHM,
      });
      for (let j = 0; j < brandNames.length; j++) {
        processList.push({
          name: preprocessText(brandNames[j]),
          med: med,
          isHM: isHM,
        });
      }
    }
    return processList;
  }

  function findMedicationOrElectrical(
    name: string,
    meds: (MedicationItem | ElectricalItem)[],
    hmMeds: (MedicationItem | ElectricalItem)[]
  ): any | undefined {
    const processedList = create_process_list(meds, false);

    // Options for Fuse.js
    const options = {
      includeScore: true, // Include the score in the result set
      findAllMatches: false, // Find all matches, not just the best match
      threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
      keys: ['name'], // Fields to search in each object
    };
    const fuse = new Fuse(processedList, options);
    //   const splitName = name.split(' ');
    //   for (let i = 0; i < splitName.length; i++) {
    let search = name
      .toLowerCase()
      .trim()
      .replace(/()-\/\\_/g, '');
    const result = fuse.search(search);
    for (let i = 0; i < result.length; i++) {
      if (result[i].score != null && (result[i].score as number) < 0.2)
        return {
          med: result[i].item.med,
          isHM: result[i].item.isHM,
        };
    }

    const hmProcessedList = create_process_list(hmMeds, true);
    const fuseHM = new Fuse(hmProcessedList, options);
    const resultHM = fuseHM.search(search);
    for (let i = 0; i < resultHM.length; i++) {
      if (resultHM[i].score != null && (resultHM[i].score as number) < 0.2)
        return {
          med: resultHM[i].item.med,
          isHM: resultHM[i].item.isHM,
        };
    }
    return undefined;
  }

  const toggleExpanded = (key: string) => {
    setExpandedStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const ProtocolItemUI = ({ name, length, errorCount }: any) => {
    const isExpanded = expandedStates[name];
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            isExpanded ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            toggleExpanded(name);
          }}
        >
          {isExpanded ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {name}
            </span>
          </div>

          {errorCount > 0 && (
            <span
              className="ketamine-general-label"
              style={{ marginRight: '10px', color: 'red' }}
            >
              Errors: {errorCount}
            </span>
          )}

          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {name === 'Electrical' ? 'Shocks: ' + length : 'Doses: ' + length}
          </span>
        </div>
      </div>
    );
  };

  const RenderDoses = (
    medicationData: MedicationData[],
    errorCount: number,
    key: string
  ) => {
    // const [data, setData] = useState<MedicationData[]>(medicationData);
    // const [errorCount, setErrorCount] = useState(
    //   calculateErrorCount(key, medicationData)
    // );

    // useEffect(() => {
    //   setData(medicationData);
    //   setErrorCount(calculateErrorCount(key, medicationData));
    // }, [medicationData, key]);

    if (medicationData.length === 0) return null;
    return (
      medicationData && (
        <div key={key}>
          <ProtocolItemUI
            name={key}
            length={medicationData.length}
            errorCount={errorCount}
            onClick={() => toggleExpanded(key)}
            expanded={expandedStates[key]}
          />
          {expandedStates[key] && key === 'Electrical' && (
            <p
              style={{
                color: 'red',
                fontSize: '14px',
                padding: 0,
                margin: 0,
              }}
            >
              NOTE: Title is a required field add the title of the shock for
              example: 'First Shock', 'Second Shock', 'Subsequent Shocks', 'V
              Fib', 'V Tach' or 'Asystole'
            </p>
          )}
          {expandedStates[key] &&
            medicationData.map((page, pageIndex) => (
              <div key={pageIndex}>
                <ViewportList items={page.doses}>
                  {(item: Dose, index: number) => {
                    item = item.confirmedData
                      ? { ...item.confirmedData }
                      : { ...item };
                    if (typeof item.administrationRoutes === 'string') {
                      item.administrationRoutes =
                        item.administrationRoutes.split(',');
                    }

                    const medicationDetails = findMedicationOrElectrical(
                      item.name == null ? '' : item.name,
                      key === 'Medication'
                        ? database.medications
                        : key === 'Infusion'
                          ? database.infusions
                          : database.electrical,
                      key === 'Medication'
                        ? dbMeds
                        : key === 'Infusion'
                          ? dbInfusions
                          : dbElectricals
                    );

                    console.log('Medication Details', medicationDetails);
                    console.log('Electricals', database.electrical);

                    return (
                      <div key={index} style={{ marginBottom: '10px' }}>
                        {key === 'Electrical' ? (
                          <AIPdfParserElectricalInput
                            dose={item}
                            db={database}
                            dbMeds={dbElectricals}
                            medication={
                              item.medication != null
                                ? item.medication
                                : medicationDetails?.med
                            }
                            isHMMedication={medicationDetails?.isHM}
                            index={index}
                            page_number={
                              page.index - Number(selectedResult.startPage)
                            }
                            onChange={(
                              data: any,
                              index: number,
                              med: ElectricalItem | null
                            ) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let newData = {
                                ...data,
                                medication: med,
                                isHmMedication: med
                                  ? med.depID !== database.department.id
                                  : false,
                              };
                              newMedicationData[pageIndex].doses[index] =
                                newData;

                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onDelete={(index: number) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              newMedicationData[pageIndex].doses.splice(
                                index,
                                1
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onCopy={(index) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let copyData =
                                newMedicationData[pageIndex].doses[index];
                              let newData = {
                                ...copyData,
                                title: copyData.title,
                              };
                              newMedicationData[pageIndex].doses.splice(
                                index + 1,
                                0,
                                newData
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                          />
                        ) : (
                          <AIPdfParserMedicationInput
                            dose={item}
                            db={database}
                            dbMeds={key === 'Medication' ? dbMeds : dbInfusions}
                            medication={
                              item.medication != null
                                ? item.medication
                                : medicationDetails?.med
                            }
                            isHMMedication={medicationDetails?.isHM}
                            index={index}
                            page_number={
                              page.index - Number(selectedResult.startPage)
                            }
                            onChange={(
                              data: any,
                              index: number,
                              med: MedicationItem | null
                            ) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let newData = {
                                ...data,
                                medication: med,
                                isHmMedication: med
                                  ? med.depID !== database.department.id
                                  : false,
                              };
                              newMedicationData[pageIndex].doses[index] =
                                newData;

                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onDelete={(index: number) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              newMedicationData[pageIndex].doses.splice(
                                index,
                                1
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                            onCopy={(index) => {
                              let result = { ...selectedResult };

                              let newMedicationData = [...medicationData];
                              let copyData =
                                newMedicationData[pageIndex].doses[index];
                              let newData = {
                                ...copyData,
                                title: copyData.title,
                              };
                              newMedicationData[pageIndex].doses.splice(
                                index + 1,
                                0,
                                newData
                              );
                              result.groupByType[key] = newMedicationData;
                              setSelectResult(result);

                              /* Update the results array with the new data */
                              let newResults = [...results];
                              let findIndex = newResults.findIndex(
                                (item) => item === selectedResult
                              );
                              newResults[findIndex] = result;
                              setResults(newResults);
                            }}
                          />
                        )}
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            ))}
        </div>
      )
    );
  };

  function calculateErrorCount(key: string, medicationData: MedicationData[]) {
    if (medicationData == null) return 0;
    let count = 0;
    for (let i = 0; i < medicationData?.length; i++) {
      for (let j = 0; j < medicationData[i].doses.length; j++) {
        if (key === 'Electrical') {
          let dose =
            medicationData[i].doses[j].confirmedData ||
            medicationData[i].doses[j];

          if (dose.dose === '' || dose.dose === null) count++;
          if (dose.title === '' || dose.title === null) count++;
        } else if (key === 'Infusion' || key === 'Medication') {
          let dose =
            medicationData[i].doses[j].confirmedData ||
            medicationData[i].doses[j];

          if (dose.dose === '' || dose.dose === null) count++;
          if (
            dose.administrationRoutes == null ||
            dose.administrationRoutes === '' ||
            dose.administrationRoutes?.length === 0
          )
            count++;
          if (dose.medication == null) count++;
        }
      }
    }
    return count;
  }

  const isContinueValid = useMemo(() => {
    let errorCount = 0;
    for (let i = 0; i < results.length; i++) {
      if (results[i].groupByType == null) continue;
      let keys = Object.keys(results[i].groupByType);
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j];
        errorCount += calculateErrorCount(key, results[i].groupByType[key]);
      }
    }
    return errorCount === 0;
  }, [results]);

  return (
    <div className="screen-container">
      {loading !== null && <Loading type="bubbles" message={loading} />}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
          navigate(`/actions/ai-pdf-parser/auto-protocol-extraction`, {
            state: {
              results: results,
              database: db,
              dbMeds: dbMeds,
              dbInfusions: dbInfusions,
              dbElectricals: dbElectricals,
              pdfKey: pdfKey,
              folder: null,
              startPage: startPage,
              endPage: endPage,
              file: file,
              aiParsedData: aiParsedData,
              parsedProtocolData: parsedProtocolData,
              pattern: pattern,
              completedResults: completedResults,
            },
          });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.description}
        secondaryDescription={modalState.secondaryDescription}
      />
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <ProtocolHeader
          page={'AI PDF Parser'}
          name={'Review Protocols and Extarcted Treatments'}
          description={
            'Successfully created ' +
            results.length +
            ' protocols in ' +
            folder.name +
            '. Review all of the extracted treatments and make any necessary changes.'
          }
          isBackButton={true}
          rightSideBtn={'edit'}
          isEditButton={false}
          isReviewButton={false}
          isReviewActive={false}
          isCustomButton={true}
          isCustomActive={isContinueValid}
          customText="Continue"
          isCancelButton={true}
          handleCustom={handleContinue}
          type={'protocol'}
          handleCancel={() => {
            if (selectedResult) setSelectResult(undefined);
            else handleBack();
          }}
          handleCancelEdit={handleBack}
        />
      </div>

      <div style={{ flex: 1, display: 'flex', ...props.containerStyle }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Row>
            <Col sm={7}>
              {/*  */}
              <div className="" style={{ padding: '0px 15px' }}></div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: '30px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    gap: '1rem',
                    // overflowY: 'auto',
                    // maxHeight: 'calc(100vh - 200px)',
                    height: '100%',
                  }}
                >
                  {selectedResult ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0px 15px',
                        flex: 1,
                      }}
                    >
                      <div
                        className=""
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <h6
                          className={`backTextLight hoverText`}
                          onClick={() => {
                            setSelectResult(undefined);
                          }}
                        >
                          <span>
                            <IoArrowBack
                              data-testid="isBackBttn"
                              size="1.15rem"
                              style={{ marginRight: '5px' }}
                            />
                          </span>
                          {folder.name} Protocols
                        </h6>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <label
                          htmlFor="protocolName"
                          className="ketamine-general-label"
                          style={{
                            fontSize: '1.5rem',
                            flex: 1,
                          }}
                        >
                          {selectedResult.newProtocol.name} Treatments
                        </label>
                        <Button
                          className={` ${
                            // rightSideBtn === "save"
                            'primary-button btn-rightMargin'
                            // : "secondary-button-small-border btn-rightMargin"
                          }`}
                          style={{
                            marginTop: '4px',
                          }}
                          onClick={() => {
                            //Create a new medication
                            let result = { ...selectedResult };
                            let newMedicationData = [
                              ...result.groupByType['Medication'],
                            ];
                            newMedicationData.push({
                              medication: null,
                              doses: [],
                            });
                            result.groupByType['Medication'] =
                              newMedicationData;
                            setSelectResult(result);

                            /* Update the results array with the new data */
                            let newResults = [...results];
                            let findIndex = newResults.findIndex(
                              (item) => item === selectedResult
                            );
                            newResults[findIndex] = result;
                            setResults(newResults);
                          }}
                        >
                          <span>
                            <FaPlus size="0.8rem" />
                          </span>{' '}
                          Add
                        </Button>
                      </div>
                      <span
                        className="contentText greyText"
                        style={{ fontWeight: '500', marginLeft: '10px' }}
                      >
                        Review the extracted treatments and make any necessary
                        changes
                      </span>
                      {selectedResult.groupByType &&
                        Object.entries(selectedResult.groupByType).map(
                          ([key, data]) =>
                            RenderDoses(
                              data as MedicationData[],
                              calculateErrorCount(
                                key,
                                data as MedicationData[]
                              ),
                              key
                            )
                        )}
                      {selectedResult.groupByType == null && (
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '5rem',
                          }}
                        >
                          <span
                            className="contentText greyText"
                            style={{ fontWeight: '500', marginLeft: '10px' }}
                          >
                            No treatments were extracted from this protocol...
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="protocolName"
                        className="ketamine-general-label"
                        style={{
                          fontSize: '1.5rem',
                        }}
                      >
                        Folder "{folder.name}" {results.length} Protocols
                      </label>
                      <span
                        className="contentText greyText"
                        style={{ fontWeight: '500', marginLeft: '10px' }}
                      >
                        Select a protocol to view the extracted treatments
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          // overflowY: 'auto',
                          // maxHeight: 'calc(100vh - 200px)',
                          border: '1px solid #e0e0e0',
                          borderRadius: '5px',
                        }}
                      >
                        <ViewportList items={results}>
                          {(item: any, index: number) => {
                            let errorCount = 0;
                            if (item.groupByType) {
                              errorCount =
                                calculateErrorCount(
                                  'Medication',
                                  item.groupByType.Medication
                                ) +
                                calculateErrorCount(
                                  'Infusion',
                                  item.groupByType.Infusion
                                ) +
                                calculateErrorCount(
                                  'Electrical',
                                  item.groupByType.Electrical
                                );
                            }
                            return (
                              <div
                                onClick={() => {
                                  // if (
                                  //   item.groupByType &&
                                  //   (item.groupByType.Medication.length > 0 ||
                                  //     item.groupByType.Infusion.length > 0 ||
                                  //     item.groupByType.Electrical.length > 0)
                                  // )
                                  setSelectResult(item);
                                }}
                                className={`hover-background ${index % 2 === 0 ? 'even' : 'odd'}`}
                                style={{}}
                              >
                                <label
                                  className=""
                                  style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: 0,
                                    padding: 0,
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {item.newProtocol.name}
                                </label>
                                {errorCount > 0 && (
                                  <label
                                    className=""
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      margin: 0,
                                      padding: 0,
                                      fontWeight: '600',
                                      cursor: 'pointer',
                                      fontSize: '1rem',
                                      color: 'red',
                                      marginRight: '1rem',
                                    }}
                                  >
                                    {errorCount} Errors
                                  </label>
                                )}
                                <label
                                  className=""
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    margin: 0,
                                    padding: 0,
                                    fontSize: '1rem',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {item.groupByType
                                    ? item.groupByType.Medication.length +
                                      item.groupByType.Infusion.length +
                                      item.groupByType.Electrical.length
                                    : 0}{' '}
                                  Treatments
                                </label>
                                <FaChevronRight
                                  style={{
                                    marginLeft: '1rem',
                                    cursor: 'pointer',
                                    padding: '0px',
                                    height: '1rem',
                                    width: '1rem',
                                  }}
                                />
                              </div>
                            );
                          }}
                        </ViewportList>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col
              sm={5}
              style={{
                display: 'flex',
                height: '80vh',
                top: '224px',
                position: 'sticky',
              }}
            >
              {objURL ? (
                <embed
                  title="PDF Document"
                  type="application/pdf"
                  src={objURL}
                  style={{ height: '100%', width: '100%' }}
                />
              ) : (
                <div>Loading...</div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AIPdfParserResultAutomatic;
