import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleUpdateSelectedAccordion } from '../../../../store/actions';
import ProtocolAccordionSecondLayer from './ProtocolAccordionSecondLayer';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import FormItem from '../../../../data/model/FormItem';
import DepartmentItem from '../../../../data/model/DepartmentItem';

import { getFormattedDate, globals } from '../../../_global/common/Utils';
import { InputText } from 'primereact/inputtext';
import { Col, Row } from 'react-bootstrap';
import { TbDatabaseOff } from 'react-icons/tb';
import MedicationItem from '../../../../data/model/MedicationItem';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { ViewportList } from 'react-viewport-list';
import { FaTimes } from 'react-icons/fa';
import MedicationAccordion from '../edit/Medication/MedicationAccordion';
import { User } from '../../../../models';
import { UserType } from '../../../../models';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { ProgressStatus } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import EditAccessModal from '../../../components/Modal/EditAccessModal';
import CategoryItem from '../../../../data/model/CategoryItem';

type CalculationScreenProps = {
  onItemClick: (
    item:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  onReorder: (
    list: (
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
    )[],
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  formik: any;
  isEditMode: any;
  protocolDetail: any;
  newEquipment?: EquipmentItem[];
  newChecklists?: FormItem[];
  newPairedProtocols?: ProtocolItem[];
};

/* 10-02-23 Arul: Created CalculationScreen component  for Protocol detail page*/
const CalculationScreen: React.FC<CalculationScreenProps> = ({
  onItemClick,
  onReorder,
  formik,
  protocolDetail,
  isEditMode,
  newEquipment,
  newChecklists,
  newPairedProtocols,
}) => {
  const database = useSelector((state: any) => state.protocol.departmentItem);
  const department: DepartmentItem = database.department;
  const [protocol, setProtocol] = useState<ProtocolItem>(protocolDetail);
  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [isAccessVisible, setAccessVisible] = useState<boolean>(false);

  const dispatch = useDispatch();
  const selectedAccordion = useSelector(
    (state: any) => state.protocol.selectedAccordion
  );
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  useEffect(() => {
    setProtocol(protocolDetail);
  }, [protocolDetail]);

  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !formik.values.pairedDeps.some(
          (d: DepartmentItem) => d.id === dep.id
        );
      });
      setDepartmentList(l);
    }
  }, [department, formik.values.pairedDeps]);

  // const protocol = useSelector((state: any) => state?.protocol?.protocol);

  const handleClick = (e: any) => {
    dispatch<any>(handleUpdateSelectedAccordion(e.index));
  };

  const handleRemoveDepartment = (item: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = formik.values.pairedDeps.filter(
      (dep: DepartmentItem) => dep.id !== item.id
    );
    formik.setFieldValue('pairedDeps', l);
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...formik.values.pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    formik.setFieldValue('pairedDeps', l);
  };

  const handleClearDepartments = () => {
    formik.setFieldValue('pairedDeps', []);
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div className="ketamineContent">
      <EditAccessModal
        isVisible={isAccessVisible}
        handleClose={() => setAccessVisible(false)}
        handleSubmit={(
          newItem: DepartmentItem | CategoryItem | ProtocolItem,
          metaData: any
        ) => {
          console.log('Updated item:', newItem);
          setAccessVisible(false);
          setProtocol(newItem as ProtocolItem);
        }}
        department={department}
        keychains={database.keychains}
        item={protocol}
      />
      <div className="KetamineGeneral">
        <h5 className="ketmine-header-text">General Information</h5>
        <div className="container overflow-info-first overflow-info-container">
          <div className="input-container roundBorder">
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
              }}
              className="ketamine-general-label"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Access:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  <Status
                    status={
                      !protocol.isPublic
                        ? 'Private'
                        : protocol.keychainID
                          ? 'Protected'
                          : 'Public'
                    }
                    onClick={
                      isEditMode
                        ? (status: string) => {
                            setAccessVisible(true);
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {protocol.modifiedBy
                    ? protocol.modifiedBy.firstName +
                      ' ' +
                      protocol.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {protocol.model.updatedAt && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedDate(protocol.model.updatedAt, false)}
                  </div>
                </div>
              )}
              {protocol.version && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Version:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {protocol.version}
                  </div>
                </div>
              )}
              {protocol.version && (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>PDF Version:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {protocol.pdfVersion}
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '10px' }}>ID:</div>
                    <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                      {protocol.uid}
                      <span>
                        {isCopied && isCopied === protocol.uid ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) => handleCopy(protocol.uid, e)}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                  {protocol.activeID && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ marginRight: '10px' }}>Active ID:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {protocol.activeID}
                        <span>
                          {isCopied && isCopied === protocol.activeID ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(protocol.activeID ?? '', e)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Nickname <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="nickname"
              name="nickname"
              data-testid="nickname"
              required={true}
              value={formik.values.nickname}
              onChange={(e: any) => {
                formik.setFieldValue('nickname', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={formik.values.rangeLow}
                  // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  disabled={!isEditMode}
                  onChange={(e: any) => {
                    /* Check for "Min" */
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeLow', 'Invalid input');
                    else formik.setFieldValue('rangeLow', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeLow && formik.errors.rangeLow ? (
                <span className="errorText">{formik.errors.rangeLow}</span>
              ) : null}
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode}
                  value={
                    formik.values.rangeHigh &&
                    Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                      ? formik.values.rangeHigh + ''
                      : ''
                  }
                  placeholder="Max"
                  onChange={(e: any) => {
                    let n = Number(e.target.value);
                    if (isNaN(n))
                      formik.setFieldError('rangeHigh', 'Invalid input');
                    else formik.setFieldValue('rangeHigh', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                <span className="errorText">{formik.errors.rangeHigh}</span>
              ) : null}
            </Col>
          </Row>

          {department.subDeps && department.subDeps.length > 0 && (
            <div>
              <label
                htmlFor="rangeHigh"
                className="ketamine-general-label"
                style={{
                  marginTop: '10px',
                }}
              >
                Subscribed Departments: {formik.values.pairedDeps.length} /{' '}
                {department.subDeps.length}
                {isEditMode && (
                  <span
                    onClick={() =>
                      formik.setFieldValue('pairedDeps', department.subDeps)
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                )}
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that subscribes to protocol{' '}
                {protocol.name}.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
                isActive={isEditMode}
              />
              {formik.values.pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  // overflowY: 'auto',
                  // maxHeight: '200px',
                  border:
                    formik.values.pairedDeps.length === 0
                      ? '0px'
                      : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={formik.values.pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === formik.values.pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className={
                          isEditMode ? 'icon-cancel' : 'icon-cancel-disabled'
                        }
                        size={16}
                        onClick={(e) => {
                          if (isEditMode) handleRemoveDepartment(item, e);
                        }}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="KetamineGeneral ">
        <h5 className="ketmine-header-text">Weight-Based Calculations</h5>
        {protocol.sum == 0 && (
          <div className="no-data-container">
            <TbDatabaseOff size={220} className="light-grey-icon" />
            <h4 className="light-grey-icon">No weight-based calculations...</h4>
          </div>
        )}
        <Accordion
          activeIndex={selectedAccordion}
          onTabChange={handleClick}
          multiple
        >
          {protocol.medications?.length >= 1 && (
            <AccordionTab data-testid="Medications" header="Medications">
              <div>
                <MedicationAccordion
                  medicationList={protocol.medications}
                  protocol={protocol}
                  isEditMode={isEditMode}
                  onReorder={(items: MedicationSubItem[]) =>
                    onReorder(items, 'Medication')
                  }
                  onItemClick={(item: MedicationSubItem) =>
                    onItemClick(item, 'Medication')
                  }
                />
              </div>
            </AccordionTab>
          )}
          {protocol.infusions?.length >= 1 && (
            <AccordionTab data-testid="Infusions" header="Infusions">
              <div>
                <ProtocolAccordionSecondLayer
                  data={protocol.infusions}
                  protocol={protocol}
                  type={'Infusion'}
                  accordionType={'Infusion'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                />
              </div>
            </AccordionTab>
          )}

          {protocol.electrical?.length >= 1 && (
            <AccordionTab data-testid="Electrical" header="Electrical">
              <div>
                <ProtocolAccordionSecondLayer
                  data={protocol.electrical}
                  protocol={protocol}
                  type={'Electrical'}
                  accordionType={'Electrical'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                />
              </div>
            </AccordionTab>
          )}
          {((newEquipment && newEquipment.length > 0) ||
            protocol.equipment?.length >= 1) && (
            <AccordionTab data-testid="Equipment" header="Equipment">
              <div>
                <ProtocolAccordionSecondLayer
                  data={newEquipment ? newEquipment : protocol.equipment}
                  protocol={protocol}
                  type={'Equipment'}
                  accordionType={'Equipment'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                />
              </div>
            </AccordionTab>
          )}
          {((newChecklists && newChecklists.length > 0) ||
            protocol.forms?.length >= 1) && (
            <AccordionTab data-testid="Checklist" header="Checklist">
              <div>
                <ProtocolAccordionSecondLayer
                  data={
                    newChecklists && newChecklists.length > 0
                      ? newChecklists
                      : protocol.forms
                  }
                  protocol={protocol}
                  type={'Checklist'}
                  accordionType={'Checklist'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                />
              </div>
            </AccordionTab>
          )}
          {((newPairedProtocols && newPairedProtocols.length > 0) ||
            protocol.pairedProtocols?.length >= 1) && (
            <AccordionTab
              data-testid="Paired Protocols"
              header="Paired Protocols"
            >
              <div>
                <ProtocolAccordionSecondLayer
                  data={
                    newPairedProtocols
                      ? newPairedProtocols
                      : protocol.pairedProtocols
                  }
                  protocol={protocol}
                  type={'Paired Protocol'}
                  accordionType={'Paired Protocol'}
                  isEditMode={isEditMode}
                  onReorder={onReorder}
                  onItemClick={onItemClick}
                />
              </div>
            </AccordionTab>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default CalculationScreen;
