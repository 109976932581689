import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { globals } from './ui/_global/common/Utils';
import { useSelector } from 'react-redux';

interface AuthRouteProps {
  content: any;
}

/* Determines if we need to check the user's authentication */
export function checkPublicURL(path: string) {
  let split = path.split('/');
  if (split.length > 1) {
    if (
      (split[1] === 'sites' || split[1] === 'fullscreen-pdf') &&
      (split.length >= 3 || split.length <= 5) &&
      split[2].length > 0
    ) {
      return true;
    }
  }
  return false;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ content }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);

  useEffect(() => {
    let split = location.pathname.split('/');
    if (globals.debug)
      console.log('Location Pathname: ', location.pathname, split);

    /* Base case for fullscreen PDF & sites */
    if (checkPublicURL(location.pathname)) {
      //   if (
      //     split.length > 2 &&
      //     (split[1] === 'sites' || split[1] === 'fullscreen-pdf')
      //   )
      //     setCheckedAuth(true);
      //   else if (isLoggedIn) navigate(`/protocol`);
      //   else navigate(`/login`);
      return;
    }

    if (
      !isLoggedIn &&
      location.pathname !== '/login' &&
      location.pathname !== '/404'
    ) {
      navigate(`/login`);
    } else if (isLoggedIn && location.pathname === '/') {
      navigate(`/protocol`);
    }
  }, [location.pathname]);

  return <>{content}</>;
};

export default AuthRoute;
