// Note: EditDB for CategoryItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';
import { Category, User } from '../../models';
import { DatabaseResponse, Response, ResponseType } from '../AmplifyDB';
import CategoryItem, { cloneCategory } from '../model/CategoryItem';
import { ProgressStatus } from '../../API';
import { globals, upgradeVersion } from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import { update } from 'lodash';
import { prefix } from '@fortawesome/free-solid-svg-icons';
import ProtocolItem, { cloneProtocol } from '../model/ProtocolItem';
import {
  createProtocol,
  deleteProtocol,
  duplicateProtocol,
} from './ProtocolDB';
import KeychainItem from '../model/KeychainItem';
import { exec } from 'child_process';
import { executeQuery } from '../GraphQL_API';
import { getCategory } from '../../graphql/queries';
import { graphqlOperation } from 'aws-amplify';

export type CategoryJSON = {
  name: string;
  index: number;
  departmentID: string;
  pairedDeps: string[];
  modifiedBy?: string;
  createdBy: string;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  version: string | null | undefined;
  keychainID: string | null | undefined;
  isPublic: boolean;
};

export const updateCategoryAccess = async (
  category: CategoryItem,
  isPublic?: boolean,
  keychain?: KeychainItem,
  override?: boolean
): Promise<Response> => {
  try {
    const dbItem = await DataStore.query(Category, category.uid);
    if (!dbItem) {
      return {
        type: ResponseType.Failure,
        data: 'Category does not exist',
      };
    }

    if (!override && keychain && dbItem.keychainID) {
      return {
        type: ResponseType.Failure,
        data: 'Category already has a keychain',
      };
    }

    let newItem = await DataStore.save(
      Category.copyOf(dbItem, (updated) => {
        updated.keychainID = keychain ? keychain.uid : null;
        updated.isPublic = isPublic ? isPublic : false;
      })
    );

    let newCategory = cloneCategory(category);
    newCategory.keychainID = keychain ? keychain.uid : null;
    newCategory.model = newItem;
    newCategory.isPublic = isPublic ? isPublic : false;

    return {
      type: ResponseType.Success,
      data: newCategory,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

/**
 * This function will check if the category is a draft version and delete it
 * @param categoryItem The category to check
 * @returns Success if ready to create a new category or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    if (globals.debug)
      if (globals.debug)
        console.log(
          'Checking if version already contains a DRAFT:',
          id,
          'IS ALREADT ACTIVE',
          isActive
        );
    let results: Category[];
    if (isActive)
      results = await DataStore.query(Category, (c) => c.activeID.eq(id));
    else
      results = await DataStore.query(Category, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCat = results[0];
    if (dbCat.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Category, dbCat.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCat,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new category in the database and choose the version
 * @param category CategoryDB JSON format
 * @param currentID The current ID of the category (Use case: upgrading a draft version where there is NO active version yet)
 * @returns The successful CategoryItem or the error
 */
export const createCategory = async (
  category: CategoryJSON | CategoryItem,
  previousItem?: CategoryItem
): Promise<Response> => {
  try {
    let json: CategoryJSON;
    if (category instanceof CategoryItem) {
      json = {
        name: category.name,
        index: category.index,
        departmentID: category.depID,
        pairedDeps: category.pairedDepIDs != null ? category.pairedDepIDs : [],
        status: category.status,
        activeID: category.activeID,
        version: category.version ? category.version : 'v1.0.0',
        modifiedBy: category.modifiedBy
          ? category.modifiedBy.id
          : category.model.modifiedBy
            ? category.model.modifiedBy
            : undefined,
        createdBy: category.model.createdBy ? category.model.createdBy : '',
        isPublic: category.isPublic,
        keychainID: category.keychainID,
      };
    } else json = category;

    let c: Category;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbCat = await DataStore.query(Category, previousItem.uid);
      if (dbCat == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT category does not exist could not update',
        };
      }

      c = await DataStore.save(
        Category.copyOf(dbCat, (updated) => {
          updated.name = json.name;
          updated.index = json.index;
          updated.pairedDepIDs = json.pairedDeps;
          updated.modifiedBy = json.modifiedBy;
          updated.isPublic = json.isPublic;
          updated.keychainID = json.keychainID;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a new Item */
      c = await DataStore.save(
        new Category({
          name: json.name,
          index: Number(json.index),
          departmentID: json.departmentID,
          pairedDepIDs: json.pairedDeps,
          status: json.status,
          activeID: json.activeID,
          version: json.version,
          modifiedBy: json.modifiedBy,
          createdBy: json.createdBy,
          isPublic: json.isPublic != null ? json.isPublic : false,
          keychainID: json.keychainID,
        })
      );
    }

    if (globals.debug) console.log('Created category:', c);
    let catItem = new CategoryItem(c);
    return {
      type: ResponseType.Success,
      data: catItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Publish all the drafts in the department for categories
 * @param department The department to publish the drafts
 * @returns The successful with a list of the updated categories or the error
 */
export const publishCategoryDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCategories = await DataStore.query(Category, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftCategories.length; i++) {
      let cat: Category = draftCategories[i];
      let response: Response = await publishCategory(cat);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: cat,
          message: `Published Folder: ${cat.name}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the category to the database
 *    1. Create a new ARCHEIVED category based on the current ACTIVE category
 *    2. Update the ACTIVE category with the new information
 *    3. Delete the DRAFT category
 * @param draftCategoryItem The category to publish
 */
export const publishCategory = async (
  draftCategoryItem: Category
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the category is configured correctly as a draft version */
    if (draftCategoryItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The category is not a draft version',
      };
    }

    let activeCategory: Category;

    /* Use case 1: Creating the FIRST active version */
    if (draftCategoryItem.activeID == null) {
      /* Update the draft category to be active */
      activeCategory = await DataStore.save(
        Category.copyOf(draftCategoryItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active category item */
      let id: string = draftCategoryItem.activeID;
      let curCategory = await DataStore.query(Category, id);

      /* Base Case 3 -- check if the active category exists */
      if (curCategory == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active category does not exist',
        };
      }

      let newArcheivedCategory = new CategoryItem(curCategory);
      newArcheivedCategory.status = ProgressStatus.ARCHIVE;
      newArcheivedCategory.activeID = curCategory.id;

      // 2. Create a new ARCHEIVED category based on the current ACTIVE category
      let archiveResult: Response = await createCategory(newArcheivedCategory);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedCategory = archiveResult.data as CategoryItem;

      // 2. Update the ACTIVE category with the new information
      activeCategory = await DataStore.save(
        Category.copyOf(curCategory, (updated) => {
          updated.name = draftCategoryItem.name;
          updated.index = draftCategoryItem.index;
          updated.pairedDepIDs = draftCategoryItem.pairedDepIDs;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCategoryItem.version; //upgradeVersion(draftCategoryItem.version == null ? "v1.0.0" : draftCategoryItem.version);
          updated.modifiedBy = draftCategoryItem.modifiedBy;
          updated.createdBy = draftCategoryItem.createdBy;
        })
      );

      // 3. Delete the DRAFT category
      let draftCategory = await DataStore.delete(
        Category,
        draftCategoryItem.id
      );
      if (draftCategory == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft category does not exist',
        };
      }
    }

    let catItem = new CategoryItem(activeCategory);
    return {
      type: ResponseType.Success,
      data: catItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteCategory = async (
  categoryItem: CategoryItem,
  modifiedBy: User,
  isSoft: boolean,
  includeSubItems: boolean = false
): Promise<Response> => {
  try {
    let id: string = categoryItem.uid;
    if (isSoft) {
      let category = await DataStore.query(Category, id);
      if (category == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category does not exist',
        };
      }

      let result = await DataStore.save(
        Category.copyOf(category, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category did not update correctly',
        };
      }

      if (globals.debug) console.log('Soft Deleted category:', category);
    } else {
      let category = await DataStore.delete(Category, id);
      if (category == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted category:', category);
    }

    if (includeSubItems) {
      for (let i = 0; i < categoryItem.protocols.length; i++) {
        let protocol = categoryItem.protocols[i];
        deleteProtocol(protocol, modifiedBy, isSoft, true)
          .then((response) => {
            if (response.type === ResponseType.Failure) {
              console.error('ERROR DELETING PROTOCOL:', response.data);
            }
          })
          .catch((error) => {
            console.error('ERROR DELETING PROTOCOL:', error);
          });
      }
      for (let i = 0; i < categoryItem.subCategories.length; i++) {
        let category = categoryItem.subCategories[i];
        deleteCategory(category, modifiedBy, isSoft, true)
          .then((response) => {
            if (response.type === ResponseType.Failure) {
              console.error('ERROR DELETING CATEGORY:', response.data);
            }
          })
          .catch((error) => {
            console.error('ERROR DELETING CATEGORY:', error);
          });
      }
    }
    return {
      type: ResponseType.Success,
      data: categoryItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCategryDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Category, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCategoryDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Category, (c) =>
      c.and((c) => [
        // c.or((c) => [
        c.status.eq('DRAFT'),
        // c.status.eq("DELETED")
        // ]),
        c.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if (model.status === "DELETED") message = `Deleted Folder: ${model.name}`;
      if (model.activeID == null) message = `Created Folder: ${model.name}`;
      else message = `Updated Folder: ${model.name}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const duplicateCategory = async (
  category: CategoryItem,
  previousCategory: CategoryItem,
  user: User
): Promise<Response> => {
  try {
    let json: CategoryJSON = {
      name: category.name,
      index: category.index,
      departmentID: category.depID,
      pairedDeps: category.pairedDepIDs != null ? category.pairedDepIDs : [],
      status: ProgressStatus.DRAFT,
      activeID: category.activeID,
      version: category.version,
      modifiedBy: undefined,
      createdBy: user.id,
      isPublic: category.isPublic,
      keychainID: category.keychainID,
    };

    let result = await createCategory(json);
    if (result.type === ResponseType.Failure) return result;
    let newCategory = result.data as CategoryItem;

    /* Add the previous categories associated protocols */
    let promises = [];
    for (let i = 0; i < previousCategory.protocols.length; i++) {
      let copyProtocol = previousCategory.protocols[i];
      let newProtocol = cloneProtocol(copyProtocol);
      newProtocol.createdBy = user;
      newProtocol.parent = newCategory;
      promises.push(duplicateProtocol(newProtocol, copyProtocol));
    }

    let results = await Promise.all(promises);

    for (let i = 0; i < results.length; i++) {
      if (results[i].type === ResponseType.Failure) {
        console.error('ERROR CREATING PROTOCOL:', results[i].data);
        if (globals.debug)
          console.log('Protocol Data', previousCategory.protocols[i]);
      } else newCategory.addProtocol(results[i].data as ProtocolItem);
    }

    return {
      type: ResponseType.Success,
      data: newCategory,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentCategoryDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCategories = await DataStore.query(Category, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftCategories.length; i++) {
      let cat: Category = draftCategories[i];
      await DataStore.delete(cat);
      updates.push({
        model: cat,
        message: `Removed Folder: ${cat.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getCategoryByID = async (
  db: DatabaseResponse,
  id: string
): Promise<CategoryItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbCat = db.categories.find((c) => c.uid === id);
      if (dbCat != null) return resolve(dbCat);
      else {
        executeQuery(graphqlOperation(getCategory, { id: id }), 1500)
          .then((data) => {
            if (data.data.getCategory == null) {
              resolve(null);
            } else {
              let cat = new CategoryItem(data.data.getCategory);
              resolve(cat);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
