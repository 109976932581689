import React, { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { ViewportList } from 'react-viewport-list';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import VitalItem from '../../../../data/model/VitalItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../../components/Search/SearchBar';
import { Accordion } from 'react-bootstrap';
import { ProgressStatus, User, Vitals } from '../../../../models';

const ListArchiveVitals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: vital, department, database } = state;

  const [filteredVital, setFilteredVital] = useState(vital);
  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [modifiedBy, setModifiedBy] = useState<User | undefined>();
  const handleItemClick = (elec: Vitals) => {};

  useEffect(() => {
    const filtered = vital.filter(
      (vital: any) =>
        vital.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (vital.modifiedBy &&
          vital.modifiedBy.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredVital(filtered);
  }, [searchQuery, vital]);

  const handleBack = () => {
    navigate(`/archive`, { state: department });
  };

  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 2fr 2fr 1fr',
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={'Vital: ' + filteredVital.length + ' items'}
        page={department.name}
        type={'protocol'}
        status={ProgressStatus.ARCHIVE}
      />
      <Row>
        <Col sm={12}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Vitals...'}
          />
        </Col>
      </Row>
      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6 className="departmentItemText">Version</h6>
                <h6 className="departmentItemText">Name</h6>
                <h6 className="departmentItemText">Archived Date</h6>
                <h6 className="departmentItemText">Archived By</h6>
                <h6 className="departmentItemText">Sizes</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={filteredVital}>
            {(item: Vitals, index) => {
              // let username = item.modifiedBy
              //   ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
              //   : 'Hinckley Medical';
              if (globals.debug) console.log(item);
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.version}</h6>
                    <h6 className="departmentItemText">{item.title}</h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.updatedAt ? item?.updatedAt : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {modifiedBy
                        ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {item.options.length}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListArchiveVitals;
