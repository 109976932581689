import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import { useMemo, useState } from 'react';
import '../Import.scss';
import { ViewportList } from 'react-viewport-list';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ImportDoseResultsPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, file, database } = location.state;

  const [doseData, setDoseData] = useState<any>(data);
  const [expanded, setExpanded] = useState<string[]>([]);
  const isLandscape = window.innerWidth > window.innerHeight;

  const handleBack = () => {
    navigate(`/actions/import-medication-doses`);
  };

  const totalResults = useMemo(() => {
    let total = 0;
    for (let i = 0; i < doseData.pages.length; i++) {
      total += doseData.pages[i].doses.length;
    }
    return total;
  }, [doseData]);

  const totalMisses = useMemo(() => {
    let total = 0;
    total += doseData.misses.medication.length;
    return total;
  }, [doseData]);

  const medications = doseData.medications;
  const newMedications = doseData.newMedications;
  console.log('Data', doseData);

  const infusions: any[] = [];
  const equipments: any[] = [];
  const electricals: any[] = [];
  // const infusions = useMemo(() => {
  //   let infus = [];
  //   for (let i = 0; i < doseData.infusion.length; i++) {
  //     if (doseData.infusion[i].doses.length > 0) {
  //       infus.push(doseData.infusion[i]);
  //     }
  //   }
  //   return infus;
  // }, [doseData]);

  // const equipments = useMemo(() => {
  //   let equip = [];
  //   for (let i = 0; i < doseData.equipment.length; i++) {
  //     if (doseData.equipment[i].doses.length > 0) {
  //       equip.push(doseData.equipment[i]);
  //     }
  //   }
  //   return equip;
  // }, [doseData]);

  // const electricals = useMemo(() => {
  //   let electrical = [];
  //   for (let i = 0; i < doseData.electrical.length; i++) {
  //     if (doseData.electrical[i].doses.length > 0) {
  //       electrical.push(doseData.electrical[i]);
  //     }
  //   }
  //   return electrical;
  // }, [doseData]);

  function getFormattedBytes(bytes: string | number): string {
    let bytesNum = Number(bytes.toString());
    let i = 0;
    const byteUnits = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    while (bytesNum >= 1024) {
      bytesNum /= 1024;
      i++;
    }
    return bytesNum.toFixed(1) + ' ' + byteUnits[i];
  }

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(item: any, index: number) => {
          const lastIndex = data.length === index + 1;
          if (item.doses.length === 0) return null;
          return (
            <div
              className="result-list-item"
              key={index}
              style={{
                borderBottom: lastIndex ? 'none' : '1px solid #e0e0e0',
              }}
            >
              <span
                className="ketamine-general-label"
                style={{ flex: 1, fontWeight: '500' }}
              >
                {item.item.name}
              </span>
              <span className="ketamine-general-label" style={{}}>
                {item.doses.length} doses
              </span>
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ListRenderNew = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(item: any, index: number) => {
          const lastIndex = data.length === index + 1;
          // if (item.doses.length === 0) return null;
          return (
            <div
              className="result-list-item"
              key={index}
              style={{
                borderBottom: lastIndex ? 'none' : '1px solid #e0e0e0',
              }}
            >
              <span
                className="ketamine-general-label"
                style={{ flex: 1, fontWeight: '500' }}
              >
                {item.name}
              </span>
              {/* <span className="ketamine-general-label" style={{}}>
                {item.doses.length} doses
              </span> */}
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const handleContinue = () => {
    let type = 'medication';

    navigate(`/actions/import-medication-doses/medication-results`, {
      state: {
        data: data,
        file: file,
        database: database,
      },
    });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        // page={'Import Medication Doses'}
        name={'Excel Import Results'}
        description={'File: ' + file.name}
        isBackButton={false}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        isCustomButton={true}
        isCustomActive={
          newMedications.length > 0 ||
          medications.length > 0 ||
          infusions.length > 0 ||
          equipments.length > 0 ||
          electricals.length > 0
        }
        customText="Continue"
        isCancelButton={true}
        handleCustom={handleContinue}
        type={'protocol'}
        handleCancel={handleBack}
        handleCancelEdit={handleBack}
      />
      <div style={{ flex: 1, display: 'flex', ...props.containerStyle }}>
        {isLandscape ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            {/* <h6 className="result-title" style={{ marginBottom: 10 }}>
            File: {file.name} Results
          </h6> */}
            <div className="input-container roundBorder">
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'column',
                }}
                className="ketamine-general-label"
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>File:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {file.name}
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Size:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedBytes(file.size)} bytes
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Treatments Found:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {totalResults} items
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Missed items:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {totalMisses} items
                  </div>
                </div>
              </div>
            </div>
            <label
              htmlFor="Mediations"
              className="ketamine-general-label"
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Review Treatments
            </label>

            {newMedications.length > 0 && (
              <>
                <div
                  className={`border hover-raise-elevation ${
                    expanded.includes('medications') ? 'expanded' : ''
                  }`}
                  style={{
                    display: 'flex',
                    width: '100%',
                    padding: '10px',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                  onClick={() => {
                    if (expanded.includes('newMedications')) {
                      setExpanded(
                        expanded.filter((item) => item !== 'newMedications')
                      );
                    } else {
                      setExpanded([...expanded, 'newMedications']);
                    }
                  }}
                >
                  {expanded.includes('newMedications') ? (
                    <FaChevronDown size={14} color={'#a0a0a0'} />
                  ) : (
                    <FaChevronRight size={14} color={'#a0a0a0'} />
                  )}
                  <span className="ketamine-general-label" style={{ flex: 1 }}>
                    New Medications
                  </span>
                  <span
                    className="ketamine-general-label"
                    style={{ marginRight: '10px' }}
                  >
                    {newMedications.length} items
                  </span>
                </div>
                {expanded.includes('newMedications') && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <ListRenderNew data={newMedications} />
                  </div>
                )}
              </>
            )}

            <div
              className={`border hover-raise-elevation ${
                expanded.includes('medications') ? 'expanded' : ''
              }`}
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('medications')) {
                  setExpanded(
                    expanded.filter((item) => item !== 'medications')
                  );
                } else {
                  setExpanded([...expanded, 'medications']);
                }
              }}
            >
              {expanded.includes('medications') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Medications
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {medications.length} items
              </span>
            </div>
            {expanded.includes('medications') && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRenderNew data={medications} />
              </div>
            )}

            <div
              className="border hover-raise-elevation"
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('infusions')) {
                  setExpanded(expanded.filter((item) => item !== 'infusions'));
                } else {
                  setExpanded([...expanded, 'infusions']);
                }
              }}
            >
              {expanded.includes('infusions') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Infusions
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {infusions.length} items
              </span>
            </div>
            {expanded.includes('infusions') && infusions.length > 0 && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRender data={infusions} />
              </div>
            )}
            <div
              className="border hover-raise-elevation"
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('electricals')) {
                  setExpanded(
                    expanded.filter((item) => item !== 'electricals')
                  );
                } else {
                  setExpanded([...expanded, 'electricals']);
                }
              }}
            >
              {expanded.includes('electricals') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Electrical
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {electricals.length} items
              </span>
            </div>
            {expanded.includes('electricals') && electricals.length > 0 && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRender data={electricals} />
              </div>
            )}
            <div
              className="border hover-raise-elevation"
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('equipments')) {
                  setExpanded(expanded.filter((item) => item !== 'equipments'));
                } else {
                  setExpanded([...expanded, 'equipments']);
                }
              }}
            >
              {expanded.includes('equipments') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Equipment
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {equipments.length} items
              </span>
            </div>
            {expanded.includes('equipments') && equipments.length > 0 && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRender data={equipments} />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            {/* <h6 className="result-title" style={{ marginBottom: 10 }}>
              File: {file.name} Results
            </h6> */}
            <div className="input-container roundBorder">
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'column',
                }}
                className="ketamine-general-label"
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>File:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {file.name}
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Size:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {getFormattedBytes(file.size)} bytes
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Treatments Found:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {totalResults} items
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ marginRight: '10px' }}>Missed items:</div>
                  <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                    {totalMisses} items
                  </div>
                </div>
              </div>
            </div>
            <label
              htmlFor="Mediations"
              className="ketamine-general-label"
              style={{
                marginTop: '10px',
                marginBottom: '10px',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Review Treatments
            </label>

            {newMedications.length > 0 && (
              <>
                <div
                  className={`border hover-raise-elevation ${
                    expanded.includes('medications') ? 'expanded' : ''
                  }`}
                  style={{
                    display: 'flex',
                    width: '100%',
                    padding: '10px',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                  onClick={() => {
                    if (expanded.includes('newMedications')) {
                      setExpanded(
                        expanded.filter((item) => item !== 'newMedications')
                      );
                    } else {
                      setExpanded([...expanded, 'newMedications']);
                    }
                  }}
                >
                  {expanded.includes('newMedications') ? (
                    <FaChevronDown size={14} color={'#a0a0a0'} />
                  ) : (
                    <FaChevronRight size={14} color={'#a0a0a0'} />
                  )}
                  <span className="ketamine-general-label" style={{ flex: 1 }}>
                    New Medications
                  </span>
                  <span
                    className="ketamine-general-label"
                    style={{ marginRight: '10px' }}
                  >
                    {newMedications.length} items
                  </span>
                </div>
                {expanded.includes('newMedications') && (
                  <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                    <ListRenderNew data={newMedications} />
                  </div>
                )}
              </>
            )}

            <div
              className={`border hover-raise-elevation ${
                expanded.includes('medications') ? 'expanded' : ''
              }`}
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('medications')) {
                  setExpanded(
                    expanded.filter((item) => item !== 'medications')
                  );
                } else {
                  setExpanded([...expanded, 'medications']);
                }
              }}
            >
              {expanded.includes('medications') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Medications
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {medications.length} items
              </span>
            </div>
            {expanded.includes('medications') && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRenderNew data={medications} />
              </div>
            )}

            <div
              className="border hover-raise-elevation"
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('infusions')) {
                  setExpanded(expanded.filter((item) => item !== 'infusions'));
                } else {
                  setExpanded([...expanded, 'infusions']);
                }
              }}
            >
              {expanded.includes('infusions') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Infusions
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {infusions.length} items
              </span>
            </div>
            {expanded.includes('infusions') && infusions.length > 0 && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRender data={infusions} />
              </div>
            )}
            <div
              className="border hover-raise-elevation"
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('electricals')) {
                  setExpanded(
                    expanded.filter((item) => item !== 'electricals')
                  );
                } else {
                  setExpanded([...expanded, 'electricals']);
                }
              }}
            >
              {expanded.includes('electricals') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Electrical
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {electricals.length} items
              </span>
            </div>
            {expanded.includes('electricals') && electricals.length > 0 && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRender data={electricals} />
              </div>
            )}
            <div
              className="border hover-raise-elevation"
              style={{
                display: 'flex',
                width: '100%',
                padding: '10px',
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              onClick={() => {
                if (expanded.includes('equipments')) {
                  setExpanded(expanded.filter((item) => item !== 'equipments'));
                } else {
                  setExpanded([...expanded, 'equipments']);
                }
              }}
            >
              {expanded.includes('equipments') ? (
                <FaChevronDown size={14} color={'#a0a0a0'} />
              ) : (
                <FaChevronRight size={14} color={'#a0a0a0'} />
              )}
              <span className="ketamine-general-label" style={{ flex: 1 }}>
                Equipment
              </span>
              <span
                className="ketamine-general-label"
                style={{ marginRight: '10px' }}
              >
                {equipments.length} items
              </span>
            </div>
            {expanded.includes('equipments') && equipments.length > 0 && (
              <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
                <ListRender data={equipments} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportDoseResultsPage;
