// Note: EditDB for MedicationItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';
import {
  Drip,
  MedicationConcentration,
  MedicationProtocol,
  MedicationRange,
  MedicationRoute,
  ModelMetaData,
  User,
} from '../../models';
import { DatabaseResponse, Response, ResponseType } from '../AmplifyDB';
import { ProgressStatus } from '../../API';
import {
  globals,
  removeTypename,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import MedicationItem, { cloneMedication } from '../model/MedicationItem';
import MedicationSubItem from '../model/MedicationSubItem';
import ProtocolItem from '../model/ProtocolItem';
import { ProtocolDB, createProtocol, validatePointerID } from './ProtocolDB';
import { executeQuery } from '../GraphQL_API';
import { graphqlOperation } from 'aws-amplify';
import { getDrip } from '../../graphql/queries';

export type InfusionJSON = {
  name: string;
  concentration: MedicationConcentration[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  routes: string[];
  dripOptions: MedicationProtocol[];
  taggedProtocols?: ProtocolItem[];

  rxNormCode?: string | null | undefined;
  nemsisRoutes?: MedicationRoute[] | null | undefined;
  minDose?: string | null | undefined;
  maxDose?: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;

  contraindication: string | null | undefined;
  note: string | null | undefined;
  warning: string | null | undefined;
  medClass: string | null | undefined;
  action: string | null | undefined;
  indication: string | null | undefined;
  interaction: string | null | undefined;
  onset: string | null | undefined;
  duration: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  version: string | null | undefined;
};

export type InfusionDoseJSON = {
  index: number;
  basis: string;
  rangeLow: number;
  rangeHigh: number;
  route: string[];
  title?: string;
  warning?: string;
  instruction?: string;
  note?: string;
  maxDose?: string | null | undefined;
  minDose?: string | null | undefined;
  calcMaxDose?: string | null | undefined;
  calcMinDose?: string | null | undefined;
};

/**
 * This function will check if the infusion is a draft version and delete it
 * @param infusionItem The infusion to check
 * @returns Success if ready to create a new infusion or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    if (globals.debug) console.log('Checking for draft version:', id, isActive);
    let results: Drip[];
    if (isActive)
      results = await DataStore.query(Drip, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.activeID.eq(id)])
      );
    else
      results = await DataStore.query(Drip, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.id.eq(id)])
      );

    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions' + results.toString(),
      };
    }

    let dbMed = results[0];
    if (dbMed.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Drip, dbMed.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbMed,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new PARENT infusion in the database and choose the version
 * @param infusion InfusionJSON JSON format or Model MedicationItem
 * @returns The successful MedicationItem or the error
 */
export const createParentInfusion = async (
  infusion: InfusionJSON | MedicationItem,
  previousItem?: MedicationItem
): Promise<Response> => {
  try {
    let json: InfusionJSON;
    if (infusion instanceof MedicationItem) {
      let medItem = infusion as MedicationItem;
      if (medItem.dbDrip == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medItem does not have a database object',
        };
      }

      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < medItem.medicationSubItems.length; i++) {
        if (!protocols.includes(medItem.medicationSubItems[i].parentProtocol))
          protocols.push(medItem.medicationSubItems[i].parentProtocol);
      }

      json = {
        name: medItem.name,
        concentration: medItem.concentrations,
        rangeLow: medItem.rangeLow ? medItem.rangeLow : 0,
        rangeHigh: medItem.rangeHigh ? medItem.rangeHigh : globals.MAX_VALUE,
        departmentID: medItem.depID,
        routes: medItem.routes,
        dripOptions: medItem.protocolOptions,
        status: medItem.status,
        activeID: medItem.activeID,
        version: medItem.version,
        taggedProtocols: protocols,

        rxNormCode: medItem.dbDrip.rxNormCode,
        nemsisRoutes: medItem.dbDrip.nemsisRoutes,
        minDose: medItem.dbDrip.minDose,
        maxDose: medItem.dbDrip.maxDose,
        metaData: medItem.dbDrip.metaData,

        contraindication: medItem.dbDrip.contraindication,
        note: medItem.dbDrip.note,
        warning: medItem.dbDrip.warning,
        medClass: medItem.dbDrip.medClass,
        action: medItem.dbDrip.action,
        indication: medItem.dbDrip.indication,
        interaction: medItem.dbDrip.interaction,
        onset: medItem.dbDrip.onset,
        duration: medItem.dbDrip.duration,

        createdBy: medItem.dbDrip.createdBy ? medItem.dbDrip.createdBy : '',
        modifiedBy: medItem.modifiedBy ? medItem.modifiedBy.id : undefined,
      };
    } else json = infusion as InfusionJSON;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    let d: Drip;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbDrip = await DataStore.query(Drip, previousItem.uid);
      if (dbDrip == null) {
        return {
          type: ResponseType.Failure,
          data:
            'The DRAFT Infusion does not exist could not update. ID: ' +
            previousItem.uid,
        };
      }

      d = await DataStore.save(
        Drip.copyOf(dbDrip, (updated) => {
          updated.name = json.name;
          updated.concentration = json.concentration;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.route = json.routes;
          updated.dripOptions = json.dripOptions;

          updated.contraindication = json.contraindication;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.medClass = json.medClass;
          updated.action = json.action;
          updated.indication = json.indication;
          updated.interaction = json.interaction;
          updated.onset = json.onset;
          updated.duration = json.duration;

          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      d = await DataStore.save(
        new Drip({
          name: json.name,
          concentration: json.concentration,
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          departmentID: json.departmentID,
          route: json.routes,
          dripOptions: json.dripOptions,

          contraindication: json.contraindication,
          note: json.note,
          warning: json.warning,
          medClass: json.medClass,
          action: json.action,
          indication: json.indication,
          interaction: json.interaction,
          onset: json.onset,
          duration: json.duration,

          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,

          status: json.status,
          activeID: json.activeID,
          version: json.version,

          rxNormCode: json.rxNormCode,
          nemsisRoutes: removeTypename(json.nemsisRoutes),
          minDose: json.minDose,
          maxDose: json.maxDose,
          metaData: removeTypename(json.metaData),
        })
      );
    }

    let infusItem = new MedicationItem(d);

    /* Validate the protocols point to the infusion --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        let infusID =
          infusItem.status === ProgressStatus.ACTIVE ||
          (infusItem.status === ProgressStatus.DRAFT &&
            infusItem.activeID == null)
            ? infusItem.uid
            : infusItem.activeID;
        if (infusID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The infusion does not have a proper version ID ' +
              infusItem.uid +
              ' ' +
              infusItem.activeID +
              ' ' +
              infusItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          infusID,
          json.modifiedBy,
          'Infusion'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: infusItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING INFUSION:', e, infusion);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the infusions that are associated with the protocol
 * @param protocol The protocol to delete the medication from
 * @param modifiedBy The user that modified the medication
 * @returns Success: data is the number of infusions deleted or Failure if there was an error
 */
export const deleteInfusionsForProtocol = async (
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      protocol.status === ProgressStatus.ACTIVE ||
      (protocol.status === ProgressStatus.DRAFT && protocol.activeID == null)
        ? protocol.uid
        : protocol.activeID;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          protocol.uid +
          ' ' +
          protocol.activeID +
          ' ' +
          protocol.status,
      };
    }

    /* Get all the Medications that the department has */
    let infusionList = await DataStore.query(Drip, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
        ]),
      ])
    );

    let infusions: MedicationItem[] = [];
    for (let i = 0; i < infusionList.length; i++) {
      infusions.push(new MedicationItem(infusionList[i]));
    }

    let n = 0;
    for (let i = 0; i < infusions.length; i++) {
      let infus = infusions[i];

      let protocolOptions = infus.protocolOptions;

      let medProtocol: MedicationProtocol | undefined = undefined;
      for (let i = 0; i < protocolOptions.length; i++) {
        let po = protocolOptions[i];
        if (po.protocolID === protID) {
          medProtocol = po;
          break;
        }
      }
      /* If there is no medication protocol then there is a problem */
      if (!medProtocol) continue;

      /* Remove the medication protocol from the medication */
      let newOptions = protocolOptions.filter(
        (p) => p.protocolID !== (medProtocol as MedicationProtocol).protocolID
      );

      let newInfus = cloneMedication(infus);
      newInfus.protocolOptions = newOptions;
      newInfus.status = ProgressStatus.DRAFT;
      newInfus.activeID =
        infus.status === ProgressStatus.ACTIVE ? infus.uid : infus.activeID;
      newInfus.version = upgradeVersion(
        infus.version == null ? 'v1.0.0' : infus.version
      );
      newInfus.modifiedBy = modifiedBy;

      //Increment the count modified
      n++;

      createParentInfusion(newInfus, infus)
        .then((result) => {
          if (result.type === ResponseType.Failure) {
            console.error('ERROR DELETING INFUSION:', result.data);
          }
          console.log('DELETED PROTOCOL REFERENCE -> MED', infus.name, protID);
        })
        .catch((e) => {
          console.error('ERROR DELETING INFUSION:', e);
        });
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Duplicate the infusion doses from the one protocol to another protocol
 * @param protocol The new protocol that has just been created
 * @param previousProtocol The protocol that was duplicated
 * @returns An array of the infusions that were duplicated
 */
export const duplicateInfusionDoses = async (
  protocol: ProtocolItem,
  previousProtocol: ProtocolItem
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      previousProtocol.status === ProgressStatus.ACTIVE ||
      (previousProtocol.status === ProgressStatus.DRAFT &&
        previousProtocol.activeID == null)
        ? previousProtocol.uid
        : previousProtocol.activeID;
    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The previous protocol does not have a proper version ID ' +
          previousProtocol.uid +
          ' ' +
          previousProtocol.activeID +
          ' ' +
          previousProtocol.status,
      };
    }

    /* Loop through the infusions and create a new MedicationProtocol */
    let infusions: MedicationItem[] = [];
    let completed: MedicationItem[] = [];
    for (let i = 0; i < previousProtocol.infusions.length; i++) {
      if (previousProtocol.infusions[i].parentMedication == null) continue;
      const infus: MedicationItem =
        previousProtocol.infusions[i].parentMedication;

      /* Type check the infusion */
      if (infus.dbDrip == null) {
        console.error('ERROR: The infusion does not have a database object');
        continue;
      }

      /* Check if the infusion is already in the list */
      if (completed.find((m) => m.uid === infus.uid) == null) {
        /* Find the MedicationProtocol for the previous protocol */
        const mp = infus.dbDrip.dripOptions.find((p) => {
          return p.protocolID === protID;
        });

        if (mp == null) {
          console.error('ERROR: The MedicationProtocol does not exist');
          continue;
        }

        /* Duplicate the MedicationProtocol */
        let newID =
          protocol.status === ProgressStatus.ACTIVE ||
          (protocol.status === ProgressStatus.DRAFT &&
            protocol.activeID == null)
            ? protocol.uid
            : protocol.activeID;
        if (newID == null) {
          console.error('ERROR: The new protocol does not have a proper ID');
          continue;
        }
        let newInfusProtocol = new MedicationProtocol({
          protocolID: newID,
          options: mp.options.map((o) => {
            return new MedicationRange({
              basis: o.basis,
              rangeLow: o.rangeLow,
              rangeHigh: o.rangeHigh,
              route: o.route,
              title: o.title,
              warning: o.warning,
              instruction: o.instruction,
              note: o.note,
              maxDose: o.maxDose,
              minDose: o.minDose,
              calcMax: o.calcMax,
              calcMin: o.calcMin,
              index: o.index,
            });
          }),
        });

        /* Update the infusion with the new MedicationProtocol */
        let options = [...infus.protocolOptions, newInfusProtocol];
        let newInfus = cloneMedication(infus);
        newInfus.protocolOptions = options;
        newInfus.status = ProgressStatus.DRAFT;
        newInfus.activeID =
          infus.status === ProgressStatus.ACTIVE ? infus.uid : infus.activeID;
        newInfus.version = upgradeVersion(
          infus.version == null ? 'v1.0.0' : infus.version
        );
        newInfus.modifiedBy = protocol.modifiedBy;

        let results = await createParentInfusion(newInfus, infus);
        if (results.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: results.data,
          };
        }
        completed.push(infus);
        infusions.push(results.data as MedicationItem);
      }
    }

    if (globals.debug)
      if (globals.debug)
        console.log('Successfully duplicated infusions:', infusions);
    return {
      type: ResponseType.Success,
      data: infusions,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if the protocol is already pointing at the infusion
 *    - Otherwise add the infusion to the protocol and create a new protocol draft
 * @param infusion The infusion o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the infusion
 * @param modifiedBy The user that modified the protocol
 * @returns Success if the infusion is paired with the protocol and returns a MedicationProtocol or Failure if there was an error
 */
export const validateInfusionProtocolPairing = async (
  infusion: MedicationItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    if (infusion.dbDrip == null) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion does not have a database object could be an infusion item',
      };
    }
    let infusionProtocol: MedicationProtocol;
    let infusID =
      infusion.status === ProgressStatus.ACTIVE ||
      (infusion.status === ProgressStatus.DRAFT && infusion.activeID == null)
        ? infusion.uid
        : infusion.activeID;
    let pairedID = protocol.model.dripIDs?.find((id) => id === infusID);

    if (infusID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The infusion does not have a proper version ID ' +
          infusion.uid +
          ' ' +
          infusion.activeID +
          ' ' +
          infusion.status,
      };
    }

    /* Create a new protocol draft if the infusion is not paired with the protocol */
    if (pairedID == null) {
      let infusIDs = protocol.model.dripIDs ? protocol.model.dripIDs : [];
      infusIDs.push(infusID);

      let draftProtocol: ProtocolDB = {
        departmentID: protocol.parent.depID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDeps: protocol.pairedDeps,
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: protocol.medications.map((m) => m.uid),
        infusions: infusIDs,
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: protocol.electrical.map((e) => e.parentElectrical.uid),

        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        keychainID: protocol.keychainID,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      infusionProtocol = new MedicationProtocol({
        protocolID: protocol.uid,
        options: [],
      });
    } else {
      /* Find the infusion protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let mo = infusion.dbDrip.dripOptions.find((p) => p.protocolID === protID);
      if (mo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion protocol does not exist',
        };
      }
      infusionProtocol = mo;
    }

    /* Otherwise we just need to update the MedicationProtocol */
    return {
      type: ResponseType.Success,
      data: infusionProtocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Reorder the infusion dose inside of the protocol
 * @param infusion The parent infusion to reorder the infusion dose
 * @param protocol The protocol to reorder the infusion dose in
 * @param infusionDoses The infusion doses to reorder
 * @param modifiedBy The user that modified the protocol
 * @returns Success if the infusion dose was reordered or Failure if there was an error
 */
export const reorderInfusionDose = async (
  infusion: MedicationItem,
  protocol: ProtocolItem,
  infusionDoses: MedicationSubItem[],
  modifiedBy: User
): Promise<Response> => {
  try {
    /* First check if the infusion and protocol are already paired together */
    let validateResult: Response = await validateInfusionProtocolPairing(
      infusion,
      protocol,
      modifiedBy
    );
    if (validateResult.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: validateResult.data,
      };
    }

    let medProtocol: MedicationProtocol =
      validateResult.data as MedicationProtocol;

    /* Create the infusion dose */
    let newInfusRanges = infusionDoses.map((m) => {
      let range = m.range;
      let medRange = new MedicationRange({
        basis: range.basis,
        rangeLow: range.rangeLow,
        rangeHigh: range.rangeHigh,
        route: range.route,
        title: range.title,
        warning: range.warning,
        instruction: range.instruction,
        note: range.note,
        maxDose: range.maxDose,
        minDose: range.minDose,
        calcMax: range.calcMax,
        calcMin: range.calcMin,
        index: m.index,
      });
      return medRange;
    });

    /* Check if we are updating a previous dose and filter out the previous dose otherwise add the new range */
    let infusionRanges = [...medProtocol.options];
    for (let i = 0; i < infusionDoses.length; i++) {
      let range = infusionDoses[i].range;
      infusionRanges = infusionRanges.filter(
        (m) => !equalsInfusionRange(m, range)
      );
    }

    let newInfusProtocol = new MedicationProtocol({
      protocolID: medProtocol.protocolID,
      options: [...infusionRanges, ...newInfusRanges],
    });

    let options = [...infusion.protocolOptions];

    options = options.filter((p) => p.protocolID !== medProtocol.protocolID);
    options.push(newInfusProtocol);

    /* Update the parent infusion with the new MedicationProtocol */
    let newInfus = cloneMedication(infusion);
    newInfus.protocolOptions = options;
    newInfus.version = upgradeVersion(
      infusion.version == null ? 'v1.0.0' : infusion.version
    );
    newInfus.activeID =
      infusion.status === ProgressStatus.ACTIVE
        ? infusion.uid
        : infusion.activeID;
    newInfus.status = ProgressStatus.DRAFT;

    let results = await createParentInfusion(newInfus, infusion);

    if (results.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: results.data,
      };
    }
    let newInfusion = results.data as MedicationItem;

    return {
      type: ResponseType.Success,
      data: newInfusion,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Check if two MedicationRanges are equal
 * @param a MedicationRange to compare
 * @param b MedicationRange to compare
 * @returns if the infusion ranges are equal
 */
export const equalsInfusionRange = (
  a: MedicationRange,
  b: MedicationRange
): boolean => {
  let isRouteSame =
    a.route.length === b.route.length &&
    a.route.every((value, index) => value === b.route[index]);
  return (
    isRouteSame &&
    a.basis === b.basis &&
    a.rangeLow === b.rangeLow &&
    a.rangeHigh === b.rangeHigh &&
    a.title === b.title &&
    a.warning === b.warning &&
    a.instruction === b.instruction &&
    a.note === b.note &&
    a.maxDose === b.maxDose &&
    a.minDose === b.minDose &&
    a.calcMax === b.calcMax &&
    a.calcMin === b.calcMin &&
    a.index === b.index
  );
};

/**
 * This function will publish all the infusion drafts in the database
 * @param department The department to publish the infusion drafts
 * @returns Success if the drafts were published or Failure if there was an error
 */
export const publishInfusionDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftInfusions = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftInfusions.length; i++) {
      let med: Drip = draftInfusions[i];
      let response: Response = await publishInfusion(med);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: med,
          message: `Published Folder: ${med.name}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the infusion to the database
 *    1. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
 *    2. Update the ACTIVE infusion with the new information
 *    3. Delete the DRAFT infusion
 * @param draftInfusionItem The infusion to publish
 */
export const publishInfusion = async (
  draftInfusionItem: Drip
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the infusion is configured correctly as a draft version */
    if (draftInfusionItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The infusion is not a draft version',
      };
    }

    let activeInfusion: Drip;

    /* Use case 1: Creating the FIRST active version */
    if (draftInfusionItem.activeID == null) {
      /* Update the draft infusion to be active */
      activeInfusion = await DataStore.save(
        Drip.copyOf(draftInfusionItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active infusion item */
      let id: string = draftInfusionItem.activeID;
      let curInfusion = await DataStore.query(Drip, id);

      /* Base Case 3 -- check if the active infusion exists */
      if (curInfusion == null) {
        console.error('Couldn not find active item:', draftInfusionItem);
        return {
          type: ResponseType.Failure,
          data: 'The active infusion does not exist: ' + draftInfusionItem,
        };
      }

      let newArcheivedCategory = new MedicationItem(curInfusion);
      newArcheivedCategory.status = ProgressStatus.ARCHIVE;
      newArcheivedCategory.activeID = curInfusion.id;

      // 2. Create a new ARCHEIVED infusion based on the current ACTIVE infusion
      let archiveResult: Response =
        await createParentInfusion(newArcheivedCategory);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedCategory = archiveResult.data as MedicationItem;

      // 2. Update the ACTIVE infusion with the new information
      activeInfusion = await DataStore.save(
        Drip.copyOf(curInfusion, (updated) => {
          updated.name = draftInfusionItem.name;
          updated.concentration = draftInfusionItem.concentration;
          updated.rangeLow = draftInfusionItem.rangeLow;
          updated.rangeHigh = draftInfusionItem.rangeHigh;
          updated.route = draftInfusionItem.route;
          updated.dripOptions = draftInfusionItem.dripOptions;

          updated.contraindication = draftInfusionItem.contraindication;
          updated.note = draftInfusionItem.note;
          updated.warning = draftInfusionItem.warning;
          updated.medClass = draftInfusionItem.medClass;
          updated.action = draftInfusionItem.action;
          updated.indication = draftInfusionItem.indication;
          updated.interaction = draftInfusionItem.interaction;
          updated.onset = draftInfusionItem.onset;
          updated.duration = draftInfusionItem.duration;

          updated.createdBy = draftInfusionItem.createdBy;
          updated.modifiedBy = draftInfusionItem.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftInfusionItem.version;
        })
      );

      // 3. Delete the DRAFT infusion
      let draftInfusion = await DataStore.delete(Drip, draftInfusionItem.id);
      if (draftInfusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft infusion does not exist',
        };
      }
    }

    let infusItem = new MedicationItem(activeInfusion);
    return {
      type: ResponseType.Success,
      data: infusItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will update the infusion in the database
 * @param infusionItem The infusion to delete from the database
 * @param isSoft True if the infusion should be soft deleted, false if it should be hard deleted
 * @returns Success if the infusion was deleted or Failure if there was an error
 */
export const deleteInfusion = async (
  infusionItem: MedicationItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = infusionItem.uid;
    if (isSoft) {
      let infusion = await DataStore.query(Drip, id);
      if (infusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }

      let result = await DataStore.save(
        Drip.copyOf(infusion, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion did not update correctly',
        };
      }
    } else {
      let infusion = await DataStore.delete(Drip, id);
      if (infusion == null) {
        return {
          type: ResponseType.Failure,
          data: 'The infusion does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: infusionItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will create a new infusion draft in the database
 * @param department The department to check for infusion drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isInfusionDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get all the infusion drafts from the database
 * @param department The department to get the infusion drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getInfusionDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Drip, (c) =>
      c.and((c) => [
        // c.or((c) => [
        c.status.eq('DRAFT'),
        // c.status.eq("DELETED"),
        // ]),
        c.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if(model.status === "DELETED")
      // message = `Deleted Infusion: ${model.name}`;
      if (model.activeID == null) message = `Created Infusion: ${model.name}`;
      else message = `Updated Infusion: ${model.name}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Remove all the infusion drafts from the database - User choose to override the drafts
 * @param department The department to remove the drafts from
 * @returns Success if the drafts were removed or Failure if there was an error
 */
export const removeCurrentInfusionDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftInfusions = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftInfusions.length; i++) {
      let med: Drip = draftInfusions[i];
      await DataStore.delete(med);
      updates.push({
        model: med,
        message: `Removed Drip Draft for: ${med.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getInfusionByID = async (
  db: DatabaseResponse,
  id: string
): Promise<MedicationItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = db.infusions.find((c) => c.uid === id);
      if (dbMed != null) return resolve(dbMed);
      else {
        executeQuery(graphqlOperation(getDrip, { id: id }), 1500)
          .then((data) => {
            if (data.data.getDrip == null) {
              resolve(null);
            } else {
              let drip = data.data.getDrip as Drip;
              let infusion = new MedicationItem(drip);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(infusion);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
