import { useState } from 'react';
import { Auth } from 'aws-amplify';
import './Login.css';

import { CgProfile } from 'react-icons/cg';
import { MdPassword } from 'react-icons/md';

import Loading from '../../components/Loading/Loading';
import { globals } from '../../_global/common/Utils';

function ConfirmEmail(props) {
  const { onChange, state, setState, onSuccess, handleClose } = props;
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');

  const confirmSignUp = async (event) => {
    event.preventDefault();
    if (!loading) {
      const { username, given_name, family_name, authCode, password, role } =
        state;
      try {
        setLoading(true);
        await Auth.confirmSignUp(username, authCode, { 'custom:role': role });
        if(globals.debug) console.log('Successfully confirmed ', given_name, ' ', family_name);
        await Auth.signIn(username, password);
        let userinfo = await Auth.currentAuthenticatedUser();
        setLoading(false);
        onSuccess({ user: userinfo, isSignedIn: true });
        handleClose();
      } catch (err) {
        if (err.toString().includes('user is not authenticated'))
          setState((state) => ({ ...state, step: 2 }));
        if(globals.debug) console.log('Error: error in confirming the user signup ', err);
        setState((state) => ({ ...state, error: err }));
        setLoading(false);
      }
    }
  };
  const resendConfirmCode = async (event) => {
    if(globals.debug) console.log('Resending confirmation code to ' + state.username);
    try {
      await Auth.resendSignUp(state.username);
      if(globals.debug) console.log('Successfully resent the confirmation code');
    } catch (err) {
      if(globals.debug) console.log('ERROR: could not resend confirmation code');
      setState((state) => ({ ...state, error: err.toString() }));
    }
  };
  const validateForm = (type) => {
    if (type === 'auth')
      return (
        state.username.length > 0 && state.authCode.length > 0
      ).toString();
    else if (type === 'signup')
      return (
        state.email.length > 0 &&
        state.given_name.length > 0 &&
        state.family_name.length > 0 &&
        state.role.length > 0
      ).toString();
    return (state.username.length > 0 && state.password.length > 0).toString();
  };
  return (
    <div>
      <div>
        <form onSubmit={confirmSignUp}>
          <div className="inputContainer" size="lg" controlId="username">
            <CgProfile size={30} />
            <label className="text-input">{state.username}</label>
          </div>
          <div className="inputContainer" size="lg" controlId="password">
            <MdPassword size={30} />
            <input
              className="text-input"
              autoFocus
              placeholder="Authentication Code"
              type="string"
              name="authCode"
              onChange={onChange}
            />
          </div>
          <button
            className="login-btn"
            block
            size="lg"
            type="submit"
            disabled={!validateForm()}
          >
            Confirm Account
          </button>
        </form>
        <button
          className="reset"
          block
          size="lg"
          type="button"
          onClick={() => {
            resendConfirmCode();
          }}
        >
          Resend Code
        </button>
      </div>
      {loading && <Loading type="bubbles" />}
    </div>
  );
}
export default ConfirmEmail;
