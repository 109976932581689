import { TbPlugConnectedX } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import './Component404.scss'; // Importing CSS
import image from '../../../assets/images/hinckleymedical_logo.png'; // Importing image
import { doc } from 'prettier';

const Component404 = (props: any) => {
  const navigate = useNavigate();
  return (
    <div className="container-404">
      <div className="left-container-404">
        <img
          style={{
            height: '75px',
          }}
          src={image}
          alt="Hinckley Medical Logo"
        />
        <div className="container-background-404">
          <div className="icon404Container">
            <TbPlugConnectedX className="icon404" />
          </div>
          <div className="text404Container">
            <h6 className="text404">404</h6>
            <h6 className="text404SubTitle">Page Not Found</h6>
            <p className="text404Text">
              The page you are looking for might have been removed, had its name
              changed, or is temporarily unavailable.
            </p>
            <button
              className="backButton404"
              onClick={() => {
                document.title = '404 | OneDose';
                navigate('/');
              }}
            >
              Go Home
            </button>
          </div>
          {/* <h6 className="categoryText">
              There was an error validating this URL. Please contact your
              administrator.
            </h6> */}
        </div>
      </div>
      <div className="background-404"></div>
    </div>
  );
};
export default Component404;
