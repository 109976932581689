import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { BiFullscreen } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddProtocolSideout from '../../../../components/SideOut/AddProtocolSideout';
import ProtocolEditHeader from '../../ProtocolHeader';
import PDFScreen from '../../details/PdfScreen';
import FolderProtocolModal from '../../../../components/Modal/FolderProtocolModal';

/* 10-12-23 Praveen: Created Landing page for Protocol Selection Create/Edit  */
const ProtocolLandingPage = (props: any) => {
  const navigate = useNavigate();
  const [isOpenSideOut, setIsOpenSideOut] = useState(false);
  const editProtocol = useSelector(
    (state: any) => state?.protocol?.editProtocol
  );
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [pdfurl, setPdfUrl] = useState('');

  // Todo: Added Static PDF File
  const samplePdf = 'https://www.africau.edu/images/default/sample.pdf';

  useEffect(() => {
    setPdfUrl(samplePdf);
  }, []);

  const handleVersion = () => {
    // Todo for Version handling
  };

  const handleBack = () => {
    navigate(`/protocol/edit`);
  };

  const openPDF = () => {
    window.open(samplePdf, '_blank');
  };

  /* 10-16-23 Arul: function for handling upload pdf  */
  const handleUploadpdf = (data: any) => {
    let url;
    if (data?.pdf.name) {
      url = URL?.createObjectURL(data?.pdf);
    }
    setPdfUrl(data?.pdf?.name ? url : data.pdf);
    setIsPdfModal(false);
  };

  return (
    <div className="screen-container">
      {isPdfModal && (
        <FolderProtocolModal
          isVisible={isPdfModal}
          title={editProtocol?.name}
          isPdf={true}
          isEdit={true}
          handleClose={() => {
            setIsPdfModal(false);
          }}
          handleAdd={handleUploadpdf}
          pdfurl={pdfurl}
        />
      )}
      <AddProtocolSideout
        isVisible={isOpenSideOut}
        isEditMode={true}
        title={'modalName'}
        handleClose={() => {
          setIsOpenSideOut(false);
        }}
      />
      <div className="fixedHeader">
        <ProtocolEditHeader
          name={editProtocol?.name}
          isVersionButton={true}
          isUploadButton={true}
          isAddButton={true}
          type={'protocol'}
          handleUpload={() => {
            setIsPdfModal(!isPdfModal);
          }}
          handleAdd={() => {
            setIsOpenSideOut(true);
          }}
          handleCancel={handleBack}
          handleVersion={handleVersion}
        />
      </div>
      <div>
        <div className="buttonContainer colAlignEnd fullScreenPdfBtn">
          <Button
            className="fullScreenBtn primary-button btn-rightMargin"
            onClick={() => openPDF()}
          >
            <span>
              <BiFullscreen className="view_pdf_icon" />
            </span>
            Full Screen
          </Button>
        </div>
        <PDFScreen pdf={pdfurl} />
      </div>
    </div>
  );
};

export default ProtocolLandingPage;
