import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';

type AddRouteModalProps = {
  dialogVisible: boolean;
  onNewRouteCancel: () => void;
  handleSet: (route: string) => void;
  headerText?: string;
};

/* 10-19-23 Arul: Created the common componnet for New Route popup */
const AddRouteModal: React.FC<AddRouteModalProps> = ({
  dialogVisible,
  headerText,
  onNewRouteCancel,
  handleSet,
}) => {
  const [routeValue, setRouteValue] = useState('');

  const handleSubmit = () => {
    handleSet(routeValue);
    setRouteValue('');
  };

  return (
    <Dialog
      header={headerText ? headerText : 'New ADministration Route'}
      visible={dialogVisible}
      style={{ width: '40vw', maxWidth: '600px' }}
      onHide={onNewRouteCancel}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            <h6 className="headerText">Enter in the administration route</h6>
            <span className="contentText">
              Enter in the administration route into the box. It will display
              EXACTYLY as entered to, so double check.{' '}
            </span>
          </div>
          <div className="dialogContent">
            <div className="p-inputgroup flex-1 calcualtionDiv">
              <div className="p-inputgroup-addon-left dialog_Item_label">
                <span style={{ display: 'flex' }}>
                  Route:{' '}
                  <span
                    className="required-field"
                    style={{ marginBottom: '5px' }}
                  >
                    *
                  </span>
                </span>
              </div>
              <div className="input-container full-width">
                <InputText
                  placeholder=""
                  data-testid="routeData"
                  autoFocus={true}
                  value={routeValue}
                  onChange={(e: any) => {
                    setRouteValue(e.target.value);
                  }}
                  className="dialogInput"
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit();
                  }}
                />
                <div className="dialogInput-border"></div>
              </div>
            </div>
            {/* <div className="p-inputgroup flex-1">
                            <span className="p-inputgroup-addon-left">
                                <span style={{display:'flex'}}>Route: <span className="required-field" style={{ marginBottom: '5px' }}>*</span></span>
                            </span>
                            <div className="input-container full-width">
                                <InputText 
                                    placeholder="" 
                                    data-testid="routeData" 
                                    value={routeValue} 
                                    onChange={(e:any)=>{setRouteValue(e.target.value)}} 
                                    className='dialogInput calculationInput'
                                    />
                                <div className="dialogInput-border"></div>
                            </div>
                        </div> */}
          </div>
          <div className="dialogButtons">
            <Button
              data-testid="dismissBttn"
              className="secondary-button-dialog btn-rightMargin"
              onClick={() => {
                onNewRouteCancel();
                setRouteValue('');
              }}
            >
              {' '}
              Cancel
            </Button>
            <Button
              data-testid="setBttn"
              className="primary-button"
              onClick={handleSubmit}
              disabled={!routeValue}
            >
              Set
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddRouteModal;
