import React, { useState, useEffect } from 'react';
import './ResizeableDiv.scss';

interface ResizableDivsProps {
  views: JSX.Element[];
  initialWidths?: number[];
  containerClassname?: string;
  panelClassname?: string;
  minWidth?: string;
  dividerColor?: string;
}

const ResizableDivs: React.FC<ResizableDivsProps> = ({
  views,
  containerClassname,
  panelClassname,
  minWidth,
  initialWidths,
  dividerColor,
}) => {
  const _initialWidths = initialWidths || views.map(() => 100 / views.length);
  const [widths, setWidths] = useState(_initialWidths);

  //   useEffect(() => {
  //     // This is a cleanup function that removes the event listeners when the component unmounts
  //     return () => {
  //       window.removeEventListener('mousemove', handleMouseMove);
  //       window.removeEventListener('mouseup', handleMouseUp);
  //     };
  //   }, []); // Empty dependency array means this effect runs once on mount and once on unmount

  const handleResize = (
    index: number,
    mouseDownEvent: React.MouseEvent<HTMLDivElement>
  ) => {
    mouseDownEvent.preventDefault();
    const startX = mouseDownEvent.clientX;
    const startWidths = [...widths];

    const handleMouseMove = (mouseMoveEvent: MouseEvent) => {
      if (mouseMoveEvent.view === null) return;
      const dx = (mouseMoveEvent.clientX - startX) * 2.5;
      const totalWidth = mouseMoveEvent.view.innerWidth;
      const deltaPercentage = (dx / totalWidth) * 100;

      const newWidths = [...startWidths];
      if (
        newWidths[index] + deltaPercentage > 0 &&
        newWidths[index + 1] - deltaPercentage > 0
      ) {
        newWidths[index] += deltaPercentage;
        newWidths[index + 1] -= deltaPercentage;
        setWidths(newWidths);
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (widths.length !== _initialWidths.length) setWidths(_initialWidths);
  }, [views, _initialWidths]);

  return (
    <div className={containerClassname}>
      {views.map((view, index) => (
        <React.Fragment key={index}>
          <div
            className={`resizeable-panel ${panelClassname}`}
            style={{
              width: `${widths[index]}%`,
              minWidth: minWidth ? minWidth : '10%',
            }}
          >
            {view}
          </div>
          {index < views.length - 1 && (
            <div
              className="resizeable-divider"
              style={{
                backgroundColor: dividerColor ? dividerColor : '#333',
              }}
              onMouseDown={(e) => handleResize(index, e)}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ResizableDivs;
