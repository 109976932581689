import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate } from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { Equipment, ProgressStatus, User } from '../../../../models';
import SearchBar from '../../../components/Search/SearchBar';
import { DatabaseResponse } from '../../../../data/AmplifyDB';

const ListArchiveEquipment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: equipment, department, database } = state;
  const db: DatabaseResponse = database;

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filteredEquipment, setFilteredEquipment] = useState(equipment);
  const [modifiedBy, setModifiedBy] = useState<User | undefined>();

  useEffect(() => {
    const filtered = equipment.filter(
      (equipment: any) =>
        equipment.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (equipment.modifiedBy &&
          equipment.modifiedBy
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
    );
    setFilteredEquipment(filtered);
  }, [searchQuery, equipment]);

  const handleItemClick = (elec: Equipment) => {
    // const state = {
    //   selectedProtocol: null,
    //   value: elec,
    //   subValue: null,
    //   type: 'Equipment',
    //   editType: 'edit',
    //   editMode: false,
    //   page: 'listEquipmentPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
  };

  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 2fr 2fr 1fr',
  };

  const handleBack = () => {
    navigate(`/archive`, { state: department });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={'Equipment: ' + filteredEquipment.length + ' items'}
        status={ProgressStatus.ARCHIVE}
        page={department.name}
        type={'protocol'}
      />
      <Row>
        <Col sm={12}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Equipment...'}
          />
        </Col>
      </Row>
      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6 className="departmentItemText">Version</h6>
                <h6 className="departmentItemText">Name</h6>
                <h6 className="departmentItemText">Archived Date</h6>
                <h6 className="departmentItemText">Archived By</h6>
                <h6 className="departmentItemText">Sizes</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={filteredEquipment}>
            {(item: Equipment, index) => {
              let id = item.modifiedBy;
              if (id == null) id = item.createdBy;
              let username = 'Hinckley Medical';
              if (id) {
                let user = db.users.find((user: User) => user.id === id);
                if (user) username = user.firstName + ' ' + user.lastName;
              }
              // let username = item.modifiedBy
              //   ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
              //   : 'Hinckley Medical';
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.version}</h6>
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.updatedAt ? item?.updatedAt : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {item.optionItems.length}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListArchiveEquipment;
