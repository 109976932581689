import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { CgProfile } from 'react-icons/cg';
import { BsFillLockFill } from 'react-icons/bs';

import Loading from '../../components/Loading/Loading';
import ForgotPassword from './ForgotPassword';
import ConfirmEmail from './ConfirmEmail';
import {
  handleLogin,
  handleUpdateCognitoUser,
  handleUpdateUser,
} from '../../../store/actions';
import './Login.css';
import { globals } from '../../_global/common/Utils';

function Login(props) {
  const dispatch = useDispatch();
  const { onSuccess, handleClose } = props;
  //const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [state, setState] = useState({
    username: '',
    email: '',
    password: '',
    password2: '',
    // given_name: "",
    // family_name: "",
    // role: "Admin",
    // dept: "HM",
    authCode: '',
    recaptcha: 0,
    step: 2,
  });

  // Author: Guruprasad Venkatraman (01-04-2024)
  // Destructured the target into name and value to optimized the entire code.
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));

    // if (name === "username")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "email")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "password")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "password2")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "given_name")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "family_name")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "role")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "authCode")
    //   setState((prevState) => ({ ...prevState, [name]: value }));
    // else if (name === "deptHead")
    //   setState((prevState) => ({ ...prevState, dept: value }));
  };

  // Author: Guruprasad Venkatraman (01-03-2024)
  // Fixed crashing issue by ensuring that the errorMsg is a string and
  // displaying the error message to the user without crashing.

  const confirmLogIn = async (event) => {
    event.preventDefault();
    if (!loading) {
      const { username, password } = state;
      // const username = "developer";
      // const password = "Developer1!";
      try {
        setLoading(true);

        const _user = await Auth.signIn(username, password);
        //dispatch the user details
        let userinfo = await Auth.currentAuthenticatedUser();
        dispatch(handleUpdateCognitoUser(userinfo));

        if (_user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setError('Password reset required');
        }
        setLoading(false);
        handleClose();
      } catch (err) {
        // if(err.toString().includes('Incorrect username or password')) setState(state => ({...state, error: 'Incorrect username or password'}));
        // else if(err.toString().includes('UserNotFoundException')) setState(state => ({...state, error: 'User does not exist'}));
        // else if(err.toString().includes('UserNotConfirmedException')){
        //   setState(state => ({...state, step: 1}));
        //   setState(state => ({...state, error: 'User has not confirmed email'}));
        // }
        if(globals.debug) console.log(err);
        if (err.code) {
          switch (err.code) {
            case 'UserNotConfirmedException':
              setError('Account not confirmed');
              break;
            case 'NotAuthorizedException':
              setError('Incorrect username or password');
              break;
            case 'UserNotFoundException':
              setError('User does not exist');
              break;
            case 'PasswordResetRequiredException':
              setError('Password reset required');
              break;
            default:
              setError('Error signing in');
              break;
          }
        }
        setLoading(false);
        //setState((state) => ({ ...state, password: "" }));
      }
    }
    dispatch(handleLogin);
  };

  const setLoadingStateFromChild = (isLoading) => {
    setLoading(isLoading);
  };
  //As of now not in use so commented

  /* const handleChecked = () => {
    setChecked(!checked);
  };*/

  // Author: Guruprasad Venkatraman (01-04-2024)
  // Modified the forgot passord function to better handle the errors
  // and redirect the users to appropriate pages as needed.
  const forgotPassword = async (event) => {
    if (state.username.length === 0) {
      setError('Username required for Forgot Password');
    } else {
      try {
        setLoading(true);
        await Auth.forgotPassword(state.username);
        setState((prevState) => ({ ...prevState, step: 3 }));
        setMessage('Kindly enter the sent by onedose@hinckleymed.com');
        setError('');
        setLoading(false);
      } catch (err) {
        if(globals.debug) console.log(err);
        if (err.code) {
          switch (err.code) {
            case 'UserNotConfirmedException':
              setError('Account not confirmed');
              break;
            case 'NotAuthorizedException':
              setError('Incorrect username or password');
              break;
            case 'UserNotFoundException':
              setError('User does not exist. Enter a valid username');
              setState((prevState) => ({ ...prevState, step: 4 }));
              break;
            case 'PasswordResetRequiredException':
              setError('Password reset required');
              break;
            default:
              setError('Error signing in');
              break;
          }
        }
        setLoading(false);
      }
    }
  };

  // const switchMenu = (type) => {
  //   if (type === 0 && state.step !== 2) {
  //     setState((state) => ({ ...state, step: 2 }));
  //     setState((state) => ({ ...state, error: "" }));
  //   } else if (type === 1 && state.step !== 0) {
  //     setState((state) => ({ ...state, step: 0 }));
  //     setState((state) => ({ ...state, error: "" }));
  //   }
  // };

  // Author: Guruprasad Venkatraman (01-04-2024)
  // Added the ability to enter just the username in case of a invalid username and
  // also added the logic to show the error and success messages to the user.
  return (
    <div className="login">
      <div className="form">
        {/* <ul className='login-menu'>
            <li><a onClick={() => switchMenu(0)}>Log In</a></li>
            <li><a onClick={() => switchMenu(1)}>Sign Up</a></li>
          </ul> */}
        {error !== '' && <div className="login-error">ERROR: {error}</div>}
        {message !== '' && <div className="login-message">{message}</div>}
        {state.step === 2 && (
          <div>
            <form onSubmit={confirmLogIn}>
              <div className="inputContainer" tabIndex={0}>
                <CgProfile size={30} />
                <input
                  data-testid="usernameTest"
                  className="text-input"
                  autoFocus
                  type="text"
                  name="username"
                  placeholder="Username"
                  // value={this.state.username}
                  onChange={onChange}
                />
              </div>
              <div className="inputContainer" tabIndex={1}>
                <BsFillLockFill size={30} />
                <input
                  data-testid="passwordTest"
                  className="text-input"
                  placeholder="Password"
                  type="password"
                  name="password"
                  // value={this.state.email}
                  onChange={onChange}
                />
              </div>
              <button
                data-testid="loginTest"
                className="login-btn"
                type="submit"
              >
                Log In
              </button>
              <div className="extra-login">
                {/* <input className='checkbox' value={false} type="checkbox" />
                <label className='remember'>Remember Me?</label> */}
                <label
                  className="forgot"
                  onClick={() => {
                    forgotPassword();
                  }}
                >
                  Forgot Password
                </label>
              </div>
            </form>
          </div>
        )}
        {state.step === 0 && <div></div>}
        {state.step === 1 && (
          <ConfirmEmail
            onChange={onChange}
            state={state}
            setState={setState}
            onSuccess={onSuccess}
            handleClose={handleClose}
          />
        )}
        {state.step === 3 && (
          <ForgotPassword
            onChange={onChange}
            state={state}
            setState={setState}
            onSuccess={onSuccess}
            handleClose={handleClose}
            setLoadingStateFromChild={setLoadingStateFromChild}
          />
        )}
        {state.step === 4 && (
          <>
            <div className="inputContainer" size="lg">
              <CgProfile size={30} />
              <input
                data-testid="usernameTest"
                className="text-input"
                autoFocus
                type="username"
                name="username"
                placeholder="Username"
                // value={this.state.username}
                onChange={onChange}
              />
            </div>
            <button
              data-testid="loginTest"
              className="login-btn"
              size="lg"
              onClick={() => {
                forgotPassword();
              }}
            >
              Reset Password
            </button>
            <button
              data-testid="loginTest"
              className="cancel-btn"
              size="lg"
              onClick={() => {
                setState((prevState) => ({ ...prevState, step: 2 }));
              }}
            >
              Cancel
            </button>
          </>
        )}
      </div>
      {loading && <Loading type="bubbles" />}
    </div>
  );
}

export default Login;
