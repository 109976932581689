import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './FullScreenPDF.scss';
import { getHashedPin } from '../_global/common/Encrypt';
import Component404 from '../components/404/Component404';
import Loading from '../components/Loading/Loading';
import { globals } from '../_global/common/Utils';
const FullScreenPDF = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [pdf, setPdf] = useState<any | null>({
    url: null as string | null,
    name: null as string | null,
  });
  const [isValidURL, setIsValidURL] = useState<boolean>();

  const [error, setError] = useState<string>('');

  const fetchPdf = (): any => {
    try {
      const url = localStorage.getItem('pdfURL');
      const urlName = localStorage.getItem('pdfName');
      if (!url) {
        console.log('No URL');
        navigate('/protocol');
        return {
          pdfUrl: null,
          pdfName: null,
        };
      }

      if (url !== pdf.url) {
        document.title = urlName ? urlName : 'OneDose';
        return {
          pdfUrl: url,
          pdfName: urlName,
        };
      }
    } catch (err: any) {
      setError(err.message);
      return {
        pdfUrl: null,
        pdfName: null,
      };
    }
  };

  const validateURL = (url: string): boolean => {
    const split = url.split('/');
    const queryParams = new URLSearchParams(location.search);
    const { pdfUrl, pdfName } = fetchPdf();
    if (
      pdfUrl &&
      pdfName &&
      (split.length === 3 || split.length === 4) &&
      split[1] === 'fullscreen-pdf' &&
      queryParams.get('signature') &&
      queryParams.get('expiration')
    ) {
      const expiration = Number(queryParams.get('expiration') as string);
      const currentTime = new Date().getTime();
      if (expiration < currentTime) return false;

      const signature = queryParams.get('signature') as string;
      let filterName = pdfName.replace(/[^a-zA-Z0-9]/g, '_');
      const hash = getHashedPin(
        filterName,
        pdfUrl + expiration.toLocaleString(),
        'SHA512'
      );
      if (signature === hash) {
        setPdf({
          url: pdfUrl,
          name: pdfName,
        });
        return true;
      }
    }
    return false;
  };

  /** Validate */
  useEffect(() => {
    let valid = validateURL(location.pathname);
    setIsValidURL(valid);
  }, [location.pathname]);

  return (
    <div className="fullscreen-pdf-container">
      {isValidURL === true ? (
        <div className="fullscreen-pdf-container">
          {error && <div className="error">{error}</div>}
          {pdf && (
            <embed
              src={pdf.url}
              title="fullscreen-pdf"
              width="100%"
              height="100%"
            ></embed>
          )}
        </div>
      ) : isValidURL === false ? (
        <Component404 />
      ) : (
        <Loading type="bubbles" />
      )}
    </div>
  );
};

export default FullScreenPDF;
