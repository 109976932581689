import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { HiPlus } from 'react-icons/hi';
import { useSelector } from 'react-redux';

type RouteDialogProps = {
  visible: boolean;
  handleSet: (selectedOption: string[]) => void;
  handleNewRoute: () => void;
  onClose: () => void;

  availableRoutes: string[];
  selectedRoutes: string[];

  headerText?: string;
  // InputLabel?: string;
  // headerTitle?: string;
};

/* 10-20-23 Praveen: Created the popup globally for Update Route related changes */
const RouteDialog: React.FC<RouteDialogProps> = ({
  visible,
  handleSet,
  handleNewRoute,
  onClose,
  availableRoutes,
  selectedRoutes,
  headerText,
}) => {
  const [allRoutes, setAllRoutes] = useState<string[]>(
    availableRoutes ? availableRoutes : []
  );
  const [selectedOption, setSelectedOption] = useState<string[]>(
    selectedRoutes ? selectedRoutes : []
  );

  // const route = useMemo(() => {
  //     if (routeItem?.length > 0 || routeList?.length > 0) {
  //         if (routeItem?.length > 0) {
  //             return [...new Set([...routeItem, ...routeList])]
  //         } else {
  //             return [...new Set([...routeList])]
  //         }
  //     } else {
  //         return [];
  //     }

  // }, [routeList, routeItem]);

  // useEffect(() => {
  //     if (data && data.length > 0) {
  //         setSelectedOption(data)
  //     }else{
  //         setSelectedOption([])
  //     }
  // }, [data, setSelectedOption, routeList]);

  /*10/17/2023 handles select and unselect behaviour of the  checkbox */
  const handleOptionChange = (e: any) => {
    const { value } = e.target;
    if (selectedOption.includes(value)) {
      setSelectedOption(selectedOption.filter((option) => option !== value));
    } else {
      setSelectedOption([...selectedOption, value]);
    }
  };

  const handleSubmit = () => {
    handleSet(selectedOption);
  };

  return (
    <Dialog
      header={headerText ? headerText : 'Administration Routes'}
      visible={visible}
      style={{ width: '40vw', maxWidth: '650px' }}
      className="routeModal"
      onHide={onClose}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px 0px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginBottom: '5px',
              }}
            >
              <h6 className="headerText">Available Routes</h6>
              <span className="contentText">
                <AiOutlineQuestionCircle className="icon-normal" />
              </span>
            </div>
            <span className="contentText">
              Select the route that are available for the provider to use for
              this medication dose
            </span>
          </div>
          {allRoutes.length > 0 && (
            <div style={{ height: '200px', overflowY: 'auto' }}>
              {allRoutes?.map((option: any, index: any) => (
                <label
                  key={index}
                  className={
                    selectedOption?.includes(option)
                      ? 'selectedCheckbox'
                      : 'checkbox-container'
                  }
                >
                  <Checkbox
                    inputId={option}
                    name="options"
                    value={option}
                    onChange={handleOptionChange}
                    checked={selectedOption.includes(option)}
                    style={{ marginRight: '8px' }}
                    className="routeCheckbox"
                  />
                  {option}
                </label>
              ))}
            </div>
          )}
          <div
            onClick={handleNewRoute}
            className="cursorPointer contentBorder protocolCalculationPad contentHeading newProtocolBorder newRouteButton"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span>
              <HiPlus className="text-icon " /> Add New Route
            </span>
          </div>
          <div className="dialogButtons">
            <Button
              className="secondary-button-dialog btn-rightMargin"
              onClick={onClose}
            >
              {' '}
              Cancel
            </Button>
            <Button className="primary-button" onClick={handleSubmit}>
              Set
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RouteDialog;
