/* 09-29-23 Arul: Created PdfScreen component  for Protocol detail page*/
const PublicPDFViewer = (props: any) => {
  return (
    // <div className="pdfContainer">
    <embed
      src={props.pdf}
      title="PDF Viewer"
      width="100%"
      height="100%"
      onError={(e) => {
        console.log(e);
      }}
      onErrorCapture={(e) => {
        console.log(e);
      }}
    ></embed>
    // </div>s
  );
};

export default PublicPDFViewer;
