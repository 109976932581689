import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { getFormattedDate } from '../../../_global/common/Utils';
import { detectedChangesMockData } from '../../../_global/constants/Protocol_constants';
import ProtocolHeader from '../../protocol/ProtocolHeader';

interface SnapshotViewPageProps {
  stateData?: any;
}

/* 11-28-23 Arul Created Snapshot View Page component for Snapshot layout */
const SnapshotViewPage: React.FC<SnapshotViewPageProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { date, user, message, title, name } = location.state;

  /*11/28/2023 Function to handle cancel in header*/
  const handleCancel = () => {
    const data = {
      value: '',
      pdf: '',
    };
    const state = { ...data };
    navigate(`/snapshot`);
  };

  /*11/28/2023, Function to handle create in header*/
  const handleCreateSnapshot = () => {
    //ToDo
  };

  return (
    <div className="screen-container">
      <div className="fixedHeader">
        <ProtocolHeader
          name={name}
          description={getFormattedDate(date, true)}
          type={'protocol'}
          page={'Snapshots'}
          isViewButton={true}
          isDotButton={true}
          handleCancel={handleCancel}
          handleCreate={handleCreateSnapshot}
        />
      </div>
      <div style={{ minHeight: '65vh' }} className="ketamineContent">
        <div className="KetamineGeneral">
          <div className="container">
            <h5 className="ketmine-header-text">Snapshot</h5>
            <div>
              <label htmlFor="Name" className="ketamine-general-label">
                Title
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="name"
                  disabled={true}
                  value={title}
                />
                <div className="input-border"></div>
              </div>
            </div>
            <div>
              <label htmlFor="Name" className="ketamine-general-label">
                Message
              </label>
              <div className="input-container">
                <InputTextarea
                  value={message}
                  placeholder=""
                  className="dialogInput"
                  disabled={true}
                  style={{ height: '140px', verticalAlign: 'top' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <div className="container">
            <div>
              <h5 className="ketmine-header-text">Information</h5>
              <div>
                <label htmlFor="Title" className="ketamine-general-label">
                  General
                </label>
                <div className="input-container roundBorder">
                  <div
                    style={{ display: 'flex', marginTop: '5px' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Created By:</div>
                    <div>{user.name}</div>
                  </div>
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Publish Date:</div>
                    <div>{getFormattedDate(user.publishDate, true)}</div>
                  </div>
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Publish Type:</div>
                    <div>{user.publishType}</div>
                  </div>
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Detected Changes:</div>
                    <div>{user.detectedChange}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="contentLabel">Detected Changes (11)</div>
                <div className="contentBorder protocolCalculationPad reviewListScroll">
                  <ViewportList items={detectedChangesMockData}>
                    {(item: any, index: any) => (
                      <div
                        key={item + index}
                        className={`listhover spaceBetween cursorPointer item contentHeading ${detectedChangesMockData.length !== index + 1 ? 'contentUnderline' : ''}`}
                      >
                        <div>{item?.topic}</div>
                      </div>
                    )}
                  </ViewportList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapshotViewPage;
