import React, { useContext, useEffect, useState } from 'react';

interface SelectionProps<T> {
  selectedIndex: number;
  items: T[];
  onPress: (item: T, index: number) => void;
  labelField: (option: T) => string;
  valueField: (option: T) => string;
}

/* 9-26-23 Hazlett: Created concentration selection item */
function GeneralSelection<T>(props: SelectionProps<T>) {
  const { onPress, selectedIndex, items } = props;

  const [selIndex, setSelectedIndex] = useState<number>(selectedIndex || -1);
  const [data, setData] = useState<any[]>(items || []);

  useEffect(() => {
    setSelectedIndex(selectedIndex);
    setData(items);
  }, [selectedIndex, items]);

  const handleItemSelection = (item: T, index: number) => {
    if (index === selIndex) return;
    setSelectedIndex(index);
    onPress(item, index);
    // dispatch<any>(toggleSelectedWeight({ selectedWeight: index }))
  };

  return (
    <div className="gs-container">
      {data.map((item, index) => (
        <div
          className={`gs-item${index === selIndex ? ' selected' : ''}`}
          key={index}
          onClick={() => handleItemSelection(item, index)}
        >
          <h4 className="gs-item-text">{item}</h4>
        </div>
      ))}
    </div>
  );
}

export default GeneralSelection;
