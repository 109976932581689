import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../data/AmplifyDB';
import { User } from '../../../models';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import { FaBuilding } from 'react-icons/fa6';
import { getSoftwarePackage, globals } from '../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import DepartmentPage from '../actions/details/department/DepartmentPage';
import './Settings.scss';
import EditDepartmentPage from './details/EditDepartmentPage';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  DepartmentJSON,
  updateDepartment,
  uploadFileToS3,
} from '../../../data/functions/DepartmentDB';
import { SoftwareType } from '../../../API';
import { get } from 'lodash';
import DepartmentItem from '../../../data/model/DepartmentItem';
import Loading from '../../components/Loading/Loading';
import { handleGetDepartment } from '../../../store/actions';

const ICON_SIZE = '1.15rem';
const SETTINGS_TABS = [
  {
    name: 'Department',
    icon: (
      <FaBuilding
        style={{ height: ICON_SIZE, width: ICON_SIZE }}
        color="#636363"
      />
    ),
  },
  {
    name: 'Profile',
    icon: (
      <Io5.IoPersonCircle
        style={{ height: ICON_SIZE, width: ICON_SIZE }}
        color="#636363"
      />
    ),
  },
];

const SettingsPage = (props: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const dbState = useSelector((state: any) => state);

  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.protocol.departmentItem
  );
  const parentDepartment: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database]);

  const [department, setDepartment] =
    useState<DepartmentItem>(parentDepartment);
  const user: User = useSelector((state: any) => state?.user);

  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [tab, setTab] = useState('Department');
  const [logo, setLogo] = useState<File | null>(null);
  const [loading, setLoading] = useState<string | null>(null);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const departmentFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: department.name,
      neonateCutoff: department.model.neonateCutoff
        ? department.model.neonateCutoff
        : 5,
      pediatricCutoff: department.model.pediatricCutoff
        ? department.model.pediatricCutoff
        : 40,
      softwarePlan: getSoftwarePackage(department.model.softwarePlan),
      activeStatus: department.model.activeStatus === false ? false : true,
      agencyNumEMS: department.model.agencyNumEMS
        ? department.model.agencyNumEMS
        : '',
      stateIDEMS: department.model.stateIdEMS
        ? department.model.stateIdEMS
        : '',
      gnisCode: department.model.gnisCodeEMS
        ? department.model.gnisCodeEMS
        : '',
      location: department.location,
      departmentCode: department.departmentCode
        ? department.departmentCode
        : '',

      pin: '',
      hashedPin: department.hashedPin ? department.hashedPin : '',
      saltedPin: department.salt ? department.salt : '',

      oneweightEnabled: department.isOneWeightEnabled,
      realTimeEnabled: department.isRealTimeEnabled,
      infusionCalculation: department.infusionCalculation,
      ageFilterEnabled: department.config.ageFilterEnabled,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      neonateCutoff: Yup.number().required(),
      pediatricCutoff: Yup.number().required(),
      softwarePlan: Yup.string().required(),
      activeStatus: Yup.boolean().required(),
      agencyNumEMS: Yup.string(),
      stateIDEMS: Yup.string(),
      gnisCode: Yup.string(),
      location: Yup.string().required(),
      departmentCode: Yup.string().required(),
      pin: Yup.string(),
      hashedPin: Yup.string(),
      saltedPin: Yup.string(),
      oneweightEnabled: Yup.boolean().required(),
      realTimeEnabled: Yup.boolean().required(),
      infusionCalculation: Yup.boolean().required(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    if (tab !== 'Department') {
      departmentFormik.resetForm();
      setLogo(null);
    }
  }, [tab, departmentFormik, logo]);

  const PageUI = useMemo(() => {
    switch (tab) {
      case 'Department':
        return (
          <EditDepartmentPage
            department={department}
            formik={departmentFormik}
            isEditMode={isEditMode}
            logo={logo}
            setLogo={setLogo}
            setDepartment={setDepartment}
            parentDepartment={parentDepartment}
          />
        );
      case 'Profile':
        return <div>Profile</div>;
      default:
        return <div>Department</div>;
    }
  }, [
    tab,
    departmentFormik,
    departmentFormik.values,
    isEditMode,
    logo,
    setLogo,
    department,
    parentDepartment,
    parentDepartment.subDeps,
  ]);

  const isSaveValid = useMemo(() => {
    return (departmentFormik.isValid && departmentFormik.dirty) || logo;
  }, [
    departmentFormik.isValid,
    departmentFormik.dirty,
    departmentFormik,
    departmentFormik.values,
    logo,
  ]);

  const handleSave = () => {
    if (tab === 'Department') handleUpdateDepartment();
  };

  const handleUpdateDepartment = async () => {
    try {
      let logoURI = department.logoURL;
      /* First upload the new logo */
      if (logo) {
        let response: Response = await uploadFileToS3(
          department,
          logo,
          (error: any) => {
            if (error) {
              console.error('error uploading logo: ', error);
            }
          }
        );
        if (response.type === ResponseType.Success) {
          logoURI = response.data;
          department.logoVerifiedUrl = URL.createObjectURL(logo);
          setLogo(null);
        }
        console.log('Successfully put logo into S3: ', logoURI);
      }

      /* Now update the department */
      let updatedDepartment: DepartmentJSON = {
        name: departmentFormik.values.name,
        location: departmentFormik.values.location,
        departmentCode: departmentFormik.values.departmentCode,
        neonateCutoff: departmentFormik.values.neonateCutoff,
        pediatricCutoff: departmentFormik.values.pediatricCutoff,
        softwarePlan: getSoftwarePlanDB(departmentFormik.values.softwarePlan),
        activeStatus: departmentFormik.values.activeStatus,
        agencyNumEMS: departmentFormik.values.agencyNumEMS,
        stateIDEMS: departmentFormik.values.stateIDEMS,
        gnisCode: departmentFormik.values.gnisCode,
        hashedPin: departmentFormik.values.hashedPin,
        saltedPin: departmentFormik.values.saltedPin,
        logoURI: logoURI,
        oneweightEnabled: departmentFormik.values.oneweightEnabled,
        realtimeUpdating: departmentFormik.values.realTimeEnabled,
        infusionCalculation: departmentFormik.values.infusionCalculation,
        ageFilterEnabled: departmentFormik.values.ageFilterEnabled,
      };
      console.log('Updating department: ', updatedDepartment);
      console.log('Department: ', department);
      const respone: Response = await updateDepartment(
        updatedDepartment,
        department
      );
      if (respone.type === ResponseType.Success) {
        let newDep: DepartmentItem = respone.data;
        console.log('Successfully Updated: ', newDep);

        // setDepartment(newDep);
        departmentFormik.resetForm();
        if (newDep.id !== parentDepartment.id) {
          replaceSubDeparment(newDep);
        }
        reloadDatabase(
          newDep.id === parentDepartment.id ? newDep : parentDepartment
        );
      } else {
        console.log('error updating department: ', respone.data);
      }
    } catch (error) {
      console.log('error updating department: ', error);
    }
  };

  const replaceSubDeparment = (newDep: DepartmentItem) => {
    if (!parentDepartment.subDeps) return;
    let prevDeps: DepartmentItem[] = [...parentDepartment.subDeps];
    let index: number | undefined = prevDeps?.findIndex(
      (dep) => dep.id === newDep.id
    );
    if (index && index !== -1) {
      prevDeps?.splice(index, 1, newDep);
      parentDepartment.subDeps = prevDeps;
      parentDepartment.activeSubDep = newDep;
    }
  };

  const reloadDatabase = async (
    department: DepartmentItem
  ): Promise<boolean> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  function getSoftwarePlanDB(
    softwarePlan: string
  ): 'ESSENTIALS' | 'VIEW_ONLY' | 'SOFTWARE_ONLY' | SoftwareType | null {
    switch (softwarePlan) {
      case 'Essentials':
        return 'ESSENTIALS';
      case 'View Only':
        return 'VIEW_ONLY';
      case 'Plus':
        return 'SOFTWARE_ONLY';
      default:
        return null;
    }
  }

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      {isEditMessage && (
        <ConfirmModal
          isVisible={isEditMessage}
          title="Edit Department?"
          handleClose={() => {
            setIsEditMessage(false);
          }}
          handleSubmit={() => {
            setIsEditMessage(false);
            setIsEditMode(true);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName="Edit"
          primaryDescription={`Are you sure you want to edit this will go live immediatelty?`}
        />
      )}
      {/* <ProtocolHeader
        name={'Settings'}
        rightSideBtn={'edit'}
        isSaveButton={true}
        isCancelButton={false}
        isSaveActive={false}
        isReviewButton={false}
        handleEdit={() => {
          setIsEditMessage(true);
        }}
        type={'protocol'}
      /> */}
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px 0px',
              flex: 1,
              overflowX: 'auto',
            }}
          >
            <div className={`titleStyling`}>
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6
                  className={`backTextLight`}
                  onClick={() => {
                    //handleBackButton();
                  }}
                ></h6>
              </div>

              <div className="titleItemContainer">
                <div className="titleTextPH">Settings</div>
              </div>

              <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                Edit the department settings
              </h6>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="headerButtonContainer">
              <Button
                data-testid="isCancelButton"
                className="secondary-button-small-border btn-rightMargin"
                onClick={() => {
                  setIsEditMode(false);
                  departmentFormik.resetForm();
                  setLogo(null);
                }}
                disabled={!isSaveValid}
              >
                <span>
                  <AiOutlineClose className="icon-normal" size="1rem" />
                </span>{' '}
                Cancel
              </Button>
              <Button
                data-testid="isSaveButton"
                className={` ${
                  // rightSideBtn === "save"
                  'primary-button btn-rightMargin'
                  // : "secondary-button-small-border btn-rightMargin"
                }`}
                onClick={() => {
                  // formik.handleSubmit();
                  handleSave();
                }}
                disabled={!isSaveValid}
              >
                <span>
                  <Io5.IoSave size="1rem" />
                </span>{' '}
                Save
              </Button>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //   justifyContent: 'space-between',
          //   alignItems: 'center',
          height: '100%',
          padding: '1rem',
          gap: '2rem',
        }}
      >
        {/* <div
          style={{
            height: '100%',
            width: '15%',
            minWidth: '100px',
            flex: 1,
            // borderRight: '1px solid #ccc',
          }}
        >
          <ViewportList items={SETTINGS_TABS}>
            {(item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={
                    {
                      // borderBottom:
                      //   index === SETTINGS_TABS.length - 1
                      //     ? undefined
                      //     : '1px solid #ccc',
                    }
                  }
                  onClick={() => {
                    setTab(item.name);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    className={`settings-tab-container ${tab === item.name ? 'active' : ''}`}
                  >
                    {item.icon}

                    <h6
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        //   gap: '0.5rem',
                        fontSize: '1rem',
                        margin: '0 0 0 12px',
                        padding: 0,
                        fontWeight: '500',
                      }}
                    >
                      {item.name}
                    </h6>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div> */}
        <div style={{ flex: 7 }}>
          {PageUI}
          {/* <DepartmentPage /> */}
        </div>
      </div>
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default SettingsPage;
