// Note: EditDB for MedicationItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';
import {
  Medication,
  MedicationConcentration,
  MedicationProtocol,
  MedicationRange,
  MedicationRoute,
  ModelMetaData,
  User,
} from '../../models';
import { DatabaseResponse, Response, ResponseType } from '../AmplifyDB';
import {
  MedicationConcentrationInput,
  MedicationRouteInput,
  ModelMetaDataInput,
  ProgressStatus,
} from '../../API';
import {
  getObjectDifference,
  globals,
  removeTypename,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import MedicationItem, { cloneMedication } from '../model/MedicationItem';
import MedicationSubItem from '../model/MedicationSubItem';
import ProtocolItem from '../model/ProtocolItem';
import { ProtocolDB, createProtocol, validatePointerID } from './ProtocolDB';
import { executeQuery } from '../GraphQL_API';
import { graphqlOperation } from 'aws-amplify';
import { getMedication } from '../../graphql/queries';

export type MedicationJSON = {
  name: string;
  concentration: MedicationConcentration[];
  rangeLow: number;
  rangeHigh: number;
  departmentID: string;
  routes: string[];
  protocolOptions: MedicationProtocol[];
  taggedProtocols?: ProtocolItem[];

  rxNormCode?: string | null | undefined;
  nemsisRoutes?: MedicationRoute[] | null | undefined;
  minDose?: string | null | undefined;
  maxDose?: string | null | undefined;
  metaData?: ModelMetaData | null | undefined;

  contraindication: string | null | undefined;
  note: string | null | undefined;
  warning: string | null | undefined;
  medClass: string | null | undefined;
  action: string | null | undefined;
  indication: string | null | undefined;
  interaction: string | null | undefined;
  onset: string | null | undefined;
  duration: string | null | undefined;

  createdBy: string;
  modifiedBy?: string;

  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  version: string | null | undefined;
};

export type MedicationDoseJSON = {
  index: number;
  basis: string;
  rangeLow: number;
  rangeHigh: number;
  route: string[];
  nemsisRoutes?: MedicationRouteInput[];
  title?: string;
  warning?: string;
  instruction?: string;
  note?: string;
  maxDose?: string | null | undefined;
  minDose?: string | null | undefined;
  calcMaxDose?: string | null | undefined;
  calcMinDose?: string | null | undefined;
};

/**
 * This function will check if the medication is a draft version and delete it
 * @param medicationItem The medication to check
 * @returns Success if ready to create a new medication or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Medication[];
    if (isActive)
      results = await DataStore.query(Medication, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.activeID.eq(id)])
      );
    else
      results = await DataStore.query(Medication, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      console.error(
        'ERROR: There are multiple draft versions of the medication'
      );
      for (let i = 0; i < results.length; i++) {
        if (globals.debug) console.log('Draft', i, ':', results[i]);
      }
    }

    for (let i = 0; i < results.length; i++) {
      let dbMed = results[i];
      if (dbMed.status === ProgressStatus.DRAFT) {
        let result = await DataStore.delete(Medication, dbMed.id);
        if (result == null) {
          return {
            type: ResponseType.Failure,
            data: 'The medication did not delete correctly',
          };
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: true,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new PARENT medication in the database and choose the version
 * @param medication MedicationJSON JSON format or Model MedicationItem
 * @returns The successful MedicationItem or the error
 */
export const createParentMedication = async (
  medication: MedicationJSON | MedicationItem,
  previousItem?: MedicationItem
): Promise<Response> => {
  try {
    let json: MedicationJSON;
    if (medication instanceof MedicationItem) {
      let medItem = medication as MedicationItem;
      if (medItem.dbMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medItem does not have a database object',
        };
      }
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < medItem.medicationSubItems.length; i++) {
        if (!protocols.includes(medItem.medicationSubItems[i].parentProtocol))
          protocols.push(medItem.medicationSubItems[i].parentProtocol);
      }

      json = {
        name: medItem.name,
        concentration: medItem.concentrations,
        rangeLow: medItem.rangeLow ? medItem.rangeLow : 0,
        rangeHigh: medItem.rangeHigh ? medItem.rangeHigh : globals.MAX_VALUE,
        departmentID: medItem.depID,
        routes: medItem.routes,
        protocolOptions: medItem.protocolOptions,
        status: medItem.status,
        activeID: medItem.activeID,
        version: medItem.version,
        taggedProtocols: protocols,

        createdBy: medItem.dbMedication.createdBy
          ? medItem.dbMedication.createdBy
          : '',
        modifiedBy: medItem.modifiedBy ? medItem.modifiedBy.id : undefined,

        contraindication: medItem.dbMedication.contraindication,
        note: medItem.dbMedication.note,
        warning: medItem.dbMedication.warning,
        medClass: medItem.dbMedication.medClass,
        action: medItem.dbMedication.action,
        indication: medItem.dbMedication.indication,
        interaction: medItem.dbMedication.interaction,
        onset: medItem.dbMedication.onset,
        duration: medItem.dbMedication.duration,

        rxNormCode: medItem.dbMedication.rxNormCode,
        nemsisRoutes: medItem.dbMedication.nemsisRoutes,
        minDose: medItem.dbMedication.minDose,
        maxDose: medItem.dbMedication.maxDose,
        metaData: medItem.dbMedication.metaData,
      };
    } else json = medication as MedicationJSON;

    console.log('NEMSIS ROUTES', json.nemsisRoutes);

    /* Validate the routes are of string array or convert to string array */
    if (json.routes == null) json.routes = [];
    else if (typeof json.routes === 'string') json.routes = [json.routes];

    /* 
        1. Creating a DRAFT the first time
        2. Creating a DRAFT from an ACTIVE version
        3. Updating a DRAFT from a DRAFT version
        4. Creating a ARCHIVE from an ACTIVE version
    */

    let nemsisRoutes: MedicationRouteInput[] = [];
    if (json.nemsisRoutes != null) {
      for (let i = 0; i < json.nemsisRoutes.length; i++) {
        let newRoute: MedicationRouteInput = {
          route: json.nemsisRoutes[i].route,
          code: json.nemsisRoutes[i].code,
        };
        nemsisRoutes.push(newRoute);
      }
    }

    let concentrations: MedicationConcentrationInput[] = [];
    if (json.concentration != null) {
      for (let i = 0; i < json.concentration.length; i++) {
        let con = json.concentration[i];
        let newConcentration: MedicationConcentrationInput = {
          firstAmnt: con.firstAmnt,
          secAmnt: con.secAmnt,
          firstUnit: con.firstUnit,
          secUnit: con.secUnit,
        };
        concentrations.push(newConcentration);
      }
    }

    let m: Medication;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbMed = await DataStore.query(Medication, previousItem.uid);
      if (dbMed == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Medication does not exist could not update',
        };
      }

      m = await DataStore.save(
        Medication.copyOf(dbMed, (updated) => {
          updated.name = json.name;
          updated.concentration = json.concentration;
          updated.rangeLow = json.rangeLow;
          updated.rangeHigh = json.rangeHigh;
          updated.route = json.routes;
          updated.protocolOptions = json.protocolOptions;

          updated.contraindication = json.contraindication;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.medClass = json.medClass;
          updated.action = json.action;
          updated.indication = json.indication;
          updated.interaction = json.interaction;
          updated.onset = json.onset;
          updated.duration = json.duration;

          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      m = await DataStore.save(
        new Medication({
          name: json.name,
          concentration: removeTypename(json.concentration),
          rangeLow: json.rangeLow,
          rangeHigh: json.rangeHigh,
          departmentID: json.departmentID,
          route: json.routes,
          protocolOptions: json.protocolOptions,

          contraindication: json.contraindication,
          note: json.note,
          warning: json.warning,
          medClass: json.medClass,
          action: json.action,
          indication: json.indication,
          interaction: json.interaction,
          onset: json.onset,
          duration: json.duration,

          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,

          status: json.status,
          activeID: json.activeID,
          version: json.version,

          rxNormCode: json.rxNormCode,
          nemsisRoutes: removeTypename(json.nemsisRoutes),
          minDose: json.minDose,
          maxDose: json.maxDose,
          metaData: removeTypename(json.metaData),
        })
      );
    }

    let medItem = new MedicationItem(m);

    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];
        /* ACTIVE - pass ID, DRAFT and activeID is null (HAS NEVER BEEN PUBLISHED) - pass UID, DRAFT with previous publish - pass activeID */
        let medID =
          medItem.status === ProgressStatus.ACTIVE ||
          (medItem.status === ProgressStatus.DRAFT && medItem.activeID == null)
            ? medItem.uid
            : medItem.activeID;
        if (medID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The medication does not have a proper version ID ' +
              medItem.uid +
              ' ' +
              medItem.activeID +
              ' ' +
              medItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          medID,
          json.modifiedBy,
          'Medication'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING MEDICATION:', e, medication);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Duplicate the medication doses from the one protocol to another protocol
 * @param protocol The new protocol that has just been created
 * @param previousProtocol The protocol that was duplicated
 * @param isDraft True if the protocol is a draft version, otherwise will update the active version
 * @returns An array of the medications that were duplicated
 */
export const duplicateMedicationDoses = async (
  protocol: ProtocolItem,
  previousProtocol: ProtocolItem
  // isDraft: boolean
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      previousProtocol.status === ProgressStatus.ACTIVE ||
      (previousProtocol.status === ProgressStatus.DRAFT &&
        previousProtocol.activeID == null)
        ? previousProtocol.uid
        : previousProtocol.activeID;
    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The previous protocol does not have a proper version ID ' +
          previousProtocol.uid +
          ' ' +
          previousProtocol.activeID +
          ' ' +
          previousProtocol.status,
      };
    }

    /* Loop through the medications and create a new MedicationProtocol */
    let medications: MedicationItem[] = [];
    let completed: MedicationItem[] = [];
    for (let i = 0; i < previousProtocol.medications.length; i++) {
      if (previousProtocol.medications[i].parentMedication == null) continue;
      const med: MedicationItem =
        previousProtocol.medications[i].parentMedication;

      /* Type check the medication */
      if (med.dbMedication == null) {
        console.error('ERROR: The medication does not have a database object');
        continue;
      }

      /* Check if the medication is already in the list */
      if (completed.find((m) => m.uid === med.uid) == null) {
        /* Find the MedicationProtocol for the previous protocol */
        const mp = med.dbMedication.protocolOptions.find((p) => {
          return p.protocolID === protID;
        });

        if (mp == null) {
          console.error('ERROR: The MedicationProtocol does not exist');
          continue;
        }

        /* Duplicate the MedicationProtocol */
        let newID =
          protocol.status === ProgressStatus.ACTIVE ||
          (protocol.status === ProgressStatus.DRAFT &&
            protocol.activeID == null)
            ? protocol.uid
            : protocol.activeID;
        if (newID == null) {
          console.error('ERROR: The new protocol does not have a proper ID');
          continue;
        }
        let newMedProtocol = new MedicationProtocol({
          protocolID: newID,
          options: mp.options.map((o) => {
            return new MedicationRange({
              basis: o.basis,
              rangeLow: o.rangeLow,
              rangeHigh: o.rangeHigh,
              route: o.route,
              title: o.title,
              warning: o.warning,
              instruction: o.instruction,
              note: o.note,
              maxDose: o.maxDose,
              minDose: o.minDose,
              calcMax: o.calcMax,
              calcMin: o.calcMin,
              index: o.index,
            });
          }),
        });

        /* Update the medication with the new MedicationProtocol */
        let options = [...med.protocolOptions, newMedProtocol];
        let newMed = cloneMedication(med);
        newMed.protocolOptions = options;
        newMed.status = ProgressStatus.DRAFT;
        newMed.activeID =
          med.status === ProgressStatus.ACTIVE ? med.uid : med.activeID;
        newMed.version = upgradeVersion(
          med.version == null ? 'v1.0.0' : med.version
        );
        newMed.modifiedBy = protocol.modifiedBy;

        let results = await createParentMedication(newMed, med);
        if (results.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: results.data,
          };
        }
        completed.push(med);
        medications.push(results.data as MedicationItem);
      }
    }

    if (globals.debug)
      if (globals.debug)
        console.log('Successfully duplicated medications:', medications);
    return {
      type: ResponseType.Success,
      data: medications,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will delete all the medications that are associated with the protocol
 * @param protocol The protocol to delete the medication from
 * @param modifiedBy The user that modified the medication
 * @returns Success: data is the number of medications deleted or Failure if there was an error
 */
export const deleteMedicationsForProtocol = async (
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    /* Get the ID of the protocol to map to the new protocol */
    let protID =
      protocol.status === ProgressStatus.ACTIVE ||
      (protocol.status === ProgressStatus.DRAFT && protocol.activeID == null)
        ? protocol.uid
        : protocol.activeID;

    if (protID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The protocol does not have a proper version ID ' +
          protocol.uid +
          ' ' +
          protocol.activeID +
          ' ' +
          protocol.status,
      };
    }

    /* Get all the Medications that the department has */
    let medicationList = await DataStore.query(Medication, (c) =>
      c.and((c) => [
        c.departmentID.eq(protocol.departmentID),
        c.or((o) => [
          c.status.eq(ProgressStatus.ACTIVE),
          c.status.eq(ProgressStatus.DRAFT),
        ]),
      ])
    );

    let medications: MedicationItem[] = [];
    for (let i = 0; i < medicationList.length; i++) {
      medications.push(new MedicationItem(medicationList[i]));
    }

    let n = 0;
    for (let i = 0; i < medications.length; i++) {
      let med = medications[i];

      let protocolOptions = med.protocolOptions;

      let medProtocol: MedicationProtocol | undefined = undefined;
      for (let i = 0; i < protocolOptions.length; i++) {
        let po = protocolOptions[i];
        if (po.protocolID === protID) {
          medProtocol = po;
          break;
        }
      }
      /* If there is no medication protocol then there is a problem */
      if (!medProtocol) continue;

      /* Remove the medication protocol from the medication */
      let newOptions = protocolOptions.filter(
        (p) => p.protocolID !== (medProtocol as MedicationProtocol).protocolID
      );

      let newMed = cloneMedication(med);
      newMed.protocolOptions = newOptions;
      newMed.status = ProgressStatus.DRAFT;
      newMed.activeID =
        med.status === ProgressStatus.ACTIVE ? med.uid : med.activeID;
      newMed.version = upgradeVersion(
        med.version == null ? 'v1.0.0' : med.version
      );
      newMed.modifiedBy = modifiedBy;

      //Increment the count modified
      n++;

      createParentMedication(newMed, med)
        .then((result) => {
          if (result.type === ResponseType.Failure) {
            console.error('ERROR DELETING MEDICATION:', result.data);
          }
          console.log('DELETED PROTOCOL REFERENCE -> MED', med.name, protID);
        })
        .catch((e) => {
          console.error('ERROR DELETING MEDICATION:', e);
        });
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Check if the protocol is already pointing at the medication
 *    - Otherwise add the medication to the protocol and create a new protocol draft
 * @param medication The medication o check if it is paired to the protocol
 * @param protocol The protocol to check if it is paired to the medication
 * @param modifiedBy The user that modified the medication
 * @returns Success if the medication is paired with the protocol and returns a MedicationProtocol or Failure if there was an error
 */
export const validateMedicationProtocolPairing = async (
  medication: MedicationItem,
  protocol: ProtocolItem,
  modifiedBy: User
): Promise<Response> => {
  try {
    if (medication.dbMedication == null) {
      return {
        type: ResponseType.Failure,
        data: 'The medication does not have a database object could be an infusion item',
      };
    }
    let medicationProtocol: MedicationProtocol;
    let medID =
      medication.status === ProgressStatus.ACTIVE ||
      (medication.status === ProgressStatus.DRAFT &&
        medication.activeID == null)
        ? medication.uid
        : medication.activeID;
    let pairedID = protocol.model.medicationIDs?.find((id) => id === medID);

    if (medID == null) {
      return {
        type: ResponseType.Failure,
        data:
          'The medication does not have a proper version ID ' +
          medication.uid +
          ' ' +
          medication.activeID +
          ' ' +
          medication.status,
      };
    }

    /* Create a new protocol draft if the medication is not paired with the protocol */
    if (pairedID == null) {
      let medIds = protocol.model.medicationIDs
        ? protocol.model.medicationIDs
        : [];
      medIds.push(medID);

      let draftProtocol: ProtocolDB = {
        departmentID: protocol.parent.depID,
        name: protocol.name,
        nickname: protocol.nickname,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.pdfUrl,
        pairedDeps: protocol.pairedDeps,
        pairedProtocols: protocol.pairedProtocols.map((p) => p.uid),
        medications: medIds,
        infusions: protocol.infusions.map((i) => i.uid),
        equipment: protocol.equipment.map((e) => e.uid),
        forms: protocol.forms.map((f) => f.uid),
        electrical: protocol.electrical.map((e) => e.parentElectrical.uid),

        createdBy: protocol.model.createdBy ? protocol.model.createdBy : '',
        modifiedBy: modifiedBy ? modifiedBy.id : undefined,

        status: ProgressStatus.DRAFT,
        activeID: protocol.uid,
        version: upgradeVersion(protocol.version),
        pdfVersion: protocol.pdfVersion,
        isPublic: protocol.isPublic,
        keychainID: protocol.keychainID,
      };

      let result: Response = await createProtocol(draftProtocol, protocol);

      if (result.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: result.data,
        };
      }

      medicationProtocol = new MedicationProtocol({
        protocolID: protocol.uid,
        options: [],
      });
    } else {
      /* Find the medication protocol */
      let protID =
        protocol.status === ProgressStatus.ACTIVE
          ? protocol.uid
          : protocol.activeID;
      let mo = medication.dbMedication.protocolOptions.find(
        (p) => p.protocolID === protID
      );
      if (mo == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication protocol does not exist',
        };
      }
      medicationProtocol = mo;
    }

    /* Otherwise we just need to update the MedicationProtocol */
    return {
      type: ResponseType.Success,
      data: medicationProtocol,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Reorder the medication dose inside of the protocol
 * @param medication The parent medication to reorder the medication dose
 * @param protocol The protocol to reorder the medication dose in
 * @param medicationDoses The medication doses to reorder
 * @param modifiedBy The user that modified the medication
 * @returns Success if the medication dose was reordered or Failure if there was an error
 */
export const reorderMedicationDose = async (
  medication: MedicationItem,
  protocol: ProtocolItem,
  medicationDoses: MedicationSubItem[],
  modifiedBy: User
): Promise<Response> => {
  try {
    /* First check if the medication and protocol are already paired together */
    let validateResult: Response = await validateMedicationProtocolPairing(
      medication,
      protocol,
      modifiedBy
    );
    if (validateResult.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: validateResult.data,
      };
    }

    /* The Medication Protocol */
    let medProtocol: MedicationProtocol =
      validateResult.data as MedicationProtocol;

    let newMedRanges = medicationDoses.map((m) => {
      let range = m.range;
      let medRange = new MedicationRange({
        basis: range.basis,
        rangeLow: range.rangeLow,
        rangeHigh: range.rangeHigh,
        route: range.route,
        title: range.title,
        warning: range.warning,
        instruction: range.instruction,
        note: range.note,
        maxDose: range.maxDose,
        minDose: range.minDose,
        calcMax: range.calcMax,
        calcMin: range.calcMin,
        index: m.index,
      });
      return medRange;
    });

    console.log('NEW MED DOSEs:', medication.name, newMedRanges);

    /* Check if we are updating a previous dose and filter out the previous dose otherwise add the new range */
    let medicationRanges = [...medProtocol.options];
    for (let i = 0; i < medicationDoses.length; i++) {
      let range = medicationDoses[i].range;
      medicationRanges = medicationRanges.filter(
        (m) => getObjectDifference(m, range).length != 0
      );
    }

    let newMedProtocol = new MedicationProtocol({
      protocolID: medProtocol.protocolID,
      options: [...medicationRanges, ...newMedRanges],
    });

    let options = [...medication.protocolOptions];

    options = options.filter((p) => p.protocolID !== medProtocol.protocolID);
    options.push(newMedProtocol);
    console.log('NEW MED PROT:', newMedProtocol);

    /* Update the parent medication with the new MedicationProtocol */
    let newMed = cloneMedication(medication);
    newMed.protocolOptions = options;
    newMed.version = upgradeVersion(
      medication.version == null ? 'v1.0.0' : medication.version
    );
    newMed.activeID =
      medication.status === ProgressStatus.ACTIVE
        ? medication.uid
        : medication.activeID;
    newMed.status = ProgressStatus.DRAFT;
    newMed.modifiedBy = modifiedBy;

    let results = await createParentMedication(newMed, medication);

    if (results.type === ResponseType.Failure) {
      return {
        type: ResponseType.Failure,
        data: results.data,
      };
    }
    let newMedication = results.data as MedicationItem;
    return {
      type: ResponseType.Success,
      data: newMedication,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish all the medication drafts in the database
 * @param department The department to publish the medication drafts
 * @returns Success if the drafts were published or Failure if there was an error
 */
export const publishMedicationDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftMedications = await DataStore.query(Medication, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftMedications.length; i++) {
      let med: Medication = draftMedications[i];
      let response: Response = await publishMedication(med);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: med,
          message: `Published Folder: ${med.name}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the medication to the database
 *    1. Create a new ARCHEIVED medication based on the current ACTIVE medication
 *    2. Update the ACTIVE medication with the new information
 *    3. Delete the DRAFT medication
 * @param draftMedicationItem The medication to publish
 */
export const publishMedication = async (
  draftMedicationItem: Medication
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the medication is configured correctly as a draft version */
    if (draftMedicationItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The medication is not a draft version',
      };
    }

    let activeMedication: Medication;

    /* Use case 1: Creating the FIRST active version */
    if (draftMedicationItem.activeID == null) {
      /* Update the draft medication to be active */
      activeMedication = await DataStore.save(
        Medication.copyOf(draftMedicationItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active medication item */
      let id: string = draftMedicationItem.activeID;
      let curMedication = await DataStore.query(Medication, id);

      /* Base Case 3 -- check if the active medication exists */
      if (curMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active medication does not exist',
        };
      }

      let archeived = new MedicationItem(curMedication);
      archeived.status = ProgressStatus.ARCHIVE;
      archeived.activeID = curMedication.id;

      // 2. Create a new ARCHEIVED medication based on the current ACTIVE medication
      let archiveResult: Response = await createParentMedication(archeived);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archeived = archiveResult.data as MedicationItem;

      // 2. Update the ACTIVE medication with the new information
      activeMedication = await DataStore.save(
        Medication.copyOf(curMedication, (updated) => {
          updated.name = draftMedicationItem.name;
          updated.concentration = draftMedicationItem.concentration;
          updated.rangeLow = draftMedicationItem.rangeLow;
          updated.rangeHigh = draftMedicationItem.rangeHigh;
          updated.route = draftMedicationItem.route;
          updated.protocolOptions = draftMedicationItem.protocolOptions;

          updated.contraindication = draftMedicationItem.contraindication;
          updated.note = draftMedicationItem.note;
          updated.warning = draftMedicationItem.warning;
          updated.medClass = draftMedicationItem.medClass;
          updated.action = draftMedicationItem.action;
          updated.indication = draftMedicationItem.indication;
          updated.interaction = draftMedicationItem.interaction;
          updated.onset = draftMedicationItem.onset;
          updated.duration = draftMedicationItem.duration;

          updated.createdBy = draftMedicationItem.createdBy;
          updated.modifiedBy = draftMedicationItem.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftMedicationItem.version;

          updated.rxNormCode = draftMedicationItem.rxNormCode;
          updated.nemsisRoutes = draftMedicationItem.nemsisRoutes;
          updated.minDose = draftMedicationItem.minDose;
          updated.maxDose = draftMedicationItem.maxDose;
          updated.metaData = draftMedicationItem.metaData;
        })
      );

      // 3. Delete the DRAFT medication
      let draftMedication = await DataStore.delete(
        Medication,
        draftMedicationItem.id
      );
      if (draftMedication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft medication does not exist',
        };
      }
    }

    let medItem = new MedicationItem(activeMedication);
    return {
      type: ResponseType.Success,
      data: medItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will update the medication in the database
 * @param medicationItem The medication to delete from the database
 * @param isSoft True if the medication should be soft deleted, false if it should be hard deleted
 * @returns Success if the medication was deleted or Failure if there was an error
 */
export const deleteMedication = async (
  medicationItem: MedicationItem,
  isSoft: boolean = true
): Promise<Response> => {
  try {
    let id: string = medicationItem.uid;
    if (isSoft) {
      let medication = await DataStore.query(Medication, id);
      if (medication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication does not exist',
        };
      }

      let result = await DataStore.save(
        Medication.copyOf(medication, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication did not update correctly',
        };
      }
    } else {
      let medication = await DataStore.delete(Medication, id);
      if (medication == null) {
        return {
          type: ResponseType.Failure,
          data: 'The medication does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: medicationItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will create a new medication draft in the database
 * @param department The department to check for medication drafts
 * @returns Success if the draft was created or Failure if there was an error
 */
export const isMedicationDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Medication, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get all the medication drafts from the database
 * @param department The department to get the medication drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getMedicationDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Medication, (c) =>
      c.and((c) => [
        // c.or((c) => [
        c.status.eq('DRAFT'),
        // c.status.eq("DELETED"),
        // ]),
        c.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if(model.status === "DELETED")
      // message = `Deleted Medication: ${model.name}`;
      if (model.activeID == null) message = `Created Medication: ${model.name}`;
      else message = `Updated Medication: ${model.name}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Remove all the medication drafts from the database - User choose to override the drafts
 * @param department The department to remove the drafts from
 * @returns Success if the drafts were removed or Failure if there was an error
 */
export const removeCurrentMedicationDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftMedications = await DataStore.query(Medication, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftMedications.length; i++) {
      let med: Medication = draftMedications[i];
      await DataStore.delete(med);
      updates.push({
        model: med,
        message: `Removed Medication Draft for: ${med.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getMedicationByID = async (
  db: DatabaseResponse,
  id: string
): Promise<MedicationItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = db.medications.find((c) => c.uid === id);
      if (dbMed != null) return resolve(dbMed);
      else {
        executeQuery(graphqlOperation(getMedication, { id: id }), 1500)
          .then((data) => {
            if (data.data.getMedication == null) {
              resolve(null);
            } else {
              let med = data.data.getMedication as Medication;
              let medication = new MedicationItem(med);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(medication);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
