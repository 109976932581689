import React, { useEffect, useMemo, useState } from 'react';
import {
  IoChevronForward,
  IoDocument,
  IoDocumentOutline,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolItem from '../../../../data/model/ProtocolItem';

import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
} from '../../../../data/AmplifyDB';
import CategoryItem from '../../../../data/model/CategoryItem';
import { handleGetDepartment } from '../../../../store/actions';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { AiOutlineFullscreen } from 'react-icons/ai';
import PublicPDFViewer from './PublicPdfViewer';
import PublicNavbar from '../Navbar/PublicNavbar';
import { ViewportList } from 'react-viewport-list';
import { FaFolderOpen, FaLock, FaLockOpen } from 'react-icons/fa6';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import SearchableList from '../../../components/Search/SearchableList';
import { getHashedPin } from '../../../_global/common/Encrypt';
import Component404 from '../../../components/404/Component404';
import { BiArrowBack } from 'react-icons/bi';
import UnlockKeychainModal from './UnlockKeychainModal';
import KeychainItem from '../../../../data/model/KeychainItem';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ResizableDivs from '../../../components/ResizeableDiv/ResizeableDiv';
import { fetchDepartmentByName } from '../../../../data/GraphQL_API';
import SplashScreen from '../../SplashScreen';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const PublicPdfScreen = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const rootUrl = window.location.origin;

  const { state } = location;
  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse | undefined>(
    state && state.database
      ? state.database
      : dbState.departmentItem && dbState.departmentItem.length !== 0
        ? dbState.departmentItem
        : undefined
  );
  const [department, setDepartment] = useState<DepartmentItem | undefined>(
    database ? database.department : undefined
  );

  const [isUnlockVisible, setIsUnlockVisible] = useState<boolean>(false);
  const [isLockModalVisible, setIsLockModalVisible] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<
    CategoryItem | ProtocolItem | undefined
  >(state && state.selectedItem ? state.selectedItem : undefined);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [keychain, setKeychain] = useState<KeychainItem | undefined>(undefined);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [options, setOptions] = useState<(CategoryItem | ProtocolItem)[]>(
    database ? [...database.categories, ...database.protocols] : []
  );

  const [isValidURL, setIsValidURL] = useState<boolean>();
  const [depInfo, setDepInfo] = useState({
    name: '',
    logo: '',
  });

  useEffect(() => {
    if (!database) {
      const split = location.pathname.split('/');
      if ((split.length >= 3 || split.length <= 5) && split[1] === 'sites') {
        const departmentName = split[2];
        fetchDepartment(departmentName);
      }
    }
  }, [database]);

  const fetchDepartment = async (departmentName: string) => {
    let result: Response = await fetchDepartmentByName(departmentName);
    if (result.type === ResponseType.Success) {
      if (result.data.db) {
        let db: DatabaseResponse = result.data.db;
        setDatabase(db);
        setOptions([...db.categories, ...db.protocols]);
        setDepartment(db.department);
        dispatch<any>(handleGetDepartment(db));
      }
    } else {
      if (globals.debug) console.log('Error fetching department', result.data);
      navigate('/login');
    }
  };

  useEffect(() => {
    if (database && selectedItem && selectedItem.keychainID) {
      const keychain = database.keychains.find(
        (keychain) => keychain.uid === selectedItem.keychainID
      );
      if (keychain) setKeychain(keychain);
      else setKeychain(undefined);
    } else setKeychain(undefined);
  }, [selectedItem, database]);

  const mentionedVisible = useMemo(() => {
    let isUnlocked = keychain ? keychain.isUnlocked : true;
    return (
      selectedItem &&
      (selectedItem as ProtocolItem).parent != null &&
      (selectedItem as ProtocolItem).pairedProtocols.length !== 0 &&
      isUnlocked
    );
  }, [selectedItem, keychain]);

  // const [isLocked, setIsLocked] = useState<boolean>(false);

  // useEffect(() => {
  //   if (selectedItem && (selectedItem as ProtocolItem).keychain) {
  //     let prot = selectedItem as ProtocolItem;
  //     console.log('KEYCHAIN:', prot.keychain);
  //     if (prot.keychain && prot.keychain.isUnlocked === false) {
  //       console.log('LOCKED:', prot.keychain);
  //       setIsLocked(true);
  //     } else setIsLocked(false);
  //   } else setIsLocked(false);
  // }, [selectedItem]);

  // console.log('IS LOCKED:', isLocked);

  const searchableProtocols = useMemo(() => {
    if (database && database.protocols) {
      let prots: ProtocolItem[] = [];
      for (let i = 0; i < database.protocols.length; i++) {
        let protocol = database.protocols[i];
        if (protocol.parent.keychainID) {
          let keychain = database.keychains.find(
            (k) => k.uid === protocol.parent.keychainID
          );
          if (keychain && keychain.isUnlocked) prots.push(protocol);
        } else prots.push(protocol);
      }
      return prots;
    }
    return [];
  }, [database]);

  const validateURL = (url: string): boolean => {
    const split = url.split('/');
    const queryParams = new URLSearchParams(location.search);

    if (
      department &&
      (split.length === 4 || split.length === 5) &&
      split[1] === 'sites' &&
      queryParams.get('signature') &&
      queryParams.get('expiration')
    ) {
      const expiration = Number(queryParams.get('expiration') as string);
      const currentTime = new Date().getTime();
      if (expiration < currentTime) return false;

      const signature = queryParams.get('signature') as string;
      const hash = getHashedPin(
        department.id,
        department.salt + expiration.toLocaleString(),
        'SHA512'
      );
      if (signature === hash) return true;
    }
    return false;
  };
  /** Validate */
  useEffect(() => {
    let valid = validateURL(location.pathname);
    setIsValidURL(valid);
    if (valid) {
      const db = state && state.database ? state.database : database;
      if (state && state.selectedItem) setSelectedItem(state.selectedItem);
      else if (db && db.protocols.length > 0) setSelectedItem(db.protocols[0]);
      // else handleBack();
      if (db) {
        setDatabase(db);
        setDepartment(db.department);

        if (db.department.activeSubDep) {
          setDepInfo({
            name: db.department.activeSubDep.name,
            logo: db.department.activeSubDep.logoVerifiedUrl
              ? db.department.activeSubDep.logoVerifiedUrl
              : '',
          });
        } else {
          setDepInfo({
            name: db.department.name,
            logo: db.department.logoVerifiedUrl
              ? db.department.logoVerifiedUrl
              : '',
          });
        }
      }
    }
  }, [location.pathname, department, state, database]);

  /* 5-3-24 Hazlett: Confrim URL is valid */
  useEffect(() => {
    if (department && !validateURL(location.pathname)) {
      if (globals.debug)
        console.log('INVALID PUBLIC SIGNED URL', location.pathname);
      // navigate('/404');
    }
  }, [location.pathname, department, pdfUrl, selectedItem, keychain]);

  const handleBack = () => {
    const split = location.pathname.split('/');
    const departmentName = split[2];
    navigate('/sites/' + departmentName);
  };

  const handleSelect = (item: CategoryItem | ProtocolItem) => {
    setSelectedItem(item);
    if ((item as ProtocolItem).parent != null) {
      setPdfLoaded(false);
    }
  };

  const PublicProtocolHeader = () => {
    return (
      <div
        className="fixedHeaderPad"
        data-testid="protocol-header"
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <div style={{ margin: '0px 10px' }}>
          <div className={`titleStyling`}>
            <div
              style={{
                display: 'flex',
              }}
            >
              <h6
                className={`backTextLight`}
                style={{
                  flex: 1,
                }}
              >
                {(selectedItem as ProtocolItem).parent
                  ? (selectedItem as ProtocolItem).parent.name
                  : 'Folder'}
              </h6>
              <div className="headerButtonContainer">
                {keychain && keychain.isUnlocked && (
                  <span
                    className="iconButton"
                    style={{
                      marginRight: '8px',
                    }}
                    onClick={() => {
                      setIsLockModalVisible(true);
                    }}
                  >
                    <FaLock
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        height: '29px',
                        width: '29px',
                        padding: '6px',
                      }}
                    />
                  </span>
                )}
                {(selectedItem as ProtocolItem).parent != null && (
                  <span className="iconButton" onClick={handleFullScreen}>
                    <AiOutlineFullscreen
                      data-testid="isFullScreenButton"
                      className="icon"
                    />
                  </span>
                )}
              </div>
            </div>

            <div
              className="titleText headerText"
              style={{
                //Max lines is 2
                // whiteSpace: 'noWrap',
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
              }}
            >
              {selectedItem?.name}
            </div>
            <h6
              className={`headerTextLight`}
              style={{
                fontWeight: '400',
                color: '#616161',
                // marginLeft: '8px',
                // marginRight: '6px',
                marginTop: '-0px',
              }}
            >
              Last Updated:{' '}
              {selectedItem
                ? getFormattedDate(selectedItem.model?.updatedAt, true)
                : ''}
            </h6>
            {/* {date && < h6 className={`headerTextLight  ${homeScreen ? "titleText" : ''}`}>{date}</h6>} */}
          </div>
          <hr />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadPDF = async () => {
      let result: Response = await fetchPDF(
        (selectedItem as ProtocolItem).pdfUrl
      );
      if (result.type === ResponseType.Success) setPdfUrl(result.data);
      else {
        console.error(
          'ERROR: Could not load pdf for protocol:' + selectedItem?.name,
          result.data
        );
        setPdfUrl('');
      }
    };
    if (selectedItem && (selectedItem as ProtocolItem).pdfUrl && !pdfLoaded) {
      loadPDF();
      setPdfLoaded(true);
    }
  }, [selectedItem, pdfLoaded]);

  const handleFullScreen = () => {
    if (
      pdfUrl !== '' &&
      selectedItem &&
      (keychain == null || keychain.isUnlocked)
    ) {
      localStorage.setItem('pdfURL', pdfUrl);
      localStorage.setItem('pdfName', selectedItem.name);
      let filterName = selectedItem.name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        pdfUrl + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${rootUrl}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );
    }
  };

  const Folders = () => {
    return (
      <div className="publicListRow">
        <h6
          className="categoryText"
          style={{
            color: '#616161',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          Folders
        </h6>
        <ViewportList items={database ? database.categories : []}>
          {(category: CategoryItem, index: number) => {
            let isSelected = false;
            const keychain = database?.keychains.find(
              (keychain) => keychain.uid === category.keychainID
            );
            if (
              (selectedItem as ProtocolItem).parent != null &&
              (selectedItem as ProtocolItem).parent.uid === category.uid
            )
              isSelected = true;
            else if ((selectedItem as CategoryItem).uid === category.uid)
              isSelected = true;
            return (
              <div
                onClick={(e) => {
                  handleSelect(category);
                }}
                key={index}
                className={`listItemContainer ${isSelected ? 'selected' : ''}`}
              >
                {keychain && !keychain.isUnlocked ? (
                  <FaLock size={16} />
                ) : keychain && keychain.isUnlocked ? (
                  <FaLockOpen size={16} />
                ) : (
                  <FaFolderOpen size={16} />
                )}
                <h6 className="categoryText">{category.name}</h6>
                <IoChevronForward size={16} />
              </div>
            );
          }}
        </ViewportList>
      </div>
    );
  };

  const Protocols = () => {
    return (
      <div className="publicListRow">
        <h6
          className="categoryText"
          style={{
            color: '#616161',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          Protocols
        </h6>
        <ViewportList
          items={
            selectedItem
              ? (selectedItem as ProtocolItem).parent != null
                ? (selectedItem as ProtocolItem).parent.protocols
                : keychain && !keychain.isUnlocked
                  ? []
                  : (selectedItem as CategoryItem).protocols
              : []
          }
        >
          {(protocol: ProtocolItem, index: number) => {
            let isSelected = false;
            if ((selectedItem as ProtocolItem).uid === protocol.uid)
              isSelected = true;
            const keychain = database?.keychains.find(
              (keychain) => keychain.uid === protocol.keychainID
            );
            return (
              <div
                onClick={(e) => {
                  handleSelect(protocol);
                }}
                key={index}
                className={`listItemContainer ${isSelected ? 'selected' : ''}`}
              >
                {keychain && !keychain.isUnlocked ? (
                  <FaLock size={16} />
                ) : keychain && keychain.isUnlocked ? (
                  <FaLockOpen size={16} />
                ) : (
                  <IoDocumentOutline size={16} />
                )}
                <h6 className="categoryText">{protocol.name}</h6>
                <IoChevronForward size={16} />
              </div>
            );
          }}
        </ViewportList>
      </div>
    );
  };

  const MentionedProtocols = () => {
    return (
      <div className="publicListRow">
        <h6
          className="categoryText"
          style={{
            color: '#616161',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          Mentioned Protocols
        </h6>
        <ViewportList
          items={
            selectedItem && (selectedItem as ProtocolItem).pairedProtocols
              ? (selectedItem as ProtocolItem).pairedProtocols
              : []
          }
        >
          {(protocol: ProtocolItem, index: number) => {
            let isSelected = false;
            if ((selectedItem as ProtocolItem).uid === protocol.uid)
              isSelected = true;
            return (
              <div
                onClick={(e) => {
                  handleSelect(protocol);
                }}
                key={index}
                className={`listItemContainer ${isSelected ? 'selected' : ''}`}
              >
                <IoDocumentOutline size={16} />
                <h6 className="categoryText">{protocol.name}</h6>
                <IoChevronForward size={16} />
              </div>
            );
          }}
        </ViewportList>
      </div>
    );
  };

  return isValidURL && selectedItem ? (
    <div className="public-screen-container">
      <PublicNavbar isProtocolPage={false} />

      {keychain && department && (
        <UnlockKeychainModal
          isVisible={isUnlockVisible}
          handleClose={() => setIsUnlockVisible(false)}
          handleSubmit={(keychain: KeychainItem) => {
            setKeychain(keychain);
            setPdfLoaded(false);
            setIsUnlockVisible(false);

            let keychains = [...database!.keychains];
            let index = keychains.findIndex((k) => k.uid === keychain.uid);
            keychains[index] = keychain;
            let db: DatabaseResponse = {
              ...database!,
              keychains: keychains,
            };
            dispatch<any>(handleGetDepartment(db));
            setDatabase(db);
          }}
          keychain={keychain}
          type="protocol"
          department={department}
        />
      )}

      <ConfirmModal
        isVisible={isLockModalVisible}
        handleClose={() => setIsLockModalVisible(false)}
        handleSubmit={() => {
          if (keychain) {
            keychain.isUnlocked = false;
            localStorage.removeItem(keychain.uid);
            setPdfUrl('');

            let keychains = [...database!.keychains];
            let index = keychains.findIndex((k) => k.uid === keychain.uid);
            keychains[index] = keychain;
            let db: DatabaseResponse = {
              ...database!,
              keychains: keychains,
            };
            dispatch<any>(handleGetDepartment(db));
            setDatabase(db);
          }

          setIsLockModalVisible(false);
        }}
        title={
          'Lock ' +
          (keychain
            ? keychain.protocols.length + keychain.categories.length
            : 0) +
          ' Items?'
        }
        primaryDescription={
          'This keychain was used to unlock ' +
          (keychain
            ? (keychain.categories.length > 0
                ? keychain.categories.length +
                  ' folder' +
                  (keychain.categories.length > 1 ? 's' : '')
                : '') +
              (keychain.protocols.length + keychain.categories.length > 0
                ? ' and '
                : '') +
              (keychain.protocols.length > 0
                ? keychain.protocols.length +
                  ' protocol' +
                  (keychain.protocols.length > 1 ? 's' : '')
                : '')
            : '') +
          ' protocols. Are you sure you want to lock them?'
        }
        secondaryDescription={
          'This will require users to unlock the keychain items again.'
        }
        primaryBtnName={'Cancel'}
        secondaryBtnName={'Lock'}
      />

      <div className="public-container">
        <div className="public-sidebar-pdf">
          <div
            className="hover-raise-elevation"
            onClick={handleBack}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              padding: '0.25rem',
              cursor: 'pointer',
              gap: '0.25rem',
              borderRadius: '0.5rem',
            }}
          >
            <BiArrowBack
              size={24}
              style={{
                color: 'black',
              }}
            />
            {depInfo.logo && (
              <img
                className="department-logo-public"
                style={{
                  height: '70px',
                  width: 'auto',
                }}
                src={depInfo.logo}
                alt="Agency Logo"
              />
            )}
            {depInfo.name && (
              <div style={{ flexDirection: 'column' }}>
                <div
                  className="titleText headerText"
                  style={{
                    marginLeft: '6px',
                    marginRight: '6px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    padding: '0px',
                  }}
                >
                  {depInfo.name}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              padding: '0 0.5rem',
            }}
          >
            <PublicProtocolHeader />
            <SearchableList<ProtocolItem>
              id="searchDropdown"
              options={searchableProtocols}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              iconField={(option) => {
                if (option instanceof CategoryItem)
                  return <FaFolderOpen size={16} />;
                else return <IoDocumentOutline size={16} />;
              }}
              onChange={(option: CategoryItem | ProtocolItem) => {
                handleSelect(option);
              }}
              // multiSelect={true}
              placeholder="Search files..."
              containerStyle={{ width: '100%' }}
              notFoundText="No files found..."
              itemClassName="search-item"
              // itemStyle={{
              //   maxHeight: 'calc(100vh - 200px)',
              //   overflowY: 'auto',
              //   width: '100%',
              // }}
            />
          </div>
          {/* <div className=""> */}
          <ResizableDivs
            containerClassname="publicListContainer"
            panelClassname="public-panel"
            dividerColor="#e0e0e0"
            // minWidth="10%"
            // initialWidths={[30, 70]}
            views={
              !mentionedVisible
                ? [<Folders />, <Protocols />]
                : [<Folders />, <Protocols />, <MentionedProtocols />]
            }
          />
          {/* <div className="publicListRow">
              <h6
                className="categoryText"
                style={{
                  color: '#616161',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                Folders
              </h6>
              <ViewportList items={database ? database.categories : []}>
                {(category: CategoryItem, index: number) => {
                  let isSelected = false;
                  const keychain = database?.keychains.find(
                    (keychain) => keychain.uid === category.keychainID
                  );
                  if (
                    (selectedItem as ProtocolItem).parent != null &&
                    (selectedItem as ProtocolItem).parent.uid === category.uid
                  )
                    isSelected = true;
                  else if ((selectedItem as CategoryItem).uid === category.uid)
                    isSelected = true;
                  return (
                    <div
                      onClick={(e) => {
                        handleSelect(category);
                      }}
                      key={index}
                      className={`listItemContainer ${isSelected ? 'selected' : ''}`}
                    >
                      {keychain && !keychain.isUnlocked ? (
                        <FaLock size={16} />
                      ) : keychain && keychain.isUnlocked ? (
                        <FaLockOpen size={16} />
                      ) : (
                        <FaFolderOpen size={16} />
                      )}
                      <h6 className="categoryText">{category.name}</h6>
                      <IoChevronForward size={16} />
                    </div>
                  );
                }}
              </ViewportList>
            </div>
            <div className="publicListRow">
              <h6
                className="categoryText"
                style={{
                  color: '#616161',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                Protocols
              </h6>
              <ViewportList
                items={
                  selectedItem
                    ? (selectedItem as ProtocolItem).parent != null
                      ? (selectedItem as ProtocolItem).parent.protocols
                      : keychain && !keychain.isUnlocked
                        ? []
                        : (selectedItem as CategoryItem).protocols
                    : []
                }
              >
                {(protocol: ProtocolItem, index: number) => {
                  let isSelected = false;
                  if ((selectedItem as ProtocolItem).uid === protocol.uid)
                    isSelected = true;
                  const keychain = database?.keychains.find(
                    (keychain) => keychain.uid === protocol.keychainID
                  );
                  return (
                    <div
                      onClick={(e) => {
                        handleSelect(protocol);
                      }}
                      key={index}
                      className={`listItemContainer ${isSelected ? 'selected' : ''}`}
                    >
                      {keychain && !keychain.isUnlocked ? (
                        <FaLock size={16} />
                      ) : keychain && keychain.isUnlocked ? (
                        <FaLockOpen size={16} />
                      ) : (
                        <IoDocumentOutline size={16} />
                      )}
                      <h6 className="categoryText">{protocol.name}</h6>
                      <IoChevronForward size={16} />
                    </div>
                  );
                }}
              </ViewportList>
            </div>


            {/*<div
              className={`publicListRow ${
                selectedItem &&
                (selectedItem as ProtocolItem).parent != null &&
                (selectedItem as ProtocolItem).pairedProtocols.length !== 0
                  ? keychain == null || keychain.isUnlocked
                    ? ''
                    : 'hidden'
                  : 'hidden'
              }`}
            >
              <h6
                className="categoryText"
                style={{
                  color: '#616161',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                Mentioned Protocols
              </h6>
              <ViewportList
                items={
                  selectedItem && (selectedItem as ProtocolItem).pairedProtocols
                    ? (selectedItem as ProtocolItem).pairedProtocols
                    : []
                }
              >
                {(protocol: ProtocolItem, index: number) => {
                  let isSelected = false;
                  if ((selectedItem as ProtocolItem).uid === protocol.uid)
                    isSelected = true;
                  return (
                    <div
                      onClick={(e) => {
                        handleSelect(protocol);
                      }}
                      key={index}
                      className={`listItemContainer ${isSelected ? 'selected' : ''}`}
                    >
                      <IoDocumentOutline size={16} />
                      <h6 className="categoryText">{protocol.name}</h6>
                      <IoChevronForward size={16} />
                    </div>
                  );
                }}
              </ViewportList>
            </div> */}
          {/* </div> */}
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            // padding: '1rem',
          }}
        >
          {keychain && !keychain.isUnlocked ? (
            <div className="locked-protocol-screen-overlay">
              <div className="locked-message-box">
                <h6 className="locked-message-title">
                  {selectedItem?.name} is locked
                </h6>
                <div
                  className="locked-message-button"
                  onClick={() => {
                    setIsUnlockVisible(true);
                  }}
                >
                  <FaLockOpen
                    size={16}
                    style={{
                      marginRight: '0.5rem',
                      marginTop: '-6px',
                    }}
                  />
                  Unlock
                </div>
                <h6 className="locked-message-text">
                  This{' '}
                  {(selectedItem as ProtocolItem).parent
                    ? 'protocol'
                    : 'folder'}{' '}
                  has been locked by {department?.name}. Please request the pin
                  from the department to unlock this{' '}
                  {(selectedItem as ProtocolItem).parent
                    ? 'protocol'
                    : 'folder'}
                  .
                </h6>
              </div>
            </div>
          ) : pdfUrl ? (
            <PublicPDFViewer pdf={pdfUrl} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : department == null ? (
    <SplashScreen />
  ) : isValidURL === false ? (
    <div className="public-screen-container">
      <PublicNavbar isProtocolPage={false} />
      <div className="public-container">
        <Component404 />
      </div>
    </div>
  ) : (
    <div className="public-screen-container"></div>
  );
};

export default PublicPdfScreen;
