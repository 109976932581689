import React, { useEffect, useState } from 'react';
import { FaAmbulance } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import { IoPeopleSharp } from 'react-icons/io5';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  deleteDepartment,
  loadDatabase,
} from '../../../data/AmplifyDB';
import { User } from '../../../models';
import { UserType } from '../../../models';
import ProtocolHeader from '../protocol/ProtocolHeader';
import ReviewSideOut from '../../components/SideOut/reviewSideOut/ReviewSideOut';
import { handleGetDepartment } from '../../../store/actions';
import { useDispatch } from 'react-redux';
import { GiElectric } from 'react-icons/gi';
import { SiEquinixmetal } from 'react-icons/si';
import { RiContactsBook2Fill } from 'react-icons/ri';
import {
  FaBoltLightning,
  FaChevronDown,
  FaChevronRight,
  FaKey,
  FaSyringe,
  FaWeightHanging,
} from 'react-icons/fa6';
import { globals } from '../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import MedicationSubItem from '../../../data/model/MedicationSubItem';

const DatabasePage = (props: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const dbState = useSelector((state: any) => state);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.protocol.departmentItem
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);

  const [isCopied, setIsCopied] = useState<string | null>(null);

  const [basicList, setBasicList] = useState<any[]>([]);
  const [advancedList, setAdvancedList] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string[]>(['basic']);

  useEffect(() => {
    let count = 0;
    let db: DatabaseResponse = database;
    for (let i = 0; i < db.medications.length; i++) {
      let subMeds = db.medications[i].medicationSubItems;
      for (let j = 0; j < subMeds.length; j++) {
        let dose: MedicationSubItem = subMeds[j];
        if (dose.nemsisRoutes.length > 0) count++;
      }
    }
    if (globals.debug)
      console.log('Count of doses with nemsis routes: ' + count);
  }, []);

  const loadDepartmentData = async () => {
    const db: DatabaseResponse = database;
    let basic: any[] = [
      { name: 'Protocols', data: db.protocols, icon: <Io5.IoDocuments /> },
      { name: 'Medications', data: db.medications, icon: <FaSyringe /> },
      { name: 'Infusions', data: db.infusions, icon: <Io5.IoWater /> },
      { name: 'Electricals', data: db.electrical, icon: <FaBoltLightning /> },
      { name: 'Equipment', data: db.equipment, icon: <Io5.IoMedkit /> },
    ];
    let advanced: any[] = [
      { name: 'Vitals', data: db.vitals, icon: <Io5.IoHeart /> },
      { name: 'Checklists', data: db.checklists, icon: <Io5.IoDocumentText /> },
      { name: 'Contacts', data: db.contacts, icon: <RiContactsBook2Fill /> },
      { name: 'Keychains', data: db.keychains, icon: <FaKey /> },
    ];
    if (!department.isSoftwareOnly)
      advanced = [
        ...advanced,
        { name: 'Ambulances', data: db.ambulances, icon: <FaAmbulance /> },
        {
          name: 'Subtractable Weights',
          data: db.weightObjects,
          icon: <FaWeightHanging />,
        },
      ];

    /* Force the users as the last item */
    advanced = [
      ...advanced,
      {
        name: 'Users',
        data: db.users,
        icon: <IoPeopleSharp />,
        detail: calculateUserDetails(db.users),
      },
    ];
    setBasicList(basic);
    setAdvancedList(advanced);
  };

  const calculateUserDetails = (users: any) => {
    const adminUsers = users.filter(
      (user: any) => user.type === UserType.ADMIN
    ).length;
    const userUsers = users.filter(
      (user: any) => user.type === UserType.USER
    ).length;
    const deptUsers = users.filter(
      (user: any) => user.type === UserType.DEPT
    ).length;
    const depAdminUsers = users.filter(
      (user: any) => user.type === UserType.DEPT_ADMIN
    ).length;
    return { adminUsers, userUsers, deptUsers, depAdminUsers };
  };

  useEffect(() => {
    loadDepartmentData();
  }, [department]);

  const handleBack = () => {
    navigate('/database');
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm(
      `Are you want to delete department ${department.name}?`
    );

    if (isConfirmed) {
      // Execute the action
      const response = await deleteDepartment(department);
      if (response.type === ResponseType.Success) navigate('/database');
      else {
        let error = response.data;
        const isConfirmed = window.confirm(
          `Department ${department.name} deletion failed:\n${error}`
        );
      }
    } else {
      if (globals.debug) console.log('Action cancelled.');
    }
  };

  const handleDepartmentClick = (item: any) => {
    let name = (item.name as string).replaceAll(' ', '-').toLowerCase();
    navigate(`/database/list-${name}`, {
      state: { department: department, data: item.data, database: database },
    });
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const reloadDatabase = async () => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    if (globals.debug) console.log('loading database');
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    }
  };

  const Badge = ({ text, type }: any) => {
    const badgeStyle = {
      display: 'inline-block',
      backgroundColor:
        type === 'admin'
          ? '#F2A7B6'
          : type === 'dept'
            ? '#CADAE9'
            : type === 'deptAdmin'
              ? '#FDCDB9'
              : '#C3DBB0',
      color: '#000',
      fontWeight: 600,
      padding: '3px 8px',
      fontSize: '12px',
      borderRadius: '15px',
      margin: '2px',
    };

    return <div style={badgeStyle}>{text}</div>;
  };

  return (
    <div className="screen-container">
      {reviewSideOutVisible && (
        <ReviewSideOut
          department={database.department}
          isVisible={reviewSideOutVisible} // Change 'visible' to 'isVisible'
          handleClose={() => {
            setReviewSideOutVisible(false);
          }}
          setVisible={setReviewSideOutVisible}
          handleAdd={() => {
            setReviewSideOutVisible(false);
          }}
          onPublish={() => {
            setReviewSideOutVisible(false);
            reloadDatabase();
            // handleCancel();
          }}
        />
      )}

      <ProtocolHeader
        name={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={true}
        isReviewActive={true}
        handleReview={() => {
          setReviewSideOutVisible(true);
        }}
        type={'protocol'}
        descriptionTitle={user.type === 'ADMIN' ? 'Department ID: ' : ''}
        description={user.type === 'ADMIN' ? '' + department.id : ''}
        isCopyDescription={true}
        customDescription={
          user.type === 'ADMIN' ? (
            <h6
              className={`headerTextLight`}
              style={{
                fontWeight: '400',
                color: '#616161',
                marginRight: '2rem',
              }}
            >
              User ID:
              {user.id}
              <span>
                {isCopied && isCopied === user.id ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size="1rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(user.id, e)}
                  />
                )}
              </span>
            </h6>
          ) : undefined
        }
      />

      {/* <div className="grid-container">
        {basicList.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleDepartmentClick(item)}
            >
              <div className="item-name">{item.name}</div>
              {item.icon}
              {item.name === 'Users' ? (
                <div className="user-details">
                  {user.type === 'ADMIN' && (
                    <Badge
                      text={`${item.detail.adminUsers} Admin`}
                      type="admin"
                    />
                  )}
                  {/* <Badge text={`${item.detail.deptUsers} Dept`} type="dept" /> 
                  <Badge
                    text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                    type="deptAdmin"
                  />
                  <Badge text={`${item.detail.deptUsers} Dept`} type="dept" />
                  <Badge text={`${item.detail.userUsers} Users`} type="user" />
                </div>
              ) : (
                <div className="item-count">{item.data.length} items</div>
              )}
            </div>
          );
        })}
      </div> */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // flex: 1,
            width: '100%',
            // padding: '10px',
            // marginTop: '.25rem',
            // marginBottom: '.25rem',
          }}
        >
          <div
            className={`border hover-raise-elevation ${
              expanded.includes('basic') ? 'expanded' : ''
            }`}
            style={{
              display: 'flex',
              width: '100%',
              padding: '10px',
              background: expanded.includes('basic') ? '#E0EADD' : '#FFF',
            }}
            onClick={() => {
              if (expanded.includes('basic')) {
                setExpanded(expanded.filter((item) => item !== 'basic'));
              } else {
                setExpanded([...expanded, 'basic']);
              }
            }}
          >
            {expanded.includes('basic') ? (
              <FaChevronDown size={14} color={'#00534C'} />
            ) : (
              <FaChevronRight size={14} color={'#a0a0a0'} />
            )}
            <span
              className="ketamine-general-label no-select"
              style={{ flex: 1, fontWeight: '700' }}
            >
              Basic Items
            </span>
            <span
              className="ketamine-general-label no-select"
              style={{ marginRight: '10px', fontWeight: '500' }}
            >
              {basicList.length} items
            </span>
          </div>
          {expanded.includes('basic') && (
            <div className="grid-container">
              {basicList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="grid-item"
                    onClick={() => handleDepartmentClick(item)}
                  >
                    <div className="item-name">{item.name}</div>
                    {item.icon}
                    {item.name === 'Users' ? (
                      <div className="user-details">
                        {user.type === 'ADMIN' && (
                          <Badge
                            text={`${item.detail.adminUsers} Admin`}
                            type="admin"
                          />
                        )}
                        {/* <Badge text={`${item.detail.deptUsers} Dept`} type="dept" /> */}
                        <Badge
                          text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                          type="deptAdmin"
                        />
                        <Badge
                          text={`${item.detail.deptUsers} Dept`}
                          type="dept"
                        />
                        <Badge
                          text={`${item.detail.userUsers} Users`}
                          type="user"
                        />
                      </div>
                    ) : (
                      <div className="item-count">{item.data.length} items</div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // flex: 1,
            width: '100%',
            // padding: '10px',
            // marginTop: '.25rem',
            // marginBottom: '.25rem',
          }}
        >
          <div
            className={`border hover-raise-elevation ${
              expanded.includes('advanced') ? 'expanded' : ''
            }`}
            style={{
              display: 'flex',
              width: '100%',
              padding: '10px',
              background: expanded.includes('advanced') ? '#E0EADD' : '#FFF',
            }}
            onClick={() => {
              if (expanded.includes('advanced')) {
                setExpanded(expanded.filter((item) => item !== 'advanced'));
              } else {
                setExpanded([...expanded, 'advanced']);
              }
            }}
          >
            {expanded.includes('advanced') ? (
              <FaChevronDown size={14} color={'#00534C'} />
            ) : (
              <FaChevronRight size={14} color={'#a0a0a0'} />
            )}
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '700' }}
            >
              Advanced Items
            </span>
            <span
              className="ketamine-general-label"
              style={{ marginRight: '10px', fontWeight: '500' }}
            >
              {advancedList.length} items
            </span>
          </div>
          {expanded.includes('advanced') && (
            <div className="grid-container">
              {advancedList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="grid-item"
                    onClick={() => handleDepartmentClick(item)}
                  >
                    <div className="item-name">{item.name}</div>
                    {item.icon}
                    {item.name === 'Users' ? (
                      <div className="user-details">
                        {user.type === 'ADMIN' && (
                          <Badge
                            text={`${item.detail.adminUsers} Admin`}
                            type="admin"
                          />
                        )}
                        {/* <Badge text={`${item.detail.deptUsers} Dept`} type="dept" /> */}
                        <Badge
                          text={`${item.detail.depAdminUsers}${user.type === 'ADMIN' ? ' Dept' : ''} Admin`}
                          type="deptAdmin"
                        />
                        <Badge
                          text={`${item.detail.deptUsers} Dept`}
                          type="dept"
                        />
                        <Badge
                          text={`${item.detail.userUsers} Users`}
                          type="user"
                        />
                      </div>
                    ) : (
                      <div className="item-count">{item.data.length} items</div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DatabasePage;
