import Component404 from '../components/404/Component404';

const Page404 = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Component404 />
    </div>
  );
};

export default Page404;
