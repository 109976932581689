import React from 'react';
import { IoChevronDown, IoChevronForward } from 'react-icons/io5';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiPencil } from 'react-icons/bi';
import { List, ListItemButton, ListItemText } from '@mui/material';

type item = {
  value: any;
  label: string;
  icon?: any;
};

interface DropdownProps {
  title: string;
  titleIcon?: any;
  options: item[];
  onChange: (item: any) => void;
  style?: React.CSSProperties;
  buttonColor?: string;
  textColor?: string;
}

/* 09-28-23 Arul: Created the global Accordion component to display the protocol list  */
const ButtonDropdown: React.FC<DropdownProps> = (props) => {
  const { title, titleIcon, options, onChange, style, buttonColor, textColor } =
    props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(options[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className="secondary-button-small-border btn-rightMargin"
      style={{
        margin: '0px 8px 0px 0',
        padding: '0px',
        borderRadius: '6px',
      }}
    >
      <List
        // className="secondary-button-small-border"
        component="nav"
        onClick={handleClickListItem}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '6px',
        }}
        // aria-label="Device settings"
        // sx={{ bgcolor: 'background.paper' }}
      >
        {titleIcon}
        {title}
        {/* <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="When device is locked"
            secondary={options[selectedIndex].label}
          />
        </ListItemButton> */}
      </List>
      <Menu
        // id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'lock-button',
        //   role: 'listbox',
        // }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            // disabled={index === 0}
            // selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            style={{
              fontFamily: 'Titillium Web, sans-serif',
              fontWeight: '500',
              fontSize: '14px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              {option.icon}
            </div>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ButtonDropdown;
