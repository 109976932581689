import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

interface FullScreenModalProps {
  isVisible: boolean;
  objURLs: string[];
  files: File[];
  onClose: () => void;
  startIndex?: number;
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  isVisible,
  objURLs,
  files,
  onClose,
  startIndex,
}) => {
  const [currentIndex, setCurrentIndex] = useState(
    startIndex && startIndex >= 0 && startIndex < files.length ? startIndex : 0
  );

  useEffect(() => {
    setCurrentIndex(
      startIndex && startIndex >= 0 && startIndex < files.length
        ? startIndex
        : 0
    );
  }, [startIndex]);

  if (!isVisible) return null;

  const handleNext = () => {
    if (currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const renderContent = (file: File, objURL: string) => {
    const fileType = file.type.split('/')[0];

    switch (fileType) {
      case 'image':
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <img
              src={objURL}
              alt={file.name}
              style={{ width: 'auto', height: '100%' }}
            />
            ;
          </div>
        );
      case 'video':
        return (
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'auto',
              boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
              borderRadius: '10px',
              backgroundColor: '#000',
            }}
          >
            <video
              src={objURL}
              controls
              style={{ width: '100%', height: '100%' }}
              autoPlay={true}
            />
          </div>
        );
      case 'application':
        return file.type === 'application/pdf' ? (
          <embed
            src={objURL}
            type="application/pdf"
            style={{ width: '100%', height: '100%' }}
          />
        ) : null;
      default:
        return <p>Unsupported file type</p>;
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {currentIndex !== 0 && (
        <FaChevronLeft
          onClick={handlePrev}
          size={40}
          className={'hoverIconGrey'}
        />
      )}
      <div
        style={{
          position: 'relative',
          width: '90%',
          height: '90%',
          overflow: 'auto',
        }}
      >
        {renderContent(files[currentIndex], objURLs[currentIndex])}
      </div>
      <FaTimes
        className="hoverIconGrey"
        onClick={onClose}
        size={40}
        style={{ position: 'absolute', top: 30, right: 30 }}
      />
      {currentIndex + 1 < files.length && (
        <FaChevronRight
          onClick={handleNext}
          size={40}
          className={'hoverIconGrey'}
        />
      )}
    </div>
  );
};

export default FullScreenModal;
