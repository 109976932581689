import React, { useEffect, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { ViewportList } from 'react-viewport-list';
import { getFormattedDate } from '../../../_global/common/Utils';
import { ProgressStatus } from '../../../../API';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../../components/Search/SearchBar';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Protocol, User } from '../../../../models';
import { DatabaseResponse } from '../../../../data/AmplifyDB';

const ListArchiveProtocols = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: protocols, department, database } = state;

  const db: DatabaseResponse = database;

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filteredProtocols, setFilteredProtocols] = useState(protocols);

  useEffect(() => {
    const filtered = protocols.filter(
      (protocol: any) =>
        protocol.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (protocol.modifiedBy &&
          protocol.modifiedBy.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredProtocols(filtered);
  }, [searchQuery, protocols]);

  const handleItemClick = (protocol: ProtocolItem) => {
    // const state = {
    //   selectedProtocol: protocol,
    //   editMode: false,
    //   backPage: 'list-protocols',
    //   search: { searchQuery },
    // };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '1fr 4fr 2fr 2fr' +
      (department.subDeps && department.subDeps.length > 0 ? ' 2fr ' : '') +
      ' 1fr',
  };

  const handleBack = () => {
    navigate(`/archive`, { state: department });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={'Protocols: ' + protocols.length + ' items'}
        status={ProgressStatus.ARCHIVE}
        page={department.name}
        rightSideBtn={'edit'}
        type={'protocol'}
      />
      <Row>
        <Col sm={12}>
          <SearchBar
            value={searchQuery}
            containerStyle={{ width: '100%' }}
            onChange={(searchTerm: string) => setSearchQuery(searchTerm)}
            placeholder={'Search Protocols...'}
          />
        </Col>
      </Row>

      <div className="accordion-container">
        <div style={{ borderBottom: '1px solid #ccc' }}>
          <Accordion>
            <div style={rowStyle} className="departmentItemNoHover">
              <h6 className="departmentItemText">Version</h6>
              <h6 className="departmentItemText">Name</h6>
              <h6 className="departmentItemText">Archive Date</h6>
              <h6 className="departmentItemText">Archived By</h6>
              {department.subDeps && department.subDeps.length > 0 && (
                <h6 className="departmentItemText">Department Subs</h6>
              )}
            </div>
          </Accordion>
        </div>
        <div className="list-container">
          <ViewportList items={filteredProtocols}>
            {(item: Protocol, index) => {
              let id = item.modifiedBy;
              if (id == null) id = item.createdBy;
              let username = 'Hinckley Medical';
              if (id) {
                let user = db.users.find((user: User) => user.id === id);
                if (user) username = user.firstName + ' ' + user.lastName;
              }
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => {}}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">{item.version}</h6>
                    <h6 className="departmentItemText">{item.name}</h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.updatedAt ? item?.updatedAt : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    {department.subDeps && department.subDeps.length > 0 && (
                      <h6
                        className="departmentItemText"
                        style={{ width: '100%', textAlign: 'center' }}
                      >
                        {item.pairedDepIDs?.length +
                          '/' +
                          department.subDeps.length}
                      </h6>
                    )}
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListArchiveProtocols;
