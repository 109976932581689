import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputTextarea } from 'primereact/inputtextarea';
import { FaTimes } from 'react-icons/fa';
import { User, WeightObject } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  createWeightObject,
  deleteWeightObject,
  editWeightObject,
  fetchUser,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { handleGetDepartment } from '../../../../store/actions';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import { Col, Row } from 'react-bootstrap';
import SearchableDropdown from '../../../components/SearchableDropdown';

const WeightObjectCreateEdit = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [list, setList] = useState<WeightObject[]>(database.weightObjects);
  const department: DepartmentItem = database.department;
  const parmWeightObj: WeightObject = state.value;
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>(
    department.subDeps || []
  );
  const [isDelete, setIsDelete] = useState(false);
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [modifiedBy, setModifiedBy] = useState<User>();

  const [message, setMessage] = useState({
    title: '',
    description: '',
    errorCode: '',
  });

  const formik = useFormik({
    initialValues: {
      id: parmWeightObj ? parmWeightObj.id : '',
      name: parmWeightObj ? parmWeightObj.name : '',
      weightLbs: parmWeightObj ? parmWeightObj.weight + '' : '',
      weightKg: parmWeightObj
        ? (parmWeightObj.weight / 2.205).toFixed(1) + ''
        : '',
      createdBy: parmWeightObj ? parmWeightObj.createdBy : '',
      modifiedBy: parmWeightObj ? parmWeightObj.modifiedBy : '',
      version: parmWeightObj ? parmWeightObj.version : 'v1.0.0',
      departmentID: department.id,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      weightLbs: Yup.number().required(
        'Weight is required and must be a number'
      ),
      weightKg: Yup.number().required(
        'Weight is required and must be a number'
      ),
    }),
    onSubmit: async (values) => {
      let result;
      /* Validate the values */
      if (
        formik.errors.name ||
        formik.errors.weightLbs ||
        formik.errors.weightKg
      )
        return;
      if (parmWeightObj == null)
        result = await createWeightObject(values, user, pairedDeps);
      else result = await editWeightObject(values, user, pairedDeps);
      if (result.type === ResponseType.Success) {
        let obj = result.data as WeightObject;
        console.log(
          'WeightObject',
          parmWeightObj ? ' editted' : ' created',
          obj
        );

        await reloadDatabase();

        navigate(`/database/list-subtractable-weights`, {
          state: { department: department, database },
        });
      } else {
        setMessage({
          title:
            'Error ' +
            (parmWeightObj ? 'editting' : 'creating') +
            ' subtractable weight',

          description: 'Error: ' + result.data,
          errorCode: '',
        });
      }
    },
  });

  /**
   * Find all the sub-departments that this weight object is paired with and set the pairedDeps state
   */
  useEffect(() => {
    const findUser = async () => {
      let id = parmWeightObj.modifiedBy
        ? parmWeightObj.modifiedBy
        : parmWeightObj.createdBy;
      let result = await fetchUser(id);
      if (result.type === ResponseType.Success) {
        setModifiedBy(result.data);
      }
    };
    if (parmWeightObj && parmWeightObj.pairedDepIDs) {
      let deps: DepartmentItem[] = [];
      for (let id of parmWeightObj.pairedDepIDs) {
        let dep = departmentList.find((d: DepartmentItem) => d.id === id);
        if (dep && !deps.includes(dep)) deps.push(dep);
      }
      setPairedDeps(deps);
    }
    if (parmWeightObj) findUser();
  }, [parmWeightObj]);

  /**
   * Whenever the department or pairedDeps change, update the departmentList state to reflect the changes
   */
  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, pairedDeps]);

  useEffect(() => {
    if (parmWeightObj && parmWeightObj.pairedDepIDs) {
      let l: DepartmentItem[] = [];
      parmWeightObj.pairedDepIDs.forEach((id: string) => {
        let dep = department.subDeps?.find((d: DepartmentItem) => d.id === id);
        if (dep) l.push(dep);
      });
      setPairedDeps(l);
    }
  }, [parmWeightObj]);

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  useEffect(() => {
    let l = [...database.weightObjects];
    setList(l);
  }, [parmWeightObj, database]);

  const handleBack = () => {
    navigate(`/database/list-subtractable-weights`, {
      state: { department: department, data: database.weightObjects, database },
    });
  };

  const Badge = ({ text }: any) => {
    const badgeStyle = {
      display: 'inline-block',
      padding: '2px 10px',
      borderRadius: '5px',
      backgroundColor: '#ADD8E6',
      color: '#0d4d8e',
      fontSize: '12px',
      fontWeight: 'bold',
      marginLeft: '10px',
    };
    return <span style={badgeStyle}>{text}</span>;
  };

  const RenderWeightObject = ({ item, index }: any) => {
    let isThis = parmWeightObj && item.id === parmWeightObj.id;
    let obj = item as WeightObject;
    return (
      <div key={index}>
        <div
          className="departmentItem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            padding: '10px 10px',
            borderBottom:
              index !== list.length - 1 ? '1px solid #E0E0E0' : 'none',
            borderTopLeftRadius: index === 0 ? '6px' : '0px',
            borderTopRightRadius: index === 0 ? '6px' : '0px',
            borderBottomLeftRadius: index === list.length - 1 ? '6px' : '0px',
            borderBottomRightRadius: index === list.length - 1 ? '6px' : '0px',
            cursor: 'pointer',
          }}
        >
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0 }}
          >
            {index + 1}: {obj.name}
            {isThis ? <Badge text="This" /> : ''}
          </div>
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0, fontWeight: '500' }}
          >
            {obj.weight} lbs ({(obj.weight / 2.205).toFixed(1)} kg)
          </div>
        </div>
      </div>
    );
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const handleDelete = async () => {
    const result = await deleteWeightObject(parmWeightObj);
    if (result.type === ResponseType.Success) {
      await reloadDatabase();
      navigate(`/database/list-subtractable-weights`, {
        state: { department: department, database },
      });
    } else if (globals.debug) {
      console.log('Weight Object', ' delete failed', result.data);
    }
  };

  const isSaveValid = useMemo(() => {
    let ids = pairedDeps.map((d: DepartmentItem) => d.id);
    if (parmWeightObj)
      return (
        formik.dirty && formik.isValid && ids !== parmWeightObj.pairedDepIDs
      );
    return formik.dirty && formik.isValid;
  }, [formik.dirty, formik.isValid, parmWeightObj, pairedDeps]);

  return (
    <div className="screen-container">
      {parmWeightObj && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Subtractable Weight?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDelete}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want delete subtractable weight: ' +
            parmWeightObj.name +
            '?'
          }
        />
      )}
      <ConfirmModal
        isVisible={message.title !== ''}
        title={message.title}
        handleClose={() => {
          setMessage({ title: '', description: '', errorCode: '' });
        }}
        handleSubmit={() => {
          setMessage({ title: '', description: '', errorCode: '' });
        }}
        isSingleBtn={true}
        secondaryBtnName="Okay"
        primaryDescription={message.description}
        secondaryDescription={message.errorCode}
      />

      <ProtocolHeader
        name={
          parmWeightObj
            ? 'Edit Subtractable Weight'
            : 'Create Subtractable Weight'
        }
        type={'protocol'}
        page={'Subtractable Weights'}
        isCreateButton={parmWeightObj ? false : true}
        isSaveButton={parmWeightObj ? true : false}
        isDeleteButton={parmWeightObj ? true : false}
        handleDelete={() => {
          setIsDelete(true);
        }}
        isSaveActive={isSaveValid}
        isBackButton={true}
        isDotButton={true}
        isCreateActive={isSaveValid}
        handleCancel={handleBack}
        handleCreate={() => formik.handleSubmit()}
        handleSave={() => formik.handleSubmit()}
        handleCancelEdit={handleBack}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Contact Information</h5>
          {parmWeightObj && (
            <div className="input-container roundBorder">
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'column',
                }}
                className="ketamine-general-label"
              >
                <Row>
                  <Col sm={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Modified By:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {modifiedBy
                          ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                          : 'Hinckley Medical'}
                      </div>
                    </div>
                    {parmWeightObj.version && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>Version:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {parmWeightObj.version}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col sm={6}>
                    {parmWeightObj.updatedAt && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>Last Updated:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {getFormattedDate(parmWeightObj.updatedAt, false)}
                        </div>
                      </div>
                    )}
                    {parmWeightObj.createdAt && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>Created At:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {getFormattedDate(parmWeightObj.createdAt, false)}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              name="name"
              data-testid="name"
              autoFocus
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
            <div className="input-border"></div>
            {formik.touched.name && formik.errors.name && (
              <div className="errorText">{formik.errors.name}</div>
            )}
          </div>

          {/* <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter the phone number (###-###-####) or a desctiption of the
            contact method. (Ex. "Contact Dispatch Center")
          </p> */}
          <Row>
            <Col sm={6}>
              <label htmlFor="weight-lbs" className="ketamine-general-label">
                Weight lbs. <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="weight-lbs"
                  name="weight-lbs"
                  data-testid="weight-lbs"
                  value={formik.values.weightLbs}
                  onChange={(e: any) => {
                    // if (isNaN(n)) n = 0;
                    if (e.target.value == '') {
                      formik.setFieldValue('weightKg', '');
                      formik.setFieldValue('weightLbs', '');
                    } else {
                      formik.setFieldValue('weightLbs', e.target.value + '');
                      let n = Number(e.target.value);
                      if (!isNaN(n))
                        formik.setFieldValue(
                          'weightKg',
                          (n / 2.205).toFixed(1)
                        );
                    }
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.weightLbs && formik.errors.weightLbs && (
                  <div className="errorText">{formik.errors.weightLbs}</div>
                )}
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="weight-kg" className="ketamine-general-label">
                Weight kg. <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="weight-kg"
                  name="weight-kg"
                  data-testid="weight-kg"
                  value={formik.values.weightKg}
                  onChange={(e: any) => {
                    // if (isNaN(n)) n = 0;
                    if (e.target.value == '') {
                      formik.setFieldValue('weightKg', '');
                      formik.setFieldValue('weightLbs', '');
                    } else {
                      formik.setFieldValue('weightKg', e.target.value + '');
                      let n = Number(e.target.value);
                      if (!isNaN(n))
                        formik.setFieldValue(
                          'weightLbs',
                          (n * 2.205).toFixed(1)
                        );
                    }
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.weightKg && formik.errors.weightKg && (
                  <div className="errorText">{formik.errors.weightKg}</div>
                )}
              </div>
            </Col>
          </Row>
          {department.subDeps && department.subDeps.length > 0 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {pairedDeps.length} /{' '}
                  {department.subDeps.length}
                  <span
                    onClick={() => {
                      if (department.subDeps) setPairedDeps(department.subDeps);
                    }}
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  // overflowY: 'auto',
                  // maxHeight: '200px',
                  border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => handleRemoveDepartment(item, e)}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">{department.name} Contacts</h5>
          {list.length === 0 && (
            <div className="container">
              <div className="text" style={{ color: '#616161' }}>
                No Contacts Created...
              </div>
            </div>
          )}
          {list.length > 0 && (
            <div className="list-container">
              <ViewportList items={list}>
                {(item: WeightObject, index: any) => {
                  return (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      <RenderWeightObject item={item} index={index} />
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeightObjectCreateEdit;
