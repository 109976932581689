import React, { useEffect, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Col, Row } from 'react-grid-system';
import { FaCaretDown, FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User, UserStatus } from '../../../../models';
import {
  DatabaseResponse,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import { UserType } from '../../../../models';
import { getFormattedDate, toTitleCase } from '../../../_global/common/Utils';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown/MultiSelectDropdown';
import { set } from 'lodash';
import SearchBar from '../../../components/Search/SearchBar';
import Dropdown from '../../../components/Dropdown/Dropdown';
import UserStatusUI from '../../../components/UserStatus/UserStatus';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListUsers = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const searchState = state ? state.searchState : undefined;
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const currentUser: User = useSelector((state: any) => state?.user);
  const [allUsers, setAllUsers] = useState<User[]>(
    currentUser.type === 'ADMIN'
      ? database.users
      : database.users.filter((item: User) => item.type !== UserType.ADMIN)
  );
  const [allUserBytype, setAllUserByType] = useState<User[]>(
    database.users.filter(
      (item: User) => item.status == null || item.status === UserStatus.ACTIVE
    )
  );
  const [filteredAllUsers, setFilteredAllUsers] =
    useState<User[]>(allUserBytype);

  const [page, setPage] = useState({
    index: 0,
    usersPerPage: 100,
    totalPages: 0,
    total: 0,
  });

  const [departmentOptions, setDepartmentOptions] = useState<DepartmentItem[]>(
    department.subDeps ? department.subDeps : []
  );

  const [sort, setSort] = useState<string>(
    searchState ? searchState.sort : 'modified_desc'
  );
  const [departmentFilters, setDepartmentFilters] = useState<DepartmentItem[]>(
    searchState ? searchState.departmentFilters : []
  );
  const [searchQuery, setSearchQuery] = useState(
    searchState ? searchState.searchQuery : ''
  );

  const [userStats, setUserStats] = useState({
    total: 0,
    createdToday: 0,
    createdThisWeek: 0,
    createdThisMonth: 0,
    admin: 0,
    deptAdmin: 0,
    user: 0,
    dept: 0,
    active: 0,
    disabled: 0,
    deleted: 0,
  });

  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.ACTIVE);
  const [userStatusTypes, setUserStatusTypes] = useState([
    { label: 'Active', value: UserStatus.ACTIVE },
    { label: 'Disabled', value: UserStatus.SUSPENDED },
    // { label: 'Deleted', value: UserStatus.DELETED },
  ]);
  const [list, setList] = useState<any[]>([]);

  const reloadDatabase = async () => {
    const response = await loadDatabase(department);
    if (response.type === ResponseType.Success) {
      const database: DatabaseResponse = response.data;
      setDatabase(database);
      setDepartmentOptions(
        database.department.subDeps ? database.department.subDeps : []
      );
      let users: User[] = database.users;

      if (currentUser.type !== UserType.ADMIN)
        users = users.filter((item: User) => item.type !== UserType.ADMIN);
      setAllUsers(users);
      users = users.filter(
        (item: User) => item.status == null || item.status === UserStatus.ACTIVE
      );
      setFilteredAllUsers(users);

      setPage({
        index: 0,
        usersPerPage: 100,
        totalPages: Math.ceil(users.length / 100),
        total: users.length,
      });
      handleSort(users);

      //Return page sizes of 100 items each

      // users.sort((a, b) => {
      //     return a.firstName.localeCompare(b.firstName);
      // })
      // setList(users);
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, [department]);

  useEffect(() => {
    const totalActive = allUsers.filter(
      (item: User) => item.status == null || item.status === UserStatus.ACTIVE
    ).length;
    const totalDisabled = allUsers.filter(
      (item: User) => item.status === UserStatus.SUSPENDED
    ).length;
    const totalDeleted = allUsers.filter(
      (item: User) => item.status === UserStatus.DELETED
    ).length;
    const totalNotifications = allUsers.filter(
      (item: User) => item.notificationTokens && item.notificationTokens.length
    ).length;
    const total = allUserBytype.length;
    const createdToday = allUserBytype.filter((item: User) => {
      if (!item.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(item.createdAt);
      return (
        createdAt.getDate() === today.getDate() &&
        createdAt.getMonth() === today.getMonth() &&
        createdAt.getFullYear() === today.getFullYear()
      );
    }).length;
    const createdThisWeek = allUserBytype.filter((item: User) => {
      if (!item.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(item.createdAt);
      const diff = today.getTime() - createdAt.getTime();
      return diff <= 7 * 24 * 60 * 60 * 1000;
    }).length;
    const createdThisMonth = allUserBytype.filter((item: User) => {
      if (!item.createdAt) return false;
      const today = new Date();
      const createdAt = new Date(item.createdAt);
      const diff = today.getTime() - createdAt.getTime();
      return diff <= 30 * 24 * 60 * 60 * 1000;
    }).length;
    const admin = allUserBytype.filter((item: User) => {
      return item.type === UserType.ADMIN;
    }).length;
    const deptAdmin = allUserBytype.filter((item: User) => {
      return item.type === UserType.DEPT_ADMIN;
    }).length;
    const user = allUserBytype.filter((item: User) => {
      return item.type === UserType.USER;
    }).length;
    const dept = allUserBytype.filter((item: User) => {
      return item.type === UserType.DEPT;
    }).length;
    setUserStats({
      total,
      createdToday,
      createdThisWeek,
      createdThisMonth,
      admin,
      deptAdmin,
      user,
      dept,
      active: totalActive,
      disabled: totalDisabled,
      deleted: totalDeleted,
    });
    setUserStatusTypes([
      { label: 'Active: ' + totalActive, value: UserStatus.ACTIVE },
      { label: 'Suspended: ' + totalDisabled, value: UserStatus.SUSPENDED },
      // { label: 'Deleted: ' + totalDeleted, value: UserStatus.DELETED },
    ]);
  }, [allUserBytype]);

  useEffect(() => {
    handleSort();
  }, [sort]);

  const handleStatusUpdate = async (status: UserStatus) => {
    const userList: User[] = allUsers.filter((item: User) => {
      if (status === UserStatus.ACTIVE)
        return item.status == null || item.status === UserStatus.ACTIVE;
      return item.status === status;
    });

    setAllUserByType(userList);
  };

  const handleSort = (sortList?: User[]) => {
    const sortedList = sortList ? [...sortList] : [...allUserBytype];
    if (sort === 'name_desc') {
      sortedList.sort((a, b) => {
        return a.firstName.localeCompare(b.firstName);
      });
    } else if (sort === 'name_asc') {
      sortedList.sort((a, b) => {
        return b.firstName.localeCompare(a.firstName);
      });
    } else if (sort === 'id') {
      sortedList.sort((a, b) => {
        return a.id.localeCompare(b.id);
      });
    } else if (sort === 'type') {
      sortedList.sort((a, b) => {
        return a.type.localeCompare(b.type);
      });
    } else if (sort === 'modified_desc') {
      sortedList.sort((a, b) => {
        if (!a.updatedAt || !b.updatedAt) return 0;
        const aDate = new Date(a.updatedAt);
        const bDate = new Date(b.updatedAt);
        return bDate.getTime() - aDate.getTime();
      });
    } else if (sort === 'modified_asc') {
      sortedList.sort((a, b) => {
        if (!a.updatedAt || !b.updatedAt) return 0;
        const aDate = new Date(a.updatedAt);
        const bDate = new Date(b.updatedAt);
        return aDate.getTime() - bDate.getTime();
      });
    } else if (sort === 'version_desc') {
      sortedList.sort((a, b) => {
        if (!a.oneDoseVersion && !b.oneDoseVersion) return 0;
        else if (!a.oneDoseVersion) return 1;
        else if (!b.oneDoseVersion) return -1;
        return compareVersions(b.oneDoseVersion, a.oneDoseVersion);
      });
    } else if (sort === 'version_asc') {
      sortedList.sort((a, b) => {
        if (!a.oneDoseVersion || !b.oneDoseVersion) return 0;
        return compareVersions(a.oneDoseVersion, b.oneDoseVersion);
      });
    }
    setFilteredAllUsers([...sortedList]);
    setList(
      sortedList.splice(page.index * page.usersPerPage, page.usersPerPage)
    );
  };

  const compareVersions = (a: string, b: string) => {
    const aVersion = a.split('.');
    const bVersion = b.split('.');
    for (let i = 0; i < aVersion.length; i++) {
      if (parseInt(aVersion[i]) > parseInt(bVersion[i])) return 1;
      if (parseInt(aVersion[i]) < parseInt(bVersion[i])) return -1;
    }
    return 0;
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '1fr 3fr 4fr 4fr 3fr' +
      (currentUser.type === UserType.ADMIN ? ' 3fr' : '') +
      ' 3fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`);
  };

  const handleDepartmentClick = (item: any) => {
    navigate(`/database/edit/user`, {
      state: {
        department: department,
        value: item,
        database: database,
        searchState: { searchQuery, departmentFilters, sort },
      },
    });
  };

  const getUserType = (
    type: UserType | 'ADMIN' | 'DEPT_ADMIN' | 'DEPT' | 'USER'
  ) => {
    switch (type) {
      case UserType.ADMIN:
        return 'Super Admin';
      case UserType.DEPT_ADMIN:
        return 'Admin';
      case UserType.USER:
        return 'User';
      case UserType.DEPT:
        return 'Department';
      default:
        return '';
    }
  };

  const updateDisplayedUsers = () => {
    // Calculate filtered list based on searchQuery and departmentFilters
    let filteredList = allUserBytype.filter((item: User) => {
      if (!searchQuery) return true;
      return (
        item.firstName.toLowerCase().includes(searchQuery) ||
        item.lastName.toLowerCase().includes(searchQuery) ||
        item.cognitoID.toLowerCase().includes(searchQuery)
      );
    });

    if (departmentFilters.length !== 0) {
      filteredList = filteredList.filter((item: User) => {
        if (!item.pairedDepIDs) return true;
        return item.pairedDepIDs.some((id: string) => {
          return departmentFilters.some((dep: DepartmentItem) => {
            return dep.id === id;
          });
        });
      });
    }

    setPage({
      ...page,
      totalPages: Math.ceil(filteredList.length / 100),
      total: filteredList.length,
    });
    handleSort(filteredList);
  };

  useEffect(() => {
    updateDisplayedUsers();
  }, [allUserBytype, page.index]);

  useEffect(() => {
    setPage({
      ...page,
      index: 0,
    });
    updateDisplayedUsers();
  }, [departmentFilters, searchQuery]);

  // Call updateDisplayedUsers whenever there's a change in filters, sort, or pagination state that requires it.

  return (
    <div className="screen-container">
      {/* <div className='headerContainer'>
                <Row>
                    <Col sm={10} style={{alignItems: 'center'}}> 
                        <h6 className='backHeader'><span className='hoverable'  onClick={handleBack}><IoArrowBack size="1.25rem" style={{ marginRight: '5px', cursor: 'pointer' }} />{department.name}</span></h6>
                        <h4 className='headerText'>Users - {userStats.total}</h4>
                        <h6 className='departmentItemText' style={{color: 'grey'}}>Admin: {userStats.admin} - Dept Admin: {userStats.deptAdmin} - User: {userStats.user} - Dept: {userStats.dept}</h6>
                        <h6 className='departmentItemText' style={{color: 'grey'}}>Users Created Today: {userStats.createdToday} - Week: {userStats.createdThisWeek} - Month: {userStats.createdThisMonth}</h6>
                    </Col>
                    <Col sm={2}>
                        {/* {isLoggedIn && 
                        <div className='protocolHeaderButton'>
                            <Button className='primary-button-small' onClick={handleCreate}>+ Create</Button>
                        </div>
                        
                    </Col>
                </Row>

            </div>
            <hr style={{margin: 10}}/> */}
      {/* <div className="fixedHeader"> */}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Users: ' + allUserBytype.length + ' items'}
        page={department.name}
        customDescription={
          <div>
            <h6 className="departmentItemText" style={{ color: 'grey' }}>
              {currentUser.type === UserType.ADMIN
                ? 'Admin: ' + userStats.admin + ' - Dept '
                : ''}
              Admin: {userStats.deptAdmin} - User: {userStats.user} -
              Department: {userStats.dept}
            </h6>
            <h6 className="departmentItemText" style={{ color: 'grey' }}>
              Users Created Today: {userStats.createdToday} - Week:{' '}
              {userStats.createdThisWeek} - Month: {userStats.createdThisMonth}
            </h6>
          </div>
        }
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={false}
        isCreateActive={true}
        handleCreate={() => {}}
        handleEdit={() => {}}
        type={'protocol'}
      />
      {/* </div> */}
      <Row>
        <Col sm={6}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyItems: 'flex-start',
              alignContent: 'flex-start',
            }}
          >
            <Dropdown
              value={userStatus}
              options={userStatusTypes}
              onChange={(e: any) => {
                setUserStatus(e);
                handleStatusUpdate(e);
              }}
              style={{
                marginTop: '-13px',
                marginRight: '16px',
                padding: 0,
              }}
              buttonColor={
                userStatus === UserStatus.ACTIVE
                  ? '#C3DBB0'
                  : userStatus === UserStatus.SUSPENDED
                    ? '#ADD8E6'
                    : '#FFC7CE'
              }
              textColor={
                userStatus === UserStatus.ACTIVE
                  ? '#037F02'
                  : userStatus === UserStatus.SUSPENDED
                    ? '#0d4d8e'
                    : '#DC143C'
              }
            />
            <SearchBar
              containerStyle={{ width: '100%' }}
              value={searchQuery}
              onChange={(searchTerm: string) => {
                setSearchQuery(searchTerm);
              }}
              onSubmit={(searchTerm: string) => {
                updateDisplayedUsers();
              }}
              placeholder={'Search Users...'}
            />
          </div>
        </Col>
        <Col sm={6}>
          {/* Create a next page view */}
          <div
            className="pagination"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: '0px 10px',
            }}
          >
            <div
              className="paginationText"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
              }}
            >
              <h6 className="departmentItemText">
                Showing {page.index * page.usersPerPage + 1} -{' '}
                {page.index * page.usersPerPage + list.length} of {page.total}{' '}
                Users
              </h6>
            </div>

            <div
              className="paginationButtons"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
              }}
            >
              <Button
                className="primary-button-small"
                style={{
                  marginRight: 10,
                }}
                disabled={page.index === 0}
                onClick={() => {
                  if (page.index > 0) {
                    setPage({
                      ...page,
                      index: page.index - 1,
                    });
                    // updateDisplayedUsers();
                  }
                }}
              >
                Previous
              </Button>
              <Button
                className="primary-button-small"
                disabled={page.index >= page.totalPages - 1}
                onClick={() => {
                  if (page.index < page.totalPages - 1) {
                    setPage({
                      ...page,
                      index: page.index + 1,
                    });
                    // updateDisplayedUsers();
                  }
                }}
              >
                Next
              </Button>
            </div>

            {departmentOptions.length > 0 && (
              <MultiSelectDropdown<DepartmentItem>
                title={'Departments'}
                options={departmentOptions}
                initialSelectedItems={departmentFilters}
                labelField={(option: DepartmentItem) => option.name}
                keyField={(option: DepartmentItem) => option.id}
                onSelected={(selected: DepartmentItem[]) => {
                  setDepartmentFilters(selected);
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div
                style={rowStyle}
                className="departmentItemNoHover"
                // onMouseEnter={() => setIsHovered(true)}
                // onMouseLeave={() => setIsHovered(false)}
              >
                <h6 className="departmentItemText">Index</h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('name')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'name_desc') setSort('name_asc');
                    else setSort('name_desc');
                  }}
                >
                  Name
                  <span>
                    {sort === 'name_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                {/* {currentUser.type === UserType.ADMIN && ( */}
                <h6 className="departmentItemText">UID</h6>
                {/* // )} */}
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('modified')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'modified_desc') setSort('modified_asc');
                    else setSort('modified_desc');
                  }}
                >
                  Modified Date
                  <span>
                    {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('type')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => setSort('type')}
                >
                  Type
                  <span>
                    <FaCaretDown />
                  </span>
                </h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('version')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'version_desc') setSort('version_asc');
                    else setSort('version_desc');
                  }}
                >
                  OneDose Version
                  <span>
                    {sort === 'version_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Push Notify</h6>
                <h6 className="departmentItemText">Departments</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={list}>
            {(item: User, index: number) => {
              let isMe = currentUser.id === item.id;
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => handleDepartmentClick(item)}
                >
                  <Accordion>
                    <div
                      style={rowStyle}
                      className="departmentItem"
                      // onMouseEnter={() => setIsHovered(true)}
                      // onMouseLeave={() => setIsHovered(false)}
                    >
                      <h6 className="departmentItemText">
                        {index + page.index * page.usersPerPage + 1}
                      </h6>
                      <h6 className="departmentItemText">
                        {item.firstName} {item.lastName}
                        {isMe ? ' (Me)' : ''}
                        {item.status != null &&
                          item.status !== UserStatus.ACTIVE && (
                            <span>
                              <UserStatusUI
                                status={
                                  item.status ? item.status : UserStatus.ACTIVE
                                }
                                style={{ marginLeft: 5 }}
                              />
                            </span>
                          )}
                      </h6>
                      {/* {currentUser.type === UserType.ADMIN && ( */}
                      <h6 className="departmentItemText">{item.cognitoID}</h6>
                      {/* // )} */}
                      {/* <h6 className='departmentItemText'>{item.id}</h6> */}
                      <h6 className="departmentItemText">
                        {item.updatedAt
                          ? getFormattedDate(item.updatedAt, false)
                          : ''}
                      </h6>
                      <h6 className="departmentItemText">
                        {getUserType(item.type)}
                      </h6>
                      <h6 className="departmentItemText">
                        {item.oneDoseVersion ? 'v' + item.oneDoseVersion : '-'}
                      </h6>
                      <h6 className="departmentItemText">
                        {item.notificationTokens &&
                        item.notificationTokens.length
                          ? 'Active'
                          : '-'}
                      </h6>
                      <h6 className="departmentItemText">
                        {item.pairedDepIDs?.length}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </Accordion>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListUsers;
