import { Contact } from '../../models';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import DepartmentItem from './DepartmentItem';
import ModelItem from './ModelItem';

class ContactItem implements ModelItem {
  TAG: string = 'ContactItem';
  uid: string;
  depID: string;
  fullName: string;
  title: string;
  number: string;
  index: number;
  note: string;
  dbContact: Contact;
  isTitle: boolean;
  pairedDepIDs: string[] | null | undefined;
  pairedDeps: DepartmentItem[] | undefined;

  constructor(contact: Contact, isTitle: boolean) {
    this.uid = contact.id;
    this.depID = contact.departmentID;
    this.fullName = contact.fullName;
    this.title = contact.title ? contact.title : '';
    this.number = contact.number;
    this.index = contact.index;
    this.note = contact.note ? contact.note : '';
    this.pairedDepIDs = contact.pairedDepIDs;
    this.pairedDeps = contact.pairedDepIDs ? [] : undefined;
    this.dbContact = contact;
    this.isTitle = isTitle;
  }
  getAmount(weight: PatientWeight): string {
    throw new Error('Method not implemented.');
  }

  public getUid(): string {
    return this.uid;
  }

  public getDepID(): string {
    return this.depID;
  }

  public getName(): string {
    return this.fullName;
  }

  public getDescription(): string {
    return this.title;
  }

  public equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof ContactItem)) return false;
    let other: ContactItem = obj as ContactItem;
    return this.uid === other.getUid() && this.fullName === other.getName();
  }

  public getContact(): Contact {
    return this.dbContact;
  }

  public getNote(): string {
    return this.note;
  }

  public getTitle(): string {
    return this.title;
  }

  public hasTitle(): boolean {
    return this.isTitle;
  }

  public getNumber(): string {
    return this.number;
  }

  public getIndex(): number {
    return this.index;
  }

  public getPairedDepIDs(): string[] | null | undefined {
    return this.pairedDepIDs;
  }
}

export default ContactItem;
