import React, { Key, useMemo, useState } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import Status from '../ProgressStatus/ProgressStatus';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import KeychainItem from '../../../data/model/KeychainItem';

interface AccordionItemProps {
  value: any;
  onItemClick: any;
  lastIndex?: boolean;
  keychains?: KeychainItem[];
  isPublic?: boolean;
}

/* 09-28-23 Arul: Created the global Accordion component to display the protocol list  */
const AccordionItem: React.FC<AccordionItemProps> = (props) => {
  const { value, lastIndex, onItemClick, keychains } = props;
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const isUnlocked = useMemo(() => {
    if (keychains && value.keychainID) {
      const keychain = keychains.find(
        (keychain) => keychain.uid === value.keychainID
      );
      return keychain?.isUnlocked;
    }
    return null;
  }, [value]);
  return (
    <div
      className={`${!lastIndex ? 'ProtocolItemListPad' : 'ProtocolItemListPadLast'}`}
      onClick={() => {
        onItemClick(value);
      }}
    >
      <div className="protocolItemListContainer">
        {props.isPublic &&
          isUnlocked != null &&
          (isUnlocked ? (
            <FaLockOpen
              size="1.0rem"
              style={{ marginLeft: '1.5%', color: '#939393' }}
            />
          ) : (
            <FaLock
              size="1.0rem"
              style={{ marginLeft: '1.5%', color: '#939393' }}
            />
          ))}
        <h6
          className="ProtocolItemListContent"
          style={{
            margin:
              '0 0 0 ' +
              (props.isPublic && isUnlocked != null
                ? 'calc(2% - 1rem)'
                : '3.5%'),
          }}
        >
          {value.name}
          {value.status !== 'ACTIVE' && <Status status={value.status} />}
        </h6>
        <IoChevronForward size="1.5rem" style={{ marginRight: '10px' }} />
      </div>
    </div>
  );
};

export default AccordionItem;
