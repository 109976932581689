// Note: EditDB for Equipment
// Date: 01/11/2024
// Author: Guruprasad Venkatraman
//...........................................................................
import { DataStore } from '@aws-amplify/datastore';
import { Department, Equipment, EquipmentOption } from '../../models';
import { DatabaseResponse, Response, ResponseType } from '../AmplifyDB';
import EquipmentItem from '../model/EquipmentItem';
import { ProgressStatus } from '../../API';
import DepartmentItem from '../model/DepartmentItem';
import { globals } from '../../ui/_global/common/Utils';
import ProtocolItem from '../model/ProtocolItem';
import { validatePointerID } from './ProtocolDB';
import { getEquipment } from '../../graphql/queries';
import { graphqlOperation } from 'aws-amplify';
import { executeQuery } from '../GraphQL_API';

export type EquipmentDB = {
  name: string;
  title: string;
  note: string;
  warning: string;
  instruction: string;
  optionItems: EquipmentOption[];
  departmentID: string;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  version: string | null | undefined;
  modifiedBy?: string;
  createdBy: string;

  taggedProtocols?: ProtocolItem[];
};

/**
 * This function will check if the Equipment is a draft version and delete it
 * @param equipmentItem The Equipment to check
 * @returns Success if ready to create a new Equipment or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Equipment[];
    if (isActive) {
      results = await DataStore.query(Equipment, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.activeID.eq(id)])
      );
    } else {
      results = await DataStore.query(Equipment, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.id.eq(id)])
      );
    }

    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbEquip = results[0];
    if (dbEquip.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Equipment, dbEquip.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbEquip,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new equipment in the database and choose the version
 * @param equipment EquipmentDB JSON format
 * @returns The successful equipmentItem or the error
 */
export const createEquipment = async (
  equipment: EquipmentDB | EquipmentItem,
  previousItem?: EquipmentItem
): Promise<Response> => {
  try {
    let json: EquipmentDB;
    if (equipment instanceof EquipmentItem) {
      json = {
        name: equipment.name,
        status: equipment.status,
        activeID: equipment.activeID,
        title: equipment.title,
        note: equipment.note,
        instruction: equipment.instruction,
        warning: equipment.warning,
        departmentID: equipment.depID,
        version:
          equipment.model?.version != null ? equipment.model.version : 'v1.0.0',
        optionItems:
          equipment.model.optionItems != null
            ? equipment.model.optionItems
            : [],
        modifiedBy: equipment.modifiedBy ? equipment.modifiedBy.id : undefined,
        createdBy: equipment.model.createdBy ? equipment.model.createdBy : '',
      };
    } else json = equipment as EquipmentDB;

    let e: Equipment;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbEquip = await DataStore.query(Equipment, previousItem.uid);
      if (dbEquip == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Equipment does not exist could not update',
        };
      }

      e = await DataStore.save(
        Equipment.copyOf(dbEquip, (updated) => {
          updated.name = json.name;
          updated.title = json.title;
          updated.instruction = json.instruction;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.optionItems = json.optionItems;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      e = await DataStore.save(
        new Equipment({
          name: json.name,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          version: json.version,
          title: json.title,
          instruction: json.instruction,
          note: json.note,
          warning: json.warning,
          optionItems: json.optionItems,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    let equipItem = new EquipmentItem(e);

    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];

        console.log('PROTOCOL:', protocol);
        /* ACTIVE - pass ID, DRAFT and activeID is null (HAS NEVER BEEN PUBLISHED) - pass UID, DRAFT with previous publish - pass activeID */
        let equipID =
          equipItem.status === ProgressStatus.ACTIVE ||
          (equipItem.status === ProgressStatus.DRAFT &&
            equipItem.activeID == null)
            ? equipItem.uid
            : equipItem.activeID;
        if (equipID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The equipment does not have a proper version ID ' +
              equipItem.uid +
              ' ' +
              equipItem.activeID +
              ' ' +
              equipItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          equipID,
          json.modifiedBy,
          'Equipment'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: equipItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING EQUIPMENT:', e, equipment);

    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Publishes draft equipments for a given department.
 * @param department - The department for which to publish draft equipments.
 * @returns A Promise that resolves to a Response object.
 */
export const publishEquipmentDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftEquipments = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );

    for (let i = 0; i < draftEquipments.length; i++) {
      let equip: Equipment = draftEquipments[i];
      let response: Response = await publishEquipment(equip);
      if (response.type === ResponseType.Success) {
        updates.push({
          model: equip,
          message: `Published Folder: ${equip.name}`,
        });
      } else {
        console.error('ERROR MAKING DRAFT TO ACTIVE:', response.data);
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the equipment to the database
 *    1. Create a new ARCHEIVED equipment based on the current ACTIVE equipment
 *    2. Update the ACTIVE equipment with the new information
 *    3. Delete the DRAFT equipment
 * @param draftEquipmentItem The equipment to publish
 */

export const publishEquipment = async (
  draftEquipmentItem: Equipment
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the equipment is configured correctly as a draft version */
    if (draftEquipmentItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The equipment is not a draft version',
      };
    }

    let activeEquipment: Equipment;

    /* Use case 1: Creating the FIRST active version */
    if (draftEquipmentItem.activeID == null) {
      /* Update the draft equipment to be active */
      activeEquipment = await DataStore.save(
        Equipment.copyOf(draftEquipmentItem, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active equipment item */
      let id: string = draftEquipmentItem.activeID;
      let curEquipment = await DataStore.query(Equipment, id);

      /* Base Case 3 -- check if the active equipment exists */
      if (curEquipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active equipment does not exist',
        };
      }

      let newArcheivedEquipment = new EquipmentItem(curEquipment);
      newArcheivedEquipment.status = ProgressStatus.ARCHIVE;
      newArcheivedEquipment.activeID = curEquipment.id;

      // 2. Create a new ARCHEIVED equipment based on the current ACTIVE equipment
      let archiveResult: Response = await createEquipment(
        newArcheivedEquipment
      );
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedEquipment = archiveResult.data as EquipmentItem;

      // 2. Update the ACTIVE equipment with the new information
      activeEquipment = await DataStore.save(
        Equipment.copyOf(curEquipment, (updated) => {
          updated.name = draftEquipmentItem.name;
          updated.optionItems = draftEquipmentItem.optionItems;
          updated.title = draftEquipmentItem.title;
          updated.note = draftEquipmentItem.note;
          updated.warning = draftEquipmentItem.warning;
          updated.instruction = draftEquipmentItem.instruction;

          updated.createdBy = draftEquipmentItem.createdBy;
          updated.modifiedBy = draftEquipmentItem.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftEquipmentItem.version;
        })
      );

      // 3. Delete the DRAFT equipment
      let draftEquipment = await DataStore.delete(
        Equipment,
        draftEquipmentItem.id
      );
      if (draftEquipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft equipment does not exist',
        };
      }
    }

    let equipItem = new EquipmentItem(activeEquipment);
    return {
      type: ResponseType.Success,
      data: equipItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteEquipment = async (
  equipmentItem: EquipmentItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = equipmentItem.uid;
    if (isSoft) {
      let equipment = await DataStore.query(Equipment, id);
      if (equipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment does not exist',
        };
      }

      let result = await DataStore.save(
        Equipment.copyOf(equipment, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment did not update correctly',
        };
      }
    } else {
      let equipment = await DataStore.delete(Equipment, id);
      if (equipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: equipmentItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isEquipmentDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getEquipmentDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Equipment, (e) =>
      e.and((e) => [
        // e.or((e) => [e.status.eq('DRAFT'), e.status.eq('DELETED')]),
        e.status.eq('DRAFT'),
        e.departmentID.eq(department.id),
      ])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if (model.status === 'DELETED')
      //   message = `Deleted Equipment: ${model.name}`;
      if (model.activeID == null) message = `Created Equipment: ${model.name}`;
      else message = `Updated Equipment: ${model.name}`;
      updates.push({
        model: model,
        message: message,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentEquipmentDrafts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftEquipments = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    for (let i = 0; i < draftEquipments.length; i++) {
      let equip: Equipment = draftEquipments[i];
      await DataStore.delete(equip);
      updates.push({
        model: equip,
        message: `Removed Equipment: ${equip.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getEquipmentByID = async (
  db: DatabaseResponse,
  id: string
): Promise<EquipmentItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbEquip = db.equipment.find((c) => c.uid === id);
      if (dbEquip != null) return resolve(dbEquip);
      else {
        executeQuery(graphqlOperation(getEquipment, { id: id }), 1500)
          .then((data) => {
            if (data.data.getEquipment == null) {
              resolve(null);
            } else {
              let elec = data.data.getEquipment as Equipment;
              let equipment = new EquipmentItem(elec);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(equipment);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
