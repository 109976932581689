import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { globals } from '../../ui/_global/common/Utils';
import { Keychain, User } from '../../models';
import { ProgressStatus } from '../../models';
import { type } from 'os';
import ProtocolItem from './ProtocolItem';
import { getHashedPin } from '../../ui/_global/common/Encrypt';
import CategoryItem from './CategoryItem';

class KeychainItem implements ModelItem {
  TAG = 'KeychainItem';
  uid: string;
  depID: string;
  name: string;
  version: string | null | undefined;
  model: Keychain;
  modifiedBy: User | null | undefined;
  createdBy: User | null | undefined;

  hash: string;
  salt: string;
  type: string;

  isUnlocked: boolean = false;

  categories: CategoryItem[] = [];
  protocols: ProtocolItem[] = [];

  constructor(keychain: Keychain) {
    this.uid = keychain.id;
    this.name = keychain.name;
    this.depID = keychain.departmentID;
    this.model = keychain;
    this.version = keychain.version;

    this.modifiedBy = null;
    this.createdBy = null;

    this.hash = keychain.hashedPin;
    this.salt = keychain.saltPin;
    this.type = keychain.hashType;

    this.isUnlocked = this.checkUnlocked(keychain);
  }

  getAmount(weight: PatientWeight): string {
    return 'N/A';
  }

  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the department id of the owner of this category.
   * @returns the department id of the owner of this category
   */
  getDepID(): string {
    return this.depID;
  }

  getName(): string {
    return this.name;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  checkUnlocked(keychain: Keychain): boolean {
    let value: string | null = localStorage.getItem(keychain.id);
    if (value) {
      let json: any = JSON.parse(value);
      const expiration = Number(json.expiration);
      const currentTime = new Date().getTime();

      /* If unexpired validate signature */
      if (expiration > currentTime) {
        let signature = getHashedPin(
          keychain.hashedPin,
          keychain.saltPin + expiration.toLocaleString(),
          keychain.hashType
        );
        if (signature === json.signature) return true;
      } else localStorage.removeItem(keychain.id);
    }
    return false;
  }

  toString(): string {
    return `${this.TAG} -> {
      uid: ${this.uid},
      name: ${this.name},
      depID: ${this.depID},
      version: ${this.version},
      model: ${this.model},
      modifiedBy: ${this.modifiedBy},
      createdBy: ${this.createdBy},
      hash: ${this.hash},
      salt: ${this.salt},
      type: ${this.type},
    }`;
  }
}

export default KeychainItem;
