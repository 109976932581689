import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { useEffect, useMemo, useState } from 'react';
import '../import/Import.scss';
import { ViewportList } from 'react-viewport-list';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { globals, upgradeVersion } from '../../../../_global/common/Utils';
import AIPdfParserMedicationInput from './AIPdfParserMedicationInput';
import {
  MedicationProtocol,
  MedicationRange,
  ProgressStatus,
} from '../../../../../models';
import MedicationItem from '../../../../../data/model/MedicationItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  MedicationJSON,
  createParentMedication,
} from '../../../../../data/functions/MedicationDB';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import { find } from 'lodash';
// import NewMedicationInput from './NewMedicationInput';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../../store/actions';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  ProtocolDB,
  createProtocol,
} from '../../../../../data/functions/ProtocolDB';
import Fuse from 'fuse.js';
import { Col, Row } from 'react-bootstrap';
import { group } from 'console';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  createParentInfusion,
  InfusionJSON,
} from '../../../../../data/functions/InfusionDB';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import Loading from '../../../../components/Loading/Loading';
interface Dose {
  name: string;
  dose: string;
  administrationRoutes: any;
  maxSingleDose: string;
  minSingleDose: string;
  maxTotalDose: string;
  repeatTime: string;
  repeatAllowed: string;
  ageGroup: string;
  weightLow: string;
  weightHigh: string;
  ageLow: string;
  ageHigh: string;
  title: string;
  instruction: string;
  warning: string;
  source: string;
  medication?: MedicationItem;
  confirmedData?: any;
  isHmMedication?: boolean;
}
interface MedicationData {
  file: string;
  index: number;
  doses: Dose[];
}
interface ExpandedStates {
  [key: string]: boolean;
}
const AIPdfParserResult = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    data,
    file,
    fileUploaded,
    database,
    folder,
    aiParsedData,
    dbMeds,
    dbInfusions,
  } = location.state;

  console.log('FOLDER', folder);
  const [protocol, setProtocol] = useState<ProtocolItem>(
    location.state.protocol
  );
  console.log('PROTOCOL', protocol);

  const [db, setDatabase] = useState<DatabaseResponse>(database);
  const [meds, setMedications] = useState<any[]>(database.medications);
  const department: DepartmentItem = db.department;

  const [expandedStates, setExpandedStates] = useState<ExpandedStates>({
    Electrical: true,
    Infusion: true,
    Medication: true,
  });
  const [loading, setLoading] = useState<string | null>(null);

  const [final, setFinal] = useState<any>();

  const objURL = useMemo(
    () => (fileUploaded ? URL.createObjectURL(fileUploaded) : ''),
    [fileUploaded]
  );

  const [allTypeData, setAllTypeData] = useState<any>(
    location.state.allTypeData
  );

  console.log('ALL TYPE DATA', allTypeData);

  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });

  const handleBack = () => {
    navigate(`/actions/ai-pdf-parser/protocol-extraction`, {
      state: {
        pdfKey: location.state.pdfKey,
        data: data,
        file: file,
        database: db,
        folder: folder,
        protocol: protocol,
        endPage: location.state.endPage,
        startPage: location.state.startPage,
        aiParsedData: aiParsedData,
        dbMeds: dbMeds,
        dbInfusions: dbInfusions,
        workbookURL: location.state.workbookURL,
      },
    });
  };

  const handleCreateMedications = async (
    groupedMedications: any
  ): Promise<MedicationItem[]> => {
    if (globals.debug) console.log('Creating medications');
    let newMeds: MedicationItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < groupedMedications.length; i++) {
      let newMed = groupedMedications[i];
      let medication: MedicationItem = newMed.medication;
      let importedOptions = newMed.protocolOptions;
      let currentMedicationOptions = medication.protocolOptions;
      if (globals.debug)
        console.log(
          'Creating medication',
          currentMedicationOptions,
          importedOptions
        );

      /* Merge the new options with the current options */
      let options = [...currentMedicationOptions];
      for (let j = 0; j < importedOptions.length; j++) {
        /* Check if the protocol is already in the current medication */
        let find = options.find(
          (item) => item.protocolID === importedOptions[j].protocolID
        );
        if (find) {
          /* Merge the options */
          let newMP = {
            protocolID: find.protocolID,
            options: [...find.options, ...importedOptions[j].options],
          };
          options = options.map((item) =>
            item.protocolID === newMP.protocolID ? newMP : item
          );
        } else {
          /* Add the new protocol to the options */
          options.push(importedOptions[j]);
        }
      }
      let createdBy =
        medication.depID !== department.id
          ? 'OneBot'
          : medication.dbMedication?.createdBy
            ? medication.dbMedication?.createdBy
            : 'OneBot';
      let activeID =
        medication.depID !== department.id
          ? null
          : medication.activeID
            ? medication.activeID
            : medication.uid;
      let version =
        medication.depID !== department.id
          ? null
          : medication.version
            ? upgradeVersion(medication.version)
            : 'v1.0.0';

      const newMedication: MedicationJSON = {
        name: medication.name,
        concentration: medication.concentrations,
        routes: medication.routes,
        rangeLow: !isNaN(Number(medication.rangeLow))
          ? Number(medication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(medication.rangeHigh))
          ? Number(medication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        protocolOptions: options,

        rxNormCode: medication.dbMedication?.rxNormCode,
        nemsisRoutes: medication.dbMedication?.nemsisRoutes,
        minDose: medication.dbMedication?.minDose,
        maxDose: medication.dbMedication?.maxDose,
        metaData: medication.dbMedication?.metaData,

        contraindication: medication.dbMedication?.contraindication,
        note: medication.dbMedication?.note,
        warning: medication.dbMedication?.warning,
        medClass: medication.dbMedication?.medClass,
        action: medication.dbMedication?.action,
        indication: medication.dbMedication?.indication,
        interaction: medication.dbMedication?.interaction,
        onset: medication.dbMedication?.onset,
        duration: medication.dbMedication?.duration,
        taggedProtocols: [], //protocolList,

        createdBy: createdBy,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        version: version,
      };

      if (globals.debug) console.log('Creating New Medication', newMedication);
      let results: Response = await createParentMedication(
        newMedication,
        medication.depID !== department.id ? undefined : medication
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created medication', results.data);
        let newMedication: MedicationItem = results.data;
        newMeds.push(newMedication);
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }
    if (globals.debug)
      console.log(
        'All Medications Created',
        index,
        '/',
        groupedMedications.length
      );
    return newMeds;
  };

  const handleCreateInfusions = async (
    groupedInfusions: any
  ): Promise<MedicationItem[]> => {
    if (globals.debug) console.log('Creating infusions');
    let newMeds: MedicationItem[] = [];
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < groupedInfusions.length; i++) {
      let newMed = groupedInfusions[i];
      let medication: MedicationItem = newMed.medication;
      let importedOptions = newMed.protocolOptions;
      let currentMedicationOptions = medication.protocolOptions;
      if (globals.debug)
        console.log(
          'Creating infusion',
          currentMedicationOptions,
          importedOptions
        );

      /* Merge the new options with the current options */
      let options = [...currentMedicationOptions];
      for (let j = 0; j < importedOptions.length; j++) {
        /* Check if the protocol is already in the current medication */
        let find = options.find(
          (item) => item.protocolID === importedOptions[j].protocolID
        );
        if (find) {
          /* Merge the options */
          let newMP = {
            protocolID: find.protocolID,
            options: [...find.options, ...importedOptions[j].options],
          };
          options = options.map((item) =>
            item.protocolID === newMP.protocolID ? newMP : item
          );
        } else {
          /* Add the new protocol to the options */
          options.push(importedOptions[j]);
        }
      }
      let createdBy =
        medication.depID !== department.id
          ? 'OneBot'
          : medication.dbMedication?.createdBy
            ? medication.dbMedication?.createdBy
            : 'OneBot';
      let activeID =
        medication.depID !== department.id
          ? null
          : medication.activeID != null
            ? medication.activeID
            : medication.uid;
      let version =
        medication.depID !== department.id
          ? null
          : medication.version
            ? upgradeVersion(medication.version)
            : 'v1.0.0';

      const newMedication: InfusionJSON = {
        name: medication.name,
        concentration: medication.concentrations,
        routes: medication.routes,
        rangeLow: !isNaN(Number(medication.rangeLow))
          ? Number(medication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(medication.rangeHigh))
          ? Number(medication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: department.id,
        dripOptions: options,

        rxNormCode: medication.dbMedication?.rxNormCode,
        nemsisRoutes: medication.dbMedication?.nemsisRoutes,
        minDose: medication.dbMedication?.minDose,
        maxDose: medication.dbMedication?.maxDose,
        metaData: medication.dbMedication?.metaData,

        contraindication: medication.dbMedication?.contraindication,
        note: medication.dbMedication?.note,
        warning: medication.dbMedication?.warning,
        medClass: medication.dbMedication?.medClass,
        action: medication.dbMedication?.action,
        indication: medication.dbMedication?.indication,
        interaction: medication.dbMedication?.interaction,
        onset: medication.dbMedication?.onset,
        duration: medication.dbMedication?.duration,
        taggedProtocols: [], //protocolList,

        createdBy: createdBy,
        status: ProgressStatus.DRAFT,
        activeID: activeID,
        version: version,
      };

      if (globals.debug) console.log('Creating New Infusion', newMedication);
      if (globals.debug)
        console.log(
          'Previous Infusion',
          medication.depID !== department.id ? undefined : medication
        );
      let results: Response = await createParentInfusion(
        newMedication,
        medication.depID !== department.id ? undefined : medication
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created infusion', results.data);
        let newMedication: MedicationItem = results.data;
        newMeds.push(newMedication);
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }
    if (globals.debug)
      console.log(
        'All Medications Created',
        index,
        '/',
        groupedInfusions.length
      );
    return newMeds;
  };
  const handleUpdateProtocol = async (
    medications: MedicationItem[],
    infusions: MedicationItem[]
  ): Promise<ProtocolItem> => {
    let p: ProtocolItem = protocol;
    if (globals.debug) console.log('Updating Protocols', p);
    let medIDs: string[] = p.model.medicationIDs
      ? ((protocol as ProtocolItem).model.medicationIDs as string[])
      : [];
    let infIDs: string[] = p.model.dripIDs ? (p.model.dripIDs as string[]) : [];
    let newMedIDs = [...medIDs, ...medications.map((item) => item.uid)];
    let newInfIDs = [...infIDs, ...infusions.map((item) => item.uid)];

    let json: ProtocolDB = {
      departmentID: database.department.id,
      name: p.name,
      nickname: p.nickname,
      pdfID: p.pdfUrl,
      rangeLow: p.rangeLow,
      rangeHigh: p.rangeHigh,
      parentCategory: p.parent,
      pairedDeps: p.pairedDeps,
      pairedProtocols: p.model.pairedProtocols ? p.model.pairedProtocols : [],
      medications: newMedIDs,
      infusions: newInfIDs,
      equipment: p.model.equipmentIDs ? p.model.equipmentIDs : [],
      electrical: p.model.electricalIDs ? p.model.electricalIDs : [],
      forms: p.model.formIDs ? p.model.formIDs : [],
      createdBy: p.model.createdBy ? p.model.createdBy : '',
      status: ProgressStatus.DRAFT,
      activeID: null,
      version: upgradeVersion(p.version),
      pdfVersion: p.pdfVersion,
      index: p.parent.protocols.length,
      isPublic: department.isPublic,
      keychainID: null,
    };

    let resp: Response = await createProtocol(json, protocol);
    if (resp.type === ResponseType.Success) {
      let newProtocol: ProtocolItem = resp.data;
      if (globals.debug) console.log('Updated Protocol', newProtocol);
      return newProtocol;
    } else {
      console.error('Failed to update protocol', resp.data);
      return protocol;
    }
  };

  const handleContinue = async () => {
    setLoading('Updating Protocol ' + protocol.name + '...');
    let medicationsDoses: Dose[] = allTypeData.Medication?.reduce(
      (acc: Dose[], page: MedicationData) => {
        return [...acc, ...page.doses];
      },
      []
    );

    let infusionsDoses: Dose[] = allTypeData.Infusion?.reduce(
      (acc: Dose[], page: MedicationData) => {
        return [...acc, ...page.doses];
      },
      []
    );

    console.log('Group Medication Doses by Medication');
    let groupedMeds = groupMedicationsDataByParent(medicationsDoses);
    let groupedInfs = infusionsDoses
      ? groupMedicationsDataByParent(infusionsDoses)
      : [];

    let medications = await handleCreateMedications(groupedMeds);
    let infusions = await handleCreateInfusions(groupedInfs);

    let newProtocol = await handleUpdateProtocol(medications, infusions);
    setProtocol(newProtocol);

    /* Now reload the database */
    let success = await reloadDatabase();
    if (success) {
      setModalState({
        isVisible: true,
        title: 'Successfully Updated ' + protocol.name,
        description:
          'Successfully updated the protocol ' +
          protocol.name +
          ' with ' +
          medications.length +
          ' medications, ' +
          infusions.length +
          ' infusions, and 0 electricals',
        primaryBtnName: 'Okay',
        secondaryBtnName: 'Okay',
        primaryDescription: '',
        secondaryDescription: '',
      });
    } else {
      console.error('ERROR RELOADING DATABASE');
    }

    setLoading(null);

    /* Now create all the medications & infusions */

    // for (let i = 0; i < allTypeData.Medication.length; i++) {
  };

  const groupMedicationsDataByParent = (doses: any[]) => {
    let groupedMeds: any[] = [];
    for (let i = 0; i < doses.length; i++) {
      let dose = doses[i];
      if (groupedMeds.length === 0) {
        groupedMeds.push({
          medication: dose.medication,
          protocolOptions: [
            {
              protocolID: protocol.activeID ? protocol.activeID : protocol.uid,
              options: [create_medication_range(dose)],
            },
          ] as MedicationProtocol[],
        });
      } else {
        let foundMedication = groupedMeds.find(
          (item) => item.medication.uid === dose.medication.uid
        );
        if (!foundMedication) {
          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: [
              {
                protocolID: protocol.activeID
                  ? protocol.activeID
                  : protocol.uid,
                options: [create_medication_range(dose)],
              },
            ] as MedicationProtocol[],
          });
        } else {
          /* Parse out the found medication */
          groupedMeds = groupedMeds.filter(
            (item) => item.medication.uid !== dose.medication.uid
          );

          let protOptions = [...foundMedication.protocolOptions];
          let index = protOptions.findIndex(
            (item) =>
              item.protocolID ===
              (protocol.activeID ? protocol.activeID : protocol.uid)
          );
          if (index === -1) {
            console.error('NO MEDICATION PROTOCOL FOUND');
            return;
          } else {
            protOptions[index].options.push(create_medication_range(dose));
          }

          groupedMeds.push({
            medication: dose.medication,
            protocolOptions: protOptions,
          });
        }
      }
    }
    console.log('GROUPED MEDICATIONS', groupedMeds);
    return groupedMeds;
  };

  const create_medication_range = (dose: any, index?: number) => {
    let range = new MedicationRange({
      basis: dose.dose,
      rangeLow: dose.rangeLow ? Number(dose.rangeLow.split(' ')[0]) : 0,
      rangeHigh: dose.rangeHigh
        ? Number(dose.rangeHigh.split(' ')[0])
        : globals.MAX_VALUE,
      route: dose.administrationRoutes,
      title: dose.title,
      warning: dose.warning,
      instruction: dose.instruction,
      note: dose.note,
      maxDose: dose.maxSingleDose,
      minDose: dose.minSingleDose,
      calcMax: dose.calcMax,
      index: index ? index : 0,
    });
    return range;
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(db.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const preprocessText = (text: string) => {
    return text
      .toLowerCase()
      .trim()
      .replace(/[.\s()+\-\/\\_]/g, ''); // Modified regex to capture other characters you mentioned
  };

  function create_process_list(
    medList: MedicationItem[],
    isHM: boolean
  ): any[] {
    let processList = [];
    // if (isHM) {
    for (let i = 0; i < medList?.length; i++) {
      let med = medList[i];
      processList.push({
        name: preprocessText(med.name),
        med: med,
        isHM: isHM,
      });
      processList.push({
        name: preprocessText(med.dbMedication?.metaData?.fullName ?? ''),
        med: med,
        isHM: isHM,
      });
      processList.push({
        name: preprocessText(med.dbMedication?.metaData?.nickName ?? ''),
        med: med,
        isHM: isHM,
      });
      let names = med.dbMedication?.metaData?.brandNames ?? [];
      for (let j = 0; j < names.length; j++) {
        processList.push({
          name: preprocessText(names[j]),
          med: med,
          isHM: isHM,
        });
      }
    }
    return processList;
  }

  function findMedication(
    name: string,
    meds: MedicationItem[],
    hmMeds: MedicationItem[]
  ): any | undefined {
    const processedList = create_process_list(meds, false);

    // Options for Fuse.js
    const options = {
      includeScore: true, // Include the score in the result set
      findAllMatches: false, // Find all matches, not just the best match
      threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
      keys: ['name'], // Fields to search in each object
    };
    const fuse = new Fuse(processedList, options);
    //   const splitName = name.split(' ');
    //   for (let i = 0; i < splitName.length; i++) {
    let search = name
      .toLowerCase()
      .trim()
      .replace(/()-\/\\_/g, '');
    const result = fuse.search(search);
    for (let i = 0; i < result.length; i++) {
      if (result[i].score != null && (result[i].score as number) < 0.2)
        return {
          med: result[i].item.med,
          isHM: result[i].item.isHM,
        };
    }

    const hmProcessedList = create_process_list(hmMeds, true);
    const fuseHM = new Fuse(hmProcessedList, options);
    const resultHM = fuseHM.search(search);
    for (let i = 0; i < resultHM.length; i++) {
      if (resultHM[i].score != null && (resultHM[i].score as number) < 0.2)
        return {
          med: resultHM[i].item.med,
          isHM: resultHM[i].item.isHM,
        };
    }
    return undefined;
  }

  const convertToAdminRoutesArray = (item: Dose) => {
    if (
      item &&
      item.administrationRoutes &&
      typeof item.administrationRoutes === 'string'
    ) {
      item.administrationRoutes = item.administrationRoutes.split(',');
    }
    return item;
  };

  const toggleExpanded = (key: string) => {
    setExpandedStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const ProtocolItemUI = ({ name, length, errorCount }: any) => {
    const isExpanded = expandedStates[name];
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            isExpanded ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            toggleExpanded(name);
          }}
        >
          {isExpanded ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {name}
            </span>
          </div>

          {errorCount > 0 && (
            <span
              className="ketamine-general-label"
              style={{ marginRight: '10px', color: 'red' }}
            >
              Errors: {errorCount}
            </span>
          )}

          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {name === 'Electrical' ? 'Shocks: ' + length : 'Doses: ' + length}
          </span>
        </div>
      </div>
    );
  };

  const renderDoses = (medicationData: MedicationData[], key: string) => {
    if (medicationData.length === 0) return null;
    const isExpanded = expandedStates[key];
    const errorCount = calculateErrorCount(key, medicationData);
    console.log('KEY', key);
    return (
      medicationData && (
        <div key={key}>
          <ProtocolItemUI
            name={key}
            length={medicationData.length}
            errorCount={errorCount}
            onClick={() => toggleExpanded(key)}
            expanded={isExpanded}
          />
          {isExpanded &&
            medicationData.map((page, pageIndex) => (
              <div key={pageIndex}>
                <ViewportList items={page.doses}>
                  {(item: Dose, index: number) => {
                    item = item.confirmedData
                      ? { ...item.confirmedData }
                      : { ...item };
                    if (typeof item.administrationRoutes === 'string') {
                      item.administrationRoutes =
                        item.administrationRoutes.split(',');
                    }

                    const medicationDetails = findMedication(
                      item.name == null ? '' : item.name,
                      key === 'Medication'
                        ? database.medications
                        : database.infusions,
                      key === 'Medication' ? dbMeds : dbInfusions
                    );

                    return (
                      <div key={index} style={{ marginBottom: '10px' }}>
                        <AIPdfParserMedicationInput
                          dose={item}
                          db={database}
                          dbMeds={key === 'Medication' ? dbMeds : dbInfusions}
                          medication={
                            item.medication != null
                              ? item.medication
                              : medicationDetails?.med
                          }
                          isHMMedication={medicationDetails?.isHM}
                          index={index}
                          page_number={
                            page.index - location.state.startPage + 1
                          }
                          onChange={(
                            data: any,
                            index: number,
                            med: MedicationItem | null
                          ) => {
                            let newMedicationData = [...medicationData];
                            let newData = {
                              ...data,
                              medication: med,
                              isHmMedication: med
                                ? med.depID !== database.department.id
                                : false,
                            };
                            newMedicationData[pageIndex].doses[index] = newData;

                            setAllTypeData({
                              ...allTypeData,
                              [key]: newMedicationData,
                            });
                          }}
                          onDelete={() => {
                            let newMedicationData = [...medicationData];
                            newMedicationData[pageIndex].doses.splice(index, 1);
                            setAllTypeData({
                              ...allTypeData,
                              [key]: newMedicationData,
                            });
                          }}
                          onCopy={(index) => {
                            let newMedicationData = [...medicationData];
                            let dose =
                              newMedicationData[pageIndex].doses[index];
                            let newDose = { ...dose };
                            newMedicationData[pageIndex].doses.push(newDose);
                            setAllTypeData({
                              ...allTypeData,
                              [key]: newMedicationData,
                            });
                          }}
                        />
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            ))}
        </div>
      )
    );
  };

  function calculateErrorCount(key: string, medicationData: MedicationData[]) {
    if (medicationData == null) return 0;
    let count = 0;
    for (let i = 0; i < medicationData?.length; i++) {
      for (let j = 0; j < medicationData[i].doses.length; j++) {
        if (key === 'Electrical') {
          let dose =
            medicationData[i].doses[j].confirmedData ||
            medicationData[i].doses[j];

          if (dose.dose === '' || dose.dose === null) count++;
          if (dose.title === '' || dose.title === null) count++;
        } else if (key === 'Infusion' || key === 'Medication') {
          let dose =
            medicationData[i].doses[j].confirmedData ||
            medicationData[i].doses[j];

          if (dose.dose === '' || dose.dose === null) count++;
          if (
            dose.administrationRoutes == null ||
            dose.administrationRoutes === '' ||
            dose.administrationRoutes?.length === 0
          )
            count++;
          if (dose.medication == null) count++;
        }
      }
    }
    return count;
  }

  const isContinueValid = useMemo(() => {
    return (
      calculateErrorCount('Medication', allTypeData.Medication) === 0 ||
      calculateErrorCount('Infusion', allTypeData.Infusion) === 0 ||
      calculateErrorCount('Electrical', allTypeData.Electrical) === 0
    );
  }, [allTypeData]);

  return (
    <div className="screen-container">
      {loading !== null && <Loading type="bubbles" message={loading} />}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
          navigate(`/actions/ai-pdf-parser/protocol-extraction`, {
            state: {
              pdfKey: location.state.pdfKey,
              data: data,
              file: file,
              database: db,
              folder: folder,
              protocol: undefined,
              endPage: '',
              startPage:
                Number(
                  location.state.endPage
                    ? location.state.endPage
                    : location.state.startPage
                ) +
                1 +
                '',
              aiParsedData: aiParsedData,
              dbMeds: dbMeds,
              dbInfusions: dbInfusions,
              workbookURL: location.state.workbookURL,
            },
          });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.description}
        secondaryDescription={modalState.secondaryDescription}
      />
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <ProtocolHeader
          page={'AI PDF Parser'}
          name={'Checking Doses'}
          description={'Extract the details from PDF into your Excel'}
          isBackButton={true}
          rightSideBtn={'edit'}
          isEditButton={false}
          isReviewButton={false}
          isReviewActive={false}
          isCustomButton={true}
          isCustomActive={isContinueValid}
          customText="Continue"
          isCancelButton={true}
          handleCustom={handleContinue}
          type={'protocol'}
          handleCancel={handleBack}
          handleCancelEdit={handleBack}
        />
      </div>

      <div style={{ flex: 1, display: 'flex', ...props.containerStyle }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Row>
            <Col sm={6}>
              {/*  */}
              <div className="" style={{ padding: '0px 15px' }}></div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  marginTop: '30px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    gap: '1rem',
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 200px)',
                  }}
                >
                  {Object.entries(allTypeData).map(([key, data]) =>
                    renderDoses(data as MedicationData[], key)
                  )}
                </div>
              </div>
            </Col>
            <Col sm={6} style={{ display: 'flex', height: '80vh' }}>
              {objURL ? (
                <embed
                  title="PDF Document"
                  type="application/pdf"
                  src={objURL}
                  style={{ height: '100%', width: '100%' }}
                />
              ) : (
                <div>Loading...</div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AIPdfParserResult;
