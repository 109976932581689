import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import {
  AiOutlineClose,
  AiOutlineFileText,
  AiOutlineFullscreen,
  AiOutlinePlus,
  AiOutlineRight,
} from 'react-icons/ai';
import {
  BiChevronLeft,
  BiChevronRight,
  BiCopy,
  BiFullscreen,
  BiSolidCopy,
} from 'react-icons/bi';
import { FaFolderOpen, FaPencilAlt, FaRegEdit } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import {
  IoArrowBack,
  IoDocument,
  IoDocumentText,
  IoDuplicate,
  IoEllipsisVertical,
  IoSave,
} from 'react-icons/io5';
import { toTitleCase } from '../../_global/common/Utils';
import { CgRename } from 'react-icons/cg';
import { useState } from 'react';
import { ProgressStatus } from '../../../API';
import Status from '../../components/ProgressStatus/ProgressStatus';
import { FaDownload, FaTrash, FaUserCheck } from 'react-icons/fa6';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { MenuItem, Select, Tooltip } from '@mui/material';
import { RiPencilFill } from 'react-icons/ri';
import { IoIosAddCircle, IoMdAdd } from 'react-icons/io';
import { MdCancel, MdOutlineCancel } from 'react-icons/md';
import ButtonDropdown from '../../components/Dropdown/ButtonDropdown';

interface ProtocolHeaderProps {
  name?: any;
  homeScreen?: boolean;
  type?: string;
  page?: string;
  descriptionTitle?: string;
  status?: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  description?: any;
  customDescription?: any;
  disableButton?: boolean;
  // isSubmenu?: boolean;
  isBackButton?: boolean;
  handleNew?: () => void;
  handleCancel?: () => void;
  handleSave?: () => void;
  handleReview?: () => void;
  isVersionButton?: boolean;
  isAddButton?: boolean;
  isEditButton?: boolean;
  isEditIcon?: boolean;
  isSaveButton?: boolean;
  isCancelButton?: boolean;
  isDotButton?: boolean;
  isFullScreenButton?: boolean;
  isUploadButton?: boolean;
  isRenameButton?: boolean;
  isDuplicateButton?: boolean;
  isFolderEditButton?: boolean;
  isSaveActive?: boolean;
  isCreateActive?: boolean;
  isSendActive?: boolean;
  isCreateButton?: boolean;
  isDeleteButton?: boolean;
  isReviewButton?: boolean;
  isReviewActive?: boolean;
  isDownloadButton?: boolean;
  isfullScreenButton?: boolean;
  isSendButton?: boolean;
  isViewButton?: boolean;
  isBaseFolder?: boolean;
  isEnableButton?: boolean;
  isCustomButton?: boolean;
  customText?: string;
  isLogo?: boolean;
  handleEnable?: () => void;
  handleFullScreen?: () => void;
  handleViewFullScreen?: () => void;
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleUpload?: () => void;
  handleVersion?: () => void;
  handleNewFolder?: () => void;
  handleNewProtocol?: () => void;
  handleCreate?: () => void;
  handleRename?: () => void;
  handleFolderEdit?: () => void;
  handleCancelEdit?: () => void;
  handleDelete?: () => void;
  handleCustom?: (text: string) => void;
  handleDuplicate?: () => void;
  handleDownload?: () => void;
  newButtonRef?: any;
  protocolDetail?: any;
  rightSideBtn?: any;
  isDeleteDisabled?: boolean;
  isCustomActive?: boolean;
  isCopyDescription?: boolean;
  isArrowButtons?: boolean;
  isLeftArrowActive?: boolean;
  isRightArrowActive?: boolean;
  handleArrow?: (isRight: boolean) => void;
}

/* 10-11-23 Arul: Created the Protocol Header Component globally*/
const ProtocolHeader: React.FC<ProtocolHeaderProps> = (props) => {
  const {
    name,
    page,
    descriptionTitle,
    description,
    customDescription,
    status,
    homeScreen,
    isBackButton,
    isfullScreenButton,
    isLogo,
    handleViewFullScreen,
    rightSideBtn,
    protocolDetail,
    isVersionButton,
    isSendButton,
    isViewButton,
    isCreateActive,
    isCustomActive,
    isFolderEditButton,
    isRenameButton,
    isDuplicateButton,
    isDownloadButton,
    handleRename,
    handleFolderEdit,
    isAddButton,
    isEditButton,
    isEditIcon,
    isUploadButton,
    isSaveButton,
    isCancelButton,
    disableButton,
    isSaveActive,
    isSendActive,
    isCreateButton,
    isDeleteButton,
    isReviewButton,
    isReviewActive,
    isBaseFolder,
    handleDelete,
    isFullScreenButton,
    isDotButton,
    isEnableButton,
    isCustomButton,
    customText,
    handleCustom,
    handleEnable,
    handleCreate,
    handleNewFolder,
    handleNewProtocol,
    handleVersion,
    handleAdd,
    handleEdit,
    handleNew,
    handleFullScreen,
    handleDuplicate,
    handleDownload,
    type,
    handleCancel,
    handleSave,
    handleCancelEdit,
    handleReview,
    handleUpload,
    newButtonRef,
    isDeleteDisabled,
    isCopyDescription,
    isArrowButtons,
    isLeftArrowActive,
    isRightArrowActive,
    handleArrow,
  } = props;

  const [isShowSubmenu, setIsShowSubmenu] = useState(false); //Whether the submenu is visible

  const [isCopied, setIsCopied] = useState<string | null>(null);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };
  const department: DepartmentItem = useSelector(
    (state: any) => state.protocol.departmentItem.department
  );

  return (
    <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
      <div
        style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0px',
            flex: 1,
            overflowX: 'auto',
          }}
        >
          <div className={`titleStyling ${homeScreen ? 'colAlignCenter' : ''}`}>
            {type === 'protocol' && (
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6
                  className={`backTextLight  ${
                    homeScreen ? 'titleText' : ''
                  } ${isBackButton ? 'hoverText' : ''}`}
                  onClick={handleCancel}
                >
                  {isBackButton && (
                    <span>
                      <IoArrowBack
                        data-testid="isBackBttn"
                        size="1.15rem"
                        onClick={handleCancel}
                        style={{ marginRight: '5px' }}
                      />
                    </span>
                  )}
                  {protocolDetail ? protocolDetail.name : page}
                </h6>
              </div>
            )}
            {name && (
              // <div className="titleItemContainer">
              //   <div className="titleItemText">
              //       <div
              //           className="hoverableText"
              //           onClick={(e) => {
              //               e.stopPropagation();
              //               handleTabClick(item, true);
              //           }}
              //       >
              //           {item?.name}
              //       </div>
              //   </div>
              //   {item.status !== "ACTIVE" &&
              //       <Status
              //           status={item.status}
              //       />
              //   }
              // </div>
              <div className="titleItemContainer">
                <div className="titleTextPH">{name}</div>
                {status && status !== 'ACTIVE' && (
                  <Status
                    status={status}
                    textStyle={{ fontSize: '16px' }}
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </div>
            )}
            {description && (
              <h6
                className={`headerTextLight  ${homeScreen ? 'titleText' : ''}`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                {descriptionTitle}
                {description}
                {isCopyDescription && (
                  <span>
                    {isCopied && isCopied === description ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size="1rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size="1rem"
                        className="copy_icon"
                        onClick={(e) => handleCopy(description, e)}
                      />
                    )}
                  </span>
                )}
              </h6>
            )}
            {customDescription && <>{customDescription}</>}
          </div>
        </div>
        {type !== 'protocol' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="protocolHeaderButton">
              {isDeleteButton && (
                <Tooltip title={'Delete'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isDeleteDisabled ? 'disabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete && handleDelete();
                    }}
                  >
                    <FaTrash
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: isDeleteDisabled ? '#e0e0e0' : '#a94c4c',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isDownloadButton && (
                <Tooltip
                  title={'Download Protocol PDF(s)'}
                  placement="bottom"
                  arrow
                >
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownload && handleDownload();
                    }}
                  >
                    <FaDownload
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isFolderEditButton && (
                <Tooltip title={'Edit'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFolderEdit && handleFolderEdit();
                    }}
                  >
                    <RiPencilFill
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isRenameButton && (
                <Tooltip title={'Rename'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRename && handleRename();
                    }}
                  >
                    <CgRename
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              <Button
                className="secondary-button-small-border btn-rightMargin"
                disabled={disableButton}
                onClick={handleCancel}
                style={{ marginLeft: '10px' }}
              >
                <span>
                  <AiOutlineClose className="icon-normal" size="1rem" />
                </span>{' '}
                Cancel
              </Button>
              <>
                <ButtonDropdown
                  title="New"
                  titleIcon={<AiOutlinePlus />}
                  options={
                    isBaseFolder
                      ? [
                          {
                            value: '1',
                            label: 'Folder',
                            icon: <FaFolderOpen />,
                          },
                        ]
                      : [
                          {
                            value: '2',
                            label: 'Protocol',
                            icon: <IoDocumentText />,
                          },
                        ]
                  }
                  onChange={(option) => {
                    if (option.value === '1') {
                      handleNewFolder && handleNewFolder();
                    } else {
                      handleNewProtocol && handleNewProtocol();
                    }
                  }}
                  style={{
                    marginLeft: '10px',
                  }}
                />
                {/* <Button
                  className="secondary-button-small-border btn-rightMargin"
                  disabled={disableButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNew && handleNew();
                  }}
                  ref={newButtonRef}
                >
                  <span>
                    <AiOutlinePlus className="icon-normal" size="1rem" />
                  </span>
                  New
                </Button> */}
                {/* {isSubmenu && ( */}
                {/*</>// <div className="subBtnBorder">
                  //   {isBaseFolder && (
                  //     <div
                  //       data-testid="folderBttn"
                  //       className="SubButtonText"
                  //       onClick={handleNewFolder}
                  //       style={{
                  //         borderBottom: '1px solid #e0e0e0',
                  //       }}
                  //     >
                  //       <span>
                  //         <FaFolderOpen className="view_pdf_icon" size="1rem" />
                  //       </span>
                  //       Folder
                  //     </div>
                  //   )}
                  //   {!isBaseFolder && (
                  //     <div
                  //       data-testid="protocolBtn"
                  //       className="SubButtonText"
                  //       onClick={handleNewProtocol}
                  //     >
                  //       <span>
                  //         <AiOutlineFileText
                  //           className="view_pdf_icon"
                  //           size="1rem"
                  //         />
                  //       </span>
                  //       Protocol
                  //     </div>
                  //   )}
                  // </div>
                // )}</div>*/}
              </>
              {isSaveButton && (
                <Button
                  data-testid="isSaveButton"
                  className={` ${
                    // rightSideBtn === "save"
                    'primary-button btn-rightMargin'
                    // : "secondary-button-small-border btn-rightMargin"
                  }`}
                  onClick={handleSave}
                  disabled={!isSaveActive}
                >
                  <span>
                    <IoSave size="1rem" />
                  </span>{' '}
                  Save
                </Button>
              )}
              <Button
                className="primary-button-small"
                disabled={disableButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleReview && handleReview();
                }}
              >
                Review{' '}
                <span>
                  <AiOutlineRight className="icon-normal" />
                </span>
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="headerButtonContainer">
              {isVersionButton && (
                <Button
                  data-testid="isVersionButton"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleVersion}
                >
                  Version(s)
                </Button>
              )}
              {isDeleteButton && (
                <Tooltip title={'Delete'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isDeleteDisabled ? 'disabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete && handleDelete();
                    }}
                  >
                    <FaTrash
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: isDeleteDisabled ? '#e0e0e0' : '#8c1010',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isDownloadButton && (
                <Button
                  data-testid="isEditButton"
                  className={` ${
                    rightSideBtn === 'edit'
                      ? 'primary-button btn-rightMargin'
                      : 'secondary-button-small-border btn-rightMargin'
                  }`}
                  onClick={handleDownload}
                >
                  <span>
                    <FaDownload className="icon-size" />
                  </span>{' '}
                  Download
                </Button>
              )}
              {isAddButton && rightSideBtn !== 'add' && (
                <Button
                  data-testid="isAddButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleAdd}
                >
                  <span>
                    <AiOutlinePlus className="icon-normal" />
                  </span>{' '}
                  Add
                </Button>
              )}
              {isCancelButton && (
                <Button
                  data-testid="isCancelButton"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleCancelEdit}
                >
                  <span>
                    <AiOutlineClose className="icon-normal" size="1rem" />
                  </span>{' '}
                  Cancel
                </Button>
              )}
              {isCreateButton && (
                <Button
                  data-testid="isCreateButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleCreate}
                  disabled={!isCreateActive}
                >
                  <span>
                    <AiOutlinePlus className="icon-normal" />
                  </span>{' '}
                  Create
                </Button>
              )}
              {isSendButton && (
                <Button
                  data-testid="isSendButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleCreate}
                  disabled={!isSendActive}
                >
                  Send
                </Button>
              )}
              {isViewButton && (
                <Button
                  data-testid="isViewButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleCreate}
                >
                  {' '}
                  View
                </Button>
              )}

              {isUploadButton && (
                <Tooltip title={'Upload'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={() => {
                      handleUpload && handleUpload();
                    }}
                  >
                    <FiUpload
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
                // <Button
                //   data-testid="isUploadButton"
                //   className="secondary-button-small-border btn-rightMargin"
                //   onClick={handleUpload}
                // >
                //   <span>
                //     <FiUpload className="icon-size" />
                //   </span>{' '}
                //   Upload
                // </Button>
              )}

              {isEnableButton && (
                <Button
                  data-testid="isEnableButton"
                  className={` ${
                    // rightSideBtn === "save"
                    'primary-button btn-rightMargin'
                    // : "secondary-button-small-border btn-rightMargin"
                  }`}
                  onClick={handleEnable}
                >
                  <span>
                    <FaUserCheck size="1rem" />
                  </span>{' '}
                  Enable
                </Button>
              )}

              {isCustomButton && (
                <Button
                  data-testid="isReviewButton"
                  className="primary-button btn-rightMargin"
                  onClick={() => {
                    handleCustom && handleCustom(customText ? customText : '');
                  }}
                  disabled={!isCustomActive}
                >
                  {customText + ' '}
                  <span>
                    <AiOutlineRight className="icon-normal" />
                  </span>
                </Button>
              )}
              {isfullScreenButton && (
                <Button
                  data-testid="isfullScreenButton"
                  className="fullScreenBtn primary-button btn-rightMargin"
                  onClick={handleViewFullScreen}
                >
                  <span>
                    <BiFullscreen className="view_pdf_icon" />
                  </span>
                  Full Screen
                </Button>
              )}
              {isEditIcon && (
                <Tooltip title={'Edit'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit && handleEdit();
                    }}
                  >
                    <RiPencilFill
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isEditButton && (
                <Button
                  data-testid="isReviewButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleEdit}
                  style={
                    {
                      // marginLeft: '10px',
                    }
                  }
                >
                  <RiPencilFill className="icon-normal" /> Edit
                </Button>
              )}
              {isFullScreenButton && (
                <Tooltip title={'Full Screen'} placement="bottom" arrow>
                  <span
                    className="iconButton"
                    onClick={() => {
                      handleFullScreen && handleFullScreen();
                    }}
                  >
                    <AiOutlineFullscreen
                      data-testid="isFullScreenButton"
                      className="icon"
                    />
                  </span>
                </Tooltip>
              )}
              {isArrowButtons && (
                <Tooltip title={'Previous'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isLeftArrowActive ? '' : 'disabled'}`}
                    onClick={() => {
                      if (isLeftArrowActive) handleArrow && handleArrow(false);
                    }}
                  >
                    <BiChevronLeft data-testid="isDotButton" className="icon" />
                  </span>
                </Tooltip>
              )}
              {isArrowButtons && (
                <Tooltip title={'Next'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isRightArrowActive ? '' : 'disabled'}`}
                    onClick={() => {
                      handleArrow && handleArrow(true);
                    }}
                  >
                    <BiChevronRight
                      data-testid="isDotButton"
                      className="icon"
                    />
                  </span>
                </Tooltip>
              )}

              {isAddButton && rightSideBtn === 'add' && (
                <Tooltip title={'Add'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isRightArrowActive ? '' : 'disabled'}`}
                    onClick={() => {
                      handleAdd && handleAdd();
                    }}
                  >
                    <IoMdAdd
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isSaveButton && (
                <>
                  <Tooltip title={'Cancel'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        handleCancelEdit && handleCancelEdit();
                      }}
                      style={{ marginRight: '10px' }}
                    >
                      <MdCancel
                        data-testid="isDotButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          // color: '#616161',
                        }}
                      />
                    </span>
                  </Tooltip>
                  <Button
                    data-testid="isSaveButton"
                    className={` ${
                      // rightSideBtn === "save"
                      'primary-button btn-rightMargin'
                      // : "secondary-button-small-border btn-rightMargin"
                    }`}
                    onClick={handleSave}
                    disabled={!isSaveActive}
                  >
                    <span>
                      <IoSave size="1rem" />
                    </span>{' '}
                    Save
                  </Button>
                </>
              )}
              {isReviewButton && (
                <Button
                  data-testid="isReviewButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleReview}
                  disabled={!isReviewActive}
                  style={
                    {
                      // marginLeft: '10px',
                    }
                  }
                >
                  Review{' '}
                  <span>
                    <AiOutlineRight className="icon-normal" />
                  </span>
                </Button>
              )}
              {isDotButton && (
                <span className="iconButton">
                  <IoEllipsisVertical
                    data-testid="isDotButton"
                    className="icon"
                  />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

export default ProtocolHeader;
