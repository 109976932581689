import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import '../Popup.css';
import { useMemo } from 'react';

interface ConfirmWarningModalProps {
  isVisible: boolean;
  modalType?: string;
  type?: string;
  handleClose?: any;
  handleDelete?: any;
}

/* 10-20-23 Praveen: Created the Confirm popup globally for Concentration and Routes deletion */
const ConfirmWarningModal: React.FC<ConfirmWarningModalProps> = (
  props: any
) => {
  const { isVisible, type, handleClose, handleDelete, modalType } = props;
  const modalTitle = useMemo(() => {
    if (modalType === 'warning') {
      return 'Warning';
    } else if (modalType === 'success') {
      return 'Successfully Notified.';
    } else {
      return `Delete ${type}`;
    }
  }, [modalType, type]);

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{ width: '40vw', maxWidth: '600px', backgroundColor: 'white' }}
        onHide={handleClose}
        header={modalTitle}
        className="confirmDialog routeConfirmModal"
      >
        <div style={{ background: 'white' }}>
          <div className="">
            <div>
              {modalType === 'warning' ? (
                <div className="contentText contentTextBlackColor">
                  <div>
                    <div>
                      This Route value is mapped with other Dose section.
                    </div>
                    <div>
                      Please remove from the respective Dose and then proceed to
                      delete from here.
                    </div>
                  </div>
                </div>
              ) : modalType === 'success' ? (
                <div>
                  The Notification has been successfully been sent to the
                  Onedose Application.
                </div>
              ) : (
                <div className="contentText contentTextBlackColor">
                  Are you sure you want to delete this {type}?.
                </div>
              )}
            </div>
          </div>
          <div className="dialogButtons" style={{ marginTop: '20px' }}>
            {modalType === 'success' ? (
              <div style={{ display: 'flex' }}>
                <Button className="primary-button-small" onClick={handleClose}>
                  {' '}
                  Continue
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <Button
                  data-testid="dismissBtn"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleClose}
                >
                  {' '}
                  {modalType === 'warning' ? 'Ok' : 'Cancel'}
                </Button>
                {modalType !== 'warning' && (
                  <Button
                    data-testid="deleteBttn"
                    className="red-background-button btn-rightMargin "
                    onClick={() => {
                      handleDelete(type);
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ConfirmWarningModal;
