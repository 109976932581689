import { Dialog } from 'primereact/dialog';
import KeychainItem from '../../../../data/model/KeychainItem';
import { Button } from 'react-bootstrap';
import { IoClose, IoDocumentOutline } from 'react-icons/io5';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import { User, UserType } from '../../../../models';
import { useSelector } from 'react-redux';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import React, { useEffect, useMemo } from 'react';
import { ViewportList } from 'react-viewport-list';
import { FaFolderOpen, FaLock } from 'react-icons/fa6';
import { RiLockPasswordFill } from 'react-icons/ri';
import { InputText } from 'primereact/inputtext';
import '../../login/css/Login.scss';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { pad } from 'lodash';
import { InputSwitch } from 'primereact/inputswitch';
import { getHashedPin } from '../../../_global/common/Encrypt';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import CategoryItem from '../../../../data/model/CategoryItem';

interface UnlockKeychainModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (keychain: KeychainItem) => void;
  keychain: KeychainItem;
  department: DepartmentItem;
  logo?: string;
  type: 'department' | 'category' | 'protocol';
}

const LOCK = 0,
  UNLOCKED = 1;

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const UnlockKeychainModal: React.FC<UnlockKeychainModalProps> = (
  props: UnlockKeychainModalProps
) => {
  const {
    isVisible,
    handleClose,
    handleSubmit,
    keychain,
    type,
    department,
    logo,
  } = props;

  const [state, setState] = React.useState({
    type: LOCK,
    keychain: keychain,
    password: '',
    passwordVisible: false,
    remember: false,
    error: '',
  });

  const user: User = useSelector((state: any) => state.user);
  const [isCopied, setIsCopied] = React.useState<string | null>(null);

  useEffect(() => {
    setState({ ...state, keychain: keychain });
  }, [keychain]);

  const handleButton = () => {
    if (!isVisible) return;
    if (state.type === LOCK) {
      if (state.password.length === 0) {
        setState({ ...state, error: 'Password is required' });
        return;
      }

      let hash = getHashedPin(
        state.password,
        state.keychain.salt,
        state.keychain.type
      );

      if (hash !== state.keychain.hash) {
        if (globals.debug)
          console.log(
            'Incorrect password',
            hash,
            state.keychain.hash,
            state.keychain.type
          );
        setState({ ...state, error: 'Incorrect password' });
        return;
      } else {
        state.keychain.isUnlocked = true;

        let extraTime = state.remember
          ? 1000 * 60 * 60 * 24 * 30
          : 1000 * 60 * 60; // 30 days or 1 hour
        let expiration = new Date().getTime() + extraTime;
        localStorage.setItem(
          state.keychain.uid,
          JSON.stringify({
            expiration: expiration,
            signature: getHashedPin(
              state.keychain.hash,
              state.keychain.salt + expiration.toLocaleString(),
              state.keychain.type
            ),
          })
        );

        if (type === 'department') handleSubmit(state.keychain);
        else setState({ ...state, type: UNLOCKED });
        // resetState();
      }
    } else {
      handleSubmit(state.keychain);
      setTimeout(() => {
        resetState();
      }, 500);
    }
  };

  const resetState = () => {
    setState({
      type: LOCK,
      password: '',
      passwordVisible: false,
      remember: false,
      error: '',
      keychain: keychain,
    });
  };

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          // width: '50vw',
          // maxWidth: '800px',
          // flex: 1,
          // maxWidth
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: state.type === LOCK ? '500px' : '800px',
        }}
        onHide={() => {
          handleClose();
          resetState();
        }}
        showHeader={false}
        className="parseDialog"
      >
        {state.type === LOCK ? (
          <div
            style={{ background: 'white', padding: 0, margin: 0, gap: '10px' }}
          >
            <h4 className="keychain-title">
              <span
                style={{
                  marginLeft: '20px',
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  color: 'black',
                  marginRight: '6rem',
                }}
              >
                Enter keychain password
              </span>
              {type !== 'department' && (
                <Button
                  className="iconButton"
                  variant=""
                  style={{ marginRight: '10px' }}
                  onClick={handleClose}
                >
                  <IoClose size="1.5rem" />
                </Button>
              )}
            </h4>
            <form
              className="keychain-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleButton();
              }}
              style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                // alignItems: 'center',
                background: 'transparent',
                border: 'none',
              }}
            >
              {type === 'department' ? (
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  {logo && (
                    <img
                      className="department-logo-public"
                      style={{
                        height: '4rem',
                        width: '4rem',
                        marginRight: '10px',
                      }}
                      src={logo}
                      alt="Agency Logo"
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      // width: '100%',
                    }}
                  >
                    <label
                      className='"ketamine-general-label'
                      htmlFor="password"
                      style={{
                        fontWeight: '600',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      {department.name}
                    </label>
                  </div>
                </div>
              ) : (
                <form
                  className="input-container"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleButton();
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '3rem',
                      height: '3rem',
                      borderRadius: '1.5rem',
                      backgroundColor: '#E0EADD',
                      marginRight: '10px',
                    }}
                  >
                    <FaLock
                      size="1.5rem"
                      style={{
                        color: 'black',
                        // marginRight: '10px',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      // width: '100%',
                    }}
                  >
                    <label
                      className='"ketamine-general-label'
                      htmlFor="password"
                      style={{
                        fontWeight: '600',
                        color: 'black',
                        fontSize: '1rem',
                      }}
                    >
                      {state.keychain?.name}
                    </label>
                    <label
                      className='"ketamine-general-label'
                      htmlFor="password"
                      style={{
                        fontWeight: '400',
                        color: 'black',
                        fontSize: '0.8rem',
                      }}
                    >
                      {'Unlocks ' +
                        (keychain.categories.length > 0
                          ? keychain.categories.length +
                            (keychain.categories.length > 1
                              ? ' folders'
                              : ' folder')
                          : '') +
                        (keychain.categories.length > 0 &&
                        keychain.protocols.length > 0
                          ? ' and '
                          : '') +
                        (keychain.protocols.length > 0
                          ? keychain.protocols.length +
                            (keychain.protocols.length > 1
                              ? ' protocols'
                              : ' protocol')
                          : '')}
                    </label>
                  </div>
                </form>
              )}

              {type === 'department' && (
                <p
                  className="departmentItemText"
                  style={{
                    fontSize: '0.9rem',
                    color: 'black',
                    marginTop: '10px',
                    // maxWidth: '50%',
                  }}
                >
                  {department.name} has password protected their protocol set.
                  You must enter the password to unlock the protocols.
                </p>
              )}

              <label
                htmlFor="password"
                className={`keychain-title`}
                style={{
                  marginTop: '10px',
                }}
              >
                Password
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // width: '100%',
                  flex: 1,
                  margin: 0,
                  padding: 0,
                  // marginBottom: '10px',
                }}
              >
                <div className="public-input-container">
                  <RiLockPasswordFill className="form-icon-left" size={20} />
                  <InputText
                    type={state.passwordVisible ? 'text' : 'password'}
                    className="password-input"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required={true}
                    value={state.password}
                    autoFocus={true}
                    onChange={(e: any) => {
                      setState({
                        ...state,
                        error: '',
                        password: state.error === '' ? e.target.value : '',
                      });
                    }}
                    style={{
                      borderColor: state.error !== '' ? 'darkred' : 'gray',
                    }}
                  />
                </div>

                {state.passwordVisible ? (
                  <BsEye
                    className="form-icon-eye"
                    size={'2rem'}
                    onClick={() => {
                      setState({
                        ...state,
                        passwordVisible: !state.passwordVisible,
                      });
                    }}
                  />
                ) : (
                  <BsEyeSlash
                    className="form-icon-eye"
                    size={'2rem'}
                    onClick={() => {
                      setState({
                        ...state,
                        passwordVisible: !state.passwordVisible,
                      });
                    }}
                  />
                )}
              </div>
              {state.error !== '' && (
                <label
                  htmlFor="password"
                  className="error-text"
                  style={{
                    color: 'darkred',
                    fontSize: '1rem',
                    marginLeft: '10px',
                    marginTop: '6px',
                  }}
                >
                  {state.error}
                </label>
              )}
              <label
                htmlFor="remember"
                className="hover-underlined departmentItemText no-select"
                style={{
                  flexDirection: 'row',
                  marginTop: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setState({ ...state, remember: !state.remember });
                }}
              >
                <InputSwitch
                  name="remember"
                  style={{ marginRight: '10px' }}
                  checked={state.remember}
                  onChange={(e) => {
                    setState({ ...state, remember: e.value });
                  }}
                />
                Remember password
              </label>

              <p
                className="departmentItemText"
                style={{
                  fontSize: '0.9rem',
                  color: state.remember ? '#495057' : 'transparent',
                  marginTop: '10px',
                }}
              >
                {state.remember
                  ? 'OneDose will remember the password for 30 days.'
                  : 'T'}
              </p>

              <Button
                data-testid="set-Bttn"
                className="primary-button btn-rightMargin"
                style={{
                  marginTop: '3rem',
                }}
                onClick={handleButton}
              >
                Unlock
              </Button>
            </form>
          </div>
        ) : (
          <div
            style={{ background: 'white', padding: 0, margin: 0, gap: '10px' }}
          >
            <h4 className="keychain-title">
              <span
                style={{
                  marginLeft: '20px',
                  fontSize: '1.25rem',
                  fontWeight: '600',
                  color: 'black',
                  marginRight: '6rem',
                  flex: 1,
                }}
              >
                Successfully unlocked keychain
              </span>
              <Button
                className="iconButton"
                variant=""
                style={{ marginRight: '10px' }}
                onClick={handleClose}
              >
                <IoClose size="1.5rem" />
              </Button>
            </h4>
            <label
              className="keychain-title"
              style={{
                fontSize: '1rem',
                fontWeight: '600',
                color: 'black',
                padding: 0,
                margin: '10px 0px',
                textDecoration: 'underline',
              }}
            >
              {keychain.protocols.length + keychain.categories.length} Item
              {keychain.protocols.length + keychain.categories.length > 1
                ? 's'
                : ''}{' '}
              Unlocked:
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0px 20px',
                gap: '0px',
                maxHeight: '50vh',
                overflowY: 'auto',
                borderRadius: '6px',
                border: '1px solid #a3a3a3',
              }}
            >
              <ViewportList
                items={[...keychain.categories, ...keychain.protocols]}
              >
                {(item: CategoryItem | ProtocolItem, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1,
                        // justifyContent: 'space-between',
                        padding: '10px',
                        gap: '1rem',
                        // backgroundColor: '#f5f5f5',

                        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      {(item as ProtocolItem).parent ? (
                        <IoDocumentOutline size={16} />
                      ) : (
                        <FaFolderOpen size={16} />
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        {(item as ProtocolItem).parent && (
                          <label
                            className="keychain-title"
                            style={{
                              fontSize: '0.8rem',
                              fontWeight: '500',
                              color: '#333333',
                              padding: 0,
                              margin: 0,
                              flex: 1,
                            }}
                          >
                            {(item as ProtocolItem).parent.name}
                          </label>
                        )}
                        <label
                          className="keychain-title"
                          style={{
                            fontSize: '1.0rem',
                            fontWeight: '500',
                            color: 'black',
                            padding: 0,
                            margin: 0,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.name + ' ' + item.name}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </ViewportList>
            </div>
            <div
              style={{
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                className="primary-button"
                style={{
                  marginTop: '3rem',
                  justifyItems: 'flex-end',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
                onClick={handleButton}
              >
                Continue
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default UnlockKeychainModal;
