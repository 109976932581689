import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { Form, FormGroup, User } from '../../models';
import { ProgressStatus } from '../../API';

class FormItem implements ModelItem {
  TAG = 'FormItem';

  uid: string;
  depID: string;
  name: string;
  // protocolIDs: (string | null)[] | null | undefined;
  items: FormGroup[];
  dbForm: Form | null;
  activeID: string | null | undefined;
  version: string;
  model: Form;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  modifiedBy: User | null | undefined;

  constructor(form: Form) {
    this.uid = form.id;
    this.name = form.name;
    this.items = form.items;
    this.dbForm = form;
    // this.protocolIDs = form.protocolIDs;
    this.depID = form.departmentID;
    this.activeID = form.activeID;
    this.version = form.version ? form.version : 'v1.0.0';
    this.model = form;
    this.status = form.status != null ? form.status : ProgressStatus.ACTIVE;
  }

  getAmount(weight: PatientWeight): string {
    return this.items.length + ' items';
  }

  getUid(): string {
    return this.uid;
  }

  getDepID(): string {
    return this.depID;
  }

  getName(): string {
    return this.name;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof FormItem)) return false;
    let other: FormItem = obj as FormItem;
    return this.uid === other.getUid() && this.name === other.getName();
  }

  getItems(): FormGroup[] {
    return this.items;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  // /**
  //  * Parse the vital option into a string amount
  //  * @param option The vital option to parse
  //  * @returns The string amount of the vital option
  //  */
  // private parseOption(option: VitalOption) {
  // if(option.amntLow == null || option.amntHigh === "") return "";
  //     try {
  //         /* First parse the low amount into the value and units */
  //         let optionArr = option.amntHigh.split(" ", 2);
  //         if(optionArr.length != 2) {
  //             if(globals.debug) console.log(this.TAG, "ERROR: Option string is not in the correct format: " + option.amntLow);
  //             return "";
  //         }

  //         /* If there is no range return the low amount */
  //         if(option.amntHigh == null || option.amntHigh === "")
  //             return option.amntLow;

  //         /* Otherwise parse the range and match units if necessary */
  //         else {

  //             /* Parse the high amount into the value and units */
  //             let optionHighArr = option.amntHigh.split(" ", 2);
  //             if(optionHighArr.length != 2) {
  //                 if(globals.debug) console.log(this.TAG, "ERROR: Option string is not in the correct format: " + option.amntHigh);
  //                 return "";
  //             }

  //             /* If the units are the same, then return the low and high amounts Ex. 72-104 mmHg */
  //             if(optionArr[1] === optionHighArr[1]) {
  //                 let str = optionArr[0] + "-" + optionHighArr[0];
  //                 for(let i = 1; i < optionArr.length; i++) str += " " + optionArr[i];
  //                 return str;
  //             }

  //             /* Otherwise return the low and high amounts with the units Ex. 72 mmHg/102 kmHg */
  //             else {
  //                 let str = optionArr[0];
  //                 for(let i = 1; i < optionArr.length; i++) str += " " + optionArr[i];
  //                 str += "/" + optionHighArr[0];
  //                 for(let i = 1; i < optionHighArr.length; i++) str += " " + optionHighArr[i];
  //                 return str;
  //             }
  //         }
  //     } catch (e){
  //         if(globals.debug) console.log(this.TAG, "ERROR: Failed to parse vital option -> " + option.amntLow + " " + option.amntHigh);
  //     }
  //     return "";
  // }

  toString(): string {
    return `FormItem -> {
            uid=${this.uid},
            name=${this.name},
            items=${this.items.length}
        }`;
  }
}

export default FormItem;
