import React, { useEffect, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { MdClear } from 'react-icons/md';
import './MultiSelect.scss';

interface Props<T> {
  title: string;
  options: T[];
  initialSelectedItems?: T[];
  labelField: (option: T) => string;
  keyField: (option: T) => string;
  onSelected: (selectedItems: T[]) => void;
  style?: React.CSSProperties;
}

const MultiSelectDropdown = <T,>({
  title,
  options,
  initialSelectedItems,
  labelField,
  keyField,
  onSelected,
  style,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  useEffect(() => {
    if (initialSelectedItems) {
      for (let i = 0; i < initialSelectedItems.length; i++) {
        handleOptionToggle(initialSelectedItems[i]);
      }
    }
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isOpen]);

  const clearFilter = (e: any) => {
    e.stopPropagation();
    setSelectedItems([]); // Clear selected items
    onSelected([]); // Call onSelected with updated items
  };

  const handleOptionToggle = (option: T) => {
    const isSelected = selectedItems.some((filter) => filter === option);
    if (!isSelected) {
      const newItems = [...selectedItems, option];
      setSelectedItems(newItems); // Update internal state
      onSelected(newItems); // Inform parent
    } else {
      const newSelected = selectedItems.filter((filter) => filter !== option);
      setSelectedItems(newSelected); // Update internal state
      onSelected(newSelected); // Inform parent
    }
  };

  return (
    <div className="c-multi-select-dropdown" ref={ref} style={style}>
      <div
        className="c-multi-select-dropdown__selected"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!isOpen);
        }}
      >
        {selectedItems.length > 0 ? selectedItems.length + ' ' + title : title}
        {selectedItems.length > 0 && (
          <span className="clear-filter">
            <MdClear onClick={clearFilter} />
          </span>
        )}
        <BiChevronDown size={16} />
      </div>
      {isOpen && (
        <ul className="c-multi-select-dropdown_list">
          {options.map((option) => {
            const isSelected = selectedItems.some(
              (filter) => filter === option
            );
            return (
              <li
                className="c-multi-select-dropdown__option"
                key={keyField(option)}
                onClick={() => handleOptionToggle(option)}
              >
                <div
                  className={`dropdown_option${isSelected ? '-selected' : ''}`}
                >
                  <div className="option-label">
                    <span>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        className="c-multi-select-dropdown__option-checkbox"
                        readOnly
                      />
                    </span>
                    {labelField(option)}
                  </div>
                  {/* <span>{labelField(option)}</span> */}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
