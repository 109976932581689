import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import * as XLSX from 'xlsx';
import CategoryItem from '../../../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import Fuse from 'fuse.js';
import MedicationItem, {
  cloneMedication,
} from '../../../../../../data/model/MedicationItem';
import { globals } from '../../../../../_global/common/Utils';

const ADULT_CUTOFF = 40;
const PED_CUTOFF = 5;

const CATEGORY_INDEX = 'Index';
const CATEGORY_NAME = 'Category Name';
const PROTOCOL_NICKNAME = 'Nickname';
const PROTOCOL_NAME = 'Full name';
const ITEM_INDEX = 'Index_1';
const ITEM_TYPE = 'Type';
const ITEM_NAME = 'Name';
const ITEM_DOSE = 'Dose';
const ITEM_ROUTE = 'Administration Routes';
const ITEM_TITLE = 'Title';
const ITEM_WARNING = 'Warning';
const ITEM_MAX = 'Max Dose';
const ITEM_AGE_GROUP = 'Age Group';
const ITEM_RANGE_LOW = 'Range Low (kg)';
const ITEM_RANGE_HIGH = 'Range High (kg)';
const ITEM_INSTRUCTIONS = 'Instructions';
const ITEM_NOTES = 'Notes';

const type = {
  Equipment: ['Equipment', 'EQUIPAMENT', 'Equip'],
  Medication: ['Medication', 'Med'],
  Infusion: ['Infusion', 'Infus'],
  Electrical: ['Electrical', 'Elec'],
};

type MedicationDose = {
  category: CategoryItem;
  protocol: ProtocolItem;
  medication: MedicationItem;
  medicationData: MedicationExtraction;
};

type MedicationExtraction = {
  index: number;
  name: string;
  dose: string;
  routes: string[];
  title?: string;
  warning?: string;
  maxDose?: string;
  ageGroup?: string;
  rangeLow?: string;
  rangeHigh?: string;
  instructions?: string;
  notes?: string;
};

export const processProtocolDosesExcelFile = async (
  file: File,
  database: DatabaseResponse
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (
        file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        resolve({
          type: ResponseType.Failure,
          data: 'Only Excel Workbook files are allowed',
        });
      }
      let promises = [
        readExcelFile(file, database),
        setTimeout(() => {
          return new Promise((resolve, reject) => resolve('timeout'));
        }, 1000),
      ];
      let response: any = await Promise.race(promises);
      if (response === 'timeout') {
        resolve({
          type: ResponseType.Failure,
          data: 'File is taking too long to process',
        });
      } else {
        if (response.type === ResponseType.Success) {
          console.log('Successfully read excel file');
          let groupData = groupDoses(database, response.data);
          resolve({ type: ResponseType.Success, data: groupData });
        } else resolve(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

function groupDoses(database: DatabaseResponse, extractedDoses: any): any {
  let data = {
    medication: [] as any[],
    infusion: [],
    equipment: [],
    electrical: [],
    misses: extractedDoses.misses,
  };
  for (let med of database.medications) {
    /* Group every medication dose */
    let doses = extractedDoses.medication.filter(
      (d: MedicationDose) => d.medication.uid === med.uid
    );
    data.medication.push({
      item: med,
      doses: doses,
    });
  }
  if (globals.debug) console.log('Grouped Doses:', data);
  return data;
}

async function readExcelFile(
  file: File,
  database: DatabaseResponse
): Promise<Response> {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'buffer' });
      let misses = {
        category: [] as any[],
        protocol: [] as any[],
        medication: [] as any[],
        infusion: [] as any[],
        equipment: [] as any[],
        electrical: [] as any[],
      };
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if (globals.debug) console.log(`Data from ${sheetName}:`, json);
        if (sheetName === 'Protocols') {
          let curCategory: CategoryItem | undefined;
          let curProtocol: ProtocolItem | undefined;
          let medicationDoses: MedicationDose[] = [];
          let infusionDoses: MedicationDose[] = [];
          let equipmentDoses: MedicationDose[] = [];
          let electricalDoses: MedicationDose[] = [];

          let medMisses: any[] = [];
          json.forEach((row: any) => {
            /* Determine the type the row is */

            /* Extract the current category */
            if (row[CATEGORY_INDEX] != null) {
              curCategory = findCategory(row[CATEGORY_NAME], database);
              if (curCategory == null)
                misses.category.push({ name: row[CATEGORY_NAME] });
            } else if (
              /* Extract the current protocol */
              row[PROTOCOL_NICKNAME] != null &&
              row[PROTOCOL_NAME] != null
            ) {
              curProtocol = findProtocol(
                row[PROTOCOL_NICKNAME],
                row[PROTOCOL_NAME],
                database
              );
              if (curProtocol == null)
                misses.protocol.push({
                  nickname: row[PROTOCOL_NICKNAME],
                  name: row[PROTOCOL_NAME],
                });
            } else if (row[ITEM_INDEX] != null && curProtocol != null) {
              /* Extract the current item */
              /* Determine the type of the item */
              let itemType = row[ITEM_TYPE].toLowerCase()
                .trim()
                .replace(/\s+/g, ' ');

              /* Extract the medication data */
              if (itemType.includes('med')) {
                let med = extractMedicationData(row);
                let dbMed = med
                  ? findMedication(med.name, database)
                  : undefined;
                if (med && dbMed) {
                  medicationDoses.push({
                    category: curCategory as CategoryItem,
                    protocol: curProtocol as ProtocolItem,
                    medication: dbMed,
                    medicationData: med,
                  });
                } else if (med && !dbMed) {
                  misses.medication.push({
                    row: row,
                  });
                  medMisses.push(med);
                }
              }

              /* Extract the equipment data */

              /* Extract the infusion data */

              /* Extract the electrical data */
            }
          });
          if (globals.debug) {
            console.log('Categories Misses:', misses.category);
            console.log('Protocols Misses:', misses.protocol);
            console.log('Medication Misses:', misses.medication);
            console.log('Medication Misses:', medMisses);
          }
          resolve({
            type: ResponseType.Success,
            data: {
              medication: medicationDoses,
              infusion: infusionDoses,
              equipment: equipmentDoses,
              electrical: electricalDoses,
              misses: {
                medication: medMisses,
                infusion: [],
                equipment: [],
                electrical: [],
              },
            },
          });
        }
      });
    } catch (error) {
      reject(error);
    }
  });
}

function findCategory(
  name: string,
  database: DatabaseResponse
): CategoryItem | undefined {
  // Options for Fuse.js
  const options = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
    keys: ['name'], // Fields to search in each object
  };
  const fuse = new Fuse(database.categories, options);
  const result = fuse.search(name);
  for (let i = 0; i < result.length; i++) {
    let cat: CategoryItem = result[i].item;
    if (result[i].score != null && (result[i].score as number) < 0.2) {
      return cat;
    }
  }
  return undefined;
}

/**
 * Search for the protocol based on hte inputted text using the nickname and name
 * @param nickname The nickname of the protocol
 * @param name The name of the protocol
 * @param database  The database to search in
 * @returns The protocol item if found, otherwise undefined
 */
function findProtocol(
  nickname: string,
  name: string,
  database: DatabaseResponse
): ProtocolItem | undefined {
  let processedProtocols: any[] = database.protocols.map((protocol) => {
    let _nickname = preprocessText(protocol.nickname);
    let _name = preprocessText(protocol.name);
    return {
      nickname: _nickname,
      name: _name,
      protocol: protocol,
    };
  });

  let _nickname = preprocessText(nickname);
  let _name = preprocessText(name);

  // Options for Fuse.js
  const nicknameOptions = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.4, // Threshold for matching. A lower number means more exact matches.
    keys: ['nickname'], // Fields to search in each object
  };
  const nameOptions = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.4, // Threshold for matching. A lower number means more exact matches.
    keys: ['name'], // Fields to search in each object
  };
  const nicknameFuse = new Fuse(processedProtocols, nicknameOptions);
  const nickNameResult = nicknameFuse.search(_nickname);

  const nameFuse = new Fuse(processedProtocols, nameOptions);
  const nameResult = nameFuse.search(_name);

  let foundScore = 1;
  let foundProtocol: ProtocolItem | undefined = undefined;
  for (let i = 0; i < nickNameResult.length; i++) {
    let protocol: ProtocolItem = nickNameResult[i].item.protocol;
    if (
      nickNameResult[i].score != null &&
      (nickNameResult[i].score as number) < 0.4
    ) {
      /* Exact match found */
      if ((nickNameResult[i].score as number) < 0.1) {
        return protocol;
      } else {
        foundProtocol = protocol;
        foundScore = nickNameResult[i].score as number;
        break;
      }
    }
  }

  for (let i = 0; i < nameResult.length; i++) {
    let protocol: ProtocolItem = nameResult[i].item.protocol;
    let score = nameResult[i].score;
    if (foundProtocol == protocol) score = foundScore + (score as number) / 2;

    if ((score as number) < 0.4) {
      if (foundProtocol == protocol) {
        return protocol;
      }
    }
  }

  return undefined;
}

function findMedication(
  name: string,
  database: DatabaseResponse
): MedicationItem | undefined {
  const processedMedications: MedicationItem[] = database.medications.map(
    (med) => {
      name = preprocessText(name);
      let newMed = cloneMedication(med);
      newMed.name = preprocessText(newMed.name);
      return newMed;
    }
  );
  // Options for Fuse.js
  const options = {
    includeScore: true, // Include the score in the result set
    findAllMatches: false, // Find all matches, not just the best match
    threshold: 0.2, // Threshold for matching. A lower number means more exact matches.
    keys: ['name'], // Fields to search in each object
  };
  const fuse = new Fuse(processedMedications, options);
  //   const splitName = name.split(' ');
  //   for (let i = 0; i < splitName.length; i++) {
  let search = name
    .toLowerCase()
    .trim()
    .replace(/()-\/\\_/g, '');
  const result = fuse.search(search);
  for (let i = 0; i < result.length; i++) {
    let med: MedicationItem = result[i].item;
    if (result[i].score != null && (result[i].score as number) < 0.2) {
      let dbMed = database.medications.find((m) => m.uid === med.uid);
      return dbMed;
    }
  }
  // if (globals.debug) console.log('Medication not found:', search, result);
  //   }
  return undefined;
}
const preprocessText = (text: string) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[.\s()+\-\/\\_]/g, ''); // Modified regex to capture other characters you mentioned
};

function extractMedicationData(row: any): MedicationExtraction | undefined {
  let index = Number(row[ITEM_INDEX]) ? Number(row[ITEM_INDEX]) : 0;
  let name = row[ITEM_NAME];
  let dose = cleanDose(row[ITEM_DOSE]);
  let routes = row[ITEM_ROUTE] ? row[ITEM_ROUTE].split('/') : [];
  let title = row[ITEM_TITLE];
  let warning = row[ITEM_WARNING];
  let max = cleanDose(row[ITEM_MAX]);
  let rangeResult = cleanRange(
    row[ITEM_RANGE_LOW],
    row[ITEM_RANGE_HIGH],
    row[ITEM_AGE_GROUP]
  );
  let ageGroup = row[ITEM_AGE_GROUP];
  let rangeLow = rangeResult.low;
  let rangeHigh = rangeResult.high;
  let instructions = row[ITEM_INSTRUCTIONS];
  let notes = row[ITEM_NOTES];

  /* Validate the required fields */
  if (index == null || name == null || !dose || routes == null)
    return undefined;

  return {
    index: index,
    name: name,
    dose: dose,
    routes: routes,
    title: title,
    warning: warning,
    maxDose: max,
    ageGroup: ageGroup,
    rangeLow: rangeLow,
    rangeHigh: rangeHigh,
    instructions: instructions,
    notes: notes,
  };
}

function cleanDose(dose: string): string {
  // Trim, remove redundant spaces, and handle line breaks
  if (!dose) return '';
  dose = dose
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/[\r\n]+/g, '');

  // Pattern to find numeric values possibly with ranges and units, optionally followed by "/kg"
  const dosePattern =
    /(\d+\.?\d*)\s*-\s*(\d+\.?\d*)\s*(mg|mcg|g|ml|MG|Mcg|G|ML|mEq|MEQ|gram|GRAM|gm|GM)\b|\b(\d+\.?\d*)\s*(mg|mcg|g|ml|MG|Mcg|G|ML)\b/g;
  const perKgPattern = /per kg|\/kg|mg\/kg|mcg\/kg|g\/kg|ml\/kg/i;

  let match,
    matches = [];

  // Check for range or single value doses
  while ((match = dosePattern.exec(dose)) !== null) {
    if (match[1] && match[2]) {
      // Range dose
      matches.push(`${match[1]}-${match[2]} ${match[3].toLowerCase()}`);
    } else if (match[4] && match[5]) {
      // Single dose
      matches.push(`${match[4]} ${match[5].toLowerCase()}`);
    }
  }

  // Determine if dose is per kg
  const perKg = perKgPattern.test(dose) ? '/kg' : '';

  // Concatenate all matches with the per kg notation if applicable
  return matches.join(' and ') + perKg;
}

function cleanRange(
  rangeLow: string,
  rangeHigh: string,
  ageGroup: string
): any {
  let weightLow = cleanWeight(rangeLow);
  let weightHigh = cleanWeight(rangeHigh);
  let ageRangeLow = cleanAgeGroup(ageGroup, false);
  let ageRangeHigh = cleanAgeGroup(ageGroup, true);

  if (weightLow || weightHigh) {
    return {
      low: weightLow,
      high: weightHigh,
    };
  } else if (ageRangeLow || ageRangeHigh) {
    return {
      low: ageRangeLow,
      high: ageRangeHigh,
    };
  }
  return {
    low: '',
    high: '',
  };
}

function cleanWeight(weight: string): string {
  if (!weight) return '';
  // Trim the input and replace multiple spaces with a single space
  weight = weight.trim().replace(/\s+/g, ' ');

  // Define a regular expression to match patterns like "5 kg" or "5kg" (numbers followed by 'kg')
  const weightPattern = /^(\d+\.?\d*)\s*kg$/i;

  let match = weight.match(weightPattern);

  if (match) {
    // If there's a match, format it correctly as "5 kg"
    return `${parseFloat(match[1])} kg`;
  }

  // If no valid format is found, return an empty string or a default message
  return '';
}

function cleanAgeGroup(ageGroup: string, isHigh: boolean): string {
  if (!ageGroup) return '';
  // Trim the input and convert to lowercase for case-insensitive comparison
  ageGroup = ageGroup.trim().toLowerCase();

  if (ageGroup === 'adult' && !isHigh) {
    return `${Number(ADULT_CUTOFF)} kg`;
  } else if (ageGroup === 'pediatric' && isHigh) {
    return `${Number(ADULT_CUTOFF)} kg`;
  }

  // If no valid age group is found, return an empty string or a default message
  return '';
}
