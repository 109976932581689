import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import {
  IoChevronDown,
  IoChevronForward,
  IoClose,
  IoSearch,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import snapshot_icon from '../../../assets/images/snapshot_icon.png';
import { getFormattedDate } from '../../_global/common/Utils';
import { snapshotList } from '../../_global/constants/Protocol_constants';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../components/Modal/DraftSaveModal';
import SnapshotModal from '../../components/Modal/SnapshotModal';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { DatabaseResponse } from '../../../data/AmplifyDB';

interface SnapshotItemProps {
  item: {
    name: string;
    user: {
      name: string;
    };
    numberOfChanges: number;
    date: string;
  };
  onClick: () => void;
}

// Author: Guruprasad Venkatraman (01-05-2024)
// Created a new component for snapshot item to be used in the viewport list keeping in mind the reusability of the component.
const SnapshotItem: React.FC<SnapshotItemProps & { index: number }> = ({
  item,
  onClick,
  index,
}) => (
  <div
    className="snapshotItem"
    onClick={onClick}
    data-testid={`snapshot-item-${index}`}
  >
    <div className="imageNameContainer">
      <img src={snapshot_icon} alt="Vital Icon" className="protocolVitalIcon" />
      <div className="a1Content">
        <div className="contentTitle">{item.name}</div>
        <div className=" a1SubItemsContent">{item.user.name}</div>
      </div>
    </div>
    <div className="dateChangesContainer">
      <div className="dataContentText">{item.numberOfChanges} Changes</div>
      <div className="a1SubItemsContent">
        {getFormattedDate(item.date, false)}
      </div>
      <IoChevronForward className="forwardIcon" />
    </div>
  </div>
);

/* 11-23-23 Arul: Created common Component for snapshot Screen*/
const Snapshot = (props: any) => {
  // const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  // const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSnapshotModalVisible, setIsSnapshotModalVisible] = useState(false);

  /* 11-24-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  /* 11-24-23 Arul: function for handling the draft confirm modal make new button functionality*/

  const handleCreate = () => {
    setIsSnapshotModalVisible(true);
  };

  const handleMakeNew = () => {
    setIsConfirmModal(false);
    setIsOpen(true);
  };

  const handleSnapshotSubmit = () => {
    setIsSnapshotModalVisible(false);
  };

  const handleViewSnapshot = (item: any) => {
    navigate(`/snapshot-detail`, { state: item });
  };

  return (
    <div className="screen-container">
      {isOpen && (
        <DraftSaveModal
          department={database.department}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            navigate(`/protocol/edit`);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          data-testid="confirm-modal-submit-button"
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the 11 detected changes from the previous draft and override it and create a new one."
        />
      )}
      <SnapshotModal
        dialogVisible={isSnapshotModalVisible}
        setDialogVisible={setIsSnapshotModalVisible}
        headerText="Your Snapshot Modal Header Text"
        handleSnapshotSubmit={handleSnapshotSubmit}
        data-testid="submit-snapshot-button"
      />

      <SnapshotModal
        dialogVisible={isSnapshotModalVisible}
        setDialogVisible={setIsSnapshotModalVisible}
        headerText="Your Snapshot Modal Header Text"
        handleSnapshotSubmit={handleSnapshotSubmit}
        data-testid="submit-snapshot-button"
      />

      <div>
        <div className="fixedHeader fixedHeaderPad">
          <ProtocolHeader
            homeScreen={true}
            type={'protocol'}
            page={'Snapshot'}
            isCreateButton={true}
            handleCreate={handleCreate}
          />
        </div>
        <div>
          <div>
            <Row>
              <Col sm={10}>
                <div className="search-bar">
                  <Form className="d-none d-sm-inline-block">
                    <InputGroup
                      style={{ zIndex: 0 }}
                      className="input-group-navbar"
                    >
                      <Button variant="">
                        <IoSearch size="1.5rem" />
                      </Button>
                      <Form.Control
                        placeholder="Search Snapshots..."
                        aria-label="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="searchInput"
                      />
                      {searchQuery && (
                        <Button variant="" onClick={() => setSearchQuery('')}>
                          <IoClose size="1.5rem" />
                        </Button>
                      )}
                    </InputGroup>
                  </Form>
                </div>
              </Col>
              <Col sm={2}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button className="secondary-button-small">
                    Filter
                    <span>
                      <IoChevronDown size="1rem" className="filter_Icon_Pad" />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="lightText"
            style={{ marginBottom: '5px', fontWeight: 'bold' }}
          >
            Search the snapshots
          </div>
          <div>
            <ViewportList items={snapshotList}>
              {(item: any, index: any) => (
                <div
                  key={index}
                  data-testid={`snapshot-item-${index}`}
                  className={`tableRowLine cursorPointer listContainer`}
                  onClick={() => {
                    handleViewSnapshot(item);
                  }}
                >
                  <div
                    style={{
                      padding: '0px 10px',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div style={{ display: 'flex', width: '60%' }}>
                      <div>
                        {' '}
                        <img
                          src={snapshot_icon}
                          alt="Vital Icon"
                          className="protocol-vital-icons"
                        />
                      </div>
                      <span className={'a1Content'}>
                        <div
                          style={{ fontSize: '20px' }}
                          className="contentTitle a1SubITems"
                        >
                          {item.name}
                        </div>
                        <div
                          style={{ justifyContent: 'flex-start' }}
                          className="a1SubItemsContent dataContentText"
                        >
                          {item.user.name}
                        </div>
                      </span>
                    </div>
                    <div
                      className="a1SubItemsContent dataContentText"
                      style={{ width: '10%' }}
                    >
                      <div>{item.numberOfChanges} Changes</div>
                    </div>
                    <div
                      className="a1SubItemsContent dataContentText"
                      style={{ width: '20%' }}
                    >
                      {getFormattedDate(item.date, false)}
                    </div>
                    <div
                      className="a1SubItemsContent dataContentText"
                      style={{ width: '10%' }}
                    >
                      <IoChevronForward style={{ marginRight: '10px' }} />
                    </div>
                  </div>
                </div>
              )}
            </ViewportList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Snapshot;
