import CryptoJS from 'crypto-js';
/**
 * 
 * @param {*} pin The pin to hash
 * @param {*} salt The salt to use for hashing
 * @param {*} type The type of hashing to use.
 *  * MD5
 *  * SHA256
 *  * SHA512
 *  * Default is MD5
 * @returns The hashed pin
 */
export const getHashedPin = (pin, salt, type="MD5") => {
    try {
        const text = pin + salt;
        let hash = "";
        switch (type) {
            case "SHA512":
                hash = CryptoJS.SHA512(text).toString();
                return hash.toUpperCase();
            case "SHA256":
                hash = CryptoJS.SHA256(text).toString();
                return hash.toUpperCase();
            case "MD5":
                hash = CryptoJS.MD5(text).toString();
                return hash.toUpperCase();
            default:
                hash = CryptoJS.MD5(text).toString();
                return hash.toUpperCase();
        }
    } catch (error) {
        console.error("Error hashing pin:", error);
        return "null";
    }
}

/**
 * Get a random salt for hashing
 * @returns A random salt for hashing
 */
export const getSalt = () => {
    try {
      const salt = CryptoJS.lib.WordArray.random(128 / 8).toString();
      return salt;
    } catch (error) {
      console.error('Error getting salt:', error);
      return 'null';
    }
  };

export const encrypt = (text, key) => {
    try {
        const encrypted = CryptoJS.AES.encrypt
            (text, key).toString();
        return encrypted;
    }
    catch (error) {
        console.error('Error encrypting:', error);
        return 'null';
    }
}

export const decrypt = (text, key) => {
    try {
        const decrypted = CryptoJS.AES.decrypt
            (text, key).toString(CryptoJS.enc.Utf8);
        return decrypted;
    }
    catch (error) {
        console.error('Error decrypting:', error);
        return 'null';
    }
}

