import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  DatabaseResponse,
  ResponseType,
  fetchChecklists,
  fetchVitals,
  getDepartments,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { copyChecklistsFromDeptoDep } from '../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { getHashedPin } from '../../../../_global/common/Encrypt';
import { IoLockClosed } from 'react-icons/io5';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import './Audit.scss';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { ViewportList } from 'react-viewport-list';
import MedicationItem from '../../../../../data/model/MedicationItem';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import { globals, toTitleCase } from '../../../../_global/common/Utils';
import MedicationViewModal from './MedicationViewModal';
import { performAudit } from './PerformAudit';
import Loading from '../../../../components/Loading/Loading';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../../store/actions';
import {
  ElectricalShockOption,
  Medication,
  MedicationProtocol,
} from '../../../../../models';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const AuditPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedMedication, setSelectedMedication] = useState<
    MedicationItem | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    department: undefined as DepartmentItem | undefined,
    database: undefined as DatabaseResponse | undefined,
  });

  const reloadDatabase = async (department: DepartmentItem) => {
    const response = await loadDatabase(department);
    if (response.type === ResponseType.Success) {
      const database = response.data;
      dispatch<any>(handleGetDepartment(database));
    }
  };

  const handleBack = () => {
    navigate('/actions');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments(true);
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadData = async (department: DepartmentItem) => {
    const response = await loadDatabase(department);
    if (response.type === ResponseType.Success) {
      let db = response.data;
      setInfo({
        department: department,
        database: db,
      });
    }
    setIsLoading(null);
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const handlePerformAudit = async (isUpdate: boolean) => {
    setIsLoading('Performing Audit on ' + info.department.name + '...');
    let response = await performAudit(info.department, info.database, isUpdate);
    if (response.type === ResponseType.Success) {
      if (globals.debug) console.log('Results: ', response.data);
      if (isUpdate) {
        await reloadDatabase(info.department);
        setModalState({
          isVisible: true,
          title: 'Audit Completed',
          primaryBtnName: 'Okay',
          primaryDescription: 'The audit has been completed successfully.',
          secondaryBtnName: 'Okay',
          secondaryDescription:
            'There was ' + response.data.length + ' items audited.',
        });
      } else if (response.data.length > 0) {
        setModalState({
          isVisible: true,
          title: 'Audit Required',
          primaryBtnName: 'Cancel',
          primaryDescription:
            'There has been detected issues with the audit. Would you like to continue with these changes?',
          secondaryBtnName: 'Continue',
          secondaryDescription:
            'There was ' + response.data.length + ' items audited.',
        });
      } else {
        setModalState({
          isVisible: true,
          title: 'Audit Results',
          primaryBtnName: 'Okay',
          primaryDescription: 'There was no changes required to change',
          secondaryBtnName: 'Okay',
          secondaryDescription:
            'There was ' + response.data.length + ' items audited.',
        });
      }
    } else {
      alert('Audit Failed');
    }
    setIsLoading(null);
  };

  const isSaveValid = useMemo(() => {
    return info.department !== undefined && info.database !== undefined;
  }, [info]);

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(item: MedicationItem | ElectricalItem, index: number) => {
          const lastIndex = data.length === index + 1;
          const items =
            item instanceof MedicationItem
              ? item.medicationSubItems
              : item.subElectricals;

          let dbItems: any[] = [];
          if (item instanceof MedicationItem) {
            let med = item as MedicationItem;
            let dbMed = med.dbMedication;
            let dbDrip = med.dbDrip;
            if (dbMed && dbDrip == null) {
              for (let i = 0; i < dbMed.protocolOptions.length; i++) {
                let protocol = dbMed.protocolOptions[i];
                for (let j = 0; j < protocol.options.length; j++) {
                  dbItems.push(protocol.options[j]);
                }
              }
            } else if (dbDrip && dbMed == null) {
              for (let i = 0; i < dbDrip.dripOptions.length; i++) {
                let protocol = dbDrip.dripOptions[i];
                for (let j = 0; j < protocol.options.length; j++) {
                  dbItems.push(protocol.options[j]);
                }
              }
            }
          } else {
            let elec = item as ElectricalItem;
            for (let i = 0; i < elec.model.options.length; i++) {
              let option = elec.model.options[i];
              for (let range in option.ranges) {
                dbItems.push(option.ranges[range]);
              }
            }
          }

          return (
            <div
              className="result-list-item"
              key={index}
              style={{
                borderBottom: lastIndex ? 'none' : '1px solid #e0e0e0',
              }}
              onClick={() => {
                if (item instanceof MedicationItem) {
                  setSelectedMedication(item);
                }
              }}
            >
              <span
                className="ketamine-general-label"
                style={{ flex: 1, fontWeight: '500' }}
              >
                {item.name}
              </span>
              <span className="ketamine-general-label" style={{}}>
                {items.length} / {dbItems.length} mapped
              </span>
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const TreatmentItem = ({ list, type }: any) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            expanded.includes(type) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(type)) {
              setExpanded(expanded.filter((item) => item !== type));
            } else {
              setExpanded([...expanded, type]);
            }
          }}
        >
          {expanded.includes(type) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <span className="ketamine-general-label" style={{ flex: 1 }}>
            {toTitleCase(type)}
          </span>
          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {list.length} items
          </span>
        </div>
        {expanded.includes(type) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={list} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          if (modalState.title === 'Audit Required') {
            setModalState({ ...modalState, isVisible: false });
          } else {
            setModalState({ ...modalState, isVisible: false });
            navigate('/database');
          }
        }}
        handleSubmit={() => {
          if (modalState.title === 'Audit Required') {
            handlePerformAudit(true);
          } else {
            setModalState({ ...modalState, isVisible: false });
            navigate('/database');
          }
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={'Audit Page'}
        description={
          'This will review all of the pairings to confirm they are correct and remove any unused pairings.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Audit"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit(false);
        }}
      />
      <MedicationViewModal
        visible={selectedMedication !== undefined}
        onClose={() => setSelectedMedication(undefined)}
        medication={selectedMedication as MedicationItem}
        db={info.database}
        displayProtID={true}
      />
      <label className="ketamine-general-label">
        Department: <span className="required-field">*</span>
      </label>
      <p className="sidebarText" style={{ marginLeft: '10px' }}>
        Select the department you want to perform the audit on.
      </p>
      <SearchableDropdown<DepartmentItem>
        id="searchDropdown"
        options={list}
        labelField={(option) => option.name}
        valueField={(option) => option.name}
        onChange={(option: DepartmentItem) => {
          setIsLoading('Loading ' + option.name + ' Data...');
          loadData(option);
        }}
        onClear={() => {
          setInfo({
            department: undefined,
            database: undefined,
          });
          setExpanded([]);
        }}
        placeholder="Search department..."
      />

      {info.database && info.department && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {info.department && info.department.logoVerifiedUrl && (
              <img
                className="large-dep-logo"
                src={info.department.logoVerifiedUrl}
                alt="Agency Logo"
              />
            )}
            {info.department && (
              <div
                className="large-dep-name"
                style={{
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              >
                {info.department.name}
              </div>
            )}
          </div>
          <div
            className="input-container roundBorder "
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
            }}
          >
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>ID:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.department.id}
                <span>
                  {isCopied && isCopied === info.department.id ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) => handleCopy(info.department.id, e)}
                    />
                  )}
                </span>
              </div>
            </div>
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>Location:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.department.location}
              </div>
            </div>
          </div>

          <TreatmentItem list={info.database.medications} type="medication" />
          <TreatmentItem list={info.database.infusions} type="infusions" />
          <TreatmentItem list={info.database.electrical} type="electricals" />
        </div>
      )}
    </div>
  );
};

export default AuditPage;
