import React from 'react';
import { InputText } from 'primereact/inputtext';
import { FormGroup } from '../../../../../models';

interface CheckListDoseProps {
  data?: FormGroup | any;
  groupIndex: number;
  isEdit?: boolean;
}

const CheckListGroup: React.FC<CheckListDoseProps> = (props) => {
  const { data, groupIndex, isEdit } = props;

  const countTheItems = () => {
    let count = 0;

    for (const item of data.options) {
      // If isDouble is false, only consider firstItem
      if (!data.isDouble) {
        if (item.firstItem && item.firstItem.trim() !== '') {
          count++;
        }
      } else {
        // If isDouble is true, consider both firstItem and secItem
        if (item.firstItem && item.firstItem.trim() !== '') {
          count++;
        }
        if (item.secItem && item.secItem.trim() !== '') {
          count++;
        }
      }
    }

    return count;
  };

  return (
    <>
      <div className="addDoseHeader">
        <span className="ketamine-general-label">Group {groupIndex + 1}</span>
      </div>
      <div
        className={`model-input-container  ${'cursorPointerDiv hoverableBackground'}`}
      >
        <div
          style={{ maxHeight: '77%', overflow: 'auto' }}
          className={`sidebarChecklistInputContainer "normal`}
        >
          <div>
            <label htmlFor="calculationBasis" className={`checkListTitle`}>
              Title
            </label>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                placeholder="Title"
                readOnly={true}
                disabled={true}
                value={data.title}
              />
              <div className="input-border"></div>
            </div>
          </div>
          <div className="checklist-threecontent">
            <div className="form-group">
              <label className={` ketamine-label`}>Options</label>
              <div className="p-input-icon-right" data-testid="editRangeLow">
                <InputText
                  type="text"
                  className={`form-control pointer "greyText"`}
                  value={countTheItems() + ' items'}
                  placeholder="Items"
                  readOnly={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor={''} className={` ketamine-label`}>
                Form Structure
              </label>
              <div className="p-input-icon-right" data-testid="editRangeLow">
                <InputText
                  type="text"
                  className={`form-control pointer "greyText"`}
                  value={data?.isDouble ? 'Double' : 'Single'}
                  readOnly={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor={''} className={` ketamine-label`}>
                Form Type
              </label>
              <div className="p-input-icon-right" data-testid="editRangeLow">
                <InputText
                  type="text"
                  className={`form-control pointer ${
                    data.isUrgent ? 'redText' : ''
                  }`}
                  value={data?.isUrgent ? 'Urgent' : 'Normal'}
                  readOnly={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckListGroup;
