import ProgressBar from '@asyrafhussin/react-progress-bar';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useMemo, useState } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import {
  getFormattedDate,
  globals,
  toTitleCase,
} from '../../../_global/common/Utils';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import QuestionSideout from '../../../components/SideOut/notificationSideOut/QuestionSideout';
import ViewUserSideout from '../../../components/SideOut/notificationSideOut/ViewUserSideout';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import NotificationItem from '../../../../data/model/NotificationItem';
import ReactLoading from 'react-loading';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import {
  deleteNotification,
  getFileFromS3,
  updateNotification,
} from '../../../../data/functions/NotificationDB';
import { Question, User } from '../../../../models';
import FullScreenModal from './FullScreeenModal';
import GenerateThumbnail from '../../../_global/common/GenerateThumbnail';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';

interface NotificationViewPageProps {
  _state?: any;
}
/* 11-29-23 praveen: Created the  common component for notification view page*/
const NotificationViewPage: React.FC<NotificationViewPageProps> = ({
  _state,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location.state;
  const dispatch = useDispatch();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const [notification, setNotification] = useState<NotificationItem>(state);

  const [isQuestionModal, setIsQuestionModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [isDelete, setIsDelete] = useState(false);
  const [isModalVisisble, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState('');
  const user: User = useSelector((state: any) => state.user);
  const [isDataChange, setisDataChange] = useState(false);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [viewList, setViewList] = useState<User[]>([]);
  const [ackList, setAckList] = useState<User[]>([]);
  const [notViewList, setNotViewList] = useState<User[]>([]);
  const [notAckList, setNotAckList] = useState<User[]>([]);
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [fullScreenIndex, setFullScreenIndex] = useState(-1);
  const [loadingAttachments, setLoadingAttachments] = useState(false);

  //initialize the formik values
  const formik = useFormik({
    initialValues: {
      title: notification.title,
      message: notification.message ? notification.message : '',
      notifyType: 'Silent',
      type: notification.type,
      trainingDeadline: notification.deadline,
      pairedProtocol: notification.taggedProtocols,
      question: notification.questions,
      user: notification.modifiedBy,
      viewPercentage: Math.round(
        (notification.isReadIDs.length / database.users.length) * 100
      ),
      ackPercentage: Math.round(
        (notification.isAckIds.length / database.users.length) * 100
      ),
      attachments: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Name is required'),
      message: Yup.string().required('Message is required'),
      notifyType: Yup.string(),
      type: Yup.string(),
      pairedProtocol: Yup.array(),
      question: Yup.array(),
      user: Yup.string(),
      trainingDeadline: Yup.date().nullable(),
      attachment: Yup.array(),
    }),
    onSubmit: (values) => {},
  });

  const objURLS = useMemo(
    () =>
      formik.values.attachments.map((file: File) => URL.createObjectURL(file)),
    [formik.values.attachments]
  );

  useEffect(() => {
    if (database) {
      const uniqueViewIds = new Set(notification.isReadIDs);
      const uniqueAckIds = new Set(notification.isAckIds);

      formik.setFieldValue(
        'viewPercentage',
        Math.round((uniqueViewIds.size / database.users.length) * 100)
      );
      formik.setFieldValue(
        'ackPercentage',
        Math.round((uniqueAckIds.size / database.users.length) * 100)
      );

      let viewList: User[] = [];
      let ackList: User[] = [];
      let notViewList: User[] = [];
      let notAckList: User[] = [];

      database.users.forEach((user) => {
        if (uniqueViewIds.has(user.id)) viewList.push(user);
        else notViewList.push(user);

        if (uniqueAckIds.has(user.id)) ackList.push(user);
        else notAckList.push(user);
      });

      setViewList(viewList);
      setAckList(ackList);
      setNotViewList(notViewList);
      setNotAckList(notAckList);
    }
  }, [database, notification.isReadIDs, notification.isAckIds]);

  const checkForChanges = () => {
    const titleChanged = formik.values.title !== notification.title;
    const messageChanged = formik.values.message !== notification.message;
    const pairedDepsChanged =
      JSON.stringify(pairedDeps.map((d) => d.id)) !==
      JSON.stringify(notification.pairedDepIDs);
    return titleChanged || messageChanged || pairedDepsChanged;
  };

  useEffect(() => {
    setisDataChange(checkForChanges());
  }, [formik.values.title, formik.values.message, pairedDeps]);

  const downloadAttachments = async () => {
    setLoadingAttachments(true);
    let respIndex = 0;
    let attachments: File[] = [];
    for (let i = 0; i < notification.fileURLs.length; i++) {
      try {
        let result: Response = await getFileFromS3(notification.fileURLs[i]);
        if (result.type == ResponseType.Success) {
          let file = result.data as File;
          attachments.push(file);
        } else {
          console.error(`Failed to get file from S3. Error: ${result.data}`);
        }
        respIndex++;
      } catch (e) {
        console.error(
          `An unexpected error occurred while downloading file. Error: ${e}`
        );
      }
    }
    formik.setFieldValue('attachments', attachments);
    setLoadingAttachments(false);
  };

  useEffect(() => {
    if (notification.fileURLs.length > 0) downloadAttachments();
  }, [notification.fileURLs]);

  const toggleEdit = () => {
    downloadAttachments();
  };

  useEffect(() => {
    if (notification.pairedDepIDs) {
      let l: DepartmentItem[] = [];
      notification.pairedDepIDs.forEach((id: string) => {
        let dep = department.subDeps?.find((d: DepartmentItem) => d.id === id);
        if (dep) l.push(dep);
      });
      setPairedDeps(l);
    }
  }, [notification.pairedDepIDs]);

  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [department, pairedDeps]);

  /* 11-29-23 praveen: function for return protocol main page*/
  const handleCancel = () => {
    navigate('/notification');
  };

  /* 11-29-23 praveen: open paired protocol sideout*/
  const handleEditProtocol = () => {
    // if (protocolData?.length >= 1) {
    //   setIsProtocolModalVisible(true)
    // }
  };

  /* 11-29-23 praveen: delete notification */
  const handleDeleteNotification = async () => {
    // const data: any = [...notification];
    // data.splice(stateData.index, 1);
    // dispatch<any>(handleSetNotification(data));
    if (globals.debug) console.log('Deleting notification', notification);
    let results: Response = await deleteNotification(notification);
    if (results.type == ResponseType.Success) {
      let deletedNotification = results.data as NotificationItem;
      if (globals.debug)
        console.log('Deleted notification', deletedNotification);
      navigate('/notification');
    } else alert('Failed to delete notification\nError: ' + results.data);
    setIsDelete(false);
  };

  /* 11-29-23 praveen: function for saving the changes*/
  const handleSave = async () => {
    if (formik.dirty) {
      const updatedNotifications = {
        ...notification,
        title: formik.values.title,
        message: formik.values.message,
        pairedDepIDs: pairedDeps.map((d) => d.id),
      };

      try {
        const response = await updateNotification(updatedNotifications, user);
        if (response.type === ResponseType.Success) {
          setIsEdit(false);
          navigate('/notification');
        } else {
          console.error('Failed to update notification:', response.data);
        }
      } catch (error) {
        console.error('Error updating notification:', error);
      }
    }
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  //progress value
  const handleGetProgress = (notification: NotificationItem) => {
    let amount =
      notification.type === 'GENERAL'
        ? notification.isReadIDs.length
        : notification.isAckIds.length;
    return {
      amount: amount,
      percentage: Math.round((amount / database.users.length) * 100),
    };
  };

  const handleViewFile = (e: any, selectedIndex: number) => {
    e.stopPropagation();
    setFullScreenIndex(selectedIndex);
  };

  return (
    <div className="screen-container">
      <FullScreenModal
        isVisible={fullScreenIndex >= 0}
        objURLs={objURLS}
        files={formik.values.attachments}
        onClose={() => {
          setFullScreenIndex(-1);
        }}
        startIndex={fullScreenIndex}
      />
      <ViewUserSideout
        isVisible={isModalVisisble}
        handleClose={() => {
          setIsModalVisible(false);
        }}
        type={type}
        users={type === 'viewed' ? viewList : ackList}
      />
      {/* {isProtocolModalVisisble && <NotificationProtocolSideout
        isVisible={isProtocolModalVisisble}
        handleClose={() => { setIsProtocolModalVisible(false) }}
        data={protocolData}
        isView={true}
      />} */}
      <QuestionSideout
        dialogVisible={isQuestionModal}
        onClose={() => {
          setIsQuestionModal(false);
          setSelectedData(null);
        }}
        handleSet={() => {}}
        headerText="Notification Questions"
        question={selectedData}
        isView={true}
      />
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Confirm Delete?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeleteNotification}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want to delete this notification?"
        />
      )}
      <div className="fixedHeader">
        <ProtocolHeader
          name={`${notification.title}`}
          description={getFormattedDate(notification.timestamp, false)}
          type={'protocol'}
          page={'Notifications'}
          isEditIcon={!isEdit}
          isBackButton={true}
          isDeleteButton={true}
          isSaveActive={isDataChange}
          isDotButton={true}
          rightSideBtn={'edit'}
          handleEdit={() => {
            setIsEdit(true);
          }}
          handleDelete={() => {
            setIsDelete(true);
          }}
          isSaveButton={isEdit}
          handleSave={() => {
            if (checkForChanges()) {
              handleSave();
            }
          }}
          handleCancelEdit={() => {
            if (formik.dirty) formik.resetForm();
            setIsEdit(false);
            toggleEdit();
          }}
          handleCancel={handleCancel}
        />
      </div>
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Notification</h5>
          <label htmlFor="Title" className="ketamine-general-label">
            General
          </label>
          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {notification.modifiedBy
                    ? notification.modifiedBy?.firstName +
                      ' ' +
                      notification.modifiedBy?.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {notification.timestamp && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Publish Date:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(notification.timestamp, true)}
                  </div>
                </div>
              )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Style:</div>
                <div style={{ fontWeight: '500' }}>
                  {notification.isPush ? 'Push' : 'Silent'}
                </div>
              </div>
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Type:</div>
                <div style={{ fontWeight: '500' }}>
                  {toTitleCase(notification.type)}
                </div>
              </div>
            </div>
          </div>
          <label htmlFor="title" className={`notification-css-title`}>
            Title
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="  form-control-general notificatonTextColor cursorPointer titleForm"
              id="title"
              name="title"
              value={formik.values.title}
              data-testid="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly={!isEdit}
              style={
                isEdit ? { cursor: 'pointer' } : { cursor: 'context-menu' }
              }
            />
            <div className="input-border"></div>
          </div>
          {formik.touched.title && formik.errors.title && (
            <div className="error-message">{formik.errors.title}</div>
          )}
          {notification.type === 'TRAINING' && (
            <div style={{ width: '100%' }}>
              <label htmlFor="notifyType" className={`notification-css-title`}>
                Training Deadline
              </label>
              {!isEdit && !formik.values.trainingDeadline && (
                <div
                  className="contentBorder"
                  style={{
                    marginTop: '10px',
                    height: '40px',
                    display: 'flex',
                  }}
                >
                  <div
                    className="contentHeadingBold"
                    style={{ color: '#616161' }}
                  >
                    No Deadline
                  </div>
                </div>
              )}
              {(isEdit || (!isEdit && formik.values.trainingDeadline)) && (
                <div className="input-container-we cursorPointer">
                  <Calendar
                    value={formik.values.trainingDeadline}
                    className="calenderWidthStyle"
                    showIcon
                    data-testid="training_calender"
                    onChange={(e) => {
                      formik.setFieldValue('trainingDeadline', e.value);
                    }}
                    disabled={!isEdit}
                    style={
                      isEdit
                        ? { cursor: 'pointer' }
                        : { cursor: 'context-menu' }
                    }
                  />
                </div>
              )}
            </div>
          )}
          {notification.taggedProtocols.length >= 1 && (
            <div>
              <label htmlFor="question" className={`notification-css-title`}>
                Paired Protocol
              </label>
              <div
                data-div-id="outsideRoute"
                onClick={handleEditProtocol}
                data-testid="edit-button"
                className={`flex_wrap  ${notification.taggedProtocols.length ? 'cursorPointer protocol-hover  ' : 'centerContent protocol-not-hover'}`}
              >
                <ViewportList items={notification.taggedProtocols}>
                  {(item: any, index: any) => {
                    return (
                      <div
                        key={index + item}
                        className={`selectedGreybox`}
                        style={{ margin: '4px 2px' }}
                      >
                        <div>{item?.name}</div>
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            </div>
          )}
          {notification.type === 'TRAINING' &&
            formik.values.question?.length >= 1 && (
              <div style={{ marginTop: '0px' }}>
                <label
                  data-testid="question"
                  htmlFor="question"
                  className={`notification-css-title`}
                >
                  Question
                </label>
                <div className="contentBorder cursorPointer ">
                  <ViewportList items={formik.values.question}>
                    {(question: Question, index: number) => (
                      <div
                        className="listhover"
                        style={
                          formik.values.question?.length !== index + 1
                            ? { borderBottom: '1px solid #e0e0e0' }
                            : {}
                        }
                      >
                        <div
                          onClick={() => {
                            setSelectedData(question);
                            setIsQuestionModal(true);
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0px 5px',
                            fontSize: '15px',
                          }}
                        >
                          <div className="contentHeading">
                            {question.question}
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div className="contentHeading">
                              {question.options.length} answers
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '5px',
                              }}
                            >
                              {' '}
                              <IoChevronForward
                                size="1.5rem"
                                style={{ marginRight: '10px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ViewportList>
                </div>
              </div>
            )}
          <label htmlFor="notifyType" className={`notification-css-title`}>
            Message
          </label>
          <InputTextarea
            className="notificatonText form-control-general"
            id="message"
            name="message"
            required={true}
            value={formik.values.message}
            data-testid="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            readOnly={!isEdit}
            style={{ height: '110px', verticalAlign: 'top' }}
          />
          {formik.touched.message && formik.errors.message && (
            <div className="error-message">{formik.errors.message}</div>
          )}
          {department.subDeps && department.subDeps.length > 0 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {pairedDeps.length} /{' '}
                  {department.subDeps.length}
                  {isEdit && (
                    <span
                      onClick={() =>
                        formik.setFieldValue('pairedDeps', department.subDeps)
                      }
                    >
                      <div className="clickableText">Add All</div>
                    </span>
                  )}
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that the user subscribes to.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                multiSelect={true}
                isActive={isEdit}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  // overflowY: 'auto',
                  // maxHeight: '200px',
                  border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      {isEdit && (
                        <FaTimes
                          className="icon-cancel"
                          size={16}
                          onClick={(e) => handleRemoveDepartment(item, e)}
                        />
                      )}
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Information</h5>
          <label htmlFor="Title" className="ketamine-general-label">
            General
          </label>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '20px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Viewed:</div>
              <div style={{ flex: 1, marginTop: '-20px' }}>
                <div
                  className="lightText"
                  style={{ justifyContent: 'center', color: '#000' }}
                >
                  {formik.values.viewPercentage}%
                </div>
                <ProgressBar
                  completed={formik.values.viewPercentage}
                  height={'10px'}
                  width={'100%'}
                  bgColor={'#00534C'}
                  isLabelVisible={false}
                />
              </div>
              <div
                className="contentJustifyCenter"
                style={{ marginLeft: '10px', marginRight: '10px' }}
              >
                ({viewList.length} / {database.users.length})
              </div>
              <div
                onClick={() => {
                  if (notification.isReadIDs.length > 0) {
                    setIsModalVisible(true);
                    setType('viewed');
                  }
                }}
                className={`'cursorPointer contentJustifyCenter' ${notification.isReadIDs.length > 0 ? ' contentTextgreenColor' : ' contentTextInactive'}`}
                style={{ marginRight: '10px' }}
              >
                View
                <IoChevronForward style={{ marginRight: '5px' }} />
              </div>
            </div>

            {notification.type !== 'GENERAL' && (
              <div
                style={{ display: 'flex', marginTop: '20px', width: '100%' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Acknowledge:</div>
                <div style={{ flex: 1, marginTop: '-20px' }}>
                  <div
                    className="lightText"
                    style={{ justifyContent: 'center', color: '#000' }}
                  >
                    {formik.values.ackPercentage}%
                  </div>
                  <ProgressBar
                    completed={formik.values.ackPercentage}
                    height={'10px'}
                    width={'100%'}
                    bgColor={'#00534C'}
                    isLabelVisible={false}
                  />
                </div>
                <div
                  className="contentJustifyCenter"
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                >
                  ({ackList.length} / {database.users.length})
                </div>
                <div
                  onClick={() => {
                    if (ackList.length > 0) {
                      setIsModalVisible(true);
                      setType('acknowledged');
                    }
                  }}
                  className={`'cursorPointer contentJustifyCenter' ${notification.isAckIds.length > 0 ? ' contentTextgreenColor' : ' contentTextInactive'}`}
                  style={{ marginRight: '10px' }}
                >
                  View
                  <IoChevronForward style={{ marginRight: '5px' }} />
                </div>
              </div>
            )}
          </div>

          {notification.fileURLs.length > 0 && (
            <>
              <div>
                <label
                  htmlFor="Title"
                  className="ketamine-general-label"
                  style={{ marginTop: '10px' }}
                >
                  Attachments: ({notification.fileURLs.length})
                </label>
              </div>
              {formik.values.attachments.length === 0 && (
                <div style={{ width: '100%', marginLeft: '45%' }}>
                  <ReactLoading
                    className="load"
                    type="bubbles"
                    height={60}
                    width={60}
                  />
                </div>
              )}
              <div className="image-gallery previewImage">
                {formik.values.attachments?.map((file: File, index: number) => (
                  <div key={index}>
                    {file.type.startsWith('image') && (
                      <div
                        className="cursorPointer"
                        style={{ width: '150px', marginTop: '10px' }}
                        onClick={(e) => handleViewFile(e, index)}
                      >
                        <img
                          src={objURLS[index]}
                          alt={file.name}
                          style={{ width: '150px' }}
                        />
                      </div>
                    )}
                    {file.type.startsWith('video') && (
                      <GenerateThumbnail
                        file={file}
                        onClick={(e: any) => handleViewFile(e, index)}
                      />
                      // <div className='cursorPointer' style={{ width: '133px', marginTop: '10px' }} onClick={(e) => handleViewFile(e, index)}>
                      //   <video src={objURLS[index]} controls style={{ width: '133px' }} />
                      // </div>
                    )}
                    {file.type === 'application/pdf' && (
                      <GenerateThumbnail
                        file={file}
                        onClick={(e: any) => handleViewFile(e, index)}
                      />
                      // <div className='cursorPointer' style={{ width: '100', marginTop: '10px' }} onClick={(e) => handleViewFile(e, index)}>
                      //   <embed src={objURLS[index]} width="150" height="85" style={{zIndex: 1}} onClick={(e) => handleViewFile(e, index)}/>
                      //   {/*Create a clickable item over this one to register the touch */}
                      //   <div style={{ position: 'absolute', top: 0, left: 0, width: '150px', height: '85px', zIndex: 2}} onClick={(e) => handleViewFile(e, index)}></div>
                      // </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationViewPage;
