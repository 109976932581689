import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import { ViewportList } from 'react-viewport-list';
import { detectedChangesMockData } from '../../../_global/constants/Protocol_constants';
import SnapshotModal from '../../Modal/SnapshotModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import {
  getDraftUpdates,
  publishDraftUpdates,
} from '../../../../data/AmplifyVersion';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { Response, ResponseType } from '../../../../data/AmplifyDB';
import { User } from '../../../../models';
import { useSelector } from 'react-redux';
import {
  getFormattedDate,
  getFormattedDateTime,
  globals,
} from '../../../_global/common/Utils';
import Loading from '../../Loading/Loading';
import { set } from 'lodash';
import { BiArrowBack } from 'react-icons/bi';

interface ReviewSideOutProps {
  department: DepartmentItem;
  handleClose?: any;
  handleAdd?: () => void | any;
  isVisible?: boolean;
  setVisible: (visible: boolean) => void;
  onPublish: () => void | any;
}

/* 11-10-23 Arul: Created the review side out component for Protocol */
const ReviewSideOut: React.FC<ReviewSideOutProps> = (props) => {
  const { isVisible, handleClose, setVisible, department, onPublish } = props;
  const user: User = useSelector((state: any) => state.user);
  const [isSnapshotOnPublishChecked, setIsSnapshotOnPublishChecked] =
    useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [isSnapshotModalVisible, setIsSnapshotModalVisible] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [draftList, setDraftList] = useState<any[]>([]);

  useEffect(() => {
    loadDetectedChanges();
  }, []);

  //function for snapshot modal submit
  const handleSnapshotSubmit = async () => {
    setIsSnapshotModalVisible(false);
    setIsPublishing(true);
    let results = await publishDraftUpdates(department);
    if (results.type === ResponseType.Success) {
      let updates = results.data;
      onPublish();
      setIsReviewModalVisible(true);
    } else {
      if (globals.debug) console.log('Failed to publish updates', results);
    }
    setIsPublishing(false);
  };

  //function for publish button onclick
  const handlePublishClick = () => {
    if (isSnapshotOnPublishChecked) {
      setIsSnapshotModalVisible(true);
    } else {
      handleSnapshotSubmit();
    }
  };
  const loadDetectedChanges = async () => {
    const response: Response = await getDraftUpdates(department);
    if (response.type === ResponseType.Success) {
      if (globals.debug) console.log(response.data);
      setDraftList(response.data);
    }
  };
  return (
    <div>
      <SnapshotModal
        dialogVisible={isSnapshotModalVisible}
        setDialogVisible={setIsSnapshotModalVisible}
        headerText="Your Snapshot Modal Header Text"
        handleSnapshotSubmit={handleSnapshotSubmit}
      />

      {isReviewModalVisible && (
        <ConfirmModal
          isVisible={isReviewModalVisible}
          title="Successfully Published, Notify?"
          handleClose={() => {
            setIsReviewModalVisible(false);
            handleClose();
          }}
          handleSubmit={() => {
            setIsReviewModalVisible(false); // Close the Review Modal
            setIsSnapshotModalVisible(false);
            handleClose();
          }}
          isDeleteBtn={false}
          primaryBtnName="Dismiss"
          secondaryBtnName="Accept"
          primaryDescription="The updates have successfully been published to the OneDose application."
          secondaryDescription="Would you like to go to the notification page to create a notification for the update?"
        />
      )}
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleClose}
        style={{ width: '30%' }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
      >
        <div className="sidebarContainer">
          <div>
            <div
              className="buttonContainer contentTitleLarge hoverText"
              onClick={handleClose}
            >
              <span className="">
                <BiArrowBack className="header-icon" style={{}} /> Review
              </span>
            </div>
            <div className="contentText">
              The changes that you have done in the folder path will be
              published to the active version of OneDose here. Make sure
              everything looks right.
            </div>
            <div className="contentLabel">Revision Information</div>
            <div>
              <div className="infolist">
                <div>Published By:</div>
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </div>
              <div className="infolist">
                <div>Publish Date:</div>
                <div>{getFormattedDate(new Date(), true)}</div>
              </div>
            </div>
            {isPublishing && (
              <div className="loading">
                <Loading type="bubbles" />
                <div className="loadingText">Publishing...</div>
              </div>
            )}
            {!isPublishing && (
              <div>
                <div className="contentLabelBold">
                  Detected Changes: {draftList.length}
                </div>
                <div className="contentBorder protocolCalculationPad reviewListScroll">
                  <ViewportList items={draftList}>
                    {(item: any, index: any) => (
                      <div
                        key={item + index}
                        className={`listhover spaceBetween cursorPointer item contentHeading ${draftList.length !== index + 1 ? 'contentUnderline' : ''}`}
                      >
                        <div>{item.message}</div>
                      </div>
                    )}
                  </ViewportList>
                </div>
                {/* <div className='btn_Bottom review_btn_Bottom' style={{ left: 0 }}>
                                    <div style={{ marginBottom: '20px' }}>
                                        <div className='colAlignCenter'>
                                            <input
                                                className='checkBoxSelectedColor custom-checkbox-button-input'
                                                type="checkbox"
                                                id="reviewCheckbox"
                                                checked={isSnapshotOnPublishChecked}
                                                onChange={() => setIsSnapshotOnPublishChecked(!isSnapshotOnPublishChecked)}
                                            />
                                            <div className="contentHeading">Create a snapshot on publish</div>
                                        </div>

                                    </div>
                                </div> */}
              </div>
            )}
          </div>
          <div className="btn_Bottom ">
            <Button
              className="secondary-button btn-rightMargin"
              onClick={() => setVisible(false)}
            >
              Dismiss
            </Button>
            <Button className="primary-button" onClick={handlePublishClick}>
              Publish
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ReviewSideOut;
