import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import { TbDatabaseOff } from 'react-icons/tb';
import { MdCreateNewFolder } from 'react-icons/md';
import WorkbookItem from '../../../../data/model/WorkbookItem';
import { DataStore } from 'aws-amplify';
import { User, Workbook, Department } from '../../../../models';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import SearchBar from '../../../components/Search/SearchBar';
import {
  CalculateByteSting,
  getFormattedDate,
} from '../../../_global/common/Utils';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import Status from '../../../components/ProgressStatus/ProgressStatus';

type WorkbookType = {
  workbook: WorkbookItem;
  department: Department | undefined;
};

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListAllWorkbooks = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const user: User = useSelector((state: any) => state.user);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state && state.search ? state.search.searchQuery : ''
  );
  const [workbookList, setWorkbookList] = useState<WorkbookType[]>([]);
  const [list, setList] = useState<WorkbookType[]>([]);

  useEffect(() => {
    const getDetails = async () => {
      const workbooks = await DataStore.query(Workbook, (wb) =>
        wb.status.eq('ACTIVE')
      );
      const departmentList = await DataStore.query(Department);
      let list = workbooks.map((item: Workbook) => {
        let department = departmentList.find(
          (dep) => dep.id === item.departmentID
        );
        return {
          workbook: new WorkbookItem(item, user),
          department: department,
        } as WorkbookType;
      });
      setWorkbookList(list);
      setList(list);
    };
    getDetails();
  }, [user]);

  useEffect(() => {
    if (sort) {
      const sortedList = list.sort((a, b) => {
        if (sort === 'name_desc')
          return a.workbook.name.localeCompare(b.workbook.name);
        else if (sort === 'modified_asc') {
          if (!a.workbook.model.updatedAt || !b.workbook.model.updatedAt)
            return 0;
          const aDate = new Date(a.workbook.model.updatedAt);
          const bDate = new Date(b.workbook.model.updatedAt);
          return aDate.getTime() - bDate.getTime();
        } else if (sort === 'modified_desc') {
          if (!a.workbook.model.updatedAt || !b.workbook.model.updatedAt)
            return 0;
          const aDate = new Date(a.workbook.model.updatedAt);
          const bDate = new Date(b.workbook.model.updatedAt);
          return bDate.getTime() - aDate.getTime();
        } else if (sort === 'file_size_asc')
          return a.workbook.fileSize - b.workbook.fileSize;
        else if (sort === 'file_size_desc')
          return b.workbook.fileSize - a.workbook.fileSize;

        return b.workbook.name.localeCompare(a.workbook.name);
      });
      setList(sortedList);
    }
  }, [sort]);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    const filteredList = workbookList.filter((item: WorkbookType) => {
      return item.workbook.name.toLowerCase().includes(query);
    });
    setList(filteredList);
  };

  const handleItemClick = (wb: WorkbookItem) => {
    const state = {
      list: workbookList,
      item: wb,
      searchState: {
        searchQuery: searchQuery,
      },
    };
    navigate(`/actions/models/view-protocol-set`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '3fr 2fr 2fr 1fr' + (user.type === 'ADMIN' ? ' 1fr' : ''), // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/actions/models`);
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        handleCancel={handleBack}
        name={'Protocol Sets: ' + list.length + ' items'}
        rightSideBtn={'edit'}
        isEditButton={false}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <SearchBar
        containerStyle={{ width: '60%' }}
        value={searchQuery}
        onChange={(searchTerm: string) => {
          handleSearchChange(searchTerm);
        }}
        onSubmit={(searchTerm: string) => {}}
        placeholder={'Search Protocol Sets...'}
      />

      <div>
        <div className="accordion-container">
          <div
          // style={{borderBottom: '1px solid #ccc'}}>
          >
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('name')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'name_desc') setSort('name_asc');
                    else setSort('name_desc');
                  }}
                >
                  Name
                  <span>
                    {sort === 'name_desc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('modified')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'modified_desc') setSort('modified_asc');
                    else setSort('modified_desc');
                  }}
                >
                  Modified Date
                  <span>
                    {sort === 'modified_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
                <h6 className="departmentItemText">Department</h6>
                {user.type === 'ADMIN' && (
                  <h6 className="departmentItemText">AI Parsed</h6>
                )}
                <h6
                  className="departmentItemTextClickable"
                  style={{
                    textDecoration: sort.includes('file_size')
                      ? 'underline'
                      : 'none',
                  }}
                  onClick={() => {
                    if (sort === 'file_size_desc') setSort('file_size_asc');
                    else setSort('file_size_desc');
                  }}
                >
                  File Size
                  <span>
                    {sort === 'file_size_asc' ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                </h6>
              </div>
              <hr style={{ margin: 0, padding: 0 }} />
            </Accordion>
          </div>
          {workbookList.length == 0 && (
            <div className="no-data-container" style={{ marginTop: '15vh' }}>
              <MdCreateNewFolder size={220} className="light-grey-icon" />
              <h4 className="light-grey-icon">No workbooks created...</h4>
            </div>
          )}

          <ViewportList items={list}>
            {(item: WorkbookType, index) => {
              return (
                <div
                  key={item.workbook.uid + index}
                  style={{
                    borderBottom:
                      index !== list.length - 1 ? '1px solid #ccc' : 'none',
                  }}
                  onClick={() => handleItemClick(item.workbook)}
                >
                  <div style={rowStyle} className="departmentItem">
                    {item.workbook.model?.status && (
                      <h6 className="departmentItemText">
                        {item.workbook.name}
                        <span>
                          <Status status={item.workbook.model?.status} />
                        </span>
                      </h6>
                    )}
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item.workbook.model?.updatedAt
                          ? item?.workbook.model?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">
                      {item.department?.name}
                    </h6>
                    {user.type === 'ADMIN' && (
                      <h6 className="departmentItemText">
                        {item.workbook.aiPdfParserResults != null
                          ? 'True'
                          : '-'}
                      </h6>
                    )}
                    {/* {department.subDeps && <h6 className='departmentItemText' style={{color: item.pairedDepIDs?.length ? 'black' : 'grey'}}>{item.pairedDepIDs?.length ? item.pairedDepIDs.length : '-'}</h6>} */}
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {CalculateByteSting(item.workbook.fileSize)}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListAllWorkbooks;
