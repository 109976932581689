import { useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import React, { useRef, useState } from 'react';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import ReactLoading from 'react-loading';
import { processDosesExcelFile } from '../medicationDoses/ProcessExcelUtils';
import {
  DatabaseResponse,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import { globals } from '../../../../../_global/common/Utils';
import { processProtocolDosesExcelFile } from './ProcessProtocolExcelUtils';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ImportProtocolDosesPage = (props: any) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = React.useState<any>();
  const [isDragActive, setIsDragActive] = React.useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const dropRef = useRef(null);
  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );
  const [data, setData] = useState<any>();
  const handleBack = () => {
    navigate(`/actions/import`);
  };

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    handleProcessFileUpload(file);

    if (e.target.files.length) {
      const data = e.target.files[0];
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    /* Check if the file is a pdf */

    const file: File = e.dataTransfer?.files[0];
    /* Check to make sure it is a excel workboox */
    if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setSelectedFile(file);
      handleProcessFileUpload(file);
      setErrorText('');
    } else {
      setErrorText('Only Excel Workbook files are allowed');
    }
    setIsDragActive(false);
  };

  const handleProcessFileUpload = async (file: File) => {
    processProtocolDosesExcelFile(file, database)
      .then((response) => {
        if (response.type === ResponseType.Success) {
          if (globals.debug) console.log('Success', response.data);
          navigate(`/actions/import-medication-doses/results`, {
            state: {
              data: response.data,
              file: file,
              database: database,
            },
          });
        } else {
          if (globals.debug)
            console.log(
              'Error processing medication doses excel file: ' + response.data
            );
          setSelectedFile(undefined);
          setErrorText('Error processing medication doses excel file');
        }
        // setSelectedFile(undefined);
      })
      .catch((error) => {
        if (globals.debug)
          console.log('Error processing medication doses excel file: ' + error);
        setSelectedFile(undefined);
        setErrorText('Error processing medication doses excel file ' + error);
        // setSelectedFile(undefined);
      });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Import'}
        name={'Import Protocol Medication Doses'}
        description={
          'Requires specific excel format and the pairings to the protocols already defined.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div style={{ flex: 1, display: 'flex', ...props.containerStyle }}>
        {selectedFile ? (
          <div
            className="drag-drop-overlay"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              // background: 'rgba(0, 0, 0, 0.3)',
            }}
          >
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '36px',
                fontWeight: 'bold',
                color: '#8CB181',
                marginTop: '10px',
              }}
            >
              Processing file
            </div>
            <ReactLoading
              className="load light"
              type="bubbles"
              height={48}
              width={48}
            />
          </div>
        ) : (
          <div
            className="cursorPointer dragDropDotGridModalWorkbook"
            style={{
              display: 'flex',
              flex: 1,
              background: isDragActive ? '#E0EADD' : 'transparent',
              padding: '20px 0px',
              border: isDragActive
                ? '1px dashed #00534C'
                : errorText
                  ? '1px dashed #880808'
                  : '1px dashed #cdc8c8',
            }}
            onClick={() => {
              const fileInput = document.getElementById('fileInput');
              fileInput?.click();
            }}
            ref={dropRef}
            onDragOver={(e) => {
              e.preventDefault();
              setIsDragActive(true);
            }}
            onDragLeave={(e) => {
              e.preventDefault();
              setIsDragActive(false);
            }}
            onDrop={handleDrop}
          >
            <input
              type="file"
              accept=".xlsx"
              className="pdfInputBox"
              id="fileInput"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            {errorText !== '' && (
              <div
                className="errorText"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                  fontSize: '16px',
                }}
              >
                {errorText}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              {isDragActive ? (
                <RiUpload2Line className="drag-drop-icon" />
              ) : (
                <RiDownload2Line className="drag-drop-icon" />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              Drag and Drop
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              or{' '}
              <span style={{ marginLeft: '5px' }} className="green_accent_text">
                {' '}
                Browse
              </span>
            </div>
            <div
              style={{ fontSize: '12px' }}
              className="grey_text ketamine-general-label"
            >
              Support: EXCEL ONLY
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportProtocolDosesPage;
