import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaChevronRight, FaFolderOpen } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { handleDataTableRowSelection } from '../../../../../store/actions';
import { getFormattedDate, globals } from '../../../../_global/common/Utils';
import { last, set } from 'lodash';
import CategoryItem from '../../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { User } from '../../../../../models';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { ViewportList } from 'react-viewport-list';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import Status from '../../../../components/ProgressStatus/ProgressStatus';
import { IoIosDocument } from 'react-icons/io';
import { CgFileDocument } from 'react-icons/cg';
import { IoDocumentText } from 'react-icons/io5';

interface ProtocolDataTableProps {
  handleTabClick: (
    item: CategoryItem | ProtocolItem,
    isDoubleClick: boolean
  ) => void;
  protocolData: (CategoryItem | ProtocolItem)[];
  newButtonRef?: any;
  isRename?: boolean;
  isModalOpen?: boolean;
  isEditFolderModalOpen?: boolean;
  handleRename?: any;
  handleReorder: (items: (CategoryItem | ProtocolItem)[]) => void;
}

const reorder = (
  list: (CategoryItem | ProtocolItem)[],
  startIndex: number,
  endIndex: number
): (CategoryItem | ProtocolItem)[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/* 10-10-2023 Arul: Created the seperate datatable component for Edit Folder Page*/
const ProtocolDataTable: React.FC<ProtocolDataTableProps> = ({
  handleTabClick,
  handleRename,
  isModalOpen,
  isEditFolderModalOpen,
  isRename,
  protocolData,
  newButtonRef,
  handleReorder,
}) => {
  const [folderData, setFolderData] = useState<any>([]);
  const dataTableRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const tableData = useSelector((state: any) => state?.protocol?.editDatatable);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const department: DepartmentItem = database?.department;
  const [inputValue, setInputValue] = useState<any>('');
  const [nameChanged, setNameChanged] = useState<any>(false);
  const [isEditFolder, setIsEditFolder] = useState<any>(isEditFolderModalOpen);
  const [lastSelectedTime, setLastSelectedTime] = useState<any>(null);
  const [isHovered, setIsHovered] = useState<string | null>(null);

  /* 10-20-2023 Arul: initialize the selected category and protocol name*/
  useEffect(() => {
    setInputValue(tableData.selectedRowData?.name);
    setNameChanged(false);
  }, [tableData.selectedRowData]);

  useEffect(() => {
    setIsEditFolder(isEditFolderModalOpen);
  }, [isEditFolderModalOpen]);

  /* 10-20-2023 Arul: Update the Row selection and selected row data in state*/
  const handleUpdataRowSelection = (isSelected: boolean) => {
    if (!isModalOpen) {
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    }
  };

  // function for dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      folderData,
      result.source.index,
      result.destination.index
    );
    setFolderData(updatedItems);
    /* Udpate the database here */
    handleReorder(updatedItems);
    // onReorder(updatedItems, type);
  };

  /* 10-20-2023 Arul: Mapping the table data in setstate*/
  useEffect(() => {
    if (protocolData?.length) {
      setFolderData(protocolData);
    } else {
      setFolderData([]);
    }
  }, [protocolData]);

  /* 10-16-2023 Arul: Removing the row selection while clicking apart from the table Row*/
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isModalOpen, inputValue, isEditFolder]);

  /* 10-16-2023 Arul: function for clicking outside to remove selection and update rename*/
  const handleDocumentClick = (e: any) => {
    if (dataTableRef?.current?.contains(e.target) || isRename || isEditFolder) {
      return;
    }
    if (globals.debug)
      console.log(
        'DOCUMENT CLICKED',
        inputValue,
        nameChanged,
        isEditFolderModalOpen
      );
    if (tableData?.selectedRowData) handleUpdataRowSelection(false);
    // handleUpdataRowSelection(false);
    if (nameChanged && inputValue !== '') handleRename(inputValue);
  };

  const iconBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    if (item instanceof CategoryItem) {
      return (
        <div className={isSelected ? 'column_selected' : 'column_unselected'}>
          <FaFolderOpen className="icon-normal" style={{ margin: '4px' }} />
        </div>
      );
    } else {
      return (
        <div className={isSelected ? 'column_selected' : 'column_unselected'}>
          <AiOutlineFileText
            className="icon-normal"
            style={{ margin: '4px' }}
          />
        </div>
      );
    }
  };

  const arrowIconBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    return (
      <div
        className={isSelected ? 'column_selected' : 'column_unselected'}
        style={{ justifyContent: 'flex-end' }}
      >
        <FaChevronRight className="icon-normal " style={{ margin: '4px' }} />
      </div>
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (globals.debug) console.log('ENTER PRESSED', inputValue, nameChanged);
      if (nameChanged && inputValue !== '') handleRename(inputValue);
    }
  };

  const nameBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    const handleInputChange = (e: any) => {
      setNameChanged(true);
      setInputValue(e.target.value);
    };
    return (
      <div>
        {isRename && isSelected ? (
          /* 10-20-2023 Arul:  Rename textinput section*/
          <span
            className={isSelected ? 'column_selected' : 'column_unselected'}
          >
            <form
              onSubmit={() => {
                handleRename(inputValue);
              }}
            >
              <InputText
                value={inputValue}
                autoFocus
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{ padding: '10px', margin: '-10px' }}
              />
            </form>
          </span>
        ) : (
          <div className={isSelected ? 'column_selected' : 'column_unselected'}>
            <div
              className="hoverableText"
              onClick={(e) => {
                e.stopPropagation();
                handleTabClick(item, true);
              }}
            >
              {item.index} - {item?.name}
              {item.status === 'DRAFT' ? ' (DRAFT)' : ''}
            </div>
          </div>
        )}
      </div>
    );
  };

  const modifiedAtBodyTemplate = (
    item: CategoryItem | ProtocolItem,
    index: any
  ) => {
    const isSelected = item.equals(tableData.selectedRowData);
    return (
      <div data-testid={`selectData-${index.rowIndex}`}>
        <span className={isSelected ? 'column_selected' : 'column_unselected'}>
          {getFormattedDate(
            item?.model?.createdAt ? item?.model?.createdAt : new Date(),
            true
          )}
        </span>
      </div>
    );
  };

  const modifiedUserBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    const userName: string = item?.modifiedBy
      ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
      : 'Hinckley Medical';
    return (
      <span className={isSelected ? 'column_selected' : 'column_unselected'}>
        {userName}
      </span>
    );
  };

  const fileSizeBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    return (
      <span className={isSelected ? 'column_selected' : 'column_unselected'}>
        {item?.getSum()}
      </span>
    );
  };

  /* 10-11-2023  Arul:Function to handle the single onrow click */
  const handleRowClick = (item: ProtocolItem | CategoryItem) => {
    handleRename('');
    if (tableData?.selectedRowData?.name === item?.name) {
      /* 10-19-2023  Arul:Update the row unselection in state*/
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    } else {
      /* 10-19-2023  Arul:Update the row selection in state*/
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: item }));
    }
    // let target = event?.originalEvent?.target;
    // if (target?.nodeName !== 'DIV') {
    //     handleRename('');
    //     if (tableData?.selectedRowData?.name === event?.data?.name) {
    //         /* 10-19-2023  Arul:Update the row unselection in state*/
    //         dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    //     } else {
    //         /* 10-19-2023  Arul:Update the row selection in state*/
    //         dispatch<any>(handleDataTableRowSelection({ selectedRowData: event?.data }));
    //     }
    // }
  };

  const handleInputChange = (e: any) => {
    setNameChanged(true);
    setInputValue(e.target.value);
  };

  const handleItemClick = (e: any, item: CategoryItem | ProtocolItem) => {
    e.stopPropagation();
    if (lastSelectedTime && new Date().getTime() - lastSelectedTime < 300)
      handleTabClick(item, true);
    else handleRowClick(item);
    setLastSelectedTime(new Date().getTime());
  };

  return (
    <div>
      <div
        className="editDataTableHeader"
        style={{
          display: 'flex',
          padding: '0px 20px',
        }}
      >
        {/* <span
          style={{
            flex: 1,
            textAlign: 'start',
          }}
        >
          <input
            className="icon-normal"
            style={{ marginLeft: '5px' }}
            type="checkbox"
            checked={false}
            onChange={() => {
              // handleRowClick(item);
            }}
          />
        </span> */}
        <span
          style={{
            flex: 1,
          }}
        ></span>
        <span style={{ textAlign: 'start', flex: 10 }}>Name</span>
        <span
          style={{
            flex: 6,
          }}
        >
          Modified At
        </span>
        <span
          style={{
            flex: 6,
          }}
        >
          Modified By
        </span>
        {department.subDeps && department.subDeps.length > 0 && (
          <span
            style={{
              flex: 6,
            }}
          >
            Department Subs
          </span>
        )}
        <span
          style={{
            flex: 6,
          }}
        >
          Access
        </span>

        <span
          style={{
            flex: 2,
          }}
        >
          File Size
        </span>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="protocolListContainer"
            >
              <ViewportList items={folderData}>
                {(item: CategoryItem | ProtocolItem, index: number) => {
                  const isSelected = item.equals(tableData.selectedRowData);
                  const userName: string = item?.modifiedBy
                    ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
                    : 'Hinckley Medical';
                  return (
                    <Draggable
                      key={item.uid}
                      draggableId={item.uid}
                      // isDragDisabled={true}
                      index={index}
                    >
                      {(provided: any) => (
                        <li
                          key={index}
                          onClick={(e) => handleItemClick(e, item)}
                          onMouseEnter={() => setIsHovered(item.uid)}
                          onMouseLeave={() => setIsHovered(null)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`cursorPointer row_unselected spanFormat ${index === folderData.length - 1 ? '' : 'underline'}`}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: isSelected
                              ? '#E0EADD'
                              : isHovered === item.uid
                                ? '#f2f2f2'
                                : undefined,
                            display: 'flex',
                            //7-10-24 Hazlett: PSA Cannot do grid format and a draggable item hd to switch to flex
                          }}
                        >
                          {/* <div
                            style={{
                              flex: 1,
                            }}
                          >
                            <input
                              className="icon-normal"
                              style={{ margin: '4px' }}
                              type="checkbox"
                              checked={isSelected}
                              onChange={() => {
                                handleRowClick(item);
                              }}
                            />
                          </div> */}
                          <div
                            style={{
                              flex: 1,
                            }}
                          >
                            {item instanceof CategoryItem ? (
                              <FaFolderOpen
                                className="table-icon"
                                style={{ marginBottom: '4px' }}
                              />
                            ) : (
                              <IoDocumentText
                                className="table-icon"
                                style={{ margin: '0 4px' }}
                              />
                            )}
                          </div>

                          {isRename && isSelected ? (
                            <form
                              style={{
                                flex: 9,
                              }}
                              onSubmit={() => {
                                handleRename(inputValue);
                              }}
                            >
                              <InputText
                                value={inputValue}
                                autoFocus
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                style={{ padding: '10px', margin: '-10px' }}
                              />
                            </form>
                          ) : (
                            <div
                              className="titleItemContainer"
                              style={{
                                flex: 10,
                              }}
                            >
                              <div className="titleItemText">
                                <div
                                  className="hoverableText"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleTabClick(item, true);
                                  }}
                                >
                                  {item?.name}
                                </div>
                              </div>
                              {item.status !== 'ACTIVE' && (
                                <Status status={item.status} />
                              )}
                            </div>
                          )}

                          <span
                            className="protocolDatatableText"
                            style={{ textAlign: 'center', flex: 6 }}
                          >
                            {getFormattedDate(
                              item?.model?.updatedAt
                                ? item?.model?.updatedAt
                                : new Date(),
                              true
                            )}
                          </span>
                          <span
                            className="protocolDatatableText"
                            style={{ flex: 6, textAlign: 'center' }}
                          >
                            {userName}
                          </span>
                          {department &&
                            department.subDeps &&
                            department.subDeps.length > 0 && (
                              <span style={{ flex: 6, textAlign: 'center' }}>
                                {item.pairedDepIDs?.length +
                                  '/' +
                                  department.subDeps.length}
                              </span>
                            )}
                          <span style={{ flex: 6, textAlign: 'center' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Status
                                status={
                                  !item.isPublic
                                    ? 'Private'
                                    : item.keychainID
                                      ? 'Protected'
                                      : 'Public'
                                }
                              />
                            </div>
                          </span>
                          <span style={{ flex: 2, textAlign: 'center' }}>
                            {item?.getSum()}
                            <span>
                              <FaChevronRight
                                className="icon-normal "
                                style={{ margin: '4px' }}
                              />
                            </span>
                          </span>
                        </li>
                      )}
                    </Draggable>
                  );
                }}
              </ViewportList>
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ProtocolDataTable;
