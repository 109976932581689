import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  DatabaseResponse,
  ResponseType,
  fetchVitals,
  getDepartments,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import { copyVitalsFromDeptoDep } from '../../../../../data/AmplifyActions';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { globals } from '../../../../_global/common/Utils';
import { useDispatch } from 'react-redux';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const VitalsCopy = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const db: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);

  const [info, setInfo] = useState<any>({
    departmentFrom: null,
    departmentTo: null,
    vitalsListFrom: [],
    vitalsListTo: [],
  });

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadVitalsData = async (
    department: DepartmentItem,
    isFrom: boolean
  ): Promise<VitalItem[] | null> => {
    const response = await fetchVitals(department.id);
    if (response.type === ResponseType.Success) {
      let vitals = response.data;
      return vitals;
    }
    return null;
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  // useEffect(() => {
  //     if(info.departmentFrom !== null) loadVitalsData(info.departmentFrom, true);
  //     if(info.departmentTo !== null)
  // }
  // , [info.departmentFrom, info.departmentTo]);

  const handleBack = () => {
    navigate(`/actions/copy`);
  };

  const handleDepartmentSelected = async (
    department: DepartmentItem,
    isFrom: boolean
  ) => {
    if (globals.debug) console.log('Department selected', department);
    let vitals = await loadVitalsData(department, isFrom);
    if (isFrom)
      setInfo({ ...info, departmentFrom: department, vitalsListFrom: vitals });
    else setInfo({ ...info, departmentTo: department, vitalsListTo: vitals });
  };

  useEffect(() => {
    let l = [...departments];
    l.sort((a, b) => a.name.localeCompare(b.name));
    /* Remove the current department from the list */
    l = l.filter(
      (item) =>
        item.id !== info.departmentFrom?.id && item.id !== info.departmentTo?.id
    );
    if (globals.debug) console.log('List', l);
    setList(l);
  }, [info]);

  const handleRouteToVitals = (department: DepartmentItem) => {};

  const handleSubmit = async () => {
    if (info.departmentFrom === null || info.departmentTo === null) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    } else if (info.vitalsListFrom.length === 0) {
      alert(
        'Error: trying to copy an EMPTY list of vitals\nFrom: ' +
          info.departmentFrom.name +
          '\nTo: ' +
          info.departmentTo.name
      );
      return;
    }
    if (globals.debug) console.log('Vital', info);
    let result = await copyVitalsFromDeptoDep(
      info.departmentFrom,
      info.departmentTo
    );
    if (result.type === ResponseType.Success) {
      let results = result.data as number;
      if (globals.debug)
        console.log(
          'Copied',
          results,
          'vitals from',
          info.departmentFrom.name,
          'to',
          info.departmentTo.name
        );
      loadDatabase(db.department, dispatch);
      let isConfirmed = window.confirm(
        `Copied ${results} vitals from ${info.departmentFrom.name} to ${info.departmentTo.name}, route to ${info.departmentTo.name} Vitals?`
      );
      if (isConfirmed) {
        handleRouteToVitals(info.departmentTo);
      }
    } else {
      if (globals.debug)
        console.log(
          'Copy vitals from ' +
            info.departmentFrom.name +
            ' to ' +
            info.departmentTo.name +
            ' failed: ',
          result.data
        );
    }
  };

  const isSaveValid = useMemo(() => {
    return (
      info.departmentFrom !== null &&
      info.departmentTo !== null &&
      info.vitalsListFrom.length > 0
    );
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Copy Actions'}
        name={'Copy Vitals'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={true}
        isSaveActive={isSaveValid}
        handleSave={handleSubmit}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div className="formContainer">
        <Row>
          <Col sm={6}>
            {' '}
            {/* Left column */}
            <label className="ketamine-general-label">
              Department FROM: <span className="required-field">*</span>
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              Select the department you want to copy vitals from.
            </p>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={list}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.id}
              onChange={(option: DepartmentItem) => {
                handleDepartmentSelected(option, true);
              }}
              onClear={() => {
                setInfo({ ...info, departmentFrom: null, vitalsListFrom: [] });
              }}
              placeholder="Search department..."
            />
            <label
              className="ketamine-general-label"
              style={{ marginTop: '20px' }}
            >
              Department TO: <span className="required-field">*</span>
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              Select the department you want to copy vitals to.
            </p>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={list}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              onChange={(option: DepartmentItem) => {
                handleDepartmentSelected(option, false);
              }}
              onClear={() => {
                setInfo({ ...info, departmentTo: null, vitalsListTo: [] });
              }}
              placeholder="Search department..."
            />
          </Col>
          <Col sm={6}>
            {' '}
            {/* Right column */}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              FROM Options - {info.vitalsListFrom.length} items:
            </label>
            <hr style={{ margin: 10 }} />
            {info.vitalsListFrom.map((item: VitalItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Options: {item.options.length}</h6>
                </div>
              </div>
            ))}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              TO Options - {info.vitalsListTo.length} items:
            </label>
            <hr style={{ margin: 10 }} />
            {info.vitalsListTo.map((item: VitalItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Options: {item.options.length}</h6>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VitalsCopy;
