import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CategoryItem, {
  cloneCategory,
} from '../../../../../data/model/CategoryItem';
import ProtocolItem, {
  cloneProtocol,
} from '../../../../../data/model/ProtocolItem';
import {
  handleDataTableRowSelection,
  handleGetDepartment,
  handleSelectDataTable,
  handleUpdateEditFolderCategory,
  handleUpdateEditProtocol,
} from '../../../../../store/actions';
import FolderProtocolModal from '../../../../components/Modal/FolderProtocolModal';
import ProtocolEditHeader from '../../ProtocolHeader';
import ReviewSideOut from '../../../../components/SideOut/reviewSideOut/ReviewSideOut';
import { Category, LazyCategory, User } from '../../../../../models';
import JSZip from 'jszip';
// import { saveAs } from 'file-saver'; // To save the zip file

import {
  ModelInit,
  MutableModel,
  __modelMeta__,
  ManagedIdentifier,
  DataStore,
} from '@aws-amplify/datastore';
import {
  newCategoryItem,
  newProtocolItem,
} from '../../../../_global/constants/model';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import {
  CategoryJSON,
  createCategory,
  deleteCategory,
  duplicateCategory,
} from '../../../../../data/functions/CategoryDB';
import { ProgressStatus } from '../../../../../models';
import { globals, upgradeVersion } from '../../../../_global/common/Utils';
import ModelItem from '../../../../../data/model/ModelItem';
import { debounce, set } from 'lodash';
import {
  ProtocolDB,
  createProtocol,
  deleteProtocol,
  duplicateProtocol,
  uploadInitialPDFToS3,
} from '../../../../../data/functions/ProtocolDB';
import ProtocolDataTable from './ProtocolDataTable';
import { tab } from '@testing-library/user-event/dist/tab';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { Alert } from 'react-bootstrap';
import Loading from '../../../../components/Loading/Loading';
import EditFolderModal from '../../../../components/Modal/EditFolderModal';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import SearchBar from '../../../../components/Search/SearchBar';
import { error } from 'console';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import { load } from 'mime';

/* 10-10-23 Arul: Created ProtocolEditMainPage Component for Protocol Edit Screen*/
const ProtocolEditMainPage = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDragActive, setIsDragActive] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState<File[] | undefined>(
    undefined
  );

  /**
   * The table information
   *  - breadcrumpData: The breadcrump data ("Home > Category > Subcategory > Protocol")
   *  - selectedRowData: The currently selected row data
   * -  tableData: The data to display in the table
   */
  const tableData = useSelector((state: any) => state.protocol.editDatatable);
  // const database: DatabaseResponse = useSelector((state: any) => state?.protocol?.departmentItem);
  // const categories: CategoryItem[] = database.categories;
  const user: User = useSelector((state: any) => state?.user);
  const dbState = useSelector((state: any) => state?.protocol?.departmentItem);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [categories, setCategories] = useState<CategoryItem[]>(
    database.categories
  );
  const dropRef = useRef<any>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [isSaveVisible, setIsSaveVisible] = useState(false);

  const [displayedFolderData, setDisplayedFolderData] = useState<
    (CategoryItem | ProtocolItem)[]
  >([]); //Holds the array of categories or protocols to display in the table
  const [selectedItem, setSelectedItem] = useState<
    CategoryItem | ProtocolItem | null
  >(null); //Holds the currently selected folder
  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([
    {
      label: '',
      icon: <FaHome className="breadCrumbIcon icon-large" />,
      data: null,
    },
  ]); //Holds the breadcrumb items
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isEditFolder, setIsEditFolder] = useState(false);
  /* ---- VIEW BASED STATE ---- */
  const [isShowSubmenu, setIsShowSubmenu] = useState(false); //Whether the submenu is visible

  const [title, setTitle] = useState('Editing Folder Path');

  const [isOpen, setIsOpen] = useState(false);
  const [modalName, setModalName] = useState('');

  /* 10-09-2023 Arul: To render Bread Crumb Home icon initially */
  const home = {
    label: '',
    icon: <FaHome className="breadCrumbIcon icon-large" />,
    data: null,
  };

  const [isSubFolder, setIsSubFolder] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);
  const newButtonRef = useRef<any>(null);
  const [loading, setLoading] = useState<string | null>(null);
  const [newOrder, setNewOrder] = useState<
    (CategoryItem | ProtocolItem)[] | null
  >(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Example: Detect "Ctrl + N"
      if (event.ctrlKey && event.key === 'n') {
        event.preventDefault();
        setModalName(
          breadcrumbItems?.length > 1 ? 'New Protocol' : 'New Folder'
        );
        setIsOpen(true);
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === 'r' && selectedItem) {
        event.preventDefault();
        setIsRename(true);
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === '.') {
        event.preventDefault();
        console.log('Ctrl + . detected');
        //Find the next item in the list
        let index = selectedItem
          ? displayedFolderData.findIndex(
              (item: CategoryItem | ProtocolItem) =>
                item.uid === selectedItem.uid
            )
          : -1;
        console.log('INDEX:', index);
        if (index < displayedFolderData.length - 1)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: displayedFolderData[index + 1],
            })
          );
        else if (index === displayedFolderData.length - 1)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: displayedFolderData[0],
            })
          );
        // handleTabClick(displayedFolderData[index + 1], false);
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === ',') {
        event.preventDefault();
        //Find the next item in the list
        let index = selectedItem
          ? displayedFolderData.findIndex(
              (item: CategoryItem | ProtocolItem) =>
                item.uid === selectedItem.uid
            )
          : displayedFolderData.length;
        if (index > 0 && index < displayedFolderData.length + 1)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: displayedFolderData[index - 1],
            })
          );
        else if (index === 0)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData:
                displayedFolderData[displayedFolderData.length - 1],
            })
          );
      } else if (
        event.ctrlKey &&
        event.key === 'b' &&
        breadcrumbItems.length > 1
      ) {
        event.preventDefault();
        //Move up the breadcrumb
        handleCrumbClick(
          breadcrumbItems[breadcrumbItems.length - 2],
          breadcrumbItems.length - 2
        );
        //Find the next item in the list
      } else if (event.ctrlKey && event.key === 'Enter' && selectedItem) {
        event.preventDefault();
        //Move up the breadcrumb
        handleTabClick(selectedItem, true);

        //Find the next item in the list
      }

      // // Example: Detect "Alt + Shift + A"
      // if (event.altKey && event.shiftKey && event.key === 'A') {
      //   event.preventDefault();
      //   console.log('Alt + Shift + A detected');
      //   // Add your custom logic here
      // }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [breadcrumbItems, selectedItem, displayedFolderData]);

  // useEffect(() => {
  //   const checkIfClickedOutside = (e: MouseEvent) => {
  //     if (!isShowSubmenu) return;
  //     if (
  //       isShowSubmenu &&
  //       newButtonRef.current &&
  //       !newButtonRef.current.contains(e.target as Node)
  //     )
  //       setIsShowSubmenu(false);
  //   };
  //   document.addEventListener('click', checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener('click', checkIfClickedOutside);
  //   };
  // }, [isShowSubmenu, newButtonRef]);

  const reloadDatabase = async () => {
    if (!loading) {
      /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
      loadDatabase(database.department, dispatch).then((resp: Response) => {
        if (resp.type === ResponseType.Success) {
          const newDB: DatabaseResponse = resp.data;
          setDatabase(newDB);
          setCategories(newDB.categories);
          dispatch<any>(handleGetDepartment(newDB));

          // /* Determine the current table data
          //  *  - If the breadcrumb items is empty, then we are at the root
          //  *  - If the breadcrumb items has > 1 items, then we are at a category
          //  *  - Replace the selcted item with the new item
          //  *  - Replace the table data with the new table data
          //  */
          // let selItem = null;
          // if (selectedItem != null) {
          //   if (selectedItem instanceof CategoryItem) {
          //     const newItem = newDB.categories.find(
          //       (category: CategoryItem) => category.uid === selectedItem.uid
          //     );
          //     if (newItem != null) selItem = newItem;
          //   } else if (selectedItem instanceof ProtocolItem) {
          //     const newItem = newDB.protocols.find(
          //       (protocol: ProtocolItem) => protocol.uid === selectedItem.uid
          //     );
          //     if (newItem != null) selItem = newItem;
          //   }
          // }

          /** 1-10-24 Hazlett: Do not change he breadcrumps unless they are equal - FIXES Edit Folder Path -> Protocol -> Edit Folder path routing bug */
          if (tableData?.breadcrumpData?.length === breadcrumbItems.length) {
            let tableData: any = {
              breadcrumpData: [home],
              selectedRowData: selectedItem,
              tableData: newDB.categories,
            };

            /* Loop through the breadcrumb items and update to the new database information */
            for (let i = 1; i < breadcrumbItems.length; i++) {
              const isLastItem = i === breadcrumbItems.length - 1;
              const item: CategoryItem = breadcrumbItems[i].data;

              /* Update the category item in the breadcrumps */
              if (!isLastItem) {
                const newItem = newDB.categories.find(
                  (category: CategoryItem) =>
                    category.uid === item.uid || category.activeID === item.uid
                );
                if (newItem != null)
                  tableData.breadcrumpData.push({
                    icon: undefined,
                    label: newItem.name,
                    data: newItem,
                  });
              } else {
                /* Update the data in the table */
                const newItem = newDB.categories.find(
                  (category: CategoryItem) =>
                    category.uid === item.uid || category.activeID === item.uid
                );
                if (newItem != null) {
                  tableData.selectedRowData = newItem;
                  tableData.breadcrumpData.push({
                    icon: undefined,
                    label: newItem.name,
                    data: newItem,
                  });
                  tableData.tableData = [
                    ...newItem.subCategories,
                    ...newItem.protocols,
                  ];
                }
              }
            }
            dispatch<any>(handleSelectDataTable(tableData));
          }
        } else {
          console.error('ERROR LOADING DATABASE', resp.data);
        }
      });
    }
  };

  // let debounceReload= debounce(reloadDatabase, 1000);

  /* 1-10-24 Hazlett:  Update the selected row data and breadcrumb data in state initially */
  useEffect(() => {
    if (globals.debug) console.log('RELOAD DATABASE');
    reloadDatabase();
    /* 10-19-2023  Arul:  Update the selected row data and breadcrumb data in state*/
    const handleUpdataDataTable = async () => {
      const data = {
        breadcrumpData: [],
        selectedRowData: null,
        tableData: database.categories,
      };
      await dispatch<any>(handleSelectDataTable(data));
    };
    if (!tableData?.breadcrumpData?.length) {
      handleUpdataDataTable();
    }
    // dispatch<any>(handleUpdateEditFolderCategory(database.categories)); //NOT USED
  }, []);

  useEffect(() => {
    /* 10-19-2023  Arul: Retrive the table selection and breadcrumb values*/
    if (tableData?.breadcrumpData?.length)
      setBreadcrumbItems(tableData.breadcrumpData);

    setSelectedItem(tableData.selectedRowData);

    let lastBreadCrumb =
      tableData?.breadcrumpData[tableData?.breadcrumpData?.length - 1];

    /* At the base folder */
    if (lastBreadCrumb) {
      if (lastBreadCrumb.data == null)
        setDisplayedFolderData(database.categories);
      else if (lastBreadCrumb.data instanceof CategoryItem) {
        //Find the category in the database
        const category: CategoryItem | undefined = database.categories.find(
          (category: CategoryItem) => category.uid === lastBreadCrumb.data.uid
        );
        if (category != null) {
          setDisplayedFolderData([
            ...category.subCategories,
            ...category.protocols,
          ]);
        } else {
          console.error('ERROR FINDING CATEGORY', lastBreadCrumb.data);
        }
      }
    } else {
      setDisplayedFolderData(database.categories);
    }

    // if (tableData.isRowSelected) {
    //     setIsSubFolder(true)
    //     setSelectedColumn(tableData?.selectedRowData)
    // } else {
    //     setIsSubFolder(false)
    //     setIsRename(false);
    // }

    setIsShowSubmenu(false);
  }, [tableData, database]);

  /* 10-19-2023  Arul:  Update the selected row data and breadcrumb data in state*/
  const handleUpdataDataTable = useCallback(
    (tableData: any, selectedData: any, breadcrumb: any) => {
      const data = {
        breadcrumpData: breadcrumb,
        selectedRowData: selectedData,
        tableData: tableData,
      };
      dispatch<any>(handleSelectDataTable(data));
    },
    [tableData]
  );

  /* 10-10-23 Arul: function for handling the Add new folder*/
  const handleAddNewFolder = () => {
    setIsOpen(true);
    setModalName('New Folder');
  };

  /* 10-10-23 Arul: function for handling the Add new protocol*/
  const handleAddNewProtocol = () => {
    setIsOpen(true);
    setModalName('New Protocol');
  };

  const handleRenameCall = async (
    selectedItem: CategoryItem | ProtocolItem,
    value: string
  ): Promise<CategoryItem | ProtocolItem | undefined> => {
    /* Base Case: No change in name */

    selectedItem.name = value.trim();

    if (selectedItem instanceof CategoryItem) {
      let newItem = cloneCategory(selectedItem);
      newItem.name = value;
      newItem.activeID =
        selectedItem.status === ProgressStatus.ACTIVE
          ? selectedItem.uid
          : selectedItem.activeID;
      newItem.status = ProgressStatus.DRAFT;
      newItem.version = upgradeVersion(
        newItem.version ? selectedItem.version : 'v1.0.0'
      );
      newItem.modifiedBy = user;
      let results: Response = await createCategory(newItem, selectedItem);

      if (results.type === ResponseType.Success) {
        let newCategory: CategoryItem = results.data;

        /* Reload the database -- OR use the subscribe method */
        reloadDatabase();
        return newCategory;
      } else {
        if (globals.debug)
          console.log(
            'ERROR CREATING DRAFT CATEGORY FOR RENAME:',
            results.data
          );
      }
    } else if (selectedItem instanceof ProtocolItem) {
      /* Rename the protocol item as a DRAFT */
      if (globals.debug) console.log('RENAME PROTOCOL ITEM', value);
      let newItem = cloneProtocol(selectedItem);
      newItem.name = value;
      newItem.version = upgradeVersion(
        selectedItem.version != null ? selectedItem.version : 'v1.0.0'
      );
      newItem.activeID =
        selectedItem.status === ProgressStatus.ACTIVE
          ? selectedItem.uid
          : selectedItem.activeID;
      newItem.status = ProgressStatus.DRAFT;
      newItem.modifiedBy = user;

      createProtocol(newItem, selectedItem).then((results: Response) => {
        if (results.type === ResponseType.Success) {
          let newProtocol: ProtocolItem = results.data;
          if (globals.debug)
            console.log('CREATED DRAFT PROTOCOL FOR RENAME:', newProtocol);

          //Replace the item in the displayedFolderData with the new item
          let index = displayedFolderData.findIndex(
            (item: CategoryItem | ProtocolItem) =>
              item.uid === newProtocol.uid || item.activeID === newProtocol.uid
          );
          if (index >= 0) displayedFolderData[index] = newProtocol;
          reloadDatabase();
        }
      });

      return newItem;
    }
    return undefined;
  };

  /* 10-20-23 Arul:Function for rename the category and protocol name*/
  const handleNameUpdate = useCallback(
    (value: any) => {
      setIsRename(false);
      if (
        value &&
        value !== '' &&
        selectedItem &&
        value !== selectedItem.name
      ) {
        selectedItem.name = value;
        handleRenameCall(selectedItem, value).then(
          (newItem: CategoryItem | ProtocolItem | undefined) => {
            if (newItem != null) {
              // setSelectedItem(newItem);
              dispatch<any>(
                handleDataTableRowSelection({
                  selectedRowData: newItem,
                })
              );
            }
          }
        );
      }
    },
    [selectedItem, breadcrumbItems]
  );

  /* 10-11-23 Arul: function for handling the add folder and protocol submission*/
  const handleAdd = useCallback(
    async (data: any) => {
      setIsShowSubmenu(false);

      /* Add the new protocol */
      if (modalName === 'New Protocol') {
        if (!data.value || !data.pdf || !data.nickName) {
          console.error('ERROR CREATING PROTOCOL: MISSING DATA', data);
          return;
        }
        setDroppedFiles(undefined);
        //Filter the added pdf out of the dropped files

        // if(globals.debug) console.log('BEGORE DROPPED FILES:', droppedFiles);
        // const files = droppedFiles.filter(
        //   (file) => file.name !== data.pdf.name
        // );
        // if(globals.debug) console.log('DROPPED FILES:', files);
        // setDroppedFiles([...files]);
        // if (files.length === 0) setIsOpen(false);

        setIsOpen(false);

        setLoading('Creating Protocol...');
        const breadcrumblength = breadcrumbItems?.length - 1;
        let parent: CategoryItem = breadcrumbItems[breadcrumblength]?.data;
        if (selectedItem && selectedItem.hasOwnProperty('subCategories'))
          parent = selectedItem as CategoryItem;

        /* Upload the PDF to the S3 bucket */
        let result: Response = await uploadInitialPDFToS3(
          database.department,
          parent,
          data.value,
          data.pdf,
          (error: any) => {
            console.error('ERROR UPLOADING PDF TO S3', error);
          }
        );
        if (result.type === ResponseType.Failure) {
          console.error('ERROR UPLOADING PDF TO S3', result.data);
          return;
        }

        let pdfID: string = result.data;

        /* Create the new protocol item */
        let json: ProtocolDB = {
          departmentID: database.department.id,
          name: data.value,
          nickname: data.nickName,
          pdfID: pdfID,
          rangeLow: 0,
          rangeHigh: globals.MAX_VALUE,
          parentCategory: parent,
          pairedDeps: data.pairedDeps,
          pairedProtocols: [],
          medications: [],
          infusions: [],
          equipment: [],
          electrical: [],
          forms: [],
          createdBy: user.id,
          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
          pdfVersion: 'v1.0.0',
          index: parent.protocols.length,
          isPublic: database.department.isPublic,
          keychainID: null,
        };

        /* Create the protocol item */
        let results: Response = await createProtocol(json);
        if (results.type === ResponseType.Success) {
          let newProtocol: ProtocolItem = results.data;
          if (globals.debug)
            console.log('CREATED DRAFT PROTOCOL:', newProtocol);

          /* Reload the database -- OR use the subscribe method */
          reloadDatabase();
        } else {
          if (globals.debug)
            console.log('ERROR CREATING DRAFT PROTOCOL:', results.data);
        }
        setLoading(null);
      } else {
        /* Otherwise, add the new folder */
        //folder modal
        // const mockData: any = newCategoryItem(data.value, database)
        if (tableData.breadcrumpData?.length > 1) {
          if (globals.debug)
            console.log(
              'TODO: Creating new sub folder in the sub folder',
              data
            );

          // event for adding sub category
          // if(globals.debug) console.log("SUBcategory", currentrenderData);
          // currentrenderData?.addSubCategories(mockData);

          // handleUpdatedTable(currentrenderData, tableData?.isRowSelected);
          if (globals.debug)
            console.log('Creating new sub folder in the folder', data);
        } else {
          setIsOpen(false);

          if (globals.debug)
            console.log('Creating new folder in the base folder', data);
          let ids = data.pairedDeps
            ? data.pairedDeps.map((dep: DepartmentItem) => dep.id)
            : [];
          let json: CategoryJSON = {
            name: data.value,
            status: ProgressStatus.DRAFT,
            index: displayedFolderData.length,
            departmentID: database.department.id,
            pairedDeps: ids,
            activeID: null,
            version: 'v1.0.0',
            createdBy: user.id,
            isPublic: database.department.isPublic,
            keychainID: null,
          };
          createCategory(json)
            .then((response: Response) => {
              if (response.type === ResponseType.Success) {
                reloadDatabase();
              } else {
                console.error('ERROR CREATING CATEGORY', response.data);
              }
            })
            .catch((error: any) => {
              console.error('ERROR CREATING CATEGORY', error);
            });
        }
      }
    },
    [isSubFolder, tableData, modalName, selectedItem, breadcrumbItems]
  );

  const handleDuplicateProtocol = async (
    data: any,
    copyProtocol: ProtocolItem
  ) => {
    if (!data.value || !data.pdf || !data.nickName) {
      console.error('ERROR CREATING PROTOCOL: MISSING DATA', data);
      return;
    }
    setIsOpen(false);
    setLoading('Creating Protocol...');
    let parent: CategoryItem =
      breadcrumbItems[breadcrumbItems?.length - 1]?.data;
    if (selectedItem && selectedItem.hasOwnProperty('subCategories'))
      parent = selectedItem as CategoryItem;

    /* Upload the PDF to the S3 bucket */
    let result: Response = await uploadInitialPDFToS3(
      database.department,
      parent,
      data.value,
      data.pdf,
      (error: any) => {
        console.error('ERROR UPLOADING PDF TO S3', error);
      }
    );
    if (result.type === ResponseType.Failure) {
      console.error('ERROR UPLOADING PDF TO S3', result.data);
      return;
    }

    let pdfID: string = result.data;
    let depIDs = data.pairedDeps.map((dep: DepartmentItem) => dep.id);
    let newProtocol = cloneProtocol(copyProtocol);
    newProtocol.name = data.value;
    newProtocol.nickname = data.nickName;
    newProtocol.pdfUrl = pdfID;
    newProtocol.pairedDepIDs = depIDs;
    newProtocol.createdBy = user;
    newProtocol.parent = parent;
    let results: Response = await duplicateProtocol(newProtocol, copyProtocol);
    if (results.type === ResponseType.Success) {
      if (globals.debug) console.log('DUPLICATED PROTOCOL', results.data);
      reloadDatabase();
    } else {
      console.error('ERROR DUPLICATING PROTOCOL', results.data);
    }
    setLoading(null);
  };

  const handleDuplicateFolder = async (data: any, copyFolder: CategoryItem) => {
    if (!data.value) {
      console.error('ERROR CREATING CATEGORY: MISSING DATA', data);
      return;
    }
    setIsOpen(false);
    setLoading('Creating Folder...');

    let depIDs = data.pairedDeps.map((dep: DepartmentItem) => dep.id);
    let newFolder = cloneCategory(copyFolder);
    newFolder.name = data.value;
    newFolder.pairedDepIDs = depIDs;
    let results: Response = await duplicateCategory(
      newFolder,
      copyFolder,
      user
    );
    if (results.type === ResponseType.Success) {
      if (globals.debug) console.log('DUPLICATED CATEGORY', results.data);
      reloadDatabase();
    } else {
      console.error('ERROR DUPLICATING PROTOCOL', results.data);
    }
    setLoading(null);
  };

  /* 10-11-23 Arul: Used to updated the datatable  */
  const handleUpdatedTable = (item: any, isSameRow?: boolean) => {
    if (isSameRow) {
      const newItem = { label: item?.name, data: item };
      const result = [...item?.subCategories, ...item?.getProtocols()];
      const breadCrumbData = [...breadcrumbItems, newItem];

      handleUpdataDataTable(result, item, breadCrumbData);
    } else {
      const result = [...item?.subCategories, ...item?.getProtocols()];
      const breadCrumbData = [...breadcrumbItems];

      handleUpdataDataTable(result, item, breadCrumbData);
    }
  };

  const handleReorderItems = (items: (CategoryItem | ProtocolItem)[]) => {
    /* Verify that the order is different then what is in displayedFolderData */
    let isDifferent = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].uid !== displayedFolderData[i].uid) {
        isDifferent = true;
        break;
      }
    }

    if (!isDifferent) {
      setNewOrder(null);
      return;
    }

    /* Show the save button */
    setNewOrder(items);
  };

  /* 10-11-23 Arul: function for handling to open submenu and new folder modal*/
  const handleNewButtonClick = () => {
    // if (tableData.isRowSelected) {
    setIsShowSubmenu(true);
    // } else {
    // 	if (breadcrumbItems?.length === 1) {
    // 		handleAddNewFolder();
    // 	} else {
    // 		handleAddNewProtocol();
    // 	}
    // }
  };

  /* 10-11-23 Arul: function for handling to open submenu and new folder modal*/
  const handleCancel = () => {
    navigate(`/protocol`);
  };

  /* 10-11-23 Arul: function for save button onclick*/
  const handleSave = async () => {
    if (newOrder) {
      setLoading('Saving...');
      let promises = [];
      for (let i = 0; i < newOrder.length; i++) {
        let item = newOrder[i];
        if (item.index === i) continue;
        if (item instanceof CategoryItem) {
          let newItem = cloneCategory(item);
          newItem.index = i;
          newItem.activeID =
            item.status === ProgressStatus.ACTIVE ? item.uid : item.activeID;
          newItem.status = ProgressStatus.DRAFT;
          newItem.version = upgradeVersion(
            item.version ? item.version : 'v1.0.0'
          );
          newItem.modifiedBy = user;
          promises.push(createCategory(newItem, item));
        } else if (item instanceof ProtocolItem) {
          let newItem = cloneProtocol(item);
          newItem.index = i;
          newItem.activeID =
            item.status === ProgressStatus.ACTIVE ? item.uid : item.activeID;
          newItem.status = ProgressStatus.DRAFT;
          newItem.version = upgradeVersion(
            item.version ? item.version : 'v1.0.0'
          );
          newItem.modifiedBy = user;
          promises.push(createProtocol(newItem, item));
        }
      }
      let results = await Promise.all(promises);
      for (let i = 0; i < results.length; i++) {
        if (results[i].type === ResponseType.Success) {
          if (globals.debug) console.log('SAVED ITEM', results[i].data);
        } else {
          console.error('ERROR SAVING ITEM', results[i].data);
        }
      }
      reloadDatabase();
      setLoading(null);
    }
  };

  /* 10-11-23 Arul: function for save review onclick*/
  const handleReview = () => {
    setReviewSideOutVisible(true);
  };

  /* 10-09-2023 Arul: To handle and populate Bread Crumb items label*/
  const handleTabClick = (
    item: CategoryItem | ProtocolItem,
    isDoubleClick: boolean
  ) => {
    if (item instanceof CategoryItem) {
      setIsShowSubmenu(false);
      if (isDoubleClick) {
        // setIsSubFolder(true);
        const newItem = { label: item?.name, data: item };
        const tableData = [...item?.subCategories, ...item?.getProtocols()];
        const breadCrumbData = [...breadcrumbItems, newItem];
        handleUpdataDataTable(tableData, null, breadCrumbData);
      }
    } else {
      if (isDoubleClick) {
        // setIsSubFolder(true);
        const state = { selectedProtocol: item, editMode: true };
        const formattedString = item.nickname.replace(/[\s\/]/g, '-');
        navigate(`/${formattedString}/protocol-detail`, { state });
        dispatch<any>(handleUpdateEditProtocol(item));
      }
    }
  };

  /* 10-09-2023 Arul: Function to render respective data when the Bread Crumb items is clicked*/
  const handleCrumbClick = (item: any, index: number) => {
    /* Home Icon Seleced */
    if (index === 0) {
      // setIsSubFolder(false);
      // Clicked on the home icon
      const breadCrumbData = [home];
      handleUpdataDataTable(categories, null, breadCrumbData);
    } else {
      /* Otherwise, a category was selected */
      const newBreadcrumbItems = breadcrumbItems?.slice(0, index + 1);
      const selectedItem = newBreadcrumbItems[index]?.data;
      const result = [
        ...selectedItem?.subCategories,
        ...selectedItem?.getProtocols(),
      ];
      handleUpdataDataTable(
        result?.length ? result : [],
        null,
        newBreadcrumbItems
      );
    }
  };

  const handleDeleteItem = async (isPermanent: boolean) => {
    if (selectedItem) {
      if (selectedItem instanceof CategoryItem) {
        let results: Response = await deleteCategory(
          selectedItem,
          user,
          !isPermanent,
          true
        );
        if (results.type === ResponseType.Success) reloadDatabase();
        else console.error('ERROR DELETING CATEGORY', results.data);
      } else if (selectedItem instanceof ProtocolItem) {
        let results: Response = await deleteProtocol(
          selectedItem,
          user,
          !isPermanent,
          true
        );
        if (results.type === ResponseType.Success) reloadDatabase();
        else console.error('ERROR DELETING PROTOCOL', results.data);
      }
      setIsDeleteModal(false);
    }
  };

  const handleEditFolder = async (data: any) => {
    const { value, pairedDeps, index, nickName } = data;
    if (selectedItem instanceof CategoryItem) {
      let newItem = cloneCategory(selectedItem);
      let depIDs = pairedDeps.map((dep: DepartmentItem) => dep.id);
      newItem.name = value;
      newItem.index = index;
      newItem.activeID =
        selectedItem.status === ProgressStatus.ACTIVE
          ? selectedItem.uid
          : selectedItem.activeID;
      newItem.status = ProgressStatus.DRAFT;
      newItem.version = upgradeVersion(
        selectedItem.version ? selectedItem.version : 'v1.0.0'
      );
      newItem.pairedDepIDs = depIDs;
      newItem.modifiedBy = user;
      let results: Response = await createCategory(newItem, selectedItem);

      if (results.type === ResponseType.Success) {
        let newCategory: CategoryItem = results.data;

        /* Reload the database -- OR use the subscribe method */
        reloadDatabase();
      } else {
        if (globals.debug)
          console.log(
            'ERROR CREATING DRAFT CATEGORY FOR RENAME:',
            results.data
          );
      }
      setIsEditFolder(false);
    } else if (selectedItem instanceof ProtocolItem) {
      let depIDs = pairedDeps.map((dep: DepartmentItem) => dep.id);
      let newProtocol = cloneProtocol(selectedItem);
      newProtocol.name = value;
      newProtocol.nickname = nickName;
      newProtocol.index = index;
      newProtocol.activeID =
        selectedItem.status === ProgressStatus.ACTIVE
          ? selectedItem.uid
          : selectedItem.activeID;
      newProtocol.status = ProgressStatus.DRAFT;
      newProtocol.version = upgradeVersion(
        selectedItem.version ? selectedItem.version : 'v1.0.0'
      );
      newProtocol.pairedDepIDs = depIDs;
      newProtocol.modifiedBy = user;
      let results: Response = await createProtocol(newProtocol, selectedItem);

      if (results.type === ResponseType.Success) {
        /* Reload the database -- OR use the subscribe method */
        reloadDatabase();
      } else {
        if (globals.debug)
          console.log(
            'ERROR CREATING DRAFT CATEGORY FOR RENAME:',
            results.data
          );
      }
      setIsEditFolder(false);
    }
  };

  const handleSearch = (searchTerm: string) => {
    let list: (CategoryItem | ProtocolItem)[] = [];
    let lastBreadCrumb =
      tableData?.breadcrumpData[tableData?.breadcrumpData?.length - 1];

    /* At the base folder */
    if (lastBreadCrumb) {
      if (lastBreadCrumb.data == null) list = database.categories;
      else if (lastBreadCrumb.data instanceof CategoryItem) {
        //Find the category in the database
        const category: CategoryItem | undefined = database.categories.find(
          (category: CategoryItem) => category.uid === lastBreadCrumb.data.uid
        );
        if (category != null) {
          list = [...category.subCategories, ...category.protocols];
        } else {
          console.error('ERROR FINDING CATEGORY', lastBreadCrumb.data);
          list = [];
        }
      }
    } else list = database.categories;

    const searchResults = list.filter((item: any) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setDisplayedFolderData(searchResults);
  };

  const handleDrop = (e: any) => {
    if (isOpen) return;
    e.preventDefault();
    /* Check if the file is a pdf */
    //Validate every file is a pdf
    let files: File[] = [];
    const dropped: FileList = e.dataTransfer?.files;
    for (let i = 0; i < dropped.length; i++) {
      const file: File = dropped[i];
      if (file.type === 'application/pdf') files.push(file);
    }
    if (files.length === 0) setDroppedFiles(undefined);
    else {
      setDroppedFiles(files);
      if (!isOpen) {
        setModalName('New Protocol');
        setIsOpen(true);
      }
    }

    // const file: File = e.dataTransfer?.files[0];
    // if (file.type === 'application/pdf') {
    //   if(globals.debug) console.log('DROPPED FILE:', file);
    //   setDroppedFile(file);
    //   if (!isOpen) {
    //     setModalName('New Protocol');
    //     setIsOpen(true);
    //   }
    // }
    setIsDragActive(false);
  };

  const handleDownload = async () => {
    if (selectedItem instanceof ProtocolItem) {
      setLoading('Downloading PDF...');
      let protocol: ProtocolItem = selectedItem;
      let result: Response = await fetchPDF(protocol.pdfUrl, protocol, true);
      if (result.type === ResponseType.Failure) {
        console.error('ERROR DOWNLOADING PDF:', result.data);
        setLoading(null);
        return;
      }

      let pdf: Blob = result.data;
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(pdf);
      link.download = `${protocol.name.replace(/[^a-zA-Z0-9]/g, '_')}.pdf`;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }, 0);
    } else if (selectedItem instanceof CategoryItem) {
      /* Download all the protocols in the category */
      let protocols: ProtocolItem[] = selectedItem.getProtocols();
      if (protocols.length === 0) {
        console.error('ERROR DOWNLOADING CATEGORY: NO PROTOCOLS');
        setLoading(null);
        return;
      }
      setLoading('Downloading PDFs... (0/' + protocols.length + ')');

      let zip = new JSZip();
      let folder = zip.folder(selectedItem.name.replace(/[^a-zA-Z0-9]/g, '_'));
      if (folder == null) {
        console.error('ERROR DOWNLOADING CATEGORY: CANNOT CREATE FOLDER');
        setLoading(null);
        return;
      }

      let filenames: string[] = [];
      for (let i = 0; i < protocols.length; i++) {
        let protocol: ProtocolItem = protocols[i];
        let result: Response = await fetchPDF(protocol.pdfUrl, protocol, true);
        if (result.type === ResponseType.Failure) {
          console.error('ERROR DOWNLOADING PDF:', result.data);
          setLoading(null);
          return;
        }
        let pdf: Blob = result.data;
        let name = protocol.name.replace(/[^a-zA-Z0-9]/g, '_') + '.pdf';

        if (filenames.includes(name)) {
          let j = 1;
          while (filenames.includes(`${protocol.name}_(${j}).pdf`)) {
            j++;
          }
          name = `${protocol.name} (${j}).pdf`;
        }
        filenames.push(name);
        folder.file(name, pdf, { binary: true });
        setLoading(
          'Downloading PDFs... (' + (i + 1) + '/' + protocols.length + ')'
        );
      }

      let content = await zip.generateAsync({ type: 'blob' }); //.then((content) => {
      //   saveAs(content, `${selectedItem.name}.zip`);
      // });
      // Create a link element
      const link = document.createElement('a');

      // Set the link's href to point to the Blob URL
      link.href = window.URL.createObjectURL(content);
      link.download = `${selectedItem.name.replace(/[^a-zA-Z0-9]/g, '_')}.zip`;

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up by removing the link
      setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }, 0);
    }
    setLoading(null);
  };

  return (
    <div
      className="screen-container"
      ref={dropRef}
      onDragOver={(e) => {
        if (!isOpen) {
          e.preventDefault();
          setIsDragActive(true);
        }
      }}
      onDragLeave={(e) => {
        if (!isOpen) {
          e.preventDefault();
          setIsDragActive(false);
        }
      }}
      onDrop={handleDrop}
    >
      {isDragActive && (
        <div className="drag-drop-overlay">
          <div
            className="cursorPointer dragDropDotGridModalWorkbook"
            style={{
              display: 'flex',
              maxWidth: '50vw',
              background: 'rgba(0, 0, 0, 0.3)',
              padding: '20px 0px',
              border: '1px dashed #00534C',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              <RiUpload2Line className="drag-drop-icon green_color" />
            </div>
            <div
              className="light_grey_text"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
                fontSize: '24px',
                // color: 'white',
              }}
            >
              Drag and Drop into OneDose
            </div>
            <div
              style={{ fontSize: '12px' }}
              className="light_grey_text ketamine-general-label"
            >
              Support: PDF ONLY
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <>
          <FolderProtocolModal
            isVisible={isOpen}
            title={modalName}
            isPdf={modalName === 'New Protocol'}
            handleClose={() => {
              if (droppedFiles) setDroppedFiles(undefined);
              setIsOpen(false);
            }}
            department={database.department}
            handleAdd={handleAdd}
            initialPDF={droppedFiles ? droppedFiles[0] : undefined}
            handleDuplicateProtocol={handleDuplicateProtocol}
            handleDuplicateFolder={handleDuplicateFolder}
          />
        </>
      )}

      {isEditFolder && selectedItem && (
        <EditFolderModal
          isVisible={isEditFolder}
          handleClose={() => {
            setIsEditFolder(false);
          }}
          handleAdd={handleEditFolder}
          department={database.department}
          item={selectedItem}
        />
      )}

      {reviewSideOutVisible && (
        <ReviewSideOut
          department={database.department}
          isVisible={reviewSideOutVisible} // Change 'visible' to 'isVisible'
          handleClose={() => {
            setReviewSideOutVisible(false);
          }}
          setVisible={setReviewSideOutVisible}
          handleAdd={() => {
            setReviewSideOutVisible(false);
          }}
          onPublish={() => {
            setReviewSideOutVisible(false);
            reloadDatabase();
            handleCancel();
          }}
        />
      )}
      {selectedItem && (
        <ConfirmModal
          isVisible={isDeleteModal}
          title={
            'Delete ' +
            (selectedItem instanceof CategoryItem ? 'Folder ' : 'Protocol ') +
            selectedItem.name +
            (selectedItem.status === ProgressStatus.DRAFT ? ' (Draft)' : '') +
            '?'
          }
          handleClose={() => {
            setIsDeleteModal(false);
          }}
          handleSubmit={handleDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            selectedItem instanceof CategoryItem
              ? `Are you sure that you want to delete folder ${selectedItem.name}? There is ${selectedItem.sum} item(s) that will be deleted with the folder. The items will be able to be able to recover from the trash bin.`
              : `Are you sure that you want to delete protocol ${selectedItem.name}? The item will be able to be able to recover from the trash bin.`
          }
        />
      )}
      <div className="fixedHeader">
        <ProtocolEditHeader
          name={title}
          // isDuplicateButton={selectedItem != null}
          isRenameButton={selectedItem != null}
          isFolderEditButton={selectedItem != null}
          isDeleteButton={selectedItem != null}
          isDownloadButton={selectedItem != null}
          isSaveButton={newOrder != null}
          isSaveActive={newOrder != null}
          handleDownload={handleDownload}
          handleDelete={() => setIsDeleteModal(true)}
          isBaseFolder={breadcrumbItems?.length === 1}
          // isSubmenu={isShowSubmenu}
          handleRename={() => {
            setIsRename(!isRename);
          }}
          handleFolderEdit={() => {
            setIsEditFolder(true);
          }}
          handleNewFolder={handleAddNewFolder}
          handleNewProtocol={handleAddNewProtocol}
          handleNew={handleNewButtonClick}
          handleCancel={handleCancel}
          handleSave={handleSave}
          handleReview={handleReview}
          newButtonRef={newButtonRef}
          description={
            breadcrumbItems?.length === 1
              ? undefined
              : 'Drag & drop a PDF to add a new protocol.'
          }
        />
      </div>
      <div className="headerContainer">
        <div className="breadCrumbsContainer" style={{ marginBottom: '10px' }}>
          {breadcrumbItems.map((item, index) => (
            <div style={{ display: 'flex', cursor: 'pointer' }} key={index}>
              <span
                className="btn-rightMargin breadCrumbText"
                onClick={() => handleCrumbClick(item, index)}
              >
                {item?.label}
                {item?.icon}
              </span>
              {index <= breadcrumbItems?.length - 1 && (
                <span className="btn-rightMargin btn-leftMargin breadCrumbIcon">
                  <IoChevronForward className="icon-large" />{' '}
                </span>
              )}
            </div>
          ))}
        </div>
        <SearchBar
          containerStyle={{ flex: 1 }}
          value={searchQuery}
          onChange={(searchTerm: string) => {
            setSearchQuery(searchTerm);
            handleSearch(searchTerm);
          }}
          onSubmit={(searchTerm: string) => {}}
          placeholder={'Search...'}
        />
      </div>
      <ProtocolDataTable
        handleTabClick={handleTabClick}
        handleRename={handleNameUpdate}
        isEditFolderModalOpen={isEditFolder}
        isModalOpen={isOpen}
        isRename={isRename}
        protocolData={displayedFolderData}
        newButtonRef={newButtonRef}
        handleReorder={handleReorderItems}
      />
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default ProtocolEditMainPage;
