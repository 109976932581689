import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import ProtocolDoseSideout from '../../../../components/SideOut/doseSideOut/ProtocolDoseSideout';
import ProtocolEditHeader from '../../ProtocolHeader';
import {
  DatabaseResponse,
  ResponseType,
  Response,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import VitalItem from '../../../../../data/model/VitalItem';
import VitalDose from './VitalDose';
import { ProgressStatus, User } from '../../../../../models';
import {
  getFormattedDate,
  globals,
  isObjectEqual,
  mapRangeToIndex,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  VitalDB,
  createVital,
  deleteVital,
} from '../../../../../data/functions/VitalDB';
import { VitalOption } from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import ProtocolHeader from '../../ProtocolHeader';
import { UserType } from '../../../../../models';

interface VitalEditPageProps {
  stateData?: any;
  location?: any;
}

/* 11-03-23 Arul  Created Vital Edit Page component for Vital layout */
const VitalProtocolEditPage: React.FC<VitalEditPageProps> = ({ stateData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { editMode } = stateData;
  const [isEditMode, setIsEditMode] = useState(editMode);
  const [parmVital, setParmVital] = useState<VitalItem>(stateData.value);
  const [editDose, setEditDose] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<VitalItem | null>(null);
  const [doseIndex, setDoseIndex] = useState<number>(1);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [isDelete, setIsDelete] = useState(false);
  const department = database.department;
  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const user: User = useSelector((state: any) => state?.user);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmVital ? parmVital.name : '',
      departmentID: parmVital ? parmVital.depID : '',
      optionItems: parmVital ? parmVital.options : [],
      protocol: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      departmentID: Yup.string(),
      protocol: Yup.string(),
      optionItems: Yup.array().required(),
    }),
    onSubmit: async (values) => {
      if (formik.dirty && formik.isValid) {
        const updatedVital: VitalDB = {
          title: values.name,
          departmentID: department.id,
          optionItems: values.optionItems,
          status: ProgressStatus.DRAFT,
          activeID:
            parmVital.status === ProgressStatus.ACTIVE
              ? parmVital.uid
              : parmVital.activeID,
          version:
            parmVital.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmVital.version == null ? 'v1.0.0' : parmVital.version
                )
              : parmVital.version == null
                ? 'v1.0.0'
                : parmVital.version,
          modifiedBy: user.id,
          createdBy:
            parmVital.dbVital && parmVital.dbVital.createdBy
              ? parmVital.dbVital.createdBy
              : '',
        };
        let results: Response = await createVital(updatedVital, parmVital);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully edited Vital', results.data);
          let updatedVital: VitalItem = results.data;
          updateVital(updatedVital);
        }
        formik.resetForm();
      }
    },
  });

  const updateVital = async (updatedVital: VitalItem | any) => {
    reloadDatabase();
    formik.setValues({
      ...formik.values,
      name: updatedVital?.title ? updatedVital?.title : '',
      optionItems: updatedVital?.optionItems ? updatedVital?.optionItems : [],
    });
  };

  const reloadDatabase = async (): Promise<boolean> => {
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));

      let updateVital: VitalItem | null = null;
      let vitalID =
        parmVital.status === ProgressStatus.ACTIVE
          ? parmVital.uid
          : parmVital.activeID;
      for (let i = 0; i < newDB.vitals.length; i++) {
        if (newDB.vitals[i].activeID === vitalID) {
          updateVital = newDB.vitals[i];
          break;
        }
      }

      if (updateVital) {
        setParmVital(updateVital);
      } else {
        if (globals.debug) console.log('Failed to find updated Vital');
      }
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleVersion = () => {
    // Todo: Required for the final completion
  };

  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty) {
      setIsWarningModal(true);
    } else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-vitals`, {
        state: {
          department: department,
          data: database.medications,
          database: database,
        },
      });
    }
  };

  const handleCancel = () => {
    /* Check if the formik is dirty and if the warning modal is not open */
    if (formik.dirty && !isCancelWarningModal) setIsCancelWarningModal(true);
    /* If the formik is not dirty or abandon has been pressed then reset it */ else {
      formik.resetForm();
      setIsEditMode(false);
      setIsCancelWarningModal(false);
    }
  };

  /* 11/03/2023,Arul : Function to handle on Add Dose click*/
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedData(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  /*function to close the modal*/
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
  };

  const handleUpateVital = (data: any, previousDose?: VitalOption) => {
    const rangeLowIndex =
      data.rangeLow !== '' && data.rangeLow !== 'MIN'
        ? mapRangeToIndex(data.rangeLow)
        : mapRangeToIndex('MIN');
    if (globals.debug) console.log('rangeLowIndex', rangeLowIndex);
    const rangeHighIndex =
      data.rangeHigh !== '' && data.rangeHigh !== 'MAX'
        ? mapRangeToIndex(data.rangeHigh)
        : mapRangeToIndex('MAX');
    let newVitalOption: VitalOption = {
      amntLow: data.valueLow,
      amntHigh: data.valueHigh,
      rangeLow: rangeLowIndex,
      rangeHigh: rangeHighIndex,
      // rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      // rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
    };

    if (!previousDose || formik.values.optionItems.length === 0) {
      let newOptions = [...formik.values.optionItems, newVitalOption];
      newOptions.sort((a: VitalOption, b: VitalOption) => {
        return a.rangeLow - b.rangeLow;
      });
      formik.setFieldValue('optionItems', newOptions);
    } else if (previousDose) {
      /* Filter out the previous dose and add the new dose */
      let updatedOptionItems: VitalOption[] = formik.values.optionItems.filter(
        (item: any) => !isObjectEqual(previousDose, item)
      );
      updatedOptionItems.push(newVitalOption);
      updatedOptionItems.sort((a: VitalOption, b: VitalOption) => {
        return a.rangeLow - b.rangeLow;
      });
      formik.setFieldValue('optionItems', updatedOptionItems);
    } else {
      console.error(
        'Unexpected case: prevDose is not provided and also optionItems is not empty.'
      );
    }
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleRemoveVital = (dose: VitalOption) => {
    const updatedOptionItems = formik.values.optionItems.filter(
      (item: VitalOption) => !isObjectEqual(dose, item)
    );

    // Set the filtered items back to the formik state
    formik.setFieldValue('optionItems', updatedOptionItems);
  };

  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleEdit = (item: VitalItem, index: any) => {
    if (isEditMode) {
      setDoseIndex(index);
      setSidebarVisible(true);
      setSelectedData(item);
      setEditDose(true);
    }
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft =
      parmVital.status === ProgressStatus.DRAFT && parmVital.activeID == null;
    let response = await deleteVital(parmVital, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete infusion', response.data);
    }
  };

  return (
    <div className="screen-container">
      {isCancelWarningModal && (
        <ConfirmModal
          isVisible={isCancelWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsCancelWarningModal(false);
          }}
          handleSubmit={handleCancel}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Vital.  Click cancel to return to Vital details.  To cancel without saving changes, select Abandon.`}
        />
      )}

      {isOpen && (
        <DraftSaveModal
          department={database.department}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsEditMode(true);
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Vital?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you would like to delete ${parmVital.name}?`}
        />
      )}

      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Vital.  Click cancel to return to Vital details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      {sidebarVisible && (
        <ProtocolDoseSideout
          type="Vital"
          doseIndex={doseIndex}
          dose={selectedData ? selectedData : undefined}
          visible={sidebarVisible}
          onSubmit={handleUpateVital}
          setVisible={handleCloseModal}
          editDose={editDose}
          parentModel={parmVital}
          protocol={null}
          onRemove={handleRemoveVital}
        />
      )}
      <ProtocolHeader
        name={parmVital.name}
        status={parmVital.status}
        type={'protocol'}
        page={protocol ? protocol.name : 'Vitals'}
        protocolDetail={protocol}
        isSaveButton={isEditMode}
        isSaveActive={formik.dirty && formik.isValid}
        // isVersionButton={true}
        rightSideBtn={isEditMode ? 'save' : 'edit'}
        isEditButton={!isEditMode}
        isDotButton={true}
        isBackButton={true}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmVital.uid : ''}
        // isVersionButton={true}
        isDeleteButton={isEditMode}
        handleCancel={handleBack}
        handleCancelEdit={handleCancel}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft && !isEditMode) setIsEditMode(true);
          });
        }}
        handleDelete={handleDelete}
        handleSave={() => formik.submitForm()}

        // handleVersion={handleVersion}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="editVital-header">
            Vital Information
          </h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {parmVital.modifiedBy
                  ? parmVital.modifiedBy?.firstName +
                    ' ' +
                    parmVital.modifiedBy?.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmVital.dbVital?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(parmVital.dbVital.updatedAt, true)}
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmVital.version}</div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Vital Options</h5>
          {isEditMode && (
            <div style={{ marginTop: '5px' }}>
              <span className="ketamine-general-label">Option</span>
              <div
                onClick={onAddDoseClick}
                className="cursorPointer contentBorder protocolCalculationPad contentHeading newProtocolBorder newRouteButton"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span>
                  <HiPlus className="text-icon " /> Add Option
                </span>
              </div>
            </div>
          )}
          <ViewportList items={formik.values.optionItems}>
            {(item: any, index: any) => (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEdit(item, index);
                }}
              >
                <VitalDose dose={item} doseIndex={index} />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default VitalProtocolEditPage;
