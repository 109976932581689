import ProtocolItem from './ProtocolItem';
import { Category, Department, ProgressStatus, User } from '../../models';
import DepartmentItem from './DepartmentItem';
import ModelItem from './ModelItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';

export const cloneCategory = (category: CategoryItem): CategoryItem => {
  const clone = new CategoryItem(category.getModel());
  clone.protocols = category.protocols;
  clone.subCategories = category.subCategories;
  clone.modifiedBy = category.modifiedBy;
  return clone;
};

class CategoryItem implements ModelItem {
  uid: string;
  depID: string;
  name: string;
  activeID: string | null | undefined;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  index: number;
  protocols: ProtocolItem[];
  subCategories: CategoryItem[];
  pairedDepIDs: string[] | null | undefined;
  model: Category;
  sum: number;
  modifiedBy: User | null | undefined;
  version: string;
  isPublic: boolean;

  keychainID: string | null | undefined;

  constructor(category: Category) {
    this.uid = category.id;
    this.name = category.name;
    this.depID = category.departmentID;
    this.index = category.index;
    this.activeID = category.activeID;
    this.status =
      category.status != null ? category.status : ProgressStatus.ACTIVE;
    this.version = category.version != null ? category.version : 'v1.0.0';
    this.protocols = [];
    this.subCategories = [];
    this.pairedDepIDs = category.pairedDepIDs;
    this.model = category;
    this.sum = this.protocols.length + this.subCategories.length;
    this.modifiedBy = null;
    this.keychainID = category.keychainID;
    this.isPublic = category.isPublic ? category.isPublic : false;
  }
  getAmount(weight: PatientWeight): string {
    throw new Error('Method not implemented.');
  }

  // constructor(name: string, id: string) {
  //     this.uid        = "1234-567-890";
  //     this.depID      = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.id         = id;
  //     this.name       = name;
  //     this.protocols  = [];
  //     this.model      = null;
  // }

  /**
   * Checks if the given object is equal to this object.
   * @param obj The object to compare to this object.
   * @returns True if the objects are equal, false otherwise.
   */
  equals(obj: any): boolean {
    return obj instanceof CategoryItem && this.uid === obj.uid;
  }

  /**
   * Gets the number of protocols in the category.
   * @returns The number of protocols in the category.
   */
  getProtocolCount(): number {
    return this.protocols.length;
  }

  /**
   *
   */
  getModel(): Category {
    return this.model as Category;
  }

  /**
   * Gets the department id of the owner of this category.
   * @returns the department id of the owner of this category
   */
  getDepID(): string {
    return this.depID;
  }

  /**
   * Gets the uid of the category from the database.
   * @returns The uid of the category.
   */
  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the name of the category.
   * @returns The name of the category.
   */
  getName(): string {
    return this.name;
  }

  /**
   * Gets the index of the category.
   * @returns The index of the category.
   */
  getIndex(): number {
    return this.index;
  }

  getSum(): number {
    return this.subCategories.length + this.protocols.length;
  }

  /**
   * Gets the description of the category. Ex. "A Airway"
   * @returns The description of the category. Ex. "A Airway"
   */
  getDescription(): string {
    return this.makeDetails();
  }

  /**
   * Gets the protocols in the category in a List.
   * @returns The protocols in the category in a List.
   */
  getProtocols(): ProtocolItem[] {
    return this.protocols;
  }
  // Rename sub category
  renameSubCategoryItem(category: any, updatedCategory: CategoryItem): void {
    const index = this.subCategories.findIndex(
      (item) => item?.getName() === category?.name
    );
    this.subCategories[index] = updatedCategory;
  }

  // Rename protcol
  renameProtocol(protocol: any, updatedProtocol: ProtocolItem): void {
    const index = this.protocols.findIndex(
      (item) => item?.getName() === protocol?.name
    );
    this.protocols[index] = updatedProtocol;
    return;
  }

  /**
   * Gets the subcategories in the category in a List.
   * @returns The subcategories in the category in a List.
   */
  getSubCategories(): CategoryItem[] {
    return this.subCategories;
  }

  setProtocols(protocols: ProtocolItem[]): void {
    for (let i = 0; i < protocols.length; i++) {
      this.addProtocol(protocols[i]);
    }
  }

  /**
   * Adds a protocol to the category.  The protocols are sorted by name.
   * @param child The protocol to add to the category.
   */
  addProtocol(child: ProtocolItem): void {
    /** 20-7-23 Praveen: Modified adding first data and then sorting it as above was failing to more than 1 index */
    for (let i = 0; i < this.protocols.length; i++)
      if (this.protocols[i].equals(child)) return;
    this.protocols.push(child);
    this.protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });
    this.sum = this.protocols.length + this.subCategories.length;
    return;
  }

  addSubCategories(child: CategoryItem): void {
    debugger;
    this.subCategories.push(child);
    this.subCategories.sort((a: any, b: any) => b.name - a.name);
    this.sum = this.protocols.length + this.subCategories.length;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  /* ------------- Private Helpers ------------- */
  /**
   * Gets the description of the category. Ex. "A Airway"
   * @returns The description of the category. Ex. "A Airway"
   */
  private makeDetails(): string {
    return `${this.name}`;
  }

  /**
   * Gets the string representation of the CategoryItem object for debugging.
   * @returns string representation of the CategoryItem object
   */
  toString(): string {
    return `CategoryItem -> {
            uid=${this.uid},
            name=${this.name},
            protocols=${this.protocols.length},
            subCategories=${this.subCategories.length},
            depID=${this.depID},
            activeID=${this.activeID},
            status=${this.status},
            index=${this.index},
            pairedDepIDs=${this.pairedDepIDs ? this.pairedDepIDs.length : 0},
            sum=${this.sum},
            modifiedBy=${this.modifiedBy ? this.modifiedBy.id : 'null'},
            version=${this.version},
            isPublic=${this.isPublic},
            keychainID=${this.keychainID}
        }`;
  }
}

export default CategoryItem;
