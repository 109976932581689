import { DataStore } from '@aws-amplify/datastore';
import {
  Ambulance,
  Category,
  Department,
  Drip,
  Electrical,
  Equipment,
  Log,
  Medication,
  Protocol,
  RequireSync,
  Vitals,
  Form,
  OneWeight,
  MedicationProtocol,
  MedicationRange,
  User,
  ElectricalOption,
  ElectricalRange,
  ElectricalShockOption,
  ElectricalShockRange,
  Contact,
  Workbook,
  UserStatus,
  WeightObject,
  Keychain,
} from '../models';
import MedicationItem from './model/MedicationItem';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import EquipmentItem from './model/EquipmentItem';
import ElectricalItem from './model/ElectricalItem';
import ContactItem from './model/ContactItem';
import FormItem from './model/FormItem';
import VitalItem from './model/VitalItem';
import ElectricalSubItem from './model/ElectricalSubItem';
import MedicationSubItem from './model/MedicationSubItem';
import LogItem from './model/LogItem';
import { Storage } from '@aws-amplify/storage';
import DepartmentItem from './model/DepartmentItem';
import NotificationItem from './model/NotificationItem';
import { Notification } from '../models';
import { API, Hub } from 'aws-amplify';
import { ElectricalShock } from '../models';
import WorkbookItem from './model/WorkbookItem';
import { globals, upgradeVersion } from '../ui/_global/common/Utils';
import { GraphQLQuery } from '@aws-amplify/api';
import { ListProtocolsQuery, ProgressStatus } from '../API';
import * as queries from '../graphql/queries';
import { getHashedPin } from '../ui/_global/common/Encrypt';
import KeychainItem from './model/KeychainItem';
import { getUserByCognitoID } from './functions/UserDB';
import { getDepartmentByID } from './functions/DepartmentDB';
import { handleGetDepartment } from '../store/actions';
import { Dispatch } from 'react';
import { add } from 'lodash';

export enum ResponseType {
  Success,
  Failure,
  Warning,
  Info,
}

export type Response = {
  type: ResponseType;
  data: any;
};

export type DatabaseResponse = {
  department: DepartmentItem;
  users: User[];
  categories: CategoryItem[];
  protocols: ProtocolItem[];
  ambulances: Ambulance[];
  oneWeights: OneWeight[];
  medications: MedicationItem[];
  infusions: MedicationItem[];
  equipment: EquipmentItem[];
  electrical: ElectricalItem[];
  checklists: FormItem[];
  vitals: VitalItem[];
  logs: LogItem[];
  notifications: NotificationItem[];
  contacts: ContactItem[];
  weightObjects: WeightObject[];
  keychains: KeychainItem[];
};

/**
 * This function returns the users information from the subID from AWS Cognito.
 * @param subID The subID of the user to fetch information for.
 * @returns @type Response data -> 0 - user information, 1 - department information
 */
export const getUserInformation = async (
  subID: string,
  username: string
): Promise<Response> => {
  try {
    let user = await DataStore.query(User, (u) =>
      u.or((u) => [u.cognitoID.eq(subID), u.cognitoID.eq(username)])
    );

    if (user.length === 0) {
      await DataStore.start();
      const isSynced = await waitForSyncQueriesReady();

      if (!isSynced) {
        console.warn(
          'DataStore syncing issue detected. Proceeding to fetch user information and will resolve syncing in background.'
        );
        handleSyncingInBackground();
      }
      user = await DataStore.query(User, (u) =>
        u.or((u) => [u.cognitoID.eq(subID), u.cognitoID.eq(username)])
      );

      if (user.length === 0) {
        console.error(
          'Error fetching user information: No user found for subID:',
          subID,
          'or username:',
          username
        );
        return { type: ResponseType.Failure, data: 'No user found' };
      }
    }

    if (user[0].departmentID == null) {
      console.error('Error fetching user information: No department found');
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }

    const department = await DataStore.query(Department, (d) =>
      d.id.eq(user[0].departmentID)
    );
    let dep = new DepartmentItem(department[0]);
    let logoResult = await findDepartmentLogo(dep);
    if (logoResult.type === ResponseType.Success)
      dep.logoVerifiedUrl = logoResult.data;
    else console.error('Error fetching department logo:', logoResult.data);

    if (dep.subDepIDs.length > 0)
      /* Get the sub departments & the last selected department */
      await getSubDepartments(dep, 'lazy');

    return {
      type: ResponseType.Success,
      data: [user[0], dep],
    };
  } catch (error) {
    console.error('Error fetching user information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function returns the users information from the subID from AWS Cognito.
 * @param subID The subID of the user to fetch information for.
 * @returns @type Response data -> 0 - user information, 1 - department information
 */
export const getUserInformationAPI = async (
  subID: string,
  username: string
): Promise<Response> => {
  try {
    let user = await getUserByCognitoID(username);

    // if (user.length === 0) {
    //   await DataStore.start();
    //   const isSynced = await waitForSyncQueriesReady();

    //   if (!isSynced) {
    //     console.warn(
    //       'DataStore syncing issue detected. Proceeding to fetch user information and will resolve syncing in background.'
    //     );
    //     handleSyncingInBackground();
    //   }
    //   user = await DataStore.query(User, (u) =>
    //     u.or((u) => [u.cognitoID.eq(subID), u.cognitoID.eq(username)])
    //   );

    //   if (user.length === 0) {
    //     console.error(
    //       'Error fetching user information: No user found for subID:',
    //       subID,
    //       'or username:',
    //       username
    //     );
    //     return { type: ResponseType.Failure, data: 'No user found' };
    //   }
    // }

    if (user == null) {
      console.error(
        'Error fetching user information: No user found for subID:',
        subID,
        'or username:',
        username
      );
      return { type: ResponseType.Failure, data: 'No user found' };
    }

    if (user.departmentID == null) {
      console.error('Error fetching user information: No department found');
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }

    const department = await getDepartmentByID(user.departmentID);

    if (department == null) {
      console.error('Error fetching user information: No department found');
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }

    let logoResult = await findDepartmentLogo(department);
    if (logoResult.type === ResponseType.Success)
      department.logoVerifiedUrl = logoResult.data;
    else console.error('Error fetching department logo:', logoResult.data);

    if (department.subDepIDs.length > 0)
      /* Get the sub departments & the last selected department */
      await getSubDepartments(department, 'lazy');

    return {
      type: ResponseType.Success,
      data: [user, department],
    };
  } catch (error) {
    console.error('Error fetching user information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getDepartmentByCode = async (code: string): Promise<Response> => {
  try {
    let department = await DataStore.query(Department, (d) =>
      d.uniqueCode.eq(code)
    );
    if (department.length === 0) {
      console.error(
        'Error fetching department information: No department found'
      );
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }
    let dep = new DepartmentItem(department[0]);
    let logoResult = await findDepartmentLogo(dep);
    if (logoResult.type === ResponseType.Success)
      dep.logoVerifiedUrl = logoResult.data;
    else console.error('Error fetching department logo:', logoResult.data);

    if (dep.subDepIDs.length > 0) {
      /* Get the sub departments & the last selected department */
      await getSubDepartments(dep, 'force');

      let id = localStorage.getItem('lastSelectedDepID');
      if (id) {
        let lastSelectedDep = dep.subDeps?.find((d) => d.id === id);
        if (lastSelectedDep) dep.activeSubDep = lastSelectedDep;
        else dep.activeSubDep = dep.subDeps?.[0];
      } else dep.activeSubDep = dep.subDeps?.[0];
    }

    return {
      type: ResponseType.Success,
      data: dep,
    };
  } catch (error) {
    console.error('Error fetching department information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const waitForSyncQueriesReady = () => {
  return new Promise((resolve, reject) => {
    let timeoutId: any;
    const listener = Hub.listen('datastore', (hubData) => {
      const { event, data } = hubData.payload;
      if (event === 'syncQueriesReady') {
        clearTimeout(timeoutId);
        listener();
        resolve('Success');
      } else if (event === 'subscriptionError') {
        clearTimeout(timeoutId);
        listener();
        reject(data);
      }
    });
    timeoutId = setTimeout(() => {
      listener();
      reject(new Error('Timeout waiting for syncQueriesReady'));
    }, 10000);
  });
};

const handleSyncingInBackground = async () => {
  if (globals.debug)
    if (globals.debug) console.log('Handling syncing in background');
  let attempt = 0;
  const maxAttempts = 5;

  const trySync = async () => {
    try {
      await DataStore.start();
      const isSynced = await waitForSyncQueriesReady();
      if (isSynced) {
        if (globals.debug)
          if (globals.debug)
            console.log('DataStore successfully synced in background');
        return true;
      } else {
        throw new Error('DataStore sync unsuccessful');
      }
    } catch (error) {
      console.error('Background syncing attempt failed:', error);
      if (attempt < maxAttempts) {
        attempt++;
        if (globals.debug)
          if (globals.debug)
            console.log(`Retrying sync in background, attempt ${attempt}`);
        setTimeout(trySync, 5000 * attempt);
      }
    }
  };
  trySync();
};

export const findDepartmentLogo = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    if (department.logoURL == null) {
      return {
        type: ResponseType.Failure,
        data: 'Department URL is null',
      };
    }

    /* Check if the logo is stored in local storage */
    let result: any = localStorage.getItem(department.logoURL);
    if (result)
      return {
        type: ResponseType.Success,
        data: result,
      };

    /* If not, fetch it from S3 */
    result = await Storage.get(department.logoURL, {
      level: 'public',
      download: true,
    });
    const base64String = await convertBlobToBase64(result.Body);
    localStorage.setItem(department.logoURL, base64String);

    return {
      type: ResponseType.Success,
      data: base64String,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Convert a blob to a base64 string
 * @param blob The blob to convert to base64
 * @returns A promise with the base64 string or ArrayBuffer
 */
const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (reader.result) resolve(reader.result as string);
      else reject('Error converting blob to base64');
    };
    reader.readAsDataURL(blob);
  });
};

/**
 * Load the sub departments for the department
 * @param department The department to fetch the sub departments for
 * @returns Response.Success -> DepartmentModel with sub departments, Response.Error -> Error message
 */
export const getSubDepartments = async (
  department: DepartmentItem,
  fetchLogo?: 'force' | 'lazy'
): Promise<Response> => {
  try {
    /* Stops the DataStore Sync Process */
    // await DataStore.stop();
    for (let i = 0; i < department.subDepIDs.length; i++) {
      let id = department.subDepIDs[i];
      const depModel = await DataStore.query(Department, id);
      if (depModel == null) {
        if (globals.debug)
          if (globals.debug)
            console.log(
              'Error fetching sub departments: No sub department found -> id:',
              id
            );
        continue;
      }
      // else if (depModel.activeStatus == null || depModel.activeStatus) {
      let dep = new DepartmentItem(depModel);
      if (fetchLogo === 'force') {
        let logoResult = await findDepartmentLogo(dep);
        if (logoResult.type === ResponseType.Success)
          dep.logoVerifiedUrl = logoResult.data;
      } else if (fetchLogo === 'lazy') {
        findDepartmentLogo(dep).then((logoResult: Response) => {
          if (logoResult.type === ResponseType.Success)
            dep.logoVerifiedUrl = logoResult.data;
        });
      }
      department.addSubDep(dep);
      if (globals.debug) {
        console.log('Sub Department:', dep.name, dep.id);
      }
      // }
    }

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error) {
    console.error('Error fetching user information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Create a department in the database
 * @param name The name of the department
 * @param location The location of the department
 * @param versions The protocol versions of the department
 * @param cognitoID The cognito ID of the user creating the department
 * @returns A response object with the type and data
 *      - SUCCESS - The department was created and returned in data
 *      - FAILURE - The department was not created and the error is returned in data
 */
export const createDepartment = async (department: any): Promise<Response> => {
  try {
    let sync = new RequireSync({
      sync: false,
    });
    let dep = new Department({
      Categories: [],
      OneWeights: [],
      name: department.name,
      location: department.location,
      protocolVersions: 'v1.0.0',
      shiftTypes: [],
      Medications: [],
      Equipment: [],
      Forms: [],
      Vitals: [],
      Electricals: [],
      cognitoID: department.cognitoID ? department.cognitoID : 'n',
      Users: [],
      Logs: [],
      Ambulances: [],
      Drips: [],
      Contacts: [],
      RequireSync: sync,
      logoID: department.logoID ? department.logoID : 'n',
      userID: department.userID ? department.userID : 'n',
      neonateCutoff: 5,
      pediatricCutoff: 40,
      calculators: [],
      adultRanges: [],
      softwarePlan: null,
      uniqueCode: department.uniqueCode,
      hashedPin: department.hashedPin,
      saltedPin: department.saltedPin,
      isPublic: department.isPublic ? department.isPublic : false,
      infusionCalculation: false,
      activeStatus: true,
      parentDepID: department.parentDep ? department.parentDep.id : undefined,
      config: {
        neonateCutoff: 5,
        pediatricCutoff: 40,
        calculators: [],
        adultRanges: [],
        softwarePlan: null,
        infusionCalculation: false,
        isPublic: department.isPublic ? department.isPublic : false,
        realTimeUpdating: false,
        oneweightEnabled: true,
        ageFilterEnabled: false,
        ageGroupFilterEnabled: false,
      },
    });
    let rs = await DataStore.save(sync);
    let d = await DataStore.save(dep);

    if (d && department.parentDep) {
      console.log('Adding ID to parent department');
      addIDToDepartment(department.parentDep.id, [d.id]);
    }
    return {
      type: ResponseType.Success,
      data: d,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const addIDToDepartment = async (
  departmentID: string,
  subDepIDs: string[]
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let dep = await DataStore.query(Department, departmentID);
      if (dep == null) {
        reject('Department not found');
        return;
      }
      let ids = [
        ...new Set(
          dep.subDepIDs ? [...dep.subDepIDs, ...subDepIDs] : subDepIDs
        ),
      ];
      let d = await DataStore.save(
        Department.copyOf(dep, (updated) => {
          updated.subDepIDs = ids;
        })
      );
      resolve({
        type: ResponseType.Success,
        data: d,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const createAmbulance = async (ambulance: any): Promise<Response> => {
  try {
    let a = await DataStore.save(
      new Ambulance({
        name: ambulance.name,
        departmentID: ambulance.department.id,
      })
    );
    return {
      type: ResponseType.Success,
      data: a,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(date);
};

export const editAmbulance = async (ambulance: any): Promise<Response> => {
  try {
    let a = await DataStore.query(Ambulance, ambulance.id);
    if (!a)
      return {
        type: ResponseType.Failure,
        data: 'Ambulance not found',
      };
    let res = await DataStore.save(
      Ambulance.copyOf(a, (updated) => {
        updated.name = ambulance.name;
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteAmbulance = async (
  ambulance: Ambulance | null
): Promise<Response> => {
  if (!ambulance)
    return {
      type: ResponseType.Failure,
      data: 'Ambulance not found',
    };
  try {
    const toDelete = await DataStore.query(Ambulance, ambulance.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Ambulance not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const createContact = async (contact: any): Promise<Response> => {
  try {
    let depIDs = contact.pairedDeps
      ? contact.pairedDeps.map((d: any) => d.id)
      : [];
    let a = await DataStore.save(
      new Contact({
        fullName: contact.fullName,
        number: contact.number,
        index: Number(contact.index),
        note: contact.note,
        departmentID: contact.departmentID,
        pairedDepIDs: depIDs,
        title: contact.title,
      })
    );
    return {
      type: ResponseType.Success,
      data: a,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const editContact = async (contact: any): Promise<Response> => {
  try {
    let a = await DataStore.query(Contact, contact.id);
    if (!a)
      return {
        type: ResponseType.Failure,
        data: 'Contact not found',
      };
    let ids = contact.pairedDeps
      ? contact.pairedDeps.map((d: any) => d.id)
      : [];
    let res = await DataStore.save(
      Contact.copyOf(a, (updated) => {
        updated.fullName = contact.fullName;
        updated.number = contact.number;
        updated.note = contact.note;
        updated.index = Number(contact.index);
        updated.departmentID = contact.departmentID;
        updated.pairedDepIDs = ids;
        updated.title = contact.title;
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const createWeightObject = async (
  weightObject: any,
  createdBy: User,
  pairedDeps?: DepartmentItem[]
): Promise<Response> => {
  try {
    let ids = pairedDeps ? pairedDeps.map((d: DepartmentItem) => d.id) : [];
    let w = await DataStore.save(
      new WeightObject({
        name: weightObject.name,
        weight: Number(weightObject.weightLbs),
        createdBy: createdBy.id,
        version: weightObject.version,
        pairedDepIDs: ids,
        departmentID: weightObject.departmentID,
      })
    );
    return {
      type: ResponseType.Success,
      data: w,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const editWeightObject = async (
  weightObject: any,
  modifiedBy: User,
  pairedDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    let w = await DataStore.query(WeightObject, weightObject.id);
    if (!w)
      return {
        type: ResponseType.Failure,
        data: 'Could not find existing subtractable weight. Try again later.',
      };
    let res = await DataStore.save(
      WeightObject.copyOf(w, (updated) => {
        updated.name = weightObject.name;
        updated.weight = Number(weightObject.weightLbs);
        updated.pairedDepIDs = pairedDeps.map((d: DepartmentItem) => d.id);
        updated.modifiedBy = modifiedBy.id;
        updated.version = upgradeVersion(weightObject.version);
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const editUser = async (
  user: any,
  pairedDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    let u = await DataStore.query(User, user.id);
    if (!u)
      return {
        type: ResponseType.Failure,
        data: 'User not found',
      };
    let res = await DataStore.save(
      User.copyOf(u, (updated) => {
        updated.firstName = user.firstName;
        updated.lastName = user.lastName;
        updated.pairedDepIDs = pairedDeps.map((d: DepartmentItem) => d.id);
        updated.type = user.type;
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const updateUserStatus = async (
  user: User | null,
  status: UserStatus
): Promise<Response> => {
  if (!user) {
    if (globals.debug) console.log('User not found', 'Params:', user, status);
    return {
      type: ResponseType.Failure,
      data: 'User not found',
    };
  }
  try {
    const dbUser = await DataStore.query(User, user.id);
    if (dbUser) {
      //Modify the user status to
      let res = await DataStore.save(
        User.copyOf(dbUser, (updated) => {
          updated.status = status;
        })
      );
      if (globals.debug) console.log('User status updated:', res);
      return {
        type: ResponseType.Success,
        data: res,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'User not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteContact = async (
  contact: Contact | null
): Promise<Response> => {
  if (!contact)
    return {
      type: ResponseType.Failure,
      data: 'Contact not found',
    };
  try {
    const toDelete = await DataStore.query(Contact, contact.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Contact not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteWeightObject = async (
  object: WeightObject | null
): Promise<Response> => {
  if (!object)
    return {
      type: ResponseType.Failure,
      data: 'Subtractble Weight not found',
    };
  try {
    const toDelete = await DataStore.query(WeightObject, object.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Subtractable Weight not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteDepartment = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const toDelete = await DataStore.query(Department, department.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Department not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const editDepartment = async (department: any): Promise<Response> => {
  try {
    const toUpdate = await DataStore.query(Department, department.id);
    if (toUpdate) {
      let dep = await DataStore.save(
        Department.copyOf(toUpdate, (updated) => {
          updated.name = department.name;
          updated.location = department.location;
          updated.protocolVersions = department.protocolVersions;
          updated.shiftTypes = department.shiftTypes;
          // updated.cognitoID = department.cognitoID;
          updated.logoID = department.logoID;
        })
      );
      return {
        type: ResponseType.Success,
        data: dep,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Department not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteMedication = async (
  medication: Medication | null
): Promise<Response> => {
  if (!medication)
    return {
      type: ResponseType.Failure,
      data: 'Medication not found',
    };
  try {
    const toDelete = await DataStore.query(Medication, medication.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Medication not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const createNotification = async (
  notification: any
): Promise<Response> => {
  return {
    type: ResponseType.Success,
    data: null,
  };
  // try {
  // 	let n = await DataStore.save(
  // 		new Notification({
  // 			type: notification.type,
  // 			title: notification.title,
  // 			message: notification.message,
  // 			timestamp: new Date().toISOString(),
  // 			isReadIDs: [],
  // 			isAckIDs: [],
  // 			fileURLs: notification.fileURLs ? notification.fileURLs : [],
  // 			taggedProtocols: [],
  // 			questions: [],
  // 			departmentID: notification.departmentID,
  // 			createdBy: notification.createdBy,
  // 			modifiedBy: notification.createdBy,
  // 		})
  // 	);
  // 	if(globals.debug) console.log("Created Notification:", n);
  // 	let newNotification = new NotificationItem(n, []);
  // 	return {
  // 		type: ResponseType.Success,
  // 		data: newNotification,
  // 	};
  // } catch (e) {
  // 	return {
  // 		type: ResponseType.Failure,
  // 		data: e,
  // 	};
  // }
};

export const editNotification = async (
  notification: any
): Promise<Response> => {
  try {
    let n = await DataStore.query(Notification, notification.id);
    if (!n)
      return {
        type: ResponseType.Failure,
        data: 'Notification not found',
      };
    let res = await DataStore.save(
      Notification.copyOf(n, (updated) => {
        updated.title = notification.title;
        updated.message = notification.message;
        updated.type = notification.type;
        updated.timestamp = new Date().toISOString();
        updated.modifiedBy = notification.modifiedBy;
      })
    );
    let newNotification = new NotificationItem(res, []);
    return {
      type: ResponseType.Success,
      data: newNotification,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Get all departments in the database
 * @returns A response object with the type and data
 */
export const getDepartments = async (getLogos?: boolean): Promise<Response> => {
  try {
    let departments = await DataStore.query(Department);
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < departments.length; i++) {
      let dep = new DepartmentItem(departments[i]);
      deps.push(dep);
      if (getLogos) {
        findDepartmentLogo(dep).then((logoResult) => {
          if (logoResult.type === ResponseType.Success)
            dep.logoVerifiedUrl = logoResult.data;
          else
            console.error('Error fetching department logo:', logoResult.data);
        });
      }
    }
    mapPairedDepartments(deps);
    return {
      type: ResponseType.Success,
      data: deps,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

function mapPairedDepartments(departments: DepartmentItem[]): void {
  for (let i = 0; i < departments.length; i++) {
    let dep = departments[i];
    if (dep.subDepIDs) {
      for (let j = 0; j < dep.subDepIDs.length; j++) {
        let id = dep.subDepIDs[j];
        let pairedDep = departments.find((d: DepartmentItem) => d.id === id);
        if (pairedDep) {
          dep.addSubDep(pairedDep);
          departments.splice(departments.indexOf(pairedDep), 1);
        }
      }
    }
  }
}

// export const fetchDepartmentUser = async (
//   department: Department
// ): Promise<Response> => {
//   try {
//     let user = await DataStore.query(User, department.userID);
//     return {
//       type: ResponseType.Success,
//       data: user,
//     };
//   } catch (error) {
//     return {
//       type: ResponseType.Failure,
//       data: error,
//     };
//   }
// };

// export const   = async (department: Department): Promise<Response> => {
//     try {
//         let categories = await DataStore.query(Category, c => c.departmentID.eq(department.id));
//         let protocolIDs: string[] = []
//         for(let i = 0; i < categories.length; i++){
//             let c = categories[i];
//             if(c.pairedProtocols){
//                 for(let j = 0; j < c.pairedProtocols.length; j++)
//                     if(c.pairedProtocols[j]) protocolIDs.push(c.pairedProtocols[j]);
//             }
//         }
//         let response = await getProtocolsByIDs(protocolIDs);
//         let protocols = (response.type === ResponseType.Success) ? response.data : [];
//         let resp = await fetchMedications(department.id);
//         let medications = (resp.type === ResponseType.Success) ? resp.data : [];

//         let infusions = await DataStore.query(Drip, i => i.departmentID.eq(department.id));
//         let equipment = await DataStore.query(Equipment, e => e.departmentID.eq(department.id));
//         let electricals = await DataStore.query(Electrical, e => e.departmentID.eq(department.id));
//         let vitals = await DataStore.query(Vitals, v => v.departmentID.eq(department.id));
//         let logs = await DataStore.query(Log, l => l.departmentID.eq(department.id));
//         let forms = await DataStore.query(Form, f => f.departmentID.eq(department.id));
//         let ambulances = await DataStore.query(Ambulance, a => a.departmentID.eq(department.id));
//         return {
//             type: ResponseType.Success,
//             data: {
//                 categories: categories,
//                 protocols: protocols,
//                 medications: medications,
//                 infusions: infusions,
//                 equipment: equipment,
//                 electricals: electricals,
//                 vitals: vitals,
//                 logs: logs,
//                 forms: forms,
//                 ambulances: ambulances,
//             },
//         };
//     } catch (error) {
//         return {
//             type: ResponseType.Failure,
//             data: error,
//         };
//     }
// }

export const fetchPDFURLs = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/', {
      level: 'public',
    });

    let urls = result.results;

    // Filter to get only .pdf files
    const pdfFiles = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.pdf')
    );

    let pdfURLs: string[] = [];
    for (let i = 0; i < pdfFiles.length; i++) {
      let file = pdfFiles[i];
      pdfURLs.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: pdfURLs,
    };
  } catch (e) {
    console.error('Error fetching PDF URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchPngURLs = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/', {
      level: 'public',
    });

    let urls = result.results;

    const pngFiles = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.png')
    );

    let pngURLs: string[] = [];
    for (let i = 0; i < pngFiles.length; i++) {
      let file = pngFiles[i];
      pngURLs.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: pngURLs,
    };
  } catch (e) {
    console.error('Error fetching PNG URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchNotificationUrls = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/notifications/', {
      level: 'public',
    });

    let urls = result.results;

    if (globals.debug) if (globals.debug) console.log('URLS:', urls);

    const pngFiles = urls.filter(
      (file: any) =>
        typeof file.key === 'string' &&
        (file.key.toLowerCase().endsWith('.png') ||
          file.key.toLowerCase().endsWith('.jpg') ||
          file.key.toLowerCase().endsWith('.jpeg'))
    );

    const mp4Files = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.mp4')
    );

    if (globals.debug) if (globals.debug) console.log('PNG FILES:', pngFiles);
    if (globals.debug) if (globals.debug) console.log('MP4 FILES:', mp4Files);

    let pngURLs: string[] = [];
    for (let i = 0; i < pngFiles.length; i++) {
      let file = pngFiles[i];
      pngURLs.push(file.key);
    }

    let mp4URLs: string[] = [];
    for (let i = 0; i < mp4Files.length; i++) {
      let file = mp4Files[i];
      mp4URLs.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: {
        png: pngURLs,
        mp4: mp4URLs,
      },
    };
  } catch (e) {
    console.error('Error fetching PNG URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchNotificationVideoURLs = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/notifications/', {
      level: 'public',
    });

    let urls = result.results;

    const mp4Files = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.mp4')
    );

    let mp4URLs: string[] = [];
    for (let i = 0; i < mp4Files.length; i++) {
      let file = mp4Files[i];
      mp4Files.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: mp4URLs,
    };
  } catch (e) {
    console.error('Error fetching MP4 URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchPdfFile = async (fileURL: string): Promise<Response> => {
  try {
    const result: any = await Storage.get(fileURL, { level: 'public' });

    return {
      type: ResponseType.Success,
      data: result,
    };
  } catch (e) {
    console.error('Error fetching PNG URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchAmbulances = async (
  dep: DepartmentItem
): Promise<Response> => {
  try {
    let ambulancesList: Ambulance[] = [];
    if (dep.isMultiDep) {
      for (let i = 0; i < dep.subDepIDs.length; i++) {
        let ambulances = await DataStore.query(Ambulance, (a) =>
          a.departmentID.eq(dep.subDepIDs[i])
        );
        ambulancesList.push(...ambulances);
      }
    } else {
      ambulancesList = await DataStore.query(Ambulance, (a) =>
        a.departmentID.eq(dep.id)
      );
    }
    return {
      type: ResponseType.Success,
      data: ambulancesList,
    };
  } catch (error) {
    console.error('Error fetching ambulances:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchKeychains = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let keychainList: Keychain[] = await DataStore.query(Keychain, (c) =>
      c.departmentID.eq(department.id)
    );

    let keychains: KeychainItem[] = [];
    for (let i = 0; i < keychainList.length; i++) {
      let keychain = new KeychainItem(keychainList[i]);
      keychains.push(keychain);
    }

    keychains.sort((a: KeychainItem, b: KeychainItem) =>
      a.name.localeCompare(b.name)
    );

    // contactsList.sort((a: Contact, b: Contact) => a.fullName.localeCompare(b.fullName));
    return {
      type: ResponseType.Success,
      data: keychains,
    };
  } catch (error) {
    console.error('Error fetching keyechains:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchContacts = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let contactsList: any[];
    /* If it is a sub department query by the ID in the pairedDepartments array */
    // if (department.subDeps && department.subDeps.length > 0)
    // 	contactsList = await DataStore.query(Contact, (c) =>
    // 		c.pairedDepIDs.contains(department.id)
    // 	);
    /* Otherwise query by the department ID owner - This is needed because the umbrella department will own ALL the contacts */
    contactsList = await DataStore.query(Contact, (c) =>
      c.departmentID.eq(department.id)
    );
    /* Group all the contacts by their title then only have the first title display */

    /* Sort all contacts by their title then by their index */
    contactsList.sort((a: Contact, b: Contact) => {
      if (a.title === b.title) return a.index - b.index;
      else if (a.title && b.title) return a.title.localeCompare(b.title);
      else return a.fullName.localeCompare(b.fullName);
    });

    /* Go through the list and create the ContactItem objects */
    if (contactsList.length === 0)
      return {
        type: ResponseType.Success,
        data: [],
      };

    let contacts = [];
    let title = '';
    let isTitle = false;
    for (let i = 0; i < contactsList.length; i++) {
      let c: Contact = contactsList[i];
      /* If the title is not the same as the previous title then make it a title */
      if (c.title && c.title !== title) {
        title = c.title;
        isTitle = true;
      } else isTitle = false;

      contacts.push(new ContactItem(contactsList[i], isTitle));
    }

    // contactsList.sort((a: Contact, b: Contact) => a.fullName.localeCompare(b.fullName));
    return {
      type: ResponseType.Success,
      data: contacts,
    };
  } catch (error) {
    console.error('Error fetching contacts:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchWeightObjects = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let weightList: WeightObject[] = await DataStore.query(WeightObject, (wo) =>
      wo.departmentID.eq(department.id)
    );

    if (globals.debug)
      console.log('Found ' + weightList.length + ' weight objects');

    /* Sort all contacts by their title then by their index */
    weightList.sort((a: WeightObject, b: WeightObject) => {
      if (a.weight === b.weight) return a.name.localeCompare(b.name);
      return a.weight - b.weight;
    });

    return {
      type: ResponseType.Success,
      data: weightList,
    };
  } catch (error) {
    if (globals.debug) console.error('Error fetching weight objects:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchUsers = async (depID: string): Promise<Response> => {
  try {
    const usersList = await DataStore.query(User, (u) =>
      u.departmentID.eq(depID)
    );
    return {
      type: ResponseType.Success,
      data: usersList,
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchUser = async (userID: string): Promise<Response> => {
  try {
    const user = await DataStore.query(User, userID);
    return {
      type: ResponseType.Success,
      data: user,
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchOneWeights = async (depID: string): Promise<Response> => {
  try {
    const oneWeightsList = await DataStore.query(OneWeight, (o) =>
      o.departmentID.eq(depID)
    );
    return {
      type: ResponseType.Success,
      data: oneWeightsList,
    };
  } catch (error) {
    console.error('Error fetching one weights:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchCategoriesWithProtocols = async (
  depID: string,
  isSubDepartment: boolean = false,
  _status?:
    | ProgressStatus
    | 'ACTIVE'
    | 'DRAFT'
    | 'DELETED'
    | 'ARCHIVE'
    | null
    | undefined
): Promise<Response> => {
  try {
    const categoriesList = await DataStore.query(Category, (c) =>
      c.and((c) => [
        isSubDepartment
          ? c.pairedDepIDs.contains(depID)
          : c.departmentID.eq(depID),
        c.or((c) =>
          _status
            ? [c.status.eq(_status)]
            : [
                c.status.eq(undefined),
                c.status.eq('ACTIVE'),
                c.status.eq('DRAFT'),
              ]
        ),
      ])
    );

    let categories: CategoryItem[] = [];
    let protocols = [];
    for (let i = 0; i < categoriesList.length; i++) {
      let category = new CategoryItem(categoriesList[i]);

      if (category.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (category.activeID !== null)
          categories = categories.filter(
            (c: CategoryItem) => c.uid !== category.activeID
          );
      } else if (category.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = categories.find(
          (c: CategoryItem) => c.activeID === category.uid
        );
        if (isAlreadyDraft) continue;
      }

      // Fetching protocols for this category
      const protocolsResponse = await fetchProtocols(
        category,
        isSubDepartment ? depID : '',
        _status
      );
      if (protocolsResponse.type === ResponseType.Success) {
        protocols.push(...protocolsResponse.data);
        for (let j = 0; j < protocolsResponse.data.length; j++)
          category.addProtocol(protocolsResponse.data[j]);
      } else
        console.error(
          `Error fetching protocols for category ${category.getUid()}:`,
          protocolsResponse.data
        );

      categories.push(category);
    }
    //Sort the categories by their order
    categories.sort((a, b) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });
    return {
      type: ResponseType.Success,
      data: [categories, protocols],
    };
  } catch (error) {
    console.error('Error fetching categories:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchProtocols = async (
  cat: CategoryItem,
  subDepID: string = '',
  _status?:
    | ProgressStatus
    | 'ACTIVE'
    | 'DRAFT'
    | 'DELETED'
    | 'ARCHIVE'
    | null
    | undefined
): Promise<Response> => {
  try {
    /* Fetch the protocols for this category and then sort by index */
    let id: string =
      cat.status === 'DRAFT' && cat.activeID ? cat.activeID : cat.uid;
    const protocolList = await DataStore.query(Protocol, (p) =>
      p.and((p) =>
        subDepID
          ? [
              p.categoryID.eq(id),
              p.pairedDepIDs.contains(subDepID),
              p.or((p) =>
                _status
                  ? [p.status.eq(_status)]
                  : [
                      p.status.eq(undefined),
                      p.status.eq('ACTIVE'),
                      p.status.eq('DRAFT'),
                    ]
              ),
            ]
          : [
              p.categoryID.eq(id),
              p.or((p) =>
                _status
                  ? [p.status.eq(_status)]
                  : [
                      p.status.eq(undefined),
                      p.status.eq('ACTIVE'),
                      p.status.eq('DRAFT'),
                    ]
              ),
            ]
      )
    );

    /* Create the ProtocolItem objects and add them to the category */
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolList.length; i++) {
      let protocol = new ProtocolItem(protocolList[i], cat);

      if (protocol.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (protocol.activeID != null)
          protocols = protocols.filter(
            (p: ProtocolItem) => p.uid !== protocol.activeID
          );
      } else if (protocol.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = protocols.find(
          (p: ProtocolItem) => p.activeID === protocol.uid
        );
        if (isAlreadyDraft) continue;
      }

      protocols.push(protocol);
    }
    cat.setProtocols(protocols);

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    return {
      type: ResponseType.Success,
      data: protocols,
    };
  } catch (error) {
    console.error('Error fetching protocols:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchMedications = async (depID: string): Promise<Response> => {
  try {
    const medicationList = await DataStore.query(Medication, (m) =>
      m.and((m) => [
        m.departmentID.eq(depID),
        m.or((m) => [
          m.status.eq(undefined),
          m.status.eq('ACTIVE'),
          m.status.eq('DRAFT'),
        ]),
      ])
    );

    let meds: MedicationItem[] = [];
    for (let i = 0; i < medicationList.length; i++) {
      let medication = new MedicationItem(medicationList[i]);

      if (medication.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (medication.activeID != null)
          meds = meds.filter(
            (m: MedicationItem) => m.uid !== medication.activeID
          );
      } else if (medication.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = meds.find(
          (m: MedicationItem) => m.activeID === medication.uid
        );
        if (isAlreadyDraft) continue;
      }

      meds.push(medication);
    }
    meds.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: meds,
    };
  } catch (error) {
    console.error('Error fetching medications:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchDrips = async (depID: string): Promise<Response> => {
  try {
    const dripList = await DataStore.query(Drip, (d) =>
      d.and((d) => [
        d.departmentID.eq(depID),
        d.or((d) => [
          d.status.eq(undefined),
          d.status.eq('ACTIVE'),
          d.status.eq('DRAFT'),
        ]),
      ])
    );

    let infus: MedicationItem[] = [];
    for (let i = 0; i < dripList.length; i++) {
      let infusion = new MedicationItem(dripList[i]);

      if (infusion.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (infusion.activeID != null) {
          let findIndex = infus.findIndex(
            (d: MedicationItem) => d.uid === infusion.activeID
          );
          if (findIndex !== -1) {
            let actv = infus[findIndex];
            infus.splice(findIndex, 1);
            // infusion.activeItem = actv;
          }
        }
        infus.push(infusion);
      } else if (infusion.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let findIndex = infus.findIndex(
          (d: MedicationItem) => d.activeID === infusion.uid
        );
        if (findIndex !== -1) {
          // infus[findIndex].activeItem = infusion;
          continue;
        } else infus.push(infusion);
      }
    }
    infus.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: infus,
    };
  } catch (error) {
    console.error('Error fetching infusions:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchEquipment = async (depID: string): Promise<Response> => {
  try {
    const equipmentList = await DataStore.query(Equipment, (m) =>
      m.and((m) => [
        m.departmentID.eq(depID),
        m.or((m) => [
          m.status.eq(undefined),
          m.status.eq('ACTIVE'),
          m.status.eq('DRAFT'),
        ]),
      ])
    );
    let equipment: EquipmentItem[] = [];
    for (let i = 0; i < equipmentList.length; i++) {
      let equip = new EquipmentItem(equipmentList[i]);

      if (equip.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (equip.activeID != null)
          equipment = equipment.filter(
            (d: EquipmentItem) => d.uid !== equip.activeID
          );
      } else if (equip.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = equipment.find(
          (e: EquipmentItem) => e.activeID === equip.uid
        );
        if (isAlreadyDraft) continue;
      }

      equipment.push(equip);
    }
    equipment.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: equipment,
    };
  } catch (error) {
    console.error('Error fetching equipment:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchElectrical = async (depID: string): Promise<Response> => {
  try {
    const electricalList = await DataStore.query(ElectricalShock, (d) =>
      d.and((d) => [
        d.departmentID.eq(depID),
        d.or((d) => [
          d.status.eq(undefined),
          d.status.eq('ACTIVE'),
          d.status.eq('DRAFT'),
        ]),
      ])
    );
    let electrical: ElectricalItem[] = [];
    for (let i = 0; i < electricalList.length; i++) {
      let elec = new ElectricalItem(electricalList[i]);

      if (elec.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (elec.activeID != null)
          electrical = electrical.filter(
            (d: ElectricalItem) => d.uid !== elec.activeID
          );
      } else if (elec.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = electrical.find(
          (e: ElectricalItem) => e.activeID === elec.uid
        );
        if (isAlreadyDraft) continue;
      }

      electrical.push(elec);
    }
    electrical.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: electrical,
    };
  } catch (error) {
    console.error('Error fetching electrical:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchChecklists = async (depID: string): Promise<Response> => {
  try {
    const checklistList = await DataStore.query(Form, (m) =>
      m.and((m) => [
        m.departmentID.eq(depID),
        m.or((m) => [
          m.status.eq(undefined),
          m.status.eq('ACTIVE'),
          m.status.eq('DRAFT'),
        ]),
      ])
    );
    let checklists: FormItem[] = [];
    for (let i = 0; i < checklistList.length; i++) {
      let checklist = new FormItem(checklistList[i]);

      if (checklist.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (checklist.activeID != null)
          checklists = checklists.filter(
            (d: FormItem) => d.uid !== checklist.activeID
          );
      } else if (checklist.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = checklists.find(
          (e: FormItem) => e.activeID === checklist.uid
        );
        if (isAlreadyDraft) continue;
      }

      checklists.push(checklist);
      checklists.sort((a, b) => a.getName().localeCompare(b.getName()));
    }
    return {
      type: ResponseType.Success,
      data: checklists,
    };
  } catch (error) {
    console.error('Error fetching checklists:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchVitals = async (depID: string): Promise<Response> => {
  try {
    const vitalsList = await DataStore.query(Vitals, (m) =>
      m.and((m) => [
        m.departmentID.eq(depID),
        m.or((m) => [
          m.status.eq(undefined),
          m.status.eq('ACTIVE'),
          m.status.eq('DRAFT'),
        ]),
      ])
    );
    let vitals: VitalItem[] = [];
    for (let i = 0; i < vitalsList.length; i++) {
      let vital = new VitalItem(vitalsList[i]);

      if (vital.status === 'DRAFT') {
        /* Take out the active version if there is one */
        if (vital.activeID != null)
          vitals = vitals.filter((d: VitalItem) => d.uid !== vital.activeID);
      } else if (vital.status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let isAlreadyDraft = vitals.find(
          (e: VitalItem) => e.activeID === vital.uid
        );
        if (isAlreadyDraft) continue;
      }

      vitals.push(vital);
    }
    vitals.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: vitals,
    };
  } catch (error) {
    console.error('Error fetching vitals:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchNotifications = async (depID: string): Promise<Response> => {
  try {
    const notificationsList = await DataStore.query(Notification, (m) =>
      m.departmentID.eq(depID)
    );
    let nots: NotificationItem[] = [];
    for (let i = 0; i < notificationsList.length; i++) {
      let n = new NotificationItem(notificationsList[i], []);
      nots.push(n);
    }
    nots.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
    return {
      type: ResponseType.Success,
      data: nots,
    };
  } catch (error) {
    console.error('Error fetching notifications:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchLogs = async (depID: string): Promise<Response> => {
  try {
    const logsList = await DataStore.query(Log, (m) =>
      m.departmentID.eq(depID)
    );
    return {
      type: ResponseType.Success,
      data: logsList,
    };
  } catch (error) {
    console.error('Error fetching logs:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchUserLogs = async (userID: string): Promise<Response> => {
  try {
    let logsList = await DataStore.query(
      Log,
      (c) => c.userIDs.contains(userID),
      { page: 0, limit: 200 }
    );
    return {
      type: ResponseType.Success,
      data: logsList,
    };
  } catch (error) {
    console.error('Error fetching logs:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const loadDatabase = async (
  department: DepartmentItem,
  dispatch?: Dispatch<any>
): Promise<Response> => {
  try {
    let data: DatabaseResponse = {
      department: department,
      categories: [],
      protocols: [],
      ambulances: [],
      users: [],
      oneWeights: [],
      medications: [],
      infusions: [],
      equipment: [],
      electrical: [],
      checklists: [],
      vitals: [],
      logs: [],
      notifications: [],
      contacts: [],
      weightObjects: [],
      keychains: [],
    };

    // Create an array of promises for the concurrent database queries
    const concurrentQueries = [
      fetchCategoriesWithProtocols(department.id),
      fetchAmbulances(department),
      fetchContacts(department),
      fetchKeychains(department),
      fetchWeightObjects(department),
      fetchUsers(department.id),
      fetchOneWeights(department.id),
      fetchMedications(department.id),
      fetchDrips(department.id),
      fetchEquipment(department.id),
      fetchElectrical(department.id),
      fetchChecklists(department.id),
      fetchVitals(department.id),
      fetchLogs(department.id),
      fetchNotifications(department.id),
    ];

    // Use Promise.all to execute queries concurrently and wait for all of them to complete
    const [
      catResp,
      ambResp,
      contactsResp,
      keychainResp,
      weightObjResp,
      usrResp,
      owResp,
      medResp,
      dripResp,
      equipResp,
      elecResp,
      checkResp,
      vitalResp,
      logResp,
      notsResp,
    ] = await Promise.all(concurrentQueries);

    // Check response of fetchCategoriesWithProtocols and proceed
    if (catResp.type === ResponseType.Success) {
      data.categories = catResp.data[0];
      data.protocols = catResp.data[1];
      if (globals.debug)
        console.log('Found ' + data.categories.length + ' categories');
      if (globals.debug)
        console.log('Found ' + data.protocols.length + ' protocols');
    } else throw new Error('Error fetching categories.');

    // Check response of fetchAmbulances and proceed
    if (ambResp.type === ResponseType.Success) {
      data.ambulances = ambResp.data;
      if (globals.debug)
        console.log('Found ' + data.ambulances.length + ' ambulances');
    } else throw new Error('Error fetching ambulances.');

    // Check response of fetchContacts and proceed
    if (contactsResp.type === ResponseType.Success) {
      data.contacts = contactsResp.data;
      if (globals.debug)
        console.log('Found ' + data.contacts.length + ' contacts');
    } else throw new Error('Error fetching contacts.');

    // Check response of fetchKeychains and proceed
    if (keychainResp.type === ResponseType.Success) {
      data.keychains = keychainResp.data;
      if (globals.debug)
        console.log('Found ' + data.keychains.length + ' keychains');
    } else throw new Error('Error fetching keychains.');

    // Check response of fetchWeightObjects and proceed
    if (weightObjResp.type === ResponseType.Success) {
      data.weightObjects = weightObjResp.data;
      if (globals.debug)
        console.log('Found ' + data.weightObjects.length + ' weight objects');
    } else throw new Error('Error fetching weight objects.');

    // Check response of fetchUsers and proceed
    if (usrResp.type === ResponseType.Success) {
      data.users = usrResp.data;
      if (globals.debug) console.log('Found ' + data.users.length + ' users');
    } else throw new Error('Error fetching users.');

    // Check response of fetch OneWeights and proceed
    if (owResp.type === ResponseType.Success) {
      data.oneWeights = owResp.data;
      if (globals.debug)
        console.log('Found ' + data.oneWeights.length + ' oneWeights');
    } else throw new Error('Error fetching ambulances.');

    // Check response of fetchMedications and proceed
    if (medResp.type === ResponseType.Success) {
      data.medications = medResp.data;
      if (globals.debug)
        console.log('Found ' + data.medications.length + ' medications');
    } else throw new Error('Error fetching medications.');

    // Check response of fetchDrips and proceed
    if (dripResp.type === ResponseType.Success) {
      data.infusions = dripResp.data;
      if (globals.debug)
        console.log('Found ' + data.infusions.length + ' infusions');
    } else throw new Error('Error fetching infusions.');

    // Check response of fetchEquipment and proceed
    if (equipResp.type === ResponseType.Success) {
      data.equipment = equipResp.data;
      if (globals.debug)
        console.log('Found ' + data.equipment.length + ' equipment');
    } else throw new Error('Error fetching equipment.');

    // Check response of fetchElectrical and proceed
    if (elecResp.type === ResponseType.Success) {
      data.electrical = elecResp.data;
      if (globals.debug)
        console.log('Found ' + data.electrical?.length + ' electrical');
    } else throw new Error('Error fetching electrical.');

    // Check response of fetchChecklists and proceed
    if (checkResp.type === ResponseType.Success) {
      data.checklists = checkResp.data;
      if (globals.debug)
        console.log('Found ' + data.checklists.length + ' checklists');
    } else throw new Error('Error fetching checklists.');

    // Check response of fetchVitals and proceed
    if (vitalResp.type === ResponseType.Success) {
      data.vitals = vitalResp.data;
      if (globals.debug) console.log('Found ' + data.vitals.length + ' vitals');
    } else throw new Error('Error fetching vitals.');

    // Check response of fetchLogs and proceed
    if (logResp.type === ResponseType.Success) {
      data.logs = logResp.data;
      if (globals.debug) console.log('Found ' + data.logs.length + ' logs');
    } else throw new Error('Error fetching logs.');

    // Check response of fetchNotifications and proceed
    if (notsResp.type === ResponseType.Success) {
      data.notifications = notsResp.data;
      if (globals.debug)
        console.log('Found ' + data.notifications.length + ' notifications');
    } else throw new Error('Error fetching notifications.', notsResp.data);

    mapItems(data);
    if (dispatch) dispatch(handleGetDepartment(data));
    return {
      type: ResponseType.Success,
      data: data,
    };
  } catch (error) {
    console.error('Error syncing to database:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Maps the items to the protocols
 * @param data The data to map the items to
 */
export function mapItems(data: DatabaseResponse) {
  data.protocols.forEach((protocol) => {
    mapMeds(protocol, data.medications);
    mapInfusions(protocol, data.infusions);
    mapEquip(protocol, data.equipment);
    mapElectrical(protocol, data.electrical);
    mapChecklists(protocol, data.checklists);
    mapProtocols(protocol, data.protocols);
    if (data.department.subDeps && data.department.subDeps.length > 1)
      mapProtocolDepartments(protocol, data.department.subDeps);
  });
  data.users.forEach((user: User) => {
    mapNotificationToUser(data.notifications, user);
    mapProtocolsToUser(data.protocols, user);
    mapCategoriesToUser(data.categories, user);
  });
  // data.notifications.forEach((notification) => {
  // 	mapUsersToNotification(notification, data.users);
  // });
}

/* ---------------------- MAPPIN MEDICATION FUNCTIONS ---------------------- */
function mapMeds(prot: ProtocolItem, meds: MedicationItem[]): void {
  let model: Protocol = prot.getModel();
  model.medicationIDs?.forEach((medID: string) => {
    meds.forEach((medication) => {
      let id =
        medication.status === 'DRAFT' && medication.activeID
          ? medication.activeID
          : medication.uid;
      if (id === medID) addMedicationItemsToProtocol(prot, medication, false);
    });
  });
}

function addMedicationItemsToProtocol(
  protocol: ProtocolItem,
  med: MedicationItem,
  isInfusion: boolean
): void {
  let protocolID =
    protocol.status === 'DRAFT' && protocol.activeID
      ? protocol.activeID
      : protocol.uid;
  let medicationProtocol: MedicationProtocol | null = getMedicationProtocol(
    med,
    protocolID,
    isInfusion
  );
  if (medicationProtocol != null && medicationProtocol.options != null) {
    medicationProtocol.options.forEach((range: MedicationRange) => {
      let newMedicationItem: MedicationSubItem = new MedicationSubItem(
        med,
        protocol,
        range
      );
      if (isInfusion) protocol.addInfusion(newMedicationItem);
      else protocol.addMedication(newMedicationItem);
      med.addMedicationSubItem(newMedicationItem);
    });
  }
}

function getMedicationProtocol(
  medication: MedicationItem,
  protocol_uid: string,
  isInfusion: boolean
): MedicationProtocol | null {
  let model: Medication | Drip | undefined = isInfusion
    ? medication.getModelInfusion()
    : medication.getModelMedication();
  if (!model) return null;

  let modelOptions = medication.getProtocolOptions();
  if (model && modelOptions) {
    for (let i = 0; i < modelOptions.length; i++) {
      let medProtocol = modelOptions[i];
      if (medProtocol?.protocolID === protocol_uid) return medProtocol;
    }
  }
  return null;
}

/* ---------------------- MAPPIN DRIP (INFUSIONS) FUNCTIONS ---------------------- */
function mapInfusions(prot: ProtocolItem, infusions: MedicationItem[]): void {
  let model: Protocol = prot.getModel();
  model.dripIDs?.forEach((dripID: string) => {
    infusions.forEach((infusion) => {
      let id =
        infusion.status === 'DRAFT' && infusion.activeID
          ? infusion.activeID
          : infusion.uid;
      if (id === dripID) addMedicationItemsToProtocol(prot, infusion, true);
      // prot.addInfusion(drip); //TODOD - add infusion to protocol
    });
  });
}

/* ---------------------- MAPPING EQUIPMENT FUNCTIONS ---------------------- */
function mapEquip(prot: ProtocolItem, equips: EquipmentItem[]): void {
  let model: Protocol = prot.getModel();
  model.equipmentIDs?.forEach((equipID: string) => {
    equips.forEach((equipment) => {
      if (equipment.getUid() === equipID) {
        prot.addEquipment(equipment);
        equipment.addParentProtocol(prot);
      }
    });
  });
}

/* ---------------------- MAPPING ELECTRICAL FUNCTIONS ---------------------- */
function mapElectrical(
  prot: ProtocolItem,
  electricals: ElectricalItem[]
): void {
  let model: Protocol = prot.getModel();
  model.electricalIDs?.forEach((electricalID: string) => {
    electricals.forEach((electrical) => {
      let id =
        electrical.status === 'DRAFT' && electrical.activeID
          ? electrical.activeID
          : electrical.uid;
      if (id === electricalID) {
        addElectricalItemsToProtocol(prot, electrical);
        // prot.addElectrical(electrical);
      }
    });
  });
}

function getElectricalOption(
  electrical: ElectricalItem,
  protocol_uid: string
): ElectricalShockOption | null {
  let modelOptions = electrical.model.options;
  if (modelOptions) {
    for (let i = 0; i < modelOptions.length; i++) {
      let elecOption = modelOptions[i];
      if (elecOption?.protocolID === protocol_uid) return elecOption;
    }
  }
  return null;
}

function addElectricalItemsToProtocol(
  protocol: ProtocolItem,
  electrical: ElectricalItem
): void {
  let protocolID =
    protocol.status === 'DRAFT' && protocol.activeID
      ? protocol.activeID
      : protocol.uid;
  let electricalOption: ElectricalShockOption | null = getElectricalOption(
    electrical,
    protocolID
  );
  if (electricalOption != null && electricalOption.ranges != null) {
    electricalOption.ranges.forEach((range: ElectricalShockRange) => {
      let newElectricalItem: ElectricalSubItem = new ElectricalSubItem(
        electrical,
        protocol,
        range
      );
      protocol.addElectrical(newElectricalItem);
      electrical.addSubElectrical(newElectricalItem);
    });
  }
}

/* ---------------------- MAPPING FORM FUNCTIONS ---------------------- */
function mapChecklists(prot: ProtocolItem, forms: FormItem[]): void {
  let model: Protocol = prot.getModel();
  model.formIDs?.forEach((formID: string) => {
    forms.forEach((form) => {
      let ID =
        form.status === 'DRAFT' && form.activeID ? form.activeID : form.uid;
      if (ID === formID) prot.addForm(form);
    });
  });
}

/* ---------------------- MAPPING PROTOCOL FUNCTIONS ---------------------- */
function mapProtocols(prot: ProtocolItem, protocols: ProtocolItem[]): void {
  let model: Protocol = prot.getModel();
  model.pairedProtocols?.forEach((protocolID: string | null) => {
    if (protocolID == null) return;
    protocols.forEach((protocol) => {
      let ID =
        protocol.status === 'DRAFT' && protocol.activeID
          ? protocol.activeID
          : protocol.uid;
      if (ID === protocolID) prot.addPairedProtocol(protocol);
    });
  });
}

function mapProtocolDepartments(
  prot: ProtocolItem,
  departments: DepartmentItem[]
): void {
  let model: Protocol = prot.getModel();
  model.pairedDepIDs?.forEach((depID: string) => {
    departments.forEach((department) => {
      if (department.id === depID) {
        prot.pairedDeps = [...prot.pairedDeps, department];
        prot.pairedDeps.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  });
}
function mapNotificationToDepartment(
  notification: NotificationItem,
  department: DepartmentItem
): void {
  let model: Notification = notification.dbNotification;
  let depID = model.departmentID;
  if (depID === department.id) {
    notification.pairedDeps = [...notification.pairedDeps, department];
    notification.pairedDeps.sort((a, b) => a.name.localeCompare(b.name));
  }
}

function mapCategoriesToUser(categories: CategoryItem[], users: User): void {
  for (let i = 0; i < categories.length; i++) {
    let model: Category = categories[i].model;
    let userID = model.modifiedBy ? model.modifiedBy : model.createdBy;
    if (userID === users.id) categories[i].setModifiedBy(users);
  }
}

function mapProtocolsToUser(protocols: ProtocolItem[], users: User): void {
  for (let i = 0; i < protocols.length; i++) {
    let model: Protocol = protocols[i].model;
    let userID = model.modifiedBy ? model.modifiedBy : model.createdBy;
    if (userID === users.id) protocols[i].setModifiedBy(users);
  }
}

/* ---------------------- MAPPING NOTIFICATION FUNCTIONS ---------------------- */
function mapNotificationToUser(
  notification: NotificationItem[],
  users: User
): void {
  for (let i = 0; i < notification.length; i++) {
    let model: Notification = notification[i].dbNotification;
    let userID = model.modifiedBy ? model.modifiedBy : model.createdBy;
    if (userID === users.id) notification[i].setModifiedBy(users);
  }
}

/* ---------------------- Fetch S3 PDF ---------------------- */
export const fetchPDF = async (
  fileURL?: string,
  protocol?: ProtocolItem,
  isDownload: boolean = false
): Promise<Response> => {
  try {
    if (fileURL == null && protocol == null)
      return {
        type: ResponseType.Failure,
        data: 'File URL and Protocol not found',
      };

    let url: string | null | undefined = fileURL ? fileURL : protocol?.pdfUrl;
    if (!url)
      return {
        type: ResponseType.Failure,
        data: 'File URL not found',
      };

    const result: any = await Storage.get(url, {
      level: 'public',
      download: isDownload,
    });

    if (!isDownload) {
      return {
        type: ResponseType.Success,
        data: result,
      };
    } else {
      return {
        type: ResponseType.Success,
        data: result.Body as Blob,
      };
    }
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Retrieves all active workbooks from the database.
 * @returns A Promise that resolves to a Response object.
 */
export const getAllWorkbooks = async (depID: string): Promise<Response> => {
  try {
    const activeWorkbooks = await DataStore.query(Workbook, (w) =>
      w.and((w) => [
        w.departmentID.eq(depID),
        w.or((w) => [w.status.eq(undefined), w.status.eq('ACTIVE')]),
      ])
    );
    let wbItems: WorkbookItem[] = [];
    for (let i = 0; i < activeWorkbooks.length; i++) {
      let model = activeWorkbooks[i];
      let userID = model.modifiedBy ? model.modifiedBy : model.createdBy;
      let modifiedBy = await DataStore.query(User, userID);
      if (modifiedBy) wbItems.push(new WorkbookItem(model, modifiedBy));
      else console.error('Error fetching user for workbook:', userID);
    }

    /* Sort the workbooks by their updated date, if it is null it was just uploaded */
    wbItems.sort((a, b) => {
      return a.name.localeCompare(b.name);
      // let aDateStr = a.model.updatedAt ? a.model.updatedAt : a.model.createdAt;
      // let aDate = aDateStr ? new Date(aDateStr) : new Date();

      // let bDateStr = b.model.updatedAt ? b.model.updatedAt : b.model.createdAt;
      // let bDate = bDateStr ? new Date(bDateStr) : new Date();

      // return bDate.getTime() - aDate.getTime();
    });
    return {
      type: ResponseType.Success,
      data: wbItems,
    };
  } catch (e) {
    console.error('Error fetching active workbooks:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

async function fetchRecordsForMonthAndYear(
  model: any,
  monthAndYear: Date,
  departmentIDs: string[],
  day: any
) {
  const year = monthAndYear.getFullYear();
  const month = monthAndYear.getMonth();
  let startDate = new Date(Date.UTC(year, month, 1));
  let endDate: any;
  if (day) {
    endDate = new Date(Date.UTC(year, month, day, 23, 59, 59));
  } else {
    endDate = new Date(Date.UTC(year, month + 1, 0, 23, 59, 59));
  }

  try {
    const records = await DataStore.query(model);
    const filteredRecords = records.filter((record) => {
      const isStatusMatch =
        record.status === 'ACTIVE' || record.status === 'ARCHIVE';
      const isWithinDateRange =
        (record.createdAt >= startDate.toISOString() &&
          record.createdAt <= endDate.toISOString()) ||
        (record.updatedAt >= startDate.toISOString() &&
          record.updatedAt <= endDate.toISOString());

      let isDepartmentMatch = false;

      if (model === Protocol) {
        isDepartmentMatch = record.pairedDepIDs
          ? departmentIDs.some((id) => record.pairedDepIDs.includes(id))
          : false;
      } else {
        isDepartmentMatch = departmentIDs.includes(record.departmentID);
      }

      return isStatusMatch && isDepartmentMatch && isWithinDateRange;
    });

    const groupedByName = filteredRecords.reduce((acc: any, record: any) => {
      const key = record.model === 'Vitals' ? record.title : record.name;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(record);
      return acc;
    }, {});

    const matchedRecords = Object.values(groupedByName)
      .filter((group: any) => {
        const statuses = group.map((record: any) => record.status);
        return statuses.includes('ACTIVE') && statuses.includes('ARCHIVE');
      })
      .flat();

    return matchedRecords;
  } catch (error) {
    console.error(`Error fetching records for ${model.name}:`, error);
    return [];
  }
}

export async function fetchAllRecordsByMonthYear(
  monthAndYear: Date,
  departmentIDs: any,
  day?: any
) {
  const models = [
    Protocol,
    Medication,
    Equipment,
    Electrical,
    Drip,
    Form,
    Vitals,
  ];
  const results: any = {};

  for (const model of models) {
    const data = await fetchRecordsForMonthAndYear(
      model,
      monthAndYear,
      departmentIDs,
      day
    );
    results[model.name] = data;
    if (globals.debug)
      console.log(`Fetched ${data.length} records for ${model.name}`);
  }
  if (globals.debug) console.log('results', results);
  return results;
}
