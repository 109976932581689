import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { IoChevronForward, IoMenu } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as _ from 'lodash';
import { ProgressStatus, User } from '../../../../models';
import MedicationSubItem, {
  getRoutesString,
} from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import FormItem from '../../../../data/model/FormItem';
import { UserType } from '../../../../models';
import { useSelector } from 'react-redux';
import Status from '../../../components/ProgressStatus/ProgressStatus';

const reorder = (
  list: (
    | MedicationSubItem
    | ElectricalSubItem
    | EquipmentItem
    | FormItem
    | ProtocolItem
  )[],
  startIndex: number,
  endIndex: number
): (
  | MedicationSubItem
  | ElectricalSubItem
  | EquipmentItem
  | FormItem
  | ProtocolItem
)[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface AccordionProps {
  type:
    | 'Medication'
    | 'Infusion'
    | 'Equipment'
    | 'Electrical'
    | 'Checklist'
    | 'Paired Protocol';
  onItemClick?: (
    item:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  onReorder: (
    items: (
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
    )[],
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  accordionType?: any;
  isEditMode: boolean;
  protocol: ProtocolItem;
  data: (
    | MedicationSubItem
    | ElectricalSubItem
    | EquipmentItem
    | FormItem
    | ProtocolItem
  )[];
}

/* 10-02-23 Arul: Created ProtocolAccordionSecondLayer component  for calculation page*/
const ProtocolAccordionSecondLayer: React.FC<AccordionProps> = ({
  type,
  onItemClick,
  accordionType,
  isEditMode,
  protocol,
  data,
  onReorder,
}) => {
  const user: User = useSelector((state: any) => state.user);
  const [items, setItems] =
    useState<
      (
        | MedicationSubItem
        | ElectricalSubItem
        | EquipmentItem
        | FormItem
        | ProtocolItem
      )[]
    >(data);
  const navigate = useNavigate();
  const isDragDisabled =
    type === 'Checklist' || type === 'Paired Protocol' || type === 'Equipment';
  // function for dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(updatedItems);
    onReorder(updatedItems, type);
  };

  useEffect(() => {
    if (data[0] instanceof ElectricalSubItem) {
      setItems(
        (data as ElectricalSubItem[]).sort(
          (a: ElectricalSubItem, b: ElectricalSubItem) => {
            if (a.index === b.index) return a.name.localeCompare(b.name);
            return a.index - b.index;
          }
        )
      );
    }
  }, [data]);

  // navigate to protocol edit page
  const handleNavigate = (
    parent:
      | MedicationItem
      | ElectricalItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    child:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
  ) => {
    if (
      user &&
      user.type !== UserType.ADMIN &&
      user.type !== UserType.DEPT_ADMIN
    )
      return;
    if (accordionType === 'Paired Protocol') {
      onItemClick && onItemClick(child, accordionType);
    } else {
      const state = {
        selectedProtocol: protocol,
        value: parent,
        subValue: child,
        type: accordionType,
        editType: 'edit',
        editMode: isEditMode,
        page: 'protocolDetailPage',
      };
      navigate(`/protocol/edit/edit-protocol`, { state });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="listItem"
          >
            <ViewportList items={items}>
              {(
                item:
                  | MedicationSubItem
                  | ElectricalSubItem
                  | EquipmentItem
                  | FormItem
                  | ProtocolItem,
                index: number
              ) => {
                let parent =
                  item.TAG === 'MedicationSubItem'
                    ? (item as MedicationSubItem).parentMedication
                    : item.TAG === 'ElectricalSubItem'
                      ? (item as ElectricalSubItem).parentElectrical
                      : item.TAG === 'FormItem'
                        ? (item as FormItem)
                        : (item as EquipmentItem);
                return isDragDisabled ? (
                  <div
                    key={index}
                    className={`cursorPointer listContainer ${
                      index % 2 === 0 ? 'listBackground' : ''
                    }`}
                  >
                    <li
                      onClick={() => handleNavigate(parent, item)}
                      className="listContent"
                    >
                      <div className="a1SubITems">
                        <div style={{ display: 'flex' }}>
                          <span className={'a1Content'}>
                            {item.name}
                            {item.status !== 'ACTIVE' && (
                              <Status status={item.status} />
                            )}
                          </span>
                        </div>
                        {type === 'Paired Protocol' && (
                          <div
                            className="a1SubItemsContent"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <div>
                              <IoChevronForward
                                size="1.5rem"
                                style={{ marginRight: '10px' }}
                              />
                            </div>
                          </div>
                        )}
                        {type === 'Equipment' && (
                          <div
                            className="a1SubItemsContent"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <div className="a1type">
                              {(item as EquipmentItem).options?.length} Sizes
                              <span>
                                <IoChevronForward
                                  size="1.5rem"
                                  style={{ marginLeft: '10px' }}
                                />
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  </div>
                ) : (
                  <Draggable
                    key={item.name + '-' + index}
                    draggableId={item.name + '-' + index}
                    isDragDisabled={!isEditMode}
                    index={index}
                  >
                    {(provided: any) => (
                      <div
                        className={`cursorPointer listContainer ${
                          index % 2 === 0 ? 'listBackground' : ''
                        }`}
                      >
                        <li
                          key={index}
                          onClick={() => handleNavigate(parent, item)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="listContent"
                        >
                          <div className="a1SubITems">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 1,
                              }}
                            >
                              {isEditMode && (
                                <div {...provided.dragHandleProps}>
                                  <IoMenu
                                    size="1.5rem"
                                    style={{ marginRight: '8px' }}
                                  />{' '}
                                </div>
                              )}
                              <h6 className="departmentItemText">
                                {item.name}
                                {item.status !== 'ACTIVE' && (
                                  <Status status={item.status} />
                                )}
                              </h6>
                            </div>
                            {type === 'Medication' && (
                              <div className="a1SubItemsContent">
                                <div className="a1type">
                                  {(item as MedicationSubItem).range.basis}
                                </div>
                                <div className="a1type">
                                  {getRoutesString(item as MedicationSubItem)}
                                  <span>
                                    <IoChevronForward
                                      size="1.5rem"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                            {type === 'Infusion' && (
                              <div className="a1SubItemsContent">
                                <div className="a1type">
                                  {(item as MedicationSubItem).range.basis}
                                </div>
                                <div className="a1type">
                                  {getRoutesString(item as MedicationSubItem)}
                                  <span>
                                    <IoChevronForward
                                      size="1.5rem"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}

                            {type === 'Electrical' && (
                              <div
                                className="a1SubItemsContent"
                                style={{ justifyContent: 'flex-end' }}
                              >
                                <div className="a1type">
                                  {(item as ElectricalSubItem).range.basis}
                                  <span>
                                    <IoChevronForward
                                      size="1.5rem"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      </div>
                    )}
                  </Draggable>
                );
              }}
            </ViewportList>{' '}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProtocolAccordionSecondLayer;
