import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import ChecklistSideout from '../../../../components/SideOut/doseSideOut/ChecklistSideout';
import ProtocolHeader from '../../ProtocolHeader';
import {
  DatabaseResponse,
  ResponseType,
  Response,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import CheckListGroups from './CheckListGroups';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import { FormGroup, User } from '../../../../../models';
import {
  getFormattedDate,
  globals,
  isObjectEqual,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  CheckListDB,
  createCheckList,
  deleteCheckList,
} from '../../../../../data/functions/CheckListDB';
import { ProgressStatus } from '../../../../../API';
import FormItem from '../../../../../data/model/FormItem';
import { UserType } from '../../../../../models';

interface CheckListEditPageProps {
  stateData?: any;
  isEditPage?: any;
  page?: any;
  isFullPage?: boolean;
}

/* 10-17-23 Arul: Created checklist Protocol Edit Page component globally */
const CheckListEditPage: React.FC<CheckListEditPageProps> = ({
  stateData,
  isEditPage,
  isFullPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(
    stateData.editMode == null ? isEditPage : stateData.editMode
  );
  const [parmChecklist, setParmChecklist] = useState<FormItem>(stateData.value);
  const [editDose, setEditDose] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [doseIndex, setDoseIndex] = useState<number>(1);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const department = database.department;
  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  /* 11-17-23 Arul: Function to handle Cancel button */
  const handleCancel = () => {
    if (formik.dirty && !isCancelWarningModal) {
      setIsCancelWarningModal(true);
    } else {
      formik.resetForm();
      setIsEditMode(false);
      setIsCancelWarningModal(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmChecklist ? parmChecklist.name : '',
      items: parmChecklist ? parmChecklist.items : [],
      departmentID: parmChecklist ? parmChecklist.depID : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      items: Yup.array(),
      departmentID: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (formik.dirty && formik.isValid) {
        const newCheckList: CheckListDB = {
          name: values.name,
          items: values.items,
          departmentID: department.id,
          status: ProgressStatus.DRAFT,
          activeID:
            parmChecklist.status === ProgressStatus.ACTIVE
              ? parmChecklist.uid
              : parmChecklist.activeID,
          version:
            parmChecklist.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmChecklist.version === null
                    ? 'v1.0.0'
                    : parmChecklist.version
                )
              : parmChecklist.version === null
                ? 'v1.0.0'
                : parmChecklist.version,
          modifiedBy: user.id,
          createdBy:
            parmChecklist.dbForm && parmChecklist.dbForm.createdBy
              ? parmChecklist.dbForm.createdBy
              : '',
        };
        if (globals.debug) console.log('Creating checklist', parmChecklist);
        let results: Response = await createCheckList(
          newCheckList,
          parmChecklist
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created checklist', results.data);
          let newCheckList: FormItem = results.data;
          updateChecklist(newCheckList);
        }
        formik.resetForm();
      }
    },
  });

  const updateChecklist = async (newChecklist: FormItem) => {
    reloadDatabase();
    formik.setValues({
      ...formik.values,
      name: newChecklist?.name ? newChecklist?.name : '',
      items: newChecklist?.items ? newChecklist?.items : [],
    });
  };

  const reloadDatabase = async (): Promise<boolean> => {
    const resp: Response = await loadDatabase(database.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));

      if (globals.debug) console.log('Searching for updated checklist item');
      let updatedChecklist: FormItem | null = null;
      let checklistID =
        parmChecklist.status === ProgressStatus.ACTIVE
          ? parmChecklist.uid
          : parmChecklist.activeID;
      for (let i = 0; i < newDB.checklists.length; i++) {
        if (newDB.checklists[i].activeID === checklistID) {
          updatedChecklist = newDB.checklists[i];
          break;
        }
      }
      if (updatedChecklist) {
        setParmChecklist(updatedChecklist);
      } else if (globals.debug) console.log('Failed to find updated checklist');
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleBack = () => {
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-checklists`, {
        state: {
          department: department,
          data: database.checklists,
          database: database,
        },
      });
    }
  };

  // create new group and open sideout modal
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedData(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  //close sideout modal
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
  };

  // set edit protocol
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database.department);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleUpdateCheckList = (data: any, prevGroup?: FormGroup) => {
    let newCheckList: FormGroup = {
      title: data.title,
      options: data.items,
      isUrgent: data.isUrgent,
      isDouble: data.isDouble,
    };
    if (!prevGroup || formik.values.items.length === 0) {
      let newOptions = [...formik.values.items, newCheckList];
      formik.setFieldValue('items', newOptions);
    } else if (prevGroup) {
      let updatedItems: FormGroup[] = formik.values.items.filter(
        (item: any) => !isObjectEqual(prevGroup, item)
      );
      updatedItems.push(newCheckList);
      formik.setFieldValue('items', updatedItems);
    } else {
      console.error(
        'Unexpected case: prevGroup is not provided and also items is not empty.'
      );
    }
  };

  const handleCheckListGroupRemove = (dose: FormGroup) => {
    const updateItems = formik.values.items.filter(
      (item: any) => !isObjectEqual(dose, item)
    );
    // Set the filtered items back to the formik state
    formik.setFieldValue('items', updateItems);
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  // const handleVersion = () => {};

  const handleViewGroup = (item: FormGroup, index: number) => {
    if (isEditMode) {
      setSidebarVisible(true);
      setDoseIndex(index);
      setSelectedData(item);
      setEditDose(true);
    }
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft =
      parmChecklist.status === ProgressStatus.DRAFT &&
      parmChecklist.activeID == null;
    let response = await deleteCheckList(parmChecklist, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete checklist', response.data);
    }
  };

  return (
    <div className="screen-container">
      {sidebarVisible && (
        <ChecklistSideout
          type="Checklist"
          data={selectedData ? selectedData : undefined}
          visible={sidebarVisible}
          setVisible={handleCloseModal}
          editDose={editDose}
          doseIndex={doseIndex}
          onSubmit={handleUpdateCheckList}
          onRemove={handleCheckListGroupRemove}
        />
      )}
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Checklist.  Click cancel to return to Checklist details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          department={database.department}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsEditMode(true);
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Checklist?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you would like to delete ${parmChecklist.name}?`}
        />
      )}

      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      {isCancelWarningModal && (
        <ConfirmModal
          isVisible={isCancelWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsCancelWarningModal(false);
          }}
          handleSubmit={handleCancel}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this checklist.  Click cancel to return to checklist details.  To cancel without saving changes, select Abandon.`}
        />
      )}

      <ProtocolHeader
        name={parmChecklist.name}
        page={protocol ? protocol.name : 'Checklists'}
        status={parmChecklist.status}
        protocolDetail={protocol}
        type={'protocol'}
        rightSideBtn={isEditMode ? 'save' : 'edit'}
        isSaveActive={formik.dirty && formik.isValid}
        // isVersionButton={true}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmChecklist.uid : ''}
        // isVersionButton={true}
        isDeleteButton={isEditMode}
        isEditButton={!isEditMode}
        isDotButton={true}
        isSaveButton={isEditMode}
        isBackButton={true}
        handleCancel={handleBack}
        handleCancelEdit={handleCancel}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft && !isEditMode) setIsEditMode(true);
          });
        }}
        handleDelete={handleDelete}
        handleSave={() => formik.submitForm()}
        // handleVersion={handleVersion}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header">
            Checklist
          </h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {parmChecklist.modifiedBy
                  ? parmChecklist.modifiedBy?.firstName +
                    ' ' +
                    parmChecklist.modifiedBy?.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmChecklist.dbForm?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(parmChecklist.dbForm.updatedAt, true)}
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmChecklist.version}</div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name<span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header-right">
            Checklist Groups
          </h5>
          {isEditMode && (
            <div style={{ marginTop: '5px' }}>
              <span className="ketamine-general-label">Option</span>
              <div
                onClick={onAddDoseClick}
                className={`contentBorder protocolCalculationPad contentHeading newProtocolBorder  ${
                  isEditMode && 'cursorPointer newRouteButton'
                }`}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  style={{
                    textDecoration: 'underLine',
                    textUnderlinePosition: 'under',
                  }}
                >
                  <HiPlus className="text-icon " /> Add Group
                </span>
              </div>
            </div>
          )}

          <ViewportList items={formik.values.items}>
            {(item: any, index: any) => (
              <div
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleViewGroup(item, index);
                }}
              >
                <CheckListGroups data={item} groupIndex={index} />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default CheckListEditPage;
