import React, { useEffect } from 'react';
import './App.scss';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  handleGetDepartment,
  handleUpdateCognitoUser,
  handleUpdateUser,
} from './store/actions';
import {
  Response,
  ResponseType,
  getUserInformation,
  getUserInformationAPI,
  loadDatabase,
} from './data/AmplifyDB';
import SplashScreen from './ui/pages/SplashScreen';
import { globals } from './ui/_global/common/Utils';
import { User } from './models';
import { UserStatus } from './models';
import ConfirmModal from './ui/components/Modal/ConfirmModal';
import DepartmentItem from './data/model/DepartmentItem';
import AuthRoute, { checkPublicURL } from './AuthRoute';

const App = () => {
  const content = useRoutes(AppRoute);
  const location = useLocation();
  const dispatch = useDispatch();
  const cognitoUser = useSelector((state: any) => state.cognitoUser);
  const user = useSelector((state: any) => state.user);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const [checkedAuth, setCheckedAuth] = React.useState(
    checkPublicURL(location.pathname)
  );

  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);

  // const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabledUser, setIsDisabledUser] = React.useState<User>();

  useEffect(() => {
    if (!checkedAuth) checkAuthState();
  }, [checkedAuth]);

  useEffect(() => {
    setLoggedIn(isLoggedIn);
  }, [isLoggedIn]);

  const checkAuthState = async (): Promise<any> => {
    try {
      if (globals.debug) console.log('Checking Auth Stateee');
      const _user = await Auth.currentAuthenticatedUser();
      setLoggedIn(true);
      setIsLoading(true);
      dispatch<any>(handleUpdateCognitoUser(_user));
    } catch (err) {
      if (globals.debug) console.log('ERROR', err);
      setLoggedIn(false);
      setIsLoading(false);
      setCheckedAuth(true);
    }
  };

  const checkRefresh = async () => {
    try {
      if (globals.debug) console.log('Checking refresh');
      let promises = [
        Auth.currentSession(),
        setTimeout(() => {
          return new Promise((resolve, reject) => resolve('timeout'));
        }, 1000),
      ];
      let result: any = await Promise.race(promises);
      if (result === 'timeout') {
        if (globals.debug) console.log('Timeout');
        setLoggedIn(false);
        setIsLoading(false);
        return;
      } else {
        const user = await Auth.currentAuthenticatedUser();
        setLoggedIn(true);
        setIsLoading(true);
        dispatch<any>(handleUpdateCognitoUser(user));
      }
    } catch (err) {
      setLoggedIn(false);
      setIsLoading(false);
    }
  };

  const fetchUserInformation = async (user: any) => {
    if (globals.debug) console.log('FETCHING USER INFO');
    if (checkPublicURL(location.pathname)) return;
    /* Get the user session and decode the token to get the user type */
    let attempt = 0;
    const maxAttempts = 3;

    const attemptFetch = async () => {
      let time = new Date().getTime();
      /* Fetch the user information from the backend wait for 10 seconds or logout the user */
      let userInfoPromise = getUserInformation(
        user.attributes.sub,
        user.username
      );

      const result = await Promise.race([
        userInfoPromise,
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Timeout')), 5000)
        ),
      ]).catch(async (error) => {
        if (error.message === 'Timeout') {
          if (attempt >= maxAttempts) {
            if (globals.debug)
              console.log('Failed to find user... Logging out...');
            try {
              // await DataStore.clear();
              await Auth.signOut();
              navigate(`/login`);
              if (globals.debug)
                console.log('Failed to find user... Logging out...');
              // attemptFetch();
            } catch (clearError) {
              console.error('Failed to clear DataStore:', clearError);
              navigate(`/login`);
              Auth.signOut();
            }
          }
          if (globals.debug)
            console.log(`Attempt ${attempt + 1} due to timeout, retrying...`);
          attempt++;
          setTimeout(attemptFetch, 2000 * attempt);
        } else {
          console.error('Error fetching user information:', error);
        }
        return null;
      });

      if (result && result !== 'Timeout') {
        if (globals.debug)
          console.log(
            'Time to fetch user info:',
            new Date().getTime() - time + 'ms',
            result
          );
        let response: Response = result as Response;
        if (response.type === ResponseType.Success) {
          if (response.data[0] == null || response.data[1] == null) {
            window.confirm(
              'User is not authorized to use this application - Please contact a HM representative: No Department Assigned'
            );
            await Auth.signOut();
            return;
          }

          document.title = response.data[1].name + ' | OneDose';

          if (
            (response.data[0] as User).status === UserStatus.SUSPENDED ||
            (response.data[0] as User).status === UserStatus.DELETED
          ) {
            Auth.signOut();
            navigate(`/login`);
            setIsDisabledUser(response.data[0] as User);
          } else {
            document.title = `${(response.data[1] as DepartmentItem).name} | OneDose`;
            const dbResp = await loadDatabase(response.data[1]);
            if (dbResp.type === ResponseType.Success) {
              dispatch<any>(handleUpdateUser(response.data[0]));
              dispatch<any>(handleGetDepartment(dbResp.data));
              if (globals.debug)
                console.log('User Information: ', response.data[0]);
              if (location.pathname === '/login' || location.pathname === '/')
                navigate(`/protocol`, { state: dbResp.data });
            } else {
              if (globals.debug) console.log(dbResp.data);
            }
          }
        } else {
          if (globals.debug) console.log(response.data);
        }
        setCheckedAuth(true);
      }
    };
    attemptFetch();
  };

  // update user department details after Login
  useEffect(() => {
    if (cognitoUser) {
      setLoggedIn(true);
      if (globals.debug)
        if (globals.debug) console.log('LOGGED IN - FETCHING USER INFO');
      fetchUserInformation(cognitoUser);
      setIsLoading(false);
    }
  }, [cognitoUser]);

  return (
    <>
      {checkedAuth ? (
        <div className="App">
          {isDisabledUser && (
            <ConfirmModal
              isVisible={isDisabledUser != null}
              title={'User has been disabled'}
              handleClose={() => {
                setIsDisabledUser(undefined);
              }}
              handleSubmit={() => {
                setIsDisabledUser(undefined);
              }}
              isSingleBtn={true}
              secondaryBtnName="Okay"
              primaryDescription={
                'The user ' +
                isDisabledUser?.firstName +
                ' ' +
                isDisabledUser?.lastName +
                ' is currently disabled.'
              }
            />
          )}
          <AuthRoute content={content} />
          {/* <LandingPage /> */}
          {/* {isLoading && <Loading type="bubbles" />} */}
        </div>
      ) : (
        <SplashScreen />
        // <DashboardLayout pageTitle="" component={<Loading type="bubbles" />} />
      )}
    </>
  );
};

export default App;
