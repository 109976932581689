export const HANDLE_LOGIN = 'HANDLE_LOGIN';
export const HANDLE_LOGOUT = 'HANDLE_LOGOUT';
export const HANDLE_UPDATE_USER_DATA = 'HANDLE_UPDATE_USER_DATA';
export const HANDLE_UPDATE_COGNITO_USER_DATA =
  'HANDLE_UPDATE_COGNITO_USER_DATA';
export const HANDLE_SHOW_SIDEBAR = 'HANDLE_SHOW_SIDEBAR';
export const HANDLE_GET_DEPARTMENT_LIST = 'HANDLE_GET_DEPARTMENT_LIST';
export const HANDLE_UPDATE_EDIT_PROTOCOL = 'HANDLE_UPDATE_EDIT_PROTOCOL';
export const HANDLE_UPDATE_EDIT_FOLDER_DATA = 'HANDLE_UPDATE_EDIT_FOLDER_DATA';
export const HANDLE_SELECT_DATATABLE = 'HANDLE_SELECT_DATATABLE';
export const HANDLE_DATATABLE_ROW_SELECTION = 'HANDLE_DATATABLE_ROW_SELECTION';
export const HANDLE_UPDATE_EQUIPMENT_PROTOCOL_VALUES =
  'HANDLE_UPDATE_EQUIPMENT_PROTOCOL_VALUES';
export const HANDLE_UPDATE_EQUIPMENT_OPTION_VALUES =
  'HANDLE_UPDATE_EQUIPMENT_OPTION_VALUES';
export const HANDLE_SET_PROTOCOL_DATA = 'HANDLE_SET_PROTOCOL_DATA';
export const HANDLE_SELECTED_DOSE_VALUE = 'HANDLE_SELECTED_DOSE_VALUE';
export const HANDLE_UPDATE_ROUTES_VALUES_LIST =
  'HANDLE_UPDATE_ROUTES_VALUES_LIST';
export const HANDLE_UPDATE_SELECTED_ACCORDION =
  'HANDLE_UPDATE_SELECTED_ACCORDION';
export const HANDLE_GET_NOTIFICATION = 'HANDLE_GET_NOTIFICATION';
export const HANDLE_SET_EDIT_MODE = 'HANDLE_SET_EDIT_MODE';

export const HANDLE_DEP_CACHE = 'HANDLE_DEP_CACHE';
export const HANDLE_HM_CACHE = 'HANDLE_HM_CACHE';
export const HANDLE_REVIEWALS_CACHE = 'HANDLE_REVIEWALS_CACHE';

export const UPDATE_CATEGORIES_DATA = 'UPDATE_CATEGORIES_DATA';

export const HANDLE_PUBLIC_SELECTED_ITEM = 'HANDLE_PUBLIC_SELECTED_ITEM';
