import { Response, ResponseType } from './AmplifyDB';
import DepartmentItem from './model/DepartmentItem';
import {
  getCategoryDrafts,
  isCategryDraftCreated,
  publishCategoryDrafts,
  removeCurrentCategoryDrafts,
} from './functions/CategoryDB';
import {
  getProtocolDrafts,
  isProtocolDraftCreated,
  publishProtocolDrafts,
  removeCurrentProtocolDrafts,
} from './functions/ProtocolDB';
import {
  getEquipmentDrafts,
  isEquipmentDraftCreated,
  publishEquipmentDrafts,
  removeCurrentEquipmentDrafts,
} from './functions/EquipmentDB';
import {
  getElectricalDrafts,
  isElectricalDraftCreated,
  publishElectricalDrafts,
  removeCurrentElectricalDrafts,
} from './functions/ElectricalDB';
import {
  getMedicationDrafts,
  isMedicationDraftCreated,
  publishMedicationDrafts,
  removeCurrentMedicationDrafts,
} from './functions/MedicationDB';
import {
  getVitalDrafts,
  isVitalDraftCreated,
  publishVitalDrafts,
  removeCurrentVitalDrafts,
} from './functions/VitalDB';
import {
  getInfusionDrafts,
  publishInfusion,
  publishInfusionDrafts,
  removeCurrentInfusionDrafts,
} from './functions/InfusionDB';
import {
  getCheckListDrafts,
  isCheckListDraftCreated,
  publishCheckListDrafts,
  removeCurrentCheckListDrafts,
} from './functions/CheckListDB';
import { globals } from '../ui/_global/common/Utils';

/* ------------------ CHECKING DRAFT ------------------ */
export const isDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const concurrentQueries = [
      isCategryDraftCreated(department),
      isProtocolDraftCreated(department),
      isMedicationDraftCreated(department),
      isEquipmentDraftCreated(department),
      isElectricalDraftCreated(department),
      isVitalDraftCreated(department),
      isCheckListDraftCreated(department),
    ];

    const [
      catResp,
      protResp,
      medResp,
      equipResp,
      elecResp,
      vitalResp,
      checkResp,
    ] = await Promise.all(concurrentQueries);

    if (catResp.type === ResponseType.Success && catResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    if (protResp.type === ResponseType.Success && protResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    if (medResp.type === ResponseType.Success && medResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    if (equipResp.type === ResponseType.Success && equipResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    if (elecResp.type === ResponseType.Success && elecResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    if (vitalResp.type === ResponseType.Success && vitalResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    if (checkResp.type === ResponseType.Success && checkResp.data > 0) {
      return {
        type: ResponseType.Success,
        data: true,
      };
    }

    /** TODO Check for all other modals */

    return {
      type: ResponseType.Success,
      data: false,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* ------------------ DRAFT UPDATES ------------------ */

export const getDraftUpdates = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];

    const concurrentQueries = [
      getCategoryDrafts(department),
      getProtocolDrafts(department),
      getMedicationDrafts(department),
      getInfusionDrafts(department),
      getEquipmentDrafts(department),
      getElectricalDrafts(department),
      getVitalDrafts(department),
      getCheckListDrafts(department),
    ];

    const [
      catResp,
      protResp,
      medResp,
      infusResp,
      equipResp,
      elecResp,
      vitalResp,
      checkResp,
    ] = await Promise.all(concurrentQueries);

    if (catResp.type === ResponseType.Success) updates.push(...catResp.data);
    else {
      if (globals.debug)
        console.log('Error getting category drafts', catResp.data);
      return {
        type: ResponseType.Failure,
        data: catResp.data,
      };
    }

    if (protResp.type === ResponseType.Success) updates.push(...protResp.data);
    else {
      if (globals.debug)
        console.log('Error getting protocol drafts', protResp.data);
      return {
        type: ResponseType.Failure,
        data: protResp.data,
      };
    }

    if (medResp.type === ResponseType.Success) updates.push(...medResp.data);
    else {
      if (globals.debug)
        console.log('Error getting medication drafts', medResp.data);
      return {
        type: ResponseType.Failure,
        data: medResp.data,
      };
    }

    if (infusResp.type === ResponseType.Success)
      updates.push(...infusResp.data);
    else {
      if (globals.debug)
        console.log('Error getting infusion drafts', infusResp.data);
      return {
        type: ResponseType.Failure,
        data: infusResp.data,
      };
    }

    if (equipResp.type === ResponseType.Success)
      updates.push(...equipResp.data);
    else {
      if (globals.debug)
        console.log('Error getting equipment drafts', equipResp.data);
      return {
        type: ResponseType.Failure,
        data: equipResp.data,
      };
    }

    if (elecResp.type === ResponseType.Success) updates.push(...elecResp.data);
    else {
      if (globals.debug)
        console.log('Error getting electrical drafts', elecResp.data);
      return {
        type: ResponseType.Failure,
        data: elecResp.data,
      };
    }

    if (vitalResp.type === ResponseType.Success)
      updates.push(...vitalResp.data);
    else {
      if (globals.debug)
        console.log('Error getting vital drafts', vitalResp.data);
      return {
        type: ResponseType.Failure,
        data: vitalResp.data,
      };
    }

    if (checkResp.type === ResponseType.Success)
      updates.push(...checkResp.data);
    else {
      if (globals.debug)
        console.log('Error getting checklist drafts', checkResp.data);
      return {
        type: ResponseType.Failure,
        data: checkResp.data,
      };
    }

    /* TODO all other models */

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* ------------------ PUBLISH DRAFT UPDATES ------------------ */

export const publishDraftUpdates = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];

    const concurrentQueries = [
      publishCategoryDrafts(department),
      publishProtocolDrafts(department),
      publishMedicationDrafts(department),
      publishInfusionDrafts(department),
      publishEquipmentDrafts(department),
      publishElectricalDrafts(department),
      publishVitalDrafts(department),
      publishCheckListDrafts(department),
    ];

    const [
      catResp,
      protResp,
      medResp,
      infusResp,
      equipResp,
      elecResp,
      vitalResp,
      checkResp,
    ] = await Promise.all(concurrentQueries);

    if (catResp.type === ResponseType.Success) updates.push(...catResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing category drafts', catResp.data);
      return {
        type: ResponseType.Failure,
        data: catResp.data,
      };
    }

    if (protResp.type === ResponseType.Success) updates.push(...protResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing protocol drafts', protResp.data);
      return {
        type: ResponseType.Failure,
        data: protResp.data,
      };
    }

    if (medResp.type === ResponseType.Success) updates.push(...medResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing medication drafts', medResp.data);
      return {
        type: ResponseType.Failure,
        data: medResp.data,
      };
    }

    if (infusResp.type === ResponseType.Success)
      updates.push(...infusResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing infusion drafts', infusResp.data);
      return {
        type: ResponseType.Failure,
        data: infusResp.data,
      };
    }

    if (equipResp.type === ResponseType.Success)
      updates.push(...equipResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing equipment drafts', equipResp.data);
      return {
        type: ResponseType.Failure,
        data: equipResp.data,
      };
    }

    if (elecResp.type === ResponseType.Success) updates.push(...elecResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing electrical drafts', elecResp.data);
      return {
        type: ResponseType.Failure,
        data: elecResp.data,
      };
    }

    if (vitalResp.type === ResponseType.Success)
      updates.push(...vitalResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing vital drafts', vitalResp.data);
      return {
        type: ResponseType.Failure,
        data: vitalResp.data,
      };
    }

    if (checkResp.type === ResponseType.Success)
      updates.push(...checkResp.data);
    else {
      if (globals.debug)
        console.log('Error publishing checklist drafts', checkResp.data);
      return {
        type: ResponseType.Failure,
        data: checkResp.data,
      };
    }

    /* TODO all other models */
    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentDraftUpdates = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let updates: any[] = [];

    const concurrentQueries = [
      removeCurrentCategoryDrafts(department),
      removeCurrentProtocolDrafts(department),
      removeCurrentMedicationDrafts(department),
      removeCurrentInfusionDrafts(department),
      removeCurrentEquipmentDrafts(department),
      removeCurrentElectricalDrafts(department),
      removeCurrentCheckListDrafts(department),
      removeCurrentVitalDrafts(department),
    ];

    const [
      catResp,
      protResp,
      medResp,
      infusResp,
      equipResp,
      elecResp,
      vitalResp,
      checkResp,
    ] = await Promise.all(concurrentQueries);

    if (catResp.type === ResponseType.Success) updates.push(...catResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current category drafts', catResp.data);
      return {
        type: ResponseType.Failure,
        data: catResp.data,
      };
    }

    if (protResp.type === ResponseType.Success) updates.push(...protResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current protocol drafts', protResp.data);
      return {
        type: ResponseType.Failure,
        data: protResp.data,
      };
    }

    if (medResp.type === ResponseType.Success) updates.push(...medResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current medication drafts', medResp.data);
      return {
        type: ResponseType.Failure,
        data: medResp.data,
      };
    }

    if (infusResp.type === ResponseType.Success)
      updates.push(...infusResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current infusion drafts', infusResp.data);
      return {
        type: ResponseType.Failure,
        data: infusResp.data,
      };
    }

    if (equipResp.type === ResponseType.Success)
      updates.push(...equipResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current equipment drafts', equipResp.data);
      return {
        type: ResponseType.Failure,
        data: equipResp.data,
      };
    }

    if (elecResp.type === ResponseType.Success) updates.push(...elecResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current electrical drafts', elecResp.data);
      return {
        type: ResponseType.Failure,
        data: elecResp.data,
      };
    }

    if (vitalResp.type === ResponseType.Success)
      updates.push(...vitalResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current vital drafts', vitalResp.data);
      return {
        type: ResponseType.Failure,
        data: vitalResp.data,
      };
    }

    if (checkResp.type === ResponseType.Success)
      updates.push(...checkResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current checklist drafts', checkResp.data);
      return {
        type: ResponseType.Failure,
        data: checkResp.data,
      };
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
