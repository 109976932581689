import { User, Workbook } from '../../models';
import { ProgressStatus } from '../../API';
import ProtocolItem from './ProtocolItem';
import DepartmentItem from './DepartmentItem';

class WorkbookItem {
  TAG = 'WorkbookItem';
  uid: string;
  name: string;
  model: Workbook;
  workbookUrl: string;
  departmentID: string;
  parents: ProtocolItem[];
  pairedDepIDs: string[] | null | undefined;
  version: string;
  fileSize: number;
  status: ProgressStatus | 'DRAFT' | 'ACTIVE' | 'ARCHIVE' | 'DELETED';
  activeID: string | null | undefined;
  modifiedBy: User;
  aiPdfParserResults?: string | null | undefined;

  constructor(workbook: Workbook, modifiedBy: User) {
    this.model = workbook;
    this.uid = workbook.id;
    this.name = workbook.name;
    this.departmentID = workbook.departmentID;
    this.workbookUrl = workbook.workbookID;
    this.parents = [];
    this.pairedDepIDs = workbook.pairedDepIDs ? workbook.pairedDepIDs : [];
    this.version = workbook.version != null ? workbook.version : 'v1.0.0';
    this.status =
      workbook.status != null ? workbook.status : ProgressStatus.ACTIVE;
    this.activeID = workbook.activeID;
    this.modifiedBy = modifiedBy;
    this.fileSize = workbook.fileSize;
    this.aiPdfParserResults = workbook.aiPdfParserResults;
  }

  toString(): string {
    return `WorkbookItem -> {
            uid=${this.uid},
            name=${this.name},
            pdfUrl=${this.workbookUrl},
            protocolVersion=${this.version},
        }`;
  }
}

export default WorkbookItem;
