import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { Storage } from 'aws-amplify';
import { Dialog } from 'primereact/dialog';
import { BiArrowBack } from 'react-icons/bi';
import { on } from 'events';
import { FaTimes } from 'react-icons/fa';
import MedicationItem from '../../../../../data/model/MedicationItem';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import { IoClose } from 'react-icons/io5';
import {
  Drip,
  LazyMedicationRange,
  Medication,
  MedicationProtocol,
  MedicationRange,
} from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import MedicationProtocolDoseInput from '../import/MedicationProtocolDoseInput';

type MedRangeWithProtocol = {
  dose: MedicationRange | LazyMedicationRange;
  protocol: ProtocolItem;
};

interface MedicationViewProps {
  db: DatabaseResponse;
  visible: boolean;
  medication: MedicationItem | undefined;
  onClose: () => void;
  displayProtID?: boolean;
}
const MedicationViewModal: React.FC<MedicationViewProps> = (props) => {
  const { visible, db, medication, onClose, displayProtID } = props;

  /* Create the list of doses */
  const list = useMemo(() => {
    let ranges: MedRangeWithProtocol[] = [];
    if (medication?.dbMedication) {
      for (let i = 0; i < medication.dbMedication.protocolOptions.length; i++) {
        let protocol = findProtocol(
          medication.dbMedication.protocolOptions[i].protocolID
        );
        if (protocol) {
          for (
            let j = 0;
            j < medication.dbMedication.protocolOptions[i].options.length;
            j++
          ) {
            ranges.push({
              dose: medication.dbMedication.protocolOptions[i].options[j],
              protocol: protocol,
            });
          }
        }
      }
    } else if (medication?.dbDrip) {
      for (let i = 0; i < medication.dbDrip.dripOptions.length; i++) {
        let protocol = findProtocol(
          medication.dbDrip.dripOptions[i].protocolID
        );
        if (protocol) {
          for (
            let j = 0;
            j < medication.dbDrip.dripOptions[i].options.length;
            j++
          ) {
            ranges.push({
              dose: medication.dbDrip.dripOptions[i].options[j],
              protocol: protocol,
            });
          }
        }
      }
    }
    return ranges;
  }, [medication]);

  function findProtocol(id: string) {
    return db.protocols.find((p) => {
      let protID =
        p.status === 'ACTIVE' || (p.status === 'DRAFT' && p.activeID == null)
          ? p.uid
          : p.activeID;
      return protID === id;
    });
  }

  return (
    <Dialog
      visible={visible}
      style={{
        display: 'flex',
        width: '90vw',
        maxWidth: '1300px',
        height: '80vh',
        backgroundColor: 'white',
      }}
      onHide={onClose}
      showHeader={false}
      className="parseDialog"
    >
      <div
        style={{
          borderRadius: '6px',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <h4
            className=""
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px 0px',
              // padding: 0,
              margin: 0,
              backgroundColor: '#f5f5f5',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <span
              style={{
                marginLeft: '20px',
                fontSize: '1.5rem',
                fontWeight: '500',
              }}
            >
              {medication?.name}: {list.length} doses
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={onClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <div
            style={{
              padding: '10px 20px',
            }}
          >
            <ViewportList items={list}>
              {(med: MedRangeWithProtocol, index: number) => {
                return (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <MedicationProtocolDoseInput
                      displayProtID={displayProtID}
                      dose={med.dose}
                      protocol={med.protocol}
                      medication={medication as MedicationItem}
                      index={index}
                      onChange={(data: any, index: number) => {
                        // const newDoses = [...cur.doses];
                        // newDoses[index].confirmedData = data;
                        // setCurrentDoses(newDoses);
                      }}
                    />
                  </div>
                );
              }}
            </ViewportList>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default MedicationViewModal;
