import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import React, { useRef, useState, useEffect } from 'react';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import ReactLoading from 'react-loading';
import { DatabaseResponse, ResponseType } from '../../../../../data/AmplifyDB';
import MedicationItem from '../../../../../data/model/MedicationItem';
import { useSelector } from 'react-redux';
import {
  getFormattedDate,
  getFormattedDateTime,
  globals,
  toTitleCase,
} from '../../../../_global/common/Utils';
import {
  fetchMedicationsFromAPI,
  fetchDripsFromAPI,
  fetchElectricalFromAPI,
} from '../../../../../data/GraphQL_API';
import { Storage } from '@aws-amplify/storage';
import WorkbookItem from '../../../../../data/model/WorkbookItem';
import { getAllWorkbooks } from '../../../../../data/AmplifyDB';
import SearchBar from '../../../../components/Search/SearchBar';
import { ViewportList } from 'react-viewport-list';
import {
  WorkbookDB,
  cloneWorkbook,
  createWorkbook,
} from '../../../../../data/functions/WorkbookDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import { ProgressStatus, User } from '../../../../../models';
import { handleHinckleyCache } from '../../../../../store/actions';
import { useDispatch } from 'react-redux';

import mock_protocol_results from './mock_protocol_names.json';
import mock_ai_results from './dummyResponse.json';
import Loading from '../../../../components/Loading/Loading';
import Lottie, { LottieRef } from 'lottie-react';
import documentLoaderAnimation from './document-loader.json';
import { Col, Row } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import { Button } from 'primereact/button';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import * as XLSX from 'xlsx';
import ElectricalItem from '../../../../../data/model/ElectricalItem';

const HM_DB_ID = process.env.REACT_APP_HM_DB_ID;
const AWS = require('aws-sdk');
const GROUP_SIZE = 50;
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});

const lambda = new AWS.Lambda({
  httpOptions: {
    timeout: 900000, // 900,000 milliseconds = 15 minutes
  },
});

const loading_strings = [
  'Loading... Our AI is stretching its legs!',
  'Extracting data... It’s almost like magic!',
  'Decoding PDFs... Summoning the data wizards!',
  'Scanning for treatment items... Hold tight!',
  'Identifying protocols... Almost there!',
  'Mapping pages... It’s a treasure hunt!',
  'Optimizing... Our AI is on caffeine!',
  'Compiling treatments... Just a sec!',
  'Analyzing shocks... Electrifying, isn’t it?',
  'Locating infusions... Almost done!',
  'Integrating equipment... Hang on!',
  'Tidying data... Our AI loves neatness!',
  'Cross-referencing... Hold on tight!',
  'Crunching numbers... Almost there!',
  'One moment... Our AI is in deep thought!',
  'Fetching details... The data elves are at work!',
  'Finalizing... Just a sip of coffee left!',
  'Verifying... Our AI is double-checking!',
  'Processing... Don’t worry, we got this!',
  'Loading... Practicing data yoga!',
];

const AIPdfParser = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingActive, setIsLoadingActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<string | null>(loading_strings[0]);
  const [isDragActive, setIsDragActive] = React.useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const dropRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );
  const [workbookCreated, setWorkbookCreated] = useState<boolean>(false);
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [workbookURL, setWorkbookURL] = useState<string>('');
  const [dbMeds, setdbMeds] = useState<MedicationItem[]>([]);
  const [dbInfusions, setdbInfusions] = useState<MedicationItem[]>([]);
  const [dbElectrical, setDBElectrical] = useState<ElectricalItem[]>([]);
  const user: User = useSelector((state: any) => state.user);
  const [list, setList] = useState<WorkbookItem[]>([]);
  const [type, setType] = useState<string>(
    location.state && location.state.type ? location.state.type : 'Full'
  );

  const [selectedWorkbook, setSelectedWorkbook] = useState<WorkbookItem | null>(
    null
  );
  const [resultKey, setResultKey] = useState<any>();
  const [data, setData] = useState<any>();
  const handleBack = () => {
    navigate(`/actions`);
  };
  const lottieRef = useRef(null);

  useEffect(() => {
    setList(workbookList);
  }, [workbookList]);

  useEffect(() => {
    const fetchData = async () => {
      const meds = await getHinckleyMediations(database);
      setdbMeds(meds);
      const infusions = await getHinckleyInfusion(database);
      setdbInfusions(infusions);
      const electrical = await getHinckleyElectrical(database);
      setDBElectrical(electrical);
    };

    fetchData();
  }, [database]);

  useEffect(() => {
    const getDetails = async () => {
      const result = await getAllWorkbooks(database.department.id);
      setWorkbookList(result.data);
    };
    getDetails();
  }, [database.department]);

  useEffect(() => {
    if (selectedWorkbook !== null) {
      const fetchURL = async () => {
        return await Storage.get(selectedWorkbook.workbookUrl, {
          level: 'public',
        });
      };
      fetchURL().then((url) => {
        if (globals.debug) console.log('URL:', url);
        setWorkbookURL(url);
      });
    }
  }, [selectedWorkbook]);

  const handleSearch = (searchTerm: string) => {
    const filteredList = workbookList.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setList(filteredList);
  };
  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    // setLoading('Processing PDF...');
    setIsLoadingActive(true);
    handleProcessFileUpload(file);

    if (e.target.files.length) {
      const data = e.target.files[0];
    }
  };

  function getHinckleyMediations(
    db: DatabaseResponse
  ): Promise<MedicationItem[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const medResponse = await fetchMedicationsFromAPI(
          db.department,
          HM_DB_ID
        );
        if (medResponse.type === ResponseType.Success) {
          resolve(medResponse.data);
        } else reject('Error fetching medications');
        dispatch<any>(
          handleHinckleyCache({
            medications: medResponse.data,
          })
        );
        // console.log('Medication List:', meds.length);
        // resolve(meds);
        // resolve(medResponse.data);
      } catch (error) {
        console.error(error);
      }
    });
  }

  function getHinckleyInfusion(
    db: DatabaseResponse
  ): Promise<MedicationItem[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const medResponse = await fetchDripsFromAPI(db.department, HM_DB_ID);
        if (medResponse.type === ResponseType.Success) {
          resolve(medResponse.data);
        } else reject('Error fetching medications');
        dispatch<any>(
          handleHinckleyCache({
            infusions: medResponse.data,
          })
        );
        // console.log('Medication List:', meds.length);
        // resolve(medResponse.data);
      } catch (error) {
        console.error(error);
      }
    });
  }

  function getHinckleyElectrical(
    db: DatabaseResponse
  ): Promise<ElectricalItem[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const medResponse = await fetchElectricalFromAPI(
          db.department,
          HM_DB_ID
        );
        if (medResponse.type === ResponseType.Success) {
          resolve(medResponse.data);
        } else reject('Error fetching electrical');
        dispatch<any>(
          handleHinckleyCache({
            electricals: medResponse.data,
          })
        );
        console.log('Electrical List:', medResponse.data.length);
        // resolve(medResponse.data);
      } catch (error) {
        console.error(error);
      }
    });
  }

  const handleParsingPDF = async (pdf_key: string, pdfStartPage: Number) => {
    // return mock_ai_results;
    const params = {
      FunctionName: 'aiPdfParserImage',
      InvocationType: 'RequestResponse', // Synchronous invocation
      Payload: JSON.stringify({
        bucket_name: process.env.REACT_APP_BUCKET_NAME,
        pdf_key: pdf_key,
        pdfStartPage: pdfStartPage,
      }),
    };
    console.log('params', params);

    try {
      const data = await lambda.invoke(params).promise();
      if (globals.debug) console.log('Lambda function response:', data.Payload);
      return JSON.parse(data.Payload as string); // Optionally parse the JSON response
    } catch (error) {
      if (globals.debug)
        console.error('Error invoking Lambda function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };

  const handleExtractProtocolNames = async (pdf_key: string) => {
    // return mock_protocol_results;
    try {
      const url =
        process.env.REACT_APP_ML_PROTOCOL_URL ??
        (function () {
          throw new Error('API URL is not defined');
        })();

      const bucketName =
        process.env.REACT_APP_BUCKET_NAME ??
        (function () {
          throw new Error('BUCKET NAME is not defined');
        })();

      const requestBody = {
        body: {
          pdfKey: `public/${pdf_key}`,
          bucketName: bucketName,
          modelType: 'regression',
        },
      };

      console.log('requestBody', requestBody);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      console.log('response', response);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('result', result);
      if (result.statusCode !== 200) {
        const errorMessage = result.body;
        throw new Error(errorMessage);
      }
      return result;
    } catch (error) {
      console.error('Error extracting protocol names:', error);
      return;
    }
  };

  const handleCreateWorkbook = async (
    aiParsedResults: any,
    fileKey: any,
    selectedFile: File
  ) => {
    try {
      const workbookDetails: WorkbookDB = {
        isNew: true,
        name: selectedFile.name,
        workbookID: fileKey,
        fileSize: selectedFile.size,
        createdBy: user.id,
        pairedDepIDs:
          database.department.subDeps && database.department.subDeps.length > 0
            ? database.department.subDeps.map((d: DepartmentItem) => d.id)
            : [],
        departmentID: database.department.id,
        status: ProgressStatus.ACTIVE,
        version: 'v1.0.0',
        activeID: null,
        aiPdfParserResults: JSON.stringify(aiParsedResults),
      };

      if (globals.debug) console.log('workbookDetails', workbookDetails);
      const resp = await createWorkbook(workbookDetails);
      if (resp.type === ResponseType.Success) {
        if (globals.debug) console.log('Workbook saved with PDF:', resp.data);
        setWorkbookCreated(true);
      } else {
        throw new Error('Failed to save workbook');
      }
    } catch (error) {
      console.error('Error creating workbook:', error);
      return;
    }
  };

  const updateWorbookWithAIResults = async (
    wb: WorkbookItem,
    aiParsedResults: any
  ) => {
    try {
      if (wb) {
        let new_wb = cloneWorkbook(wb);
        new_wb.aiPdfParserResults = JSON.stringify(aiParsedResults);
        const resp = await createWorkbook(new_wb, wb);
        if (resp.type === ResponseType.Success) {
          if (globals.debug) console.log('Workbook saved with PDF:', resp.data);
          setSelectedWorkbook(resp.data);
        } else {
          console.error('Failed to save workbook');
        }
      }
    } catch (error: any) {
      console.error('Error updating workbook:', error);
      return {
        type: ResponseType.Failure,
        data: error.message,
      };
    }
  };

  const handleMultipleSplitting = async (file: File, pdfKey: any) => {
    if (type === 'Protocols Only') return [];
    // console.log('Splitting PDF:', pdfKey);
    // return;
    const url =
      process.env.REACT_APP_API_GATEWAY_URL ??
      (function () {
        throw new Error('API URL is not defined');
      })();
    const bucketName =
      process.env.REACT_APP_BUCKET_NAME ??
      (function () {
        throw new Error('BUCKET NAME is not defined');
      })();
    const apiUrl = url + '/ai-pdf-split';

    const requestBody = {
      pdf_key: `public/${pdfKey}`,
      bucket_name: bucketName,
      depID: database.department.id,
      group_size: GROUP_SIZE,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (globals.debug) console.log('result', result);
      if (result.statusCode !== 200) {
        const errorMessages = JSON.parse(result.body);
        throw new Error(errorMessages.message);
      }
      //  print type of result.body
      console.log('result.body', typeof result.body);
      console.log('result.body.files', typeof result.body.files);
      const parsedBody = result.body?.files;
      if (globals.debug) console.log('parsedBody', parsedBody);
      return parsedBody;
    } catch (error) {
      console.error('Error splitting PDF:', error);
      return;
    }
  };

  const handleProcessFileUpload = async (file: File) => {
    try {
      // Create the file path
      let dateID = new Date().toLocaleDateString().replace(/\//g, '_');
      let fileName = file.name.split('.');
      let name = fileName.slice(0, fileName.length - 1).join('.');
      let fileExt = fileName[fileName.length - 1];
      let filePath = `${database.department.id}/staging/${dateID}/${name}.${fileExt}`;

      // Replace invalid characters in the file path
      filePath = filePath.replace(/[^a-zA-Z0-9./-]/g, '_');

      // Upload the file to S3
      const result = await Storage.put(filePath, file, {
        contentType: 'application/pdf', // Make sure to set the content type dynamically
        level: 'public',
      });
      if (globals.debug) console.log(result.key);
      setResultKey(result.key);
      // let dbMeds = await getHinckleyMediations(database);
      // setdbMeds(dbMeds);
      // let dbInfusions = await getHinckleyInfusion(database);
      // setdbInfusions(dbInfusions);
      console.log('dbInfusions', dbInfusions);

      let promises = [
        handleMultipleSplitting(file, result.key),
        handleExtractProtocolNames(result.key),
      ];

      let [keyArray, nameResults] = await Promise.all(promises);
      const protocolNames = JSON.parse(nameResults.body);

      let aiJSON = [];
      if (type === 'Full') {
        // Extract the part from public/ to .pdf
        const keys = keyArray.map((url: any) => {
          // Split the URL to get the part after 'public/'
          let afterCom = url.split('.com/')[1];
          return afterCom;
        });

        console.log('keys', keys);
        const pageNumbers = keyArray.map((url: any) => {
          // Extract the part after 'pages_'
          const pagesPart = url.split('pages_')[1];
          const pageNumber = pagesPart.split('_')[0];
          return parseInt(pageNumber, 10);
        });
        console.log('pageNumbers', pageNumbers);

        promises = keys.map(async (key: any, index: number) => {
          const result = await handleParsingPDF(key, pageNumbers[index]);
          return JSON.parse(result.body);
        });

        const aiParsedResults = await Promise.all(promises);

        // Combine all the parsed results into a single array
        let combinedResults = aiParsedResults.flat();

        combinedResults = combinedResults.sort((a, b) => a.index - b.index);
        // let combinedResults=[{"file":"A7._Nausea_and_Vomiting_pages_1_to_1","index":1,"doses":[{"type":"Medication","name":"Ondansetron","dose":"4 mg","administrationRoutes":"IV, IO","maxSingleDose":"","minSingleDose":"","maxTotalDose":"","repeatTime":"15 min","repeatAllowed":"TRUE","ageGroup":"Adult","weightLow":"","weightHigh":"","ageLow":"","ageHigh":"","instructionMain":"Repeat x1","instructionSecond":"911","warning":"","source":"BOTH"},{"type":"Medication","name":"Ondansetron","dose":"0.15 mg/kg","administrationRoutes":"IV, IO","maxSingleDose":"4 mg","minSingleDose":"","maxTotalDose":"","repeatTime":"","repeatAllowed":"","ageGroup":"Pediatric","weightLow":"","weightHigh":"","ageLow":"6 months","ageHigh":"","instructionMain":"","instructionSecond":"","warning":"","source":"PYPDF"},{"type":"Medication","name":"Diphenhydramine","dose":"12.5-25 mg","administrationRoutes":"IV, IO, IM","maxSingleDose":"","minSingleDose":"","maxTotalDose":"","repeatTime":"","repeatAllowed":"","ageGroup":"Adult","weightLow":"","weightHigh":"","ageLow":"","ageHigh":"","instructionMain":"Must evaluate QTc <470 ms","instructionSecond":"","warning":"","source":"PYPDF"},{"type":"Medication","name":"Diphenhydramine","dose":"0.1 mg/kg","administrationRoutes":"IV, IO, IM","maxSingleDose":"25 mg","minSingleDose":"","maxTotalDose":"","repeatTime":"","repeatAllowed":"","ageGroup":"Pediatric","weightLow":"12 kg","weightHigh":"","ageLow":"2 years","ageHigh":"","instructionMain":"Must evaluate QTc <470 ms","instructionSecond":"","warning":"","source":"PYPDF"},{"type":"Medication","name":"Isopropyl Alcohol","dose":"","administrationRoutes":"","maxSingleDose":"","minSingleDose":"","maxTotalDose":"","repeatTime":"15 min","repeatAllowed":"TRUE","ageGroup":"","weightLow":"","weightHigh":"","ageLow":"","ageHigh":"","instructionMain":"Allow patient to inhale vapor from alcohol wipe","instructionSecond":"","warning":"","source":"PYPDF"}]}]

        if (file && combinedResults.length > 0) {
          let workBook = await handleCreateWorkbook(
            combinedResults,
            result.key,
            file
          );
        }
        try {
          aiJSON = combinedResults;

          if (!aiJSON || aiJSON.length === 0) {
            alert('Error parsing AI data Body is empty or undefined');
            return;
          }

          // Save the JSON results as a text file and download it
          const jsonBlob = new Blob([JSON.stringify(aiJSON)], {
            type: 'application/json',
          });
          const jsonUrl = URL.createObjectURL(jsonBlob);
          const jsonLink = document.createElement('a');
          jsonLink.href = jsonUrl;
          jsonLink.download = 'parsedAI.json';
          jsonLink.click();
          URL.revokeObjectURL(jsonUrl);

          // Save the JSON results as a Excel file and download it
          const excelData: any[] = [];

          aiJSON.forEach((item: any) => {
            const { file, index, doses } = item;

            if (doses && doses.length > 0) {
              excelData.push({
                'File Name': file,
                'Page Number': index,
                type: '',
                name: '',
                dose: '',
                administrationRoutes: '',
                maxSingleDose: '',
                minSingleDose: '',
                maxTotalDose: '',
                repeatTime: '',
                repeatAllowed: '',
                ageGroup: '',
                weightLow: '',
                weightHigh: '',
                ageLow: '',
                ageHigh: '',
                instructionMain: '',
                instructionSecond: '',
                warning: '',
              });

              doses.forEach((dose: any) => {
                excelData.push({
                  'File Name': '',
                  'Page Number': '',
                  type: dose.type || '',
                  name: dose.name || '',
                  dose: dose.dose || '',
                  administrationRoutes: dose.administrationRoutes || '',
                  maxSingleDose: dose.maxSingleDose || '',
                  minSingleDose: dose.minSingleDose || '',
                  maxTotalDose: dose.maxTotalDose || '',
                  repeatTime: dose.repeatTime || '',
                  repeatAllowed: dose.repeatAllowed || '',
                  ageGroup: dose.ageGroup || '',
                  weightLow: dose.weightLow || '',
                  weightHigh: dose.weightHigh || '',
                  ageLow: dose.ageLow || '',
                  ageHigh: dose.ageHigh || '',
                  instructionMain: dose.instructionMain || '',
                  instructionSecond: dose.instructionSecond || '',
                  warning: dose.warning || '',
                });
              });
            } else {
              excelData.push({
                'File Name': file,
                'Page Number': index,
                type: '',
                name: '',
                dose: '',
                administrationRoutes: '',
                maxSingleDose: '',
                minSingleDose: '',
                maxTotalDose: '',
                repeatTime: '',
                repeatAllowed: '',
                ageGroup: '',
                weightLow: '',
                weightHigh: '',
                ageLow: '',
                ageHigh: '',
                instructionMain: '',
                instructionSecond: '',
                warning: '',
              });
            }
          });

          // Create a new workbook and worksheet
          const worksheet = XLSX.utils.json_to_sheet(excelData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Parsed AI Data');

          // Convert the workbook to a Blob and download it as an Excel file
          const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });
          const blob = new Blob([excelBuffer], {
            type: 'application/octet-stream',
          });
          const excelUrl = URL.createObjectURL(blob);
          const excelLink = document.createElement('a');
          excelLink.href = excelUrl;
          excelLink.download = 'parsedAI.xlsx';
          excelLink.click();
          URL.revokeObjectURL(excelUrl);
        } catch (e) {
          console.error('Error parsing AI data', e);
          return;
        }
      }
      // const dummyData = aiMedicationsData;
      // setLoading(null);
      setIsLoadingActive(false);
      if (aiJSON) {
        navigate('/actions/ai-pdf-parser/auto-protocol-extraction', {
          state: {
            file: file,
            pdfKey: result.key, // Pass the file path if needed
            dbMeds: dbMeds,
            dbInfusions: dbInfusions,
            dbElectricals: dbElectrical,
            aiParsedData: aiJSON,
            workbookURL: workbookURL,
            protocolNames: protocolNames,
            type: type,
            // department:database.department
          },
        });
      }
    } catch (e: any) {
      console.error('Upload error:', e);
      return {
        type: ResponseType.Failure,
        data: e.message,
      };
    }
  };

  const handleProcessWorkbook = async (wb: WorkbookItem) => {
    try {
      let response: any = await Storage.get(wb.workbookUrl, {
        level: 'public',
        download: true,
      });
      let blob = await response.Body.blob();
      let file = new File([blob], wb.name, { type: 'application/pdf' });
      setErrorText('');
      if (globals.debug) console.log(wb.workbookUrl);
      setResultKey(wb.workbookUrl);
      // let dbMeds = await getHinckleyMediations(database);
      // setdbMeds(dbMeds);
      // let dbInfusions = await getHinckleyInfusion(database);
      // setdbInfusions(dbInfusions);
      let promises = [
        handleMultipleSplitting(file, wb.workbookUrl),
        handleExtractProtocolNames(wb.workbookUrl),
      ];

      let [keyArray, nameResults] = await Promise.all(promises);

      const protocolNames = JSON.parse(nameResults.body);
      console.log('keyArray', keyArray);
      console.log('protocolNames', protocolNames);
      // let keyArray = await handleMultipleSplitting(file, wb.workbookUrl);

      let aiJSON = [];
      if (type === 'Full') {
        // Extract the part from public/ to .pdf
        const keys = keyArray.map((url: any) => {
          // Split the URL to get the part after 'public/'
          let afterCom = url.split('.com/')[1];
          return afterCom;
        });

        const pageNumbers = keyArray.map((url: any) => {
          // Extract the part after 'pages_'
          const pagesPart = url.split('pages_')[1];
          const pageNumber = pagesPart.split('_')[0];
          return parseInt(pageNumber, 10);
        });

        console.log('keys', keys);
        console.log('pageNumbers', pageNumbers);

        promises = keys.map(async (key: any, index: number) => {
          const result = await handleParsingPDF(key, pageNumbers[index]);
          return JSON.parse(result.body);
        });

        const aiParsedResults = await Promise.all(promises);

        // Combine all the parsed results into a single array
        let combinedResults = aiParsedResults.flat();

        combinedResults = combinedResults.sort((a, b) => a.index - b.index);
        // let combinedResults=[{"file":"A7._Nausea_and_Vomiting_pages_1_to_1","index":1,"doses":[{"type":"Medication","name":"Ondansetron","dose":"4 mg","administrationRoutes":"IV, IO","maxSingleDose":"","minSingleDose":"","maxTotalDose":"","repeatTime":"15 min","repeatAllowed":"TRUE","ageGroup":"Adult","weightLow":"","weightHigh":"","ageLow":"","ageHigh":"","instructionMain":"Repeat x1","instructionSecond":"911","warning":"","source":"BOTH"},{"type":"Medication","name":"Ondansetron","dose":"0.15 mg/kg","administrationRoutes":"IV, IO","maxSingleDose":"4 mg","minSingleDose":"","maxTotalDose":"","repeatTime":"","repeatAllowed":"","ageGroup":"Pediatric","weightLow":"","weightHigh":"","ageLow":"6 months","ageHigh":"","instructionMain":"","instructionSecond":"","warning":"","source":"PYPDF"},{"type":"Medication","name":"Diphenhydramine","dose":"12.5-25 mg","administrationRoutes":"IV, IO, IM","maxSingleDose":"","minSingleDose":"","maxTotalDose":"","repeatTime":"","repeatAllowed":"","ageGroup":"Adult","weightLow":"","weightHigh":"","ageLow":"","ageHigh":"","instructionMain":"Must evaluate QTc <470 ms","instructionSecond":"","warning":"","source":"PYPDF"},{"type":"Medication","name":"Diphenhydramine","dose":"0.1 mg/kg","administrationRoutes":"IV, IO, IM","maxSingleDose":"25 mg","minSingleDose":"","maxTotalDose":"","repeatTime":"","repeatAllowed":"","ageGroup":"Pediatric","weightLow":"12 kg","weightHigh":"","ageLow":"2 years","ageHigh":"","instructionMain":"Must evaluate QTc <470 ms","instructionSecond":"","warning":"","source":"PYPDF"},{"type":"Medication","name":"Isopropyl Alcohol","dose":"","administrationRoutes":"","maxSingleDose":"","minSingleDose":"","maxTotalDose":"","repeatTime":"15 min","repeatAllowed":"TRUE","ageGroup":"","weightLow":"","weightHigh":"","ageLow":"","ageHigh":"","instructionMain":"Allow patient to inhale vapor from alcohol wipe","instructionSecond":"","warning":"","source":"PYPDF"}]}]

        try {
          aiJSON = combinedResults;

          if (!aiJSON || aiJSON.length === 0) {
            alert('Error parsing AI data Body is empty or undefined');
            return;
          }

          /* Save the results of the JSON to a text file and download it */
          const blob = new Blob([JSON.stringify(aiJSON)], {
            type: 'application/json',
          });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'parsedAI.json';
          a.click();
          URL.revokeObjectURL(url);
        } catch (e) {
          console.error('Error parsing AI data', e);
          return;
        }

        /* Save the results to the workbook */
        if (aiJSON && aiJSON.length > 0) {
          updateWorbookWithAIResults(wb, aiJSON);
        }
      }

      // const dummyData = aiMedicationsData;
      // setLoading(null);
      setIsLoadingActive(false);
      if (aiJSON) {
        navigate('/actions/ai-pdf-parser/auto-protocol-extraction', {
          state: {
            file: file,
            pdfKey: wb.workbookUrl, // Pass the file path if needed
            dbMeds: dbMeds,
            dbInfusions: dbInfusions,
            dbElectricals: dbElectrical,
            aiParsedData: aiJSON,
            protocolNames: protocolNames,
            type: type,
            // department:database.department
          },
        });
      }
    } catch (e: any) {
      console.error('Upload error:', e);
      return {
        type: ResponseType.Failure,
        data: e.message,
      };
    }
  };
  // const startLoading = () => {
  //   const loading_strings = [
  //     'Loading... Our AI is stretching its legs!',
  //     'Extracting data... It’s almost like magic!',
  //     'Decoding PDFs... Summoning the data wizards!',
  //     'Scanning for treatment items... Hold tight!',
  //     'Identifying protocols... Almost there!',
  //     'Mapping pages... It’s a treasure hunt!',
  //     'Optimizing... Our AI is on caffeine!',
  //     'Compiling treatments... Just a sec!',
  //     'Analyzing shocks... Electrifying, isn’t it?',
  //     'Locating infusions... Almost done!',
  //     'Integrating equipment... Hang on!',
  //     'Tidying data... Our AI loves neatness!',
  //     'Cross-referencing... Hold on tight!',
  //     'Crunching numbers... Almost there!',
  //     'One moment... Our AI is in deep thought!',
  //     'Fetching details... The data elves are at work!',
  //     'Finalizing... Just a sip of coffee left!',
  //     'Verifying... Our AI is double-checking!',
  //     'Processing... Don’t worry, we got this!',
  //     'Loading... Practicing data yoga!',
  //   ];

  //   const generate_loading_text = () => {
  //     let index = Math.floor(Math.random() * loading_strings.length);
  //     console.log('index', index);
  //     // setLoading(loading_strings[index]);
  //     const loadingElement = document.getElementById('loading-text');
  //     if (loadingElement) {
  //       loadingElement.classList.remove('slide-in-active');
  //       loadingElement.classList.add('slide-out');

  //       setTimeout(() => {
  //         setLoading(loading_strings[index]);
  //         loadingElement.classList.remove('slide-out');
  //         loadingElement.classList.add('slide-in');

  //         setTimeout(() => {
  //           loadingElement.classList.add('slide-in-active');
  //         }, 10); // Ensure the transition starts
  //       }, 500); // Adjust timing as needed
  //     } else setLoading(loading_strings[index]);
  //   };

  //   generate_loading_text();
  //   // Loop every 2.5 seconds until loading is null
  //   const interval = setInterval(() => {
  //     if (loading === null) {
  //       clearInterval(interval);
  //     } else {
  //       generate_loading_text();
  //     }
  //   }, 2500);

  //   //If the user routes to a different page, clear the interval

  //   return interval;
  // };

  const generate_loading_text = () => {
    let index = Math.floor(Math.random() * loading_strings.length);
    const loadingElement = document.getElementById('loading-text');
    if (loadingElement) {
      loadingElement.classList.remove('slide-in-active');
      loadingElement.classList.add('slide-out');

      setTimeout(() => {
        setLoading(loading_strings[index]);
        loadingElement.classList.remove('slide-out');
        loadingElement.classList.add('slide-in');

        setTimeout(() => {
          loadingElement.classList.add('slide-in-active');
        }, 10); // Ensure the transition starts
      }, 500); // Adjust timing as needed
    } else {
      setLoading(loading_strings[index]);
    }
  };

  useEffect(() => {
    let interval: any;

    const startInterval = () => {
      generate_loading_text();
      interval = setInterval(() => {
        generate_loading_text();
      }, 3500);
    };

    const stopInterval = () => {
      clearInterval(interval);
    };
    if (!isLoadingActive) {
      stopInterval();
    } else {
      startInterval();
    }

    return () => {
      stopInterval();
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isLoadingActive]);

  const handleDrop = (e: any) => {
    e.preventDefault();

    // Check if the file is a PDF
    const file: File = e.dataTransfer?.files[0];
    if (file.type === 'application/pdf') {
      // setLoading('Processing PDF...');
      setIsLoadingActive(true);
      handleProcessFileUpload(file);
      setErrorText('');
    } else {
      setErrorText('Only PDF files are allowed');
    }

    setIsDragActive(false);
  };

  const handleWorkbookSelect = async (item: WorkbookItem) => {
    console.log('Starting Parsing:', getFormattedDateTime(new Date(), false));
    setSelectedWorkbook(item);
    // setLoading('Processing PDF...');
    setIsLoadingActive(true);
    // startLoading();

    if (item.aiPdfParserResults) {
      // setLoading('Downloading PDF...');
      let response: any = await Storage.get(item.workbookUrl, {
        level: 'public',
        download: true,
      });
      let blob = await response.Body.blob();
      let file = new File([blob], item.name, { type: 'application/pdf' });
      // setLoading('Processing PDF...');
      let nameResults = await handleExtractProtocolNames(item.workbookUrl);
      const protocolNames = JSON.parse(nameResults.body);
      console.log('Extracted protocol names:', protocolNames);
      console.log('TYPE SELECTED:', type);
      setIsLoadingActive(false);
      navigate('/actions/ai-pdf-parser/auto-protocol-extraction', {
        state: {
          file: file,
          pdfKey: item.workbookUrl, // Pass the pdf key here
          dbMeds: dbMeds,
          dbInfusions: dbInfusions,
          dbElectricals: dbElectrical,
          aiParsedData: item.aiPdfParserResults,
          workbookURL: item.workbookUrl,
          protocolNames: protocolNames,
          type: type,

          // department:database.department
        },
      });
    } else {
      // console.log('Extracting protocol names');
      // handleExtractProtocolNames(item.workbookUrl).then((data) => {
      //   console.log('Extracted protocol names:', data);
      // });
      handleProcessWorkbook(item);
      setErrorText('');
    }
  };

  const AIParserHeader = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{ margin: '0px 20px', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0px 0px',
              flex: 1,
              width: '100%',
            }}
          >
            <div className={`titleStyling`}>
              <h6 className={`backTextLight hoverText`} onClick={handleBack}>
                <span>
                  <IoArrowBack size="1.15rem" style={{ marginRight: '5px' }} />
                </span>
                Actions
              </h6>
              <div className="titleText headerText">Select PDF</div>
              {/* {date && < h6 className={`headerTextLight  ${homeScreen ? "titleText" : ''}`}>{date}</h6>} */}
              <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                Extract the details from PDF into your Excel
              </h6>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="headerButtonContainer">
              <Dropdown
                value={type}
                options={[
                  { label: 'Full', value: 'Full' },
                  { label: 'Protocols Only', value: 'Protocols Only' },
                ]}
                onChange={(e: any) => {
                  console.log('Type:', e);
                  setType(e);
                }}
                style={{
                  marginTop: '-13px',
                  marginRight: '16px',
                  padding: 0,
                }}
                buttonColor={'#E0EADD'}
                textColor={'#00534C'}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  return !isLoadingActive ? (
    <div className="screen-container">
      <AIParserHeader />

      <div style={{ flex: 1, display: 'flex', ...props.containerStyle }}>
        <div
          className="cursorPointer dragDropDotGridModalWorkbook"
          style={{
            display: 'flex',
            flex: 1,
            background: isDragActive ? '#E0EADD' : 'transparent',
            padding: '20px 0px',
            border: isDragActive
              ? '1px dashed #00534C'
              : errorText
                ? '1px dashed #880808'
                : '1px dashed #cdc8c8',
          }}
          onClick={() => {
            const fileInput = document.getElementById('fileInput');
            fileInput?.click();
          }}
          ref={dropRef}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragActive(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setIsDragActive(false);
          }}
          onDrop={handleDrop}
        >
          <input
            type="file"
            accept=".pdf"
            className="pdfInputBox"
            id="fileInput"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
          />
          {errorText !== '' && (
            <div
              className="errorText"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
                fontSize: '16px',
              }}
            >
              {errorText}
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5px',
            }}
          >
            {isDragActive ? (
              <RiUpload2Line className="drag-drop-icon" />
            ) : (
              <RiDownload2Line className="drag-drop-icon" />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5px',
            }}
          >
            Drag and Drop
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              marginBottom: '5px',
            }}
          >
            or{' '}
            <span style={{ marginLeft: '5px' }} className="green_accent_text">
              {' '}
              Browse
            </span>
          </div>
          <div
            style={{ fontSize: '12px' }}
            className="grey_text ketamine-general-label"
          >
            Support: PDF ONLY
          </div>
        </div>
        {workbookList.length > 0 && (
          <>
            <div
              style={{
                flex: 1,
                padding: '20px',
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
                border: '1px solid #e0e0e0',
                borderRadius: '5px',
              }}
              className="ketamine-general-label"
            >
              <h5 className="ketmine-header-text">Select From Workbook</h5>
              <SearchBar
                containerStyle={{ width: '100%' }}
                value={searchQuery}
                onChange={(searchTerm: string, e) => {
                  setSearchQuery(searchTerm);
                  handleSearch(searchTerm);
                }}
                placeholder={'Search Workbooks...'}
              />
              <div
                className="contentBorder protocolCalculationPad primaryListScroll"
                style={{ marginBottom: '10px' }}
              >
                <ViewportList items={list}>
                  {(item: WorkbookItem, index: number) => (
                    <div
                      className=" cursorPointer hoverItem"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        borderBottom:
                          index !== list.length - 1
                            ? '1px solid #e0e0e0'
                            : 'none',
                      }}
                      onClick={() => {
                        handleWorkbookSelect(item);
                      }}
                    >
                      <span className={'a1Content'} style={{ width: '100%' }}>
                        <div
                          className="notification-css-grey"
                          style={{ fontSize: '14px' }}
                        >
                          {item.model.updatedAt &&
                            getFormattedDate(item.model.updatedAt, false)}
                        </div>

                        <div
                          style={{
                            fontSize: '16px',
                            marginBottom: '8px',
                          }}
                          className="contentTitle a1SubITems"
                        >
                          {item.name}
                        </div>
                      </span>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="screen-container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          ...props.containerStyle,
        }}
      >
        {/*className="loading-background">*/}
        <Lottie
          ref={lottieRef}
          animationData={documentLoaderAnimation}
          loop={true}
          style={{}}
        />
        <div
          id="loading-text"
          style={{
            fontSize: '1rem',
            // marginTop: '20px',
            color: '#636363',
            fontWeight: 'bold',
          }}
        >
          {loading}
        </div>
      </div>
    </div>
  );
};

export default AIPdfParser;
