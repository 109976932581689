import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Copy from '../ui/pages/actions/details/copy/Copy';
import MedicationCopy from '../ui/pages/actions/details/copy/MedicationCopy';
import EquipmentCopy from '../ui/pages/actions/details/copy/EquipmentCopy';
import VitalsCopy from '../ui/pages/actions/details/copy/VitalsCopy';
import ChecklistCopy from '../ui/pages/actions/details/copy/ChecklistCopy';
import ProtocolCopy from '../ui/pages/actions/details/copy/ProtocolCopy';
import SubDepCopy from '../ui/pages/actions/details/sub-departments/SubDepCopy';
import CategoryOwner from '../ui/pages/actions/details/sub-departments/CategoryOwner';
import ProtocolOwner from '../ui/pages/actions/details/sub-departments/ProtocolOwner';
import IndexPage from '../ui/pages/actions/details/index/IndexPage';
import ImportPage from '../ui/pages/actions/details/import/ImportPage';
import { User } from '../models';
import { useSelector } from 'react-redux';
import EncryptionPage from '../ui/pages/actions/details/encryption/EncryptionPage';
import ImportDosesPage from '../ui/pages/actions/details/import/medicationDoses/ImportDosesPage';
import ImportDoseResultsPage from '../ui/pages/actions/details/import/medicationDoses/ImportDoseResultsPage';
import ImportMedicationResultsPage from '../ui/pages/actions/details/import/protocolDoses/ImportMedicationsPage';
import AuditPage from '../ui/pages/actions/details/audit/AuditPage';
import { globals } from '../ui/_global/common/Utils';
import DepartmentPage from '../ui/pages/actions/details/department/DepartmentPage';
import AIPdfParserPage from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParser';
import AIPdfParserProtocol from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParserProtocol';
import AIPdfParseResult from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParserResult';
import ImportProtocolDosesPage from '../ui/pages/actions/details/import/protocolDoses/ImportProtocolDosesPage';
import ImportPagesResults from '../ui/pages/actions/details/import/medicationDoses/ImportPagesResults';
import HinckleyDatabasePage from '../ui/pages/actions/details/hinckley-database/HinckleyDatabasePage';
import HinckleyMedications from '../ui/pages/actions/details/hinckley-database/hinckley-medications/HinckleyMedications';
import HinckleyEditMedication from '../ui/pages/actions/details/hinckley-database/hinckley-medications/HinckleyEditMedication';
import AIPdfParserProtocolAutomatic from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParserProtocolAutomatic';
import AIPdfParserResultAutomatic from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParserResultAutomatic';
import NotificationCopy from '../ui/pages/actions/details/copy/NotificationCopy';
import ModelsPage from '../ui/pages/actions/models/ModelsPage';
import ListAllWorkbooks from '../ui/pages/actions/models/ListAllWorkbooks';
import ViewProtocolSet from '../ui/pages/actions/models/ViewProtocolSet';

/* 10-27-23 praveen : Created the common component protocol route navigation */
const ActionsNavigator = () => {
  const user: User = useSelector((state: any) => state?.user);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const page = useMemo(() => {
    if (path === '/actions/copy') return <Copy />;
    else if (path === '/actions/medication-copy') return <MedicationCopy />;
    else if (path === '/actions/infusion-copy') return <MedicationCopy />;
    else if (path === '/actions/equipment-copy') return <EquipmentCopy />;
    else if (path === '/actions/vitals-copy') return <VitalsCopy />;
    else if (path === '/actions/checklist-copy') return <ChecklistCopy />;
    else if (path === '/actions/notifications-copy')
      return <NotificationCopy />;
    else if (path === '/actions/category-protocols-copy')
      return <ProtocolCopy />;
    else if (path === '/actions/sub-departments') return <SubDepCopy />;
    else if (path === '/actions/category-owner') return <CategoryOwner />;
    else if (path === '/actions/models') return <ModelsPage />;
    else if (path === '/actions/models/protocol-sets')
      return <ListAllWorkbooks />;
    else if (path === '/actions/models/view-protocol-set')
      return <ViewProtocolSet />;
    else if (path === '/actions/protocol-owner') return <ProtocolOwner />;
    else if (path === '/actions/indexes') return <IndexPage />;
    else if (path === '/actions/import') return <ImportPage />;
    else if (path === '/actions/encryption') return <EncryptionPage />;
    else if (path === '/actions/audit') return <AuditPage />;
    else if (
      path === '/actions/ai-pdf-parser' ||
      path === '/actions/ai-pdf-parser/'
    )
      return <AIPdfParserPage />;
    else if (
      path === '/actions/ai-pdf-parser/protocol-extraction' ||
      path === '/actions/ai-pdf-parser/protocol-extraction/'
    )
      return <AIPdfParserProtocol />;
    else if (
      path === '/actions/ai-pdf-parser/auto-protocol-extraction' ||
      path === '/actions/ai-pdf-parser/auto-protocol-extraction/'
    )
      return <AIPdfParserProtocolAutomatic />;
    else if (
      path === '/actions/ai-pdf-parser/results' ||
      path === '/actions/ai-pdf-parser/results'
    )
      return <AIPdfParseResult />;
    else if (
      path === '/actions/ai-pdf-parser/results-automatic' ||
      path === '/actions/ai-pdf-parser/results-automatic'
    )
      return <AIPdfParserResultAutomatic />;
    else if (path === '/actions/hinckley-database')
      return <HinckleyDatabasePage />;
    else if (path === '/actions/hinckley-database/medications')
      return <HinckleyMedications />;
    else if (path === '/actions/hinckley-database/view-medication')
      return <HinckleyEditMedication stateData={location.state} />;
    else if (path === '/actions/import-protocol-medication-doses')
      return <ImportProtocolDosesPage />;
    else if (path === '/actions/import-medication-doses')
      return <ImportDosesPage />;
    else if (path === '/actions/create-department') return <DepartmentPage />;
    else if (path === '/actions/import-medication-doses/results') {
      if (
        location.state &&
        location.state.data &&
        location.state.file &&
        location.state.database
      )
        return <ImportDoseResultsPage />;
      else {
        if (globals.debug) console.log('Invalid state', location.state);
        navigate('/actions/import-protocol-medication-doses');
      }
    } else if (path === '/actions/import-medication-doses/medication-results') {
      if (
        location.state &&
        location.state.data &&
        location.state.file &&
        location.state.database
      )
        return <ImportPagesResults />;
      else {
        navigate('/actions/import-medication-doses/results');
      }
    }
    return <>null</>;
  }, [location]);

  if (!user || user.type !== 'ADMIN') {
    navigate('/');
    return null;
  }
  return <>{page}</>;
};

export default ActionsNavigator;
