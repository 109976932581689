// Note: EditDB for Checklist/Form
// Date: 01/15/2024
// Author: Guruprasad Venkatraman

import { DataStore } from 'aws-amplify';
import { Storage } from '@aws-amplify/storage';
import { Form, FormGroup, Notification, Question, User } from '../../models';
import { NotificationType, ProgressStatus } from '../../API';
import { ResponseType, Response } from '../AmplifyDB';
import NotificationItem from '../model/NotificationItem';
import DepartmentItem from '../model/DepartmentItem';
import { globals } from '../../ui/_global/common/Utils';

export type NotificationJSON = {
  title: string;
  message: string;
  timestamp: Date;
  deadline?: Date;
  type: NotificationType;
  isReadIDs: string[];
  isAckIDs: string[];
  departmentID: string;
  isPush: boolean;
  pairedDeps: DepartmentItem[] | undefined | null;
  fileURLs: string[];
  taggedProtocols: string[];
  questions: Question[];
};

export const createNotification = async (
  notification: NotificationJSON | NotificationItem,
  creator: User
): Promise<Response> => {
  try {
    let json: NotificationJSON;
    if (notification.hasOwnProperty('dbNotification')) {
      let not = notification as NotificationItem;
      json = {
        title: not.title,
        message: not.message ? not.message : '',
        timestamp: new Date(),
        deadline: not.deadline ? not.deadline : undefined,
        type: not.type,
        isReadIDs: not.isReadIDs,
        isAckIDs: not.isAckIds,
        departmentID: not.depID,
        fileURLs: not.fileURLs,
        taggedProtocols: not.taggedProtocols.map((p) => p.uid),
        questions: not.questions,
        isPush: not.isPush,
        pairedDeps: not.pairedDeps,
      };
    } else json = notification as NotificationJSON;

    let result = await DataStore.save(
      new Notification({
        title: json.title,
        message: json.message,
        timestamp: json.timestamp.toISOString(),
        type: json.type,
        isReadIDs: json.isReadIDs,
        isAckIDs: json.isAckIDs,
        departmentID: json.departmentID,
        fileURLs: json.fileURLs,
        taggedProtocols: json.taggedProtocols,
        questions: json.questions,
        isPush: json.isPush,
        createdBy: creator.id,
        modifiedBy: creator.id,
        deadlineTimestamp: json.deadline
          ? json.deadline.toISOString()
          : undefined,
        pairedDepIDs: json.pairedDeps ? json.pairedDeps.map((d) => d.id) : [],
      })
    );

    let newNotification = new NotificationItem(result, []);
    newNotification.setModifiedBy(creator);

    return {
      type: ResponseType.Success,
      data: newNotification,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const updateNotification = async (
  updatedNotification: any | Notification,
  user: User
): Promise<Response> => {
  try {
    if (globals.debug)
      console.log('Updated Notification:', updatedNotification);
    const originalNotification = await DataStore.query(
      Notification,
      updatedNotification.uid
    );
    if (!originalNotification) {
      throw new Error('Notification not found.');
    }

    // Update the notification with the new values
    const result = await DataStore.save(
      Notification.copyOf(originalNotification, (updated) => {
        updated.title = updatedNotification.title;
        updated.message = updatedNotification.message;
        updated.timestamp = updatedNotification.timestamp.toISOString();
        updated.type = updatedNotification.type;
        updated.isReadIDs = updatedNotification.isReadIDs;
        updated.isAckIDs = updatedNotification.isAckIDs
          ? updatedNotification.isAckIDs
          : [];
        updated.departmentID = updatedNotification.depID;
        updated.fileURLs = updatedNotification.fileURLs;
        updated.taggedProtocols = updatedNotification.taggedProtocols.map(
          (p: any) => p
        );
        updated.questions = updatedNotification.questions;
        updated.isPush = updatedNotification.isPush;
        updated.modifiedBy = user.id;
        updated.createdBy = updatedNotification.dbNotification.createdBy;
        updated.pairedDepIDs = updatedNotification.pairedDepIDs
          ? updatedNotification.pairedDepIDs.map((d: any) => d)
          : [];
        updated.deadlineTimestamp = updatedNotification.deadlineTimestamp;
      })
    );

    let newNotification = new NotificationItem(result, []);
    newNotification.setModifiedBy(user);

    return {
      type: ResponseType.Success,
      data: newNotification,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Hard delete a notification
 * @param notification  The notification to delete
 * @returns Success if the notification was deleted or Failure if the notification does not exist
 */
export const deleteNotification = async (
  notification: NotificationItem
): Promise<Response> => {
  try {
    /* Delete the notification files in S3 */
    for (let i = 0; i < notification.fileURLs.length; i++)
      await Storage.remove(notification.fileURLs[i], { level: 'public' });

    let not = await DataStore.delete(Notification, notification.uid);
    if (not == null) {
      return {
        type: ResponseType.Failure,
        data: 'The notification does not exist',
      };
    }
    return {
      type: ResponseType.Success,
      data: notification,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Upload multiple files to S3 and return the file paths
 * @param department The department that owns the notification
 * @param files The files to upload
 * @returns Success if the files were uploaded or Failure if there was an error
 */
export const uploadAllFilesToS3 = async (
  department: DepartmentItem,
  files: File[],
  callback?: (status: string) => void
): Promise<Response> => {
  try {
    let fileIncrementPercent = Math.round((1.0 / files.length) * 100);
    let totalPercent = 0;
    let filePaths: string[] = [];
    for (let i = 0; i < files.length; i++) {
      let response = await uploadNewFileToS3(
        department,
        files[i],
        totalPercent,
        fileIncrementPercent,
        callback
      );
      if (response.type === ResponseType.Success)
        filePaths.push(response.data as string);
      else {
        //Retry the upload
        response = await uploadNewFileToS3(
          department,
          files[i],
          totalPercent,
          fileIncrementPercent,
          callback
        );
        if (response.type === ResponseType.Success)
          filePaths.push(response.data as string);
        else console.error('Error uploading file:', files[i], response.data);
      }
      totalPercent += fileIncrementPercent;
    }
    return {
      type: ResponseType.Success,
      data: filePaths,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Upload the new JPEG, PNG, PDF, MP4, MOV, or IMEG to S3 and return the file path
 * @param department The department that owns the protocol
 * @param notification The notification that owns the file
 * @param file The file to upload
 * @returns Success if the file was uploaded or Failure if there was an error
 */
export const uploadNewFileToS3 = async (
  department: DepartmentItem,
  file: File,
  totalPercent: number,
  fileIncrementPercent?: number,
  loadingCallback?: (status: string) => void
): Promise<Response> => {
  try {
    /* First create the file path -> Public / departmentID / notifications / (Month_Day_Year) / (Name).(File Extension)*/
    let dateID = new Date().toLocaleDateString().replace(/\//g, '_');
    let fileName = file.name.split('.');
    let name = fileName.slice(0, fileName.length - 1).join('.');
    let filExt = fileName[fileName.length - 1];
    let filePath =
      department.id + '/notifiations/' + dateID + '/' + name + '.' + filExt;

    /* Then take out ay characters that are not allowed in the file path and replace them with an underscore */
    filePath = filePath.replace(/[^a-zA-Z0-9./-]/g, '_');

    /* Make sure all the folders exist in the S3 bucket */
    await Storage.put(filePath, file, {
      contentType: file.type,
      level: 'public',
      progressCallback: (progress) => {
        let percent = progress.loaded / progress.total;
        if (fileIncrementPercent && loadingCallback)
          loadingCallback(
            'Uploading files... ' +
              Math.round(totalPercent + percent * fileIncrementPercent) +
              '%'
          );
      },
    });

    return {
      type: ResponseType.Success,
      data: filePath,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Fetch a temporary URL for the file from S3
 * @param filePath The file path to get from S3
 * @returns Success if the file was retrieved or Failure if there was an error
 */
export const getFileFromS3 = async (filePath: string): Promise<Response> => {
  try {
    let result = await Storage.get(filePath, {
      level: 'public',
      download: true,
    });
    let fileName: string = filePath.split('/').pop() as string;
    let file = blobToFile(result.Body, fileName);
    return {
      type: ResponseType.Success,
      data: file,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

const blobToFile = (blob: any, filename: string): File => {
  const lastModified = new Date(); // Use the current date as the last modified date
  const file = new File([blob], filename, {
    type: blob.type,
    lastModified: lastModified.getTime(),
  });
  return file;
};
